import React, { useMemo, useState } from 'react'
import { groupBy } from 'lodash'
import { compose } from 'redux'
import { withWindowSizes } from '@edulastic/common'
import { connect } from 'react-redux'
import { Button } from 'antd'
import styled from 'styled-components'
import EdulasticOverviewModel from '../Showcase/components/Myclasses/components/EdulasticOverview/EdulasticOverviewModel'

export const QuickStartGuide = (props) => {
  const { dashboardTiles, windowWidth } = props

  const [showQuickStartGuide, setShowQuickStartGuide] = useState(null)

  const quickStartGuideData = useMemo(() => {
    const { BANNER } = groupBy(dashboardTiles, 'type')
    if (!BANNER) {
      return null
    }
    const quickStartGuideBanner = BANNER.find(
      (banner) => banner.description === '2 Min Overview'
    )?.config?.filters[0]
    const { data } = quickStartGuideBanner
    return data
  }, [dashboardTiles])

  if (!quickStartGuideData) {
    return null
  }

  return (
    <>
      {showQuickStartGuide && (
        <EdulasticOverviewModel
          handleBannerModalClose={() => setShowQuickStartGuide(null)}
          isBannerModalVisible={quickStartGuideData}
          setShowBannerModal={setShowQuickStartGuide}
          windowWidth={windowWidth}
          continueToDashboardButton
        />
      )}
      <StyledButton onClick={() => setShowQuickStartGuide(true)}>
        Quick Start Guide
      </StyledButton>
    </>
  )
}

const enhance = compose(
  withWindowSizes,
  connect((state) => ({
    dashboardTiles: state.dashboardTeacher.configurableTiles,
  }))
)

export default enhance(QuickStartGuide)

const StyledButton = styled(Button)`
  background: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid #ffffff99 !important;
  border-radius: 8px;
  font-size: 12px;
  height: 34px;
  &:hover {
    color: #ffffff99;
    background: transparent;
  }
`
