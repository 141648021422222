import React, { useEffect } from 'react'
import { Button } from 'antd'
import styled from 'styled-components'
import { IconGrid, IconListBar } from '@edulastic/icons'
import { connect } from 'react-redux'

import { themeColor } from '@edulastic/colors'
import { segmentApi } from '@edulastic/api'
import { getUserId } from '../../../../../../../src/selectors/user'
import { getClassView, setClassViewAction } from '../../../../../../ducks'
import { classView } from '../../../../utils'

const ButtonGroup = Button.Group

const ClassView = ({ userId, selectedClassView, setClassView }) => {
  useEffect(() => {
    // When view is not set in localStorage then default set to GRID view
    if (!selectedClassView) {
      setClassView({ userId, view: classView.GRID })
    }
  }, [])

  const handleClick = (view) => {
    segmentApi.genericEventTrack('Classview', {
      Selected: view,
    })
    setClassView({ userId, view })
  }

  return (
    <ClassViewContainer>
      <ButtonGroup data-joyride-id="gridListView">
        <StyledButton
          $isHighlighted={selectedClassView === classView.GRID}
          size="medium"
          onClick={() => handleClick(classView.GRID)}
          data-cy="classGridView"
          data-testid="grid-view"
        >
          <IconGrid color="currentColor" />
        </StyledButton>
        <StyledButton
          $isHighlighted={selectedClassView === classView.LIST}
          size="medium"
          onClick={() => handleClick(classView.LIST)}
          data-cy="classListView"
          data-testid="list-view"
        >
          <IconListBar color="currentColor" />
        </StyledButton>
      </ButtonGroup>
    </ClassViewContainer>
  )
}

export default connect(
  (state) => ({
    userId: getUserId(state),
    selectedClassView: getClassView(state),
  }),
  {
    setClassView: setClassViewAction,
  }
)(ClassView)

const ClassViewContainer = styled.div`
  display: flex;
`
/** @type {typeof Button} */
const StyledButton = styled(Button)`
  color: ${({ $isHighlighted }) => $isHighlighted && themeColor};
  background-color: ${({ $isHighlighted }) => $isHighlighted && '#1ab39514'};
  &:focus,
  &:hover {
    color: ${themeColor};
    background-color: #1ab39514;
  }
`
