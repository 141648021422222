import React from 'react'
import { segmentApi } from '@edulastic/api'
import { StyledSeeAll } from './styled'
import { setFilterInSession } from '../../../../../common/utils/helpers'

const SeeAll = ({ history, userId, districtId, filterState }) => {
  const handleSeeAll = () => {
    history.push('/author/assignments')
    setFilterInSession({
      key: 'assignments_filter',
      userId,
      districtId,
      filter: {
        grades: filterState.grades ? filterState.grades.split(',') : [],
        classId: filterState.classIds,
        status: filterState.statuses,
      },
    })
    segmentApi.genericEventTrack('Dashboard:Assignment:SeeAll')
  }

  return (
    <div>
      <StyledSeeAll onClick={handleSeeAll} data-cy="seeAllAssignments">
        See All
      </StyledSeeAll>
    </div>
  )
}

export default SeeAll
