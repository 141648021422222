import { SelectInputStyled, TextAreaInputStyled } from '@edulastic/common'
import {
  LANGUAGE_EN,
  LANGUAGES_OPTIONS,
} from '@edulastic/constants/const/languages'

import { IconCopyContent } from '@edulastic/icons'
import { Form, Select } from 'antd'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { segmentApi } from '@edulastic/api'
import AIContentView from '../common/AIContentView'
import AIFooterAction from '../common/AIFooterAction'
import { StyledForm } from '../common/styled'
import {
  generateAITranslatedContentAction,
  getAITranslateContentState,
  resetAITranslateContentStateAction,
} from '../ducks/translate'
import { parseHTMLToPlainText } from '../utils/htmlParser'
import { copyContentToClipboard } from '../utils/common'
import {
  originalQuestionFieldDataTestId,
  originalQuestionFieldLabelDataTestId,
  translateLanguageSelectFieldDataTestId,
  translateLanguageSelectFieldLabelDataTestId,
} from '../constants/test'
import { ITEM_AUTHORING_PROMPTS } from '../constants/prompt'

const AITranslate = ({
  form,
  data: {
    question: { stimulus = '', languageFeatures } = {},
    selectedLanguage = LANGUAGE_EN,
  } = {},
  generateAITranslatedContent,
  aiTranslateContentState,
  resetAITranslateContentState,
}) => {
  const { getFieldDecorator, setFieldsValue } = form
  const { apiStatus, result } = aiTranslateContentState

  useEffect(() => {
    resetAITranslateContentState()
    setFieldsValue({
      languageCode: selectedLanguage || LANGUAGE_EN,
    })
  }, [selectedLanguage])

  useEffect(() => {
    const languageStimulus = languageFeatures?.[selectedLanguage]?.stimulus
    setFieldsValue({
      content: parseHTMLToPlainText(languageStimulus || stimulus),
    })
  }, [stimulus])

  const currentPrompt = ITEM_AUTHORING_PROMPTS.TRANSLATION

  const onSubmit = (e) => {
    e.preventDefault()
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (currentPrompt.trackingEvent) {
          segmentApi.genericEventTrack('GenerateAIItem', {
            source: currentPrompt.trackingEvent.source,
          })
        }

        generateAITranslatedContent(values)
      }
    })
  }

  const isLoading = apiStatus === 'INITIATED'

  return (
    <StyledForm onSubmit={onSubmit}>
      <Form.Item
        required
        label={
          <label data-testid={originalQuestionFieldLabelDataTestId}>
            ORIGINAL QUESTION
          </label>
        }
        name="content"
      >
        {getFieldDecorator('content', {
          rules: [
            {
              required: true,
              message: 'This field is required!',
            },
          ],
        })(
          <TextAreaInputStyled
            disabled={isLoading}
            autoSize={{ minRows: 4 }}
            data-cy="originalQuestionTextOnTranslate"
            placeholder="Your original question..."
            data-testid={originalQuestionFieldDataTestId}
          />
        )}
      </Form.Item>
      <Form.Item
        label={
          <label data-testid={translateLanguageSelectFieldLabelDataTestId}>
            TRANSLATE LANGUAGE
          </label>
        }
        name="languageCode"
      >
        {getFieldDecorator('languageCode', {
          rules: [
            {
              required: true,
              message: 'This field is required!',
            },
          ],
        })(
          <SelectInputStyled
            disabled={isLoading}
            placeholder="Select language"
            dropdownStyle={{ zIndex: 1005 }}
            options={LANGUAGES_OPTIONS}
            data-testid={translateLanguageSelectFieldDataTestId}
            data-cy="translateLanguageSelect"
          >
            {LANGUAGES_OPTIONS.map(({ value, label }) => (
              <Select.Option value={value} data-testid={value}>
                {label}
              </Select.Option>
            ))}
          </SelectInputStyled>
        )}
      </Form.Item>
      {apiStatus && (
        <div style={{ marginBottom: '90px' }} data-cy="translateContentByAi">
          <AIContentView
            onRegenerate={onSubmit}
            title="Created by AI Assistant for Translating Text"
            loadingTitle="Generating translated content"
            content={result}
            status={apiStatus}
          />
        </div>
      )}
      <AIFooterAction
        status={apiStatus}
        onRegenerate={onSubmit}
        action={{
          label: 'Copy',
          icon: <IconCopyContent height="20px" width="20px" />,
          onClick: () => {
            copyContentToClipboard(result)
          },
        }}
        isLoading={isLoading}
      />
    </StyledForm>
  )
}

const enhance = compose(
  Form.create({ name: 'ai-text-translate' }),
  connect(
    (state) => ({
      aiTranslateContentState: getAITranslateContentState(state),
    }),
    {
      resetAITranslateContentState: resetAITranslateContentStateAction,
      generateAITranslatedContent: generateAITranslatedContentAction,
    }
  )
)

export default enhance(AITranslate)
