import React from 'react'
import {
  captureSentryException,
  EduButton,
  notification,
} from '@edulastic/common'
import { canvasApi } from '@edulastic/api'

import { connect } from 'react-redux'
import { get } from 'lodash'
import {
  IconCanvas,
  IconCleverSquare,
  IconGoogleClassroomSquare,
} from '@edulastic/icons'
import styled from 'styled-components'
import { AUTH_FLOW, GoogleLoginWrapper } from '../../../../../../vendors/google'
import AuthorCompleteSignupButton from '../../../../../common/components/AuthorCompleteSignupButton'
import { scopes } from '../../../../ManageClass/components/ClassListContainer/ClassCreatePage'
import authorizeCanvas from '../../../../../common/utils/CanavsAuthorizationModule'
import {
  getCanvasAllowedInstitutionPoliciesSelector,
  getCleverLibraryUserSelector,
  getGoogleAllowedInstitionPoliciesSelector,
  getUser,
} from '../../../../src/selectors/user'
import {
  fetchClassListAction,
  setShowCleverSyncModalAction,
} from '../../../../ManageClass/ducks'

const SyncAction = ({
  googleAllowedInstitutions,
  canvasAllowedInstitutions,
  isUserGoogleLoggedIn,
  fetchClassList,
  isCleverUser,
  setShowCleverSyncModal,
  handleCanvasBulkSync,
  user,
  history,
}) => {
  const { cleverId } = user

  const handleLoginSucess = (data) => {
    fetchClassList({ data })
    history.push('/author/manageClass')
  }

  const handleError = (err) => {
    console.log('error', err)
  }
  const allowGoogleLogin = googleAllowedInstitutions.length > 0
  const loginGoogle = (googleClient) => googleClient.requestCode()

  const handleSyncWithCanvas = async () => {
    try {
      const result = await canvasApi.getCanvasAuthURI(
        canvasAllowedInstitutions?.[0]?.institutionId
      )
      if (!result.userAuthenticated) {
        const subscriptionTopic = `canvas:${user.districtId}_${user._id}_${
          user.username || user.email || ''
        }`
        authorizeCanvas(result.canvasAuthURL, subscriptionTopic)
          .then((res) => {
            handleCanvasBulkSync(res)
          })
          .catch((err) => {
            console.error('Error while authorizing', err)
            captureSentryException(err)
            notification({ messageKey: 'errorOccuredWhileAuthorizing' })
          })
      } else {
        handleCanvasBulkSync()
      }
    } catch (err) {
      captureSentryException(err)
      notification(
        err.status === 403 && err.response.data?.message
          ? {
              msg: err.response.data?.message,
            }
          : { messageKey: 'errorWhileGettingAuthUri' }
      )
    }
  }

  const enableCanvasSync = canvasAllowedInstitutions.length > 0
  const syncCleverSync = () => setShowCleverSyncModal(true)

  return (
    <>
      {allowGoogleLogin !== false && !cleverId && (
        <GoogleLoginWrapper
          WrappedComponent={({ googleClient }) => (
            <AuthorCompleteSignupButton
              renderButton={(handleClick) => (
                <StyledButton
                  isGhost
                  onClick={handleClick}
                  data-cy="syncGoogle"
                >
                  <IconGoogleClassroomSquare />
                  <ButtonText>Sync with Google Classroom</ButtonText>
                </StyledButton>
              )}
              onClick={() => loginGoogle(googleClient)}
              triggerSource="Sync Google Class Button Click"
            />
          )}
          successCallback={handleLoginSucess}
          errorCallback={handleError}
          scopes={scopes}
          prompt={isUserGoogleLoggedIn ? '' : 'consent'}
          flowType={AUTH_FLOW.CODE}
          onRetry={(googleClient) => loginGoogle(googleClient)}
        />
      )}
      {isCleverUser && (
        <AuthorCompleteSignupButton
          renderButton={(handleClick) => (
            <StyledButton isGhost onClick={handleClick}>
              <IconCleverSquare />
              <ButtonText>Sync Class Roster from Clever</ButtonText>
            </StyledButton>
          )}
          onClick={syncCleverSync}
        />
      )}
      {enableCanvasSync && !cleverId && (
        <AuthorCompleteSignupButton
          renderButton={(handleClick) => (
            <StyledButton isGhost onClick={handleClick} data-cy="syncCanvas">
              <IconCanvas />
              <ButtonText>Sync with Canvas</ButtonText>
            </StyledButton>
          )}
          onClick={handleSyncWithCanvas}
        />
      )}
    </>
  )
}
export default connect(
  (state) => ({
    user: getUser(state),
    canvasAllowedInstitutions: getCanvasAllowedInstitutionPoliciesSelector(
      state
    ),
    googleAllowedInstitutions: getGoogleAllowedInstitionPoliciesSelector(state),
    isUserGoogleLoggedIn: get(state, 'user.user.isUserGoogleLoggedIn'),
    isCleverUser: getCleverLibraryUserSelector(state),
  }),
  {
    fetchClassList: fetchClassListAction,
    setShowCleverSyncModal: setShowCleverSyncModalAction,
  }
)(SyncAction)

const ButtonText = styled.p`
  padding: 0px 5px;
`
const StyledButton = styled(EduButton)`
  padding: 5px 12px;
  margin: 0px;
`
