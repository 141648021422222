import React from 'react'
import { isEmpty, compact } from 'lodash'

import { IconUploadFile } from '@edulastic/icons'
import { notification, EduButton, ActionsDropdown } from '@edulastic/common'
import { FEED_TYPES_WITH_SFTP_ONLY } from '@edulastic/constants/const/testTypes'

import { dwLogStatus, tableColumns, COLUMN_KEYS } from './constants'
import appConfig from '../../../../../../../app-config'
import TableActionIcon from '../components/TableActionIcon'
import {
  DeleteRecords,
  ViewUploadedRecords,
} from '../components/ActionsDropdownOptions'
import { StyledIconUploadFile } from '../../common/components/StyledComponents'

const { cdnURI } = appConfig

export const sortText = (a, b, key) =>
  (a[key] || '').toLowerCase().localeCompare((b[key] || '').toLowerCase())

export const sortByDate = (a = '', b = '') => new Date(a) - new Date(b)

export const getTableColumns = ({ termsMap, feedTypes, onDelete, onEdit }) => {
  const feedTypesMap = new Map(
    (feedTypes || []).map(({ key, title }) => [key, title])
  )
  return tableColumns.map((col) => {
    if (col.key === COLUMN_KEYS.FEED_TYPE) {
      return {
        ...col,
        render: (value) =>
          feedTypesMap.has(value) ? feedTypesMap.get(value) : value,
      }
    }
    if (col.key === COLUMN_KEYS.SCHOOL_TERM) {
      return {
        ...col,
        render: (termId) =>
          termsMap.has(termId) ? termsMap.get(termId).name : '-',
        sorter: (a, b) =>
          sortByDate(
            termsMap.get(a.termId)?.startDate,
            termsMap.get(b.termId)?.startDate
          ),
      }
    }
    if (col.key === COLUMN_KEYS.EDIT) {
      const onDisabledClick = (record) =>
        notification({
          type: 'info',
          msg: FEED_TYPES_WITH_SFTP_ONLY.includes(record.feedType)
            ? 'Please re-upload the file using SFTP'
            : 'Re-upload is currently unavailable as the previous upload is still in progress. Please wait for a while or contact support.',
        })
      return {
        ...col,
        render: (_, record) => {
          const isDisabled = [
            // termId and feedType may be empty when uploaded via sftp
            !record.termId,
            !record.feedType,
            record.status === dwLogStatus.IN_PROGRESS,
            FEED_TYPES_WITH_SFTP_ONLY.includes(record.feedType),
          ].some((e) => e)
          return (
            <TableActionIcon
              icon={<IconUploadFile />}
              onClick={() => onEdit(record)}
              disabled={isDisabled}
              onDisabledClick={() => onDisabledClick(record)}
              label="Re-Upload"
              dataCy="reUploadButton"
            />
          )
        },
      }
    }
    if (col.key === COLUMN_KEYS.ACTIONS) {
      return {
        ...col,
        render: (_, record) => (
          <ActionsDropdown
            options={compact([
              record.inputFileDownloadLink && (
                <ViewUploadedRecords url={record.inputFileDownloadLink} />
              ),
              <DeleteRecords record={record} onDelete={onDelete} />,
            ])}
            triggerElement={
              <EduButton
                data-cy="actionsButton"
                isGhost
                width="70px"
                height="25px"
                justifyContent="center"
              >
                Actions
              </EduButton>
            }
            showDivider
          />
        ),
      }
    }
    return col
  })
}

export const getTemplateFilePath = (type, feedTypes) => {
  if (isEmpty(feedTypes)) return null
  const templateLink = feedTypes.find(({ key }) => key === type)?.templateLink
  if (!isEmpty(templateLink)) {
    return `${cdnURI}${templateLink}`
  }
  return null
}

export const getFailedStatusTooltipText = (statusReason = '') => {
  if (!statusReason) return ''
  if (statusReason.includes(' upload ')) {
    const [statusReasonPart1, statusReasonPart2] = statusReason.split(
      ' upload '
    )
    return (
      <>
        {statusReasonPart1} <StyledIconUploadFile /> Re-Upload{' '}
        {statusReasonPart2}
      </>
    )
  }
  return (
    <>
      {statusReason} Please <StyledIconUploadFile /> Re-Upload the file!
    </>
  )
}
