import React, { Component } from 'react'
import PropTypes from 'prop-types'
import produce from 'immer'
import { connect } from 'react-redux'
import { getFormattedAttrId } from '@edulastic/common/src/helpers'
import { withNamespaces } from '@edulastic/localization'

import { setQuestionDataAction } from '../../../author/QuestionEditor/ducks'
import { updateVariables } from '../../utils/variables'
import QuestionTextArea from '../../components/QuestionTextArea'
import { Subtitle } from '../../styled/Subtitle'

import Question from '../../components/Question'

class ComposeQuestion extends Component {
  render() {
    const { item, setQuestionData, t, fillSections, cleanSections } = this.props

    const handleItemChangeChange = (prop, uiStyle) => {
      setQuestionData(
        produce(item, (draft) => {
          draft[prop] = uiStyle
          updateVariables(draft)
        })
      )
    }

    return (
      <Question
        section="main"
        label={t('component.matchList.composeQuestion')}
        fillSections={fillSections}
        cleanSections={cleanSections}
      >
        <Subtitle
          id={getFormattedAttrId(
            `${item?.title}-${t('component.matchList.composeQuestion')}`
          )}
        >
          {t('component.matchList.composeQuestion')}
        </Subtitle>

        <QuestionTextArea
          placeholder={t('component.matchList.enterQuestion')}
          onChange={(stimulus) => handleItemChangeChange('stimulus', stimulus)}
          value={item.stimulus}
          border="border"
        />
      </Question>
    )
  }
}

ComposeQuestion.propTypes = {
  item: PropTypes.object.isRequired,
  setQuestionData: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  fillSections: PropTypes.func,
  cleanSections: PropTypes.func,
}

ComposeQuestion.defaultProps = {
  fillSections: () => {},
  cleanSections: () => {},
}

export default withNamespaces('assessment')(
  connect(null, { setQuestionData: setQuestionDataAction })(ComposeQuestion)
)
