import React from 'react'
import withIconStyles from '@edulastic/icons/src/HOC/withIconStyles'
import SVG from '@edulastic/icons/src/common/SVG'

const IconItalic = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="8.108"
    height="12.162"
    viewBox="0 0 8.108 12.162"
    {...props}
  >
    <g transform="translate(-73.089)">
      <path d="M80.912.02q-.277.02-.538.036Q79.2.127,78.679.127q-.269,0-.578-.016L75.591,0l-.15.816a2.922,2.922,0,0,0,.3.032,3,3,0,0,1,1.14.222v.3l-.063.4-.174,1.069-.127.5-.23,1.243a.807.807,0,0,1-.028.091q-.028.083-.071.241t-.1.368q-.056.21-.127.531t-.135.669l-.1.507L75.29,9.105l-.214,1.1a1.827,1.827,0,0,1-.325.8,4.7,4.7,0,0,1-.918.309l-.61.158-.135.673q.19-.016.776-.071,1.053-.095,1.378-.087L76.81,12q1,.1,1.148.135a1.6,1.6,0,0,0,.222.024q.143,0,.333-.016a1.25,1.25,0,0,1,.182-.008q.016-.079.071-.325a2.754,2.754,0,0,0,.055-.459q-.238-.039-.523-.079a7.949,7.949,0,0,1-.942-.166.612.612,0,0,1-.008-.214l.1-.356.34-1.861.3-1.251.483-2.462q.174-.847.523-2.423.032-.3.1-.649a4.742,4.742,0,0,1,.19-.7,6.274,6.274,0,0,1,.8-.245A7.841,7.841,0,0,0,81.046.7q.048-.174.1-.4A2.894,2.894,0,0,0,81.2,0Z" />
    </g>
  </SVG>
)

export default withIconStyles(IconItalic)
