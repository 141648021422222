/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconEyeClose = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="14.821"
    height="12.5"
    viewBox="0 0 14.821 12.5"
    {...props}
  >
    <g transform="translate(-408.928 -291.811)">
      <path d="M420.607,300.887l.021-.014-.7-.7-.021.013-1.044-1.043c0-.008.011-.013.015-.02l-3.981-3.981c-.008,0-.013.011-.02.015l-.738-.739.022-.011-.736-.736-.022.012-1.647-1.647a.75.75,0,1,0-1.061,1.06l1.38,1.379a14.3,14.3,0,0,0-3.047,2.914.488.488,0,0,0,0,.577c.132.181,3.287,4.435,7.316,4.435a6.726,6.726,0,0,0,2.931-.725l2.42,2.42a.75.75,0,0,0,1.061-1.061Zm-4.268.528c-2.968,0-5.538-2.823-6.3-3.746a13.283,13.283,0,0,1,2.726-2.5l1.043,1.043a2.867,2.867,0,0,0-.4,1.463,2.931,2.931,0,0,0,2.931,2.931,2.867,2.867,0,0,0,1.463-.4l.721.721A5.566,5.566,0,0,1,416.339,301.415Z" />
      <path d="M423.655,297.385c-.132-.181-3.287-4.435-7.316-4.435a6.061,6.061,0,0,0-1.36.163l.84.841a4.725,4.725,0,0,1,.52-.03c2.975,0,5.538,2.823,6.3,3.746a14.039,14.039,0,0,1-1.527,1.577l.7.7a14.139,14.139,0,0,0,1.848-1.981A.488.488,0,0,0,423.655,297.385Z" />
    </g>
  </SVG>
)

export default withIconStyles(IconEyeClose)
