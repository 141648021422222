/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconBookInformation = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18.621 22.561"
    {...props}
  >
    <g transform="translate(36.968 22.561) rotate(180)">
      <path
        d="M34.471,0H24.992a2.5,2.5,0,0,0-2.5,2.5V2.8H20.844a2.5,2.5,0,0,0-2.5,2.5V20.064a2.5,2.5,0,0,0,2.5,2.5h9.478a2.5,2.5,0,0,0,2.5-2.5v-.306H34.47a2.5,2.5,0,0,0,2.5-2.5V2.5A2.5,2.5,0,0,0,34.471,0ZM31.208,20.064a.887.887,0,0,1-.886.886H20.844a.887.887,0,0,1-.886-.886V5.3a.887.887,0,0,1,.886-.886h9.478a.887.887,0,0,1,.886.886V20.064Zm4.148-2.8a.887.887,0,0,1-.886.886H32.819V5.3a2.5,2.5,0,0,0-2.5-2.5H24.106V2.5a.887.887,0,0,1,.886-.886h9.478a.887.887,0,0,1,.886.886V17.26Z"
        transform="translate(0)"
      />
      <path
        d="M-2.418-6.389a2.868,2.868,0,0,1,1.066-.783A3.36,3.36,0,0,1-.008-7.444a2.625,2.625,0,0,1,1.659.479,1.513,1.513,0,0,1,.613,1.25,1.8,1.8,0,0,1-.1.62,1.648,1.648,0,0,1-.264.481,2.858,2.858,0,0,1-.359.368q-.2.169-.394.313t-.353.283a1.265,1.265,0,0,0-.255.308.708.708,0,0,0-.1.364.6.6,0,0,0,.2.477l-1.188.361A1.475,1.475,0,0,1-.91-3.151a1.372,1.372,0,0,1,.165-.672,1.671,1.671,0,0,1,.4-.494q.236-.194.47-.361a2.114,2.114,0,0,0,.394-.358.617.617,0,0,0,.161-.4.563.563,0,0,0-.216-.456.983.983,0,0,0-.636-.177,1.675,1.675,0,0,0-1.354.693ZM-.648-.124A.775.775,0,0,1-.91-.706a.775.775,0,0,1,.263-.582.872.872,0,0,1,.618-.247.853.853,0,0,1,.613.247.783.783,0,0,1,.257.582.783.783,0,0,1-.257.582A.853.853,0,0,1-.029.124.872.872,0,0,1-.648-.124Z"
        transform="translate(25.418 8.77) rotate(180)"
      />
    </g>
  </SVG>
)

export default withIconStyles(IconBookInformation)
