import { adaptiveTestThresholdTypes } from '../constants'

export const validateAndGetDefaultThresholdValue = ({
  thresholdValue,
  thresholdType,
  baseSectionMaxItemsCount,
}) => {
  const isThresoldTypePercent =
    thresholdType === adaptiveTestThresholdTypes.PERCENTAGE.value
  const defaultThresholdValue = isThresoldTypePercent ? 10 : 1
  const isInvalidThresholdValue =
    typeof thresholdValue !== 'number' ||
    thresholdValue < 1 ||
    (isThresoldTypePercent && thresholdValue > 99) ||
    (!isThresoldTypePercent && thresholdValue > baseSectionMaxItemsCount)

  if (isInvalidThresholdValue) {
    return defaultThresholdValue
  }
  return thresholdValue
}
