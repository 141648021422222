import styled from 'styled-components'
import { Row, Col } from 'antd'

export const RowContainer = styled.div`
  display: flex;
`
export const StyledStandardInputRow = styled(Row)`
  margin-left: 0px !important;
  margin-right: 0px !important;

  .ant-col:first-child {
    padding-left: 0px !important;
  }
  .ant-col:last-child {
    padding-right: 0px !important;
  }
`
export const StyledSearchStandardCol = styled(Col)`
  ${(props) => props?.paddingRight && 'padding-right: 0px !important;'}
`
