import React from 'react'
import styled from 'styled-components'
import { compose } from 'redux'
import { withNamespaces } from '@edulastic/localization'

const AccommodationDescription = ({ i18translate }) => {
  return (
    <div>
      <p>{i18translate('accommodationsSettings.description')}</p>
      <Description>
        <DescriptionBullet>
          {i18translate('accommodationsSettings.enableDisable')}
        </DescriptionBullet>
        <DescriptionBullet>
          {i18translate('accommodationsSettings.studentLevel')}
        </DescriptionBullet>
      </Description>
    </div>
  )
}

export default compose(withNamespaces('author'))(AccommodationDescription)

const Description = styled.ul`
  padding-left: 16px;
`
const DescriptionBullet = styled.li`
  margin-top: 8px;
  &::marker {
    color: #d8d8d8;
  }
`
