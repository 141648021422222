import React from 'react'
import withIconStyles from '@edulastic/icons/src/HOC/withIconStyles'
import SVG from '@edulastic/icons/src/common/SVG'

const IconGraphSecant = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="43.998"
    height="26.267"
    viewBox="0 0 43.998 26.267"
    {...props}
  >
    <g transform="translate(-521.869 -281.513)">
      <path
        d="M522.869,295.683a1,1,0,1,1,0-2l42-.019h0a1,1,0,0,1,0,2l-42,.019Z"
        fill="#c1c3c9"
      />
      <path
        d="M543.869,307.522a1,1,0,0,1-1-1V282.843a1,1,0,0,1,2,0v23.679A1,1,0,0,1,543.869,307.522Z"
        fill="#c1c3c9"
      />
      <path d="M543.584,292.245a3.634,3.634,0,0,1-2.58-1.074c-2.437-2.377-2.472-8.026-2.468-8.663a1,1,0,0,1,1-.993h.006a1,1,0,0,1,.994,1c-.008,1.5.313,5.707,1.865,7.22a1.6,1.6,0,0,0,1.241.5,1.738,1.738,0,0,0,1.278-.606c1.481-1.587,1.665-5.653,1.611-7.085a1,1,0,0,1,.961-1.038,1.019,1.019,0,0,1,1.037.96c.025.612.166,6.046-2.145,8.526a3.722,3.722,0,0,1-2.68,1.242Z" />
      <path d="M523.268,292.244a1,1,0,0,1-.031-2,1.741,1.741,0,0,0,1.279-.607c1.484-1.593,1.666-5.654,1.61-7.084a1,1,0,0,1,.96-1.038.983.983,0,0,1,1.038.961c.024.611.166,6.045-2.145,8.525a3.72,3.72,0,0,1-2.679,1.242Z" />
      <path d="M564.134,292.244H564.1a3.722,3.722,0,0,1-2.68-1.242c-2.312-2.48-2.17-7.914-2.146-8.525a1,1,0,1,1,2,.076c-.053,1.433.131,5.5,1.612,7.086a1.738,1.738,0,0,0,1.278.606,1,1,0,0,1-.03,2Z" />
      <path d="M548.035,307.78a1,1,0,0,1-1-.994c0-.637.032-6.291,2.47-8.668a3.6,3.6,0,0,1,2.7-1.071,3.719,3.719,0,0,1,2.68,1.243c2.311,2.481,2.169,7.917,2.144,8.529a1.03,1.03,0,0,1-1.037.96,1,1,0,0,1-.961-1.037c.054-1.433-.13-5.5-1.611-7.091a1.744,1.744,0,0,0-1.277-.605,1.659,1.659,0,0,0-1.241.5c-1.558,1.519-1.875,5.73-1.866,7.224a1,1,0,0,1-.994,1.006Z" />
      <path d="M531.633,307.78a1,1,0,0,1-1-.994c0-.637.032-6.291,2.47-8.668a3.591,3.591,0,0,1,2.7-1.071,3.715,3.715,0,0,1,2.679,1.243c2.312,2.481,2.169,7.917,2.145,8.529a1.028,1.028,0,0,1-1.037.96,1,1,0,0,1-.961-1.037c.054-1.433-.13-5.5-1.611-7.091a1.748,1.748,0,0,0-1.278-.605,1.666,1.666,0,0,0-1.24.5c-1.559,1.519-1.875,5.73-1.866,7.224a1,1,0,0,1-.994,1.006Z" />
    </g>
  </SVG>
)

export default withIconStyles(IconGraphSecant)
