import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Tooltip } from 'antd'
import UnScored from '@edulastic/common/src/components/Unscored'
import {
  helpers,
  WithMathFormula,
  FlexContainer,
  CheckboxLabel,
  PremiumItemBanner,
} from '@edulastic/common'
import { Stimulus, NumberInputStyledTestPage } from './styled'
import { ScoreInputWrapper, InfoIcon } from '../styled'
import Actions from '../Actions'

class MainInfo extends React.Component {
  render() {
    const {
      data,
      index,
      handlePreview,
      handleAiOptionSelected,
      isEditable,
      owner,
      onChangePoints,
      blur,
      expandRow,
      onDelete,
      onSelect,
      checked,
      isScoringDisabled = false,
      groupPoints,
      groupMinimized,
      isUnScoredItem,
      showAltScoreInfo,
      isPremiumContentWithoutAccess,
      premiumCollectionWithoutAccess,
      setShowAutoSelectScoreChangeModal,
      showScorePopUp,
      handleReplace,
      replaceableRestrictedContent,
      isEditDisabledForSectionsAdaptiveTest,
      showAiActionButton,
    } = this.props
    const newHtml = helpers.sanitizeForReview(data.stimulus) || ''
    const points = groupMinimized ? groupPoints : data.points

    return (
      <FlexContainer
        data-cy-item-index={index}
        style={{ justifyContent: 'space-between' }}
      >
        {isPremiumContentWithoutAccess ? (
          <PremiumItemBanner
            itemBankName={premiumCollectionWithoutAccess}
            handleReplace={handleReplace}
            replaceableRestrictedContent={replaceableRestrictedContent}
            hideQuestionLabels
            height="auto"
            itemId={data.id}
            showAsTooltip
            reviewPage
          />
        ) : (
          <Stimulus
            dangerouslySetInnerHTML={{ __html: newHtml }}
            onClick={() => handlePreview(data.id)}
            style={{ position: 'relative' }}
          />
        )}
        <FlexContainer
          flexDirection="column"
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          <FlexContainer flexDirection="row" alignItems="center">
            <Actions
              onPreview={() => handlePreview(data.id)}
              onCollapseExpandRow={expandRow}
              onDelete={onDelete}
              isEditable={isEditable && !isEditDisabledForSectionsAdaptiveTest}
              showAiActionButton={showAiActionButton}
              onAiOptionSelected={(aiAction) => {
                handleAiOptionSelected(aiAction, data)
              }}
            />
            <ScoreInputWrapper>
              <div onClick={setShowAutoSelectScoreChangeModal}>
                {!isUnScoredItem ? (
                  <NumberInputStyledTestPage
                    width="60px"
                    value={points}
                    margin="0px 0px 0px 5px"
                    padding="0px 4px"
                    textAlign="center"
                    aria-label="Score"
                    disabled={
                      (!owner ||
                        !isEditable ||
                        isScoringDisabled ||
                        groupMinimized) &&
                      !showScorePopUp
                    }
                    onChange={(value) =>
                      !showScorePopUp && onChangePoints(data.id, value)
                    }
                    onBlur={() => blur(data.id)}
                  />
                ) : (
                  <UnScored
                    width="60px"
                    height="32px"
                    margin="0px 0px 0px 5px"
                    fontSize="10px"
                    text="Z"
                    fontWeight="700"
                  />
                )}
              </div>
              {showAltScoreInfo && (
                <Tooltip title="Question has alternate answers with different score points.">
                  <InfoIcon data-cy="alternate-score-info" />
                </Tooltip>
              )}
            </ScoreInputWrapper>
            {isEditable && (
              <CheckboxLabel
                aria-label="Select Question"
                checked={checked}
                ml="8px"
                onChange={onSelect}
                fixEmptyLabel
              />
            )}
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    )
  }
}

MainInfo.propTypes = {
  data: PropTypes.object.isRequired,
  handlePreview: PropTypes.func.isRequired,
  handleAiOptionSelected: PropTypes.func.isRequired,
  isUnScoredItem: PropTypes.bool,
}

MainInfo.defaultProps = {
  isUnScoredItem: false,
}

export default WithMathFormula(withRouter(MainInfo))
