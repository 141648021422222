import React from 'react'
import { Icon } from 'antd'

const IconCaretLeft = (props) => (
  <Icon type="caret-left" theme="filled" {...props} />
)

const IconCaretRight = (props) => (
  <Icon type="caret-right" theme="filled" {...props} />
)

const IconCaretUp = (props) => (
  <Icon type="caret-up" theme="filled" {...props} />
)

const IconCaretDown = (props) => (
  <Icon type="caret-down" theme="filled" {...props} />
)

export default {
  IconCaretDown,
  IconCaretLeft,
  IconCaretRight,
  IconCaretUp,
}
