import {
  EduButton,
  EduIf,
  NumberInputStyled,
  SelectInputStyled,
  TextAreaInputStyled,
  TextInputStyled,
} from '@edulastic/common'
import { IconMagicWand } from '@edulastic/icons'
import { white } from '@edulastic/colors'
import { Col, Row, Select } from 'antd'
import React from 'react'
import { segmentApi } from '@edulastic/api'
import { selectsData } from '../../../TestPage/components/common'
import {
  StyledButtonText,
  StyledDrawerFooter,
  StyledFilterLabel,
  StyledQuestionContextInputInfo,
  StyledRequired,
} from './styled'
import { formFields } from './ducks/constants'

import StandardSet from '../../../AssessmentPage/components/QuestionEditModal/common/StandardSet/StandardSet'
import { standardsFields } from '../../../AssessmentPage/VideoQuiz/constants'
import AIAttachment from './AIAttachment'

const {
  TEST_NAME,
  ITEM_TYPE,
  NUMBER_OF_ITEMS,
  DIFFICULTY,
  DOK,
  PREFERENCE,
} = formFields

const FormFields = ({
  handleFieldDataChange,
  handleAiFormSubmit,
  onCancel,
  addItems,
  aiFormContent,
  updateAlignment,
  attachmentType,
  onChangeAttachmentType,
  handleAttachmentChange,
  attachmentMessageData,
  setAttachmentMessageData,
  isGenerateDisabled,
  setIsGenerateDisabled,
  createAITestContainerRef,
}) => {
  const {
    testName,
    itemType,
    numberOfItems,
    dok,
    difficulty,
    preference,
    alignment,
    attachments,
  } = aiFormContent

  const handleGenerate = (...args) => {
    handleAiFormSubmit(...args)
    segmentApi.genericEventTrack('GenerateAIItem', {
      source: addItems ? 'AI Quiz: AddItem' : 'AI Quiz: CreateQuiz',
    })
  }

  return (
    <>
      <Row>
        <Row gutter={30}>
          <EduIf condition={!addItems}>
            <Col xs={24}>
              <StyledFilterLabel>
                Test Name<StyledRequired>*</StyledRequired>
              </StyledFilterLabel>
              <TextInputStyled
                data-cy="aiTestName"
                placeholder="Enter Test Name"
                maxLength={256}
                value={testName}
                onChange={(e) =>
                  handleFieldDataChange(TEST_NAME, e.target.value)
                }
              />
            </Col>
          </EduIf>
        </Row>
        <Row gutter={30}>
          <Col xs={24}>
            <StyledFilterLabel>QUESTION CONTEXT</StyledFilterLabel>
            <StyledQuestionContextInputInfo>
              Generate questions using your documents or URL, and add context
              for AI.
            </StyledQuestionContextInputInfo>
            <TextAreaInputStyled
              data-cy="aiTestPromptDescription"
              placeholder="Eg: Generate items focused on rational numbers where students need to use rational root test and also generate hints."
              maxLength={1000}
              onChange={(e) =>
                handleFieldDataChange(PREFERENCE, e.target.value)
              }
              height="120px"
              value={preference}
            />
          </Col>
        </Row>

        <Row gutter={30}>
          <Col xs={24}>
            <AIAttachment
              attachmentType={attachmentType}
              onChangeAttachmentType={onChangeAttachmentType}
              handleAttachmentChange={handleAttachmentChange}
              attachmentMessageData={attachmentMessageData}
              setAttachmentMessageData={setAttachmentMessageData}
              attachments={attachments}
              setIsGenerateDisabled={setIsGenerateDisabled}
              createAITestContainerRef={createAITestContainerRef}
            />
          </Col>
        </Row>

        <Row gutter={30}>
          <Col xs={12}>
            <StyledFilterLabel>
              Item Type<StyledRequired>*</StyledRequired>
            </StyledFilterLabel>
            <SelectInputStyled
              showArrow
              showSearch
              data-cy="aiItemType"
              placeholder="Select Item Type"
              optionFilterProp="children"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              height="36px"
              onChange={(value) => handleFieldDataChange(ITEM_TYPE, value)}
              value={itemType}
            >
              {selectsData.allQuestionTypes.map(({ value, text }) => (
                <Select.Option key={value} value={value}>
                  {text}
                </Select.Option>
              ))}
            </SelectInputStyled>
          </Col>
          <Col xs={12}>
            <StyledFilterLabel>
              Number Of Items<StyledRequired>*</StyledRequired>
            </StyledFilterLabel>
            <NumberInputStyled
              onChange={(value) =>
                handleFieldDataChange(NUMBER_OF_ITEMS, value)
              }
              min={1}
              max={20}
              step={1}
              height="36px"
              value={numberOfItems}
              data-cy="numberOfItem"
            />
          </Col>
        </Row>
        <Row gutter={30}>
          <Col xs={24}>
            <StyledFilterLabel>
              Standards <StyledRequired>*</StyledRequired>
            </StyledFilterLabel>
            <StandardSet
              alignment={alignment}
              onUpdate={(data) => updateAlignment(data.alignment)}
              showIconBrowserBtn
              hideLabel
              standardsRequiredFields={[
                standardsFields.SUBJECT,
                standardsFields.GRADES,
              ]}
              isDocBased
              trackingEventSource="AIQuiz"
              isAIFlow
            />
          </Col>
        </Row>
        <Row gutter={30}>
          <Col xs={12}>
            <StyledFilterLabel>DOK</StyledFilterLabel>
            <SelectInputStyled
              height="36px"
              showArrow
              showSearch
              data-cy="aiDok"
              placeholder="Select Dok"
              optionFilterProp="children"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              onChange={(value) => handleFieldDataChange(DOK, value)}
              value={dok}
            >
              {selectsData.allDepthOfKnowledgeAI
                .filter(({ value }) => value)
                .map(({ value, text }) => (
                  <Select.Option key={value} value={value}>
                    {text}
                  </Select.Option>
                ))}
            </SelectInputStyled>
          </Col>
          <Col xs={12}>
            <StyledFilterLabel>Difficulty</StyledFilterLabel>
            <SelectInputStyled
              height="36px"
              showArrow
              showSearch
              data-cy="aiDifficulty"
              placeholder="Select Difficulty"
              optionFilterProp="children"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              onChange={(value) => handleFieldDataChange(DIFFICULTY, value)}
              value={difficulty}
            >
              {selectsData.allAuthorDifficulty
                .filter(({ value }) => value)
                .map(({ value, text }) => (
                  <Select.Option key={value} value={value}>
                    {text}
                  </Select.Option>
                ))}
            </SelectInputStyled>
          </Col>
        </Row>
      </Row>
      <StyledDrawerFooter>
        <EduButton
          btnType="primary"
          width="248px"
          data-cy="aiCancelButton"
          isGhost
          onClick={onCancel}
        >
          <StyledButtonText>Cancel</StyledButtonText>
        </EduButton>
        <EduButton
          width="248px"
          justifyContent="center"
          data-cy="aiGenerateButton"
          onClick={handleGenerate}
          disabled={isGenerateDisabled}
        >
          <IconMagicWand fill={`${white}`} />
          <StyledButtonText>Generate</StyledButtonText>
        </EduButton>
      </StyledDrawerFooter>
    </>
  )
}

export default FormFields
