import styled from 'styled-components'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const DashboardContentContainer = styled.div`
  padding: 32px 100px 0px 100px;
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor
      ? typeof $backgroundColor === 'string'
        ? $backgroundColor
        : '#f9f9f9'
      : ''};
  /* Border will be only visible when background is there */
  border-bottom: ${({ $backgroundColor }) =>
    $backgroundColor ? '1px solid #d8d8d8' : ''};
`
