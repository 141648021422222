import React, { useRef, forwardRef, useImperativeHandle } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  AI,
  AI_ITEM_QUICK_LINKS,
  ITEM_QUICK_LINKS,
  LIBRARY,
  MORE_ITEM_TYPES,
} from '@edulastic/constants/const/addQuestionQuickLinks'
import { Col, Row, Tooltip } from 'antd'
import i18 from '@edulastic/localization/src/instance'
import { isVideoQuizAndAIEnabledSelector } from '../../../../../../src/selectors/user'
import QuickItemLinkCard from '../QuickItemLinkCard'
import EduAIQuiz from '../../../../../../AssessmentCreate/components/CreateAITest'
import { CREATE_AI_TEST_DISPLAY_SCREENS } from '../../../../../../AssessmentCreate/components/CreateAITest/constants'
import { isSectionAdaptiveTestSelector } from '../../../../../ducks'

const QuickItemLinks = ({
  isVideoQuiAndAiEnabled,
  disabled,
  onClick,
  disabledTitle,
  loading,
  myForwardedRef,
  isSectionAdaptiveTest,
}) => {
  const eduAIQuizRef = useRef(null)

  useImperativeHandle(myForwardedRef, () => ({
    createAIItems: () => {
      eduAIQuizRef?.current?.createItems?.()
    },
  }))

  const quickLinks = [
    LIBRARY,
    ...(isVideoQuiAndAiEnabled ? AI_ITEM_QUICK_LINKS : ITEM_QUICK_LINKS),
    MORE_ITEM_TYPES,
  ]

  return (
    <>
      <Row type="flex" gutter={[14]} justify="center">
        {quickLinks.map((qType) => {
          const card = (
            <QuickItemLinkCard
              {...qType}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()

                onClick(qType.key)
              }}
              disabled={disabled}
              disabledTitle={disabledTitle}
            />
          )
          return (
            <Col
              span={24 / 7}
              style={{
                borderRight:
                  qType.key === LIBRARY.key ? '1px solid #d9d9d9' : 'none',
              }}
            >
              {qType.key === AI.key && !disabled ? (
                <Tooltip
                  title={
                    <>
                      <p>
                        {isSectionAdaptiveTest
                          ? i18.t(
                              'author:notSupportedForSectionsAdaptiveTest.info'
                            )
                          : i18.t('author:rubric.infoText')}
                      </p>
                    </>
                  }
                >
                  <div>
                    <EduAIQuiz
                      disableGenerate={loading}
                      ref={eduAIQuizRef}
                      addItems
                      displayScreen={
                        CREATE_AI_TEST_DISPLAY_SCREENS.QUICK_LINK_TEST_REVIEW_SCREEN
                      }
                    >
                      {card}
                    </EduAIQuiz>
                  </div>
                </Tooltip>
              ) : (
                card
              )}
            </Col>
          )
        })}
      </Row>
    </>
  )
}

const enhance = compose(
  connect((state) => ({
    isVideoQuiAndAiEnabled: isVideoQuizAndAIEnabledSelector(state),
    isSectionAdaptiveTest: isSectionAdaptiveTestSelector(state),
  }))
)

const EnhancedComponent = enhance(QuickItemLinks)

export default forwardRef((props, ref) => (
  <EnhancedComponent {...props} myForwardedRef={ref} />
))
