import React from 'react'

import { IconWrapper } from '../../common/components/StyledComponents'

const TableActionIcon = ({
  icon,
  onClick,
  disabled,
  onDisabledClick,
  label = '',
  dataCy,
}) => (
  <IconWrapper
    data-cy={dataCy}
    onClick={disabled ? onDisabledClick : onClick}
    $disabled={disabled}
  >
    {icon}
    <span>{label}</span>
  </IconWrapper>
)

export default TableActionIcon
