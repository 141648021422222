import React from 'react'

import Extras from '../../../containers/Extras'

const _Extras = ({ fillSections, cleanSections, advancedAreOpen }) => {
  return (
    <Extras
      fillSections={fillSections}
      cleanSections={cleanSections}
      advancedAreOpen={advancedAreOpen}
    />
  )
}

export default _Extras
