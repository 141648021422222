import React from 'react'
import styled from 'styled-components'
import { IconPearAssessLogoCompact } from '@edulastic/icons'
import NewAssignmentButton from '../../../../Assignments/components/TableList/NewAssignmentButton'

const NoAssignmentData = () => (
  <NoDataBox>
    <IconPearAssessLogoCompact width="32" height="32" />
    <h4>No Assignment Found</h4>
    <NewAssignmentButton
      buttonTitle="Create Assignment"
      isBlue={false}
      buttonDataCy="createNew"
    />
  </NoDataBox>
)

export default NoAssignmentData

const NoDataBox = styled.div`
  width: 100%;
  height: 300px;
  border-radius: 6px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;

  h4 {
    margin: 10px 0 20px 0;
    color: #555555;
    font-size: ${(props) => props.theme.noData.NoDataArchiveTextSize};
    font-weight: 600;
  }
}
`
