import { watcherSaga as aiRewriteSaga, reducer as aiRewrite } from './rewrite'
import {
  watcherSaga as aiTranslateSaga,
  reducer as aiTranslate,
} from './translate'
import {
  watcherSaga as aiQuestionGenerationSaga,
  reducer as aiQuestionGeneration,
} from './questionGeneration'
import {
  watcherSaga as aiPassageGenerationSaga,
  reducer as aiPassageGeneration,
} from './passageGeneration'
import {
  watcherSaga as aiPassageQuestionGenerationSaga,
  reducer as aiPassageQuestionGeneration,
} from './passageQuestionGeneration'
import {
  watcherSaga as aiEnhanceQuestionSaga,
  reducer as aiEnhanceQuestion,
} from './enhanceQuestion'
import {
  watcherSaga as aiResponseFeedbackSaga,
  reducer as aiResponseFeedback,
} from './feedback'

export const aiSagas = () => [
  aiRewriteSaga(),
  aiTranslateSaga(),
  aiQuestionGenerationSaga(),
  aiPassageGenerationSaga(),
  aiPassageQuestionGenerationSaga(),
  aiEnhanceQuestionSaga(),
  aiResponseFeedbackSaga(),
]

export const aiReducers = {
  aiRewrite,
  aiTranslate,
  aiQuestionGeneration,
  aiPassageGeneration,
  aiPassageQuestionGeneration,
  aiEnhanceQuestion,
  aiResponseFeedback,
}
