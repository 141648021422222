import React from 'react'
import { Button, Col, Modal } from 'antd'
import styled from 'styled-components'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { isKiosk } from '@edulastic/common/src/helpers'
import { themeColor } from '@edulastic/colors'
import { logoutAction } from '../Login/ducks'

const SebQuitConfirm = ({ logout }) => {
  return (
    <MainContainer>
      {isKiosk() ? (
        <Modal keyboard={false} onCancel={logout} visible footer={null}>
          <div style={{ textAlign: 'center' }}>
            <h3>
              Congratulations on completing the Test! <br />
              Now, you will Sign Out automatically.
            </h3>
            <br />
            <StyledButton
              size="small"
              style={{ background: themeColor }}
              type="primary"
              onClick={logout}
            >
              Close
            </StyledButton>
          </div>
        </Modal>
      ) : (
        <Container>
          <p>Do you really want to quit SEB?</p>
          <Col md={24} sm={24}>
            {/* its completely fine hardcoding this url, since this is just an indication */}
            <StyledButton
              href="https://app.edulastic.com/home/grades"
              data-cy="submit"
              type="primary"
              btnType={2}
            >
              Quit SEB
            </StyledButton>
          </Col>
        </Container>
      )}
    </MainContainer>
  )
}

const enhance = compose(
  connect(() => ({}), {
    logout: logoutAction,
  })
)

export default enhance(SebQuitConfirm)

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
`

const Container = styled.div`
  border-radius: 10px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 50px 50px;
  background: white;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  text-align: center;
`

const StyledButton = styled(Button)`
  height: 40px;
  padding: 8px 50px;
  @media screen and (max-width: 767px) {
    margin-top: 10px;
  }
`
