import React from 'react'
import withIconStyles from '@edulastic/icons/src/HOC/withIconStyles'
import SVG from '@edulastic/icons/src/common/SVG'

const IconStrike = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="13.5"
    height="11.743"
    viewBox="0 0 13.5 11.743"
    {...props}
  >
    <g transform="translate(-13.875 -29.413)">
      <path
        d="M5808.326-8335.844a7.3,7.3,0,0,1-3.235-.687v-2.25a13.175,13.175,0,0,0,1.957.727,5.826,5.826,0,0,0,1.464.212,2.091,2.091,0,0,0,1.223-.306,1.046,1.046,0,0,0,.425-.905,1.01,1.01,0,0,0-.188-.6,2.093,2.093,0,0,0-.549-.5c-.057-.038-.13-.083-.239-.145h3.17a3.375,3.375,0,0,1,.182,1.129,2.964,2.964,0,0,1-1.115,2.437A4.86,4.86,0,0,1,5808.326-8335.844Zm.991-6.956h-3.71c-.022-.039-.047-.083-.071-.13a3.034,3.034,0,0,1-.312-1.405,2.968,2.968,0,0,1,1.029-2.384,4.286,4.286,0,0,1,2.839-.868,6.722,6.722,0,0,1,1.7.212,12.23,12.23,0,0,1,1.693.593l-.781,1.884a11.374,11.374,0,0,0-1.513-.523,4.851,4.851,0,0,0-1.175-.149,1.565,1.565,0,0,0-1.056.32,1.069,1.069,0,0,0-.367.836,1.046,1.046,0,0,0,.148.56,1.621,1.621,0,0,0,.474.461,10.438,10.438,0,0,0,1.1.593Z"
        transform="translate(-5788.2 8377)"
      />
      <g transform="translate(13.875 34.7)" strokeWidth="1">
        <rect width="13.5" height="1.5" rx="0.75" stroke="none" />
        <rect x="0.5" y="0.5" width="12.5" height="0.5" rx="0.25" fill="none" />
      </g>
    </g>
  </SVG>
)

export default withIconStyles(IconStrike)
