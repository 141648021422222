import React from 'react'
import withIconStyles from '@edulastic/icons/src/HOC/withIconStyles'
import SVG from '@edulastic/icons/src/common/SVG'

const IconGraphLine = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="32.441"
    height="10.158"
    viewBox="0 0 32.441 10.158"
    {...props}
  >
    <path
      d="M403.121,320.743a1,1,0,0,1-.259-1.966L433.3,310.62a1,1,0,0,1,.517,1.932l-30.441,8.157A1.014,1.014,0,0,1,403.121,320.743Z"
      transform="translate(-402.121 -310.585)"
    />
  </SVG>
)

export default withIconStyles(IconGraphLine)
