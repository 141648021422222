export const STUDENT_SCALED_SCORE = {
  key: 'studentScaledScore',
  location: '/author/reports/student-scaled-score/student/',
  urlKey: 'student-scaled-score',
  title: 'Student Adaptive Scaled Score',
  description:
    'View Adaptive Scaled score for each student along with strengths, and areas for improvement.',
  sellDescription:
    'View Adaptive Scaled score for each student along with strengths, and areas for improvement.',
}

export const ADAPTIVE_REPORT_URLS = [STUDENT_SCALED_SCORE.location]

export const staticDropDownData = {
  filterSections: {
    TEST_FILTERS: { key: '0', title: 'Select Tests' },
    STUDENT_FILTERS: { key: '1', title: 'Select Classes' },
    PERFORMANCE_FILTERS: { key: '2', title: 'Performance' },
  },
  tagTypes: [
    // test filters
    { key: 'termId', tabKey: '0' },
    { key: 'testGrades', subType: 'test', tabKey: '0' },
    { key: 'assessmentTypes', tabKey: '0' },
    { key: 'testId1', tabKey: '0' },
    { key: 'testId2', tabKey: '0' },
    // class filters
    { key: 'assignedBy', tabKey: '1' },
    { key: 'schoolIds', tabKey: '1' },
    { key: 'teacherIds', tabKey: '1' },
    { key: 'grades', subType: 'class', tabKey: '1' },
    { key: 'subjects', subType: 'class', tabKey: '1' },
    { key: 'courseId', tabKey: '1' },
    { key: 'classIds', tabKey: '1' },
    { key: 'groupIds', tabKey: '1' },
    // performance filters
    { key: 'profileId', tabKey: '2' },
  ],
  subjects: [
    { key: 'Mathematics', title: 'Mathematics' },
    { key: 'ELA', title: 'ELA' },
    { key: 'Science', title: 'Science' },
    { key: 'Social Studies', title: 'Social Studies' },
    { key: 'Computer Science', title: 'Computer Science' },
    { key: 'Other Subjects', title: 'Other Subjects' },
  ],
  grades: [
    { key: 'TK', title: 'PreKindergarten' },
    { key: 'K', title: 'Kindergarten' },
    { key: '1', title: 'Grade 1' },
    { key: '2', title: 'Grade 2' },
    { key: '3', title: 'Grade 3' },
    { key: '4', title: 'Grade 4' },
    { key: '5', title: 'Grade 5' },
    { key: '6', title: 'Grade 6' },
    { key: '7', title: 'Grade 7' },
    { key: '8', title: 'Grade 8' },
    { key: '9', title: 'Grade 9' },
    { key: '10', title: 'Grade 10' },
    { key: '11', title: 'Grade 11' },
    { key: '12', title: 'Grade 12' },
    { key: 'O', title: 'Other' },
  ],
  assignedBy: [
    { key: 'anyone', title: 'Anyone' },
    { key: 'me', title: 'Me' },
  ],
  initialFilters: {
    termId: '',
    testGrades: [],
    assessmentTypes: [],
    testId1: '',
    testId2: '',
    assignedBy: 'anyone',
    schoolIds: [],
    teacherIds: [],
    grades: [],
    subjects: [],
    courseId: '',
    classIds: [],
    groupIds: [],
    profileId: '',
  },
  filtersDefaultValues: [
    { key: 'termId', value: '' },
    { key: 'schoolIds', value: '' },
    { key: 'teacherIds', value: '' },
    { key: 'courseId', value: '' },
    {
      key: '',
      nestedFilters: [
        { key: 'grades', value: '' },
        { key: 'subjects', value: '' },
        { key: 'classIds', value: '' },
        { key: 'groupIds', value: '' },
      ],
    },
  ],
}

const FILTER_KEYS_MAP = Object.keys(staticDropDownData.initialFilters).reduce(
  (res, ele) => ({ [ele]: ele, ...res }),
  {}
)

export const clearTestFilterKeys = [
  FILTER_KEYS_MAP.termId,
  FILTER_KEYS_MAP.testGrades,
  FILTER_KEYS_MAP.assessmentTypes,
]
