import {
  MULTIPLE_CHOICE,
  MULTIPLE_SELECTION,
  TRUE_OR_FALSE,
} from '@edulastic/constants/const/questionType'
import { cloneDeep, pick } from 'lodash'
import { processMcqStandardQuestion } from './aiQuestionToEduQuestion/processMcqStandard'
import { processMcqTrueAndFalse } from './aiQuestionToEduQuestion/processMcqTrueAndFalse'
import { processMSQ } from './aiQuestionToEduQuestion/processMsq'

export const processQuestionAndCreateItem = {
  [TRUE_OR_FALSE]: processMcqTrueAndFalse,
  [MULTIPLE_CHOICE]: processMcqStandardQuestion,
  [MULTIPLE_SELECTION]: processMSQ,
}

export const processAiGeneratedItems = ({
  questions,
  questionType,
  alignmentData,
  grades,
  subjects,
  existingQidToRegenerate,
  aiDocExtracted = false,
}) => {
  const testItems = []

  questions.forEach((question) => {
    const itemProcessor = processQuestionAndCreateItem[questionType]
    const item = itemProcessor(
      question,
      alignmentData,
      grades,
      subjects,
      aiDocExtracted
    )
    if (existingQidToRegenerate) {
      testItems.push({ ...item, _id: existingQidToRegenerate })
    } else {
      testItems.push(item)
    }
  })

  return testItems
}

export const getAlignmentDataForAiQuestions = ([alignment]) => {
  const {
    grades,
    subject,
    standards = [],
    curriculum: standardSet = '',
  } = alignment
  const standardIds = (standards || []).map(({ identifier }) => identifier)
  const standardDescriptions = (standards || []).map(
    ({ identifier = '', description = '' }) => ({
      id: identifier,
      description,
    })
  )
  return { grades, subject, standardIds, standardSet, standardDescriptions }
}
export const getAlignmentForEduItems = ([alignment], standardName) => {
  const { domains = [], standards = [], ...rest } = cloneDeep(alignment)
  const _domains = domains
    .filter(({ standards: sts }) =>
      sts.some(({ name }) => name === standardName)
    )
    .map((domain) => ({
      ...domain,
      standards: domain.standards.filter(({ name }) => name === standardName),
    }))

  const _standards = standards.filter(
    ({ identifier }) => identifier === standardName
  )

  return [
    {
      ...rest,
      domains: _domains,
      standards: _standards,
    },
  ]
}

export const formatStandard = (standard = {}) => {
  const formattedStandard = pick(standard, [
    '_id',
    'level',
    'grades',
    'identifier',
    'tloIdentifier',
    'tloId',
    'tloDescription',
    'eloId',
    'subEloId',
    'description',
    'curriculumId',
  ])
  return formattedStandard
}

export const getExistingQuestionContents = (assessment) => {
  const existingQuestionContent = []
  ;(assessment.itemGroups || []).forEach((itemGroup = []) => {
    const content = (itemGroup.items || [])
      .flatMap(({ data }) =>
        data?.questions.map(({ stimulus, questionDisplayTimestamp }) => ({
          name: stimulus,
          ...(questionDisplayTimestamp
            ? { displayAtSecond: questionDisplayTimestamp }
            : {}),
        }))
      )
      .filter(({ name }) => name)
    existingQuestionContent.push(...content)
  })
  return existingQuestionContent
}
