import React from 'react'
import JsxParser from 'react-jsx-parser/lib/react-jsx-parser.min'
import { Tooltip } from 'antd'

import { EduIf } from '@edulastic/common'
import {
  BannerActionButton,
  BannerInfo,
  CloseBannerIcon,
  Container,
  LeftContainer,
  RightContainer,
} from './styled'
import { getLibraryBannerTitle } from '../utils'

const LibraryBanner = ({ onClose, onClick, banner }) => {
  return (
    <Container $imageUrl={banner.imageUrl}>
      <LeftContainer>
        <BannerInfo>
          <EduIf condition={banner.title}>
            <JsxParser
              components={{ Tooltip }}
              renderInWrapper={false}
              jsx={getLibraryBannerTitle(banner.title)}
            />
          </EduIf>
        </BannerInfo>
      </LeftContainer>
      <RightContainer>
        <BannerActionButton
          height="25px"
          isGhost
          $buttonTextColor={banner.buttonTextColor}
          onClick={onClick}
        >
          {banner.buttonText}
        </BannerActionButton>
        <CloseBannerIcon
          title="close"
          height={10}
          width={10}
          onClick={onClose}
        />
      </RightContainer>
    </Container>
  )
}

export default LibraryBanner
