import styled from 'styled-components'
import { withKeyboard } from '@edulastic/common'

const getCellColor = (correct, theme) => {
  switch (correct) {
    case true:
      return theme.widgets.matrixChoice.correctCellInputWrapperBgColor
    case 'incorrect':
      return theme.widgets.matrixChoice.incorrectCellInputWrapperBgColor
    default:
      return ''
  }
}

export const Wrapper = withKeyboard(styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    !props.isPrintPreview && getCellColor(props.correct, props.theme)};
  padding: ${(props) => (props.smallSize ? 1 : 16)}px;
  position: relative;
  cursor: pointer;
  .inline-label {
    margin-right: ${({ isPrintPreview }) => (isPrintPreview ? '0px' : '10px')};
  }
  height: calc(100% - 4px);
  width: calc(100% - 4px);
`)
