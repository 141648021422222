import { Modal } from 'antd'
import React, { useState } from 'react'
import Menu from 'antd/lib/menu'
import { connect } from 'react-redux'
import { compose } from 'redux'
import {
  EduButton,
  EduElse,
  EduIf,
  EduThen,
  FlexContainer,
} from '@edulastic/common'
import { IconCaretDown, IconCollapse } from '@edulastic/icons'
import { themeColor } from '@edulastic/colors'
import styled from 'styled-components'
import produce from 'immer'
import RatingBox from '../RatingBoxContainer'
import {
  getCurrentRubricDataSelector,
  updateRubricDataAction,
} from '../../ducks'
import {
  StyledDropdownContainer,
  StyledPopoverContainer,
} from '../../../QuestionEditor/components/ChangeQType/styled'
import SelectCriteriaOption from './SelectCriteriaOption'
import {
  defaultAlignment,
  generateCriteriaData,
  generateRatingData,
} from './helper'
import StandardsBox from '../StandardsBox'

const MaximisedRubricModal = ({
  handleOnClickExpand,
  selectedCriteriaToExpand,
  selectedRatingToExpand,
  lastUpdatedRubricData,
  setLastUpdatedRubricData,
  currentRubricData,
  updateRubricData,
  isEditable,
}) => {
  const isFullScreenVisible = !!selectedRatingToExpand
  const isStandardView = selectedRatingToExpand === 'criteria-standards'
  const [isCriteriaDropdownVisible, setIsCriteriaDropdownVisible] = useState(
    false
  )

  const handleOnCancel = () => {
    updateRubricData(lastUpdatedRubricData)
    handleOnClickExpand('', '')
  }

  const currentCriteria = currentRubricData?.criteria?.find(
    (c) => c.id === selectedCriteriaToExpand
  )
  const currentRatingIndex = currentCriteria?.ratings?.findIndex(
    (r) => r.id === selectedRatingToExpand
  )

  const handleAddCriteria = () => {
    const newCriteria = generateCriteriaData(
      currentRubricData.criteria.length + 1
    )
    const newState = {
      ...currentRubricData,
      criteria: [...currentRubricData.criteria, newCriteria],
    }
    handleOnClickExpand(newCriteria.id, newCriteria?.ratings[0].id)
    setLastUpdatedRubricData(newState)
    updateRubricData(newState)
  }

  const handleDelete = () => {
    const updatedRubricData = produce(currentRubricData, (draft) => {
      draft.criteria.map((criteria) => {
        if (criteria.id === selectedCriteriaToExpand) {
          const ratings = criteria.ratings.filter(
            (r) => r.id !== selectedRatingToExpand
          )
          criteria.ratings = ratings
        }
        return criteria
      })
    })
    handleOnClickExpand(
      selectedCriteriaToExpand,
      currentRatingIndex > 0
        ? currentCriteria?.ratings[currentRatingIndex - 1].id
        : currentCriteria?.ratings[1].id
    )
    setLastUpdatedRubricData(updatedRubricData)
    updateRubricData(updatedRubricData)
  }
  const handleAddRating = () => {
    const newRating = generateRatingData(currentCriteria.ratings.length + 1)
    const clonedRubricData = {
      ...currentRubricData,
      criteria: currentRubricData.criteria.map((c) => ({
        ...c,
        ratings:
          c.id === selectedCriteriaToExpand
            ? [...c.ratings, newRating]
            : c.ratings,
      })),
    }
    handleOnClickExpand(selectedCriteriaToExpand, newRating.id)
    setLastUpdatedRubricData(clonedRubricData)
    updateRubricData(clonedRubricData)
  }

  const onUpdateAlignment = (newAlignment) => {
    const nextState = {
      ...currentRubricData,
      criteria: currentRubricData.criteria.map((c) => ({
        ...c,
        alignment:
          c.id === selectedCriteriaToExpand ? newAlignment : c.alignment,
      })),
    }
    updateRubricData(nextState)
  }

  const boxProps = isStandardView
    ? {
        parentId: selectedCriteriaToExpand,
        handleOnClickExpand,
        handleOnCancel,
        alignment: currentCriteria?.alignment || defaultAlignment,
        onUpdateAlignment,
      }
    : {
        className:
          currentRatingIndex === currentCriteria?.ratings?.length - 1
            ? 'last-rating'
            : '',
        id: selectedRatingToExpand,
        parentId: selectedCriteriaToExpand,
        isEditable,
        data: currentCriteria?.ratings[currentRatingIndex],
        allRatings: currentCriteria?.ratings,
        handleDelete,
        selectedRatingToExpand,
        handleOnClickExpand,
        handleOnCancel,
      }
  return (
    <StyledModal
      visible={isFullScreenVisible}
      wrapClassName="test-preview-modal"
      footer={null}
      header={null}
      onCancel={() => handleOnClickExpand('', '')}
      width="100%"
      closable={false}
      maskClosable={false}
    >
      <FlexContainer
        justifyContent="space-between"
        alignItems="center"
        padding="16px 32px"
        data-cy="criteriaName"
      >
        <StyledPopoverContainer
          placement="bottomLeft"
          trigger="click"
          visible={isCriteriaDropdownVisible}
          onVisibleChange={(value) => setIsCriteriaDropdownVisible(value)}
          content={
            <StyledPopoverContentContainer>
              <SelectCriteriaOption
                handleOnClickExpand={(c, r) => {
                  setLastUpdatedRubricData(currentRubricData)
                  handleOnClickExpand(c, r)
                }}
              />
            </StyledPopoverContentContainer>
          }
        >
          {currentCriteria?.name}
          <StyledDropdownContainer>
            <IconCaretDown height="12px" width="12px" color={themeColor} />
          </StyledDropdownContainer>
        </StyledPopoverContainer>
        <FlexContainer gap="20px">
          <EduButton
            isGhost
            onClick={() => handleAddCriteria()}
            data-cy="maximizedViewAddCriteria"
          >
            + New Criterion
          </EduButton>
          <StyledIconCollapse
            color={themeColor}
            onClick={() => handleOnClickExpand('', '')}
            height="34"
            width="34"
            data-cy="maximizedViewCollapseButton"
          />
        </FlexContainer>
      </FlexContainer>
      <StyledRatingCardWrapper
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        height="calc(100% - 68px)"
        padding="32px 0 0 0"
      >
        <StyledFullScreenRatingWrapper data-cy="ratingContainer">
          <StyledMenu
            data-cy="rating-selector"
            onClick={(event) => {
              if (event.key === 'add-rating') {
                handleAddRating()
              } else {
                setLastUpdatedRubricData(currentRubricData)
                handleOnClickExpand(selectedCriteriaToExpand, event.key)
              }
            }}
            selectedKeys={[selectedRatingToExpand]}
            mode="horizontal"
          >
            <Menu.Item
              key="criteria-standards"
              data-cy="maximizedViewAddStandards"
            >
              Standards
            </Menu.Item>
            {currentCriteria?.ratings.map((rating) => (
              <Menu.Item key={rating.id} data-cy="maximizedViewRatingTab">
                {rating.name}
              </Menu.Item>
            ))}
            <Menu.Item
              key="add-rating"
              style={{ color: themeColor }}
              data-cy="maximizedViewAddRatingButton"
            >
              + Add Rating
            </Menu.Item>
          </StyledMenu>
          {isFullScreenVisible && (
            <EduIf condition={isStandardView}>
              <EduThen>
                <StandardsBox {...boxProps} />
              </EduThen>
              <EduElse>
                <RatingBox {...boxProps} isFullScreen />
              </EduElse>
            </EduIf>
          )}
        </StyledFullScreenRatingWrapper>
      </StyledRatingCardWrapper>
    </StyledModal>
  )
}

const enhance = compose(
  connect(
    (state) => ({
      currentRubricData: getCurrentRubricDataSelector(state),
    }),
    {
      updateRubricData: updateRubricDataAction,
    }
  )
)

export default enhance(MaximisedRubricModal)

const StyledModal = styled(Modal)`
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;

  .ant-modal-close-x {
    display: none;
  }
  .ant-modal-header {
    display: none;
  }
  .ant-modal-content {
    height: 100vh;
    padding-top: 20px;
    bottom: auto;
    border-radius: 0;
    display: flex;
    width: 100%;
    padding: 0;
  }
  .ant-modal-body {
    padding: 0px;
    position: relative;
    width: 100%;
  }

  .exit-btn-row {
    margin-top: -10px;
    margin-bottom: 10px;
  }
`
const StyledFullScreenRatingWrapper = styled.div`
  max-width: 700px;
  width: 100%;
  position: relative;
`

const StyledRatingCardWrapper = styled(FlexContainer)`
  background: #f2f2f2;
`

const StyledMenu = styled(Menu)`
  position: relative;
  width: 100%;
  background: white;
  border-radius: 10px 10px 0 0;
  .ant-menu-item {
    z-index: 1;
    font-weight: bold;
  }
  .ant-menu-item:hover {
    color: ${themeColor};
    border-bottom: 4px solid ${themeColor};
  }
  .ant-menu-item-selected,
  .ant-menu-item-active {
    color: ${themeColor};
    border-bottom: 4px solid ${themeColor};
  }
`

const StyledPopoverContentContainer = styled.div`
  width: 250px;
  margin: -12px -16px;
`

const StyledIconCollapse = styled(IconCollapse)`
  margin-left: 15px;
  padding: 8px;
  outline: 1px solid ${themeColor};
  border-radius: 1px;
  cursor: pointer;
`
