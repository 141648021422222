/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconHamburger = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M2.25 13.5H15.75V12H2.25V13.5ZM2.25 9.75H15.75V8.25H2.25V9.75ZM2.25 4.5V6H15.75V4.5H2.25Z"
    />
  </SVG>
)

export default withIconStyles(IconHamburger)
