/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconAutoAssign = (props) => {
  const { fillColor } = props
  return (
    <SVG
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.8805 2.94585C14.4888 1.78752 12.7638 1.02919 10.8555 0.854187V2.52919C12.2971 2.68752 13.6138 3.26252 14.6971 4.12919L15.8805 2.94585Z"
        fill={fillColor || '#9501DB'}
      />
      <path
        d="M9.1888 2.52919V0.854187C7.28047 1.02085 5.55547 1.78752 4.1638 2.94585L5.34714 4.12919C6.43047 3.26252 7.74714 2.68752 9.1888 2.52919Z"
        fill={fillColor || '#9501DB'}
      />
      <path
        d="M4.17214 5.30419L2.9888 4.12085C1.83047 5.51252 1.07214 7.23752 0.897135 9.14585H2.57214C2.73047 7.70419 3.30547 6.38752 4.17214 5.30419Z"
        fill={fillColor || '#9501DB'}
      />
      <path
        d="M17.4721 9.14585H19.1471C18.9721 7.23752 18.2138 5.51252 17.0555 4.12085L15.8721 5.30419C16.7388 6.38752 17.3138 7.70419 17.4721 9.14585Z"
        fill={fillColor || '#9501DB'}
      />
      <path
        d="M5.85547 9.97919L8.72214 11.2792L10.0221 14.1459L11.3221 11.2792L14.1888 9.97919L11.3221 8.67919L10.0221 5.81252L8.72214 8.67919L5.85547 9.97919Z"
        fill={fillColor || '#9501DB'}
      />
      <path
        d="M10.0221 17.4792C7.43047 17.4792 5.14714 16.1542 3.80547 14.1459H5.85547V12.4792H0.855469V17.4792H2.52214V15.2292C4.18047 17.5959 6.9138 19.1459 10.0221 19.1459C14.0805 19.1459 17.5221 16.5042 18.7221 12.8459L17.0888 12.4709C16.0638 15.3792 13.2888 17.4792 10.0221 17.4792Z"
        fill={fillColor || '#9501DB'}
      />
    </SVG>
  )
}

export default withIconStyles(IconAutoAssign)
