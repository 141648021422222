import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconDemoAccGreen = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="11.634"
    height="20.664"
    viewBox="0 0 11.634 20.664"
  >
    <g transform="translate(-21.089 -0.001)">
      <path
        d="M32.564,13.955l-1.936-1.567a16.5,16.5,0,0,0,.426-3.8c0-4.274-3.008-8.214-4.007-8.561a.43.43,0,0,0-.281,0c-1,.347-4.007,4.287-4.007,8.561a16.473,16.473,0,0,0,.426,3.8l-1.936,1.567a.429.429,0,0,0-.155.389l.79,5.949a.428.428,0,0,0,.753.218l3.086-3.688a2.2,2.2,0,0,0,2.37,0l3.085,3.688a.428.428,0,0,0,.753-.218l.79-5.949A.427.427,0,0,0,32.564,13.955ZM26.906,8.482A1.713,1.713,0,1,1,28.619,6.77,1.713,1.713,0,0,1,26.906,8.482Z"
        transform="translate(0 0)"
        fill="#1ab395"
      />
    </g>
  </SVG>
)

export default withIconStyles(IconDemoAccGreen)
