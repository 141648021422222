import { Dropdown, Menu } from 'antd'
import React, { useState } from 'react'
import { segmentApi } from '@edulastic/api'
import { AIEnhanceQuestionButtonIcon } from '@edulastic/icons/src/AIEnhanceQuestionButtonIcon'
import { QUESTION_ENHANCE_PROMPTS } from '../constants/prompt'
import { StyledMenuItem } from './styled'

const AiQuestionActionMenu = ({ onOptionSelected, source }) => (
  <Menu selectedKeys={[]}>
    {QUESTION_ENHANCE_PROMPTS.map((option) => {
      return (
        <StyledMenuItem
          key={option.key}
          value={option.value}
          onClick={({ key }) => {
            onOptionSelected(key)

            segmentApi.genericEventTrack('AIQuestionEnhanceOptionClick', {
              source,
              option: key,
            })
          }}
          data-cy={`menu-item-${option.key}`}
        >
          {option.label}
        </StyledMenuItem>
      )
    })}
  </Menu>
)

const AIQuestionActionButton = ({
  onOptionSelected,
  source = 'Review Test',
  height,
  width,
  marginLeft = '0px',
}) => {
  const [dropDownVisible, setDropDownVisible] = useState(false)

  return (
    <Dropdown
      overlay={
        <AiQuestionActionMenu
          onOptionSelected={onOptionSelected}
          source={source}
        />
      }
      onVisibleChange={setDropDownVisible}
      getPopupContainer={(trigger) => trigger.parentElement}
    >
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        <div title="AI Enhance" data-cy="aiEnhance" style={{ marginLeft }}>
          <AIEnhanceQuestionButtonIcon
            height={height}
            width={width}
            hovered={dropDownVisible}
          />
        </div>
      </a>
    </Dropdown>
  )
}

export default AIQuestionActionButton
