/* eslint-disable react/prop-types */
import { passageApi, segmentApi, testItemsApi } from '@edulastic/api'
import { red, themeColor, white, title } from '@edulastic/colors'
import {
  EduIf,
  EduButton,
  FlexContainer,
  notification,
  withWindowSizes,
} from '@edulastic/common'
import {
  questionType,
  roleuser,
  collections as collectionConst,
  test as testConstants,
  appLanguages,
} from '@edulastic/constants'
import {
  IconClose,
  IconCollapse,
  IconCopy,
  IconExpand,
  IconPencilEdit,
  IconTrash,
  IconClear,
} from '@edulastic/icons'
import { withNamespaces } from '@edulastic/localization'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Icon, Modal, Spin, Popover, Tooltip } from 'antd'
import { cloneDeep, get, intersection, isEmpty, keyBy, uniq } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import styled, { css } from 'styled-components'
import {
  QUE_TYPE_BY_TITLE,
  TTS_ENABLED_QUESTION_TYPES,
} from '@edulastic/constants/const/questionType'
import produce from 'immer'
import { LANGUAGES_OPTIONS } from '@edulastic/constants/const/languages'
import { languageByCode } from '@edulastic/constants/const/test'
import SelectGroupModal from '../../../../TestPage/components/AddItems/SelectGroupModal'
import { SMALL_DESKTOP_WIDTH } from '../../../../../assessment/constants/others'
import { Nav } from '../../../../../assessment/themes/common'
import FeaturesSwitch from '../../../../../features/components/FeaturesSwitch'
import { getAssignmentsSelector } from '../../../../AssignTest/duck'
import {
  clearItemDetailAction,
  deleteItemAction,
  getItemDeletingSelector,
} from '../../../../ItemDetail/ducks'
import {
  addItemToCartAction,
  approveOrRejectSingleItem as approveOrRejectSingleItemAction,
} from '../../../../ItemList/ducks'
import { setTestItemsAction } from '../../../../TestPage/components/AddItems/ducks'
import {
  getTestSelector,
  setTestDataAndUpdateAction,
  updateTestAndNavigateAction,
  setPassageItemsAction,
  setAndSavePassageItemsAction,
  setCurrentGroupIndexAction,
  setTestDataAction,
  isDynamicTestSelector,
  isSectionAdaptiveTestSelector,
  getEquivalentItemsSelector,
  setReplaceItemIdAction,
  setReplaceItemsAction,
  setModalVisibilityAction,
} from '../../../../TestPage/ducks'
import { clearAnswersAction } from '../../../actions/answers'
import { changePreviewAction, changeViewAction } from '../../../actions/view'
import {
  getCollectionsSelector,
  getUserFeatures,
  getUserRole,
  getWritableCollectionsSelector,
} from '../../../selectors/user'
import {
  allowDuplicateCheck,
  allowContentEditCheck,
  isContentOfCollectionEditable,
} from '../../../utils/permissionCheck'
import ScoreBlock from '../ScoreBlock'
import AuthorTestItemPreview from './AuthorTestItemPreview'
import {
  addPassageAction,
  archivedItemsSelector,
  clearPreviewAction,
  duplicateTestItemPreviewRequestAction,
  editNonAuthoredItemAction,
  getItemDetailSelectorForPreview,
  getPassageSelector,
  setPrevewItemAction,
  setQuestionsForPassageAction,
  passageItemIdsSelector,
  verifyItemUsedInTestAndProceedAction,
  getIsDraftItemCloningInProgressSelector,
} from './ducks'
import ReportIssue from './ReportIssue'
import {
  ButtonsWrapper,
  ModalContentArea,
  PreviewPopupOverlay,
  RejectButton,
  Title,
} from './styled'
import { aiTestActions } from '../../../../AssessmentCreate/components/CreateAITest/ducks'
import {
  fetchTTSTextAction,
  setTTSTextStateAction,
  updateTTSTextAction,
} from '../../../actions/ttsText'
import {
  getTTSTextAPIStatusSelector,
  getTTSTextResultSelector,
  updateTTSTextAPIStatusSelector,
} from '../../../selectors/ttsText'
import CustomizeTTSModal from './CustomizeTTSModal'
import { TTS_NAV_NEXT, pageType } from './constants'
import {
  isManuallyGradableItemAndNotify,
  isSectionsAdaptiveTestItemsLimitExceededAndNotify,
} from '../../../../TestPage/utils'
import ReplaceItemsNav from '../../../../TestPage/components/Review/components/ReplaceItemsNav'
import { updatedItemGroupsAfterReplace } from '../../../../dataUtils'
import AIQuestionActionButton from '../../../../AIFeatures/common/AIQuestionActionButton'

const {
  ITEM_GROUP_TYPES,
  ITEM_GROUP_DELIVERY_TYPES,
  testCategoryTypes,
} = testConstants

const getVoiceLanguageByCode = (languageCode) =>
  (languageByCode?.[languageCode] || '')?.toLowerCase()

const { ENGLISH, LANGUAGE_EN } = appLanguages

const getQuestions = (items) => {
  const allQuestions = items.flatMap((item) => {
    return item.data.questions || []
  })
  return keyBy(allQuestions, 'id')
}

class PreviewModal extends React.Component {
  constructor(props) {
    super(props)
    const { item } = props
    this.state = {
      flag: false,
      passageLoading: false,
      showHints: false,
      showReportIssueField: false,
      fullModal: false,
      isRejectMode: false,
      showSelectGroupModal: false,
      showTTSTextModal: false,
      selectedLanguage: LANGUAGE_EN,
      isAddingSinglePassageItem: false,
      voiceLanguage: getVoiceLanguageByCode(item?.language) || ENGLISH,
      modalDraggable: false,
      currentQuestionIndex: 0,
    }
  }

  get ttsCustomizableData() {
    const { item = {} } = this.props
    return produce(item?.data || {}, (draft) => {
      draft.questions = (draft?.questions || [])?.filter(({ type }) =>
        TTS_ENABLED_QUESTION_TYPES.includes(type)
      )
    })
  }

  notificationStringConst = {
    enterValidPassword: 'enterValidPassword',
    itemCantBeAdded: 'itemCantBeAdded',
    nameShouldNotEmpty: 'nameShouldNotEmpty',
    itemAddedTest: 'itemAddedTest',
    itemRemovedTest: 'itemRemovedTest',
  }

  componentDidMount() {
    const { item } = this.props
    if (item.language) {
      const itemLanguageLower = item.language.toLowerCase()

      const languageCode = LANGUAGES_OPTIONS.find(
        (o) =>
          o.label.toLowerCase() === itemLanguageLower ||
          o.value.toLowerCase() === itemLanguageLower
      )?.value
      this.setState({ selectedLanguage: languageCode })
    }

    if (item.passageId) {
      this.loadPassage(item.passageId)
    }

    this.setIsAddingSinglePassageItem(false)

    const { ttsTextAPIStatus, setTTSTextState } = this.props

    if (ttsTextAPIStatus) {
      setTTSTextState({
        apiStatus: false,
        result: [],
        TTSUpdateData: {
          apiStatus: false,
        },
      })
    }
  }

  loadPassage(passageId) {
    /**
     * FIXME: move this to redux-saga
     */
    const { addPassage, setPassageTestItems } = this.props
    this.setState({ passageLoading: true })
    try {
      passageApi.getById(passageId).then((response) => {
        addPassage(response)
        this.setState({ passageLoading: false })
      })
      testItemsApi.getPassageItems(passageId).then((passageItems) => {
        passageItems = passageItems.filter((item) => item?.active)
        setPassageTestItems(passageItems)
      })
    } catch (e) {
      this.setState({ passageLoading: false })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      item: newItem,
      archivedItems: oldArchivedItems,
      updateCurrentItemFromPassagePagination,
      passage,
      clearPreview,
      updateTTSAPIStatus,
    } = this.props

    const { item: oldItem, archivedItems: newArchivedItems } = prevProps
    if (oldItem?.passageId !== newItem?.passageId && newItem?.passageId) {
      this.loadPassage(newItem.passageId)
    }
    /** Watching changes in "testsAddItems.archivedItems"
     * and updating testItemPreview for passages
     * */
    if (oldArchivedItems?.length !== newArchivedItems?.length) {
      const { testItems = [] } = passage || {}
      if (testItems.length) {
        updateCurrentItemFromPassagePagination(testItems[0])
      } else {
        clearPreview()
      }
    }

    if (
      (this.ttsCustomizableData?.questions || []).length === 1 &&
      prevProps?.updateTTSAPIStatus !== updateTTSAPIStatus &&
      updateTTSAPIStatus === 'SUCCESS'
    ) {
      this.toggleTTSTextModal()
    }
  }

  componentWillUnmount() {
    const {
      clearAnswers,
      setReplaceItemId,
      setReplaceItems,
      handlePaginationChange,
      page,
      setModalVisibility,
    } = this.props
    clearAnswers()
    if (page === 'replaceItem') {
      setReplaceItemId(null)
      setReplaceItems([])
      handlePaginationChange(1)
    }

    // resetting modal visibility to false
    setModalVisibility(false)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { flag } = this.state
    const { isVisible } = nextProps
    if (isVisible && !flag) {
      this.setState({ flag: true })
    }
  }

  closeModal = (reset) => {
    const {
      onClose,
      changePreviewMode,
      clearPreview,
      replaceItemId,
      page,
      setReplaceItemId,
    } = this.props
    this.setState({ flag: false })
    clearPreview()
    changePreviewMode('clear')
    onClose()
    if (replaceItemId && page === 'addItems' && reset == true) {
      setReplaceItemId(null)
    }
  }

  onRegenerateTestItem = () => {
    const { regenerateTestItem, item, groupIndex = 0 } = this.props
    const {
      alignment,
      grades,
      subjects,
      stimulus,
      depthOfKnowledge,
      authorDifficulty,
      title: questionTitle,
    } = item?.data?.questions[0] || {}

    const { _id } = item

    if (
      [_id, alignment, grades, subjects, stimulus, questionTitle].every(
        (x) => !isEmpty(x)
      )
    ) {
      regenerateTestItem({
        itemType: QUE_TYPE_BY_TITLE[questionTitle],
        numberOfItems: 1,
        dok: [depthOfKnowledge],
        difficulty: [authorDifficulty],
        alignment,
        existingQidToRegenerate: _id,
        groupIndex,
      })
    } else {
      notification({
        type: 'warn',
        msg: 'Facing some issue while regenerating this Question.',
      })
    }

    segmentApi.genericEventTrack('GenerateAIItem', {
      source: 'AI Quiz: Regenerate',
    })
  }

  handleDuplicateTestItem = async () => {
    const {
      data,
      testId,
      duplicateTestItem,
      test,
      match,
      isTest = !!testId,
      passage,
      item = {},
      isPlaylistTestReview,
      playlistId,
      groupIndex,
      hasSections,
      setSectionsTestSetGroupIndex,
      setShowSectionsTestSelectGroupIndexModal,
    } = this.props
    const itemId = data.id
    const regradeFlow = match.params.oldId && match.params.oldId !== 'undefined'
    if (!passage || !item?.passageId) {
      if (hasSections) {
        setSectionsTestSetGroupIndex(groupIndex)
        setShowSectionsTestSelectGroupIndexModal(true)
      }
      return duplicateTestItem({
        data,
        testId,
        test,
        match,
        isTest,
        itemId,
        regradeFlow,
        isPlaylistTestReview,
        playlistId,
      })
    }

    const { _id: currentItem = '' } = item

    const handleCompletePassageClick = () => {
      duplicateTestItem({
        data,
        testId,
        test,
        match,
        isTest,
        itemId,
        regradeFlow,
        passage,
        duplicateWholePassage: true,
        isPlaylistTestReview,
        playlistId,
      })
      Modal.destroyAll()
      this.closeModal()
    }

    const handleCurrentItemClick = () => {
      duplicateTestItem({
        data,
        testId,
        test,
        match,
        isTest,
        itemId,
        regradeFlow,
        passage,
        currentItem,
        isPlaylistTestReview,
        playlistId,
      })
      Modal.destroyAll()
      this.closeModal()
    }

    // show confirmation message only if more than one item to be cloned
    if (passage.testItems.length > 1) {
      const bodyContent = (
        <>
          <p>
            This passage has {passage.testItems.length} Items associated with
            it. Would you like to clone complete passage or a single item?
          </p>
          <FlexContainer justifyContent="flex-end" mt="24px">
            <EduButton
              isGhost
              onClick={handleCompletePassageClick}
              data-cy="completePassageBtn"
            >
              Complete Passage
            </EduButton>
            <EduButton
              onClick={handleCurrentItemClick}
              data-cy="currentItemBtn"
            >
              Current Item
            </EduButton>
          </FlexContainer>
        </>
      )

      Modal.confirm({
        title: 'Clone Passage Item',
        content: bodyContent,
        onCancel: () => this.setState({ flag: false }),
        centered: true,
        width: 500,
        className: 'passage-clone-modal',
      })
    } else {
      handleCompletePassageClick()
    }

    // const duplicatedItem = await duplicateTestItem(itemId);

    // if (isTest) {
    //   updateTestAndNavigate({
    //     pathname: `/author/tests/${testId}/editItem/${duplicatedItem._id}`,
    //     fadeSidebar: true,
    //     regradeFlow,
    //     previousTestId: test.previousTestId,
    //     testId,
    //     isDuplicating: true,
    //     passage
    //   });
    // } else {
    //   history.push(`/author/items/${duplicatedItem._id}/item-detail`);
    // }
  }

  // this is the one need to be modified
  editTestItem = () => {
    const {
      data,
      history,
      testId,
      clearItemStore,
      changeView,
      updateTestAndNavigate,
      test,
      isTest = !!testId,
      editNonAuthoredItem,
      isEditable,
      item,
      userId,
      testAssignments,
      userRole,
      writableCollections,
      collections,
      userFeatures,
      passage,
      isSectionAdaptiveTest,
      verifyItemUsedInTestAndProceed,
      page,
    } = this.props

    const itemId = data.id
    const regradeFlow = !!test?._id && testAssignments.length && test.isUsed
    const isOwner = item?.authors?.some((author) => author._id === userId)
    const hasCollectionAccess = allowContentEditCheck(
      item?.collections,
      writableCollections
    )
    const isCollectionContentEditable = isContentOfCollectionEditable(
      item?.collections,
      writableCollections
    )
    const allowDuplicate = allowDuplicateCheck(
      item?.collections,
      collections,
      'item'
    )
    const isDisableEdit = !(
      (isEditable && isOwner) ||
      userRole === roleuser.EDULASTIC_CURATOR ||
      (hasCollectionAccess && userFeatures.isCurator) ||
      isCollectionContentEditable
    )

    // change the question editor view to "edit"
    changeView('edit')
    // itemDetail store has leftovers from previous visit to the page,
    // clearing it before navigation.

    clearItemStore()

    const isCuratorUser =
      userFeatures?.isCurator || userRole === roleuser.EDULASTIC_CURATOR

    if (
      (item.status === 'draft' || item.passageId) &&
      page === 'review' &&
      !isCuratorUser &&
      !isDisableEdit &&
      !regradeFlow
    ) {
      verifyItemUsedInTestAndProceed({
        itemId,
        testId,
        regradeFlow,
        passageId: item.passageId,
      })
      return
    }
    if (isDisableEdit && isTest && allowDuplicate) {
      let passageItems = passage && passage.testItems
      const passageId = passage && passage._id
      if (passageItems && passageId) {
        const testItemIds = test.itemGroups.flatMap((group) =>
          group.items.map((ele) => ele._id)
        )
        passageItems = passageItems.filter((ele) => testItemIds.includes(ele))
      }

      return editNonAuthoredItem({
        itemId,
        testId,
        replaceOldItem: true,
        passageItems,
        passageId,
        regradeFlow,
      })
    }
    if (isTest) {
      const payload = {
        pathname: `/author/tests/${testId}/editItem/${itemId}`,
        fadeSidebar: 'false',
        regradeFlow,
        testId,
        isEditing: true,
        isSectionAdaptiveTest,
      }
      if (testId !== test.previousTestId) {
        payload.previousTestId = test.previousTestId
      }
      updateTestAndNavigate(payload)
    } else {
      history.push(`/author/items/${itemId}/item-detail`)
    }
  }

  clearView = () => {
    const { changePreviewMode, clearAnswers } = this.props
    changePreviewMode('clear')
    clearAnswers()
  }

  goToItem = (page) => {
    const {
      setQuestionsForPassage,
      setPrevewItem,
      item,
      testItemPreviewData,
      passageItemIds = [],
      updateCurrentItemFromPassagePagination,
    } = this.props
    const itemId = passageItemIds[page - 1]
    if (!(testItemPreviewData && testItemPreviewData.data)) {
      setPrevewItem(item)
    }
    testItemsApi.getById(itemId).then((response) => {
      if (response?._id && updateCurrentItemFromPassagePagination) {
        /**
         * Whenever we are changing the item using the navigation in the passage
         * we need to update the state in the ItemListContainer component as well
         * why? @see https://snapwiz.atlassian.net/browse/EV-15223
         */
        updateCurrentItemFromPassagePagination(response._id)
      }
      setQuestionsForPassage(response)
    })
  }

  handleSelectGroupModalResponse = (index) => {
    const { setCurrentGroupIndex, item } = this.props
    const { isAddingSinglePassageItem } = this.state
    if (index || index === 0) {
      setCurrentGroupIndex(index)
      if (item.passageId && !isAddingSinglePassageItem) {
        this.handleAddPassageItemsToSection(index)
      } else {
        this.handleDynamicTestSelection(index)
      }
    }
    this.setState({ showSelectGroupModal: false })
  }

  handleAddPassageItemsToSection = (selectedCurrentGroupIndex) => {
    const {
      passageItems,
      page,
      setAndSavePassageItems,
      isSectionAdaptiveTest,
      currentGroupIndexValueFromStore,
      test,
    } = this.props
    if (isSectionAdaptiveTest) {
      if (isManuallyGradableItemAndNotify(passageItems)) {
        return
      }
      const _currentGroupIndex =
        typeof selectedCurrentGroupIndex === 'number'
          ? selectedCurrentGroupIndex
          : currentGroupIndexValueFromStore
      const isItemLimitExceeded = isSectionsAdaptiveTestItemsLimitExceededAndNotify(
        {
          itemGroup: test?.itemGroups?.[_currentGroupIndex] || {},
          adaptiveDeliveryData: test?.adaptiveDelivery || {},
          itemsToBeAdded: passageItems,
          isBaseSection: _currentGroupIndex === 0,
        }
      )
      if (isItemLimitExceeded) {
        return
      }
    }
    setAndSavePassageItems({ passageItems, page, remove: false })
  }

  handleDynamicTestSelection = async (selectedCurrentGroupIndex) => {
    const {
      setTestItems,
      setDataAndSave,
      selectedRows,
      test,
      gotoSummary,
      item,
      page,
      current,
      isSectionAdaptiveTest,
      currentGroupIndexValueFromStore,
    } = this.props
    if (!test.title?.trim().length && page !== pageType.itemList) {
      gotoSummary()
      return notification({
        messageKey: this.notificationStringConst.nameShouldNotEmpty,
      })
    }

    let keys = []
    if (test.safeBrowser && !test.sebPassword) {
      return notification({
        messageKey: this.notificationStringConst.enterValidPassword,
      })
    }

    if (selectedRows !== undefined) {
      ;(selectedRows || []).forEach((selectedRow, index) => {
        keys[index] = selectedRow
      })
    }
    if (!keys.includes(item._id)) {
      keys[keys.length] = item._id
      if (isSectionAdaptiveTest) {
        if (isManuallyGradableItemAndNotify([item])) {
          return
        }
        const _currentGroupIndex =
          typeof selectedCurrentGroupIndex === 'number'
            ? selectedCurrentGroupIndex
            : currentGroupIndexValueFromStore
        const isItemLimitExceeded = isSectionsAdaptiveTestItemsLimitExceededAndNotify(
          {
            itemGroup: test?.itemGroups?.[_currentGroupIndex] || {},
            adaptiveDeliveryData: test?.adaptiveDelivery || {},
            itemsToBeAdded: [item],
            isBaseSection: _currentGroupIndex === 0,
          }
        )
        if (isItemLimitExceeded) {
          return
        }
      }
      setDataAndSave({ addToTest: true, item, current })
      notification({
        type: 'success',
        messageKey: this.notificationStringConst.itemAddedTest,
      })
    } else {
      keys = keys.filter((_item) => _item !== item._id)
      setDataAndSave({ addToTest: false, item: { _id: item._id }, current })
      notification({
        type: 'success',
        messageKey: this.notificationStringConst.itemRemovedTest,
      })
    }
    setTestItems(keys)
  }

  handleAddToSection = (staticGroups, item) => {
    const {
      test: { itemGroups },
      setCurrentGroupIndex,
      showSelectGroupIndexModal,
      currentGroupIndexValueFromStore,
    } = this.props
    /**
     * For sections test if group index is known, directly add the item to respective index
     * without setting index in store
     * showSelectGroupIndexModal - this value is always "true" for all other tests except sections test
     */
    if (
      staticGroups?.length > 1 &&
      !showSelectGroupIndexModal &&
      typeof currentGroupIndexValueFromStore === 'number'
    ) {
      this.handleSelectGroupModalResponse(currentGroupIndexValueFromStore)
      return
    }

    if (staticGroups?.length === 1) {
      const index = itemGroups.findIndex(
        (g) => g.groupName === staticGroups[0].groupName
      )
      if (
        itemGroups[index]?.deliveryType ===
          ITEM_GROUP_DELIVERY_TYPES.LIMITED_RANDOM &&
        item.itemLevelScoring === false
      ) {
        return notification({ type: 'warn', messageKey: 'itemCantBeAdded' })
      }
      setCurrentGroupIndex(index)
      this.handleSelection()
    } else if (staticGroups.length > 1) {
      this.setState({ showSelectGroupModal: true })
    } else {
      return notification({
        type: 'warn',
        messageKey: 'noStaticGroupFound',
      })
    }
  }

  handleRemoveOne = (itemId) => {
    const { test, setTestData, setTestItems } = this.props
    const newData = cloneDeep(test)

    const itemsSelected = newData.itemGroups
      .flatMap((itemGroup) => itemGroup.items || [])
      .find((item) => item._id === itemId)

    if (!itemsSelected) {
      return notification({
        type: 'warn',
        messageKey: 'pleaseSelectAtleastOneQuestion',
      })
    }

    newData.itemGroups = newData.itemGroups.map((itemGroup) => ({
      ...itemGroup,
      items: itemGroup.items.filter(
        (testItem) => testItem._id !== itemsSelected._id
      ),
    }))

    newData.scoring.testItems = newData.scoring.testItems.filter((item) =>
      newData.itemGroups
        .flatMap((itemGroup) => itemGroup.items || [])
        .find(({ id }) => id === item._id && itemsSelected._id !== id)
    )

    const testItems = newData.itemGroups.flatMap(
      (itemGroup) => itemGroup.items || []
    )

    setTestItems(testItems.map((item) => item._id))
    setTestData(newData)
    this.handleSelection(false)
  }

  toggleTTSTextModal = () => {
    const [question] = this.ttsCustomizableData?.questions || []
    const callBack = () => this.requestToGetTTSText({ isLanguageChanged: true })
    const selectedLanguage = this.getAvailableLanguageCode(question)
    this.setState(({ showTTSTextModal: prevShowTTSTextModal }) => {
      return {
        ...prevShowTTSTextModal,
        showTTSTextModal: !prevShowTTSTextModal,
        currentQuestionIndex: 0,
        selectedLanguage,
        voiceLanguage: getVoiceLanguageByCode(selectedLanguage),
      }
    }, callBack)
  }

  onLanguageChange = (value) => {
    const { selectedLanguage: prevSelectedLanguage } = this.state
    if (prevSelectedLanguage !== value) {
      this.setState(
        {
          selectedLanguage: value,
          voiceLanguage: getVoiceLanguageByCode(value),
        },
        () => this.requestToGetTTSText({ isLanguageChanged: true })
      )
    }
  }

  onChangeVoiceLanguge = (value) => {
    this.setState({ voiceLanguage: value })
  }

  requestToGetTTSText = ({
    updateTTSText = false,
    isLanguageChanged = false,
  }) => {
    const {
      item: { _id: itemId = {} },
      fetchTTSText,
      ttsTextResult,
    } = this.props
    const { selectedLanguage, currentQuestionIndex } = this.state
    const ttsCustomizableQuestions = this.ttsCustomizableData?.questions
    const questionId = ttsCustomizableQuestions?.[currentQuestionIndex]?.id

    if (questionId) {
      let language = selectedLanguage
      if (
        !ttsCustomizableQuestions?.[currentQuestionIndex]?.languageFeatures?.[
          selectedLanguage
        ]
      ) {
        language = LANGUAGE_EN
      }
      const requestData = {
        itemId,
        questionId,
        updateTTSText,
        language,
      }

      if (isEmpty(ttsTextResult) || updateTTSText || isLanguageChanged) {
        fetchTTSText(requestData)
      }
    }
  }

  regenerateTTSText = () => {
    this.requestToGetTTSText({ updateTTSText: true })
  }

  updateQuestionTTSText = (updatedTTSTextData) => {
    const {
      item: { _id: itemId },
      updateTTSText,
    } = this.props
    const { selectedLanguage, voiceLanguage, currentQuestionIndex } = this.state
    const ttsCustomizableQuestions = this.ttsCustomizableData?.questions
    const questionId = ttsCustomizableQuestions?.[currentQuestionIndex]?.id

    if (questionId) {
      let language = selectedLanguage
      if (
        !ttsCustomizableQuestions?.[currentQuestionIndex]?.languageFeatures?.[
          selectedLanguage
        ]
      ) {
        language = LANGUAGE_EN
      }
      const requestData = {
        itemId,
        questionId,
        data: updatedTTSTextData,
        language,
        voiceLanguage,
      }

      updateTTSText(requestData)
    }
  }

  handleAddOrRemove = () => {
    const {
      item,
      test,
      test: { itemGroups },
      hasSections,
      isDynamicTest,
    } = this.props

    if (item?.unsavedItem) {
      return this.handleRemoveOne(item?._id)
    }

    const staticGroups = (itemGroups || []).filter(
      (g) => g.type === ITEM_GROUP_TYPES.STATIC
    )

    if (hasSections || isDynamicTest) {
      const isAdding = this.isAddOrRemove
      /**
       * set the following state as there is no way to figure out whether user is
       * adding single passage item or all passage items, in handleSelectGroupModalResponse method
       */
      if (isAdding) {
        if (item.passageId) {
          this.setState({ isAddingSinglePassageItem: true }, () => {
            this.handleAddToSection(staticGroups, item)
          })
          return
        }
        this.handleAddToSection(staticGroups, item)
        return
      }
      this.handleSelection()
      return
    }

    if (
      test.testCategory !== testCategoryTypes.DYNAMIC_TEST ||
      item.passageId
    ) {
      this.handleSelection()
    } else if (this.isAddOrRemove) {
      this.handleAddToSection(staticGroups, item)
    } else {
      this.handleDynamicTestSelection()
    }
  }

  handleSelection = (shouldSave = true) => {
    const {
      setDataAndSave,
      selectedRows,
      addItemToCart,
      test,
      gotoSummary,
      item,
      setTestItems,
      page,
      setNextPreviewItem,
      replaceItemId,
      setTestData,
      history,
    } = this.props
    if (page === 'replaceItem' || (replaceItemId && page === 'addItems')) {
      setTestData({
        itemGroups: updatedItemGroupsAfterReplace(
          test.itemGroups,
          replaceItemId,
          item
        ),
      })
      this.closeModal(true)
      if (page === 'addItems') {
        history.push(`/author/tests/tab/review/id/${test._id}`)
      }
      return
    }
    if (page === 'itemList') {
      return addItemToCart(item)
    }
    if (!test?.title?.trim()?.length && page !== 'itemList') {
      this.closeModal()
      gotoSummary()
      console.log('Reaching here')
      notification({ messageKey: 'nameShouldNotEmpty' })
    }
    let keys = [...(selectedRows || [])]
    if (test.safeBrowser && !test.sebPassword) {
      notification({ messageKey: 'enterValidPassword' })
      return
    }
    if (!keys.includes(item?._id)) {
      keys[keys.length] = item?._id
      setDataAndSave({ addToTest: true, item })
      notification({ type: 'success', messageKey: 'itemAddedTest' })
    } else {
      if (page === 'review') {
        setNextPreviewItem(item._id)
      }
      keys = (keys || []).filter((key) => key !== item?._id)
      if (shouldSave) {
        setDataAndSave({ addToTest: false, item: { _id: item?._id } })
      }
      notification({ type: 'success', messageKey: 'itemRemovedTest' })
    }
    setTestItems(keys)
    if (page === 'review' && keys.length === 0) {
      this.closeModal()
    }
  }

  handleAddMultiplePassageItemsToSections = () => {
    const {
      passageItems,
      passageItemIds = [],
      test: { itemGroups },
      showSelectGroupIndexModal,
      currentGroupIndexValueFromStore,
      setCurrentGroupIndex,
      setAndSavePassageItems,
      page,
      selectedRows,
    } = this.props

    const hasPassageItemToAdd = (passageItemIds || []).some(
      (x) => !selectedRows.includes(x)
    )

    // remove all passage items from test
    if (!hasPassageItemToAdd) {
      setAndSavePassageItems({ passageItems, page, remove: true })
      return
    }

    const staticGroups = (itemGroups || []).filter(
      (g) => g.type === ITEM_GROUP_TYPES.STATIC
    )

    if (
      staticGroups?.length > 1 &&
      !showSelectGroupIndexModal &&
      typeof currentGroupIndexValueFromStore === 'number'
    ) {
      setAndSavePassageItems({ passageItems, page, remove: false })
      return
    }

    this.setIsAddingSinglePassageItem(false)

    if (staticGroups?.length === 1) {
      const index = itemGroups.findIndex(
        (g) => g.groupName === staticGroups[0].groupName
      )
      setCurrentGroupIndex(index)
      setAndSavePassageItems({ passageItems, page, remove: false })
    } else if (staticGroups.length > 1) {
      this.setState({ showSelectGroupModal: true })
    }
  }

  handleAddAllPassageItems = () => {
    const {
      passageItems,
      passage,
      page,
      selectedRows,
      setAndSavePassageItems,
      hasSections,
      isDynamicTest,
      isSectionAdaptiveTest,
    } = this.props

    if (isSectionAdaptiveTest) {
      if (isManuallyGradableItemAndNotify(passageItems)) {
        return
      }
    }
    const passageTestItems = get(passage, 'testItems', [])

    const isAdding = passageTestItems.some((x) => !selectedRows.includes(x))

    if (hasSections || isDynamicTest) {
      this.handleAddMultiplePassageItemsToSections()
      return
    }

    setAndSavePassageItems({ passageItems, page, remove: !isAdding })
  }

  get isAddOrRemove() {
    const { item, selectedRows } = this.props
    if (selectedRows && selectedRows.length) {
      return !selectedRows.includes(item?._id)
    }
    return true
  }

  toggleHints = () => {
    this.setState((prevState) => ({
      showHints: !prevState.showHints,
    }))
  }

  toggleReportIssue = () => {
    this.setState((prevState) => ({
      showReportIssueField: !prevState.showReportIssueField,
    }))
  }

  toggleFullModal = () => {
    this.setState((prevState) => ({
      fullModal: !prevState.fullModal,
    }))
  }

  navigationBtns = () => {
    const { nextItem, prevItem } = this.props
    return (
      <>
        <PrevArrow
          onClick={() => {
            this.clearView()
            prevItem()
          }}
          position="absolute"
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </PrevArrow>
        <NextArrow
          onClick={() => {
            this.clearView()
            nextItem()
          }}
          position="absolute"
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </NextArrow>
      </>
    )
  }

  getBtnStyle = (addedToTest) => ({
    backgroundColor: !addedToTest ? '#fff' : themeColor,
    color: !addedToTest ? themeColor : '#fff',
    borderColor: !addedToTest ? themeColor : '',
  })

  handleDeleteItem = () => {
    const {
      item: { _id, isPassageWithQuestions = false, collections = [] },
      deleteItem,
      isEditable,
      page,
      closeModal,
      writableCollections = [],
    } = this.props
    const isCollectionContentEditable = isContentOfCollectionEditable(
      collections,
      writableCollections
    )
    if (!(isEditable || isCollectionContentEditable)) {
      notification({ messageKey: 'dontHaveWritePermission' })
      return
    }
    if (closeModal) closeModal()
    return deleteItem({
      id: _id,
      isItemPrevew: page === 'addItems' || page === 'itemList',
      isPassageWithQuestions,
    })
  }

  handleApproveOrRejectSingleItem = (value) => {
    const {
      approveOrRejectSingleItem: _approveOrRejectSingleItem,
      item,
    } = this.props
    if (item?._id) {
      _approveOrRejectSingleItem({ itemId: item._id, status: value })
    }
  }

  handleReject = () => this.setState({ isRejectMode: true })

  setIsAddingSinglePassageItem = (val) =>
    this.setState({ isAddingSinglePassageItem: val })

  get navigationButtonVisibile() {
    const { item, page, replaceItemId } = this.props
    if (
      page === pageType.REPORTS ||
      (replaceItemId && page === 'replaceItem')
    ) {
      return false
    }
    if (page === 'review') {
      return true
    }
    return !item?.isPassageWithQuestions
  }

  onClickCustomizeTTS = () => {
    this.toggleTTSTextModal()
  }

  getAvailableLanguageCode = (question) => {
    let language = LANGUAGE_EN
    const languageCodes = LANGUAGES_OPTIONS.map((lang) => lang.value)
    if (question?.stimulus || question?.contentsTitle) {
      return LANGUAGE_EN
    }
    for (const languageCode of languageCodes) {
      const languageStimulus =
        question?.languageFeatures?.[languageCode]?.stimulus ||
        question?.languageFeatures?.[languageCode]?.contentsTitle
      // If the stimulus is not empty, then set the language and break the loop
      if (languageStimulus) {
        language = languageCode
        break
      }
    }

    return language
  }

  changeQuestion = (action) => {
    const questionsCount = (this.ttsCustomizableData?.questions || []).length

    const callBack = () => this.requestToGetTTSText({ isLanguageChanged: true })

    this.setState((prevState) => {
      const { currentQuestionIndex: prevCurrentQuestionIndex } = prevState
      if (action === TTS_NAV_NEXT) {
        const newIndex = prevCurrentQuestionIndex + 1
        if (newIndex < questionsCount) {
          const question = this.ttsCustomizableData?.questions?.[newIndex]
          const isEnglishLanguageDisable = !question?.stimulus
          const selectedLanguage = this.getAvailableLanguageCode(question)
          return {
            ...prevState,
            currentQuestionIndex: newIndex,
            ...(isEnglishLanguageDisable
              ? {
                  selectedLanguage,
                  voiceLanguage: getVoiceLanguageByCode(selectedLanguage),
                }
              : {}),
          }
        }
      } else {
        const newIndex = prevCurrentQuestionIndex - 1

        const question = this.ttsCustomizableData?.questions?.[newIndex]
        const isEnglishLanguageDisable = !question?.stimulus
        const selectedLanguage = this.getAvailableLanguageCode(question)

        if (prevCurrentQuestionIndex > 0)
          return {
            ...prevState,
            currentQuestionIndex: newIndex,
            ...(isEnglishLanguageDisable
              ? {
                  selectedLanguage,
                  voiceLanguage: getVoiceLanguageByCode(selectedLanguage),
                }
              : {}),
          }
      }
    }, callBack)
  }

  // TODO consistency for question and resources for previeew
  render() {
    const {
      item = {
        rows: [],
        data: {},
        authors: [],
      },
      isVisible,
      lcbLinkComponent,
      collections,
      loading,
      userId,
      isEditable = false,
      checkAnswer,
      showAnswer,
      preview,
      passage,
      page,
      showAddPassageItemToTestButton = false, // show if add item to test button needs to shown.
      windowWidth,
      userFeatures,
      onlySratchpad,
      changePreviewMode,
      test,
      test: { itemGroups },
      testAssignments,
      userRole,
      deleting,
      writableCollections,
      testStatus = 'draft',
      selectedRows,
      passageItemIds = [],
      isPlaylistTestReview,
      t,
      isDynamicTest,
      ttsTextResult,
      ttsTextAPIStatus,
      updateTTSAPIStatus,
      isSectionAdaptiveTest,
      handlePaginationChange,
      currentReplaceItemIndex,
      equivalentItems,
      replaceItemId,
      testId,
      isTest = !!testId,
      isEditDisabledForSectionsAdaptiveTest,
      isDraftItemCloningInProgress,
      handleAiOptionSelected,
    } = this.props
    let {
      questions = keyBy(get(item, 'data.questions', []), 'id'),
    } = this.props
    if (replaceItemId && page === 'replaceItem') {
      questions = getQuestions(equivalentItems)
    }
    const premiumCollectionWithoutAccess =
      item?.premiumContentRestriction &&
      item?.collections
        ?.filter(({ type = '' }) => type === collectionConst.types.PREMIUM)
        .map(({ name }) => name)
    const uniSectionTestLength = 1
    const { testItems = [] } = passage || {}
    const hasMultipleTestItems = testItems.length > 1

    const {
      passageLoading,
      showHints,
      showReportIssueField,
      fullModal,
      isRejectMode,
      showSelectGroupModal,
      showTTSTextModal,
      selectedLanguage,
      voiceLanguage,
      modalDraggable,
      currentQuestionIndex = 0,
    } = this.state
    const resources = keyBy(
      get(item, 'data.resources', []),
      (r) => `${item._id}_${r.id}`
    )

    let allWidgets = { ...questions, ...resources }
    const { authors = [], rows, data = {} } = item || {}

    const questionsType =
      data.questions && uniq(data.questions.map((question) => question.type))
    const noAnswerButtonQns = [
      ...questionType.manuallyGradableQn,
      ...questionType.questionTypeWithoutCorrectAnswer,
    ]
    const intersectionCount = intersection(questionsType, noAnswerButtonQns)
      .length
    const isAnswerBtnVisible =
      questionsType && intersectionCount < questionsType.length
    const isOwner = authors.some((author) => author._id === userId)
    const hasCollectionAccess = allowContentEditCheck(
      item?.collections,
      writableCollections
    )
    const { derivedFromPremiumBankId = false } = item || {}
    const isCollectionContentEditable = isContentOfCollectionEditable(
      item?.collections,
      writableCollections
    )
    const allowDuplicatePrimitive = allowDuplicateCheck(
      item?.collections,
      collections,
      'item'
    )
    let allowDuplicate =
      (allowDuplicatePrimitive || isOwner) && !derivedFromPremiumBankId
    if (
      item?.sharedWith?.filter(
        (s) =>
          `${s?._id}` === `${item._id}` &&
          s.name === 'LINK' &&
          s.permission === 'NOACTION'
      ).length &&
      !isOwner
    ) {
      allowDuplicate = false
    }
    const allRows =
      item && !!item.passageId && !!passage
        ? [passage.structure, ...rows]
        : rows
    const passageTestItems = passageItemIds || []
    const isPassage = passage && passageTestItems.length
    const hasPassageItemToAdd = passageTestItems.some(
      (x) => !selectedRows.includes(x)
    )

    if (!!item?.passageId && !!passage) {
      allWidgets = { ...allWidgets, ...keyBy(passage.data, 'id') }
    }

    const isSmallSize = windowWidth <= SMALL_DESKTOP_WIDTH
    const isReportsPage = page === pageType.REPORTS

    const isTestInRegrade =
      !!test?._id &&
      (test.isInEditAndRegrade || (testAssignments.length && test.isUsed))

    const isDisableEditPrimitive = !(
      (isEditable && isOwner) ||
      userRole === roleuser.EDULASTIC_CURATOR ||
      (hasCollectionAccess && userFeatures.isCurator) ||
      isCollectionContentEditable
    )
    const isDisableEdit =
      (isDisableEditPrimitive && !(allowDuplicate && isEditable)) ||
      item?.unsavedItem

    const isDisableDuplicate = !(
      allowDuplicate && userRole !== roleuser.EDULASTIC_CURATOR
    )
    const disableEdit = item?.algoVariablesEnabled && isTestInRegrade
    const itemHasAtleastOneQuestion = Object.keys(questions || {}).length > 0
    const showAddItemToTestButton =
      !isReportsPage &&
      itemHasAtleastOneQuestion &&
      testStatus !== 'published' &&
      !replaceItemId &&
      !isEditDisabledForSectionsAdaptiveTest
    const isLoading = loading || item === null || passageLoading
    const isMobile = isSmallSize || fullModal
    const staticGroups = (itemGroups || []).filter(
      (g) => g.type === ITEM_GROUP_TYPES.STATIC
    )
    const groupName =
      staticGroups.length === uniSectionTestLength
        ? 'Test'
        : test?.itemGroups?.find(
            (grp) => !!grp.items.find((i) => i._id === item._id)
          )?.groupName || 'Test'

    const showviewTTSTextBtn = (questionsType || [])?.some((type) =>
      TTS_ENABLED_QUESTION_TYPES.includes(type)
    )

    const handleDraggable = (value) => {
      this.setState({ modalDraggable: value })
    }

    const disableEditItemButton =
      isPlaylistTestReview ||
      isDisableEdit ||
      !!premiumCollectionWithoutAccess ||
      isDynamicTest ||
      isEditDisabledForSectionsAdaptiveTest ||
      item.unsavedItem ||
      replaceItemId

    const showDuplicateAndEditItemTooltip =
      isDisableEditPrimitive &&
      isTest &&
      allowDuplicatePrimitive &&
      !disableEditItemButton

    const editButtonComponent =
      disableEdit && userRole !== roleuser.EDULASTIC_CURATOR ? (
        <Popover
          placement="bottomRight"
          content={
            <DisabledHelperText>
              Please clone to make changes. Dynamic Parameter questions
              generates random values when the test is assigned, which cannot be
              changed.
            </DisabledHelperText>
          }
        >
          <DisabledButton>
            <IconPencilEdit color={themeColor} />
          </DisabledButton>
        </Popover>
      ) : (
        <Tooltip
          title={
            isDynamicTest || isEditDisabledForSectionsAdaptiveTest
              ? t(
                  `${
                    isEditDisabledForSectionsAdaptiveTest
                      ? 'regradeNotSupportedForSectionsAdaptiveTest'
                      : 'authoringItemDisabled'
                  }.info`
                )
              : showDuplicateAndEditItemTooltip
              ? 'Edit a copy of this item. Original item will not be altered.'
              : ''
          }
          placement={showDuplicateAndEditItemTooltip ? 'bottom' : undefined}
        >
          <span
            style={{
              cursor:
                isDynamicTest || isEditDisabledForSectionsAdaptiveTest
                  ? 'not-allowed'
                  : 'pointer',
            }}
          >
            <EduButton
              IconBtn
              isGhost
              data-cy="editItem"
              height="28px"
              width="28px"
              title={
                item.unsavedItem
                  ? `Please save the Test to edit this ${
                      item.aiDocExtracted ? 'imported' : 'AI Generated'
                    } Item`
                  : isDisableEdit
                  ? t(
                      !allowDuplicate
                        ? 'itemPreviewActionInfo.editItemRestrictedByPublisher'
                        : 'itemPreviewActionInfo.editItemRestrictedByAuthor'
                    )
                  : 'Edit item'
              }
              noHover={isDisableEdit}
              disabled={disableEditItemButton}
              onClick={this.editTestItem}
              style={
                isDynamicTest || isEditDisabledForSectionsAdaptiveTest
                  ? { pointerEvents: 'none', padding: 0 }
                  : { padding: 0 }
              } // For Dynamic & Sections test, edit and clone button are disabled. To avoid overlapping of tooltip on hover of edit and clone button, we disable the pointer events.
            >
              <IconPencilEdit
                color={themeColor}
                title={showDuplicateAndEditItemTooltip ? '' : 'Edit item'}
              />
            </EduButton>
          </span>
        </Tooltip>
      )

    return (
      <PreviewModalWrapper
        bodyStyle={{ padding: 0 }}
        wrapClassName="preview-full-modal"
        width={isMobile ? '100%' : '75%'}
        height={isMobile ? '100%' : null}
        visible={isVisible}
        closable={false}
        onCancel={this.closeModal}
        footer={null}
        centered
        className="noOverFlowModal"
        isMobile={isMobile}
      >
        <EduIf condition={!isReportsPage && showTTSTextModal}>
          <CustomizeTTSModal
            showTTSTextModal={showTTSTextModal}
            modalDraggable={modalDraggable}
            data={this.ttsCustomizableData}
            currentQuestionIndex={currentQuestionIndex}
            toggleTTSTextModal={this.toggleTTSTextModal}
            changeQuestion={this.changeQuestion}
            ttsTextAPIStatus={ttsTextAPIStatus}
            updateTTSAPIStatus={updateTTSAPIStatus}
            ttsTextResult={ttsTextResult}
            voiceLanguage={voiceLanguage}
            updateQuestionTTSText={this.updateQuestionTTSText}
            regenerateTTSText={this.regenerateTTSText}
            onLanguageChange={this.onLanguageChange}
            onChangeVoiceLanguge={this.onChangeVoiceLanguge}
            selectedLanguage={selectedLanguage}
            handleDraggable={handleDraggable}
          />
        </EduIf>
        {this.navigationButtonVisibile && this.navigationBtns()}
        {isDraftItemCloningInProgress && (
          <PreviewPopupOverlay>
            <Spin tip="" />
          </PreviewPopupOverlay>
        )}

        <HeadingWrapper>
          <Title justifyContent="flex-start" alignItems="center">
            Preview
          </Title>
          <FlexContainer justifyContent="flex-end" width="100%">
            <EduIf condition={showSelectGroupModal}>
              <SelectGroupModal
                visible={!isReportsPage && showSelectGroupModal}
                test={test}
                handleResponse={this.handleSelectGroupModalResponse}
                isSectionAdaptiveTest={isSectionAdaptiveTest}
              />
            </EduIf>
            <ScoreBlock
              customStyle={{
                position: 'relative',
                top: 'unset',
                right: 'unset',
                bottom: 'unset',
                left: 'unset',
                margin: '0 5px',
                transform: 'unset',
              }}
            />
          </FlexContainer>

          <ModalTopAction hidden={isLoading}>
            <FeaturesSwitch
              inputFeatures="premium"
              actionOnInaccessible="hidden"
            >
              <EduIf condition={!isReportsPage && showviewTTSTextBtn}>
                <EduButton
                  isGhost
                  height="28px"
                  data-cy="viewTTSText"
                  disabled={isDisableEdit}
                  onClick={this.onClickCustomizeTTS}
                  title={
                    item?.unsavedItem
                      ? `Please save the Test to customize TTS for this ${
                          item.aiDocExtracted ? 'imported' : 'AI Generated'
                        } Item`
                      : isDisableEdit
                      ? t(
                          !allowDuplicate
                            ? 'itemPreviewActionInfo.editItemRestrictedByPublisher'
                            : 'itemPreviewActionInfo.editItemRestrictedByAuthor'
                        )
                      : 'Customize TTS'
                  }
                >
                  Customize TTS
                </EduButton>
              </EduIf>
            </FeaturesSwitch>
            {showAddItemToTestButton &&
              (isPassage && showAddPassageItemToTestButton ? (
                <>
                  <EduButton
                    isBlue
                    height="28px"
                    justifyContent="center"
                    onClick={this.handleAddOrRemove}
                    dataCy={
                      this.isAddOrRemove
                        ? 'addCurrentItem'
                        : 'removeCurrentItem'
                    }
                  >
                    {this.isAddOrRemove
                      ? 'ADD CURRENT ITEM'
                      : `REMOVE CURRENT ITEM FROM ${groupName.toUpperCase()}`}
                  </EduButton>
                  {isPassage > 1 && (
                    <EduButton
                      isGhost
                      height="28px"
                      justifyContent="center"
                      onClick={this.handleAddAllPassageItems}
                      dataCy={
                        hasPassageItemToAdd ? `addAllItems` : `removeAllItems`
                      }
                    >
                      {hasPassageItemToAdd
                        ? `Add all(${isPassage}) items`
                        : `Remove all(${isPassage}) items`}
                    </EduButton>
                  )}
                </>
              ) : (
                <EduButton
                  isBlue
                  height="28px"
                  justifyContent="center"
                  onClick={this.handleAddOrRemove}
                  data-cy={this.isAddOrRemove ? 'addToTest' : 'removefromTest'}
                  title={
                    item.unsavedItem
                      ? `Please save the Test to delete this ${
                          item.aiDocExtracted ? 'imported' : 'AI Generated'
                        } Item`
                      : ''
                  }
                >
                  {this.isAddOrRemove
                    ? 'Add To Test'
                    : `Remove from ${groupName}`}
                </EduButton>
              ))}
            <ButtonsWrapper
              justifyContent="flex-end"
              wrap="nowrap"
              style={{
                visibility: onlySratchpad && 'hidden',
                position: 'relative',
                marginLeft: '5px',
              }}
            >
              {replaceItemId && this.isAddOrRemove && (
                <EduButton
                  isBlue
                  height="28px"
                  justifyContent="center"
                  dataCy="Replace"
                  onClick={this.handleSelection}
                >
                  Replace
                </EduButton>
              )}
              {isAnswerBtnVisible && (
                <>
                  <EduButton
                    isGhost
                    height="28px"
                    data-cy="check-answer-btn"
                    onClick={checkAnswer}
                    disabled={!!premiumCollectionWithoutAccess}
                  >
                    CHECK ANSWER
                  </EduButton>
                  <EduButton
                    isGhost
                    height="28px"
                    data-cy="show-answers-btn"
                    onClick={showAnswer}
                    disabled={!!premiumCollectionWithoutAccess}
                  >
                    SHOW ANSWER
                  </EduButton>
                </>
              )}
              {page === 'review' && item?.unsavedItem && !item?.aiDocExtracted && (
                <AIQuestionActionButton
                  height="28px"
                  width="28px"
                  marginLeft="5px"
                  smallIcon
                  onOptionSelected={(option) => {
                    handleAiOptionSelected(option, item)
                  }}
                  source="Item Preview"
                />
              )}
              {page !== 'itemAuthoring' && (
                <EduButton
                  title="Clear"
                  IconBtn
                  isGhost
                  width="28px"
                  height="28px"
                  data-cy="clear-btn"
                  onClick={this.clearView}
                  disabled={!!premiumCollectionWithoutAccess}
                  style={{ padding: 0 }}
                >
                  <IconClear width="15" height="15" color={themeColor} />
                </EduButton>
              )}
              {!isReportsPage && editButtonComponent}
              {!isReportsPage && (
                <Tooltip
                  title={
                    isDynamicTest || isEditDisabledForSectionsAdaptiveTest
                      ? t(
                          `${
                            isEditDisabledForSectionsAdaptiveTest
                              ? 'regradeNotSupportedForSectionsAdaptiveTest'
                              : 'authoringItemDisabled'
                          }.info`
                        )
                      : ''
                  }
                >
                  <span
                    style={{
                      cursor:
                        isDynamicTest || isEditDisabledForSectionsAdaptiveTest
                          ? 'not-allowed'
                          : 'pointer',
                    }}
                  >
                    <EduButton
                      IconBtn
                      isGhost
                      data-cy="cloneItem"
                      width="28px"
                      height="28px"
                      title={
                        item.unsavedItem
                          ? `Please save the Test to Clone this ${
                              item.aiDocExtracted ? 'imported' : 'AI Generated'
                            } Item`
                          : isDisableDuplicate
                          ? 'Clone permission is restricted by the author'
                          : 'Clone'
                      }
                      noHover={isDisableDuplicate}
                      disabled={
                        isDisableDuplicate ||
                        !!premiumCollectionWithoutAccess ||
                        isDynamicTest ||
                        isEditDisabledForSectionsAdaptiveTest ||
                        item.unsavedItem ||
                        replaceItemId
                      }
                      onClick={this.handleDuplicateTestItem}
                      style={
                        isDynamicTest || isEditDisabledForSectionsAdaptiveTest
                          ? { pointerEvents: 'none', padding: 0 }
                          : { padding: 0 }
                      } // For Dynamic & Sections test, edit and clone button are disabled. To avoid overlapping of tooltip on hover of edit and clone button, we disable the pointer events.
                    >
                      <IconCopy color={themeColor} />
                    </EduButton>
                  </span>
                </Tooltip>
              )}
              {(isOwner || isCollectionContentEditable) &&
                !(
                  userFeatures?.isPublisherAuthor && item.status === 'published'
                ) &&
                (page === 'addItems' || page === 'itemList') && (
                  <EduButton
                    IconBtn
                    title="Delete item"
                    isGhost
                    height="28px"
                    width="28px"
                    onClick={this.handleDeleteItem}
                    disabled={
                      (isPassage
                        ? !hasMultipleTestItems || deleting
                        : deleting) || !!premiumCollectionWithoutAccess
                    }
                    data-cy="deleteItem"
                    style={{ padding: 0 }}
                  >
                    <IconTrash title="Delete item" />
                    {/* <span>delete</span> */}
                  </EduButton>
                )}
              <FeaturesSwitch
                inputFeatures="isCurator"
                actionOnInaccessible="hidden"
              >
                <>
                  {!isReportsPage &&
                  item &&
                  item.status === 'inreview' &&
                  hasCollectionAccess ? (
                    <RejectButton
                      title="Reject"
                      isGhost
                      height="28px"
                      onClick={this.handleReject}
                      disabled={isRejectMode}
                    >
                      <Icon type="stop" color={red} />
                      <span>Reject</span>
                    </RejectButton>
                  ) : null}
                  {item &&
                  ((!isReportsPage && item.status === 'inreview') ||
                    item.status === 'rejected') &&
                  hasCollectionAccess ? (
                    <EduButton
                      title="Approve"
                      isGhost
                      height="28px"
                      onClick={() => {
                        this.handleApproveOrRejectSingleItem('published')
                      }}
                    >
                      <Icon type="check" color={themeColor} />
                      <span>Approve</span>
                    </EduButton>
                  ) : null}
                </>
              </FeaturesSwitch>
            </ButtonsWrapper>

            <EduButton
              IconBtn
              isGhost
              type="primary"
              width="28px"
              height="28px"
              onClick={this.toggleFullModal}
              title={fullModal ? 'Collapse' : 'Expand'}
              style={{ padding: 0 }}
            >
              {fullModal ? <IconCollapse /> : <IconExpand />}
            </EduButton>
            <EduButton
              data-cy="close-preview"
              IconBtn
              isGhost
              width="28px"
              height="28px"
              onClick={this.closeModal}
              title="Close"
              noHover
              style={{ border: 'none', boxShadow: 'none', padding: 0 }}
            >
              <IconClose width={10} height={10} color={`${title} !important`} />
            </EduButton>
          </ModalTopAction>
        </HeadingWrapper>
        <ModalContentArea isMobile={isMobile} id="preview-modal-content-area">
          {isReportsPage && lcbLinkComponent}
          {isLoading && (
            <ProgressContainer>
              <Spin tip="" />
            </ProgressContainer>
          )}
          <QuestionWrapper hidden={isLoading}>
            {!!replaceItemId && page !== 'addItems' && (
              <ReplaceItemsNav
                onChange={handlePaginationChange}
                total={equivalentItems.length}
                current={currentReplaceItemIndex}
              />
            )}
            <AuthorTestItemPreview
              cols={allRows}
              preview={preview}
              previewTab={preview}
              verticalDivider={item.verticalDivider}
              scrolling={item.scrolling}
              style={{ width: '100%' }}
              questions={allWidgets}
              viewComponent="authorPreviewPopup"
              handleCheckAnswer={checkAnswer}
              handleShowAnswer={showAnswer}
              handleShowHints={this.toggleHints}
              toggleReportIssue={this.toggleReportIssue}
              showHints={showHints}
              allowDuplicate={allowDuplicate}
              /* Giving edit test item functionality to the user who is a curator as curator can edit any test item. */
              isEditable={
                (isEditable && isOwner) ||
                userFeatures.isCurator ||
                userRole === roleuser.EDULASTIC_CURATOR ||
                isCollectionContentEditable
              }
              isPassage={isPassage}
              passageTestItems={passageTestItems}
              handleDuplicateTestItem={this.handleDuplicateTestItem}
              editTestItem={this.editTestItem}
              clearView={this.clearView}
              goToItem={this.goToItem}
              isAnswerBtnVisible={isAnswerBtnVisible}
              item={item}
              page={page}
              isMobile={isMobile}
              showCollapseBtn
              changePreviewTab={changePreviewMode}
              onlySratchpad={onlySratchpad}
              isTestInRegrade={isTestInRegrade}
              closeModal={this.closeModal}
              isPremiumContentWithoutAccess={!!premiumCollectionWithoutAccess}
              premiumCollectionWithoutAccess={premiumCollectionWithoutAccess}
            />
            {/* we may need to bring hint button back */}
            {/* {showHints && <Hints questions={get(item, [`data`, `questions`], [])} />} */}
            {!isReportsPage && showReportIssueField && (
              <ReportIssue
                textareaRows="3"
                item={item}
                toggleReportIssue={this.toggleReportIssue}
              />
            )}
          </QuestionWrapper>
        </ModalContentArea>
      </PreviewModalWrapper>
    )
  }
}

PreviewModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  item: PropTypes.object.isRequired,
  preview: PropTypes.any.isRequired,
  userId: PropTypes.string.isRequired,
  collections: PropTypes.any.isRequired,
  loading: PropTypes.bool,
  gotoSummary: PropTypes.func,
  checkAnswer: PropTypes.func,
  showAnswer: PropTypes.func,
  clearAnswers: PropTypes.func.isRequired,
  changeView: PropTypes.func.isRequired,
  testId: PropTypes.string.isRequired,
  history: PropTypes.any.isRequired,
  windowWidth: PropTypes.number.isRequired,
  prevItem: PropTypes.func,
  nextItem: PropTypes.func,
  setSectionsTestSetGroupIndex: PropTypes.func,
  setShowSectionsTestSelectGroupIndexModal: PropTypes.func,
}

PreviewModal.defaultProps = {
  checkAnswer: () => {},
  showAnswer: () => {},
  gotoSummary: () => {},
  prevItem: () => {},
  nextItem: () => {},
  setSectionsTestSetGroupIndex: () => {},
  setShowSectionsTestSelectGroupIndexModal: () => {},
  loading: false,
  isEditable: false,
}

const enhance = compose(
  withRouter,
  withWindowSizes,
  withNamespaces('author'),
  connect(
    (state, ownProps) => {
      const itemId = (ownProps.data || {}).id
      return {
        item:
          getItemDetailSelectorForPreview(
            state,
            itemId,
            ownProps.page,
            ownProps.clickedTestItem
          ) || get(state, 'itemDetail.item'),
        collections: getCollectionsSelector(state),
        passage: getPassageSelector(state),
        preview: get(state, ['view', 'preview']),
        userId: get(state, ['user', 'user', '_id']),
        testItemPreviewData: get(state, ['testItemPreview', 'item'], {}),
        test: getTestSelector(state),
        testAssignments: getAssignmentsSelector(state),
        userFeatures: getUserFeatures(state),
        deleting: getItemDeletingSelector(state),
        passageItems: state.tests.passageItems,
        writableCollections: getWritableCollectionsSelector(state),
        archivedItems: archivedItemsSelector(state),
        passageItemIds: passageItemIdsSelector(state),
        aiTestStatus: state?.aiTestDetails?.status,
        isDynamicTest: isDynamicTestSelector(state),
        ttsTextAPIStatus: getTTSTextAPIStatusSelector(state),
        ttsTextResult: getTTSTextResultSelector(state),
        updateTTSAPIStatus: updateTTSTextAPIStatusSelector(state),
        isSectionAdaptiveTest: isSectionAdaptiveTestSelector(state),
        equivalentItems: getEquivalentItemsSelector(state),
        userRole: getUserRole(state),
        isDraftItemCloningInProgress: getIsDraftItemCloningInProgressSelector(
          state
        ),
      }
    },
    {
      changeView: changeViewAction,
      changePreviewMode: changePreviewAction,
      clearAnswers: clearAnswersAction,
      addPassage: addPassageAction,
      addItemToCart: addItemToCartAction,
      setPrevewItem: setPrevewItemAction,
      setQuestionsForPassage: setQuestionsForPassageAction,
      clearPreview: clearPreviewAction,
      setDataAndSave: setTestDataAndUpdateAction,
      setTestItems: setTestItemsAction,
      clearItemStore: clearItemDetailAction,
      updateTestAndNavigate: updateTestAndNavigateAction,
      duplicateTestItem: duplicateTestItemPreviewRequestAction,
      deleteItem: deleteItemAction,
      approveOrRejectSingleItem: approveOrRejectSingleItemAction,
      setPassageTestItems: setPassageItemsAction,
      setAndSavePassageItems: setAndSavePassageItemsAction,
      editNonAuthoredItem: editNonAuthoredItemAction,
      setCurrentGroupIndex: setCurrentGroupIndexAction,
      regenerateTestItem: aiTestActions.regenerateAiTestItems,
      setTestData: setTestDataAction,
      setTTSTextState: setTTSTextStateAction,
      fetchTTSText: fetchTTSTextAction,
      updateTTSText: updateTTSTextAction,
      setReplaceItemId: setReplaceItemIdAction,
      setReplaceItems: setReplaceItemsAction,
      setModalVisibility: setModalVisibilityAction,
      verifyItemUsedInTestAndProceed: verifyItemUsedInTestAndProceedAction,
    }
  )
)

export default enhance(PreviewModal)

const ProgressContainer = styled.div`
  min-width: 250px;
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const PreviewModalWrapper = styled(Modal)`
  height: ${({ isMobile }) => (isMobile ? '100%' : 'auto')};
  border-radius: ${({ isMobile }) => (isMobile ? '0px' : '5px')};
  background: #f7f7f7;
  top: 30px;
  padding: 0px;
  position: relative;

  .ant-modal-content {
    background: transparent;
    box-shadow: none;
    overflow-y: auto;

    .ant-modal-close {
      top: 22px;
      right: 18px;
    }

    .ant-modal-close-icon {
      font-size: 25px;
      color: #000;
    }
  }
`

const ArrowStyle = css`
  max-width: 30px;
  font-size: 26px;
  border-radius: 0px;
  left: 0px;

  svg {
    fill: #878a91;

    path {
      fill: unset;
    }
  }

  .help-text {
    color: #878a91;
  }

  &:hover {
    svg {
      fill: ${white};
    }

    .help-text {
      color: ${white};
      font-size: 20;
      font-weight: 600;
    }
  }
`

const PrevArrow = styled(Nav.BackArrow)`
  ${ArrowStyle};
`

const NextArrow = styled(Nav.NextArrow)`
  ${ArrowStyle};
  left: unset;
  right: 0px;
`

const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 30px;
  background: ${white};
  justify-content: space-between;
  position: relative;
`

const ModalTopAction = styled(FlexContainer)`
  visibility: ${({ hidden }) => hidden && 'hidden'};
  justify-content: flex-end;
`

export const PlusIcon = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid ${themeColor};
  color: ${themeColor};
  font-size: 18px;
  line-height: 1;
  margin-right: 10px;
`

const QuestionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  visibility: ${({ hidden }) => hidden && 'hidden'};

  .report {
    flex: 0 0 100%;
  }
`

const DisabledButton = styled.div`
  height: 28px;
  width: 28px;
  border-radius: 4px;
  border: 1px solid ${themeColor};
  position: relative;
  margin-left: 5px;
  opacity: 0.3;
  cursor: not-allowed;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const DisabledHelperText = styled.div`
  max-width: 320px;
`
