import { TRUE_OR_FALSE } from '@edulastic/constants/const/questionType'
import { Typography } from 'antd'
import React from 'react'
import { StyledQuestionListItem } from './styled'
import { ANSWER_OPTION_DELIMITER } from '../constants/test'

const QuestionView = ({ question, questionType, questionIndex }) => {
  const {
    name = '',
    correctAnswer = false,
    correctAnswerIndex = -1,
    correctAnswersIndex = [],
    options = [],
  } = question
  return (
    <>
      <Typography.Paragraph data-cy="original-question-text">
        {questionIndex ? `Q${questionIndex}. ` : ''}
        {name}
      </Typography.Paragraph>
      {questionType === TRUE_OR_FALSE ? (
        <Typography.Paragraph>
          <ol type="A">
            <StyledQuestionListItem
              isBold={correctAnswer}
              data-cy="original-option-true"
            >
              <Typography.Text strong={correctAnswer}>True</Typography.Text>
            </StyledQuestionListItem>
            <StyledQuestionListItem
              isBold={!correctAnswer}
              data-cy="original-option-false"
            >
              <Typography.Text strong={!correctAnswer}>False</Typography.Text>
            </StyledQuestionListItem>
          </ol>
          <Typography.Text>
            <b>Correct answer: </b>
            <span data-cy="correct-answer">
              {correctAnswer
                ? `A${ANSWER_OPTION_DELIMITER} True`
                : `B${ANSWER_OPTION_DELIMITER} False`}
            </span>
          </Typography.Text>
        </Typography.Paragraph>
      ) : (
        <Typography.Paragraph>
          <ol type="A">
            {options.map(({ name: optionText }, index) => {
              const isCorrectAnswer =
                (correctAnswersIndex || []).includes(index) ||
                index === correctAnswerIndex

              return (
                <StyledQuestionListItem isBold={isCorrectAnswer}>
                  <Typography.Text strong={isCorrectAnswer}>
                    <span data-cy={`original-option-${index}`}>
                      {optionText}
                    </span>
                  </Typography.Text>
                </StyledQuestionListItem>
              )
            })}
          </ol>
          <Typography.Text>
            <b>Correct answer{correctAnswersIndex?.length > 1 ? 's' : ''}: </b>
            <span data-cy="correct-answer">
              {options
                .reduce(
                  (correctAnswersTextArray, { name: optionText }, index) => {
                    if (
                      (correctAnswersIndex || []).includes(index) ||
                      index === correctAnswerIndex
                    ) {
                      correctAnswersTextArray.push(
                        `${String.fromCharCode(
                          65 + index
                        )}${ANSWER_OPTION_DELIMITER} ${optionText}`
                      )
                    }

                    return correctAnswersTextArray
                  },
                  []
                )
                .join(', ')}
            </span>
          </Typography.Text>
        </Typography.Paragraph>
      )}
    </>
  )
}

export default QuestionView
