import styled from 'styled-components'

export const LikertScaleContent = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  margin-left: 8px;
  position: relative;
`
