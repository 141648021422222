import React from 'react'
import { EduButton } from '@edulastic/common'
import { connect } from 'react-redux'
import styled from 'styled-components'
import AuthorCompleteSignupButton from '../../../../../common/components/AuthorCompleteSignupButton'
import { setShowClassCreationModalAction } from '../../../ducks'
import { setCreateClassTypeDetailsAction } from '../../../../ManageClass/ducks'

const CreateClass = ({
  setShowClassCreationModal,
  setCreateClassTypeDetails,
}) => {
  return (
    <div>
      <AuthorCompleteSignupButton
        renderButton={(handleClick) => (
          <CreateClassButton
            icon="plus"
            data-cy="createNewClassOnDashboardTab"
            onClick={handleClick}
            data-testid="createNewClassOnDashboardTab"
          >
            Create Class
          </CreateClassButton>
        )}
        onClick={() => {
          setShowClassCreationModal(true)
          setCreateClassTypeDetails({ type: 'class' })
        }}
        triggerSource="Create Class"
      />
    </div>
  )
}

export default connect(() => ({}), {
  setShowClassCreationModal: setShowClassCreationModalAction,
  setCreateClassTypeDetails: setCreateClassTypeDetailsAction,
})(CreateClass)

const CreateClassButton = styled(EduButton)`
  .anticon-plus {
    margin: 0px;
    font-size: 14px;
  }
  span {
    margin-left: 0px !important;
  }
  padding: 0 5px;
`
