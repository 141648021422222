export const SHOW_GRIDLINES_NONE = 'none'
export const SHOW_GRIDLINES_BOTH = 'both'
export const SHOW_GRIDLINES_X_ONLY = 'x_only'
export const SHOW_GRIDLINES_Y_ONLY = 'y_only'
export const SETTING_NAME_SHOW_GRIDLINES = 'showGridlines'

export const SIZE_DEFAULT = 'default'
export const SIZE_LARGE = 'large'
export const SIZE_SMALL = 'small'

export const SHOW_ALWAYS = 'showAlways'
export const SHOW_BY_HOVER = 'showByHover'
export const HIDDEN = 'hidden'
