import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { arrayMove } from 'react-sortable-hoc'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import 'react-quill/dist/quill.snow.css'
import { withTheme } from 'styled-components'
import produce from 'immer'

import { withNamespaces } from '@edulastic/localization'

import { updateVariables } from '../../utils/variables'
import { setQuestionDataAction } from '../../../author/QuestionEditor/ducks'

// import ComposeQuestion from "./ComposeQuestion";
import TemplateMarkup from './TemplateMarkup'
import GroupResponses from './GroupRespones'
import Question from '../../components/Question'

class Authoring extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
    setQuestionData: PropTypes.func.isRequired,
    theme: PropTypes.object.isRequired,
    fillSections: PropTypes.func,
    cleanSections: PropTypes.func,
  }

  static defaultProps = {
    fillSections: () => {},
    cleanSections: () => {},
  }

  onChangeQuestion = (stimulus) => {
    const { item, setQuestionData } = this.props
    setQuestionData(
      produce(item, (draft) => {
        draft.stimulus = stimulus
        updateVariables(draft)
      })
    )
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { item, setQuestionData } = this.props
    setQuestionData(
      produce(item, (draft) => {
        draft.options = arrayMove(draft.options, oldIndex, newIndex)
      })
    )
  }

  remove = (index) => {
    const { item, setQuestionData } = this.props
    setQuestionData(
      produce(item, (draft) => {
        draft.options.splice(index, 1)
        updateVariables(draft)
      })
    )
  }

  render() {
    const {
      item,
      theme,
      fillSections,
      cleanSections,
      t,
      froalaFooter,
    } = this.props

    return (
      <div>
        {/* <ComposeQuestion item={item} fillSections={fillSections} cleanSections={cleanSections} /> */}
        <Question
          position="unset"
          section="main"
          label={t('component.cloze.dragDrop.composequestion')}
          fillSections={fillSections}
          cleanSections={cleanSections}
        >
          <TemplateMarkup
            froalaFooter={froalaFooter}
            item={item}
            fillSections={fillSections}
            cleanSections={cleanSections}
          />
        </Question>
        <Question
          position="unset"
          section="main"
          label={t('component.cloze.dragDrop.choicesforresponse')}
          fillSections={fillSections}
          cleanSections={cleanSections}
        >
          <GroupResponses
            item={item}
            theme={theme}
            fillSections={fillSections}
            cleanSections={cleanSections}
          />
        </Question>
      </div>
    )
  }
}

const enhance = compose(
  withRouter,
  withNamespaces('assessment'),
  withTheme,
  connect(null, { setQuestionData: setQuestionDataAction })
)

export default enhance(Authoring)
