import React from 'react'
import styled from 'styled-components'

import PerfectScrollbar from 'react-perfect-scrollbar'
import { borderGrey } from '@edulastic/colors'
import { test as testContants } from '@edulastic/constants'
import { ConfirmationModal } from '../../../src/components/common/ConfirmationModal'

const { ITEM_GROUP_TYPES } = testContants
const SelectGroupModal = ({
  visible,
  test,
  handleResponse,
  isSectionAdaptiveTest,
}) => {
  const {
    adaptiveDelivery: {
      baseSectionMaxItemsCount = 0,
      threshold: { thresholdSectionMaxItemsCount = 0 } = {},
    } = {},
  } = test || {}
  return (
    <StyledModal
      title={[<h3>Add Items</h3>]}
      centered
      textAlign="left"
      visible={visible}
      footer={null}
      onCancel={() => handleResponse(undefined)}
      width="400px"
    >
      <PerfectScrollbar style={{ maxHeight: '500px', marginRight: '-14px' }}>
        <ModalBody>
          {test.itemGroups.map(({ groupName, type, items }, index) => {
            if (type === ITEM_GROUP_TYPES.STATIC)
              return (
                <GroupWrapper
                  key={index}
                  onClick={() => handleResponse(index)}
                  data-cy={`addItemToSection-${groupName}`}
                  justifyContent={
                    isSectionAdaptiveTest ? 'space-between' : 'unset'
                  }
                >
                  {groupName}
                  {isSectionAdaptiveTest && (
                    <div>{`${items.length}/${
                      index === 0
                        ? baseSectionMaxItemsCount
                        : thresholdSectionMaxItemsCount
                    } items added`}</div>
                  )}
                </GroupWrapper>
              )
            return null
          })}
        </ModalBody>
      </PerfectScrollbar>
    </StyledModal>
  )
}

const StyledModal = styled(ConfirmationModal)`
  min-width: 550px;
  .ant-modal-content {
    .ant-modal-header {
      padding-bottom: 0px;
    }
    .ant-modal-body {
      background: transparent;
      box-shadow: unset;
    }
  }
`

const ModalBody = styled.div`
  display: block;
  width: 100%;
  padding-right: 14px;
`

const GroupWrapper = styled.div`
  height: 50px;
  background: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: ${(props) => props?.justifyContent || 'unset'};
  margin-bottom: 10px;
  padding: 0px 20px;
  cursor: pointer;
  border: 1px solid ${borderGrey};
`

export default SelectGroupModal
