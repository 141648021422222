import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { isPremiumUserSelector } from '../../../src/selectors/user'
import PremiumPopover from '../../../../features/components/PremiumPopover'

/**
 * @param {{ isPremiumUser: boolean, popoverProps?: React.ComponentPropsWithoutRef<typeof PremiumPopover> } & import('react-router-dom').LinkProps} props
 */
const PremiumLinkInner = ({
  isPremiumUser,
  onClick,
  children,
  popoverProps,
  ...linkProps
}) => {
  const [showPopup, setShowPopup] = useState(false)

  /** @type {React.MouseEventHandler<HTMLAnchorElement>} */
  const handlePremiumClick = (e) => {
    if (!isPremiumUser) {
      e.preventDefault()
      setShowPopup(true)
    }
    e.stopPropagation()
    return onClick?.(e)
  }

  return (
    <>
      <Link
        target="_blank"
        rel="opener"
        onClick={handlePremiumClick}
        {...linkProps}
      >
        {children}
      </Link>
      <PremiumPopover
        target={showPopup}
        onClose={() => setShowPopup(null)}
        descriptionType="report"
        imageType="IMG_DATA_ANALYST"
        {...popoverProps}
      />
    </>
  )
}

const enhance = compose(
  connect((state) => ({
    isPremiumUser: isPremiumUserSelector(state),
  }))
)

const PremiumLink = enhance(PremiumLinkInner)
export default PremiumLink
