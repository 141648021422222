import styled from 'styled-components'

import { themeColor } from '@edulastic/colors'

export default styled.div`
  font-size: 13px;
  font-weight: 600;
  color: ${themeColor};
  border: none;
  background: transparent;
  cursor: pointer;
  text-transform: uppercase;
  margin: 3px 2px 0;
  height: 28px;
`
