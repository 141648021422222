import React, { useRef } from 'react'

import FormFields from './FormFields'
import {
  StyledCreateAiTestDrawer,
  StyledDrawerTitle,
  StyledFormFieldsContainer,
} from './styled'

export const CreateAITest = ({
  onCancel,
  isVisible,
  handleFieldDataChange,
  handleAiFormSubmit,
  addItems,
  aiFormContent,
  updateAlignment,
  onChangeAttachmentType,
  attachmentType,
  handleAttachmentChange,
  attachmentMessageData,
  setAttachmentMessageData,
  isGenerateDisabled,
  setIsGenerateDisabled,
}) => {
  const createAITestContainerRef = useRef(null)

  return (
    <StyledCreateAiTestDrawer
      style={{ zIndex: 1004 }}
      title={<StyledDrawerTitle>Auto-generate items</StyledDrawerTitle>}
      maskClosable={false}
      onClose={onCancel}
      visible={isVisible}
      width="566px"
      destroyOnClose
    >
      <StyledFormFieldsContainer ref={createAITestContainerRef}>
        <FormFields
          handleFieldDataChange={handleFieldDataChange}
          handleAiFormSubmit={handleAiFormSubmit}
          onCancel={onCancel}
          addItems={addItems}
          aiFormContent={aiFormContent}
          updateAlignment={updateAlignment}
          onChangeAttachmentType={onChangeAttachmentType}
          attachmentType={attachmentType}
          handleAttachmentChange={handleAttachmentChange}
          attachmentMessageData={attachmentMessageData}
          setAttachmentMessageData={setAttachmentMessageData}
          isGenerateDisabled={isGenerateDisabled}
          setIsGenerateDisabled={setIsGenerateDisabled}
          createAITestContainerRef={createAITestContainerRef}
        />
      </StyledFormFieldsContainer>
    </StyledCreateAiTestDrawer>
  )
}
