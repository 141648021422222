import React from 'react'
import { withNamespaces } from 'react-i18next'
import { Divider } from 'antd'
import { FlexContainer } from '@edulastic/common'
import VerticalTextSlider from '../../../../../common/components/VerticalTextSlider'
import {
  StyledSummaryDiscardButton,
  StyledSummaryHeading,
  StyledSummaryList,
  StyledSummaryListItem,
  StyledSummaryModal,
  StyledSummaryReviewButton,
  StyledFooterInfoBanner,
  StyledIconInfoCircle,
} from './styled'

const ImportSummary = ({
  t,
  importTestFromDocAPIStatus,
  metaData,
  fileName,
  processQuestionsAndGoToTestReview,
  discardAndRestart,
}) => {
  const isVisible =
    importTestFromDocAPIStatus === 'INITIATED' ||
    importTestFromDocAPIStatus === 'SUCCESS'

  const {
    totalQuestionCount = 0,
    importedQuestionCount = 0,
    failedQuestionCount = 0,
    questionWithoutCorrectAnswerCount = 0,
    questionWithoutStandardsCount = 0,
  } = metaData || {}

  const showMoreThan100Question =
    importedQuestionCount === 100 && totalQuestionCount > 100

  return (
    <StyledSummaryModal
      visible={isVisible}
      centered
      maskClosable={false}
      onCancel={discardAndRestart}
      closable={false}
      footer={null}
      destroyOnClose
      width="600px"
    >
      {importTestFromDocAPIStatus === 'INITIATED' && (
        <VerticalTextSlider
          texts={[
            t('loadingTexts.step1'),
            t('loadingTexts.step2'),
            t('loadingTexts.step3'),
            t('loadingTexts.step4'),
          ]}
          textChangeInterval={5}
          showCheck
        />
      )}
      {importTestFromDocAPIStatus === 'SUCCESS' && (
        <>
          <StyledSummaryHeading>
            {fileName} - Import Summary
          </StyledSummaryHeading>
          <Divider type="horizontal" />
          <div>
            <StyledSummaryList>
              {importedQuestionCount === 0 ? (
                <>
                  <StyledSummaryListItem>
                    <b>No questions were imported.</b> We couldn&apos;t extract
                    any questions from the document. Please check the file and
                    try again!
                  </StyledSummaryListItem>
                  <StyledSummaryListItem>
                    <b>Need help?</b> Consider reaching out for support if you
                    continue to experience issues.
                  </StyledSummaryListItem>
                </>
              ) : (
                <>
                  <StyledSummaryListItem>
                    {showMoreThan100Question
                      ? `The document contains more than 100 questions. Only the first ${importedQuestionCount} have been imported.`
                      : `${importedQuestionCount} questions extracted`}
                  </StyledSummaryListItem>
                  {failedQuestionCount > 0 && (
                    <StyledSummaryListItem color="#EB9442">
                      Some questions couldn&apos;t be imported due to
                      unsupported formats or other limitations. Note that we
                      currently support Multiple Choice, Multiple Select, and
                      True/False question types.
                    </StyledSummaryListItem>
                  )}
                  {questionWithoutCorrectAnswerCount > 0 && (
                    <StyledSummaryListItem color="#EB9442">
                      We&apos;ve made some edits to the answers. Please review
                      them before saving the test.
                    </StyledSummaryListItem>
                  )}
                  {questionWithoutStandardsCount > 0 && (
                    <StyledSummaryListItem color="#EB9442">
                      Standards are missing for {questionWithoutStandardsCount}{' '}
                      question(s). Please review them.
                    </StyledSummaryListItem>
                  )}
                </>
              )}
            </StyledSummaryList>
          </div>
          <Divider type="horizontal" style={{ marginTop: 0 }} />
          {importedQuestionCount > 0 && (
            <StyledFooterInfoBanner>
              <StyledIconInfoCircle height={16} width={16} /> This feature is
              powered by AI. Please take a moment to review the results to
              ensure accuracy
            </StyledFooterInfoBanner>
          )}
          <FlexContainer justifyContent="center" gap="10px">
            <StyledSummaryDiscardButton
              data-cy="retryImport"
              onClick={discardAndRestart}
            >
              DISCARD & RESTART
            </StyledSummaryDiscardButton>
            {importedQuestionCount > 0 && (
              <StyledSummaryReviewButton
                data-cy="reviewTest"
                onClick={processQuestionsAndGoToTestReview}
              >
                REVIEW YOUR TEST
              </StyledSummaryReviewButton>
            )}
          </FlexContainer>
        </>
      )}
    </StyledSummaryModal>
  )
}

export default withNamespaces('importTest')(ImportSummary)
