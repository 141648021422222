import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconThumbsUp = ({ width, height, fillColor, ...props }) => (
  <SVG
    width={width || '12'}
    height={height || '12'}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color={fillColor || '#2F4151'}
    {...props}
  >
    <path d="M10.5 4.5H7.345L7.82 2.215L7.835 2.055C7.835 1.85 7.75 1.66 7.615 1.525L7.085 1L3.795 4.295C3.61 4.475 3.5 4.725 3.5 5V10C3.5 10.55 3.95 11 4.5 11H9C9.415 11 9.77 10.75 9.92 10.39L11.43 6.865C11.475 6.75 11.5 6.63 11.5 6.5V5.5C11.5 4.95 11.05 4.5 10.5 4.5ZM10.5 6.5L9 10H4.5V5L6.67 2.83L6.115 5.5H10.5V6.5ZM0.5 5H2.5V11H0.5V5Z" />
  </SVG>
)

export default withIconStyles(IconThumbsUp)
