import React from 'react'

import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconWhiteUrlAttachment = ({ fillColor = 'white', ...props }) => (
  <SVG
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.99992 8.99992H6.99992V4.99992H9.66659L4.99992 0.333252L0.333252 4.99992H2.99992V8.99992ZM4.99992 2.21992L6.44659 3.66659H5.66659V7.66659H4.33325V3.66659H3.55325L4.99992 2.21992ZM0.333252 10.3333H9.66659V11.6666H0.333252V10.3333Z"
      fill={fillColor}
    />
  </SVG>
)

export default withIconStyles(IconWhiteUrlAttachment)
