/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconAdaptive = (props) => (
  <SVG
    width="20"
    height="23"
    viewBox="0 0 20 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_111_1334)">
      <path
        d="M10.6667 14.6668H16V10.0002H14V7.3335H10.6667V6.00016H12.6667V1.3335H7.33333V6.00016H9.33333V7.3335H6V10.0002H4V14.6668H9.33333V10.0002H7.33333V8.66683H12.6667V10.0002H10.6667V14.6668ZM8.66667 4.66683V2.66683H11.3333V4.66683H8.66667ZM8 11.3335V13.3335H5.33333V11.3335H8ZM14.6667 11.3335V13.3335H12V11.3335H14.6667Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_111_1334"
        x="-2"
        y="0"
        width="24"
        height="24"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_111_1334"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_111_1334"
          result="shape"
        />
      </filter>
    </defs>
  </SVG>
)

export default withIconStyles(IconAdaptive)
