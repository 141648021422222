/* eslint-disable react/prop-types */
import React from 'react'

import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconTryItOut = ({ title, ...props }) => (
  <SVG
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.3333 0H2.66667C1.18667 0 0 1.2 0 2.66667V21.3333C0 22.8 1.18667 24 2.66667 24H21.3333C22.8 24 24 22.8 24 21.3333V2.66667C24 1.2 22.8133 0 21.3333 0ZM21.3333 21.3333H2.66667V5.33333H21.3333V21.3333ZM12 10C14.4533 10 16.64 11.28 17.7867 13.3333C16.64 15.3867 14.4533 16.6667 12 16.6667C9.54667 16.6667 7.36 15.3867 6.21333 13.3333C7.36 11.28 9.54667 10 12 10ZM12 8C8.36 8 5.25333 10.2133 4 13.3333C5.25333 16.4533 8.36 18.6667 12 18.6667C15.64 18.6667 18.7467 16.4533 20 13.3333C18.7467 10.2133 15.64 8 12 8ZM12 15.3333C10.8933 15.3333 10 14.44 10 13.3333C10 12.2267 10.8933 11.3333 12 11.3333C13.1067 11.3333 14 12.2267 14 13.3333C14 14.44 13.1067 15.3333 12 15.3333Z"
      fill="#1A4A41"
    />
  </SVG>
)

export default withIconStyles(IconTryItOut)
