import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Tooltip } from 'antd'
import { isEqual, keyBy } from 'lodash'
import { SelectInputStyled } from '@edulastic/common'
import { IconSearch, IconClose } from '@edulastic/icons'
import { lightGrey2, accessibilityColors } from '@edulastic/colors'
import { getAssigmentGroupDetails } from '../../../src/selectors/assignments'

const showAllValue = 'Show All Classes'

const SearchAssignmentClasses = ({
  assignmentGroupDetails,
  handleGroupListChange,
}) => {
  const [selectedValue, setSelectedValue] = useState([])
  const [previousSearchValue, setPreviousSearchValue] = useState([])
  const [closeIconClick, setCloseIconClick] = useState(false)
  const handleChange = (value) => {
    if (value.includes(showAllValue)) {
      value = []
    }
    setSelectedValue(value)
  }

  const onSearch = (value) => {
    if (!isEqual(previousSearchValue, value)) {
      setPreviousSearchValue(value)
      handleGroupListChange(value)
    }
  }

  const filterOption = (inputValue, option) => {
    return (
      option.props?.name?.toLowerCase()?.indexOf(inputValue?.toLowerCase()) !==
        -1 ||
      option.props?.code?.toLowerCase()?.indexOf(inputValue?.toLowerCase()) !==
        -1
    )
  }

  useEffect(() => {
    if (closeIconClick) {
      onSearch(selectedValue)
      setCloseIconClick(false)
    }
  }, [closeIconClick])

  const assignmentGroupDetailsById = useMemo(
    () => keyBy(assignmentGroupDetails, '_id'),
    [assignmentGroupDetails]
  )
  const omitedItemsTooltip = (omittedItems) => {
    const items = omittedItems.map(
      (item) => assignmentGroupDetailsById[item]?.name
    )
    return (
      <Tooltip placement="topLeft" title={items.join(', ')}>
        <span>+ {omittedItems.length}</span>
      </Tooltip>
    )
  }

  return (
    <GroupSelectAndIcon>
      <SearchIcon>
        <IconSearch color={lightGrey2} />
      </SearchIcon>
      <StyledSelectInput
        showSearch
        style={{
          width: 250,
          height: 32,
        }}
        height="32px"
        data-cy="searchClassByNameOrCode"
        placeholder="Search Class by Name or Code"
        filterOption={filterOption}
        onChange={handleChange}
        value={selectedValue}
        mode="multiple"
        maxTagTextLength={18}
        maxTagCount={1}
        maxTagPlaceholder={omitedItemsTooltip}
        $paddingRight="12px"
        onBlur={onSearch}
        removeIcon={
          <IconClose
            color="#1766ce"
            onClick={() => {
              setCloseIconClick(true)
            }}
          />
        }
      >
        <SelectInputStyled.Option
          code={showAllValue}
          name={showAllValue}
          key={showAllValue}
          value={showAllValue}
        >
          Show All Classes
        </SelectInputStyled.Option>
        {assignmentGroupDetails.map((option) => (
          <SelectInputStyled.Option
            name={option.name}
            code={option.code}
            key={option._id}
            value={option._id}
          >
            {option.name}
          </SelectInputStyled.Option>
        ))}
      </StyledSelectInput>
    </GroupSelectAndIcon>
  )
}

export default connect(
  (state) => ({
    assignmentGroupDetails: getAssigmentGroupDetails(state),
  }),
  {}
)(SearchAssignmentClasses)

const StyledSelectInput = styled(SelectInputStyled)`
  &.ant-select {
    .ant-select-selection {
      &.ant-select-selection--multiple {
        padding-left: 25px;
        .ant-select-selection__choice__remove {
          position: absolute;
          top: 0px;
          right: 2px;
          svg {
            width: 10px;
            height: 10px;
          }
        }
        .ant-select-selection__rendered {
          .ant-select-selection__choice__content {
            color: ${accessibilityColors.labels.blue.text};
            font-weight: 500;
          }
          .ant-select-selection__placeholder {
            margin-left: 10px;
          }
          .ant-select-selection__choice {
            background: ${accessibilityColors.labels.blue.bg};
            border-radius: 0;
            border: 1px solid ${accessibilityColors.labels.blue.text};
          }
        }
      }
    }
  }
`

const GroupSelectAndIcon = styled.div`
  position: relative;
  display: inline-block;
`
const SearchIcon = styled.span`
  position: absolute;
  display: inline-block;
  width: 25px;
  z-index: 1;
  top: 8px;
  left: 8px;
`
