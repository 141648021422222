import React from 'react'
import withIconStyles from '@edulastic/icons/src/HOC/withIconStyles'
import SVG from '@edulastic/icons/src/common/SVG'

const IconGraphArea2 = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="25"
    viewBox="0 0 29 25"
    {...props}
  >
    <g transform="translate(-6291 807)">
      <path
        d="M9,0A9,9,0,1,1,0,9,9,9,0,0,1,9,0Z"
        transform="translate(6296 -807)"
        fill="#dbdbdb"
      />
      <path
        d="M9,0A9,9,0,1,1,0,9,9,9,0,0,1,9,0Z"
        transform="translate(6291 -800)"
        fill="#dbdbdb"
      />
      <path
        d="M9,0A9,9,0,1,1,0,9,9,9,0,0,1,9,0Z"
        transform="translate(6302 -800)"
        fill="#dbdbdb"
      />
      <path
        d="M5,9a9.093,9.093,0,0,1,.066-1.1A9,9,0,0,1,18,16a9.089,9.089,0,0,1-.066,1.1A9,9,0,0,1,5,9Z"
        transform="translate(6291 -807)"
        fill="#c1c3c9"
      />
      <path
        d="M6.13,17.533A9.007,9.007,0,0,1,17.87,7.467,9.007,9.007,0,0,1,6.13,17.533Z"
        transform="translate(6296 -807)"
        fill="#c1c3c9"
      />
      <path
        d="M11,9a8.985,8.985,0,0,1,3.5-7.125,9,9,0,0,1,0,14.249A8.985,8.985,0,0,1,11,9Z"
        transform="translate(6291 -800)"
        fill="#c1c3c9"
      />
      <path
        d="M8.945,11H8.823l-.112,0h0l-.048,0H8.645l-.043,0H8.589l-.044,0H8.535l-.053,0H8.469l-.039,0-.023,0-.027,0-.026,0-.024,0-.119-.01h0l-.041,0H8.16a8.991,8.991,0,0,1-1.428-.25h0l-.1-.026-.036-.01-.012,0-.041-.012-.007,0q-.2-.058-.4-.125A9.011,9.011,0,0,1,9.5,1.875,9.009,9.009,0,0,1,12.933,10.1a8.952,8.952,0,0,1-3.582.9H9.346l-.048,0H8.945Z"
        transform="translate(6296 -800)"
        fill="#878a91"
      />
    </g>
  </SVG>
)

export default withIconStyles(IconGraphArea2)
