import React from 'react'
import { StackedTranslatorContext } from './StackedTranslatorProvider'

const withStackedTranslator = (Component) => {
  return function ContextComponent(props) {
    return (
      <StackedTranslatorContext.Consumer>
        {(value) => {
          return <Component {...props} stackedTranslatorContext={value} />
        }}
      </StackedTranslatorContext.Consumer>
    )
  }
}

export default withStackedTranslator
