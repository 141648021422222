import styled from 'styled-components'

export const Break = styled.div`
  height: 0;
  flex-basis: 100%;
`
export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
`
