import React from 'react'
import { connect } from 'react-redux'
import { StyledSelect } from './styled'
import { currentTermGroupsListSelector } from '../../../../src/selectors/user'
import { selectsData } from '../../../../TestPage/components/common'
import { AssignmentStatus } from '../../../../Assignments/components/LeftFilter'
import { getAssignmentStatus } from '../../../constants'

const { Option } = StyledSelect
const { allGrades } = selectsData

const dropdownStyles = {
  width: '150px',
  height: '25px',
  margin: '0px 10px',
}
const Filters = ({ classList, filterState, handleFilterChange }) => {
  return (
    <div>
      <StyledSelect
        onChange={(value) => handleFilterChange('grades', value)}
        value={filterState.grades}
        {...dropdownStyles}
        margin="0px"
        data-cy="grades"
      >
        <Option value="">All Grades</Option>
        {allGrades.map((grade) => {
          return (
            <Option key={grade.value} value={grade.value}>
              {grade.text}
            </Option>
          )
        })}
      </StyledSelect>
      <StyledSelect
        onChange={(value) => {
          handleFilterChange('classIds', value)
        }}
        value={filterState.classIds}
        {...dropdownStyles}
        data-cy="filter-class"
      >
        <Option value="">All Classes</Option>
        {classList.map((group) => {
          return (
            <Option key={group.id} value={group._id}>
              {group.name}
            </Option>
          )
        })}
      </StyledSelect>
      <StyledSelect
        onChange={(value) => {
          handleFilterChange('statuses', value)
        }}
        value={filterState.statuses}
        {...dropdownStyles}
        data-cy="filter-status"
      >
        <Option value="">All Status</Option>
        {Object.keys(AssignmentStatus).map((_status) => (
          <Option key={_status} value={AssignmentStatus[_status]}>
            {getAssignmentStatus(AssignmentStatus[_status])}
          </Option>
        ))}
      </StyledSelect>
    </div>
  )
}

export default connect((state) => ({
  classList: currentTermGroupsListSelector(state),
}))(Filters)
