import { isArray, keyBy, sum, uniq, uniqBy } from 'lodash'
import memoizeOne from 'memoize-one'
import { v4 } from 'uuid'

export const generateRatingData = (index) => ({
  name: `Rating ${index}`,
  desc: '',
  id: v4(),
  points: 0,
})

/**
 * Build a convenient map to access rubric criteria-ratings
 * @template {{ id: string; ratings: {id: string; points: number}[]}[]} Criteria
 * @param {Criteria} criteria
 */
function _buildCriteriaRatingMap(criteria) {
  return keyBy(
    criteria.map((criterion) => ({
      ...criterion,
      ratingsMap: keyBy(criterion.ratings, 'id'),
      maxScore: Math.max(...criterion.ratings.map((r) => r.points)),
    })),
    'id'
  )
}
export const buildCriteriaRatingMap = memoizeOne(_buildCriteriaRatingMap)

export const calculateScore = (rubricData, rubricFeedback) => {
  const criteriaRatingMap = buildCriteriaRatingMap(rubricData.criteria)
  const feedbackPoints = Object.keys(rubricFeedback).map((criteriaId) => {
    const ratingId = rubricFeedback[criteriaId]?.ratingId
    if (!ratingId) return 0
    return criteriaRatingMap[criteriaId].ratingsMap[ratingId].points
  })

  return sum(feedbackPoints)
}

export const generateCriteriaData = (index) => ({
  name: `Criteria Name ${index}`,
  id: v4(),
  ratings: [
    {
      name: 'Rating 1',
      desc: '',
      id: v4(),
      points: 0,
    },
    {
      name: 'Rating 2',
      desc: '',
      id: v4(),
      points: 0,
    },
  ],
})

export const getDefaultRubricData = () => ({
  name: '',
  description: '',
  criteria: [generateCriteriaData(1)],
})

export const getUniqueAlignmentData = (alignment = {}) => {
  const curriculumToStandard = {}
  const alignmentStandards = alignment?.standards || []
  alignmentStandards.forEach((std) => {
    if (curriculumToStandard[std.curriculumId]) {
      curriculumToStandard[std.curriculumId].push(std._id)
    } else {
      curriculumToStandard[std.curriculumId] = [std._id]
    }
  })
  return Object.keys(curriculumToStandard).map((currId) => ({
    curriculumId: Number(currId),
    standardIds: uniq(curriculumToStandard[currId]),
  }))
}

export const defaultAlignment = {
  standards: [],
  grades: [],
  domains: [],
  subject: '',
  curriculumId: '',
  curriculum: '',
}

export const getAllStandardsFromCriterias = (criterias) => {
  return uniqBy(
    criterias.flatMap((criteria) => {
      if (!criteria.alignment) return []
      if (criteria.alignment.standards) return criteria.alignment.standards
      return criteria.alignment.flatMap((al) => {
        if (al.standards) return al.standards
        if (al.domains)
          return al.domains.flatMap((d) =>
            d.standards.map((std) => ({
              ...std,
              curriculumId: d.curriculumId,
              identifier: std.name,
              _id: std.id,
              tloId: d.id,
              tloIdentifier: d.name,
            }))
          )
        return []
      })
    }),
    '_id'
  )
}

export const getAllStandardsFromCriteriasObj = (criterias) => {
  return uniqBy(
    criterias
      .flatMap((criteria) => criteria?.alignment?.standards || [])
      .flat(),
    '_id'
  )
}

export const convertCriteriaAlignment = (criteria) =>
  criteria.map((criterion) => {
    // Convert alignment array to object
    if (isArray(criterion.alignment) && criterion.alignment?.length) {
      const newAlignment = {
        ...criterion.alignment[0],
        standards: getAllStandardsFromCriterias([criterion]),
      }
      return { ...criterion, alignment: newAlignment }
    }
    return { ...criterion }
  })

export const getAlignmentStandardMap = (
  rubricStandardIds,
  newStandards,
  alignments
) => {
  const alignmentIndexMap = {}
  if (rubricStandardIds.size || newStandards.length) {
    const updatedAlignmentStandards = [
      ...(alignments[0]?.standards?.filter(
        ({ _id }) => !rubricStandardIds.has(_id)
      ) || []),
      ...newStandards,
    ]

    alignmentIndexMap[0] = {
      standards: uniqBy(updatedAlignmentStandards, '_id'),
    }

    if (rubricStandardIds.size) {
      for (let i = 1; i < alignments.length; i += 1) {
        alignmentIndexMap[i] = {
          standards:
            alignments[i].standards.filter(
              ({ _id }) => !rubricStandardIds.has(_id)
            ) || [],
        }
      }
    }
  }
  return alignmentIndexMap
}

/**
 * @param {Record<string, string>} selectedRatings
 * @param {Record<string, any>} criteriaRatingMap
 */
export function buildRubricFeedback(selectedRatings, criteriaRatingMap) {
  return Object.fromEntries(
    Object.entries(selectedRatings).map(([criteriaId, ratingId]) => {
      return [
        criteriaId,
        {
          ratingId,
          score: criteriaRatingMap[criteriaId].ratingsMap[ratingId].points,
          maxScore: criteriaRatingMap[criteriaId].maxScore,
          standardIds: getAllStandardsFromCriterias([
            criteriaRatingMap[criteriaId],
          ]).map((s) => s._id),
        },
      ]
    })
  )
}
