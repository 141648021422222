import { EduIf, notification } from '@edulastic/common'
import { IconWarnCircle } from '@edulastic/icons'
import React, { useEffect, useState } from 'react'
import { SortableElement } from 'react-sortable-hoc'
import styled from 'styled-components'
import { Popover } from 'antd'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { DragSource } from 'react-dnd'
import { validateQuestionsForGoogleForm } from '../../../../../../../common/utils/helpers'
import ReviewItem from '../../ReviewItem'
import { DragCrad, ReviewItemWrapper } from '../styled'
import DragHandle from './DragHandle'
import {
  enableSectionReOrderSelector,
  getTestSelector,
  isSectionAdaptiveTestSelector,
} from '../../../../../ducks'

const itemSource = {
  beginDrag(props) {
    const { onSetDragState, item } = props
    onSetDragState({
      itemId: item._id,
      groupId: item.groupId,
    })
    return { itemId: item._id, groupId: item.groupId }
  },
  endDrag(props) {
    const { onSetDragState } = props
    onSetDragState({
      itemId: null,
      groupId: null,
    })
  },
  canDrag(props) {
    const {
      enableSectionReOrder,
      test,
      isEditable,
      isSectionAdaptiveTest,
    } = props
    if (
      test?.hasSections &&
      test?.isInEditAndRegrade &&
      !isSectionAdaptiveTest
    ) {
      notification({ messageKey: 'disabledDragForRegrade' })
    }
    return enableSectionReOrder && isEditable
  },
}

function collect(connectDrag, monitor) {
  return {
    connectDragSource: connectDrag.dragSource(),
    connectDragPreview: connectDrag.dragPreview(),
    isDragging: monitor.isDragging(),
  }
}

const ReviewItemComponent = DragSource(
  'item',
  itemSource,
  collect
)(({ connectDragSource, ...props }) => {
  return connectDragSource(
    <div className="item" style={{ width: 'calc(100% - 35px)' }}>
      <ReviewItemWrapper
        data-cy={props.item._id}
        fullWidth={props.groupMinimized}
      >
        <ReviewItem {...props} />
      </ReviewItemWrapper>
    </div>
  )
})

const SortableItem = SortableElement((props) => {
  const { item, isEditable, expand, groupMinimized, test } = props

  const isGoogleFormTest = test?.importData?.googleForm
  const isAIDocExtractTest = test?.aiDocExtracted

  const isValid =
    isGoogleFormTest || isAIDocExtractTest
      ? validateQuestionsForGoogleForm(item?.data?.questions || [], false)
      : true

  return (
    <DragCrad data-cy="drag-card" noPadding={groupMinimized}>
      <EduIf condition={!isValid}>
        <StyledInfoIconWrapper>
          <Popover
            placement="bottomLeft"
            content={
              <>
                Item is missing content or answer. <br />
                Please add them by editing this item.
              </>
            }
          >
            <IconWarnCircle />
          </Popover>
        </StyledInfoIconWrapper>
      </EduIf>
      {!expand && !groupMinimized && (
        <DragHandle isEditable={isEditable} indx={item.indx} />
      )}
      <ReviewItemComponent {...props} />
    </DragCrad>
  )
})

const SingleItem = (props) => {
  const { item, isCollapse, removeItem, disabled, ...rest } = props

  const [expand, toggleExpand] = useState(false)

  const toggleExpandRow = () => toggleExpand(!expand)

  const handleDelete = () => {
    removeItem(item._id)
  }

  useEffect(() => {
    toggleExpand(!isCollapse)
  }, [isCollapse])

  return (
    <SortableItem
      {...rest}
      item={item}
      expand={expand}
      disabled={disabled || expand}
      onDelete={handleDelete}
      toggleExpandRow={toggleExpandRow}
    />
  )
}

const enhance = compose(
  connect(
    (state) => ({
      test: getTestSelector(state),
      isSectionAdaptiveTest: isSectionAdaptiveTestSelector(state),
      enableSectionReOrder: enableSectionReOrderSelector(state),
    }),
    {}
  )
)

export default enhance(SingleItem)

const StyledInfoIconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  left: 0px;
  top: 20px;
`
