/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconSubscriptionHighlight = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19.176 19.176"
    {...props}
  >
    <path d="M9.588,0a9.588,9.588,0,1,0,9.588,9.588A9.588,9.588,0,0,0,9.588,0ZM12.2,13.29a3.046,3.046,0,0,1-2.043.934V15.58H9.024V14.231a2.974,2.974,0,0,1-2.759-2.589l1.756-.458a1.526,1.526,0,0,0,1.615,1.483,1.251,1.251,0,0,0,.945-.317,1.072,1.072,0,0,0,.3-.767.845.845,0,0,0-.3-.7,5.457,5.457,0,0,0-1.344-.609,7.906,7.906,0,0,1-1.463-.643,2.416,2.416,0,0,1-.856-.887,2.632,2.632,0,0,1-.329-1.332,2.953,2.953,0,0,1,.588-1.793,2.625,2.625,0,0,1,1.847-.973V3.6h1.135V4.643A2.746,2.746,0,0,1,12.623,6.8l-1.564.641q-.458-1.32-1.415-1.32a1.033,1.033,0,0,0-.771.295.98.98,0,0,0-.291.715.8.8,0,0,0,.281.658,4.825,4.825,0,0,0,1.2.566,10.657,10.657,0,0,1,1.586.7,2.492,2.492,0,0,1,.919.907,2.609,2.609,0,0,1,.343,1.353A2.862,2.862,0,0,1,12.2,13.29Z" />
  </SVG>
)

export default withIconStyles(IconSubscriptionHighlight)
