import React from 'react'
import { EmptyText, StyledText, StyledTable } from './styled'
import ClassTitle from './components/Title'
import ClassListActions from './components/ClassListActions'

const ClassListView = ({ activeClasses }) => {
  const columns = [
    {
      key: 'name',
      width: '30%',
      dataIndex: 'name',
      render: (_, record) => <ClassTitle classInfo={record} />,
    },
    {
      key: 'studentCount',
      width: '62%',
      dataIndex: 'studentCount',
      render: (studentCount) => {
        const count = Math.max(studentCount ?? 0, 0)
        return (
          <StyledText>
            {count} Student{count > 0 && 's'}
          </StyledText>
        )
      },
    },
    {
      key: 'actions',
      width: '8%',
      render: (_, record) => <ClassListActions classInfo={record} />,
    },
  ]
  return (
    <StyledTable
      pagination={false}
      dataSource={activeClasses}
      columns={columns}
      locale={{ emptyText: <EmptyText /> }}
    />
  )
}

export default ClassListView
