/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconMagnify = (props) => (
  <SVG
    data-cy="magnifier"
    xmlns="http://www.w3.org/2000/svg"
    width="20.058"
    height="20.058"
    viewBox="0 0 22.114 22.141"
    {...props}
  >
    <g transform="translate(-0.2)">
      <g transform="translate(0.2)">
        <g transform="translate(0)">
          <path
            d="M21.722,18.7l-4.116-4.116a2.064,2.064,0,0,0-1.426-.592,1.533,1.533,0,0,0-.592.108l-1.453-1.453A7.725,7.725,0,0,0,15.8,7.8,7.807,7.807,0,0,0,8,0,7.807,7.807,0,0,0,.2,7.8a7.715,7.715,0,0,0,2.287,5.515,7.79,7.79,0,0,0,10.358.646L14.3,15.415a2.1,2.1,0,0,0-.108.592,1.893,1.893,0,0,0,.592,1.4L18.9,21.549a1.981,1.981,0,0,0,2.825,0,2.064,2.064,0,0,0,.592-1.426A2,2,0,0,0,21.722,18.7Zm-9.308-6.511a6.235,6.235,0,1,1,1.8-4.385A6.2,6.2,0,0,1,12.414,12.187Zm8.179,8.205a.39.39,0,0,1-.538,0l-4.143-4.116a.377.377,0,0,1,.269-.646.32.32,0,0,1,.3.108l4.116,4.116a.39.39,0,0,1,0,.538Z"
            transform="translate(-0.2 0)"
          />
        </g>
      </g>
      <g transform="translate(5.661 2.887)">
        <path
          d="M88.636,47.468a3.932,3.932,0,0,0-.4-1.695,4.593,4.593,0,0,0-1-1.4,4.867,4.867,0,0,0-2.5-1.345,5.1,5.1,0,0,0-2.825.242.809.809,0,0,0,.592,1.507,3.334,3.334,0,0,1,1.91-.161,3.211,3.211,0,0,1,1.668.915,3.344,3.344,0,0,1,.673.942,3.19,3.19,0,0,1,.3,1.13.8.8,0,1,0,1.587-.135Z"
          transform="translate(-81.399 -42.93)"
        />
      </g>
    </g>
  </SVG>
)

export default withIconStyles(IconMagnify)
