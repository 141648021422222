import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconAIGradientTick = (props) => (
  <SVG
    width="12"
    height="10"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.86339 7.58336L1.08339 4.80336L0.136719 5.74336L3.86339 9.47003L11.8634 1.47003L10.9234 0.530029L3.86339 7.58336Z"
      fill="url(#paint0_linear_486_250)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_486_250"
        x1="11.8634"
        y1="0.530041"
        x2="0.00298117"
        y2="0.711135"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#007D65" />
        <stop offset="1" stopColor="#1766CE" />
      </linearGradient>
    </defs>
  </SVG>
)

export default withIconStyles(IconAIGradientTick)
