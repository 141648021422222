import React from 'react'
import styled from 'styled-components'

const TabTitle = ({ title, icon, ...rest }) => {
  return (
    <Container {...rest}>
      {icon}
      <Title data-cy={`${title}Tab`}>{title}</Title>
    </Container>
  )
}

export default TabTitle

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
const Title = styled.p`
  font-size: 14px;
  font-weight: 600;
`
