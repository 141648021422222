import React, { useEffect, useRef } from 'react'
import { getDesmosConfig } from '@edulastic/common'
import { Calculator } from './styled-components'

export const ScientificDesmosCalculator = ({ calcMode, schoolState }) => {
  const desmosScientificRef = useRef()

  useEffect(() => {
    const Desmos = window.Desmos
    if (desmosScientificRef.current && Desmos) {
      const config = getDesmosConfig(schoolState, calcMode)
      const desmosScientificCalculator = Desmos.ScientificCalculator(
        desmosScientificRef.current,
        config
      )

      return () => {
        desmosScientificCalculator.destroy()
      }
    }
  }, [schoolState, calcMode])

  return (
    <Calculator id="demos-scientific-calculator" ref={desmosScientificRef} />
  )
}
