/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconSeeAllArrow = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path d="M7.99935 2.66666L7.05935 3.60666L10.7793 7.33333H2.66602V8.66666H10.7793L7.05935 12.3933L7.99935 13.3333L13.3327 8L7.99935 2.66666Z" />
  </SVG>
)

export default withIconStyles(IconSeeAllArrow)
