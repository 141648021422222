/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconDownwardArrow = (props) => (
  <SVG
    width="14"
    height="47"
    viewBox="0 0 14 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.333252 39.6667L6.99992 46.3334L13.6666 39.6667H0.333252Z"
      fill="#999999"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 40V0H8V40H6Z"
      fill="#D8D8D8"
    />
  </SVG>
)

export default withIconStyles(IconDownwardArrow)
