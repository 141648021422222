import aiAPI from '@edulastic/api/src/aiAPI'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { createSelector } from 'reselect'

export const namespaceKey = 'aiRewrite'

// actions constants
export const GENERATE_AI_REWRITTEN_CONTENT = `[${namespaceKey}] generate rewritten content`
export const SET_AI_REWRITE_STATE = `[${namespaceKey}] set state`
export const RESET_AI_REWRITE_STATE = `[${namespaceKey}] reset state`

// actions
export const generateAIRewrittenContentAction = (payload) => ({
  type: GENERATE_AI_REWRITTEN_CONTENT,
  payload,
})

export const setAIRewriteContentStateAction = (payload) => ({
  type: SET_AI_REWRITE_STATE,
  payload,
})

export const resetAIRewriteContentStateAction = () => ({
  type: RESET_AI_REWRITE_STATE,
})

// selectors
export const stateSelector = (state) => state?.[namespaceKey]

export const getAIRewriteContentState = createSelector(
  stateSelector,
  (state) => state || {}
)

// initial state
const initialState = {
  apiStatus: null,
  result: null,
  errorMessage: null,
}

// reducer
export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_AI_REWRITE_STATE:
      return {
        ...state,
        ...payload,
      }
    case RESET_AI_REWRITE_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

// sagas
function* generateAIRewrittenContentSaga({ payload }) {
  const generalErrorMessage = 'Something went wrong'

  try {
    yield put(
      setAIRewriteContentStateAction({
        apiStatus: 'INITIATED',
        result: null,
        errorMessage: null,
      })
    )
    const { result } = yield call(aiAPI.generateRewrittenContent, payload)
    yield put(
      setAIRewriteContentStateAction({
        apiStatus: 'SUCCESS',
        result,
        errorMessage: null,
      })
    )
  } catch (err) {
    yield put(
      setAIRewriteContentStateAction({
        apiStatus: 'FAILED',
        result: null,
        errorMessage: err?.message || generalErrorMessage,
      })
    )
  }
}

export function* watcherSaga() {
  yield all([
    takeEvery(GENERATE_AI_REWRITTEN_CONTENT, generateAIRewrittenContentSaga),
  ])
}
