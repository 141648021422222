import React, { useState } from 'react'
import styled from 'styled-components'
import { themeColor, lightGrey, fadedBlack2 } from '@edulastic/colors'
import { assignmentApi } from '@edulastic/api'
import {
  CustomModalStyled,
  EduButton,
  captureSentryException,
  notification,
} from '@edulastic/common'
import { contentSharing } from '../../../TestList/components/ViewModal/utils'

const TestPermissionsBannerAndModal = ({
  accessLevel = 'ASSIGN',
  isEasyAssignEnabled = true,
  history,
  test,
}) => {
  const [readMoreModalVisible, setReadMoreModalVisible] = useState(false)

  const onCloseModal = () => setReadMoreModalVisible(false)
  const createDuplicateAssignment = () => {
    assignmentApi
      .duplicateAssignment({
        _id: test._id,
        title: test.title,
      })
      .then((testItem) => {
        const duplicateTestId = testItem._id
        history.push(`/author/tests/${duplicateTestId}`)
      })
      .catch((err) => {
        const {
          data: { message: errorMessage },
        } = err.response
        captureSentryException(err)
        if (err?.status === 403) {
          return notification({
            msg:
              err?.message ||
              'You do not have the permission to clone the test.',
          })
        }
        notification({
          msg: errorMessage || 'User does not have duplicate permission.',
        })
      })
  }
  return (
    <AccessMessageContainer isEasyAssignEnabled={isEasyAssignEnabled}>
      {accessLevel === 'ASSIGN'
        ? 'You can view and assign the test, but you don’t have edit rights for any test content updates.'
        : accessLevel === 'VIEW'
        ? 'You don’t have test edit rights, but you can create a clone for any content updates after assigning the test.'
        : 'You have edit rights to this test, note that any future content changes will affect existing assignments, if any.'}
      <div
        style={{
          color: themeColor,
          marginTop: '10px',
          cursor: 'pointer',
          fontWeight: 600,
        }}
        onClick={() => setReadMoreModalVisible(true)}
      >
        Read more
      </div>
      <CustomModalStyled
        visible={readMoreModalVisible}
        title={
          <span style={{ fontSize: '18px', fontWeight: 400, color: 'black' }}>
            {accessLevel === contentSharing.ASSIGN
              ? 'View and Assign Only Permission'
              : accessLevel === contentSharing.VIEW
              ? 'You have Test Clone Permission'
              : 'You have Test Edit Permission'}
          </span>
        }
        onCancel={onCloseModal}
        maskClosable={false}
        centered
        footer={[
          <EduButton height="40px" key="okButton" onClick={onCloseModal}>
            Okay, Got it
          </EduButton>,
        ]}
      >
        <div style={{ fontSize: '14px' }}>
          {accessLevel === 'ASSIGN' ? (
            <ModalBodyParagraph>
              You can view and assign this test but cannot edit its content. If
              you need to make changes or corrections to the test content later,
              please request edit rights from the author.
            </ModalBodyParagraph>
          ) : accessLevel === 'VIEW' ? (
            <div>
              <ModalBodyParagraph>
                You don&apos;t have permission to edit the test content, but you
                can clone it to create your own editable copy. If you think you
                might need to update or correct the test content later, please
                <span
                  style={{
                    color: themeColor,
                    fontWeight: 700,
                    cursor: 'pointer',
                  }}
                  onClick={createDuplicateAssignment}
                >
                  {' '}
                  clone the test
                </span>{' '}
                before assigning it.
              </ModalBodyParagraph>{' '}
              <ModalBodyParagraph color={fadedBlack2}>
                Note : Don’t clone if this is a common assignment conducted
                across multiple teachers&apos; classes and the admin needs to
                view reports collectively.
              </ModalBodyParagraph>
            </div>
          ) : (
            <div>
              <ModalBodyParagraph>
                You have edit rights for this test. Future changes you make to
                the test content will affect your existing current year
                assignments for this test, if any.
              </ModalBodyParagraph>
              <ModalBodyParagraph>
                If you prefer future content updates related to the upcoming
                assignment not impact current assignments, please{' '}
                <span
                  style={{
                    color: themeColor,
                    fontWeight: 700,
                    cursor: 'pointer',
                  }}
                  onClick={createDuplicateAssignment}
                >
                  clone the test
                </span>{' '}
                before assigning it.
              </ModalBodyParagraph>
            </div>
          )}
        </div>
      </CustomModalStyled>
    </AccessMessageContainer>
  )
}

export default TestPermissionsBannerAndModal

const AccessMessageContainer = styled.div`
  background: ${lightGrey};
  border: 1px solid #eeeeee;
  font-size: 12px;
  font-weight: 400;
  width: 180px;
  position: absolute;
  top: ${({ isEasyAssignEnabled }) =>
    isEasyAssignEnabled ? '200px' : '250px'};
  padding: 20px;
  line-height: 18px;
  right: 77%;
  z-index: 10;
  border-radius: 5px;
`

const ModalBodyParagraph = styled.p`
  font-weight: 400 !important;
  color: ${({ color }) => color || 'black'} !important;
`
