/* eslint-disable func-names */
import { useCallback, useState, useRef, useMemo, useEffect } from 'react'
import { isEmpty } from 'lodash'
import {
  clearSelection,
  decodeHTML,
  highlightSelectedText,
  getRangeAtFirst,
} from '@edulastic/common'
import { highlightTag, getPositionOfElement } from '../utils/helper'

const useTextHighlight = ({
  toggleOpen,
  passageContent,
  highlightedContent,
  onChangeContent,
  itemId,
  disableResponse,
}) => {
  const rangRef = useRef()
  const mainContentsRef = useRef()
  const [isMounted, setIsMounted] = useState(false)
  const [selectHighlight, setSelectedHighlight] = useState(null)

  const content = useMemo(() => {
    const decoded = decodeHTML(passageContent)
    if (isEmpty(highlightedContent)) {
      // replacing all non styled paragraph with plane text with <br /> in end for new line
      return decoded.replace(/<p>((?:(?!<p).)*)<\/p>/g, '$1<br />')
    }
    return highlightedContent
  }, [highlightedContent, passageContent])

  const storeUpdatedContent = useCallback(() => {
    let { innerHTML: highlightContent = '' } = mainContentsRef.current

    if (
      highlightContent.search(new RegExp(`<${highlightTag}(.*?)>`, 'g')) === -1
    ) {
      highlightContent = null
    } else {
      highlightContent = highlightContent.replace(/input__math/g, '')
    }

    onChangeContent(highlightContent)
  }, [onChangeContent])

  const finishedRendering = useCallback(() => {
    // This was needed for iOS safari. need to render one more time.
    if (!isMounted) {
      setIsMounted(!isMounted)
    }
  }, [isMounted])

  const handleClickBackdrop = useCallback(() => {
    setSelectedHighlight(null)
  }, [])

  const updateColor = useCallback(
    (color) => {
      if (mainContentsRef.current && selectHighlight) {
        const element = $(mainContentsRef.current).find(
          `#${selectHighlight.id}`
        )

        // Find all child elements with the highlightTag, including the main element itself
        const elementsToUpdate = element.add(element.find(highlightTag))
        const reversedElements = $(elementsToUpdate.toArray().reverse())

        reversedElements.each(function () {
          if (color === 'remove') {
            // Replace each element with its inner HTML, effectively removing the highlight
            $(this).replaceWith($(this).html())
          } else {
            // Set background color on each element
            $(this).css('background-color', color)
          }
        })

        clearSelection()
        setSelectedHighlight(null)
        storeUpdatedContent()
      }
    },
    [selectHighlight, storeUpdatedContent]
  )

  const onSelectColor = useCallback(
    (color, selection = rangRef.current) => {
      if (color !== 'remove') {
        highlightSelectedText(
          null, // parent container class, needed in token highlight type
          'text-highlight',
          highlightTag,
          {
            background: color,
          },
          selection
        )
        storeUpdatedContent()
      }
      clearSelection()
      toggleOpen(false)
    },
    [storeUpdatedContent]
  )

  const handleMouseUp = useCallback(() => {
    rangRef.current = getRangeAtFirst()
  }, [])

  const addEventToSelectedText = useCallback(() => {
    if (window.$) {
      $(mainContentsRef.current)
        .find(highlightTag)
        .each(function (index) {
          // Check if there is a parent with the same highlightTag
          const isOutermostHighlight =
            $(this).parents(highlightTag).length === 0

          if (isOutermostHighlight) {
            const newId = `highlight-${itemId}-${index}`

            $(this).attr('id', newId)
            $(this)
              .off()
              .on('mousedown', function (e) {
                e.preventDefault()
                e.stopPropagation()
                const pos = getPositionOfElement(this)
                setSelectedHighlight({ ...pos, id: newId })
              })
          }
        })
    }
  }, [itemId])

  useEffect(() => {
    if (!disableResponse && mainContentsRef.current) {
      setTimeout(addEventToSelectedText, 10)
    }
    // DO NOT ADD DEPENDENCIES, should track highlight tag in every renders
  })

  return {
    finishedRendering,
    handleClickBackdrop,
    updateColor,
    onSelectColor,
    handleMouseUp,
    mainContentsRef,
    content,
    selectHighlight,
  }
}

export default useTextHighlight
