import styled from 'styled-components'
import { Button } from 'antd'
import { green, greyThemeDark2, themeColor } from '@edulastic/colors'

export const AddMoreStandardsButton = styled(Button)`
  &.ant-btn {
    background: transparent;
    color: ${({ disabled }) => (disabled ? greyThemeDark2 : themeColor)};
    padding: 0px 12px;
    box-shadow: none;
    min-height: 34px;
    text-transform: uppercase;
    font-size: 11px;
    outline: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    border: 1px solid transparent !important;
    opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};

    &:hover,
    &:active,
    &:visited {
      background: transparent;
      color: ${({ disabled }) => (disabled ? greyThemeDark2 : themeColor)};
      outline: none !important;
      box-shadow: none !important;
    }

    &:focus {
      border: 1px solid ${green} !important;
    }
  }
`
