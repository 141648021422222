/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconNewFile = (props) => (
  <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 50" {...props}>
    <g transform="translate(-7.092)">
      <path d="M7.11,39.54a3.82,3.82,0,0,0,1.107,2.9,3.6,3.6,0,0,0,2.381.942c.039,0,.064,0,.072,0H37.024a3.706,3.706,0,0,0,2.722-1.05,4.191,4.191,0,0,0,1.115-2.786V8.837a1.392,1.392,0,0,0-.4-.974L33,.4a1.4,1.4,0,0,0-.974-.4H10.67A3.378,3.378,0,0,0,8.023,1.109,4.5,4.5,0,0,0,7.11,4.34v35.2ZM31.333,2.754h.119l6.321,6.321H32.691a1.472,1.472,0,0,1-.965-.353,1.7,1.7,0,0,1-.394-1.264V2.754Zm-21.241.173c.038-.043.152-.174.579-.174H28.579V7.461a4.311,4.311,0,0,0,1.249,3.261,4.289,4.289,0,0,0,2.844,1.112c.046,0,.077,0,.09,0h5.345v27.7c-.024,1.091-.821,1.091-1.082,1.091l-26.426,0a.887.887,0,0,1-.485-.187,1.36,1.36,0,0,1-.247-.9V4.269a1.392,1.392,0,0,0-.012-.176,1.874,1.874,0,0,1,.237-1.165Z" />
    </g>
  </SVG>
)

export default withIconStyles(IconNewFile)
