/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconScratchPad = (props) => (
  <SVG
    data-cy="scratchPad"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18.811 23.076"
    {...props}
  >
    <g transform="translate(-391.096 -217.111)">
      <path
        d="M485.357,374.617l-1.025.513q-2.566,1.282-5.131,2.565a.7.7,0,0,1-1.043-.833q.608-2.421,1.211-4.844a.684.684,0,1,1,1.319.313c-.151.611-.307,1.221-.46,1.832-.14.559-.277,1.118-.424,1.708.1-.046.172-.077.242-.112q2.937-1.469,5.876-2.936a1.2,1.2,0,0,1,.51-.144.664.664,0,0,1,.6.886q-.453,1.8-.916,3.6-.471,1.841-.946,3.681a.688.688,0,0,1-.644.591.666.666,0,0,1-.678-.858c.248-1,.507-1.99.761-2.985l.719-2.811C485.332,374.746,485.338,374.71,485.357,374.617Z"
        transform="translate(-82.121 -145.6)"
      />
      <path
        d="M409.9,220.1c0-.57-.253-.823-.826-.823h-.609v-.039c0,.008-.011.017-.018.029h-2.962c0-.444,0-.877,0-1.311a1.471,1.471,0,0,0-.028-.357.672.672,0,0,0-1.318.151c-.018.262-.006.526-.007.788,0,.238,0,.477,0,.727h-2.985c0-.068,0-.139,0-.21,0-.406.006-.811,0-1.217a.689.689,0,0,0-.694-.728.676.676,0,0,0-.657.734c0,.465,0,.93,0,1.413h-3c0-.235,0-.466,0-.7,0-.278.006-.556-.012-.833a.658.658,0,0,0-.654-.618.649.649,0,0,0-.667.578c-.03.3-.018.6-.021.9,0,.217,0,.435,0,.69h-3.516c-.573,0-.826.253-.826.823q0,9.589-.006,19.178a.869.869,0,0,0,.5.909H409.4a.869.869,0,0,0,.5-.909Q409.894,229.69,409.9,220.1Zm-1.369,18.724H392.47V220.642h2.973c0,.225,0,.44,0,.655,0,.308-.01.617.02.922a.665.665,0,0,0,1.324-.045c.017-.427.006-.856.008-1.284,0-.08,0-.16,0-.248h3c0,.5-.007,1,0,1.491a.665.665,0,0,0,.807.656.682.682,0,0,0,.542-.706c.006-.473,0-.945,0-1.436h2.984v.392c0,.353-.006.706,0,1.059a.676.676,0,1,0,1.35,0c.005-.479,0-.958,0-1.45h2.98v0h.065Z"
        transform="translate(0 0)"
      />
    </g>
  </SVG>
)

export default withIconStyles(IconScratchPad)
