export const responseDisplayOption = {
  choiceMatrix: { value: 'TEI' },
  clozeImageDragDrop: { value: 'TEI' },
  clozeImageDropDown: { value: 'TEI' },
  clozeImageText: { value: 'TEI' },
  sortList: { value: 'TEI' },
  classification: { value: 'TEI' },
  matchList: { value: 'TEI' },
  orderList: { value: 'TEI' },
  essayRichText: { value: 'Constructed Response' },
  essayPlainText: { value: 'Constructed Response' },
  shortText: { value: 'Constructed Response' },
  audioResponse: { value: 'Constructed Response' },
  passage: { value: 'TEI' },
  highlightImage: { value: 'TEI' },
  shading: { value: 'TEI' },
  hotspot: { value: 'TEI' },
  tokenhighlight: { value: 'TEI' },
  math: { value: 'TEI', titles: ['Complete the Equation', 'Matrices'] },
  formulaessay: { value: 'Constructed Response' },
  graph: { value: 'TEI' },
  fractionEditor: { value: 'TEI' },
  bar: { value: 'TEI' },
  line: { value: 'TEI' },
  histogram: { value: 'TEI' },
  dots: { value: 'TEI' },
  linePlot: { value: 'TEI' },
  expressionMultipart: { value: 'TEI' },
  combinationMultipart: { value: 'TEI' },
  video: { value: 'TEI' },
  text: { value: 'TEI' },
  protractor: { value: 'TEI' },
  coding: { value: 'TEI' },
}
