import React from 'react'
import { connect } from 'react-redux'
import produce from 'immer'

import { updateRubricDataAction, getCurrentRubricDataSelector } from '../ducks'
import RatingBox from './RatingBoxContainer'

const Rating = ({
  data,
  id,
  parentId,
  currentRubricData,
  updateRubricData,
  isEditable,
  className,
  handleOnClickExpand = () => {},
  selectedRatingToExpand = '',
}) => {
  const allRatings = currentRubricData.criteria.find((c) => c.id === parentId)
    .ratings
  const handleDelete = () => {
    const updatedRubricData = produce(currentRubricData, (draft) => {
      draft.criteria.map((criteria) => {
        if (criteria.id === parentId) {
          const ratings = criteria.ratings.filter((r) => r.id !== id)
          criteria.ratings = ratings
        }
        return criteria
      })
    })
    updateRubricData(updatedRubricData)
  }

  const ratingProps = {
    className,
    id,
    parentId,
    isEditable,
    data,
    allRatings,
    handleDelete,
    selectedRatingToExpand,
    handleOnClickExpand,
  }

  return (
    <>
      <RatingBox {...ratingProps} />
    </>
  )
}

export default connect(
  (state) => ({
    currentRubricData: getCurrentRubricDataSelector(state),
  }),
  {
    updateRubricData: updateRubricDataAction,
  }
)(Rating)
