import React from 'react'
import withIconStyles from '@edulastic/icons/src/HOC/withIconStyles'
import SVG from '@edulastic/icons/src/common/SVG'

const IconGraphSegment = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="31.271"
    height="12.561"
    viewBox="0 0 31.271 12.561"
    {...props}
  >
    <g transform="translate(-292.129 -257.073)">
      <path d="M296.341,267.041a1,1,0,0,1-.223-1.975l20.645-4.763a1,1,0,1,1,.449,1.948l-20.645,4.764A1.031,1.031,0,0,1,296.341,267.041Z" />
      <path d="M297.893,262.671a3.767,3.767,0,1,1-5.191,1.2,3.766,3.766,0,0,1,5.191-1.2Z" />
      <path d="M321.629,257.646a3.768,3.768,0,1,1-5.193,1.2h0A3.768,3.768,0,0,1,321.629,257.646Z" />
    </g>
  </SVG>
)

export default withIconStyles(IconGraphSegment)
