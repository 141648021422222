import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const Cursor = withIconStyles((props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="9.129"
    height="14.597"
    viewBox="0 0 9.129 14.597"
    {...props}
  >
    <path
      d="M9.318,8.337,1.076.1A.47.47,0,0,0,.32.446V11.705a.457.457,0,0,0,.456.456.433.433,0,0,0,.3-.111l2.283-1.99L5.22,14.412a.305.305,0,0,0,.166.163A.318.318,0,0,0,5.5,14.6a.305.305,0,0,0,.12-.025l1.96-.84a.3.3,0,0,0,.16-.4l-1.8-4.213H8.993a.457.457,0,0,0,.456-.456.507.507,0,0,0-.132-.325Zm0,0"
      transform="translate(-0.32 0)"
      fill="currentColor"
    />
  </SVG>
))

const AnswerEliminator = withIconStyles((props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="18.729"
    height="18.724"
    viewBox="0 0 18.729 18.724"
    {...props}
  >
    <g transform="translate(-0.001 -0.07)">
      <path
        d="M-12887.27-4099.844a9.3,9.3,0,0,1-6.622-2.738,9.309,9.309,0,0,1-2.744-6.625,9.3,9.3,0,0,1,2.744-6.622,9.3,9.3,0,0,1,6.622-2.738,9.3,9.3,0,0,1,6.622,2.738,9.308,9.308,0,0,1,2.74,6.625,9.3,9.3,0,0,1-2.74,6.623A9.3,9.3,0,0,1-12887.27-4099.844Zm.046-8.383h0l2.054,2.051a.723.723,0,0,0,.512.214.723.723,0,0,0,.515-.214.729.729,0,0,0,0-1.026l-2.056-2.049,2.056-2.052a.73.73,0,0,0,0-1.026.725.725,0,0,0-.515-.213.713.713,0,0,0-.512.213l-2.055,2.052-2.056-2.052a.719.719,0,0,0-.514-.213.713.713,0,0,0-.512.213.717.717,0,0,0-.214.51.722.722,0,0,0,.214.517l2.055,2.052-2.055,2.049a.712.712,0,0,0-.214.51.719.719,0,0,0,.214.516.717.717,0,0,0,.511.214.727.727,0,0,0,.515-.214l2.055-2.051Z"
        transform="translate(12896.636 4118.638)"
        fill="currentColor"
      />
    </g>
  </SVG>
))

const Zoom = withIconStyles((props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="15.891"
    height="16.209"
    viewBox="0 0 15.891 16.209"
    {...props}
  >
    <g transform="translate(-0.109)">
      <path
        d="M11.18,9.618a6.094,6.094,0,1,0-1.875,1.726L13.8,15.812a1.276,1.276,0,0,0,1.85-1.757c-.015-.016-.03-.031-.047-.046ZM6.2,10.035A3.931,3.931,0,1,1,10.131,6.1,3.932,3.932,0,0,1,6.2,10.035Zm0,0"
        transform="translate(0 0)"
        fill="currentColor"
      />
    </g>
  </SVG>
))

const IconNext = withIconStyles((props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="8.689"
    height="15.244"
    viewBox="0 0 8.689 15.244"
    {...props}
  >
    <path d="M8.377,6.867,1.822.313A1.067,1.067,0,0,0,.313,1.822l5.8,5.8-5.8,5.8a1.067,1.067,0,0,0,1.51,1.51L8.377,8.377a1.067,1.067,0,0,0,0-1.509Z" />
  </SVG>
))

const IconPrevious = withIconStyles((props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="8.689"
    height="15.243"
    viewBox="0 0 8.689 15.243"
    {...props}
  >
    <path
      d="M105.515,8.376,98.961,14.93a1.067,1.067,0,0,1-1.51-1.509l5.8-5.8-5.8-5.8a1.067,1.067,0,0,1,1.51-1.51l6.554,6.554a1.067,1.067,0,0,1,0,1.509Z"
      transform="translate(105.828 15.243) rotate(180)"
    />
  </SVG>
))

export default {
  Cursor,
  AnswerEliminator,
  Zoom,
  IconNext,
  IconPrevious,
}
