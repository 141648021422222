import { SelectInputStyled } from '@edulastic/common'
import styled from 'styled-components'
import { Description } from '../../../TestPage/components/Setting/components/Container/styled'

export const CalculatorDropdown = styled(SelectInputStyled)`
  .ant-select-selection--multiple {
    padding-bottom: 0px;
  }
`

export const Label = styled.span`
  font-size: ${(props) => props.theme.linkFontSize};
  font-weight: 600;
  text-transform: uppercase;
  align-items: center;
`

export const CalcDescription = styled(Description)`
  width: ${({ width }) => width || 'max-content'};
  margin-top: 0px;
  margin-bottom: 1.5rem;
  div {
    position: relative;
    z-index: 1;
  }
`

export const CalcCountDescription = styled(Description)`
  width: ${({ width }) => width || 'max-content'};
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 1rem;
`
