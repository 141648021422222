import { IconPearAssessLogoCompact } from '@edulastic/icons'
import React from 'react'
import styled from 'styled-components'
import { gray600 } from '@edulastic/colors'

const EmptyMessage = ({ message }) => {
  return (
    <MessageContainer>
      <IconPearAssessLogoCompact width="32" height="32" />
      <Message>{message}</Message>
    </MessageContainer>
  )
}

export default EmptyMessage

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`
export const Message = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: ${gray600};
`
