import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconGrid = (props) => (
  <SVG
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.33333 5.33333H4C4.73333 5.33333 5.33333 4.73333 5.33333 4V1.33333C5.33333 0.6 4.73333 0 4 0H1.33333C0.6 0 0 0.6 0 1.33333V4C0 4.73333 0.6 5.33333 1.33333 5.33333Z"
      fill={props.color || '#1AB395'}
    />
    <path
      d="M1.33333 12H4C4.73333 12 5.33333 11.4 5.33333 10.6667V8C5.33333 7.26667 4.73333 6.66667 4 6.66667H1.33333C0.6 6.66667 0 7.26667 0 8V10.6667C0 11.4 0.6 12 1.33333 12Z"
      fill={props.color || '#1AB395'}
    />
    <path
      d="M6.66667 1.33333V4C6.66667 4.73333 7.26667 5.33333 8 5.33333H10.6667C11.4 5.33333 12 4.73333 12 4V1.33333C12 0.6 11.4 0 10.6667 0H8C7.26667 0 6.66667 0.6 6.66667 1.33333Z"
      fill={props.color || '#1AB395'}
    />
    <path
      d="M8 12H10.6667C11.4 12 12 11.4 12 10.6667V8C12 7.26667 11.4 6.66667 10.6667 6.66667H8C7.26667 6.66667 6.66667 7.26667 6.66667 8V10.6667C6.66667 11.4 7.26667 12 8 12Z"
      fill={props.color || '#1AB395'}
    />
  </SVG>
)

export default withIconStyles(IconGrid)
