/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconMultiLanguage = (props) => (
  <SVG
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.5813 10.0463L6.88797 8.37301L6.90797 8.35301C8.06797 7.05967 8.89463 5.57301 9.3813 3.99967H11.3346V2.66634H6.66797V1.33301H5.33464V2.66634H0.667969V3.99301H8.11463C7.66797 5.27967 6.9613 6.49967 6.0013 7.56634C5.3813 6.87967 4.86797 6.12634 4.4613 5.33301H3.12797C3.61464 6.41967 4.2813 7.44634 5.11463 8.37301L1.7213 11.7197L2.66797 12.6663L6.0013 9.33301L8.07463 11.4063L8.5813 10.0463ZM12.3346 6.66634H11.0013L8.0013 14.6663H9.33463L10.0813 12.6663H13.248L14.0013 14.6663H15.3346L12.3346 6.66634ZM10.588 11.333L11.668 8.44634L12.748 11.333H10.588Z"
      fill="#858992"
    />
  </SVG>
)

export default withIconStyles(IconMultiLanguage)
