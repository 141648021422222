import {
  reportGroupType,
  reportNavType,
} from '@edulastic/constants/const/report'

const {
  WHOLE_LEARNER_REPORT,
  MULTIPLE_ASSESSMENT_REPORT_DW,
  DW_DASHBOARD_REPORT,
  DW_ATTENDANCE_SUMMARY_REPORT,
  DW_EARLY_WARNING_REPORT,
  DW_GOALS_AND_INTERVENTIONS_REPORT,
  DW_EFFICACY_REPORT,
  DW_SEL_REPORT,
  DW_SEL_RESPONSE_SUMMARY_REPORT,
  DW_DOMAIN_ANALYSIS_REPORT,
} = reportNavType
const prefix = '/author/reports'

export const DW_MAR_REPORT_URL = `${prefix}/${MULTIPLE_ASSESSMENT_REPORT_DW}`
export const DW_WLR_REPORT_URL = `${prefix}/${WHOLE_LEARNER_REPORT}/student/`
export const DW_DASHBOARD_URL = `${prefix}/${DW_DASHBOARD_REPORT}`
export const DW_ATTENDANCE_REPORT_URL = `${prefix}/${DW_ATTENDANCE_SUMMARY_REPORT}`
export const DW_EARLY_WARNING_REPORT_URL = `${prefix}/${DW_EARLY_WARNING_REPORT}`
export const DW_GOALS_AND_INTERVENTIONS_URL = `${prefix}/${DW_GOALS_AND_INTERVENTIONS_REPORT}`
export const DW_EFFICACY_REPORT_URL = `${prefix}/${DW_EFFICACY_REPORT}`
export const DW_SEL_REPORT_URL = `${prefix}/${DW_SEL_REPORT}`
export const DW_SEL_RESPONSE_SUMMARY_REPORT_URL = `${prefix}/${DW_SEL_RESPONSE_SUMMARY_REPORT}`
export const DW_DOMAIN_ANALYSIS_REPORT_URL = `${prefix}/${DW_DOMAIN_ANALYSIS_REPORT}`

export const CUSTOM_REPORTS_URL = `${prefix}/${reportGroupType.CUSTOM_REPORT}`

export const DW_REPORT_URLS = [
  DW_DASHBOARD_URL,
  DW_MAR_REPORT_URL,
  DW_WLR_REPORT_URL,
  DW_ATTENDANCE_REPORT_URL,
  DW_EARLY_WARNING_REPORT_URL,
  DW_GOALS_AND_INTERVENTIONS_URL,
  DW_EFFICACY_REPORT_URL,
  DW_SEL_REPORT_URL,
  DW_SEL_RESPONSE_SUMMARY_REPORT_URL,
  DW_DOMAIN_ANALYSIS_REPORT_URL,
]

export const DGA_VISIBLE_REPORT_GROUP = [
  reportGroupType.DATA_WAREHOUSE_REPORT,
  reportGroupType.CUSTOM_REPORT,
]

export const DGA_VISIBLE_REPORTS = [
  reportNavType.MULTIPLE_ASSESSMENT_REPORT_DW,
  reportNavType.DW_DOMAIN_ANALYSIS_REPORT,
]

export const DS_PERFORMANCE_BAND_TYPES = {
  TEST: 'TEST',
  STANDARD_SCALED_SCORE: 'STANDARD_SCALED_SCORE',
  STANDARD_RAW_SCORE: 'STANDARD_RAW_SCORE',
}
