/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconCreate = (props) => (
  <SVG
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 16.3349L10.6667 21.6682H21.3333C22.8 21.6682 24 20.4682 24 19.0015C24 17.5349 22.8 16.3349 21.3333 16.3349H16Z"
      fill="#1A4A41"
    />
    <path
      d="M12.08 4.58821L0.386667 16.2815C0.146667 16.5215 0 16.8682 0 17.2149V20.3349C0 21.0682 0.6 21.6682 1.33333 21.6682H4.45333C4.81333 21.6682 5.14667 21.5215 5.4 21.2815L17.0933 9.58821L12.08 4.58821Z"
      fill="#1A4A41"
    />
    <path
      d="M20.9467 5.72154C21.4667 5.20154 21.4667 4.36154 20.9467 3.84154L17.8267 0.721543C17.3067 0.201543 16.4667 0.201543 15.9467 0.721543L13.5067 3.16154L18.5067 8.16154L20.9467 5.72154Z"
      fill="#1A4A41"
    />
  </SVG>
)

export default withIconStyles(IconCreate)
