import { IconAITest, IconStar } from '@edulastic/icons'
import React from 'react'
import { withNamespaces } from '@edulastic/localization'
import { DollarSymbolWrapper } from '../../../../../../../AssessmentCreate/components/common/AddOnTag'
import {
  AITrialBannerCard,
  AITrialBannerDescription,
  AITrialBannerTitleContainer,
} from './styled'

const AIBannerCard = ({ videoQuizProduct, t }) => {
  return (
    <AITrialBannerCard bordered>
      <AITrialBannerTitleContainer>
        <IconAITest />
        <b>Build using AI</b>
        <DollarSymbolWrapper>
          <IconStar />
        </DollarSymbolWrapper>
      </AITrialBannerTitleContainer>
      <AITrialBannerDescription>
        Access this premium feature with{' '}
        {`${videoQuizProduct.trialPeriod} day${
          videoQuizProduct.trialPeriod > 1 ? 's' : ''
        }`}{' '}
        trial. No credit card required.
      </AITrialBannerDescription>
    </AITrialBannerCard>
  )
}

export default withNamespaces('author')(AIBannerCard)
