import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconCanvas = (props) => (
  <SVG
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/SVG"
    {...props}
  >
    <g clipPath="url(#clip0_1662_2002)">
      <path
        d="M2.90635 10.0001C2.90539 9.29394 2.64644 8.61246 2.17819 8.08387C1.70994 7.55528 1.06468 7.21601 0.363795 7.12988C-0.120614 9.01414 -0.120614 10.9903 0.363795 12.8746C1.06523 12.7879 1.71081 12.4479 2.17906 11.9185C2.64732 11.3891 2.90597 10.7069 2.90635 10.0001Z"
        fill="#E6252A"
      />
      <path
        d="M4.59147 9.09351C4.41109 9.09309 4.23465 9.14625 4.08453 9.24624C3.9344 9.34624 3.81735 9.48856 3.74823 9.65517C3.67911 9.82178 3.66102 10.0052 3.69626 10.1821C3.7315 10.359 3.81849 10.5214 3.94619 10.6488C4.07388 10.7762 4.23654 10.8628 4.41352 10.8976C4.59051 10.9325 4.77385 10.914 4.94029 10.8444C5.10674 10.7749 5.24879 10.6575 5.34843 10.5072C5.44808 10.3568 5.50082 10.1803 5.49998 9.99989C5.49886 9.75948 5.40271 9.52927 5.23251 9.35947C5.06232 9.18967 4.83188 9.09407 4.59147 9.09351Z"
        fill="#E6252A"
      />
      <path
        d="M17.1362 9.99999C17.136 10.7074 17.3944 11.3904 17.8626 11.9206C18.3309 12.4508 18.9768 12.7915 19.6788 12.8787C20.166 10.9948 20.166 9.01794 19.6788 7.13403C18.9786 7.22007 18.3339 7.55873 17.8658 8.08643C17.3976 8.61413 17.1382 9.29457 17.1362 9.99999Z"
        fill="#E6252A"
      />
      <path
        d="M15.4512 9.09351C15.2709 9.09351 15.0946 9.14702 14.9448 9.24728C14.7949 9.34753 14.6782 9.49 14.6094 9.65665C14.5406 9.82329 14.5229 10.0066 14.5583 10.1834C14.5938 10.3601 14.681 10.5224 14.8088 10.6496C14.9365 10.7768 15.0992 10.8631 15.2761 10.8978C15.453 10.9325 15.6363 10.9138 15.8026 10.8442C15.9689 10.7747 16.1108 10.6573 16.2104 10.507C16.3099 10.3567 16.3626 10.1802 16.3618 9.99989C16.3607 9.75911 16.2642 9.52858 16.0936 9.35872C15.9229 9.18886 15.6919 9.0935 15.4512 9.09351Z"
        fill="#E6252A"
      />
      <path
        d="M9.99978 17.1448C9.29163 17.1455 8.6083 17.4057 8.07903 17.8762C7.54975 18.3467 7.21121 18.9948 7.12744 19.698C9.01165 20.1827 10.9879 20.1827 12.8721 19.698C12.7884 18.9948 12.4498 18.3467 11.9205 17.8762C11.3913 17.4057 10.7079 17.1455 9.99978 17.1448Z"
        fill="#E6252A"
      />
      <path
        d="M10.0002 14.5488C9.81997 14.5484 9.6437 14.6015 9.49367 14.7013C9.34363 14.8011 9.22658 14.9431 9.15733 15.1095C9.08808 15.2759 9.06975 15.459 9.10464 15.6358C9.13954 15.8126 9.2261 15.9751 9.35337 16.1026C9.48064 16.2302 9.6429 16.3171 9.8196 16.3525C9.9963 16.3878 10.1795 16.3699 10.346 16.301C10.5126 16.2321 10.6549 16.1154 10.7551 15.9656C10.8552 15.8158 10.9087 15.6397 10.9087 15.4595C10.9087 15.2183 10.813 14.987 10.6427 14.8163C10.4724 14.6456 10.2413 14.5494 10.0002 14.5488Z"
        fill="#E6252A"
      />
      <path
        d="M9.99992 2.90638C10.7072 2.90698 11.3902 2.64873 11.9201 2.18034C12.4501 1.71194 12.7903 1.06581 12.8765 0.363828C10.9923 -0.12095 9.01605 -0.12095 7.13184 0.363828C7.21792 1.06435 7.55688 1.70932 8.08503 2.17752C8.61317 2.64572 9.29413 2.90491 9.99992 2.90638Z"
        fill="#E6252A"
      />
      <path
        d="M9.99982 3.68286C9.82013 3.68286 9.64448 3.73614 9.49508 3.83597C9.34567 3.9358 9.22923 4.07769 9.16047 4.2437C9.0917 4.40971 9.07371 4.59238 9.10877 4.76861C9.14382 4.94485 9.23035 5.10673 9.35741 5.23379C9.48446 5.36084 9.64634 5.44737 9.82258 5.48243C9.99881 5.51748 10.1815 5.49949 10.3475 5.43073C10.5135 5.36196 10.6554 5.24552 10.7552 5.09611C10.855 4.94671 10.9083 4.77106 10.9083 4.59137C10.9083 4.35042 10.8126 4.11934 10.6422 3.94896C10.4719 3.77858 10.2408 3.68286 9.99982 3.68286Z"
        fill="#E6252A"
      />
      <path
        d="M15.0424 15.0427C14.5432 15.5427 14.2438 16.2077 14.2003 16.9129C14.1568 17.6181 14.3723 18.3149 14.8062 18.8725C16.4832 17.884 17.8815 16.4856 18.87 14.8086C18.3129 14.375 17.6167 14.1596 16.912 14.2027C16.2073 14.2458 15.5425 14.5444 15.0424 15.0427Z"
        fill="#E6252A"
      />
      <path
        d="M13.2087 13.2085C13.0805 13.3351 12.9928 13.497 12.9568 13.6735C12.9208 13.8501 12.9381 14.0333 13.0065 14.2C13.0749 14.3667 13.1914 14.5093 13.341 14.6096C13.4907 14.71 13.6668 14.7636 13.847 14.7636C14.0272 14.7636 14.2033 14.71 14.3529 14.6096C14.5026 14.5093 14.619 14.3667 14.6874 14.2C14.7559 14.0333 14.7732 13.8501 14.7372 13.6735C14.7012 13.497 14.6135 13.3351 14.4853 13.2085C14.3153 13.0407 14.0859 12.9465 13.847 12.9465C13.608 12.9465 13.3787 13.0407 13.2087 13.2085Z"
        fill="#E6252A"
      />
      <path
        d="M4.97854 4.97871C5.47849 4.47915 5.77849 3.81398 5.82199 3.10855C5.86549 2.40312 5.6495 1.70614 5.21471 1.14893C3.53793 2.13954 2.13974 3.5392 1.15088 5.21701C1.70836 5.65049 2.40495 5.86549 3.10977 5.82161C3.81459 5.77773 4.47913 5.47799 4.97854 4.97871Z"
        fill="#E6252A"
      />
      <path
        d="M5.53193 5.53202C5.40371 5.65861 5.31603 5.82047 5.28004 5.99702C5.24404 6.17357 5.26135 6.35683 5.32977 6.52352C5.39819 6.6902 5.51463 6.83278 5.66428 6.93312C5.81394 7.03346 5.99005 7.08704 6.17023 7.08704C6.35041 7.08704 6.52652 7.03346 6.67618 6.93312C6.82583 6.83278 6.94227 6.6902 7.01069 6.52352C7.07911 6.35683 7.09642 6.17357 7.06042 5.99702C7.02443 5.82047 6.93675 5.65861 6.80853 5.53202C6.63796 5.36516 6.40884 5.27173 6.17023 5.27173C5.93162 5.27173 5.7025 5.36516 5.53193 5.53202Z"
        fill="#E6252A"
      />
      <path
        d="M15.0363 4.97024C15.5361 5.46978 16.2012 5.76948 16.9065 5.81298C17.6118 5.85647 18.3087 5.64075 18.8661 5.20641C17.8776 3.5294 16.4793 2.13105 14.8023 1.14258C14.3687 1.69974 14.1532 2.39594 14.1963 3.10061C14.2394 3.80529 14.5381 4.47005 15.0363 4.97024Z"
        fill="#E6252A"
      />
      <path
        d="M14.4892 6.80843C14.6174 6.68184 14.7051 6.51998 14.7411 6.34344C14.7771 6.16689 14.7598 5.98362 14.6914 5.81694C14.6229 5.65025 14.5065 5.50768 14.3568 5.40734C14.2072 5.30699 14.0311 5.25342 13.8509 5.25342C13.6707 5.25342 13.4946 5.30699 13.3449 5.40734C13.1953 5.50768 13.0789 5.65025 13.0104 5.81694C12.942 5.98362 12.9247 6.16689 12.9607 6.34344C12.9967 6.51998 13.0844 6.68184 13.2126 6.80843C13.3832 6.97529 13.6123 7.06873 13.8509 7.06873C14.0895 7.06873 14.3186 6.97529 14.4892 6.80843Z"
        fill="#E6252A"
      />
      <path
        d="M4.97432 15.0318C4.47452 14.5323 3.80941 14.2326 3.10411 14.1891C2.39881 14.1456 1.70192 14.3613 1.14453 14.7957C2.13374 16.4721 3.53193 17.8703 5.20836 18.8595C5.64283 18.3027 5.85884 17.6063 5.81573 16.9014C5.77263 16.1964 5.47339 15.5315 4.97432 15.0318Z"
        fill="#E6252A"
      />
      <path
        d="M5.53193 13.1914C5.40371 13.318 5.31603 13.4799 5.28004 13.6564C5.24404 13.833 5.26135 14.0163 5.32977 14.1829C5.39819 14.3496 5.51463 14.4922 5.66428 14.5925C5.81394 14.6929 5.99005 14.7465 6.17023 14.7465C6.35041 14.7465 6.52652 14.6929 6.67618 14.5925C6.82583 14.4922 6.94227 14.3496 7.01069 14.1829C7.07911 14.0163 7.09642 13.833 7.06042 13.6564C7.02443 13.4799 6.93675 13.318 6.80853 13.1914C6.63796 13.0246 6.40884 12.9312 6.17023 12.9312C5.93162 12.9312 5.7025 13.0246 5.53193 13.1914Z"
        fill="#E6252A"
      />
    </g>
    <defs>
      <clipPath id="clip0_1662_2002">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </SVG>
)
export default withIconStyles(IconCanvas)
