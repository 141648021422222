import React from 'react'
import withIconStyles from '@edulastic/icons/src/HOC/withIconStyles'
import SVG from '@edulastic/icons/src/common/SVG'

const IconOrdered = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="14.851"
    height="14.98"
    viewBox="0 0 14.851 14.98"
    {...props}
  >
    <g transform="translate(-0.209 -5.461)">
      <path
        d="M32.573,12.41h9.342a.316.316,0,0,0,.315-.315v-1.62a.316.316,0,0,0-.315-.315H32.573a.316.316,0,0,0-.315.315v1.62A.315.315,0,0,0,32.573,12.41Z"
        transform="translate(-27.17 -3.176)"
      />
      <path
        d="M41.915,40.158H32.573a.316.316,0,0,0-.315.315v1.62a.316.316,0,0,0,.315.315h9.342a.316.316,0,0,0,.315-.315v-1.62A.315.315,0,0,0,41.915,40.158Z"
        transform="translate(-27.17 -28.444)"
      />
      <path
        d="M41.915,71.064H32.573a.316.316,0,0,0-.315.315V73a.316.316,0,0,0,.315.315h9.342A.316.316,0,0,0,42.231,73v-1.62A.315.315,0,0,0,41.915,71.064Z"
        transform="translate(-27.17 -54.476)"
      />
      <path
        d="M2.272,0h-.83V-2.272l.008-.373.013-.408q-.207.207-.287.271L.725-2.42l-.4-.5L1.59-3.926h.682Z"
        transform="translate(0 9.388)"
      />
      <path
        d="M2.965,0H.22V-.577l.986-1q.438-.448.572-.622a1.367,1.367,0,0,0,.193-.321.817.817,0,0,0,.059-.306.446.446,0,0,0-.13-.352.505.505,0,0,0-.348-.115,1,1,0,0,0-.443.1,2.287,2.287,0,0,0-.448.3L.209-3.421A2.943,2.943,0,0,1,.69-3.771a1.765,1.765,0,0,1,.416-.157,2.133,2.133,0,0,1,.5-.055,1.5,1.5,0,0,1,.65.134,1.037,1.037,0,0,1,.438.376,1,1,0,0,1,.156.553,1.354,1.354,0,0,1-.1.509,1.954,1.954,0,0,1-.3.487,7.222,7.222,0,0,1-.7.712l-.5.475V-.7H2.965Z"
        transform="translate(0 14.888)"
      />
      <path
        d="M2.812-3.048a.924.924,0,0,1-.223.626,1.155,1.155,0,0,1-.626.354v.016a1.25,1.25,0,0,1,.72.289.809.809,0,0,1,.244.619,1.049,1.049,0,0,1-.411.882A1.894,1.894,0,0,1,1.343.054,2.85,2.85,0,0,1,.209-.158V-.865a2.561,2.561,0,0,0,.5.188A2.113,2.113,0,0,0,1.254-.6a1.055,1.055,0,0,0,.607-.14.513.513,0,0,0,.2-.448.405.405,0,0,0-.226-.392,1.669,1.669,0,0,0-.72-.115h-.3v-.636h.3a1.411,1.411,0,0,0,.667-.12A.432.432,0,0,0,2-2.865q0-.446-.559-.446a1.278,1.278,0,0,0-.393.064A1.922,1.922,0,0,0,.6-3.024L.215-3.6A2.134,2.134,0,0,1,1.5-3.983a1.654,1.654,0,0,1,.963.247A.792.792,0,0,1,2.812-3.048Z"
        transform="translate(0 20.388)"
      />
    </g>
  </SVG>
)

export default withIconStyles(IconOrdered)
