/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconShoppingBag = (props) => (
  <SVG
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(-10.201)">
      <g transform="translate(10.2 0)">
        <g transform="translate(0 0)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.85547 6.05459H3.549C3.37136 6.05459 3.22734 6.1986 3.22734 6.37625V9.7736C3.22734 11.4305 4.57049 12.7736 6.22734 12.7736H10.3402C11.9971 12.7736 13.3402 11.4305 13.3402 9.7736V6.37625C13.3402 6.1986 13.1962 6.05459 13.0186 6.05459H11.7119V6.58587C11.7119 6.91724 11.4433 7.18587 11.1119 7.18587C10.7805 7.18587 10.5119 6.91724 10.5119 6.58587V6.05459H6.05547V6.58587C6.05547 6.91724 5.78684 7.18587 5.45547 7.18587C5.1241 7.18587 4.85547 6.91724 4.85547 6.58587V6.05459ZM6.13718 4.85459H10.4302C10.1682 3.91541 9.30642 3.22637 8.28369 3.22637C7.26095 3.22637 6.39915 3.91541 6.13718 4.85459ZM11.6596 4.85459H13.0186C13.8589 4.85459 14.5402 5.53586 14.5402 6.37625V9.7736C14.5402 12.0932 12.6598 13.9736 10.3402 13.9736H6.22734C3.90775 13.9736 2.02734 12.0932 2.02734 9.7736V6.37625C2.02734 5.53586 2.70861 4.85459 3.549 4.85459H4.9078C5.19152 3.24731 6.59502 2.02637 8.28369 2.02637C9.97236 2.02637 11.3759 3.24731 11.6596 4.85459Z"
          />
        </g>
      </g>
    </g>
  </SVG>
)

export default withIconStyles(IconShoppingBag)
