export const originalQuestionFieldDataTestId = 'original-question-field'
export const originalQuestionFieldLabelDataTestId =
  'original-question-field-label'
export const translateLanguageSelectFieldDataTestId =
  'translate-language-select-field'
export const translateLanguageSelectFieldLabelDataTestId =
  'translate-language-select-field-label'

export const createdByAIAssistantBlockDataTestId =
  'created-by-ai-assistant-block'
export const aiAssistantLoadingBlockDataTestId = 'ai-assistant-loading-block'
export const aiAssistantSuccessBlockDataTestId = 'ai-assistant-success-block'
export const aiAssistantFailedBlockDataTestId = 'ai-assistant-failed-block'
export const regenerateButtonDataTestId = 'regenerate-button'

export const generateButtonDataTestId = 'generate-button'
export const reGenerateButtonDataTestId = 're-generate-button'
export const disclaimerTextBlockDataTestId = 'disclaimer-text-block'
export const footerActionButtonDataTestId = 'footer-action-button'

export const loadingDotsAnimationDataTestId = 'loading-dots-animation'

export const passageHeadingDataTestId = 'passage-heading'
export const passageTitleDataTestId = 'passage-title'
export const passageSourceDataTestId = 'passage-source'
export const passageContentDataTestId = 'passage-content'

export const getAIFeaturesAccessTextDataTestId = 'get-ai-features-access-text'
export const featuresListBlockDataTestId = 'features-list-block'

export const ANSWER_OPTION_DELIMITER = ')'
