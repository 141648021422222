import React from 'react'
import withIconStyles from '@edulastic/icons/src/HOC/withIconStyles'
import SVG from '@edulastic/icons/src/common/SVG'

const IconUnderline = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="13.327"
    height="13.327"
    viewBox="0 0 13.327 13.327"
    {...props}
  >
    <g transform="translate(-0.002)">
      <g transform="translate(0.002)">
        <path
          d="M.418.825q1.059.026,1.275.338a5.726,5.726,0,0,1,.148,1.848V5.9A11.306,11.306,0,0,0,1.98,7.956,3.742,3.742,0,0,0,2.7,9.648a3.65,3.65,0,0,0,1.553,1.059,6.748,6.748,0,0,0,2.464.4A7.666,7.666,0,0,0,8.93,10.82a4.907,4.907,0,0,0,1.579-.772,3.845,3.845,0,0,0,.972-1.067,3.586,3.586,0,0,0,.356-1.058,12.663,12.663,0,0,0,.13-2.638q-.121-3.271-.191-3.436,0-.1-.013-.269t-.013-.234a.481.481,0,0,1,.13-.356,2.572,2.572,0,0,1,.685-.148,3.159,3.159,0,0,0,.729-.113A2.143,2.143,0,0,0,13.329.46a3.512,3.512,0,0,0-.052-.442L13.121,0a12.023,12.023,0,0,1-1.7.087L9.642,0H8.913L8.9.746l.122.026L9.885.755a.879.879,0,0,1,.668.3,1.369,1.369,0,0,1,.208.763l.035.512q.052.746.117,1.384t.1,1.063q.03.425.03,1.111a7.835,7.835,0,0,1-.182,1.987,3.812,3.812,0,0,1-.46.989,2.222,2.222,0,0,1-.564.555,2.808,2.808,0,0,1-.859.442,5.384,5.384,0,0,1-1.657.243,3.229,3.229,0,0,1-1.536-.408,2.124,2.124,0,0,1-.833-.8,3.865,3.865,0,0,1-.443-1.753L4.384,4.711,4.375,2.725q0-.052,0-.221t0-.282a2.845,2.845,0,0,1,.113-1.145A.835.835,0,0,1,5.164.859,7.245,7.245,0,0,0,6.24.781V.7L6.223.148,6.232.026q-.26.017-.746.017L4.219.087Q3.507.113,2.761.113q-.3,0-1.44-.061Q.87.018.349.018.115.018,0,.026L.028.79A3.126,3.126,0,0,0,.418.825Z"
          transform="translate(-0.002)"
        />
        <path
          d="M13.054,402H.283a.267.267,0,0,0-.278.278v.555a.267.267,0,0,0,.278.278H13.054a.267.267,0,0,0,.278-.278v-.555a.267.267,0,0,0-.278-.278Z"
          transform="translate(-0.005 -389.784)"
        />
      </g>
    </g>
  </SVG>
)

export default withIconStyles(IconUnderline)
