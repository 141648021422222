export const privacyOptions = [
  'Do not send any user information',
  'Only send name of the user who launches the tool',
  'Only send Email/Username of user who launches the tool',
  'Send Name and Email/Username of user who launches the tool',
]

export const configOptions = [
  {
    title: 'Manual',
    key: 'manual',
  },
  {
    title: 'URL/XML',
    key: 'url',
  },
]

export const matchOptions = [
  {
    title: 'Domain',
    key: 'domain',
  },
  {
    title: 'URL',
    key: 'url',
  },
]
