import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconRerecord = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="15.035"
    height="17.171"
    viewBox="0 0 15.035 17.171"
    {...props}
  >
    <path
      id="restart_alt_FILL1_wght700_GRAD0_opsz48"
      d="M12.421,20.421a7.249,7.249,0,0,1-4.063-2.585A7.17,7.17,0,0,1,6.75,13.207a6.778,6.778,0,0,1,.576-2.748A6.335,6.335,0,0,1,8.966,8.21a.8.8,0,0,1,.706-.348,1.079,1.079,0,0,1,.793.326.871.871,0,0,1,.261.641.981.981,0,0,1-.282.684,4.839,4.839,0,0,0-1.217,1.673,4.925,4.925,0,0,0-.435,2.021,5.268,5.268,0,0,0,4.019,5.171,1.323,1.323,0,0,1,.576.413.978.978,0,0,1,.228.63,1.006,1.006,0,0,1-.369.836A.873.873,0,0,1,12.421,20.421Zm3.715,0a.85.85,0,0,1-.847-.152,1.013,1.013,0,0,1-.369-.826,1.038,1.038,0,0,1,.228-.63,1.261,1.261,0,0,1,.576-.435,5.084,5.084,0,0,0,2.89-1.858,5.239,5.239,0,0,0,1.108-3.313,5.4,5.4,0,0,0-5.106-5.432h-.5l.869.869a.782.782,0,0,1-.576,1.325.716.716,0,0,1-.554-.239l-2.39-2.368a1.028,1.028,0,0,1-.228-.337.99.99,0,0,1-.076-.38.966.966,0,0,1,.076-.391,1.067,1.067,0,0,1,.228-.326L13.855,3.5a.8.8,0,0,1,.554-.2.88.88,0,0,1,.576.2.88.88,0,0,1,.2.576.8.8,0,0,1-.2.554L13.876,5.712H14.4a7.039,7.039,0,0,1,5.236,2.205,7.308,7.308,0,0,1,2.151,5.29,7.17,7.17,0,0,1-1.608,4.628A7.244,7.244,0,0,1,16.136,20.421Z"
      transform="translate(-6.75 -3.3)"
      fill="#1ab395"
    />
  </SVG>
)

export default withIconStyles(IconRerecord)
