import { captureSentryException } from '@edulastic/common'

const openingTagRegex = /<([a-z]+)(?![^>]*\/>)[^>]*>/gi
const closingTagRegex = /<\/([a-z]+)>/gi

/**
 * @param {*} sentencesArray
 * @returns sentencesArray with completed tags
 * Consider a span element like:
 * <span style="font-size: 14px;">first <span style="font-size: 20px;">second. </span> Second continue</span>first continue</span><br/>
 * When the sentence is split into sentence (split with . or br tag), the tags are not closed properly and
 * font-size and other tags is not applied correctly to the split sentences.
 * This function keeps a stack of open tags and appends to future sentences until the tag is closed.
 * This ensures styling is applied to the entire sentence.
 */

export const completeTagsForSentencesArray = (sentencesArray = []) => {
  const updatedSentencesArray =
    Array.isArray(sentencesArray) && sentencesArray?.length
      ? [...sentencesArray]
      : []
  if (updatedSentencesArray?.length) {
    const tagsStack = []
    ;(updatedSentencesArray || []).forEach((sentenceObj, sentenceIndex) => {
      try {
        const sentence = sentenceObj?.value || ''
        if (sentence) {
          let updatedSentence = sentence

          if (tagsStack?.length) {
            updatedSentence = `${tagsStack
              .map((tag) => tag?.match || '')
              .join('')}${sentence}`
            updatedSentencesArray[sentenceIndex].value = updatedSentence
          }

          const tagsMatches = []
          const openingTagMatches = sentence.matchAll(openingTagRegex)
          const closingTagsMatches = sentence.matchAll(closingTagRegex)

          for (const openingTagMatch of openingTagMatches) {
            const openingTag = openingTagMatch?.[0] || ''
            const openingTagName = openingTagMatch?.[1] || ''
            if (openingTag && openingTagName) {
              tagsMatches.push({
                type: 'OPEN',
                tagName: openingTagName,
                match: openingTag,
                index: openingTagMatch?.index,
              })
            }
          }

          for (const closingTagMatch of closingTagsMatches) {
            const closingTag = closingTagMatch?.[0] || ''
            const closingTagName = closingTagMatch?.[1] || ''
            if (closingTag && closingTagName) {
              tagsMatches.push({
                type: 'CLOSE',
                tagName: closingTagName,
                match: closingTag,
                index: closingTagMatch?.index,
              })
            }
          }

          tagsMatches.sort((a, b) => a.index - b.index)

          for (const tagMatch of tagsMatches) {
            if (
              tagMatch?.type === 'OPEN' &&
              tagMatch?.match &&
              tagMatch?.tagName
            ) {
              tagsStack.push({
                match: tagMatch.match,
                tagName: tagMatch?.tagName,
              })
            } else if (
              tagMatch.type === 'CLOSE' &&
              tagsStack?.length &&
              tagsStack?.[tagsStack.length - 1]?.tagName === tagMatch?.tagName
            ) {
              tagsStack.pop()
            }
          }
        }
      } catch (error) {
        captureSentryException(error, {
          errorMessage:
            'Error in completing tags for sentences array in TokenHighlight',
        })
      }
    })
  }
  return updatedSentencesArray
}
