/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconGoogleClassroomSquare = (props) => (
  <SVG
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/SVG"
    {...props}
  >
    <g clipPath="url(#clip0_1492_14986)">
      <path
        d="M1.81812 3.18164H18.1818V16.818H1.81812V3.18164Z"
        fill="#0F9D58"
      />
      <path
        d="M13.6363 10.4544C14.2007 10.4544 14.659 9.99606 14.659 9.43166C14.659 8.86727 14.2007 8.40894 13.6363 8.40894C13.0719 8.40894 12.6135 8.86727 12.6135 9.43166C12.6135 9.99606 13.0719 10.4544 13.6363 10.4544ZM13.6363 11.1362C12.5416 11.1362 11.3635 11.7158 11.3635 12.4355V13.1817H15.909V12.4355C15.909 11.7158 14.731 11.1362 13.6363 11.1362ZM6.36355 10.4544C6.92794 10.4544 7.38628 9.99606 7.38628 9.43166C7.38628 8.86727 6.92794 8.40894 6.36355 8.40894C5.79915 8.40894 5.34082 8.86727 5.34082 9.43166C5.34082 9.99606 5.79915 10.4544 6.36355 10.4544ZM6.36355 11.1362C5.26885 11.1362 4.09082 11.7158 4.09082 12.4355V13.1817H8.63627V12.4355C8.63627 11.7158 7.45824 11.1362 6.36355 11.1362Z"
        fill="#57BB8A"
      />
      <path
        d="M9.99993 9.54539C10.7537 9.54539 11.3636 8.93554 11.3636 8.18175C11.3636 7.42796 10.7537 6.81812 9.99993 6.81812C9.24615 6.81812 8.6363 7.42796 8.6363 8.18175C8.6363 8.93554 9.24615 9.54539 9.99993 9.54539ZM9.99993 10.4545C8.46584 10.4545 6.81812 11.2689 6.81812 12.2727V13.1818H13.1818V12.2727C13.1818 11.2689 11.534 10.4545 9.99993 10.4545Z"
        fill="#F7F7F7"
      />
      <path
        d="M11.8181 15.9089H15.909V16.818H11.8181V15.9089Z"
        fill="#F1F1F1"
      />
      <path
        d="M18.6364 1.36353H1.36364C0.609848 1.36353 0 1.97337 0 2.72716V17.2726C0 18.0264 0.609848 18.6363 1.36364 18.6363H18.6364C19.3902 18.6363 20 18.0264 20 17.2726V2.72716C20 1.97337 19.3902 1.36353 18.6364 1.36353ZM18.1818 16.8181H1.81818V3.18171H18.1818V16.8181Z"
        fill="#F4B400"
      />
      <path
        opacity="0.2"
        d="M18.6364 1.36353H1.36364C0.609848 1.36353 0 1.97337 0 2.72716V2.8408C0 2.08701 0.609848 1.47716 1.36364 1.47716H18.6364C19.3902 1.47716 20 2.08701 20 2.8408V2.72716C20 1.97337 19.3902 1.36353 18.6364 1.36353Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M18.6364 18.5226H1.36364C0.609848 18.5226 0 17.9127 0 17.1589V17.2726C0 18.0264 0.609848 18.6362 1.36364 18.6362H18.6364C19.3902 18.6362 20 18.0264 20 17.2726V17.1589C20 17.9127 19.3902 18.5226 18.6364 18.5226Z"
        fill="#BF360C"
      />
      <path
        d="M15.9015 16.8181H11.8105L13.6287 18.6363H17.7159L15.9015 16.8181Z"
        fill="url(#paint0_linear_1492_14986)"
      />
      <path
        opacity="0.2"
        d="M1.81812 3.06812H18.1818V3.18175H1.81812V3.06812Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M1.81812 16.8181H18.1818V16.9318H1.81812V16.8181Z"
        fill="white"
      />
      <path
        d="M18.6364 1.36353H1.36364C0.609848 1.36353 0 1.97337 0 2.72716V17.2726C0 18.0264 0.609848 18.6363 1.36364 18.6363H18.6364C19.3902 18.6363 20 18.0264 20 17.2726V2.72716C20 1.97337 19.3902 1.36353 18.6364 1.36353Z"
        fill="url(#paint1_radial_1492_14986)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1492_14986"
        x1="1570.9"
        y1="28.301"
        x2="1570.9"
        y2="854.548"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BF360C" stopOpacity="0.2" />
        <stop offset="1" stopColor="#BF360C" stopOpacity="0.02" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_1492_14986"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(310.719 185.984) scale(43570.6 43570.4)"
      >
        <stop stopColor="white" stopOpacity="0.1" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <clipPath id="clip0_1492_14986">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </SVG>
)

export default withIconStyles(IconGoogleClassroomSquare)
