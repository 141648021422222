import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconRulerPencil = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23.609 23.527"
    {...props}
  >
    <g transform="translate(0.125 -0.367)">
      <path
        d="M.455,23.769h22.36a.454.454,0,0,0,.321-.776L.776.633A.454.454,0,0,0,0,.954v22.36a.454.454,0,0,0,.454.454ZM.909,2.052,4.565,5.707,3.6,6.671a.454.454,0,1,0,.643.643l.964-.964L7.136,8.278l-.964.964a.454.454,0,1,0,.643.643l.964-.964,1.928,1.928-.964.964a.454.454,0,0,0,.643.643l.964-.964,1.928,1.928-.964.964a.454.454,0,0,0,.643.643l.964-.964,1.928,1.928-.964.964a.454.454,0,1,0,.643.643l.964-.964,1.928,1.928-.964.964a.455.455,0,0,0,.643.643l.964-.964,3.656,3.656H.909Zm0,0"
        transform="translate(0 0)"
        fill="#6a737f"
        stroke="#6a737f"
        strokeWidth="0.25"
      />
      <path
        d="M86.776,205.622a.454.454,0,0,0-.776.321v9.135a.454.454,0,0,0,.454.454H95.59a.454.454,0,0,0,.322-.776Zm.133,9v-7.584l7.584,7.584Zm0,0"
        transform="translate(-82.091 -195.673)"
        fill="#6a737f"
        stroke="#6a737f"
        strokeWidth="0.25"
      />
      <path
        d="M122.008,5.132l12.757,12.757a1.363,1.363,0,0,0,1.928,0l1.285-1.285a1.366,1.366,0,0,0,0-1.929L125.222,1.918a.453.453,0,0,0-.178-.11L121.188.523a.455.455,0,0,0-.575.575L121.9,4.954a.454.454,0,0,0,.11.178Zm15.328,10.186a.455.455,0,0,1,0,.643l-1.285,1.285a.455.455,0,0,1-.643,0l-.985-.985,1.928-1.928Zm-1.627-1.627-1.928,1.928-.643-.643,1.928-1.928ZM125.2,3.183l9.222,9.222L132.5,14.333l-9.222-9.222Zm-.691-.595L122.678,4.42l-.916-2.747Zm0,0"
        transform="translate(-115.109)"
        fill="#6a737f"
        stroke="#6a737f"
        strokeLinejoin="round"
        strokeWidth="0.25"
      />
    </g>
  </SVG>
)

export default withIconStyles(IconRulerPencil)
