import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import styled from 'styled-components'
import { FlexContainer } from '@edulastic/common'
import { IconThumbsUp, IconThumbsDown } from '@edulastic/icons'
import { themeColor } from '@edulastic/colors'
import { FEEDBACK_VALUES } from '../constants/form'
import {
  getAIResponseFeedbackState,
  resetAIResponseFeedbackStateAction,
} from '../ducks/feedback'

const AIResponseFeedback = ({
  onAIResponseFeedbackSubmit,
  resetAIResponseFeedbackState,
  aiResponseFeedbackState,
}) => {
  useEffect(() => {
    resetAIResponseFeedbackState()
  }, [])

  const onFeedback = ({ feedback }) => {
    onAIResponseFeedbackSubmit({ feedback })
  }

  const { apiStatus, feedback, requestId } = aiResponseFeedbackState || {}
  const isFeedbackSubmitted = apiStatus === 'SUCCESS' && requestId

  return (
    <FlexContainer
      alignItems="center"
      justifyContent="flex-start"
      gap="8px"
      mt="10px"
    >
      <StyledText>Was this helpful</StyledText>
      <StyledFeedbackIconContainer
        onClick={() => onFeedback({ feedback: FEEDBACK_VALUES.HELPFUL })}
        backgroundColor={
          isFeedbackSubmitted && feedback === FEEDBACK_VALUES.HELPFUL
            ? themeColor
            : '#fff'
        }
      >
        <IconThumbsUp />
      </StyledFeedbackIconContainer>
      <StyledFeedbackIconContainer
        onClick={() => onFeedback({ feedback: FEEDBACK_VALUES.NOT_HELPFUL })}
        backgroundColor={
          isFeedbackSubmitted && feedback === FEEDBACK_VALUES.NOT_HELPFUL
            ? themeColor
            : '#fff'
        }
      >
        <IconThumbsDown />
      </StyledFeedbackIconContainer>
    </FlexContainer>
  )
}

const enhance = compose(
  connect(
    (state) => ({
      aiResponseFeedbackState: getAIResponseFeedbackState(state),
    }),
    {
      resetAIResponseFeedbackState: resetAIResponseFeedbackStateAction,
    }
  )
)

export default enhance(AIResponseFeedback)

const StyledText = styled.p`
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #2f4151;
`

const StyledFeedbackIconContainer = styled.div`
  width: 22px;
  height: 22px;
  border: 1px solid #bbbbbb;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  background-color: ${({ backgroundColor }) => backgroundColor || '#fff'};
  svg {
    fill: ${({ backgroundColor }) =>
      backgroundColor === themeColor ? '#fff' : '#2f4151'};
  }
  &:hover {
    border: 1px solid ${themeColor};
    svg {
      fill: ${({ backgroundColor }) =>
        backgroundColor === themeColor ? '#fff' : themeColor};
    }
  }
`
