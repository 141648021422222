import React from 'react'
import withIconStyles from '@edulastic/icons/src/HOC/withIconStyles'
import SVG from '@edulastic/icons/src/common/SVG'

const IconTable = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="15.24"
    height="12.896"
    viewBox="0 0 15.24 12.896"
    {...props}
  >
    <g transform="translate(0 -36.545)">
      <path
        d="M14.81,36.975a1.411,1.411,0,0,0-1.035-.43H1.465a1.411,1.411,0,0,0-1.035.43A1.411,1.411,0,0,0,0,38.01v9.965A1.411,1.411,0,0,0,.43,49.01a1.411,1.411,0,0,0,1.035.43H13.775a1.469,1.469,0,0,0,1.465-1.465V38.01A1.411,1.411,0,0,0,14.81,36.975Zm-10.12,11a.282.282,0,0,1-.293.293H1.465a.282.282,0,0,1-.293-.293V46.216a.282.282,0,0,1,.293-.293H4.4a.282.282,0,0,1,.293.293v1.759Zm0-3.517a.282.282,0,0,1-.293.293H1.465a.282.282,0,0,1-.293-.293V42.7a.282.282,0,0,1,.293-.293H4.4a.282.282,0,0,1,.293.293v1.759Zm0-3.517a.282.282,0,0,1-.293.293H1.465a.282.282,0,0,1-.293-.293V39.183a.282.282,0,0,1,.293-.293H4.4a.282.282,0,0,1,.293.293v1.758Zm4.689,7.034a.281.281,0,0,1-.293.293H6.155a.282.282,0,0,1-.293-.293V46.216a.282.282,0,0,1,.293-.293H9.086a.282.282,0,0,1,.293.293v1.759h0Zm0-3.517a.282.282,0,0,1-.293.293H6.155a.282.282,0,0,1-.293-.293V42.7a.282.282,0,0,1,.293-.293H9.086a.281.281,0,0,1,.293.293v1.759Zm0-3.517a.282.282,0,0,1-.293.293H6.155a.282.282,0,0,1-.293-.293V39.183a.282.282,0,0,1,.293-.293H9.086a.282.282,0,0,1,.293.293v1.758Zm4.689,7.034a.281.281,0,0,1-.293.293H10.844a.281.281,0,0,1-.293-.293V46.216a.282.282,0,0,1,.293-.293h2.931a.282.282,0,0,1,.293.293Zm0-3.517a.282.282,0,0,1-.293.293H10.844a.282.282,0,0,1-.293-.293V42.7a.281.281,0,0,1,.293-.293h2.931a.282.282,0,0,1,.293.293Zm0-3.517a.282.282,0,0,1-.293.293H10.844a.282.282,0,0,1-.293-.293V39.183a.282.282,0,0,1,.293-.293h2.931a.282.282,0,0,1,.293.293Z"
        transform="translate(0)"
      />
    </g>
  </SVG>
)

export default withIconStyles(IconTable)
