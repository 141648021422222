import React, { useEffect, useRef } from 'react'
import { getDesmosConfig } from '@edulastic/common'
import { Calculator } from './styled-components'

export const BasicDesmosCalculator = ({ calcMode, schoolState }) => {
  const desmosBasicRef = useRef()

  useEffect(() => {
    const Desmos = window.Desmos
    if (desmosBasicRef.current && Desmos) {
      const config = getDesmosConfig(schoolState, calcMode)
      const desmosFourFunctionCalculator = Desmos.FourFunctionCalculator(
        desmosBasicRef.current,
        config
      )

      return () => {
        desmosFourFunctionCalculator.destroy()
      }
    }
  }, [schoolState, calcMode])

  return <Calculator id="demos-basic-calculator" ref={desmosBasicRef} />
}
