import { createSelector } from 'reselect'
import { get } from 'lodash'
import { getTestItemsSelector } from '../AddItems/ducks'
import { getTestSelector, isSectionAdaptiveTestSelector } from '../../ducks'
import {
  adaptiveTestSettings,
  adaptiveTestSettingsOptions,
  adaptiveTestThresholdTypes,
  defaultSectionAdaptiveSettingType,
} from '../GroupItems/constants'
import {
  getAdaptiveSettingFromTest,
  getAdaptiveSettingTypeFromSettings,
} from '../GroupItems/utils/adaptive'

// selectors

export const getStandardsSelector = createSelector(
  getTestItemsSelector,
  (state) => {
    const result = {}

    state.forEach((item) => {
      const tags = get(item, 'data.questions', []).reduce((acc, question) => {
        const t = get(question, 'standardsMap.domains', []).reduce(
          (r, { standards }) => [...r, ...standards.map((s) => s.name)],
          []
        )
        return [...acc, ...t]
      }, [])

      result[item._id] = tags
    })

    return result
  }
)

export const getAdaptiveSettingLabel = createSelector(
  getTestSelector,
  isSectionAdaptiveTestSelector,
  (test, isSectionAdaptiveTest) => {
    if (isSectionAdaptiveTest) {
      let _currentTestAdaptiveSettingsType = defaultSectionAdaptiveSettingType
      let _currentTestAdaptiveSettings =
        adaptiveTestSettings[_currentTestAdaptiveSettingsType]

      if (test?.itemGroups && test?.adaptiveDelivery) {
        const thresholdType =
          test.adaptiveDelivery?.threshold?.thresholdType ||
          adaptiveTestThresholdTypes.PERCENTAGE

        _currentTestAdaptiveSettings = getAdaptiveSettingFromTest(test)
        _currentTestAdaptiveSettingsType = getAdaptiveSettingTypeFromSettings(
          _currentTestAdaptiveSettings,
          thresholdType,
          true
        )
      }

      return adaptiveTestSettingsOptions.find(
        (option) => option.value === _currentTestAdaptiveSettingsType
      )?.label
    }
  }
)
