import React from 'react'
import withIconStyles from '@edulastic/icons/src/HOC/withIconStyles'
import SVG from '@edulastic/icons/src/common/SVG'

const IconGraphPolygon = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="29.055"
    height="16.263"
    viewBox="0 0 29.055 16.263"
    {...props}
  >
    <path
      d="M367.292,271H338.237l3.956-11.272,20.316-4.991Zm-26.234-2H364.62l-3.49-11.865-17.409,4.276Z"
      transform="translate(-338.237 -254.737)"
    />
  </SVG>
)

export default withIconStyles(IconGraphPolygon)
