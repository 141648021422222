import { Dropdown, Menu, Tooltip } from 'antd'
import React from 'react'
import { withNamespaces } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { magnifierSizes } from '../constants/assessmentPlayer'

const MagnifierDropDown = ({
  handleMagnifier = () => {},
  magnifierSize = magnifierSizes.SMALL,
  children,
  tooltipPlacement = '',
  t: i18Translate,
  zIndex = 1100,
  disabled = false,
}) => {
  const handleMenuClick = (e) => {
    if (magnifierSize === e.key) {
      handleMagnifier('')
    } else {
      handleMagnifier(e.key)
    }
  }

  const menu = (
    <Menu onClick={handleMenuClick} selectedKeys={[magnifierSize]}>
      <Menu.Item key={magnifierSizes.SMALL}>
        {i18Translate('header:toolbar.magnifySmall')}
      </Menu.Item>
      <Menu.Item key={magnifierSizes.MEDIUM}>
        {i18Translate('header:toolbar.magnifyMedium')}
      </Menu.Item>
    </Menu>
  )

  return (
    <Tooltip
      placement={tooltipPlacement}
      title={tooltipPlacement ? i18Translate('header:toolbar.magnify') : ''}
    >
      <Dropdown
        overlayStyle={{
          zIndex,
        }}
        overlay={menu}
        placement="bottomLeft"
        trigger={['hover']}
        disabled={disabled}
      >
        {children}
      </Dropdown>
    </Tooltip>
  )
}

const enhance = compose(
  withNamespaces('header'),
  connect((state) => ({
    magnifierSize: state.testPlayer?.magnifierSize,
  }))
)

export default enhance(MagnifierDropDown)
