import React from 'react'

function IconSchedule() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.112"
      height="12.475"
      viewBox="0 0 12.112 12.475"
      style={{ fill: '#ee1658' }}
    >
      <path
        d="M11.835 2.047a.915.915 0 00-.655-.265h-.932v-.668a1.05 1.05 0 00-.342-.787A1.148 1.148 0 009.084 0h-.466a1.148 1.148 0 00-.823.327 1.049 1.049 0 00-.342.787v.668H4.659v-.668a1.05 1.05 0 00-.342-.787A1.148 1.148 0 003.494 0h-.47a1.148 1.148 0 00-.823.327 1.05 1.05 0 00-.342.787v.668H.927a.916.916 0 00-.655.265.838.838 0 00-.277.627v8.91a.839.839 0 00.277.626.916.916 0 00.655.265H11.18a.916.916 0 00.655-.265.839.839 0 00.277-.626V2.673a.839.839 0 00-.277-.626zm-3.45-.933a.212.212 0 01.065-.16.232.232 0 01.167-.063h.466a.232.232 0 01.167.063.212.212 0 01.065.16v2a.212.212 0 01-.065.16.233.233 0 01-.167.063h-.466a.232.232 0 01-.167-.063.212.212 0 01-.065-.16zm-5.59 0a.212.212 0 01.065-.16.233.233 0 01.164-.063h.466a.232.232 0 01.167.063.212.212 0 01.066.16v2a.212.212 0 01-.066.16.233.233 0 01-.167.063h-.466a.232.232 0 01-.167-.063.212.212 0 01-.065-.16zm8.385 10.47H.932V4.455H11.18z"
        className="a"
      ></path>
    </svg>
  )
}

export default IconSchedule
