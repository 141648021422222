import {
  DISTRICT_ADMIN,
  SCHOOL_ADMIN,
  TEACHER,
} from '@edulastic/constants/const/roleType'
import { TEST_TYPE_SURVEY } from '@edulastic/constants/const/testTypes'

// TODO: we should move some of the logic from componets to this file moving forward.
export const canEditTest = (test, userId) => {
  if (test?.freezeSettings === true || test?.isDocBased === true) {
    // when freeze settings enabled or test is doc based, user has to be an author to enable edit
    return test?.authors?.some((author) => author._id === userId)
  }
  return true
}

export const canEditAssignedTest = (
  test = {},
  userId = '',
  userRole = '',
  additionalData = {}
) => {
  return (
    canEditTest(test, userId) &&
    additionalData?.testId &&
    !(
      [SCHOOL_ADMIN, DISTRICT_ADMIN].includes(
        additionalData?.assignedBy?.role
      ) && userRole === TEACHER
    )
  )
}

export const getEditTestNavigation = (testId, testType) => ({
  pathname: `/author/tests/tab/review/id/${testId}`,
  state: { editAssigned: true, showCancelButton: true },
  ...(testType === TEST_TYPE_SURVEY
    ? { search: `testType=${TEST_TYPE_SURVEY}` }
    : {}),
})

export const isRestrictedTimeWindowForAssignment = (
  startDate,
  serverTimeStamp,
  isPaused,
  isOutsideTimeWindow
) => {
  return (
    !(Date(startDate) > new Date(serverTimeStamp) || !startDate || isPaused) &&
    isOutsideTimeWindow
  )
}

export const assignmentStatus = (status, isPaused, startDate) => {
  return status === 'NOT OPEN' && startDate && startDate < Date.now()
    ? `IN PROGRESS${isPaused ? ' (PAUSED)' : ''}`
    : `${status}${isPaused && status !== 'DONE' ? ' (PAUSED)' : ''}`
}
