import React from 'react'
import { segmentApi } from '@edulastic/api'
import {
  CardSubtext,
  CardText,
  IconContainer,
  LowerContainer,
  StyledCardDiv,
  StyledCardLink,
  UpperContainer,
} from './styled'

function Card(props) {
  const {
    card: { icon, title, subTitle, navigation, trackingEvent, dataCy },
    onClick: _onClick,
  } = props

  const handleClick = () => {
    _onClick?.()
    segmentApi.genericEventTrack(trackingEvent.eventName, {
      source: trackingEvent.source,
    })
  }

  const CardContent = () => (
    <>
      <UpperContainer>
        <IconContainer>{icon}</IconContainer>
      </UpperContainer>
      <LowerContainer>
        <CardText>{title}</CardText>
        <CardSubtext>{subTitle}</CardSubtext>
      </LowerContainer>
    </>
  )
  return navigation ? (
    <StyledCardLink to={navigation} onClick={handleClick} data-cy={dataCy}>
      <CardContent />
    </StyledCardLink>
  ) : (
    <StyledCardDiv onClick={handleClick} data-cy={dataCy}>
      <CardContent />
    </StyledCardDiv>
  )
}

export default Card
