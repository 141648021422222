import styled from 'styled-components'

export const BodyContainer = styled.div`
  width: 100%;

  .ant-row {
    margin-bottom: 15px;
  }
`

export const SectionLockingWarningContainer = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #e43334;
  margin-left: 0.5rem;
  margin-top: ${({ marginTop }) => marginTop || '0'}rem;
`
