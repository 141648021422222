import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Menu from 'antd/lib/menu'
import Popover from 'antd/lib/popover'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Checkbox from 'antd/lib/checkbox'
import styled from 'styled-components'
import { IconPlus as Icon, IconMute } from '@edulastic/icons'
import { greenPrimary, themeColor } from '@edulastic/colors'
import './index.scss'
import {
  LANGUAGES_OPTIONS,
  LANGUAGE_EN,
} from '@edulastic/constants/const/languages'
import { EduButton } from '@edulastic/common'
import {
  getCurrentLanguage,
  getLanguageTabsSelector,
  languageStateSelector,
  setLangAction,
  setLangTabsAction,
} from './duck'
import {
  getItemDetailSelector,
  getPassageSelector,
} from '../../../author/ItemDetail/ducks'

import { allowedToSelectMultiLanguageInTest } from '../../../author/src/selectors/user'

import {
  getAvailableLanguageKeysInItem,
  getAvailableLanguageKeysInPassage,
} from '../../utils/helpers'

const LanguageSelectorTab = ({
  currentLang,
  setLanguage,
  isEditView,
  item,
  passage,
  isLCB = false,
  allowedToSelectMultiLanguage,
  setLanguageTabs,
  languageTabs,
  currentItemId,
  authoredQuestions,
  isItemDetailView,
  isQuestionEditView,
}) => {
  const [popoverVisible, setPopoverVisible] = useState(false)

  const [selectedLanguages, setSelectedLanguages] = useState([])

  const [itemLevelLanguageTabs, setItemLevelLanguageTabs] = useState([])

  useEffect(() => {
    if (
      isItemDetailView &&
      (item?.data?.questions?.length ||
        item?.data?.resources?.length ||
        passage?.data?.length)
    ) {
      const allAvailableLanguagesInItemAndPassages = new Set([
        ...getAvailableLanguageKeysInItem(item),
        ...getAvailableLanguageKeysInPassage(passage),
      ])
      const languageCodes = Array.from(allAvailableLanguagesInItemAndPassages)
      setItemLevelLanguageTabs(
        languageCodes.length ? languageCodes : [LANGUAGE_EN]
      )
      setLanguage(languageCodes[0] || LANGUAGE_EN)
    }
  }, [
    isItemDetailView,
    item?.data?.questions,
    item?.data?.resources,
    passage?.data,
  ])

  useEffect(() => {
    if (isQuestionEditView || isLCB) {
      const data = authoredQuestions?.[currentItemId]
      const allAvailableLanguagesInQuestion = getAvailableLanguageKeysInItem({
        data: { questions: [data] },
      })
      const languageCodes =
        allAvailableLanguagesInQuestion?.length > 0
          ? allAvailableLanguagesInQuestion
          : languageTabs?.length > 0
          ? languageTabs
          : [LANGUAGE_EN]

      setLanguageTabs(languageCodes)
      setSelectedLanguages(languageCodes)
      setLanguage(languageCodes[0] || LANGUAGE_EN)
    }
  }, [isQuestionEditView, isLCB, currentItemId])

  const handleSelectedLang = (checked, lang) => {
    if (checked) {
      setSelectedLanguages([...selectedLanguages, lang])
    } else {
      setSelectedLanguages(selectedLanguages.filter((l) => l !== lang))
    }
  }

  const handleSaveLanguage = () => {
    setSelectedLanguages([...selectedLanguages])
    setLanguageTabs(selectedLanguages)
    setLanguage(selectedLanguages[0] || LANGUAGE_EN)
  }

  const handleChangeLanguage = ({ key }) => {
    setLanguage(key)
  }

  const languageTabsToShow = isItemDetailView
    ? itemLevelLanguageTabs.length
      ? itemLevelLanguageTabs
      : [LANGUAGE_EN]
    : languageTabs.length
    ? languageTabs
    : [LANGUAGE_EN]

  const showAddLanguageButton =
    allowedToSelectMultiLanguage && isEditView && !isItemDetailView

  const menu = (
    <div style={{ width: 300 }}>
      <p>Select at least 1 and maximum 3</p>
      <Row gutter={[20, 20]}>
        {LANGUAGES_OPTIONS.map((language) => (
          <Col span={12}>
            <Checkbox
              onChange={({ target }) =>
                handleSelectedLang(target.checked, language.value)
              }
              checked={selectedLanguages.includes(language.value)}
              disabled={
                selectedLanguages.length === 3 &&
                !selectedLanguages.includes(language.value)
              }
              data-cy={`language-checkbox-${(
                language?.label || ''
              ).toLowerCase()}`}
            >
              {language.label}
              {language.isTTSDisabled && <IconMute />}
            </Checkbox>
          </Col>
        ))}
      </Row>
      <EduButton
        block
        disabled={selectedLanguages.length === 0}
        style={{ marginLeft: 0 }}
        onClick={handleSaveLanguage}
        data-cy="save-language-button"
      >
        Save
      </EduButton>
    </div>
  )

  return (
    <StyledRow
      type="flex"
      isPassage={item.isPassageWithQuestions || item.multipartItem || isLCB}
      isEditView={isEditView}
    >
      {/* Student won't see language selector menu */}
      <Menu
        data-cy="language-selector"
        onClick={handleChangeLanguage}
        selectedKeys={[currentLang]}
        mode="horizontal"
      >
        {LANGUAGES_OPTIONS.filter((lo) =>
          languageTabsToShow.includes(lo.value)
        ).map((language) => (
          <Menu.Item key={language.value}>{language.label}</Menu.Item>
        ))}
      </Menu>
      {showAddLanguageButton && (
        <Popover
          overlayClassName="language-selector-popover"
          content={menu}
          placement="bottomLeft"
          visible={popoverVisible}
          trigger="click"
          onVisibleChange={(visible) => setPopoverVisible(visible)}
        >
          <StyledButtonRow
            type="flex"
            align="middle"
            data-cy="add-language-button"
          >
            <IconPlus />
            <p>Add Language</p>
          </StyledButtonRow>
        </Popover>
      )}
    </StyledRow>
  )
}

export default connect(
  (state) => ({
    initialLang: languageStateSelector(state),
    currentLang: getCurrentLanguage(state),
    languageTabs: getLanguageTabsSelector(state),
    item: getItemDetailSelector(state),
    passage: getPassageSelector(state),
    allowedToSelectMultiLanguage: allowedToSelectMultiLanguageInTest(state),
    currentItemId: state.authorQuestions.current,
    authoredQuestions: state.authorQuestions.byId,
  }),
  {
    setLanguage: setLangAction,
    setLanguageTabs: setLangTabsAction,
  }
)(LanguageSelectorTab)

export const StyledButtonRow = styled(Row)`
  cursor: pointer;
  &:hover {
    p {
      color: ${greenPrimary};
    }
    svg {
      fill: ${greenPrimary};
    }
  }
`

const StyledRow = styled(Row)`
  position: relative;
  top: ${({ isPassage }) => (isPassage ? '0px' : '35px')};
  left: ${({ isPassage, isEditView }) =>
    isPassage ? (isEditView ? '315px' : '0px') : isEditView ? '315px' : '40px'};
  width: ${({ isPassage }) => (isPassage ? '100%' : '96%;')};
  background: transparent;
  border-bottom: 1px solid #e8e8e8;
  ant-menu-horizontal {
    border-bottom: 0;
  }
  .ant-menu-item {
    z-index: 1;
  }
  .ant-menu-item:hover {
    color: ${themeColor};
    border-bottom: 2px solid ${themeColor};
  }
  .ant-menu-item-selected,
  .ant-menu-item-active {
    color: ${themeColor};
    border-bottom: 2px solid ${themeColor};
  }
  p {
    color: #999999;
    margin-left: 5px;
    margin-bottom: 0;
    font-weight: 600;
  }
`
export const IconPlus = styled(Icon)`
  width: 10px;
  height: 10px;
  fill: #999999;
`
