import styled from 'styled-components'
import { StyledTable, StyledTableButton } from '../../../../common/styled'

export const StyledTeacherTable = styled(StyledTable)``

export const StyledMaskButton = styled(StyledTableButton)`
  svg {
    transform: scale(2);
    margin-right: 5px;
  }
`
