import React from 'react'
import withIconStyles from '@edulastic/icons/src/HOC/withIconStyles'
import SVG from '@edulastic/icons/src/common/SVG'

const IconGraphMove = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15.794 15.794"
    width="15.794"
    height="15.794"
    {...props}
  >
    <path
      d="M3.417,11.313,0,7.9,3.416,4.48V7.111H7.111V3.417H4.481L7.9,0l3.416,3.416H8.683V7.111h3.695V4.48L15.794,7.9l-3.416,3.416V8.683H8.683v3.695h2.631L7.9,15.794,4.481,12.378H7.111V8.683H3.417Z"
      fill="#66707a"
    />
  </SVG>
)

export default withIconStyles(IconGraphMove)
