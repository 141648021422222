import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { EduButton, notification } from '@edulastic/common'
import { IconShoppingBag } from '@edulastic/icons'
import { test as testConstants } from '@edulastic/constants'
import { MdInfoOutline } from 'react-icons/md'
import styled from 'styled-components'
import { themeColor, white } from '@edulastic/colors'
import { segmentApi } from '@edulastic/api'
import {
  getTestsSelector,
  addItemsToCartFromTestAction,
  getIsAddingTestToCartStateSelector,
} from '../../ducks'
import { getSelectedItemSelector } from '../../../TestPage/components/AddItems/ducks'
import { TooltipStyled } from '../../../Dashboard/components/Showcase/components/Myclasses/components/CardAssignmentContent/styled'
import {
  getUserRole,
  enableSectionAdaptiveTestSelector,
} from '../../../src/selectors/user'
import { whiteTooltipProps } from '../ViewModal/styled'

const MAX_COMBINED_TEST_LIMIT = 150

const CombineTestButton = ({
  testId,
  test = {},
  selectedItems,
  addItemsToCartFromTest,
  isAddingTestToCart,
  listView = false,
  userRole,
  enableSectionAdaptiveTest,
}) => {
  const { testCategory, itemGroups } = test
  /** *****===========*******
  validate test category is default and proceed
  validate items in cart not exceeding the max limit
  validate if test is already added
  proceed to add to cart if all three conditions satisfy
  *******===========****** */
  const handleCombineTest = (e) => {
    segmentApi.genericEventTrack('LibraryAddTestToCart', {
      origin: 'test_library',
      trigger: listView ? 'test_card_list' : 'wizzard',
      role: userRole,
    })
    e.stopPropagation()
    if (
      isAddingTestToCart ||
      testCategory !== testConstants.testCategoryTypes.DEFAULT
    ) {
      return
    }
    if (selectedItems.length >= MAX_COMBINED_TEST_LIMIT) {
      return notification({ messageKey: 'combineLimitExceeded' })
    }
    const allItemsFromTest = itemGroups.flatMap((itemGroup) =>
      itemGroup.items.map((itemScoreMap) => itemScoreMap.itemId)
    )
    const itemsFromTestWithoutCartItemIds = allItemsFromTest.filter(
      (itemId) => !selectedItems.includes(itemId)
    )
    if (itemsFromTestWithoutCartItemIds.length === 0) {
      return notification({ type: 'warn', messageKey: 'testAlreadyAdded' })
    }
    addItemsToCartFromTest({
      testId,
      itemsFromTestWithoutCartItemIds,
    })
  }

  const combinedBtnProps = {
    onClick: handleCombineTest,
    listView,
    disabled: testCategory !== testConstants.testCategoryTypes.DEFAULT,
  }

  const combinedBtnText = 'Merge Test'

  const tooltipText =
    testCategory !== testConstants.testCategoryTypes.DEFAULT
      ? `${
          enableSectionAdaptiveTest ? 'Adaptive Section, ' : ''
        }Video, SnapQuiz & Smart Build tests cannot be combined with other tests`
      : 'You can combine or merge items from multiple tests and create a new test.'

  return (
    <TooltipStyled {...whiteTooltipProps} title={tooltipText}>
      <StyledCombineBtnContainer listView={listView}>
        <StyledCombineBtn
          {...combinedBtnProps}
          style={{ justifyContent: 'center' }}
          data-cy="addTotestButton"
        >
          <IconShoppingBag style={{ fill: themeColor }} />
          <span>
            {combinedBtnText}
            <MdInfoOutline
              size="14px"
              style={{
                verticalAlign: 'middle',
                fill: themeColor,
                marginRight: 0,
              }}
            />
          </span>
        </StyledCombineBtn>
      </StyledCombineBtnContainer>
    </TooltipStyled>
  )
}

const enhance = compose(
  connect(
    (state) => ({
      tests: getTestsSelector(state),
      selectedItems: getSelectedItemSelector(state),
      isAddingTestToCart: getIsAddingTestToCartStateSelector(state),
      userRole: getUserRole(state),
      enableSectionAdaptiveTest: enableSectionAdaptiveTestSelector(state),
    }),
    {
      addItemsToCartFromTest: addItemsToCartFromTestAction,
    }
  )
)

export default enhance(CombineTestButton)

const StyledCombineBtnContainer = styled.div`
  height: 100%;
  margin-right: ${({ listView }) => (!listView ? '5px' : 'auto')};
  display: ${({ listView }) => (!listView ? 'block' : 'inline')};
`

const StyledCombineBtn = styled(EduButton)`
  height: ${({ listView }) => (listView ? 'auto' : '40px !important')};

  &.ant-btn.ant-btn-primary, ${({ listView }) =>
    listView ? '&.ant-btn.ant-btn-primary:hover,' : ''}
  &.ant-btn.ant-btn-primary: focus {
    ${(props) => (props.listView ? 'margin-right: 10px' : 0)};
    ${(props) => (props.listView ? 'height: 36px' : '40px')};
    background-color: ${white};
    border-color: ${themeColor};
    color: ${themeColor};
  }
  &.ant-btn.ant-btn-primary:focus svg {
    fill: ${themeColor} !important;
  }
  ${({ listView }) =>
    listView
      ? `&.ant-btn.ant-btn-primary:hover svg {fill: ${themeColor} !important;}`
      : ''}
`
