import { Select, TextAreaInputStyled } from '@edulastic/common'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Row from 'antd/lib/row'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { segmentApi } from '@edulastic/api'
import { selectsData } from '../../TestPage/components/common'
import AIContentView from '../common/AIContentView'
import AIFooterAction from '../common/AIFooterAction'
import PassageView from '../common/PassageView'
import PromptSuggestions from '../common/PromptSuggestions'
import { StyledForm } from '../common/styled'
import {
  PASSAGE_AUTHORING_PROMPTS,
  PROMPT_SUGGESTIONS,
} from '../constants/prompt'

import {
  generateAIPassageAction,
  getAIPassageState,
} from '../ducks/passageGeneration'
import { PASSAGE_SIZE } from '../constants/form'

const AIPassageGeneration = ({
  form,
  onFinish,
  aiPassageState,
  generateAIPassage,
}) => {
  const { getFieldDecorator, setFieldsValue, getFieldValue } = form
  const { apiStatus, result } = aiPassageState

  const currentPrompt = PASSAGE_AUTHORING_PROMPTS.PASSAGE_GENERATION

  const onSubmit = (e) => {
    e.preventDefault()
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const requestBody = {
          ...values,
          grades: [values.grades],
        }

        if (currentPrompt.trackingEvent) {
          segmentApi.genericEventTrack('GenerateAIItem', {
            source: currentPrompt.trackingEvent.source,
          })
        }

        generateAIPassage(requestBody)
      }
    })
  }

  const isLoading = apiStatus === 'INITIATED'

  return (
    <StyledForm onSubmit={onSubmit}>
      <Row type="flex" gutter={[20, 0]}>
        <Col span={12}>
          <Form.Item label="GRADE LEVEL">
            {getFieldDecorator('grades', {
              rules: [
                {
                  required: true,
                  message: 'Please select grade level',
                },
              ],
            })(
              <Select
                disabled={isLoading}
                placeholder="Select"
                dropdownStyle={{ zIndex: 1005 }}
                options={selectsData.allGrades.map(({ text, value }) => ({
                  label: text,
                  value,
                }))}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="PASSAGE LENGTH">
            {getFieldDecorator('size', {
              rules: [
                {
                  required: true,
                  message: 'Please select passage length',
                },
              ],
            })(
              <Select
                disabled={isLoading}
                placeholder="Select"
                dropdownStyle={{ zIndex: 1005 }}
                options={PASSAGE_SIZE.map((value) => ({
                  value,
                  label: value,
                }))}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="CONTENT TITLE">
        {getFieldDecorator('contentTitle', {
          rules: [
            {
              required: true,
              message: 'Please input content title',
            },
            {
              max: 250,
              message: 'This field should not exceed 250 characters!',
            },
          ],
        })(
          <Input
            disabled={isLoading}
            placeholder="Enter details here"
            data-cy="passageContentTitle"
          />
        )}
      </Form.Item>
      <Form.Item label="CONTENT REQUIREMENT">
        {getFieldDecorator('contentRequirement', {
          rules: [
            {
              max: 500,
              message: 'This field should not exceed 500 characters!',
            },
          ],
        })(
          <TextAreaInputStyled
            disabled={isLoading}
            autoSize={{ minRows: 4 }}
            placeholder="Enter details here"
            data-cy="passageContentRequirement"
          />
        )}
      </Form.Item>
      <PromptSuggestions
        disabled={isLoading}
        currentPrompt={getFieldValue('contentRequirement')}
        suggestions={PROMPT_SUGGESTIONS.PASSAGE_CONTENT_REQUIREMENT}
        onSelect={(contentRequirement) => {
          setFieldsValue({
            contentRequirement,
          })
        }}
      />
      {apiStatus && (
        <div style={{ marginBottom: '90px' }}>
          <AIContentView
            onRegenerate={onSubmit}
            title="Created by AI Assistant for Passage Generation"
            loadingTitle="Generating passage"
            content={<PassageView passage={result?.passage} />}
            status={apiStatus}
          />
        </div>
      )}
      <AIFooterAction
        status={apiStatus}
        onRegenerate={onSubmit}
        action={{
          label: '   ADD PASSAGE',
          onClick: () => {
            onFinish({
              passage: result?.passage,
            })
          },
        }}
        isLoading={isLoading}
      />
    </StyledForm>
  )
}

const enhance = compose(
  Form.create({ name: 'ai-passage-generation' }),
  connect(
    (state) => ({
      aiPassageState: getAIPassageState(state),
    }),
    {
      generateAIPassage: generateAIPassageAction,
    }
  )
)

export default enhance(AIPassageGeneration)
