import { SECTION_STATUS } from '@edulastic/constants/const/testActivityStatus'
import { adaptiveTestSectionNames } from './constants'

export const getAdaptiveTestSectionNames = ({ groupName, index }) => {
  if (index === 0) {
    groupName = adaptiveTestSectionNames.firstSection
  } else if (index === 1) {
    groupName = adaptiveTestSectionNames.secondSection
  }
  return groupName
}

export const isAnySectionStarted = (itemsToDeliverInGroup = []) => {
  if (!itemsToDeliverInGroup?.length) {
    return false
  }
  return itemsToDeliverInGroup.some(
    (section) =>
      section?.status !== SECTION_STATUS.NOT_STARTED &&
      section?.status !== SECTION_STATUS.LOCKED
  )
}
