import React, { useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {
  accessibilityColors,
  themeColor,
  themeColorBlue,
  white,
} from '@edulastic/colors'
import { withNamespaces } from '@edulastic/localization'
import {
  CustomModalStyled,
  EduButton,
  FlexContainer,
  withKeyboard,
} from '@edulastic/common'
import { StyledMenu, MenuItem } from './styled'
import { getDisabledQuestionDropDownIndexMapSelector } from '../../../selectors/test'

const getItemStatusColor = (selectedCard) => {
  switch (selectedCard) {
    case 'notAnswered':
      return accessibilityColors.studentSubmitPage.markedSkipped
    case 'answered':
      return accessibilityColors.studentSubmitPage.markedAnswered
    case 'bookmarks':
      return accessibilityColors.studentSubmitPage.markedForReview
    default:
      return accessibilityColors.studentSubmitPage.markedSkipped
  }
}

const ReviewQuestionsModal = ({
  options,
  filterData = {},
  gotoQuestion,
  skipped = [],
  bookmarks,
  blockNavigationToAnsweredQuestions,
  handleClose,
  showReviewPopup,
  gotoSummary,
  previewPlayer,
  finishTest,
  disabledQuestionDropDownIndexMap,
  t: i18Translate,
}) => {
  const [selectedCard, setSelectedCard] = useState('notAnswered')
  const handleCardClick = (cardType) => setSelectedCard(cardType)
  const { totalQuestions, totalBookmarks, totalUnanswered } = filterData

  const getOptions = () => {
    switch (selectedCard) {
      case 'notAnswered':
        return options.filter((o, i) => skipped[i])
      case 'answered':
        return options.filter((o, i) => !skipped[i])
      case 'bookmarks':
        return options.filter((o, i) => bookmarks[i])
      default:
        return options
    }
  }

  const handleQuestionCLick = (e) => {
    handleClose()
    e && gotoQuestion(options[parseInt(e.key, 10)])
  }

  return (
    <CustomModalStyled
      maskClosable={false}
      textAlign="left"
      centered
      visible={showReviewPopup}
      onCancel={handleClose}
      destroyOnClose
      footer={[
        <EduButton
          height="40px"
          isGhost
          key="cancelButton"
          onClick={handleClose}
          style={{ padding: '10px 40px' }}
        >
          {i18Translate('common.layout.reviewQuestionsModal.noCancel')}
        </EduButton>,
        <EduButton
          height="40px"
          key="okButton"
          onClick={previewPlayer ? finishTest : gotoSummary}
          style={{ padding: '10px 52px' }}
        >
          {i18Translate('common.layout.questionNavigation.submit')}
        </EduButton>,
      ]}
      title={i18Translate('common.test.review')}
      width="640px"
    >
      <div>
        <LeadingParagraph>
          {i18Translate('common.layout.reviewQuestionsModal.reviewTextInitial')}{' '}
          <b>
            {totalQuestions - totalUnanswered} of {totalQuestions}
          </b>{' '}
          {i18Translate('common.layout.reviewQuestionsModal.reviewTextEnd')}
        </LeadingParagraph>
        <FlexContainer
          marginBottom="20px"
          alignItems="center"
          justifyContent="space-between"
        >
          <QuestionHead>
            {i18Translate('common.layout.questionlist.heading')}
          </QuestionHead>
          <ReviewFilters>
            <Card
              selected={selectedCard === 'notAnswered'}
              onClick={() => handleCardClick('notAnswered')}
            >
              <div>
                {i18Translate('common.layout.reviewQuestionsModal.unanswered')}{' '}
                ({totalUnanswered})
              </div>
            </Card>
            <Card
              selected={selectedCard === 'answered'}
              onClick={() => handleCardClick('answered')}
            >
              <div>
                {i18Translate('common.layout.reviewQuestionsModal.answered')} (
                {totalQuestions - totalUnanswered})
              </div>
            </Card>
            <Card
              selected={selectedCard === 'bookmarks'}
              onClick={() => handleCardClick('bookmarks')}
            >
              <div>
                {i18Translate('common.layout.reviewQuestionsModal.bookmarked')}{' '}
                ({totalBookmarks})
              </div>
            </Card>
          </ReviewFilters>
        </FlexContainer>
        <StyledMenu
          style={{ height: '250px', overflow: 'auto', border: '0' }}
          onClick={handleQuestionCLick}
        >
          {getOptions().map((option) => (
            <MenuItem
              key={option}
              disabled={
                blockNavigationToAnsweredQuestions ||
                disabledQuestionDropDownIndexMap[option]
              }
              data-cy="questionSelectOptions"
              bg={getItemStatusColor(selectedCard)}
              onClick={() => {
                handleQuestionCLick({ key: option })
              }}
            >
              {option + 1}
            </MenuItem>
          ))}
        </StyledMenu>
        <LegendsContainer>
          <LegendWrapper>
            <LegendColor color={getItemStatusColor('unanswered')} />
            {i18Translate('common.layout.reviewQuestionsModal.unanswered')}
          </LegendWrapper>
          <LegendWrapper>
            <LegendColor color={getItemStatusColor('answered')} />
            {i18Translate('common.layout.reviewQuestionsModal.answered')}
          </LegendWrapper>
          <LegendWrapper>
            <LegendColor color={getItemStatusColor('bookmarks')} />
            {i18Translate('common.layout.reviewQuestionsModal.bookmarked')}
          </LegendWrapper>
        </LegendsContainer>
      </div>
    </CustomModalStyled>
  )
}

const enhance = compose(
  withNamespaces('student'),
  connect((state) => ({
    // Direct subscribe to disable question dropdown for quester player
    disabledQuestionDropDownIndexMap: getDisabledQuestionDropDownIndexMapSelector(
      state
    ),
  }))
)

export default enhance(ReviewQuestionsModal)

const Card = withKeyboard(styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 10px;
  cursor: pointer;
  padding: 2px 15px;
  border-right: 1px solid ${themeColor};
  color: ${themeColor};
  background: ${(props) => (props.selected ? themeColorBlue : white)};
  color: ${(props) => (props.selected ? white : '#40b395')};
  width: 130px;
  font-weight: 600;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${themeColor};
  }
  &:hover {
    color: ${white};
    background: ${themeColorBlue};
  }
  :last-child {
    border: none;
  }
`)

const LegendsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-size: 10px;
`
const LegendWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 10px;
`
const LegendColor = styled.div`
  background: ${(props) => props.color};
  margin-right: 10px;
  width: 12px;
  height: 12px;
`
const QuestionHead = styled.div`
  font-weight: bold;
  margin: 10px 0;
  font-size: 16px;
`

const ReviewFilters = styled.div`
  display: flex;
  border: 1px solid #40b394;
  border-radius: 3px;
`

const LeadingParagraph = styled.div`
  width: 90%;
`
