export const copyDomOnClickOfElements = [
  'question-select-dropdown',
  'answer-math-input-field',
  'ant-select-selection',
  'graph-toolbar-right li',
  'graph-toolbar-left li',
  'left-collapse-btn',
  'right-collapse-btn',
  'mq-editable-field',
  'froala-wrapper .fr-command',
]

export const copyDomOnHoverOfElements = [
  'parcc-question-list',
  'ant-dropdown-trigger',
]

export const copyDomOnBlurOfElements = ['ant-input']

export const copyDomOnScrollOfElements = ['froala-wrapper .fr-wrapper']
