import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { withNamespaces } from '@edulastic/localization'
import { response } from '@edulastic/constants'
import { clamp } from 'lodash'
import { Row } from '../../../styled/WidgetOptions/Row'
import { Col } from '../../../styled/WidgetOptions/Col'
import { Label } from '../../../styled/WidgetOptions/Label'

import { IconTrash } from '../styled/IconTrash'
import { TextInputStyled } from '../../../styled/InputStyles'
import { CustomStyleBtn } from '../../../styled/ButtonStyles'

class ResponseContainers extends Component {
  render() {
    const { containers, onChange, onAdd, onDelete, t } = this.props
    const { minHeight, maxHeight, minWidth, maxWidth } = response

    const handleChange = (index, prop, value, isClamp = false) => {
      let updatedValue = value

      if (isClamp) {
        if (prop === 'widthpx') {
          updatedValue = clamp(updatedValue, minWidth, maxWidth)
        } else {
          updatedValue = clamp(updatedValue, minHeight, maxHeight)
        }
      }

      onChange({
        index,
        prop,
        value: updatedValue,
      })
    }
    return (
      <Container>
        <Label>{t('component.options.responseBoxOverride')}</Label>

        {containers.map((container, index) => (
          <>
            <Row type="flex" justify="space-between" marginTop={15}>
              <Label>
                {t('component.options.responseBox')} {index + 1}
              </Label>
              <IconTrash onClick={() => onDelete(index)} />
            </Row>

            <Row gutter={24}>
              <Col md={12}>
                <Label>{t('component.options.widthpx')}</Label>
                <TextInputStyled
                  type="number"
                  size="large"
                  value={container.widthpx || 0}
                  min={minWidth}
                  max={maxWidth}
                  onBlur={(e) =>
                    handleChange(index, 'widthpx', +e.target.value, true)
                  }
                  onChange={(e) =>
                    handleChange(index, 'widthpx', +e.target.value)
                  }
                />
              </Col>
              <Col md={12}>
                <Label>{t('component.options.heightpx')}</Label>
                <TextInputStyled
                  type="number"
                  size="large"
                  value={container.heightpx || 0}
                  min={minHeight}
                  max={maxHeight}
                  onBlur={(e) =>
                    handleChange(index, 'heightpx', +e.target.value, true)
                  }
                  onChange={(e) =>
                    handleChange(index, 'heightpx', +e.target.value)
                  }
                />
              </Col>
            </Row>
          </>
        ))}

        <CustomStyleBtn width="180px" onClick={onAdd}>
          {t('component.options.addResponseContainer')}
        </CustomStyleBtn>
      </Container>
    )
  }
}

ResponseContainers.propTypes = {
  containers: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

ResponseContainers.defaultProps = {}

export default withNamespaces('assessment')(ResponseContainers)

const Container = styled.div`
  margin-top: 32px;
`
