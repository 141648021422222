import React from 'react'
import styled, { keyframes } from 'styled-components'
import { loadingDotsAnimationDataTestId } from '../constants/test'

const DotWrapper = styled.div`
  display: inline-block;
  position: relative;
`
const bounce = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const Dot = styled.span`
  display: inline-block;
  margin: 0 2px;
  animation: ${bounce} 1.2s ease-in-out infinite alternate;
  animation-delay: ${(props) => props.delay};
`

const LoadingDots = () => {
  return (
    <DotWrapper data-testid={loadingDotsAnimationDataTestId}>
      <Dot delay="0s">.</Dot>
      <Dot delay="0.3s">.</Dot>
      <Dot delay="0.6s">.</Dot>
    </DotWrapper>
  )
}

export default LoadingDots
