export const ValidList = [
  'list',
  'align',
  'script',
  'indent',
  'header',
  'direction',
]

export const qlToFroalaMapping = {
  strike: 'strikeThrough',
  header1: 'h1',
  header2: 'h2',
  listordered: 'formatOL',
  listbullet: 'formatUL',
  alignright: 'align',
  blockquote: 'quote',
  scriptsub: 'subscript',
  scriptsuper: 'superscript',
  'indent+1': 'indent',
  'indent-1': 'outdent',
  directionrtl: 'paragraphFormat',
  clean: 'clearFormatting',
  formula: 'math',
  specialCharacters: 'specialCharacters',
  image: 'insertImage',
  link: 'insertLink',
}
