/* eslint-disable react/prop-types */
import React from 'react'

import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconClosedCaption = () => (
  <SVG
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_8081_2760)">
      <path d="M21.6667 -3.33398H-1.66667C-3.51667 -3.33398 -5 -1.83398 -5 -0.000651121V19.9993C-5 21.8327 -3.51667 23.3327 -1.66667 23.3327H21.6667C23.5 23.3327 25 21.8327 25 19.9993V-0.000651121C25 -1.83398 23.5 -3.33398 21.6667 -3.33398ZM21.6667 19.9993H-1.66667V-0.000651121H21.6667V19.9993ZM1.66667 14.9993H6.66667C7.58333 14.9993 8.33333 14.2493 8.33333 13.3327V11.666H5.83333V12.4993H2.5V7.49935H5.83333V8.33268H8.33333V6.66602C8.33333 5.74935 7.58333 4.99935 6.66667 4.99935H1.66667C0.75 4.99935 0 5.74935 0 6.66602V13.3327C0 14.2493 0.75 14.9993 1.66667 14.9993ZM13.3333 14.9993H18.3333C19.25 14.9993 20 14.2493 20 13.3327V11.666H17.5V12.4993H14.1667V7.49935H17.5V8.33268H20V6.66602C20 5.74935 19.25 4.99935 18.3333 4.99935H13.3333C12.4167 4.99935 11.6667 5.74935 11.6667 6.66602V13.3327C11.6667 14.2493 12.4167 14.9993 13.3333 14.9993Z" />
    </g>
    <defs>
      <clipPath id="clip0_8081_2760">
        <rect width="20" height="20" />
      </clipPath>
    </defs>
  </SVG>
)

export default withIconStyles(IconClosedCaption)
