export const playlistPageNavButtons = [
  {
    icon: null,
    value: 'playlist',
    text: 'Playlist',
  },
  {
    icon: null,
    value: 'insights',
    text: 'Insights',
  },
  {
    icon: null,
    value: 'differentiation',
    text: 'Differentiation',
  },
]
