const WebsiteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.265"
    height="17.265"
    viewBox="0 0 17.265 17.265"
  >
    <path
      className="a"
      d="M163.843,11.505a.785.785,0,0,1-.555-1.34l2.091-2.091A3.76,3.76,0,0,0,166.5,5.381a3.816,3.816,0,0,0-3.812-3.811,3.76,3.76,0,0,0-2.691,1.116L157.9,4.779a.785.785,0,0,1-1.11-1.11l2.091-2.091A5.314,5.314,0,0,1,162.686,0a5.387,5.387,0,0,1,5.381,5.381,5.314,5.314,0,0,1-1.581,3.806L164.4,11.275A.781.781,0,0,1,163.843,11.505Zm0,0"
      transform="translate(-150.802)"
    />
    <path
      className="a"
      d="M5.381,168.067A5.387,5.387,0,0,1,0,162.686a5.314,5.314,0,0,1,1.581-3.806l2.089-2.088a.785.785,0,0,1,1.11,1.11l-2.091,2.091a3.757,3.757,0,0,0-1.119,2.693A3.816,3.816,0,0,0,5.381,166.5a3.76,3.76,0,0,0,2.691-1.116l2.094-2.094a.785.785,0,1,1,1.11,1.11l-2.091,2.091A5.314,5.314,0,0,1,5.381,168.067Zm0,0"
      transform="translate(0 -150.802)"
    />
    <path
      className="a"
      d="M128.78,135.843a.785.785,0,0,1-.555-1.34l6.278-6.278a.785.785,0,1,1,1.11,1.11l-6.278,6.278A.782.782,0,0,1,128.78,135.843Zm0,0"
      transform="translate(-123.287 -123.287)"
    />
  </svg>
)

export default WebsiteIcon
