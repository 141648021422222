import { isEqual } from 'lodash'
import produce from 'immer'
import {
  adaptiveTestSettings,
  adaptiveTestThresholdTypes,
  customSectionAdaptiveSettingType,
  defaultSectionAdaptiveSettingType,
} from '../constants'

/**
 * Retrieves adaptive test settings based on provided test data.
 *
 * @param {Object} testData - The test data object containing item groups and adaptive delivery information.
 * @returns {Object} The adaptive test settings object, or the default settings if no item groups or adaptive delivery are provided.
 */
export const getAdaptiveSettingFromTest = (testData = {}) => {
  const { itemGroups, adaptiveDelivery } = testData
  let setting = adaptiveTestSettings[defaultSectionAdaptiveSettingType]
  if (itemGroups && adaptiveDelivery) {
    setting = {
      threshold: {
        [adaptiveDelivery.threshold.thresholdType]:
          adaptiveDelivery.threshold.value,
        scaledScore: itemGroups.slice(1).reduce((acc, group) => {
          if (group.settings.scaledScore) {
            acc[group.index] = group.settings.scaledScore
          }
          return acc
        }, {}),
      },
      itemsRequired: adaptiveDelivery.baseSectionMaxItemsCount,
      timeLimit: itemGroups[0].settings.timeLimit,
      scaledScore: itemGroups[0].settings.scaledScore,
    }
  }

  return setting
}

/**
 * Retrieves the adaptive setting type from the given settings based on the specified adaptive type.
 *
 * @param {Object} setting - The settings object to search.
 * @param {string} thresholdType - The adaptive type to filter for.
 * @returns {string} - The adaptive setting type if found, otherwise defaultSectionAdaptiveSettingType.
 */
export const getAdaptiveSettingTypeFromSettings = (
  setting,
  thresholdType,
  showCustomIfNotFound = false
) => {
  const _adaptiveTestSettings = produce(adaptiveTestSettings, (draft) => {
    // Remove other types from draft to compare equality
    const otherTypes = Object.keys(adaptiveTestThresholdTypes).filter(
      (t) => t !== thresholdType
    )
    otherTypes.forEach((otherType) => {
      Object.keys(draft).forEach((key) => {
        delete draft[key].threshold[otherType]
      })
    })
    return draft
  })

  // Use Object.entries to iterate over key-value pairs directly
  for (const [key, value] of Object.entries(_adaptiveTestSettings)) {
    if (isEqual(value, setting)) {
      return key
    }
  }

  return showCustomIfNotFound
    ? customSectionAdaptiveSettingType
    : defaultSectionAdaptiveSettingType
}
