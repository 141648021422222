import React from 'react'
import JsxParser from 'react-jsx-parser/lib/react-jsx-parser.min'

import { EduIf } from '@edulastic/common'
import {
  BannerActionButton,
  BannerInfo,
  CloseBannerIcon,
  Container,
  LeftContainer,
  RightContainer,
} from './styled'
import { getJsx } from '../utils'

const DashboardBanner = ({
  onClose,
  onClick,
  banner,
  showDiscountOffer,
  isValidTargetUrl,
}) => {
  const buttonStyle = {
    marginLeft: '0px',
    ...(showDiscountOffer ? { backgroundColor: 'orange' } : {}),
  }

  return (
    <Container $imageUrl={banner.imageUrl} data-cy="dashBoardBanner">
      <LeftContainer>
        <BannerInfo>
          <EduIf condition={banner.title}>
            <JsxParser renderInWrapper={false} jsx={getJsx(banner.title)} />
          </EduIf>
          <EduIf condition={banner.description}>
            <JsxParser
              renderInWrapper={false}
              jsx={getJsx(banner.description)}
            />
          </EduIf>
        </BannerInfo>
        <BannerActionButton
          height="32px"
          isGhost
          $buttonTextColor={banner.buttonTextColor}
          onClick={onClick}
          style={buttonStyle}
          data-cy="bannerActionButton"
        >
          {isValidTargetUrl ? (
            <a
              href={banner.targetUrl?.teacher}
              target="_blank"
              rel="noopener noreferrer"
            >
              {banner.buttonText}
            </a>
          ) : (
            banner.buttonText
          )}
        </BannerActionButton>
      </LeftContainer>
      <RightContainer>
        <CloseBannerIcon
          title="close"
          height={15}
          width={15}
          color={showDiscountOffer ? 'grey' : '#FFFFFF'}
          onClick={onClose}
          data-cy="bannerCloseButton"
        />
      </RightContainer>
    </Container>
  )
}

export default DashboardBanner
