import React from 'react'

import { StyledText } from '../components/styledComponents'

export const ANALYZE_BY_KEYS = {
  SCORE: 'score',
  MASTERY_LEVEL: 'masteryLevel',
}

export const ANALYZE_BY_OPTIONS = [
  { key: 'score', title: 'Score' },
  { key: 'masteryLevel', title: 'Mastery Level' },
]

export const SCORE_TYPE_KEYS = {
  SCALED_SCORE: 'scaledScore',
  RAW_SCORE: 'rawScore',
}

export const SCORE_TYPE_OPTIONS = [
  { key: 'scaledScore', title: 'Scaled Score' },
  { key: 'rawScore', title: 'Raw Score' },
]

export const VIEW_BY_KEYS = {
  // TEST: 'test',
  DOMAIN: 'domain',
  CLUSTER: 'cluster',
  STANDARD: 'standard',
}

export const VIEW_BY_OPTIONS = [
  // {key: 'test', title: 'Test'},
  { key: 'domain', title: 'Domain' },
  {
    key: 'disabledText',
    title: (
      <StyledText>
        Select single test to
        <br /> activate below options
      </StyledText>
    ),
    disabled: true,
  },
  {
    key: 'cluster',
    title: 'Cluster',
    disabled: true,
  },
  { key: 'standard', title: 'Standard', disabled: true },
]
const groupInfoMap = /** @type { const } */ ({
  test: {
    dataKey: 'metric.test._id',
    labelKey: 'metric.test.name',
    descriptionKey: 'metric.test.name',
  },
  domain: {
    dataKey: 'skill.domainId',
    labelKey: 'skill.domain',
    descriptionKey: 'skill.domainName',
  },
  cluster: {
    dataKey: 'skill.clusterId',
    labelKey: 'skill.cluster',
    descriptionKey: 'skill.clusterName',
  },
  standard: {
    dataKey: 'skill.standardId',
    labelKey: 'skill.standard',
    descriptionKey: 'skill.standardName',
  },
})

export const reportAtOptions = /** @type { const } */ ({
  test: {
    key: '_id',
    title: 'Test',
    ...groupInfoMap.test,
    groupKeys: [],
    tableGroupKeys: ['test._id'], // keys inside the 'values' property
  },
  domain: {
    key: 'domainId',
    title: 'Domain',
    ...groupInfoMap.domain,
    groupKeys: [groupInfoMap.test],
    tableGroupKeys: ['test._id', 'domainId'], // keys inside the 'values' property
  },
  cluster: {
    key: 'clusterId',
    title: 'Cluster',
    ...groupInfoMap.cluster,
    groupKeys: [groupInfoMap.domain],
    tableGroupKeys: ['domainId', 'clusterId'], // keys inside the 'values' property
  },
  standard: {
    key: 'standardId',
    title: 'Standard',
    ...groupInfoMap.standard,
    groupKeys: [groupInfoMap.domain, groupInfoMap.cluster],
    tableGroupKeys: ['domainId', 'clusterId', 'standardId'], // keys inside the 'values' property
  },
})

export const DEFAULT_FILTERS = {
  // student filters
  termId: '',
  districtIds: [],
  schoolIds: [],
  teacherIds: [],
  grades: [],
  subjects: [],
  courseId: '',
  classIds: [],
  groupIds: [],
  // test filters
  testGrades: [],
  testSubjects: [],
  assessmentTypes: [],
  tagIds: [],
  testIds: [],
  // demographic filters
  race: '',
  gender: '',
  iepStatus: '',
  frlStatus: '',
  ellStatus: '',
  hispanicEthnicity: '',
  // page filters
  reportAt: VIEW_BY_OPTIONS[0].key,
  scoreType: SCORE_TYPE_OPTIONS[0].key,
  curriculumId: '',
  standardGrades: [],
  domainIds: [],
  clusterIds: [],
  standardIds: [],
}

export const SKILL_INFO_FILTERS = [
  // student filters
  'termId',
  'districtIds',
  'schoolIds',
  'teacherIds',
  'grades',
  'subjects',
  'courseId',
  'classIds',
  'groupIds',
  // test filters
  'testGrades',
  'testSubjects',
  'assessmentTypes',
  'tagIds',
  'testIds',
  // page filters
  'curriculumId',
]

export const PAGE_STANDARD_FILTERS = ['domainIds', 'clusterIds', 'standardIds']

export const DIMENSION_COLUMN = {
  key: 'dimension',
  dataIndex: 'dimension',
  title: 'dimension',
  align: 'left',
  fixed: 'left',
  width: 200,
  className: 'dimension',
  render: (value) => value.name || '',
}
