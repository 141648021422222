import styled from 'styled-components'
import { IconClose } from '@edulastic/icons'
import { greyThemeDark4 } from '@edulastic/colors'
import { SelectInputStyled } from '@edulastic/common'

export const MainContainer = styled.div`
  background-color: ${greyThemeDark4};
  padding: 16px 30px;
  border-radius: 8px;
  position: relative;
  margin-bottom: 24px;
  width: 100%;
`

export const CloseBannerIcon = styled(IconClose)`
  position: absolute;
  top: 12px;
  right: 12px;
  &:hover {
    cursor: pointer;
  }
`

// InterestedStandardInputs styled

export const StandardInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;

  .ant-select-dropdown-menu-item {
    justify-content: flex-start;
    gap: 10px;

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: rgba(63, 132, 229, 1);
      border-color: rgba(63, 132, 229, 1);
    }

	.anticon-check {
		display: none !important;
	}
  }

  
  .
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`
export const Heading = styled.h3`
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  margin: 0px;
  /* border: 1px solid red; */
`
export const Description = styled.p`
  font-size: 12px;
  font-weight: 600px;
  color: #ffffff;
`
export const CustomSelect = styled(SelectInputStyled)`
  &.ant-select {
    .ant-select-selection {
      border-radius: 4px;
      min-height: 36px;
      .ant-select-arrow {
        top: 19px !important;
      }
    }
  }
  .ant-select-selection__rendered {
    .ant-select-selection {
      &__choice {
        background-color: #555555 !important;
        &__content {
          color: #ffffff !important;
        }
        .ant-select-remove-icon svg {
          fill: #ffffff !important;
        }
      }
    }
  }
  .ant-select-dropdown-menu-item {
    background-color: red !important;
  }
`

// Confirmation styles
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
