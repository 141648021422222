import React, { useEffect, useState, useRef, useCallback } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { uniq } from 'lodash'
import { fileTypes, aws } from '@edulastic/constants'
import { views } from '@edulastic/common/src/components/FileUploader/constants'
import { Select, Divider } from 'antd'
import { EduButton, notification } from '@edulastic/common'
import tagsApi from '@edulastic/api/src/tags'
import FileUploader from '@edulastic/common/src/components/FileUploader'
import { FlexContainer } from './styled'
import {
  uploadTestStatusAction,
  uploadTestRequestAction,
  UPLOAD_STATUS,
} from '../ducks'
import {
  getAllTagsAction,
  getAllTagsSelector,
  addNewTagAction,
} from '../../TestPage/ducks'
import ImportSummary from './ImportSummary'

const UploadTest = ({
  uploadTest,
  getAllTags,
  allTagsData,
  addNewTag,
  contentType,
  fileDropAreaRef,
  createTestRef,
  status,
  uploadTestStatus,
}) => {
  const [showTags, setShowTags] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [selectedTags, setSelectedTags] = useState([])
  const [isSummaryVisible, setIsSummaryVisible] = useState(false)
  const [qtiUploadPayload, setQtiUploadPayload] = useState(null)
  const intervalRef = useRef(null)
  const jobIds = JSON.parse(sessionStorage.getItem('jobIds'))

  const [uploadedFiles, setUploadedFiles] = useState([])

  useEffect(() => {
    getAllTags({ type: 'testitem' })
  }, [])

  useEffect(() => {
    // When jobIds doesn't exist in session updating status to standby.
    if (!jobIds?.length) {
      uploadTestStatus(UPLOAD_STATUS.STANDBY)
      setIsSummaryVisible(false)
      setQtiUploadPayload(null)
    }
  }, [])

  const searchTags = async (_value) => {
    if (
      allTagsData?.some(
        (tag) =>
          tag?.tagName?.toLowerCase() === _value?.toLowerCase() ||
          tag?.tagName?.toLowerCase() === _value?.trim().toLowerCase()
      )
    ) {
      setSearchValue('')
    } else {
      setSearchValue(_value)
    }
  }

  const setPayloadForQtiUpload = (files, tags) => {
    if (files.length)
      setQtiUploadPayload({
        responseFiles: files.map((file) => file.cdnUrl),
        contentType,
        tags,
      })
  }

  const selectTags = async (id) => {
    let newTag = {}
    const tempSearchValue = searchValue
    if (id === searchValue) {
      setSearchValue('')
      try {
        const { _id, tagName } = await tagsApi.create({
          tagName: tempSearchValue,
          tagType: 'testitem',
        })
        newTag = { _id, tagName }
        addNewTag({ tag: newTag, tagType: 'testitem' })
      } catch (e) {
        notification({ messageKey: 'savingTagFailed' })
      }
    } else {
      newTag = allTagsData.find((tag) => tag._id === id)
    }
    const newTags = uniq([...selectedTags, newTag._id])
    setSelectedTags(newTags)
    setSearchValue('')
    setPayloadForQtiUpload(uploadedFiles, newTags)
  }

  const deselectTags = (id) => {
    const newTags = selectedTags.filter((tag) => tag !== id)
    setSelectedTags([...newTags])
    setPayloadForQtiUpload(uploadedFiles, newTags)
  }

  const resetQtiState = useCallback(() => {
    setUploadedFiles([])
    setShowTags(false)
    setQtiUploadPayload(null)
  }, [])

  useEffect(() => {
    setIsSummaryVisible(status !== UPLOAD_STATUS.STANDBY)
    if (status === UPLOAD_STATUS.STANDBY) {
      resetQtiState()
    }
  }, [status])

  useEffect(() => {
    resetQtiState()
  }, [contentType])

  return (
    <FlexContainer
      flexDirection="column"
      alignItems="flex-start"
      style={{ padding: 30 }}
    >
      <FileUploader
        fileDropAreaRef={fileDropAreaRef}
        maxFilesCount={5}
        allowedFileTypes={[
          fileTypes.ZIP,
          fileTypes.X_ZIP,
          fileTypes.X_ZIP_COMPRESSED,
        ]}
        view={
          contentType === 'qti'
            ? views.TEST_SELECT_QTI_UPLOAD
            : views.TEST_SELECT_WEBCT_UPLOAD
        }
        s3Folder={
          contentType === 'qti'
            ? aws.s3Folders.QTI_IMPORT
            : aws.s3Folders.WEBCT_IMPORT
        }
        maxFileSize={15}
        fileUploadFinishCallback={(result) => {
          setUploadedFiles(result.filesData)
          setPayloadForQtiUpload(result.filesData, selectedTags)
        }}
        fileUploadProgressCallback={() => {
          setShowTags(true)
        }}
        resetAllData={!uploadedFiles.length}
        uploadCancelCallback={() => resetQtiState()}
        fileDeleteCallback={() => resetQtiState()}
      />
      {showTags && (
        <>
          <Divider />
          <FlexContainer alignItems="center">
            <div>
              <b>Tags</b>&nbsp;
              <small>(OPTIONAL)</small>&nbsp;
            </div>
            <Select
              data-cy="tagsSelect"
              mode="multiple"
              style={{ width: '300px' }}
              optionLabelProp="title"
              placeholder="Select tags"
              onSearch={searchTags}
              onSelect={selectTags}
              onDeselect={deselectTags}
              filterOption={(input, option) =>
                option.props.title
                  .toLowerCase()
                  .includes(input.trim().toLowerCase())
              }
            >
              {searchValue.trim() ? (
                <Select.Option key={0} value={searchValue} title={searchValue}>
                  {`${searchValue} (Create new Tag)`}
                </Select.Option>
              ) : (
                ''
              )}
              {allTagsData.map(({ tagName, _id }) => (
                <Select.Option key={_id} value={_id} title={tagName}>
                  {tagName}
                </Select.Option>
              ))}
            </Select>
          </FlexContainer>
        </>
      )}
      {qtiUploadPayload &&
        createTestRef?.current &&
        createPortal(
          <FlexContainer mt="20px" justifyContent="center">
            <EduButton
              data-cy="createTest"
              onClick={() => uploadTest(qtiUploadPayload)}
            >
              Create Test
            </EduButton>
          </FlexContainer>,
          createTestRef.current
        )}
      <ImportSummary
        status={status}
        visible={isSummaryVisible}
        intervalRef={intervalRef}
      />
    </FlexContainer>
  )
}

UploadTest.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default withRouter(
  connect(
    (state) => ({
      allTagsData: getAllTagsSelector(state, 'testitem'),
      status: state.admin.importTest.status,
    }),
    {
      uploadTest: uploadTestRequestAction,
      uploadTestStatus: uploadTestStatusAction,
      getAllTags: getAllTagsAction,
      addNewTag: addNewTagAction,
    }
  )(UploadTest)
)
