import React from 'react'
import withIconStyles from '@edulastic/icons/src/HOC/withIconStyles'
import SVG from '@edulastic/icons/src/common/SVG'

const IconUndo = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="15.364"
    height="13.717"
    viewBox="0 0 15.364 13.717"
    {...props}
  >
    <g transform="translate(0 -27.417)">
      <path d="M14.91,33.616q-1.389-3.455-7.5-3.455H5.487V27.966a.546.546,0,0,0-.935-.386L.163,31.97a.538.538,0,0,0,0,.772l4.39,4.39a.549.549,0,0,0,.935-.386V34.55h1.92q.84,0,1.5.051a10.847,10.847,0,0,1,1.32.184,4.9,4.9,0,0,1,1.14.364,4.037,4.037,0,0,1,.9.6,2.987,2.987,0,0,1,.686.866,4.666,4.666,0,0,1,.416,1.187,7.3,7.3,0,0,1,.15,1.552q0,.471-.043,1.055a1.757,1.757,0,0,1-.021.2,1.765,1.765,0,0,0-.021.227.32.32,0,0,0,.073.214.251.251,0,0,0,.2.086.293.293,0,0,0,.24-.146.98.98,0,0,0,.111-.189q.051-.111.116-.257c.043-.1.073-.166.09-.206a10.294,10.294,0,0,0,1.089-3.867A7.806,7.806,0,0,0,14.91,33.616Z" />
    </g>
  </SVG>
)

export default withIconStyles(IconUndo)
