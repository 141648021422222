import { compose } from 'redux'
import connect from 'react-redux/lib/connect/connect'
import React, { useMemo, useState } from 'react'
import { isPremiumUserSelector } from '../../../src/selectors/user'
import {
  getProducts,
  getSubscriptionDataSelector,
  slice,
  trialPeriodTextSelector,
} from '../../../Subscription/ducks'
import AuthorCompleteSignupButton from '../../../../common/components/AuthorCompleteSignupButton'
import TrialModal from '../../../Dashboard/components/Showcase/components/Myclasses/components/TrialModal'

const SignupAndTrialModals = ({
  onCancel,
  onTrialSubsConfirmation,
  startTrialAction,
  isPremiumUser,
  displayText,
  trialProducts = [],
  showAIBanner = false,
  products = [],
  subscriptionData: { isPremiumTrialUsed } = {},
}) => {
  const [showTrialModal, setShowTrialModal] = useState(false)

  const addOnProductIds = useMemo(() => trialProducts.map((tp) => tp.id), [
    trialProducts,
  ])

  return (
    <>
      <AuthorCompleteSignupButton
        setShowCompleteSignupModal={() => {
          onCancel({ action: 'signup' })
        }}
        onClick={() => setShowTrialModal(true)}
        autoClickedOnRender
      />
      <TrialModal
        showAIBanner={showAIBanner}
        addOnProductIds={addOnProductIds}
        mandatoryAddOnProductIds={addOnProductIds}
        isPremiumUser={isPremiumUser}
        isPremiumTrialUsed={isPremiumTrialUsed}
        startPremiumTrial={startTrialAction}
        products={products}
        displayText={displayText}
        isVisible={showTrialModal}
        toggleModal={() => {
          setShowTrialModal((visible) => !visible)
          onCancel({ action: 'trial' })
        }}
        setShowTrialSubsConfirmation={() => {
          setShowTrialModal(false)
          onTrialSubsConfirmation()
        }}
      />
    </>
  )
}

const enhance = compose(
  connect(
    (state) => ({
      isPremiumUser: isPremiumUserSelector(state),
      products: getProducts(state),
      subscriptionData: getSubscriptionDataSelector(state),
      displayText: trialPeriodTextSelector(state),
    }),
    {
      startTrialAction: slice.actions.startTrialAction,
    }
  )
)

export default enhance(SignupAndTrialModals)
