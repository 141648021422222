/* eslint-disable react/prop-types */
import React from "react";
import withIconStyles from "./HOC/withIconStyles";
import SVG from "./common/SVG";

const IconTextEdit = props => (
  <SVG xmlns="http://www.w3.org/2000/svg" width="14.66" height="13.23" viewBox="0 0 14.66 13.23" {...props}>
    <path
      d="M1.35-4.16a.152.152,0,0,1,.16.12L2.87-.66c.12.24-.02.3-.2.36-.1.04-.18.08-.18.16,0,.1.12.14.3.16C4.05.1,6.15.06,6.99.06c.26,0,.34-.06.34-.18,0-.1-.08-.18-.3-.18a3.657,3.657,0,0,1-.66-.04c-.42-.08-.92-.34-1.56-1.84C3.69-4.74.73-11.56.39-12.54c-.2-.44-.3-.62-.46-.62-.22,0-.42.38-.78,1.32L-5.09-1.82c-.46,1.16-.74,1.4-2,1.52-.14,0-.24.08-.24.18,0,.14.1.18.32.18C-6.09.06-5.09,0-4.89,0c.62,0,1.34.06,1.96.06.18,0,.32-.04.32-.18,0-.08-.06-.16-.3-.16-.38,0-1-.02-1-.52a3.664,3.664,0,0,1,.26-1.04l.88-2.18c.04-.1.08-.14.16-.14Zm-3.56-1c-.08,0-.1-.04-.08-.1L-.71-9.34c.08-.2.14-.16.2,0L1.05-5.26c.02.06.02.1-.08.1Z"
      transform="translate(7.33 13.16)"
      fill="#66717a"
    />
  </SVG>
);

export default withIconStyles(IconTextEdit);
