import { themeColor } from '@edulastic/colors'
import styled from 'styled-components'

export const StyledInfoText = styled.div`
  color: ${themeColor};
  user-select: none;
  cursor: pointer;
  margin-top: 1.125rem;
  margin-left: 1rem;
  font-weight: 600;
`

export const StyledPopoverText = styled.div`
  font-size: 12px;
`
