import React, { useMemo, useState, useRef, useEffect } from 'react'

/**
 * A lazy-loading component that renders its children when they enter the viewport.
 *
 * @param {object} props
 * @param {boolean} [props.enabled=false] Whether the component should be lazy-loaded.
 * @param {boolean} [props.initialVisible=false] Whether the component should be initially visible.
 * @param {number} [props.defaultHeight=300] The default height of the placeholder element.
 * @param {number} [props.visibleOffset=1000] The offset from the viewport edge to trigger visibility.
 * @param {boolean} [props.stayRendered=true] Whether the component should stay rendered after being visible.
 * @param {Node} [props.root=null] The root element for intersection observation.
 * @param {string} [props.rootElement='div'] The root element type.
 * @param {string} [props.rootElementClass=''] Additional classes for the root element.
 * @param {string} [props.placeholderElement='div'] The placeholder element type.
 * @param {string} [props.placeholderElementClass=''] Additional classes for the placeholder element.
 * @param {ReactNode} children The children to be lazy-loaded.
 * @returns {ReactNode} The rendered component.
 */
const LazyLoadComponent = ({
  enabled = false,
  initialVisible = false,
  defaultHeight = 300,
  visibleOffset = 1000,
  stayRendered = true,
  root = null,
  rootElement = 'div',
  rootElementClass = '',
  placeholderElement = 'div',
  placeholderElementClass = '',
  children,
}) => {
  if (!enabled) {
    return children // Early return if lazy loading is disabled
  }

  const [isVisible, setIsVisible] = useState(initialVisible)
  const wasVisible = useRef(initialVisible)
  const placeholderHeight = useRef(defaultHeight)
  const intersectionRef = useRef(null)

  useEffect(() => {
    // Set up Intersection Observer to detect when the component enters the viewport
    if (intersectionRef.current) {
      const localRef = intersectionRef.current
      const observer = new IntersectionObserver(
        (entries) => {
          // Update visibility state and placeholder height based on intersection
          if (!entries[0].isIntersecting) {
            placeholderHeight.current = localRef.offsetHeight
          }
          if (typeof window !== 'undefined' && window.requestIdleCallback) {
            window.requestIdleCallback(
              () => setIsVisible(entries[0].isIntersecting),
              { timeout: 600 }
            )
          } else {
            setIsVisible(entries[0].isIntersecting)
          }
        },
        { root, rootMargin: `${visibleOffset}px 0px ${visibleOffset}px 0px` }
      )

      observer.observe(localRef)
      return () => {
        if (localRef) {
          observer.unobserve(localRef) // Clean up observer on unmount
        }
      }
    }
    return () => {}
  }, [])

  useEffect(() => {
    // Track if the component was ever visible for `stayRendered` behavior
    if (isVisible) {
      wasVisible.current = true
    }
  }, [isVisible])

  const placeholderStyle = { height: placeholderHeight.current }
  const rootClasses = useMemo(() => `renderIfVisible ${rootElementClass}`, [
    rootElementClass,
  ])
  const placeholderClasses = useMemo(
    () => `renderIfVisible-placeholder ${placeholderElementClass}`,
    [placeholderElementClass]
  )

  // Render the component or placeholder based on visibility and `stayRendered` prop
  return React.createElement(rootElement, {
    children:
      isVisible || (stayRendered && wasVisible.current) ? (
        <>{children}</>
      ) : (
        React.createElement(placeholderElement, {
          className: placeholderClasses,
          style: placeholderStyle,
        })
      ),
    ref: intersectionRef,
    className: rootClasses,
  })
}

export default LazyLoadComponent
