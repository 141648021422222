import { gray300 } from '@edulastic/colors'
import { Icon, Tooltip } from 'antd'
import React from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import { getUserDetails } from '../../../../../student/Login/ducks'
import { getUserOrgId } from '../../../../src/selectors/user'
import { togglefavoriteClassAction } from '../../../ducks'

const ToggleFavorite = ({
  user,
  districtId,
  classInfo,
  toggleFavoriteClass,
}) => {
  const handleToggle = (payload) => {
    const currentFilter = localStorage.getItem(
      `author:dashboard:classFilter:${user._id}:${districtId}`
    )

    toggleFavoriteClass({
      ...payload,
      removeClassFromList:
        !payload.toggleValue && currentFilter === 'MY_FAVORITES',
    })
  }

  return (
    <Tooltip
      title="Mark class as Favorite to organize your classes"
      placement="bottom"
    >
      <FavCircleBtn
        isFavorite={classInfo.isFavourite}
        onClick={(event) => {
          event.stopPropagation()
          handleToggle({
            groupId: classInfo._id,
            toggleValue: !classInfo.isFavourite,
          })
        }}
      >
        <Icon
          data-cy="classFavourite"
          type="heart"
          theme="filled"
          style={{ fontSize: '16px' }}
        />
      </FavCircleBtn>
    </Tooltip>
  )
}

export default connect(
  (state) => ({
    user: getUserDetails(state),
    districtId: getUserOrgId(state),
  }),
  {
    toggleFavoriteClass: togglefavoriteClassAction,
  }
)(ToggleFavorite)

const CircleBtnStyle = css`
  cursor: pointer;
  display: flex;
`
const FavCircleBtn = styled.div`
  ${CircleBtnStyle}
  &:hover {
    svg {
      transform: scale(1.15);
    }
  }
  & > i > svg {
    fill: ${(props) => (props.isFavorite ? '#ca481e' : gray300)};
  }
`
