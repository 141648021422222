// Context.js
import React, { createContext } from 'react'

const TestPageContext = createContext({ isTestAuthorView: false })

const TestPageProvider = ({ children }) => {
  return (
    <TestPageContext.Provider value={{ isTestAuthorView: true }}>
      {children}
    </TestPageContext.Provider>
  )
}

export { TestPageProvider, TestPageContext }
