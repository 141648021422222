import React from 'react'
import withIconStyles from '@edulastic/icons/src/HOC/withIconStyles'
import SVG from '@edulastic/icons/src/common/SVG'

const IconGraphLogarithm = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="40.486"
    height="23.939"
    viewBox="0 0 40.486 23.939"
    {...props}
  >
    <g transform="translate(-4.954 5.48)">
      <path
        d="M0,28.427a1,1,0,0,1-.7-.287,1,1,0,0,1-.011-1.414L26.287-.7A1,1,0,0,1,27.7-.713,1,1,0,0,1,27.713.7l-27,27.427A1,1,0,0,1,0,28.427Z"
        transform="translate(27.887 -1.425) rotate(45)"
        opacity="0.5"
      />
      <path
        d="M0,16.11a1,1,0,0,1-.707-.293,1,1,0,0,1,0-1.414L14.4-.707a1,1,0,0,1,1.414,0,1,1,0,0,1,0,1.414L.707,15.817A1,1,0,0,1,0,16.11Z"
        transform="translate(6.368 11.259) rotate(-45)"
        opacity="0.5"
      />
      <path
        d="M5963.942-620.575c-.006-.162-.1-4.007,3.62-8a23.18,23.18,0,0,1,8.774-5.579,56.015,56.015,0,0,1,16.935-3.161h.054a1,1,0,0,1,1,.947,1,1,0,0,1-.946,1.051c-14.456.76-21.155,4.757-24.229,7.976a11.964,11.964,0,0,0-2.835,4.637,7.137,7.137,0,0,0-.372,2.063v-.006Z"
        transform="translate(-5945.778 642.127)"
      />
      <path
        d="M1.277,0H-.232V-4.131l.015-.679.024-.742q-.376.376-.522.493l-.82.659-.728-.908,2.3-1.831h1.24Z"
        transform="translate(24.21 2.182)"
      />
      <path
        d="M2.537,0h-4.99V-1.05L-.662-2.861q.8-.815,1.04-1.13A2.486,2.486,0,0,0,.73-4.575a1.486,1.486,0,0,0,.107-.557A.811.811,0,0,0,.6-5.771a.918.918,0,0,0-.632-.21,1.818,1.818,0,0,0-.806.19,4.158,4.158,0,0,0-.815.542l-.82-.972A5.352,5.352,0,0,1-1.6-6.855a3.208,3.208,0,0,1,.757-.286,3.878,3.878,0,0,1,.918-.1A2.718,2.718,0,0,1,1.257-7a1.885,1.885,0,0,1,.8.684,1.816,1.816,0,0,1,.283,1.006,2.462,2.462,0,0,1-.173.925,3.553,3.553,0,0,1-.537.886A13.131,13.131,0,0,1,.344-2.2l-.918.864v.068h3.11Z"
        transform="translate(41.679 1.762)"
      />
      <path
        d="M3,0A3,3,0,1,1,0,3,3,3,0,0,1,3,0Z"
        transform="translate(23.164 2.239) rotate(30)"
      />
      <path
        d="M3,0A3,3,0,1,1,0,3,3,3,0,0,1,3,0Z"
        transform="translate(33.01 -5.377) rotate(30)"
      />
    </g>
  </SVG>
)

export default withIconStyles(IconGraphLogarithm)
