import { IconMultiLanguage } from '@edulastic/icons'
import React, { useMemo } from 'react'
import { languageByCode } from '@edulastic/constants/const/test'
import uuid from 'uuid'
import { Popover } from 'antd'
import { FlexContainer } from '@edulastic/common'
import styled from 'styled-components'
import { PopupContainer } from '../../../src/components/common/Tags'
import { Label, LabelText } from './styled'

const MultiLanguageSelector = React.memo(
  ({ languageCodes = [], isTestFlow }) => {
    const popup = useMemo(() => {
      const languages = languageCodes
        .map((languageCode) => languageByCode[languageCode])
        .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))

      return (
        <PopupContainer className="tag-wrapper">
          {languages.map((language) => (
            <Label
              data-cy={`language-${language}`}
              className="custom-tags"
              popupContainer
              key={`${language}-${uuid()}`}
            >
              <LabelText>{language}</LabelText>
            </Label>
          ))}
        </PopupContainer>
      )
    }, [languageCodes])

    return (
      <Popover
        placement="top"
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        content={popup}
        onClick={(e) => e.stopPropagation()}
      >
        <StyledLabel
          isTestFlow={isTestFlow}
          data-cy="multi-language"
          className="custom-tags"
        >
          <FlexContainer
            alignItems="center"
            justifyContent="center"
            height={isTestFlow ? 'auto' : '21px'}
          >
            <IconMultiLanguage />
          </FlexContainer>
        </StyledLabel>
      </Popover>
    )
  }
)

export default MultiLanguageSelector

const StyledLabel = styled(Label)`
  margin: ${({ isTestFlow }) => (isTestFlow ? '0px' : '0px 3px 3px 0px')};
`
