import styled from 'styled-components'

const Container = styled.div`
  height: ${({ isTestAuthorView }) =>
    isTestAuthorView ? 'calc(100vh - 40px)' : '100vh'};
  padding-top: ${({ isTestAuthorView, containerTopPadding }) =>
    isTestAuthorView
      ? `${containerTopPadding + 40}px`
      : ` ${containerTopPadding}px`};
  width: 100%;
  overflow: hidden;
  position: ${(props) => (props.scratchPadMode ? 'relative' : '')};
`

export default Container
