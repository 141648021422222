import React from 'react'
import SVG from './common/SVG'
import withIconStyles from './HOC/withIconStyles'

const IconAIItem = (props) => {
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <path
        d="M14.6259 5.625L15.3309 4.08L16.8759 3.375L15.3309 2.67L14.6259 1.125L13.9209 2.67L12.3759 3.375L13.9209 4.08L14.6259 5.625Z"
        fill="black"
      />
      <path
        d="M6.0009 5.625L6.7059 4.08L8.2509 3.375L6.7059 2.67L6.0009 1.125L5.2959 2.67L3.7509 3.375L5.2959 4.08L6.0009 5.625Z"
        fill="black"
      />
      <path
        d="M14.6259 9.75L13.9209 11.295L12.3759 12L13.9209 12.705L14.6259 14.25L15.3309 12.705L16.8759 12L15.3309 11.295L14.6259 9.75Z"
        fill="black"
      />
      <path
        d="M12.9084 7.215L10.7859 5.0925C10.6359 4.95 10.4484 4.875 10.2534 4.875C10.0584 4.875 9.8709 4.95 9.7209 5.0925L1.3434 13.47C1.0509 13.7625 1.0509 14.235 1.3434 14.5275L3.4659 16.65C3.6159 16.8 3.8034 16.875 3.9984 16.875C4.1934 16.875 4.3809 16.8 4.5309 16.6575L12.9084 8.28C13.2009 7.9875 13.2009 7.5075 12.9084 7.215ZM10.2534 6.69L11.3109 7.7475L10.4334 8.625L9.3759 7.5675L10.2534 6.69ZM3.9984 15.0675L2.9409 14.01L8.3184 8.625L9.3759 9.6825L3.9984 15.0675Z"
        fill="black"
      />
    </SVG>
  )
}

export default withIconStyles(IconAIItem)
