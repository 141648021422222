import { EduButton, FlexContainer, notification } from '@edulastic/common'
import {
  StyledDocUploadTitle,
  StyledUploadIcon,
} from '@edulastic/common/src/components/FileUploader/styled-components'
import { IconGdrive } from '@edulastic/icons'
import { Typography } from 'antd'
import React, { useState } from 'react'
import connect from 'react-redux/es/connect/connect'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { signUpState } from '@edulastic/constants'
import { GooglePicker } from '../../../../../../vendors/google'
import DollarPremiumSymbol from '../../../../AssignTest/components/Container/DollarPremiumSymbol'
import TrialModal from '../../../../Dashboard/components/Showcase/components/Myclasses/components/TrialModal'
import { isPremiumUserSelector } from '../../../../src/selectors/user'
import { slice, trialPeriodTextSelector } from '../../../../Subscription/ducks'
import { DEFAULT_TEST_TITLE } from '../../../../TestPage/utils'
import { stopPropagation } from '../../CreationOptions/utils'
import ImportGoogleFormModal from '../components/ImportGoogleFormModal'
import {
  getImportGoogleFormTestSelector,
  importGoogleFormTestAction,
} from '../ducks'
import AuthorCompleteSignupButton from '../../../../../common/components/AuthorCompleteSignupButton'

const GoogleFormQuiz = ({
  children,
  history,
  importGoogleFormTest,
  importGoogleFormState,
  isPremiumUser,
  startTrialAction,
  displayText,
  subscription,
}) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [showTrialModalVisible, setShowTrialModalVisible] = useState(false)

  const [formName, setFormName] = useState('')
  const {
    products = [],
    subscriptionData: { premiumProductId, isPremiumTrialUsed } = {},
  } = subscription || {}

  const toggleModal = () => {
    setModalVisible(!modalVisible)
  }

  const onAuthFailed = (message) => {
    console.error('Google Authentication Failed', message)
    return notification({ type: 'warn', messageKey: 'authenticationFailed' })
  }

  const onSelect = ({ action, docs, token }) => {
    const selectedDoc = docs?.[0]
    if (action === 'picked' && selectedDoc?.id && token) {
      setFormName(selectedDoc?.name || DEFAULT_TEST_TITLE)
      importGoogleFormTest({
        formId: selectedDoc.id,
        oAuthToken: token,
      })
      toggleModal()
    }
  }

  const redirectToSubscription = (e) => {
    stopPropagation(e)
    return history.push('/author/subscription')
  }
  const onCreate = (e) => {
    if (!isPremiumUser) {
      if (!children) {
        if (isPremiumTrialUsed) {
          return redirectToSubscription(e)
        }

        setShowTrialModalVisible(true)
      } else {
        return redirectToSubscription(e)
      }
    }
  }

  const { apiStatus, result, errorMessage } = importGoogleFormState

  const GooglePickerHOC = ({ children: googlePickerChildren }) => (
    <GooglePicker
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      onChange={onSelect}
      onAuthFailed={onAuthFailed}
      mimeTypes={['application/vnd.google-apps.form']}
    >
      {googlePickerChildren}
    </GooglePicker>
  )

  return (
    <>
      {!!products?.length && (
        <TrialModal
          addOnProductIds={premiumProductId ? [premiumProductId] : []}
          isVisible={showTrialModalVisible}
          toggleModal={() => setShowTrialModalVisible(!showTrialModalVisible)}
          isPremiumUser={isPremiumUser}
          isPremiumTrialUsed={isPremiumTrialUsed}
          setShowTrialSubsConfirmation={() => {
            setShowTrialModalVisible(false)
            notification({
              type: 'success',
              messageKey: 'trialPurchase',
            })
          }}
          startPremiumTrial={startTrialAction}
          products={products}
          displayText={displayText}
        />
      )}
      <ImportGoogleFormModal
        status={apiStatus}
        data={result}
        errorMessage={errorMessage}
        formName={formName}
        visible={modalVisible}
        onCancel={toggleModal}
      />
      {children ? (
        <GooglePickerHOC>
          <span onClick={onCreate}>{children}</span>
        </GooglePickerHOC>
      ) : (
        <FlexContainer
          mt={isPremiumUser ? '38px' : '0px'}
          padding="30px"
          flexDirection="column"
          alignItems="center"
        >
          <StyledUploadIcon />
          <StyledDocUploadTitle>
            Convert your Google Forms into Tests instantly
          </StyledDocUploadTitle>
          <br />
          {isPremiumUser ? (
            <GooglePickerHOC>
              <EduButton onClick={onCreate} style={{ borderRadius: '8px' }}>
                <IconGdrive />
                Import From Drive
              </EduButton>
            </GooglePickerHOC>
          ) : (
            <>
              <AuthorCompleteSignupButton
                renderButton={(handleClick) => (
                  <EduButton
                    onClick={handleClick}
                    style={{ borderRadius: '8px' }}
                  >
                    {isPremiumTrialUsed
                      ? 'Upgrade to Premium '
                      : 'Activate Trial '}
                    & import
                  </EduButton>
                )}
                onClick={onCreate}
              />
              <br />
              <Typography.Paragraph strong style={{ color: '#BE8B07' }}>
                <FlexContainer>
                  <DollarPremiumSymbol />
                  <FlexContainer marginLeft="8px">
                    This is a premium feature and you can access this by
                    {isPremiumTrialUsed
                      ? ' upgrading to premium plan'
                      : ' activating trial plan'}
                  </FlexContainer>
                </FlexContainer>
              </Typography.Paragraph>
              {!isPremiumTrialUsed && (
                <Typography.Paragraph strong>
                  No credit card required.
                </Typography.Paragraph>
              )}
            </>
          )}
        </FlexContainer>
      )}
    </>
  )
}

const enhance = compose(
  withRouter,
  connect(
    (state) => ({
      importGoogleFormState: getImportGoogleFormTestSelector(state),
      isPremiumUser: isPremiumUserSelector(state),
      subscription: state.subscription,
      displayText: trialPeriodTextSelector(state),
      isSignupCompleted: state.user?.currentSignUpState === signUpState.DONE,
    }),
    {
      getSubscriptionStatus: slice.actions.fetchUserSubscriptionStatus,
      startTrialAction: slice.actions.startTrialAction,
      importGoogleFormTest: importGoogleFormTestAction,
    }
  )
)

export default enhance(GoogleFormQuiz)
