import React, { useEffect, useState, useMemo } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { keyBy, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import {
  test as testConstants,
  testTypes as testTypesConstants,
} from '@edulastic/constants'
import { ThemeProvider } from 'styled-components'
import {
  EduElse,
  EduIf,
  EduThen,
  FlexContainer,
  MathFormulaDisplay,
  useRealtimeV2,
} from '@edulastic/common'
import { IconUserRegular } from '@edulastic/icons'
import { Spin, Modal, Tooltip } from 'antd'
import { SECTION_STATUS } from '@edulastic/constants/const/testActivityStatus'
import {
  FirestorePings,
  ForceFullScreenModal,
  useFullScreenListener,
  useTabNavigationCounterEffect,
} from '../../../assessment/themes/index'
import {
  StyledStudentName,
  StyledStudentNameContainer,
  StyledTestName,
} from '../../../publicTest/styled'
import { themes } from '../../../theme'
import {
  getActivityDataSelector,
  getAssignmentSettingsSelector,
  getIsLoadingSelector,
  getItemstoDeliverWithAttemptCount,
  getPasswordValidatedStatusSelector,
  getPreventSectionNavigationSelector,
  slice,
} from '../ducks'
import {
  utaStartTimeUpdateRequired,
  regradedRealtimeAssignmentAction,
} from '../../sharedDucks/AssignmentModule/ducks'
import { TIME_UPDATE_TYPE } from '../../../assessment/themes/common/TimedTestTimer'
import SummaryHeader from '../../TestAttemptReview/components/SummaryHeader'
import { saveBlurTimeAction } from '../../../assessment/actions/items'
import SectionsTestRequirePassword from './SectionsTestRequirePassword'
import { MainContainer, ContentArea } from '../styled-components'
import SectionsInfo from './SectionsInfo'
import TestSectionsContainer from './TestSectionsContainer'
import { getAdaptiveTestSectionNames, isAnySectionStarted } from '../utils'
import { getUserNameSelector } from '../../../author/src/selectors/user'
import { startSectionAction } from '../../../assessment/actions/test'
import RegradeModalForStudent from '../../../assessment/themes/common/RegradeModalForStudent'
import { getUserAccommodations } from '../../Login/ducks'

const {
  SKIPPED,
  SKIPPED_AND_WRONG,
  SKIPPED_PARTIAL_AND_WRONG,
} = testConstants.redirectPolicy.QuestionDelivery
const { TEST_TYPES_VALUES_MAP, TEST_TYPES } = testTypesConstants
const { COMMON, PRACTICE } = TEST_TYPES

const RealTimeV2HookWrapper = ({
  userId,
  testId,
  regradedAssignment,
  regradedRealtimeAssignment,
  handleRealtimeOpenSection,
  groupId,
  handleAssignmentUpdate,
}) => {
  /**
   * need to memoize the topics since its going to be used as dependency for
   * useEffect and its referrence shouldn't change for each re-render
   */
  const topicsMemoized = useMemo(() => {
    let topics = [
      `student_assessment:user:${userId}`,
      `student_assessment:test:${testId}:group:${groupId}`,
      `student_assessment:test:${testId}`,
      `student_assignment:class:${groupId}`,
    ]
    if (regradedAssignment?.newTestId) {
      topics = [
        ...topics,
        `student_assessment:test:${regradedAssignment?.newTestId}:group:${groupId}`,
      ]
    }
    return topics
  }, [userId, testId, groupId, regradedAssignment?.newTestId])

  useRealtimeV2(
    topicsMemoized,
    {
      regradedAssignment: (payload) => {
        regradedRealtimeAssignment(payload)
      },
      openSection: handleRealtimeOpenSection,
      correctItem: (payload) => {
        regradedRealtimeAssignment(payload)
      },
      addAssignment: handleAssignmentUpdate,
    },
    { dynamicTopics: true }
  )
  return null
}

const getLastVisitedItemId = (activityData, sectionIndex, resume) => {
  const { questionActivities, itemsToBeExcluded, testActivity } = activityData
  const { itemsToDeliverInGroup } = testActivity
  const excludeItemsById = keyBy(itemsToBeExcluded)
  const currentSectionItems = itemsToDeliverInGroup[sectionIndex].items.filter(
    (item) => !excludeItemsById[item]
  )
  if (!resume) {
    return currentSectionItems[0]
  }
  const currentGroupItemsById = keyBy(currentSectionItems)
  const currentSectionActivities = questionActivities.filter(
    (uqa) => currentGroupItemsById[uqa.testItemId]
  )
  let lastAttemptedItem = currentSectionItems[0]
  if (!currentSectionActivities.length) {
    return lastAttemptedItem
  }
  const activitiesByTestItemId = keyBy(currentSectionActivities, 'testItemId')
  currentSectionItems.forEach((item, index) => {
    const uqa = activitiesByTestItemId[item]
    if (uqa && !uqa.skipped && index < currentSectionItems.length - 1) {
      lastAttemptedItem = currentSectionItems[index + 1]
    }
  })
  return lastAttemptedItem
}

const SummaryContainer = (props) => {
  const {
    history,
    match,
    preventSectionNavigation,
    fetchSectionsData,
    itemsToDeliverInGroup,
    isLoading,
    activityData,
    utaStartTimeUpdate,
    assignmentSettings,
    userId,
    saveBlurTime,
    savedBlurTime: blurTimeAlreadySaved = 0,
    isPasswordValidated,
    setIsSectionsTestPasswordValidated,
    userName,
    startSection,
    regradedAssignment,
    regradedRealtimeAssignment,
    updateSectionStatus,
    testType,
    userAccomodations,
    passwordValidationComplete = false,
  } = props
  const { groupId, utaId, testId, assessmentType } = match.params
  const [showRegradedModal, setShowRegradedModal] = useState(false)
  const [showAssignmentUpdateModal, setAssignmentUpdateModal] = useState(false)
  const {
    restrictNavigationOut,
    restrictNavigationOutAttemptsThreshold,
    blockSaveAndContinue,
    passwordPolicy,
    questionsDelivery,
  } = assignmentSettings
  const {
    testActivity,
    test: { adaptiveDelivery = {}, hasSections } = {},
  } = activityData
  const isSectionAdaptiveTest = !isEmpty(adaptiveDelivery)

  useEffect(() => {
    fetchSectionsData({ utaId, groupId })
    if (!isPasswordValidated && passwordValidationComplete) {
      setIsSectionsTestPasswordValidated(true)
    }
  }, [])

  useEffect(() => {
    if (regradedAssignment && regradedAssignment?.newTestId) {
      setShowRegradedModal(true)
    }
  }, [regradedAssignment?.newTestId])

  const totalItemsCountWithQuestionDelivery = useMemo(() => {
    const totalCount = (itemsToDeliverInGroup || []).reduce(
      (acc, itemGroup) => {
        acc += itemGroup?.items?.length || 0
        return acc
      },
      0
    )
    return totalCount
  }, [itemsToDeliverInGroup])

  const currentlyFullScreen = useFullScreenListener({
    enabled: restrictNavigationOut,
    assignmentId: testActivity?.assignmentId,
    classId: groupId,
    testActivityId: utaId,
    history,
    disableSave: blockSaveAndContinue,
    userId,
  })

  useTabNavigationCounterEffect({
    testActivityId: utaId,
    enabled: restrictNavigationOut && currentlyFullScreen,
    threshold: restrictNavigationOutAttemptsThreshold,
    history,
    assignmentId: testActivity?.assignmentId,
    classId: groupId,
    userId,
    onTimeInBlurChange: (v) => {
      saveBlurTime(v)
    },
    blurTimeAlreadySaved,
  })

  const handleAssignmentUpdate = (updatedAssignemnt) => {
    const classLevelLockSections = updatedAssignemnt?.class?.find(
      (x) => x._id === groupId
    )?.lockSections
    const lockSections =
      typeof classLevelLockSections === 'boolean'
        ? classLevelLockSections
        : updatedAssignemnt?.lockSections
    if (assignmentSettings?.lockSections !== lockSections) {
      setAssignmentUpdateModal(true)
    }
  }

  const showSectionInfoModal = ({ index, resume, title, content }) => {
    return Modal.confirm({
      title: (
        <FlexContainer
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <Tooltip title={title}>
            <StyledTestName data-cy="sectionName">{title}</StyledTestName>
          </Tooltip>
          <Tooltip title={userName}>
            <StyledStudentNameContainer>
              <IconUserRegular height="24px" width="24px" />
              <StyledStudentName data-cy="studentName">
                Hi, {userName}!
              </StyledStudentName>
            </StyledStudentNameContainer>
          </Tooltip>
        </FlexContainer>
      ),
      content,
      onOk: () => {
        const nextItemId = getLastVisitedItemId(activityData, index, resume)
        if (
          (resume || isAnySectionStarted(itemsToDeliverInGroup)) &&
          activityData?.assignmentSettings?.timedAssignment
        ) {
          utaStartTimeUpdate(TIME_UPDATE_TYPE.RESUME)
        }
        history.push({
          pathname: `/student/${assessmentType}/${testId}/class/${groupId}/uta/${utaId}/itemId/${nextItemId}`,
          state: { fromSummary: true },
        })
        Modal.destroyAll()
      },
      onCancel: () => {
        Modal.destroyAll()
      },
      okText: 'YES, CONTINUE',
      cancelText: 'NO, CANCEL',
      className: 'ant-modal-confirm-custom-styled',
      centered: true,
      maskClosable: false,
      icon: '',
    })
  }

  const handleStartSection = (index, resume, section) => () => {
    const { items, settings } = section || {}
    const { test } = activityData
    const hasInstruction = test.hasInstruction
    const instruction = test.instruction
    if (!isSectionAdaptiveTest && hasSections && !resume) {
      startSection({
        testActivityId: utaId,
        sectionId: itemsToDeliverInGroup[index].groupId,
      })
    }

    if (
      !resume &&
      isSectionAdaptiveTest &&
      (hasInstruction || !!settings?.timeLimit)
    ) {
      const groupName = getAdaptiveTestSectionNames({
        groupName: section.groupName,
        index,
      })
      return showSectionInfoModal({
        index,
        resume,
        title: groupName,
        content: (
          <>
            {!!settings?.timeLimit && (
              <FlexContainer
                flexDirection="column"
                data-cy="sectionStartConformationPopupContent"
              >
                <p>You have to complete this section within time limit</p>
                <FlexContainer
                  flexDirection="row"
                  style={{ justifyContent: 'flex-start' }}
                >
                  <span style={{ width: 100 }}>Questions</span>
                  <span style={{ width: 20 }}>:</span>
                  <b data-cy="itemCount">{items?.length}</b>
                </FlexContainer>
                <FlexContainer
                  flexDirection="row"
                  style={{ justifyContent: 'flex-start' }}
                >
                  <span style={{ width: 100 }}>Time</span>
                  <span style={{ width: 20 }}>:</span>
                  <b data-cy="timeLimit">
                    {(settings.timeLimit *
                      (userAccomodations?.extraTimeOnTest > 0
                        ? userAccomodations.extraTimeOnTest
                        : 1)) /
                      60000}{' '}
                    mins
                  </b>
                </FlexContainer>
                <br />
                <p>Do you want to start now?</p>
              </FlexContainer>
            )}
            {hasInstruction && instruction && (
              <p>
                <MathFormulaDisplay
                  dangerouslySetInnerHTML={{ __html: instruction }}
                />
              </p>
            )}
          </>
        ),
      })
    }
    if (
      (resume || isAnySectionStarted(itemsToDeliverInGroup)) &&
      activityData?.assignmentSettings?.timedAssignment
    ) {
      utaStartTimeUpdate(TIME_UPDATE_TYPE.RESUME)
    }
    const nextItemId = getLastVisitedItemId(activityData, index, resume)
    history.push({
      pathname: `/student/${assessmentType}/${testId}/class/${groupId}/uta/${utaId}/itemId/${nextItemId}`,
      state: { fromSummary: true },
    })
  }

  const handleReviewSection = (index) => () => {
    const sectionId = itemsToDeliverInGroup[index].groupId
    history.push({
      pathname: `/student/${assessmentType}/${testId}/class/${groupId}/uta/${utaId}/section/${sectionId}/test-summary`,
      state: { fromSummary: true },
    })
  }
  const onRegradedModalOk = () => {
    window.dispatchEvent(new Event('assignment-regraded'))
    let playerTestType = testType || TEST_TYPES_VALUES_MAP.ASSESSMENT

    if (COMMON.includes(testType)) {
      playerTestType = TEST_TYPES_VALUES_MAP.ASSESSMENT
    }
    if (PRACTICE.includes(testType)) {
      playerTestType = TEST_TYPES_VALUES_MAP.PRACTICE
    }
    window.location.href = `/student/${playerTestType}/${regradedAssignment.newTestId}/class/${groupId}/uta/${utaId}/sections-start`
  }

  const onAssignmentModalOk = () => {
    window.dispatchEvent(new Event('assignment-regraded'))
    window.location.reload()
  }

  const handleRealtimeOpenSection = (payload) => {
    const {
      assignmentId: realtimeAssignmentId,
      sectionOpenedForStudents = {},
    } = payload
    if (
      realtimeAssignmentId === testActivity?.assignmentId &&
      sectionOpenedForStudents[userId]
    ) {
      const openedSectionId = sectionOpenedForStudents[userId]
      updateSectionStatus({
        sectionId: openedSectionId,
        status: SECTION_STATUS.NOT_STARTED,
      })
    }
  }

  const exitSectionsPage = () => {
    setIsSectionsTestPasswordValidated(false)
    history.push('/home/assignments')
  }

  const isRedirectedWithQuestionDelivery = [
    SKIPPED,
    SKIPPED_AND_WRONG,
    SKIPPED_PARTIAL_AND_WRONG,
  ].includes(questionsDelivery)

  if (
    !isEmpty(assignmentSettings) &&
    passwordPolicy !==
      testConstants?.passwordPolicy?.REQUIRED_PASSWORD_POLICY_OFF &&
    !isPasswordValidated &&
    !passwordValidationComplete
  ) {
    return <SectionsTestRequirePassword />
  }

  return (
    <ThemeProvider theme={themes.default}>
      {restrictNavigationOut && (
        <>
          <ForceFullScreenModal
            testActivityId={utaId}
            history={history}
            visible={!currentlyFullScreen}
            finishTest={exitSectionsPage}
          />
        </>
      )}
      {(blockSaveAndContinue || restrictNavigationOut) && (
        <FirestorePings
          testActivityId={utaId}
          history={history}
          blockSaveAndContinue={blockSaveAndContinue}
          userId={userId}
          classId={groupId}
          assignmentId={testActivity?.assignmentId}
        />
      )}
      <SummaryHeader
        showExit={!isLoading}
        hidePause={blockSaveAndContinue}
        onExitClick={exitSectionsPage}
      />
      <MainContainer>
        <ContentArea>
          <EduIf condition={isLoading}>
            <EduThen>
              <Spin />
            </EduThen>
            <EduElse>
              <SectionsInfo
                title={activityData?.test?.title}
                thumbnail={activityData?.test?.thumbnail}
                isRedirectedWithQuestionDelivery={
                  isRedirectedWithQuestionDelivery
                }
                totalItemsCountWithQuestionDelivery={
                  totalItemsCountWithQuestionDelivery
                }
              />
              {showRegradedModal && (
                <RegradeModalForStudent
                  loading={false}
                  onRegradedModalOk={onRegradedModalOk}
                />
              )}
              {showAssignmentUpdateModal && (
                <RegradeModalForStudent
                  loading={false}
                  onRegradedModalOk={onAssignmentModalOk}
                />
              )}
              <RealTimeV2HookWrapper
                userId={userId}
                testId={testId}
                groupId={groupId}
                regradedAssignment={regradedAssignment}
                regradedRealtimeAssignment={regradedRealtimeAssignment}
                handleRealtimeOpenSection={handleRealtimeOpenSection}
                assignmentId={testActivity?.assignmentId}
                handleAssignmentUpdate={handleAssignmentUpdate}
              />
              <TestSectionsContainer
                itemsToDeliverInGroup={itemsToDeliverInGroup}
                preventSectionNavigation={preventSectionNavigation}
                questionsDelivery={questionsDelivery}
                handleStartSection={handleStartSection}
                handleReviewSection={handleReviewSection}
                isRedirectedWithQuestionDelivery={
                  isRedirectedWithQuestionDelivery
                }
                isSectionAdaptiveTest={isSectionAdaptiveTest}
                extraTimeOnTest={
                  userAccomodations?.extraTimeOnTest > 0
                    ? userAccomodations?.extraTimeOnTest
                    : 1
                }
              />
            </EduElse>
          </EduIf>
        </ContentArea>
      </MainContainer>
    </ThemeProvider>
  )
}

const enhance = compose(
  withRouter,
  connect(
    (state) => ({
      isLoading: getIsLoadingSelector(state),
      activityData: getActivityDataSelector(state),
      itemsToDeliverInGroup: getItemstoDeliverWithAttemptCount(state),
      preventSectionNavigation: getPreventSectionNavigationSelector(state),
      assignmentSettings: getAssignmentSettingsSelector(state),
      userId: state.user?.user?._id,
      savedBlurTime: state.test?.savedBlurTime,
      isPasswordValidated: getPasswordValidatedStatusSelector(state),
      regradedAssignment: state?.studentAssignment?.regradedAssignment,
      userName: getUserNameSelector(state),
      testType: state.test?.testType,
      userAccomodations: getUserAccommodations(state),
      passwordValidationComplete:
        state?.router?.location?.state?.passwordValidationComplete,
    }),
    {
      fetchSectionsData: slice.actions.fetchSectionsData,
      setIsSectionsTestPasswordValidated:
        slice.actions.setIsSectionsTestPasswordValidated,
      utaStartTimeUpdate: utaStartTimeUpdateRequired,
      saveBlurTime: saveBlurTimeAction,
      startSection: startSectionAction,
      updateSectionStatus: slice.actions.updateSectionStatus,
      regradedRealtimeAssignment: regradedRealtimeAssignmentAction,
    }
  )
)

export default enhance(SummaryContainer)

SummaryContainer.propTypes = {
  history: PropTypes.func.isRequired,
}
