import React from 'react'
import { IconQuestionCircle } from '@edulastic/icons'
import { lightGreen10 } from '@edulastic/colors'
import EduButton from './EduButton'

export default function HelpButton(props) {
  return (
    <EduButton isGhost height={30} {...props}>
      <IconQuestionCircle width="14" height="14" color={lightGreen10} /> Help
    </EduButton>
  )
}
