import React, { useEffect } from 'react'
import { segmentApi } from '@edulastic/api'
import { roleuser } from '@edulastic/constants'
import { connect } from 'react-redux'
import AssignTest from './AssignTest'
import AssignRecommendations from './AssignRecommendations'
import {
  getUserDistrict,
  getUserId,
  getUserRole,
} from '../../../src/selectors/user'
import { setEasyAssign } from '../../../src/actions/assignments'
import { rememberEasyAssignPreferenceLocalStorageKey } from '../SimpleOptions/constants'

const Container = ({
  isAssignRecommendations,
  role,
  loadAssignmentCount,
  setEasyAssignEnabled,
  currentTermId,
  userId,
  districtId,
  ...rest
}) => {
  useEffect(() => {
    const { state } = rest?.location || {}
    const { testId } = rest?.match?.params || {}
    segmentApi.genericEventTrack('testAssignStart', { ...state, testId })
  }, [rest?.location?.pathname])

  useEffect(() => {
    if (role === roleuser.TEACHER) {
      const rememberPreferenceLocalStorageKey = `${rememberEasyAssignPreferenceLocalStorageKey}-${userId}-${districtId}-${currentTermId}`
      const rememberPreferenceStored = localStorage.getItem(
        rememberPreferenceLocalStorageKey
      )
      if (rememberPreferenceStored && JSON.parse(rememberPreferenceStored)) {
        setEasyAssignEnabled(true)
      } else {
        setEasyAssignEnabled(false)
      }
    } else {
      setEasyAssignEnabled(false)
    }
  }, [role])

  if (isAssignRecommendations) {
    return <AssignRecommendations {...rest} />
  }

  return <AssignTest {...rest} />
}

const enhance = connect(
  (state) => ({
    role: getUserRole(state),
    currentTermId: state.user?.user?.orgData?.defaultTermId,
    userId: getUserId(state),
    districtId: getUserDistrict(state)?.districtId,
  }),
  {
    setEasyAssignEnabled: setEasyAssign,
  }
)

export default enhance(Container)
