/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconSectionsCalculator = (props) => (
  <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 688 548" {...props}>
    <path d="M140.933 37.4667C117.867 42.9334 97.3333 65.3334 93.4667 89.3334C91.4667 101.333 91.4667 581.333 93.4667 593.333C97.3333 617.733 117.867 639.867 141.467 645.2C149.467 647.067 533.2 647.067 541.2 645.2C564.8 639.867 585.333 617.733 589.2 593.333C591.2 581.333 591.2 101.333 589.2 89.3334C585.333 64.9334 564.8 42.8001 541.2 37.4667C533.733 35.7334 148.267 35.7334 140.933 37.4667ZM537.333 81.7334C539.867 83.3334 543.067 86.8001 544.533 89.3334C547.333 94.0001 547.333 94.2667 547.333 341.333C547.333 588.4 547.333 588.667 544.533 593.333C543.067 595.867 539.867 599.333 537.333 600.933L532.933 604H341.333H149.733L145.2 600.933C142.667 599.333 139.333 595.333 137.733 592L134.667 586.133V341.333V96.5334L137.733 90.6667C139.333 87.3334 142.667 83.3334 145.2 81.7334L149.733 78.6667H341.333H532.933L537.333 81.7334Z" />
    <path d="M187.867 124.4C185.333 126 182 130 180.267 133.333L177.2 139.333L177.6 200.667C178 262 178 262 181.067 266.933C182.8 269.733 186.4 272.933 189.867 274.533C195.467 277.333 196.533 277.333 341.333 277.333C486.133 277.333 487.2 277.333 492.8 274.533C496.267 272.933 499.867 269.733 501.6 266.933L504.667 262V199.333C504.667 138.533 504.533 136.533 501.867 132C500.4 129.467 497.2 126 494.667 124.4L490.267 121.333H341.333H192.4L187.867 124.4ZM462.667 199.333V234.667H341.333H220V199.333V164H341.333H462.667V199.333Z" />
    <path d="M219.733 328.267C216.933 328.933 212 332 208.8 334.933C193.733 348 196.933 371.6 214.8 380.533C226.533 386.4 238.267 384.533 247.467 375.333C267.733 355.067 247.467 320.533 219.733 328.267Z" />
    <path d="M334.667 327.867C320.667 332.4 313.333 342 313.333 356C313.333 363.733 316.4 370.8 322.267 376.267C336.133 389.467 358.667 384.933 366.8 367.333C371.333 357.467 369.333 343.867 362.267 336.267C356 329.467 342.4 325.333 334.667 327.867Z" />
    <path d="M448.267 328C440.667 329.733 433.867 335.333 430.133 342.8C424.267 354.667 426 366.133 435.067 375.2C444.4 384.533 456 386.4 467.867 380.533C484.8 372 488.533 351.333 475.733 336.8C471.867 332.267 468.667 330.133 463.6 328.667C456.267 326.533 454.8 326.4 448.267 328Z" />
    <path d="M219.733 413.6C216.933 414.267 212 417.333 208.8 420.267C193.733 433.333 196.933 456.933 214.8 465.867C226.533 471.733 238.267 469.867 247.467 460.667C267.733 440.4 247.467 405.867 219.733 413.6Z" />
    <path d="M334.667 413.2C320.667 417.733 313.333 427.333 313.333 441.333C313.333 465.6 342.667 478.267 360.533 461.6C371.333 451.467 372.267 432.267 362.267 421.6C356 414.8 342.4 410.667 334.667 413.2Z" />
    <path d="M448.533 413.467C442.267 415.2 435.067 424 434 430.933C433.6 434.133 433.333 459.733 433.6 488C434 538.8 434 539.333 437.067 544.267C441.2 550.8 450.267 555.2 457.6 554.267C464 553.467 468.8 550.267 473.067 544C476 539.6 476 539.467 476 483.333C476 427.2 476 427.067 472.933 422.533C469.867 417.733 460 411.867 455.333 412.133C453.867 412.133 450.8 412.8 448.533 413.467Z" />
    <path d="M219.733 498.933C216.933 499.6 212 502.667 208.8 505.6C193.733 518.667 196.933 542.267 214.8 551.2C226.533 557.067 238.267 555.2 247.467 546C267.733 525.733 247.467 491.2 219.733 498.933Z" />
    <path d="M334.667 498.533C320.667 503.067 313.333 512.667 313.333 526.667C313.333 550.933 342.667 563.6 360.533 546.933C371.333 536.8 372.267 517.6 362.267 506.933C356 500.133 342.4 496 334.667 498.533Z" />
  </SVG>
)

export default withIconStyles(IconSectionsCalculator)
