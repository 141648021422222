import React from 'react'
import { FlexContainer } from '@edulastic/common'
import { Divider } from 'antd'
import { connect } from 'react-redux'
import { capitalize } from 'lodash'
import {
  Container,
  MessageText,
  SecondaryText,
  StyledImage,
  TitleText,
} from './styled'
import Card from './Card'
import { CARD_IDS, CARDS } from './constants'
import { getTotalAssignmentCount, isTeacherDashboardLoading } from '../../ducks'
import { changeCurrentQuestionAction } from '../../../sharedDucks/questions'
import { getUserDetails } from '../../../../student/Login/ducks'
import QuickStartGuide from '../QuickStartGuide'
import SpecialistProfileImg from '../../../../student/assets/specialist-profile.png'
import { createTestItemAction } from '../../../src/actions/testItem'
import {
  isGcpsDistrictSelector,
  isPremiumUserSelector,
} from '../../../src/selectors/user'
import CreateGoogleFormQuiz from '../../../AssessmentCreate/components/CreateGoogleFormQuiz'
import EduAIQuiz from '../../../AssessmentCreate/components/CreateAITest'
import { CREATE_AI_TEST_DISPLAY_SCREENS } from '../../../AssessmentCreate/components/CreateAITest/constants'

const TestCreationBanner = (props) => {
  const {
    totalAssignmentCount = 0,
    isLoading,
    user: { firstName } = {},
    setCurrentQuestion,
    createItem,
    isGcpsDistrictExcludingDemoUsers,
    isPremiumsUser,
  } = props

  const cardsToBeShown = CARDS.filter(
    (card) =>
      !(
        (
          (totalAssignmentCount === 0 && card.id === CARD_IDS.AUTHOR_ITEM) ||
          (totalAssignmentCount > 0 && card.id === CARD_IDS.TRY_IT_OUT) ||
          (isGcpsDistrictExcludingDemoUsers &&
            card.id === CARD_IDS.IMPORT_TEST) || // dont show import test for GCPS district excluding demo users
          ((!isPremiumsUser || !isGcpsDistrictExcludingDemoUsers) &&
            card.id === CARD_IDS.GOOGLE_FORM_TEST)
        ) // dont show google form test for non-premium users or non GCPS district excluding demo users
      )
  )

  const handleClick = (title) => {
    if (title === 'Author an Item') {
      createItem({
        rows: [
          {
            tabs: [],
            dimension: '100%',
            widgets: [],
            flowLayout: false,
            content: '',
          },
        ],
      })
      setCurrentQuestion('')
    }
  }

  if (isLoading || totalAssignmentCount >= 2) {
    return null
  }

  return (
    <Container data-joyride-id="userLifecycleBanner">
      <FlexContainer justifyContent="space-between">
        <FlexContainer
          justifyContent="flex-start"
          alignItems="center"
          gap="12px"
        >
          <StyledImage
            data-cy="specialist-image"
            src={SpecialistProfileImg}
            alt="Customer Success Specialist Profile"
          />
          <div>
            <MessageText data-cy="onboarding-message">
              Hey {capitalize(firstName) || ''}! I am here to assist you in
              onboarding our platform
            </MessageText>
            <SecondaryText data-cy="customer-success-specialist-signature">
              - John, Customer Success Specialist
            </SecondaryText>
          </div>
        </FlexContainer>
        <QuickStartGuide />
      </FlexContainer>
      <Divider style={{ background: '#FFFFFF33' }} />
      <div>
        <TitleText>Get Started with Test Creation</TitleText>
        <FlexContainer justifyContent="flex-start" gap="40px">
          {cardsToBeShown.map((card) => {
            if (card.id === CARD_IDS.GOOGLE_FORM_TEST) {
              return (
                <CreateGoogleFormQuiz>
                  <Card key={card.title} card={card} />
                </CreateGoogleFormQuiz>
              )
            }
            if (card.id === CARD_IDS.USE_AI_TEST_CREATE) {
              return (
                <EduAIQuiz
                  childrenStyle={{ width: '100%' }}
                  displayScreen={
                    CREATE_AI_TEST_DISPLAY_SCREENS.DASHBOARD_TEST_BANNER
                  }
                >
                  <Card key={card.title} card={card} />
                </EduAIQuiz>
              )
            }
            return (
              <Card
                key={card.title}
                card={card}
                onClick={() => handleClick(card.title)}
              />
            )
          })}
        </FlexContainer>
      </div>
    </Container>
  )
}

export default connect(
  (state) => ({
    user: getUserDetails(state),
    totalAssignmentCount: getTotalAssignmentCount(state),
    isLoading: isTeacherDashboardLoading(state),
    isGcpsDistrictExcludingDemoUsers: isGcpsDistrictSelector(state, true),
    isPremiumsUser: isPremiumUserSelector(state),
  }),
  {
    setCurrentQuestion: changeCurrentQuestionAction,
    createItem: createTestItemAction,
  }
)(TestCreationBanner)
