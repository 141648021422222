import styled, { css } from 'styled-components'
import { white, lightGrey, darkGrey1, lightGreen10 } from '@edulastic/colors'

export const Wrapper = styled.div`
  background-color: ${lightGrey};
  padding: 24px;
  border-radius: 5px;
  margin: 20px 0 52px 0;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
`
export const IconWrap = styled.div`
  border-radius: 8px;
  background: ${white};
  width: 36px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e3e3e3;
  margin-right: 10px;
  box-shadow: 0px -1px 0px 0px ${darkGrey1} inset;
  svg {
    fill: #555;
    &:hover {
      fill: #555;
    }
  }
`

export const Heading = styled.h3`
  font-weight: 600;
`

export const Header = styled.header`
  display: flex;
  margin-bottom: 10px;
  position: relative;
  p {
    font-size: 12px;
    color: #777;
  }
`
export const WidgetContainer = styled.div`
  display: flex;
  flex-flow: wrap;
`

export const SeeAll = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 65px;
  position: absolute;
  cursor: pointer;
  color: ${lightGreen10};
  font-size: 14px;
  font-weight: 600;
  right: 0;
  top: 0;
`
const widgetstyles = css`
  display: inline-flex;
  align-items: center;
  background: #fff;
  border: 1px solid #eeeeee;
  cursor: pointer;
  height: 48px;
  border-radius: 4px;
  margin-top: 10px;
  svg {
    margin-right: 10px;
  }
`

const googleFormWidgetStyles = css`
  display: inline-flex;
`

export const WidgetWrap = styled.div`
  margin-right: 20px;
  padding: ${(props) => (props.innerStyle ? 0 : '10px 20px')};
  ${(props) => (props.innerStyle ? googleFormWidgetStyles : widgetstyles)}
  ${(props) =>
    props.innerStyle &&
    `span {
    padding: 10px 20px;
    ${widgetstyles}
  }`}
  &:last-child {
    margin-right: 0px;
  }
  p {
    color: #2f4151;
  }
  &:hover {
    border: 1px solid ${lightGreen10}};
  }
`
