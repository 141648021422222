import React from 'react'
import { IconPencilEdit } from '@edulastic/icons'
import { greenDarkSecondary } from '@edulastic/colors'
import { Tooltip } from 'antd'
import { tutorials } from '@edulastic/constants'
import { EduJoyride } from '@edulastic/common'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { ACTIONS, LIFECYCLE } from 'react-joyride'
import {
  StyledPencilIconContainer,
  StyledPopoverContainer,
  StyledPopoverContentContainer,
} from './styled'
import SelectQTypeOption from './SelectQTypeOption'
import {
  setViewedTutorialsAction,
  viewedTutorialsSelector,
} from '../../../../tutorials/tutorialReducer'
import { ITEM_DETAILS_CHANGE_QUESTION_TYPE_TUTORIAL_STEPS } from '../../../src/constants/others'

const ChangeQType = ({
  children,
  onQuestionTypeSelect,
  handlePopupVisibilityChange,
  isQuestionTypeChangePopupVisible,
  viewedTutorials,
  setViewedTutorials,
}) => {
  const changeQTypeTutorialCallback = (data) => {
    const { COMPLETE } = LIFECYCLE
    const { CLOSE, NEXT } = ACTIONS
    if (
      data.lifecycle === COMPLETE &&
      (data.action === CLOSE || data.action === NEXT)
    ) {
      setViewedTutorials([
        ...viewedTutorials,
        tutorials.itemDetailsChangeQuestionType,
      ])
    }
  }

  return (
    <span data-joyride-id="changeQuestionType">
      <Tooltip title="Click to change question type" placement="right">
        <StyledPopoverContainer
          placement="bottomLeft"
          trigger="click"
          visible={isQuestionTypeChangePopupVisible}
          onVisibleChange={(value) => {
            handlePopupVisibilityChange(value)
          }}
          content={
            <StyledPopoverContentContainer>
              <SelectQTypeOption onQuestionTypeSelect={onQuestionTypeSelect} />
            </StyledPopoverContentContainer>
          }
        >
          {children}
          <StyledPencilIconContainer>
            <IconPencilEdit color={greenDarkSecondary} />
          </StyledPencilIconContainer>
        </StyledPopoverContainer>
      </Tooltip>
      {!viewedTutorials.includes(tutorials.itemDetailsChangeQuestionType) && (
        <EduJoyride
          steps={ITEM_DETAILS_CHANGE_QUESTION_TYPE_TUTORIAL_STEPS}
          callback={changeQTypeTutorialCallback}
          styles={{
            tooltipContent: {
              paddingTop: '20px',
            },
          }}
          locale={{
            close: 'Done',
          }}
        />
      )}
    </span>
  )
}

const enhance = compose(
  connect(
    (state) => ({
      viewedTutorials: viewedTutorialsSelector(state),
    }),
    {
      setViewedTutorials: setViewedTutorialsAction,
    }
  )
)

export default enhance(ChangeQType)
