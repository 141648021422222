import { stackedTranslationEnabledValues } from './constants'

/**
 * Sorts the keys array based on the order specified in the sortKeyOrder array.
 *
 * @param {Array<string>} sortKeyOrder - An array of keys specifying the desired order.
 * @param {Array<string>} keys - An array of keys to be sorted.
 * @returns {Array<string>} - A new array of keys sorted according to the sortKeyOrder.
 */
const sortKeys = (sortKeyOrder = [], keys = []) => {
  return sortKeyOrder
    .map((sortKey) => keys.find((key) => sortKey === key))
    .filter((x) => x)
}

const isStackedTranslationEnabled = (stackedTranslation, isPreview) => {
  return isPreview
    ? [...stackedTranslationEnabledValues, undefined].includes(
        stackedTranslation
      )
    : stackedTranslationEnabledValues.includes(stackedTranslation)
}

export { sortKeys, isStackedTranslationEnabled }
