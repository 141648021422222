import React, { useState } from 'react'
import { Popover, Tooltip, Typography } from 'antd'
import {
  Content,
  Header,
  StyledPreviewImage,
} from '../../../../../../PickUpQuestionType/components'
import {
  ImageContainer,
  ImageLabelContainer,
  MainContainer,
  StyledPopoverContent,
  StyledRoundDiv,
} from './styled'

const QuickItemLinkCard = ({
  key,
  icon,
  label,
  hoverImage,
  disabled,
  onClick,
  dataCy,
  disabledTitle,
}) => {
  const [popoverVisible, setPopoverVisible] = useState(false)

  const iconContainer = (
    <Tooltip title={disabled && (disabledTitle || 'Edit test to add new item')}>
      <ImageLabelContainer disabled={disabled} popoverVisible={popoverVisible}>
        <ImageContainer>{icon}</ImageContainer>
        <Typography style={{ textAlign: 'center' }}>{label}</Typography>
      </ImageLabelContainer>
    </Tooltip>
  )

  return (
    <MainContainer
      onClick={!disabled && onClick}
      disabled={disabled}
      data-cy={dataCy || key}
    >
      {hoverImage ? (
        <Popover
          content={
            disabled ? null : (
              <StyledPopoverContent data-cy={`hover-image-${dataCy}`}>
                <StyledRoundDiv>
                  <Header className="card-title">{label}</Header>
                  <Content>
                    <StyledPreviewImage src={hoverImage} />
                  </Content>
                </StyledRoundDiv>
              </StyledPopoverContent>
            )
          }
          trigger="hover"
          key={key}
          onVisibleChange={(visible) => setPopoverVisible(visible)}
          placement="top"
        >
          {iconContainer}
        </Popover>
      ) : (
        iconContainer
      )}
    </MainContainer>
  )
}

export default QuickItemLinkCard
