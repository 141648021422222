import styled, { css } from 'styled-components'

import { EduButton } from '@edulastic/common'
import { IconClose } from '@edulastic/icons'

export const Container = styled.div`
  display: flex;
  background: ${(props) => `url(${props.$imageUrl})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  padding: 16px 24px;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 24px;
  min-height: 126px;
  border-radius: 8px;
`

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  min-width: 50%;
  max-width: 50%;
  overflow-wrap: break-word;
  word-break: normal;
  white-space: normal;
`

export const RightContainer = styled.div`
  position: relative;
  min-width: 50%;
  max-width: 50%;
`

export const BannerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  & > *:first-child {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
  }
  & > *:nth-child(2) {
    opacity: 70%;
    font-size: 12px;
    font-weight: 600;
    color: #ffffff;
  }
`

const buttonTextColor = css`
  color: ${(props) => props.$buttonTextColor || '#764dbe'};
`

export const BannerActionButton = styled(EduButton)`
  &.ant-btn.ant-btn-primary {
    ${buttonTextColor}
    border: none;
    span {
      margin: auto;
    }
  }
  &:hover {
    &.ant-btn.ant-btn-primary {
      background-color: #ffffff;
      ${buttonTextColor}
    }
  }
  &:focus {
    &.ant-btn.ant-btn-primary {
      ${buttonTextColor}
    }
  }
`

export const CloseBannerIcon = styled(IconClose)`
  position: absolute;
  right: 0px;
  top: 0px;
  &:hover {
    cursor: pointer;
  }
`
