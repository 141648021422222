/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconScience = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="28.222"
    height="53.25"
    viewBox="0 0 28.222 53.25"
    {...props}
  >
    <g transform="translate(-120.262 -0.002)">
      <path
        d="M145.289,51.654a1.6,1.6,0,0,0,3.195,0c0-10.915-4.4-17.391-9.585-22.9-.358.36-.711.711-1.065,1.065-.382.382-.69.688-1.065,1.065.521.555,1.1,1.558,1.6,2.13h-7.455c1.507-1.734,3.131-3.663,4.792-5.325,6.386-6.386,12.78-12.914,12.78-26.092a1.6,1.6,0,1,0-3.195,0c0,.537.023,1.085,0,1.6H123.457c-.023-.513,0-1.061,0-1.6a1.6,1.6,0,1,0-3.195,0c0,10.915,4.4,17.391,9.585,22.9.358-.36.711-.711,1.065-1.065.382-.382,1.223-.688,1.6-1.065-.5-.537-1.116-1.045-1.6-1.6h7.455c-1.493,1.714-3.148,3.148-4.792,4.792-6.386,6.386-13.312,12.914-13.312,26.092a1.6,1.6,0,0,0,3.195,0c0-.518-.022-1.1,0-1.6h21.832C145.311,50.552,145.289,51.137,145.289,51.654ZM140.5,35.679a30.52,30.52,0,0,1,2.663,4.26h-17.04a28.048,28.048,0,0,1,2.13-4.26Zm4.792-29.287a23.024,23.024,0,0,1-1.065,3.727h-19.7a23.7,23.7,0,0,1-.533-3.727Zm-17.04,11.182a28.071,28.071,0,0,1-2.13-4.26h17.04a30.536,30.536,0,0,1-2.663,4.26Zm-4.26,29.287a23.739,23.739,0,0,1,.533-3.727h19.7a23.051,23.051,0,0,1,1.065,3.727Z"
        fill="#b0bac1"
      />
    </g>
  </SVG>
)

export default withIconStyles(IconScience)
