// create after footer button component
import React from 'react'
import { EduButton, FlexContainer } from '@edulastic/common'
import { IconAI, IconArrowRight } from '@edulastic/icons'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { lightGreen10 } from '@edulastic/colors'
import { StyledCreateAITestText, StyledUploadNowButton } from './styled'
import EduAIQuiz from '../../CreateAITest'
import { isVideoQuizAndAIEnabledSelector } from '../../../../src/selectors/user'
import AddOnTag from '../../common/AddOnTag'

const AfterFooterButton = ({
  isDocUploadInProgress,
  uploadedDocCdnUrl,
  createTestFromDoc,
  isVideoQuizAndAIEnabled,
  t: i18translate,
}) => {
  const showTestCreationButton = !!uploadedDocCdnUrl?.length

  return (
    <>
      {!isDocUploadInProgress && (
        <FlexContainer mt="20px" justifyContent="flex-start" gap="10px">
          <IconAI />
          <StyledCreateAITestText>
            Don&apos;t have questions ready? Use our AI to generate questions
            from your notes/documents
          </StyledCreateAITestText>
          <EduAIQuiz displayScreen="dashboard">
            <FlexContainer
              alignItems="center"
              cursor="pointer"
              data-cy="uploadNowFromAi"
            >
              {!isVideoQuizAndAIEnabled && (
                <AddOnTag message={i18translate('aiSuite.addOnText')} />
              )}
              <StyledUploadNowButton>Upload Now</StyledUploadNowButton>
              <IconArrowRight color={lightGreen10} />
            </FlexContainer>
          </EduAIQuiz>
        </FlexContainer>
      )}
      {showTestCreationButton && (
        <FlexContainer mt="20px" alignItems="center">
          <EduButton
            data-cy="createTest"
            width="141px"
            height="36px"
            onClick={createTestFromDoc}
          >
            Create Test
          </EduButton>
        </FlexContainer>
      )}
    </>
  )
}

const enhance = compose(
  withNamespaces('author'),
  connect(
    (state) => ({
      isVideoQuizAndAIEnabled: isVideoQuizAndAIEnabledSelector(state),
    }),
    {}
  )
)

export default enhance(AfterFooterButton)
