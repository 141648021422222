import React from 'react'
import withIconStyles from '@edulastic/icons/src/HOC/withIconStyles'
import SVG from '@edulastic/icons/src/common/SVG'

const IconGraphRay = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="34.619"
    height="15.66"
    viewBox="0 0 34.619 15.66"
    {...props}
  >
    <g transform="translate(-252.267 -306.638)">
      <path d="M256.486,319.688a1,1,0,0,1-.258-1.966l26.53-7.109a1,1,0,0,1,.517,1.932l-26.529,7.108A.975.975,0,0,1,256.486,319.688Z" />
      <path d="M257.921,315.264a3.769,3.769,0,1,1-5.149,1.379,3.769,3.769,0,0,1,5.149-1.379Z" />
      <path d="M271.921,311.484a3.769,3.769,0,1,1-5.149,1.379,3.769,3.769,0,0,1,5.149-1.379Z" />
      <path d="M286.886,310.465l-3.828,6.63-1.562-5.643-1.24-4.814Z" />
    </g>
  </SVG>
)

export default withIconStyles(IconGraphRay)
