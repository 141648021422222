import styled from 'styled-components'
import { Row, Radio, Button, Collapse, Input, Typography, Select } from 'antd'
import {
  boxShadowDefault,
  white,
  themeColor,
  linkColor1,
  borderGrey,
  lightGreySecondary,
  themeColorTagsBg,
  greyThemeDark4,
  lightGrey,
  mainBgColor,
  greyThemeLight,
} from '@edulastic/colors'
import {
  IconInfo,
  IconNewFeatureIndicator,
  IconItemCross,
} from '@edulastic/icons'
import { NumberInputStyled } from '@edulastic/common'

export const Container = styled.div``

export const BreadcrumbContainer = styled(Row)`
  margin-left: 40px;
  margin-top: 15px;
`

export const StyledSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 35px;
  border-radius: 6px;
  border: 1px solid ${({ color }) => color || themeColor};
  span {
    color: ${greyThemeLight};
  }
  .ant-select-selection-selected-value {
    color: ${({ color }) => color || themeColor};
  }
`

export const CreateGroupWrapper = styled.div`
  width: 80%;
  background: ${white};
  margin: auto;
  padding: 40px 80px;
  margin-top: 50px;
`

export const Heading = styled.div`
  font-size: ${(props) => props.theme.keyboardFontSize};
  font-weight: ${(props) => props.theme.semiBold};
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 30px;
  i {
    color: ${themeColor};
    font-size: 18px;
    line-height: 16px;
  }
`

export const ContentBody = styled.div``

export const GroupField = styled.div`
  margin-bottom: ${({ marginBottom }) => marginBottom || '30px'};
  input {
    height: 40px;
  }
  .ant-checkbox-wrapper {
    font-size: ${(props) => props.theme.smallFontSize};
    text-transform: uppercase;
    font-weight: ${(props) => props.theme.semiBold};
    .ant-checkbox {
      margin-right: 10px;
    }
  }
`

export const Label = styled.label`
  display: block;
  margin-bottom: ${({ fontWeight }) => (fontWeight ? '0px' : '5px')};
  font-size: ${({ fontWeight, theme }) =>
    fontWeight ? theme.standardFont : theme.smallFontSize};
  text-transform: uppercase;
  font-weight: ${({ fontWeight }) => fontWeight || '600'};
  cursor: ${({ fontWeight }) => (fontWeight ? 'pointer' : 'default')};
`
export const SectionNameInput = styled(Input)`
  max-width: 400px;
  margin: 10px;
`

export const RadioGroup = styled(Radio.Group)`
  display: block;
  .ant-radio-wrapper {
    display: inline-flex;
    align-items: center;
    .ant-radio {
      margin-right: 10px;
    }
    > span:last-child {
      font-weight: ${(props) => props.theme.semiBold};
      text-transform: uppercase;
      ${(props) => props.theme.smallFontSize};
    }
  }
`

export const ItemCountWrapper = styled.div`
  display: inline;
  > input {
    width: 70px;
    margin: 0px 5px;
    height: 30px;
  }
`

export const AddGroupButton = styled.span`
  text-transform: uppercase;
  color: ${white};
  background: ${themeColor};
  display: inline-block;
  padding: 10px 30px;
  font-size: ${(props) => props.theme.commentFontSize};
  border-radius: 4px;
  cursor: pointer;
  box-shadow: ${boxShadowDefault};
  transition: all 0.3s ease-in-out;
  border: 1px solid ${themeColor};
  margin-top: 10px;
  &:hover {
    color: ${themeColor};
    background: ${white};
  }
`

export const RadioMessage = styled.div`
  margin: 5px 0px ${(props) => props.marginBottom || '0px'}
    ${(props) => props.marginLeft || '0px'};
  font-size: ${(props) => props.theme.smallFontSize};
  color: ${linkColor1};
`

export const SelectItemsButton = styled(AddGroupButton)`
  color: ${themeColor};
  background: ${white};
  height: 40px;
  width: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: unset;
  margin-top: 0px;
  &:hover {
    color: ${white};
    background: ${themeColor};
  }
`

export const BrowseButton = styled(SelectItemsButton)`
  box-shadow: unset;
  display: flex;
  width: auto;
  border-radius: 2px;
`

export const QuestionTagsWrapper = styled.div`
  display: flex;
  gap: ${(props) => props?.gap || 'unset'};
`

export const QuestionTagsContainer = styled.div`
  width: ${(props) => props?.width || '510px'};
  margin-right: 30px;
  display: inline-block;
  min-height: 40px;
  border: 1px solid ${borderGrey};
  border-radius: 2px;
  padding: 3px 8px 8px 3px;
`

export const SelectWrapper = styled.div`
  width: ${({ width }) => width};
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  .ant-select {
    width: 100%;
    min-height: 40px;
    .ant-select-selection {
      background: ${lightGreySecondary};
      border-radius: 2px;
      .ant-select-selection__rendered {
        line-height: 38px;
      }
    }
  }
  input {
    height: 40px;
    background: ${lightGreySecondary};
    border-radius: 2px;
  }
`

export const AutoSelectFields = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
`

export const DoneButton = styled(AddGroupButton)`
  margin: auto;
`

export const ItemTag = styled.span`
  background: ${themeColorTagsBg};
  color: ${themeColor};
  border-radius: 4px;
  padding: 1px 6px;
  font-size: ${(props) => props.theme.smallFontSize};
  display: inline-block;
  margin: 5px 0px 0px 5px;
  font-weight: ${(props) => props.theme.semiBold};
  cursor: ${(props) => props?.cursor || 'unset'};
`

export const StandardNameSection = styled.div`
  height: 40px;
  border: 1px solid ${themeColor};
  border-radius: 2px;
  padding: 8px;
  color: ${themeColor};
  background: ${themeColorTagsBg};
  > span:first-child {
    display: inline-block;
    width: 90%;
    text-align: center;
  }
  > span:last-child {
    cursor: pointer;
  }
`

export const PanelStyled = styled(Collapse.Panel)`
  & .ant-collapse-header[role='button'] {
    padding: ${(props) => props?.padding || '0 16px 0 40px'};
    .ant-collapse-arrow {
      svg {
        fill: ${themeColor};
      }
    }
  }
`

export const PanelHeading = styled.div`
  display: flex;
  justify-content: space-between;
  > div:last-child {
    display: flex;
    align-items: center;
    > div {
      margin-left: 10px;
      padding: 8px;
      font-size: 18px;
      color: ${themeColor};
      svg {
        fill: ${themeColor};
      }
    }
  }
  & > label {
    padding: 12px 0;
  }
`

export const SaveButton = styled(Button)`
  width: 130px;
  height: 35px;
  text-transform: uppercase;
  font-size: 12px;
  color: ${white};
  background: ${themeColor};
  margin-right: 10px;
  &:hover,
  &:focus {
    color: ${themeColor};
  }
`
export const SectionsTestCreateGroupWrapper = styled.div`
  width: 80%;
  background: ${white};
  margin: auto;
  padding: ${(props) => props?.padding || '40px 80px'};
`
export const StyledAddSectionsButton = styled.button`
  padding-block: 12px;
  border-radius: 7px;
  border-color: transparent;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  font-size: 12px;
`

export const StyledInfoMessage = styled(Typography.Paragraph)`
  padding-top: 12px;
`
export const StyledConvertToAdaptiveTest = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  span {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    margin-right: 5px;
    color: #000000;
  }
`

export const StyledHorizontalLine = styled.hr`
  border: 1px solid #d8d8d8;
  margin-top: ${(props) => props.mt || 'unset'};
`

export const StyledIconInfo = styled(IconInfo)`
  margin-right: 15px;
  cursor: pointer;
`

export const SectionsAdaptiveTestContainer = styled.div``

export const SectionsAdaptiveTestSettingsText = styled.span`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  margin-right: ${(props) => props.mr || 'unset'};
  color: ${greyThemeDark4};
`

export const SectionsAdaptiveTestTextContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.mb || 'unset'};
  margin-top: ${(props) => props.mt || 'unset'};
`

export const StyledVerticalArrowContainer = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
  margin-bottom: 3px;
`

export const StyledSectionsAdaptiveTestSettingsContainer = styled.div`
  display: flex;
  border-radius: 4px;
  opacity: 0px;
  min-height: 90px;
  background: ${lightGrey};
  border: 1px dashed #d8d8d8;
  padding: 0px 5px;
  gap: 25px;
`

export const StyledCollapse = styled(Collapse)`
  margin-top: ${(props) => props?.marginTop || '20px'};
`

export const StyledInputContainer = styled.div`
  display: flex;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  background: ${mainBgColor};
  height: 30px;
`

export const StyledMinutesLabelContainer = styled.div`
  border-left: 1px solid #d8d8d8;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 5px;
  color: #555555;
  font-family: Open Sans;
`

export const SectionAdaptiveTestPanelHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const StyledSettingsInputContainer = styled.div`
  display: flex;
  align-items: ${(props) => props.alignItems || 'center'};
  justify-content: center;
  flex-direction: ${(props) => props.flexDirection || 'initial'};
  gap: ${(props) => props.gap || '10px'};
`

export const StyledThresholdText = styled.span`
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: #777777;
`

export const StyledSelect = styled(Select)`
  border-left: ${({ borderLess }) => (borderLess ? 0 : '1px solid #d8d8d8')};
  width: ${({ width }) => width || 110}px;
  .ant-select-selection {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    color: #555555;
    border: unset;
    background: unset !important;
    &:focus,
    &:hover {
      box-shadow: unset !important;
    }
    .ant-select-arrow {
      margin-top: -4px;
    }
  }
`

export const SectionsContainer = styled.div`
  margin-top: 20px;
`

export const StyledNextSectionHighlighterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 7px;
  padding: 5px 10px;
  width: auto;
  height: 28px;
  border-radius: 4px;
  background: ${greyThemeDark4};
  span {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: ${mainBgColor};
  }
`

export const StyledDotDivider = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #999999;
`

export const StyledWarningText = styled.div`
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: #e43334;
`

export const StyledNewTestIndicator = styled(IconNewFeatureIndicator)`
  margin-bottom: -15px;
  margin-left: -32px;
`

export const StyledIconItemCross = styled(IconItemCross)`
  margin-left: 5px;
  cursor: pointer;
`

export const StyledSectionSettingsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`
export const StyledSectionSettingLabel = styled.span`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #000000;
`
export const StyledSectionLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
export const StyledEditIconContainer = styled.div`
  font-size: 18px;
  pointer-events: ${(props) => (props?.disabled ? 'none' : 'unset')};
  color: ${themeColor};
  svg {
    fill: ${themeColor};
  }
  &:hover {
    svg {
      fill: ${themeColor};
    }
  }
`
export const StyledNumberInput = styled(NumberInputStyled)`
  &.ant-input-number {
    &:focus,
    &:hover,
    &:active {
      border: none !important;
      background-color: none !important;
      box-shadow: none !important;
    }
    .ant-input-number-input {
      font-family: Open Sans;
      font-size: 12px;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
      color: #000000;
      padding: 0px;
    }
  }
`
export const StyledAdaptiveTestHelpButtonContainer = styled.div`
  border-left: 1px solid #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
`
export const StyledAdaptiveTestHelpButton = styled.div`
  width: 80px;
  height: 30px;
  border-radius: 4px;
  border: 1px;
  border: 1px solid ${themeColor};
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 12.26px;
  text-align: left;
  color: ${themeColor};
  margin-left: 15px;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
  cursor: pointer;
`
export const StyledCreateRuleContainer = styled.div`
  padding: 0px 30px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
`
export const StyledCreateRuleLeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`
export const StyledCreateRuleText = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #000000;
`
