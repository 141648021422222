import styled from 'styled-components'
import {
  SelectInputStyled,
  CustomModalStyled,
  EduButton,
} from '@edulastic/common'
import { themeColor, fieldRequiredColor, black } from '@edulastic/colors'
import { IconInfoCircle } from '@edulastic/icons'

export const StyledDocUploadContainer = styled.div`
  padding: 30px;
`
export const StyledFooter = styled.div`
  border-radius: 0 0 8px 8px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 20px;
  border-bottom: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  p {
    margin-left: 5px;
    font-size: 14px;
    font-weight: 600;
    color: #2f4151;
  }
`
export const StyledCreateAITestText = styled.span`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #000000;
`
export const StyledUploadNowButton = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #1ab395;
  margin-right: 10px;
`
export const StyledDataInputWrapper = styled.div`
  margin-top: 40px;
`

export const StyledFieldLabel = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #000000;
  white-space: nowrap;
  padding-bottom: 8px;
`
export const StyledSelectInput = styled(SelectInputStyled)`
  width: ${(props) => props?.width || '100%'} !important;
  &.ant-select {
    .ant-select-open,
    .ant-select-enabled {
      background: #ffffff !important;
    }
    .ant-select-selection,
    ant-select-selection--single {
      border: 1px solid #d8d8d8;
      background: #ffffff;
      .ant-select-selection__rendered {
        padding: 0px 25px 0px 4px !important;
        .ant-select-search--inline {
          margin-left: -2px !important;
        }
        .ant-select-selection__placeholder {
          font-family: Open Sans;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: #777777;
          margin-left: 7px !important;
        }
      }
    }
    .ant-select-arrow-icon {
      svg {
        width: 11px;
        height: 11px;
      }
    }
  }
`
export const StyledOptionalText = styled.span`
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #555555;
`
export const StyledSummaryModal = styled(CustomModalStyled)`
  .ant-modal-content {
    padding: 24px 0px;
    .ant-modal-body {
      padding: 0px !important;
    }
    .ant-modal-close {
      display: none;
    }
  }
`
export const StyledSummaryHeading = styled.h3`
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #000000;
  padding: 0px 24px;
`
export const StyledSummaryList = styled.ul`
  font-weight: 600;
  padding: 0px 24px 1px 38px;

  li {
    margin: 20px 8px;
  }
`
export const StyledSummaryListItem = styled.li`
  color: ${({ color }) => color || '#3D3D3D'};
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  &::marker {
    color: ${({ color }) => color || '#3D3D3D'};
  }
`
export const StyledSummaryDiscardButton = styled(EduButton)`
  border: 1px solid #bbbbbb !important;
  background-color: #ffffff !important;
  width: 155px;
  height: 36px;
  &.ant-btn.ant-btn-primary:focus {
    outline: 0 !important;
    box-shadow: 0 0 0 1px #bbbbbb !important;
  }
  span {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #555555;
  }
`

export const StyledSummaryReviewButton = styled(EduButton)`
  span {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #ffffff;
  }
`

export const StyledBrowseIconWrapper = styled.div`
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 1;
  color: ${themeColor};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

export const StyledRequired = styled.span`
  color: ${fieldRequiredColor};
  margin-left: 3px;
  font-size: 14px;
`

export const StyledFooterInfoBanner = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${black};
  padding: 0px 24px 16px 24px;
`

export const StyledIconInfoCircle = styled(IconInfoCircle)`
  position: relative;
  top: 4px;
`
