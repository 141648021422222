import React from 'react'
import withIconStyles from '@edulastic/icons/src/HOC/withIconStyles'
import SVG from '@edulastic/icons/src/common/SVG'

const IconGraphExponent = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="40.501"
    height="23.94"
    viewBox="0 0 51.23 23.94"
    {...props}
  >
    <g transform="translate(-3.362 4.581)" opacity="0.9">
      <path
        d="M0,28.427a1,1,0,0,1-.7-.287,1,1,0,0,1-.011-1.414L26.287-.7A1,1,0,0,1,27.7-.713,1,1,0,0,1,27.713.7l-27,27.427A1,1,0,0,1,0,28.427Z"
        transform="translate(24.169 0.61) rotate(45)"
        opacity="0.5"
      />
      <path
        d="M0,16.11a1,1,0,0,1-.707-.293,1,1,0,0,1,0-1.414L14.4-.707a1,1,0,0,1,1.414,0,1,1,0,0,1,0,1.414L.707,15.817A1,1,0,0,1,0,16.11Z"
        transform="translate(13.334 9.018) rotate(-45)"
        opacity="0.5"
      />
      <path
        d="M5962.941-621.759a1,1,0,0,1-1-.963,1,1,0,0,1,.962-1.037c17.757-.664,26.581-6.321,30.857-10.95,4.543-4.918,5.009-9.65,5.014-9.7a1,1,0,0,1,1.084-.908,1,1,0,0,1,.908,1.084c-.02.221-.535,5.464-5.537,10.879a31.475,31.475,0,0,1-11.374,7.6,61.583,61.583,0,0,1-20.877,3.995Z"
        transform="translate(-5958.18 643.033)"
      />
      <path
        d="M3,0A3,3,0,1,1,0,3,3,3,0,0,1,3,0Z"
        transform="translate(40.493 -4.581) rotate(30)"
      />
      <path
        d="M3,0A3,3,0,1,1,0,3,3,3,0,0,1,3,0Z"
        transform="translate(10.395 16.176) rotate(30)"
      />
      <path
        d="M1.277,0H-.232V-4.131l.015-.679.024-.742q-.376.376-.522.493l-.82.659-.728-.908,2.3-1.831h1.24Z"
        transform="translate(11.145 15.762)"
      />
      <path
        d="M2.537,0h-4.99V-1.05L-.662-2.861q.8-.815,1.04-1.13A2.486,2.486,0,0,0,.73-4.575a1.486,1.486,0,0,0,.107-.557A.811.811,0,0,0,.6-5.771a.918.918,0,0,0-.632-.21,1.818,1.818,0,0,0-.806.19,4.158,4.158,0,0,0-.815.542l-.82-.972A5.352,5.352,0,0,1-1.6-6.855a3.208,3.208,0,0,1,.757-.286,3.878,3.878,0,0,1,.918-.1A2.718,2.718,0,0,1,1.257-7a1.885,1.885,0,0,1,.8.684,1.816,1.816,0,0,1,.283,1.006,2.462,2.462,0,0,1-.173.925,3.553,3.553,0,0,1-.537.886A13.131,13.131,0,0,1,.344-2.2l-.918.864v.068h3.11Z"
        transform="translate(50.176 3.138)"
      />
    </g>
  </SVG>
)

export default withIconStyles(IconGraphExponent)
