import styled from 'styled-components'

const TitleWrapper = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin: 20px 0 15px;
  text-align: center;
`

export default TitleWrapper
