/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconCleverSquare = (props) => (
  <SVG
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0 0H20V20H0V0Z" fill="#0066FF" />
    <path
      d="M10.8756 16.4356C9.91556 16.4356 9.02074 16.2815 8.19111 15.9733C7.37333 15.6533 6.66222 15.203 6.05778 14.6222C5.45333 14.0415 4.97926 13.36 4.63556 12.5778C4.3037 11.7956 4.13778 10.9363 4.13778 10C4.13778 9.0637 4.3037 8.20444 4.63556 7.42222C4.97926 6.64 5.45333 5.95852 6.05778 5.37778C6.67407 4.79704 7.39111 4.35259 8.20889 4.04444C9.02667 3.72444 9.92148 3.56444 10.8933 3.56444C11.9719 3.56444 12.9437 3.75407 13.8089 4.13333C14.6859 4.50074 15.4207 5.04593 16.0133 5.76889L14.1644 7.47556C13.7378 6.98963 13.2637 6.62815 12.7422 6.39111C12.2207 6.14222 11.6519 6.01778 11.0356 6.01778C10.4548 6.01778 9.92148 6.11259 9.43556 6.30222C8.94963 6.49185 8.52889 6.76444 8.17333 7.12C7.81778 7.47555 7.53926 7.8963 7.33778 8.38222C7.14815 8.86815 7.05333 9.40741 7.05333 10C7.05333 10.5926 7.14815 11.1319 7.33778 11.6178C7.53926 12.1037 7.81778 12.5244 8.17333 12.88C8.52889 13.2356 8.94963 13.5081 9.43556 13.6978C9.92148 13.8874 10.4548 13.9822 11.0356 13.9822C11.6519 13.9822 12.2207 13.8637 12.7422 13.6267C13.2637 13.3778 13.7378 13.0044 14.1644 12.5067L16.0133 14.2133C15.4207 14.9363 14.6859 15.4874 13.8089 15.8667C12.9437 16.2459 11.9659 16.4356 10.8756 16.4356Z"
      fill="white"
    />
  </SVG>
)

export default withIconStyles(IconCleverSquare)
