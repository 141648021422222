import React, { useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Col, Divider, Row, Select } from 'antd'
import { isEmpty, isEqual, pick } from 'lodash'
import { IconExpandBox } from '@edulastic/icons'
import { withNamespaces } from '@edulastic/localization'
import {
  StyledBrowseIconWrapper,
  StyledDataInputWrapper,
  StyledFieldLabel,
  StyledOptionalText,
  StyledRequired,
  StyledSelectInput,
} from './styled'
import { selectsData } from '../../../../TestPage/components/common'
import {
  getAllFormattedCurriculumsSelector,
  getStandardsListSelector,
  standardsSelector,
} from '../../../../src/selectors/dictionaries'
import { getDictStandardsForCurriculumAction } from '../../../../src/actions/dictionaries'
import StandardsModal from '../../../../../assessment/containers/QuestionMetadata/StandardsModal'

const StandardsInput = ({
  t: i18translate,
  updateAlignmentData,
  formattedCuriculums,
  curriculumStandards,
  curriculumStandardsLoading,
  getCurriculumStandards,
  alignment,
  allCurriculums,
}) => {
  const [showStandardsModal, setShowStandardsModal] = useState(false)
  const [searchProps, setSearchProps] = useState({
    curriculumId: null,
    grades: [],
    searchStr: null,
  })

  const {
    curriculum = '',
    curriculumId = null,
    grades = [],
    subject = '',
    standards = [],
  } = alignment || {}

  const curriculumStandardsELO = curriculumStandards?.elo || []
  const standardsArr = (standards || []).map((el) => el.identifier)

  const updateSubject = (_subject) => {
    updateAlignmentData({
      subject: _subject,
      curriculumId: null,
      curriculum: '',
      standards: [],
      domains: [],
    })
  }

  const handleSearchStandard = (searchStr) => {
    const searchObject = { curriculumId, grades, searchStr }
    if (!isEqual(searchProps, searchObject)) {
      setSearchProps(searchObject)
      getCurriculumStandards(
        searchObject.curriculumId,
        searchObject.grades,
        searchObject.searchStr
      )
    }
  }

  const handleStandardSelect = (chosenStandardsArr, option) => {
    const newStandard = pick(option.props.obj, [
      '_id',
      'level',
      'grades',
      'identifier',
      'tloIdentifier',
      'tloId',
      'tloDescription',
      'eloId',
      'subEloId',
      'description',
      'curriculumId',
    ])
    let newStandards = standards.some(
      (standard) => standard._id === newStandard._id
    )
    if (newStandards) {
      newStandards = standards.filter(
        (standard) => standard._id !== newStandard._id
      )
    } else {
      newStandards = [...standards, newStandard]
    }
    updateAlignmentData({ standards: newStandards })
  }

  const handleStandardDeselect = (removedElement) => {
    const newStandards = standards.filter(
      (el) => !(el.identifier === removedElement)
    )
    updateAlignmentData({ standards: newStandards })
  }

  const handleApply = (data) => {
    if (!isEmpty(data)) {
      let { subject: _subject } = data
      if (!_subject) {
        const curriculumFromStandard = data.standard.id
          ? formattedCuriculums.find((c) => c.value === data.standard.id) || {}
          : {}
        _subject = curriculumFromStandard?.subject
      }

      updateAlignmentData({
        subject: data.subject,
        curriculum: data.standard?.curriculum,
        curriculumId: data.standard?.id,
        grades: data.grades,
        standards: data.eloStandards,
      })
    }
    setShowStandardsModal(false)
  }

  const handleShowStandardsModal = () => {
    if (curriculumStandardsLoading) {
      return
    }
    setShowStandardsModal(true)
  }

  return (
    <>
      {showStandardsModal && (
        <StandardsModal
          t={i18translate}
          defaultSubject={subject}
          defaultGrades={grades}
          defaultStandards={standards}
          defaultStandard={{ curriculum, id: curriculumId }}
          visible={showStandardsModal}
          curriculums={allCurriculums}
          onApply={handleApply}
          setSubject={updateSubject}
          onCancel={() => setShowStandardsModal(false)}
          getCurriculumStandards={getCurriculumStandards}
          curriculumStandardsLoading={curriculumStandardsLoading}
          alignmentIndex={0}
          showAllInterestedCurriculums
        />
      )}
      <StyledDataInputWrapper>
        <Divider />
        <br />
        <Row type="flex" gutter={16}>
          <Col span={5}>
            <StyledFieldLabel>
              Subject<StyledRequired>*</StyledRequired>
            </StyledFieldLabel>
            <StyledSelectInput
              onChange={updateSubject}
              data-cy="selectSubject"
              placeholder="Select Subject"
              getPopupContainer={(trigger) => trigger.parentNode}
              value={subject || undefined}
            >
              {selectsData.allSubjects.map(({ text, value }) => (
                <Select.Option key={value} value={value}>
                  {text}
                </Select.Option>
              ))}
            </StyledSelectInput>
          </Col>
          <Col span={7}>
            <StyledFieldLabel>
              Grade<StyledRequired>*</StyledRequired>
            </StyledFieldLabel>
            <StyledSelectInput
              mode="multiple"
              data-cy="selectGrades"
              showArrow
              showSearch
              placeholder="Select Grade"
              getPopupContainer={(trigger) => trigger.parentNode}
              value={grades}
              maxTagCount={4}
              onChange={(_grades) => {
                updateAlignmentData({ grades: _grades })
              }}
            >
              {selectsData.allGrades.map(({ text, value }) => (
                <Select.Option key={text} value={value}>
                  {text}
                </Select.Option>
              ))}
            </StyledSelectInput>
          </Col>
          <Col span={5}>
            <StyledFieldLabel>
              Standard Set <StyledOptionalText>(OPTIONAL)</StyledOptionalText>{' '}
            </StyledFieldLabel>
            <StyledSelectInput
              showSearch
              filterOption
              labelInValue
              optionFilterProp="children"
              placeholder="Select Standard Set"
              data-cy="selectSdtSet"
              getPopupContainer={(trigger) => trigger.parentNode}
              value={
                curriculumId && curriculum
                  ? { value: curriculumId, label: curriculum }
                  : undefined
              }
              onChange={(selectedCurriculum) => {
                updateAlignmentData({
                  curriculumId: selectedCurriculum.key,
                  curriculum: selectedCurriculum.label,
                })
              }}
            >
              {formattedCuriculums.map(
                ({ value, text, disabled: disabledOption }) => (
                  <Select.Option value={value} disabled={disabledOption}>
                    {text}
                  </Select.Option>
                )
              )}
            </StyledSelectInput>
          </Col>
          <Col span={7}>
            <StyledFieldLabel>
              Standards <StyledOptionalText>(OPTIONAL)</StyledOptionalText>{' '}
            </StyledFieldLabel>
            <div style={{ position: 'relative' }}>
              <StyledSelectInput
                bg="white"
                mode="multiple"
                data-cy="selectStandards"
                placeholder="Select Standards"
                filterOption={false}
                value={standardsArr}
                optionLabelProp="title"
                maxTagCount={4}
                getPopupContainer={(trigger) => trigger.parentNode}
                onFocus={() => handleSearchStandard('')}
                onSearch={handleSearchStandard}
                onSelect={handleStandardSelect}
                onDeselect={handleStandardDeselect}
              >
                {!curriculumStandardsLoading &&
                  curriculumStandardsELO?.length > 0 &&
                  curriculumStandardsELO.map((el) => (
                    <Select.Option
                      title={el.identifier}
                      key={el._id}
                      value={el.identifier}
                      obj={el}
                      style={{ whiteSpace: 'normal' }}
                    >
                      <div>
                        <div>
                          <b>{el.identifier}</b>
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: el.description,
                          }}
                        />
                      </div>
                    </Select.Option>
                  ))}
              </StyledSelectInput>
              <StyledBrowseIconWrapper disabled={curriculumStandardsLoading}>
                <IconExpandBox onClick={handleShowStandardsModal} />
              </StyledBrowseIconWrapper>
            </div>
          </Col>
        </Row>
      </StyledDataInputWrapper>
    </>
  )
}

const enhance = compose(
  withNamespaces('assessment'),
  connect(
    (state, props) => ({
      formattedCuriculums: getAllFormattedCurriculumsSelector(state, {
        subject: props?.alignment?.subject,
      }),
      curriculumStandards: getStandardsListSelector(state),
      curriculumStandardsLoading: standardsSelector(state)?.loading,
    }),
    {
      getCurriculumStandards: getDictStandardsForCurriculumAction,
    }
  )
)

export default enhance(StandardsInput)
