import {
  CheckboxLabel,
  CustomModalStyled,
  EduButton,
  SelectInputStyled,
  TextInputStyled,
} from '@edulastic/common'
import { Col, Form, Row, Select, Tooltip } from 'antd'
import { omit, uniqBy } from 'lodash'
import React, { Component } from 'react'
import { userPermissions, roleuser } from '@edulastic/constants'
import { IconInfo } from '@edulastic/icons'
import { ButtonsContainer, ModalFormItem } from '../../../../../common/styled'
import {
  canEnableInsightOnly,
  dataOpsRoleSelected,
  saRolePermissions,
  saRolePermissionsEmptyValue,
} from '../../../../DistrictAdmin/components/DistrictAdminTable/helpers'
import FeaturesSwitch from '../../../../../features/components/FeaturesSwitch'

const Option = Select.Option

class EditSchoolAdminModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      insightNotSupRoleChecked: !canEnableInsightOnly(
        props?.schoolAdminData?._source?.permissions || []
      ),
    }
  }

  onSaveSchoolAdmin = () => {
    const { role } = this.props
    this.props?.form.validateFields((err, row = {}) => {
      if (!err) {
        const { schoolAdminData, updateSchoolAdmin, userOrgId } = this.props
        const { isSuperAdmin, isInsightsOnly, rolePermissions } = row
        const { permissions: currPermissions = [] } = schoolAdminData?._source

        if (!row.password) row = omit(row, ['password'])
        row = omit(row, [
          'confirmPassword',
          'isSuperAdmin',
          'isInsightsOnly',
          'rolePermissions',
        ])

        const permissions = currPermissions.filter((p) => {
          const excludePermissions =
            role === roleuser.DISTRICT_ADMIN
              ? [
                  userPermissions.SUPER_ADMIN,
                  userPermissions.DATA_OPS,
                  userPermissions.DATA_OPS_ONLY,
                  userPermissions.INSIGHTS_ONLY,
                ]
              : [userPermissions.SUPER_ADMIN, userPermissions.INSIGHTS_ONLY]
          return !excludePermissions.includes(p)
        })

        if (isSuperAdmin) {
          permissions.push(userPermissions.SUPER_ADMIN)
        }

        if (
          role === roleuser.DISTRICT_ADMIN &&
          rolePermissions !== saRolePermissionsEmptyValue
        ) {
          permissions.push(rolePermissions)
        }

        const enableInsightOnly = canEnableInsightOnly(permissions)

        if (enableInsightOnly && isInsightsOnly) {
          permissions.push(userPermissions.INSIGHTS_ONLY)
        }
        updateSchoolAdmin({
          userId: schoolAdminData._id,
          data: Object.assign(row, {
            districtId: userOrgId,
            permissions: [...new Set(permissions)],
          }),
        })
        this.onCloseModal()
      }
    })
  }

  handleConfirmPassword = (rule, value, callback) => {
    const { form = {} } = this.props
    const { getFieldValue } = form
    const password = getFieldValue('password')
    const confirmPassword = getFieldValue('confirmPassword')

    if (password !== confirmPassword) return callback('Password does not match')

    callback() // no error
  }

  onCloseModal = () => {
    const { closeModal } = this.props
    closeModal()
  }

  handelSuperAdminChange = (e) => {
    const {
      form,
      schoolAdminData: { _source },
    } = this.props
    if (e.target.checked) {
      form.setFieldsValue({ isInsightsOnly: !e.target.checked })
    }
    const role = form.getFieldValue('rolePermissions')
    this.setState({
      insightNotSupRoleChecked: e.target.checked || dataOpsRoleSelected([role]),
    })
  }

  handleRolePermissionsChange = (role) => {
    const { form } = this.props
    if (dataOpsRoleSelected([role])) {
      form.setFieldsValue({ isInsightsOnly: false })
    }
    const isSuperAdminChecked = form.getFieldValue('isSuperAdmin')
    this.setState({
      insightNotSupRoleChecked:
        isSuperAdminChecked || dataOpsRoleSelected([role]),
    })
  }

  render() {
    const {
      modalVisible,
      schoolAdminData: { _source },
      schoolsList = [],
      t,
      role,
    } = this.props
    const { insightNotSupRoleChecked } = this.state
    const isSuperAdmin = _source?.permissions.includes('super_admin')
    const isInsightsOnly = _source?.permissions.includes(
      userPermissions.INSIGHTS_ONLY
    )

    let { institutionDetails = [] } = _source
    let schooleFinalList = [...schoolsList]
    if (institutionDetails.length) {
      institutionDetails = institutionDetails
        .filter(({ id = '', name = '' }) => id && name)
        .map(({ id, name }) => ({ _id: id, name }))
      schooleFinalList = uniqBy(
        [...schooleFinalList, ...institutionDetails],
        '_id'
      )
    }

    const schoolsOptions = schooleFinalList.map((row, index) => (
      <Option key={index} value={row?._id} label={row?.name}>
        {row?.name}
      </Option>
    ))

    let rolePermissions = saRolePermissionsEmptyValue
    if (
      _source?.permissions.includes(userPermissions.DATA_OPS) ||
      _source?.permissions.includes(userPermissions.DATA_OPS_ONLY)
    ) {
      rolePermissions =
        saRolePermissions.find((item) =>
          _source?.permissions.includes(item.value)
        )?.value || saRolePermissionsEmptyValue
    }

    const { getFieldDecorator } = this.props?.form
    return (
      <CustomModalStyled
        visible={modalVisible}
        title={t('users.schooladmin.editsa.title')}
        onOk={this.onCreateSchoolAdmin}
        onCancel={this.onCloseModal}
        maskClosable={false}
        centered
        footer={[
          <ButtonsContainer>
            <EduButton isGhost onClick={this.onCloseModal} data-cy="CancelEdit">
              {t('users.schooladmin.editsa.nocancel')}
            </EduButton>
            <EduButton onClick={this.onSaveSchoolAdmin} data-cy="YesEdit">
              {t('users.schooladmin.editsa.yesupdate')}
            </EduButton>
          </ButtonsContainer>,
        ]}
      >
        <Row>
          <Col span={24}>
            <ModalFormItem label={t('users.schooladmin.firstname')}>
              {getFieldDecorator('firstName', {
                rules: [
                  {
                    required: true,
                    message: t(
                      'users.schooladmin.editsa.validations.firstname'
                    ),
                  },
                ],
                initialValue: _source.firstName,
              })(
                <TextInputStyled
                  placeholder={t('users.schooladmin.editsa.enterfirstname')}
                  data-cy="firstNameTextBox"
                />
              )}
            </ModalFormItem>
          </Col>
          <Col span={24}>
            <ModalFormItem label={t('users.schooladmin.lastname')}>
              {getFieldDecorator('lastName', {
                rules: [
                  {
                    required: true,
                    message: t('users.schooladmin.editsa.validations.lastname'),
                  },
                ],
                initialValue: _source.lastName,
              })(
                <TextInputStyled
                  placeholder={t('users.schooladmin.editsa.enterlastname')}
                  data-cy="lastNameTextBox"
                />
              )}
            </ModalFormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <ModalFormItem label={t('users.schooladmin.email')}>
              {getFieldDecorator('email', {
                rules: [
                  {
                    required: true,
                    message: t('users.schooladmin.editsa.validations.email'),
                  },
                  {
                    type: 'email',
                    message: t(
                      'users.schooladmin.editsa.validations.invalidemail'
                    ),
                  },
                ],
                initialValue: _source.email,
              })(
                <TextInputStyled
                  placeholder={t('users.schooladmin.editsa.enteremail')}
                  data-cy="emailTextBox"
                />
              )}
            </ModalFormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <ModalFormItem label={t('users.schooladmin.editsa.password')}>
              {getFieldDecorator(
                'password',
                {}
              )(
                <TextInputStyled
                  type="password"
                  autoComplete="off"
                  placeholder={t('users.schooladmin.editsa.enterpassword')}
                  data-cy="passwordTextBox"
                />
              )}
            </ModalFormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <ModalFormItem
              label={t('users.schooladmin.editsa.confirmpassword')}
            >
              {getFieldDecorator('confirmPassword', {
                rules: [
                  {
                    validator: this.handleConfirmPassword,
                    message: t(
                      'users.schooladmin.editsa.validations.invalidpassword'
                    ),
                  },
                ],
              })(
                <TextInputStyled
                  type="password"
                  autoComplete="off"
                  placeholder={t(
                    'users.schooladmin.editsa.enterconfirmpassword'
                  )}
                  data-cy="confirmPasswordTextBox"
                />
              )}
            </ModalFormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <ModalFormItem label={t('users.schooladmin.school')}>
              {getFieldDecorator('institutionIds', {
                rules: [
                  {
                    required: true,
                    message: t('users.schooladmin.editsa.validations.school'),
                  },
                ],
                initialValue: _source.institutionIds,
              })(
                <SelectInputStyled
                  mode="multiple"
                  placeholder={t('users.schooladmin.editsa.selectschool')}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  data-cy="selectSchools"
                  data-testid="selectSchools"
                  showSearch
                  optionFilterProp="label"
                >
                  {schoolsOptions}
                </SelectInputStyled>
              )}
            </ModalFormItem>
          </Col>
        </Row>
        {role === roleuser.DISTRICT_ADMIN && (
          <FeaturesSwitch
            inputFeatures="isDataOpsUser"
            actionOnInaccessible="hidden"
          >
            <Row>
              <Col span={24}>
                <ModalFormItem
                  label={t('users.districtadmin.editda.selectrole')}
                >
                  {getFieldDecorator('rolePermissions', {
                    initialValue: rolePermissions,
                  })(
                    <SelectInputStyled
                      data-cy="selectRole"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      onChange={this.handleRolePermissionsChange}
                    >
                      {saRolePermissions.map((item) => (
                        <Select.Option key={item.value} value={item.value}>
                          {item.label}
                          <Tooltip title={item.tooltipTitle}>
                            <IconInfo height={10} />
                          </Tooltip>
                        </Select.Option>
                      ))}
                    </SelectInputStyled>
                  )}
                </ModalFormItem>
              </Col>
            </Row>
          </FeaturesSwitch>
        )}
        <Row>
          <Col span={7}>
            <ModalFormItem style={{ margin: '0px' }}>
              {getFieldDecorator('isSuperAdmin', {
                initialValue: isSuperAdmin,
                valuePropName: 'checked',
              })(
                <CheckboxLabel
                  data-cy="superAdminCheckbox"
                  data-testid="superAdminCheckbox"
                  onChange={this.handelSuperAdminChange}
                >
                  {t('users.schooladmin.superAdmin')}
                </CheckboxLabel>
              )}
            </ModalFormItem>
          </Col>
          <Col span={9}>
            <ModalFormItem style={{ margin: '0px' }}>
              {getFieldDecorator('isPowerTeacher', {
                initialValue: _source?.isPowerTeacher,
                valuePropName: 'checked',
              })(
                <CheckboxLabel
                  data-cy="powerUserCheckBox"
                  data-testid="powerUserCheckBox"
                >
                  {t('users.schooladmin.powertools')}
                </CheckboxLabel>
              )}
            </ModalFormItem>
          </Col>
          {role === roleuser.DISTRICT_ADMIN && (
            <Col span={7}>
              <ModalFormItem style={{ margin: '0px' }}>
                {getFieldDecorator('isInsightsOnly', {
                  initialValue: isInsightsOnly,
                  valuePropName: 'checked',
                })(
                  <CheckboxLabel
                    data-cy="insightsOnlyCheckbox"
                    data-testid="insightsOnlyCheckbox"
                    disabled={insightNotSupRoleChecked}
                  >
                    {t('users.schooladmin.insightsOnly.title')}
                    <Tooltip title={t('users.schooladmin.insightsOnly.text')}>
                      <IconInfo height={10} />
                    </Tooltip>
                  </CheckboxLabel>
                )}
              </ModalFormItem>
            </Col>
          )}
        </Row>
      </CustomModalStyled>
    )
  }
}

const EditSchoolAdminModalForm = Form.create()(EditSchoolAdminModal)
export default EditSchoolAdminModalForm
