import { Table, Typography } from 'antd'
import styled from 'styled-components'
import { gray600 } from '@edulastic/colors'

const { Text } = Typography

/** @type {typeof Table} */
export const StyledTable = styled(Table)`
  .ant-table-thead {
    display: none;
  }
  .ant-table-tbody {
    tr {
      td:first-child {
        padding: 16px 0px;
      }
    }
  }
`
/** @type {typeof Text} */
export const StyledText = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  color: ${gray600};
`
export const EmptyText = styled.div`
  min-height: 160px;
`
