import React, { useState } from 'react'
import { Icon, Menu, Popover } from 'antd'
import {
  MORE_ITEM_TYPES,
  NEW_ITEM_DROPDOWN_QUICK_LINKS,
} from '@edulastic/constants/const/addQuestionQuickLinks'
import { connect } from 'react-redux'
import { Divider } from '../../../ItemDetail/components/SettingsBar/components/SettingsBarIcon/styled'
import { isAddItemQuickLinkEnabledSelector } from '../../../src/selectors/user'
import {
  isDynamicTestSelector,
  isSurveyTestSelector,
} from '../../../TestPage/ducks'
import {
  StyledMenuItem,
  StyledPopoverContent,
  StyledPopoverInnerContainer,
  StyledButtonInnerContainer,
  StyledEduButton,
} from './styled'

const QuickLinksMenu = ({ onItemClick }) => {
  const menuItems = [...NEW_ITEM_DROPDOWN_QUICK_LINKS, MORE_ITEM_TYPES]

  return (
    <StyledPopoverContent>
      <Menu>
        {menuItems.map((item) => {
          return (
            <StyledMenuItem
              key={item.key}
              onClick={(e) => {
                e.domEvent.stopPropagation()
                onItemClick(item.key)
              }}
              data-cy={`${
                item.key === MORE_ITEM_TYPES.key ? 'quick-link-' : ''
              }${item.dataCy}`}
            >
              <div>
                {item.icon}
                <span style={{ marginLeft: '12px' }}>{item.label}</span>
              </div>
            </StyledMenuItem>
          )
        })}
      </Menu>
    </StyledPopoverContent>
  )
}

export const QuickLinkDropDown = ({ onItemClick, disabled }) => {
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <Popover
      content={!disabled && <QuickLinksMenu onItemClick={onItemClick} />}
      overlayClassName="create-item-quick-link-popover"
      onVisibleChange={() => setMenuOpen((o) => !o)}
      visible={menuOpen}
    >
      <StyledPopoverInnerContainer
        onClick={(e) => {
          e.stopPropagation()
          setMenuOpen((o) => !o)
        }}
        data-cy="item-quick-links-dd"
      >
        <Icon type="down" style={{ color: 'white' }} />
      </StyledPopoverInnerContainer>
    </Popover>
  )
}

const NewItemQuickLinkButton = ({
  children,
  onClick,
  isAddItemQuickLinksEnabled,
  isSurveyTest,
  isDynamicTest,
  ...props
}) => {
  const showQuickLinks =
    isAddItemQuickLinksEnabled && !(isSurveyTest || isDynamicTest)

  return (
    <StyledEduButton
      {...props}
      showQuickLinks={showQuickLinks}
      onClick={() => onClick()}
    >
      <StyledButtonInnerContainer>{children}</StyledButtonInnerContainer>
      {showQuickLinks && (
        <>
          <Divider
            getMarginLeft={(f) => f}
            style={{ background: 'white', height: 'inherit' }}
          />
          <QuickLinkDropDown onItemClick={onClick} />
        </>
      )}
    </StyledEduButton>
  )
}

export default connect((state) => ({
  isAddItemQuickLinksEnabled: isAddItemQuickLinkEnabledSelector(state),
  isSurveyTest: isSurveyTestSelector(state),
  isDynamicTest: isDynamicTestSelector(state),
}))(NewItemQuickLinkButton)
