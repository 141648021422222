import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { get, cloneDeep } from 'lodash'
import uuid from 'uuid/v4'
import styled from 'styled-components'
import produce from 'immer'
import { PaddingDiv } from '@edulastic/common'
import { withNamespaces } from '@edulastic/localization'
import { setQuestionDataAction } from '../../../author/QuestionEditor/ducks'
import {
  PREVIEW,
  EDIT,
  CLEAR,
  CHECK,
  SHOW,
} from '../../constants/constantsForQuestions'

import Options from './components/Options'
import Authoring from './components/Authoring'
import Display from './components/Display'
import CorrectAnswers from './CorrectAnswers'
import { replaceVariables } from '../../utils/variables'

import { ContentArea } from '../../styled/ContentArea'
import { AdvancedOptionContainer } from '../../styled/AdvancedOptionContainer'
import { changePreviewAction } from '../../../author/src/actions/view'
import { StyledPaperWrapper } from '../../styled/Widget'
import { getFontSize } from '../../utils/helpers'
import QuestionMetadata from '../../containers/QuestionMetadata'
import { getCurrentQuestionSelector } from '../../../author/sharedDucks/questions'

const EmptyWrapper = styled.div``

const MutlChoiceWrapper = styled(StyledPaperWrapper)`
  border-radius: ${({ flowLayout }) => (flowLayout ? 0 : 10)}px;
  ${({ flowLayout }) => flowLayout && 'background: transparent'};
  padding: ${(props) => (props.padding ? props.padding : '0px')};
  box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : 'none')};
`

class MultipleChoice extends Component {
  getRenderData = () => {
    const { item: templateItem, history, view } = this.props
    const item = view === EDIT ? templateItem : replaceVariables(templateItem)
    const locationState = history.location.state
    const isDetailPage =
      locationState !== undefined ? locationState.itemDetail : false
    let previewDisplayOptions
    let previewStimulus
    let itemForEdit
    if (item.smallSize || isDetailPage) {
      previewStimulus = item.stimulus
      previewDisplayOptions = item.options
      itemForEdit = templateItem
    } else {
      previewStimulus = item.stimulus
      previewDisplayOptions = item.options
      itemForEdit = {
        ...templateItem,
        stimulus: templateItem.stimulus,
        list: templateItem.options,
        validation: templateItem.validation,
      }
    }

    return {
      previewStimulus,
      previewDisplayOptions,
      itemForEdit,
      uiStyle: item.uiStyle,
      maxResponses: item.maxResponses,
      multipleResponses: !!item.multipleResponses,
    }
  }

  handleAddAnswer = (qid) => {
    const {
      saveAnswer,
      userAnswer,
      item,
      previewTab,
      changePreviewTab,
      changeView,
    } = this.props
    const newAnswer = cloneDeep(userAnswer)
    if (previewTab !== CLEAR) {
      changePreviewTab(CLEAR)
      changeView(CLEAR)
    }
    if (item.multipleResponses) {
      if (newAnswer.includes(qid)) {
        const removeIndex = newAnswer.findIndex((el) => el === qid)
        newAnswer.splice(removeIndex, 1)
        saveAnswer(newAnswer)
      } else if (!item.maxResponses || newAnswer.length < item.maxResponses) {
        saveAnswer([...newAnswer, qid])
      }
    } else {
      saveAnswer([qid])
    }
  }

  handleRemoveAnswer = (opIndex) => {
    const { saveAnswer, userAnswer } = this.props
    const newAnswer = cloneDeep(userAnswer)
    const removeIndex = newAnswer.findIndex((el) => el === opIndex)
    if (removeIndex !== -1) {
      newAnswer.splice(removeIndex, 1)
    }
    saveAnswer(newAnswer)
  }

  handleOptionsChange = (name, value) => {
    const { setQuestionData, item, saveAnswer } = this.props
    setQuestionData(
      produce(item, (draft) => {
        const reduceResponses = (acc, val, index) => {
          if (index === 0) {
            acc.push(val)
          }
          return acc
        }

        if (name === 'multipleResponses' && value === false) {
          draft.validation.validResponse.value = draft.validation.validResponse.value.reduce(
            reduceResponses,
            []
          )
          draft.validation.altResponses = draft.validation.altResponses.map(
            (res) => {
              res.value = res.value.reduce(reduceResponses, [])
              return res
            }
          )
          saveAnswer([])
        }

        draft[name] = value
      })
    )
  }

  addNewChoiceBtn = () => {
    const { item, setQuestionData } = this.props
    setQuestionData(
      produce(item, (draft) => {
        draft.options.push({
          value: uuid(),
          label: '',
        })
      })
    )
  }

  render() {
    const {
      col,
      qIndex,
      view,
      previewTab,
      smallSize,
      item,
      userAnswer,
      t,
      testItem,
      evaluation,
      fillSections,
      cleanSections,
      advancedAreOpen,
      flowLayout,
      advancedLink,
      disableResponse,
      setQuestionData,
      froalaFooter,
      ...restProps
    } = this.props
    const {
      previewStimulus,
      previewDisplayOptions,
      itemForEdit,
      uiStyle,
      multipleResponses,
    } = this.getRenderData()
    const isV1Multipart = get(col, 'isV1Multipart', false)
    const fontSize = getFontSize(uiStyle?.fontsize)
    const Wrapper = testItem ? EmptyWrapper : MutlChoiceWrapper
    const qId = item.id
    // const multi_response = this.props.item.multipleResponses;

    return (
      <>
        <PaddingDiv className="multiple-choice-wrapper">
          {view === EDIT && (
            <ContentArea>
              <Authoring
                froalaFooter={froalaFooter}
                item={itemForEdit}
                fontSize={fontSize}
                setQuestionData={setQuestionData}
                fillSections={fillSections}
                cleanSections={cleanSections}
              />

              <CorrectAnswers
                uiStyle={uiStyle}
                options={previewDisplayOptions}
                question={itemForEdit}
                multipleResponses={multipleResponses}
                validation={item.validation}
                itemLevelScoring={item.itemLevelScoring}
                itemLevelScore={item.itemLevelScore}
                setQuestionData={setQuestionData}
                styleType="primary"
                fillSections={fillSections}
                cleanSections={cleanSections}
                fontSize={fontSize}
                queTitle={item?.title}
                onChangeOption={this.handleOptionsChange}
                {...restProps}
              />
              <QuestionMetadata isEditView />

              {advancedLink}

              <AdvancedOptionContainer>
                <Options
                  onChange={this.handleOptionsChange}
                  uiStyle={uiStyle}
                  advancedAreOpen={advancedAreOpen}
                  fillSections={fillSections}
                  cleanSections={cleanSections}
                  multipleResponses={multipleResponses}
                  item={item}
                  {...restProps}
                />
              </AdvancedOptionContainer>
            </ContentArea>
          )}
          {view === PREVIEW && (
            <Wrapper isV1Multipart={isV1Multipart} flowLayout={flowLayout}>
              {previewTab === SHOW ||
              previewTab === CLEAR ||
              previewTab === CHECK ? (
                <Display
                  showAnswer={previewTab === SHOW}
                  preview={previewTab === CLEAR}
                  checkAnswer={previewTab === CHECK}
                  view={view}
                  smallSize={smallSize}
                  options={previewDisplayOptions}
                  question={previewStimulus}
                  userSelections={userAnswer}
                  uiStyle={uiStyle}
                  evaluation={evaluation}
                  validation={item.validation}
                  onChange={!disableResponse ? this.handleAddAnswer : () => {}}
                  onRemove={this.handleRemoveAnswer}
                  qIndex={qIndex}
                  qId={qId}
                  multipleResponses={multipleResponses}
                  flowLayout={flowLayout}
                  qLabel={item.qLabel}
                  qSubLabel={item.qSubLabel}
                  testItem={testItem}
                  styleType="primary"
                  item={item}
                  fontSize={fontSize}
                  {...restProps}
                />
              ) : null}
            </Wrapper>
          )}
        </PaddingDiv>
      </>
    )
  }
}

MultipleChoice.propTypes = {
  view: PropTypes.string.isRequired,
  qIndex: PropTypes.number.isRequired,
  previewTab: PropTypes.string,
  item: PropTypes.object,
  smallSize: PropTypes.bool,
  history: PropTypes.object,
  setQuestionData: PropTypes.func.isRequired,
  saveAnswer: PropTypes.func.isRequired,
  userAnswer: PropTypes.any,
  t: PropTypes.func.isRequired,
  testItem: PropTypes.bool,
  evaluation: PropTypes.any,
  changePreviewTab: PropTypes.func.isRequired,
  fillSections: PropTypes.func,
  cleanSections: PropTypes.func,
  advancedAreOpen: PropTypes.bool,
  flowLayout: PropTypes.bool,
  disableResponse: PropTypes.bool,
  col: PropTypes.object.isRequired,
  advancedLink: PropTypes.any,
  changeView: PropTypes.func.isRequired,
}

MultipleChoice.defaultProps = {
  previewTab: CLEAR,
  item: {
    options: [],
  },
  advancedLink: null,
  smallSize: false,
  history: {},
  userAnswer: [],
  testItem: false,
  evaluation: '',
  advancedAreOpen: false,
  fillSections: () => {},
  cleanSections: () => {},
  flowLayout: false,
  disableResponse: false,
}

const enhance = compose(
  withRouter,
  withNamespaces('assessment'),
  connect(
    (state) => ({
      questionInOriginalState: getCurrentQuestionSelector(state),
    }),
    {
      setQuestionData: setQuestionDataAction,
      changeView: changePreviewAction,
    }
  )
)

const MultipleChoiceContainer = enhance(MultipleChoice)

export { MultipleChoiceContainer as MultipleChoice }
