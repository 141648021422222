import React from 'react'
import sectionsAdaptiveTestFlowImage from '../static/sectionsAdaptiveTestFlow.png'
import {
  StyledAdaptiveTestInfoTextContainer,
  StyledAdaptiveTestOverviewContainer,
} from '../styled-components'

export const SectionsAdaptiveTestInfoContent = () => {
  return (
    <StyledAdaptiveTestOverviewContainer>
      <img
        src={sectionsAdaptiveTestFlowImage}
        width="90%"
        alt="Adaptive Test Flowchart"
      />
      <StyledAdaptiveTestInfoTextContainer>
        <div className="heading title">How Adaptive Tests Work?</div>
        <div className="content">
          <ol className="outer-list">
            <li>
              <p>
                Adaptive tests adjust to a student&apos;s performance for a more
                personalized assessment experience.
              </p>
            </li>
            <li>
              <p>
                Test Structure:
                <ul>
                  <li>
                    <p>
                      <strong>Section 1:</strong> Given to all students.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Section 2:</strong> Has two versions (Easy or
                      Hard) determined by student performance in Section 1.
                    </p>
                  </li>
                </ul>
              </p>
            </li>
            <li>
              <p>
                Key Features:
                <ul>
                  <li>
                    <p>
                      <strong>Time limits:</strong> Each section can have a
                      specific time limit.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Item limits:</strong> Sections can have a set
                      number of questions.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Scaled scores:</strong> Each section has a scaled
                      score range. A student’s score is scaled based on the
                      number of correct answers. For example, with a score range
                      of 80-340, if the total possible points in Section 1 is
                      22:
                      <ul className="inner-list">
                        <li>
                          <p>Scoring 0/22 points = 80</p>
                        </li>
                        <li>
                          <p>Scoring 22/22 points = 340</p>
                        </li>
                        <li>
                          <p>
                            Scores in between will be scaled proportionally.
                          </p>
                        </li>
                        <li>
                          <p>
                            Future enhancements will factor in more parameters
                            such as question difficulty for more precise
                            scoring.
                          </p>
                        </li>
                      </ul>
                    </p>
                  </li>
                </ul>
              </p>
            </li>
          </ol>
          <div className="heading">Section 1</div>
          <ol className="outer-list">
            <li>
              <p>All students start with Section 1.</p>
            </li>
            <li>
              <p>Only automatically graded items can be included.</p>
            </li>
          </ol>
          <div className="heading">Section 2</div>
          <ol className="outer-list">
            <li>
              <p>
                Students are given either the easier or harder version of
                Section 2 based on their performance in Section 1.
              </p>
            </li>
            <li>
              <p>
                You can define a threshold using either a percentage score or
                the number of correct responses to make this decision.
              </p>
            </li>
          </ol>
          <div className="heading">Reports</div>
          <ol className="outer-list">
            <li>
              <p>
                Once students complete the adaptive test, their scaled scores
                will be available in the “Adaptive Test Reports” section on the
                <strong> Insights </strong>page. You can analyze and compare
                student performance across the district, and view both total
                scores and scores in each area of the selected adaptive
                assessment(s).
              </p>
            </li>
            <li>
              <p>
                For raw scores, refer to other reports in
                <strong> Insights </strong>, such as the
                <strong> Single Assessment Reports</strong>.
              </p>
            </li>
          </ol>
        </div>
      </StyledAdaptiveTestInfoTextContainer>
    </StyledAdaptiveTestOverviewContainer>
  )
}

export default SectionsAdaptiveTestInfoContent
