import React from 'react'
import styled from 'styled-components'
import BreadCrumb from '../../../src/components/Breadcrumb'
import { SecondHeader } from '../../../TestPage/components/Summary/components/Container/styled'
import { getUrlFilter } from '../../../Playlist/components/Container/Container'

const CurriculumBreadCrumb = ({ playlistPage = 1, playlistFilters }) => {
  const filterUrlFragment = getUrlFilter(playlistFilters?.filter)
  const playlistBreadcrumbData = [
    {
      title: 'PLAYLIST',
      to: `/author/playlists${
        filterUrlFragment ? `/filter/${filterUrlFragment}` : ''
      }/page/${playlistPage}`,
    },
    {
      title: 'REVIEW',
      to: '',
    },
  ]

  return (
    <ReviewBreadCrumbWrapper>
      <SecondHeader>
        <BreadCrumb
          data={playlistBreadcrumbData}
          style={{ position: 'unset' }}
        />
      </SecondHeader>
    </ReviewBreadCrumbWrapper>
  )
}

export default CurriculumBreadCrumb

const ReviewBreadCrumbWrapper = styled.div`
  padding: 0px 0px 15px;
  width: 100%;
`
