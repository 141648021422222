/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconSaveAs = (props) => (
  <SVG
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M9.8999 5.7V3L7.8999 1H1.8999C1.3449 1 0.899902 1.45 0.899902 2V9C0.899902 9.55 1.3449 10 1.8999 10H5.5999L6.5999 9H1.8999V2H7.4849L8.8999 3.415V6.7L9.8999 5.7ZM6.8999 7C6.8999 7.83 6.2299 8.5 5.3999 8.5C4.5699 8.5 3.8999 7.83 3.8999 7C3.8999 6.17 4.5699 5.5 5.3999 5.5C6.2299 5.5 6.8999 6.17 6.8999 7ZM2.3999 2.5H6.8999V4.5H2.3999V2.5ZM9.3949 7.625L10.2799 8.51L7.7849 11H6.8999V10.115L9.3949 7.625ZM11.0249 7.755L10.5999 8.18L9.7149 7.295L10.1399 6.87C10.2399 6.77 10.3949 6.77 10.4949 6.87L11.0249 7.4C11.1249 7.5 11.1249 7.66 11.0249 7.755Z" />
  </SVG>
)

export default withIconStyles(IconSaveAs)
