import { staticDropDownData, STUDENT_SCALED_SCORE } from './constants'

export const getAdaptiveReportUrl = ({ studentId = '', ...filters }) => {
  const url = new URL(window.location.href)
  url.pathname = `${STUDENT_SCALED_SCORE.location}${studentId}`
  url.search = `?${new URLSearchParams(filters)}`
  return url
}

export const getUrlFilters = ({
  search = {},
  termOptions = [],
  defaultTermId,
  testTypeOptions = [],
  performanceBandsOptions = [],
}) => {
  const termId = termOptions.find((item) => item.key === search.termId) ||
    termOptions.find((item) => item.key === defaultTermId) ||
    termOptions[0] || { key: '', title: '' }

  const testGrades = staticDropDownData.grades.filter(
    (item) => search.testGrades && search.testGrades?.includes(item.key)
  )
  const subjects = staticDropDownData.subjects.filter(
    (item) => search.subjects && search.subjects?.includes(item.key)
  )
  const grades = staticDropDownData.grades.filter(
    (item) => search.grades && search.grades?.includes(item.key)
  )

  const assessmentTypes = testTypeOptions.filter((testType) =>
    search.assessmentTypes?.includes(testType.key)
  )

  const profileId =
    performanceBandsOptions.find((item) => item.key === search.profileId) ||
    performanceBandsOptions[0] ||
    {}

  const assignedBy =
    staticDropDownData.assignedBy.find(
      ({ key }) => key === search.assignedBy
    ) || staticDropDownData.assignedBy[0]

  return {
    termId,
    grades,
    subjects,
    testGrades,
    assessmentTypes,
    assignedBy,
    profileId,
  }
}

export const getNewPathname = (location) => {
  const splitted = location.pathname.split('/')
  splitted.splice(splitted.length - 1)
  return `${splitted.join('/')}/`
}

export const getUrlStudentId = (location) => {
  const splittedPath = location.pathname.split('/')
  return splittedPath[splittedPath.length - 1]
}
