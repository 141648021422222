/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconGroup = (props) => (
  <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.819 11.8" {...props}>
    <g transform="translate(0 -105.55)">
      <g transform="translate(0 105.55)">
        <g transform="translate(4.935 0)">
          <path
            d="M126.036,114.97a17.556,17.556,0,0,0-3.2-2.051.1.1,0,0,1-.056-.09v-2.166a1.012,1.012,0,0,0,.453-.842v-2.247a2.024,2.024,0,0,0-2.025-2.025h-.483a2.024,2.024,0,0,0-2.025,2.025v2.247a1.006,1.006,0,0,0,.453.842v2.166a.1.1,0,0,1-.056.09,17.812,17.812,0,0,0-3.2,2.051,1.087,1.087,0,0,0-.4.842v1.538h10.933v-1.538A1.087,1.087,0,0,0,126.036,114.97Z"
            transform="translate(-115.5 -105.55)"
          />
        </g>
        <g transform="translate(13.291)">
          <path
            d="M318.282,113.454a14.627,14.627,0,0,0-2.683-1.722.081.081,0,0,1-.047-.077v-1.816a.855.855,0,0,0,.38-.709v-1.884a1.7,1.7,0,0,0-1.7-1.7h-.4a1.7,1.7,0,0,0-1.7,1.7v1.884a.85.85,0,0,0,.38.709v1.816a.085.085,0,0,1-.047.077,14.043,14.043,0,0,0-1.371.778,18.222,18.222,0,0,1,2.717,1.82,1.917,1.917,0,0,1,.666,1.119h4.144v-1.29A.954.954,0,0,0,318.282,113.454Z"
            transform="translate(-311.1 -105.55)"
          />
        </g>
        <g transform="translate(0 0)">
          <path
            d="M6.152,111.732a.081.081,0,0,1-.047-.077v-1.816a.855.855,0,0,0,.38-.709v-1.884a1.7,1.7,0,0,0-1.7-1.7h-.41a1.7,1.7,0,0,0-1.7,1.7v1.884a.85.85,0,0,0,.38.709v1.816a.085.085,0,0,1-.047.077,14.626,14.626,0,0,0-2.683,1.722.92.92,0,0,0-.333.7v1.29H4.144a1.9,1.9,0,0,1,.666-1.119,18.224,18.224,0,0,1,2.717-1.82A14.439,14.439,0,0,0,6.152,111.732Z"
            transform="translate(0 -105.55)"
          />
        </g>
      </g>
    </g>
  </SVG>
)

export default withIconStyles(IconGroup)
