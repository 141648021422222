import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconSectionAdaptiveTest = (props) => (
  <SVG
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle opacity="0.15" cx="21" cy="21" r="21" fill="#025862" />
    <path
      d="M21.8333 29.3334H28.5V23.5001H26V20.1667H21.8333V18.5001H24.3333V12.6667H17.6667V18.5001H20.1667V20.1667H16V23.5001H13.5V29.3334H20.1667V23.5001H17.6667V21.8334H24.3333V23.5001H21.8333V29.3334ZM19.3333 16.8334V14.3334H22.6667V16.8334H19.3333ZM18.5 25.1667V27.6667H15.1667V25.1667H18.5ZM26.8333 25.1667V27.6667H23.5V25.1667H26.8333Z"
      fill="#025862"
    />
  </SVG>
)

export default withIconStyles(IconSectionAdaptiveTest)
