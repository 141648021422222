import styled from 'styled-components'

const Blank = styled.div`
  flex: 1;

  @media (max-width: 768px) {
    display: none;
  }
`

export default Blank
