/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconTranscribe = (props) => (
  <SVG
    width="24"
    height="24"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M10.0003 12.0833C11.3837 12.0833 12.5003 10.9666 12.5003 9.58325V4.58325C12.5003 3.19992 11.3837 2.08325 10.0003 2.08325C8.61699 2.08325 7.50033 3.19992 7.50033 4.58325V9.58325C7.50033 10.9666 8.61699 12.0833 10.0003 12.0833ZM9.16699 4.58325C9.16699 4.12492 9.54199 3.74992 10.0003 3.74992C10.4587 3.74992 10.8337 4.12492 10.8337 4.58325V9.58325C10.8337 10.0416 10.4587 10.4166 10.0003 10.4166C9.54199 10.4166 9.16699 10.0416 9.16699 9.58325V4.58325ZM14.167 9.58325C14.167 11.8833 12.3003 13.7499 10.0003 13.7499C7.70033 13.7499 5.83366 11.8833 5.83366 9.58325H4.16699C4.16699 12.5249 6.34199 14.9416 9.16699 15.3499V17.9166H10.8337V15.3499C13.6587 14.9416 15.8337 12.5249 15.8337 9.58325H14.167Z" />
  </SVG>
)

export default withIconStyles(IconTranscribe)
