import React, { useRef } from 'react'
import { connect } from 'react-redux'
import {
  EduButton,
  CustomModalStyled,
  TextInputStyled,
} from '@edulastic/common'
import { Icon } from 'antd'
import {
  ModalContent,
  ModalHeader,
  ModalHeaderTitle,
  CloseIcon,
  ModalFooterContainer,
} from './styled'
import { getTestSelector, setTestDataAction } from '../../ducks'
import { DEFAULT_TEST_TITLE } from '../../utils'
import { StyledInfoMessage } from '../GroupItems/styled'
import { getDeleteTestStateSelector } from '../../../TestList/ducks'
import {
  testNameChangeModalTitleDataTestId,
  deleteAndExitButtonDataTestId,
  continueOrSaveButtonDataTestId,
} from './constants'

const TestNameChangeModal = ({
  visible,
  closeModal,
  handleResponse,
  test,
  setData,
  showSaveTitle,
  testNameSaving,
  handleDeleteTestAndExit,
  deletingTest,
}) => {
  const { title: testName } = test
  const inputRef = useRef(null)
  const isDeleteTestInProgress = deletingTest === 'INPROGRESS'

  const Footer = [
    <ModalFooterContainer>
      {!!showSaveTitle && (
        <EduButton
          isGhost
          onClick={handleDeleteTestAndExit}
          height="36px"
          fontSize="14px"
          data-cy="dontSaveAndExitTest"
          loading={isDeleteTestInProgress}
          style={{ textTransform: 'none' }}
          data-testid={deleteAndExitButtonDataTestId}
        >
          Don&apos;t Save and Exit
        </EduButton>
      )}

      <EduButton
        onClick={() => {
          if (
            !testName?.trim() ||
            testName?.trim().toLowerCase() === DEFAULT_TEST_TITLE.toLowerCase()
          ) {
            return
          }
          handleResponse()
        }}
        height="36px"
        width="124px"
        fontSize="14px"
        data-cy="continue"
        style={{ textTransform: 'none' }}
        loading={testNameSaving}
        disabled={
          !testName?.trim() ||
          testName?.trim().toLowerCase() === DEFAULT_TEST_TITLE.toLowerCase()
        }
        data-testid={continueOrSaveButtonDataTestId}
      >
        {showSaveTitle ? 'Save' : 'Continue'}
      </EduButton>
    </ModalFooterContainer>,
  ]

  return (
    <CustomModalStyled
      centered
      closable={false}
      textAlign="left"
      visible={visible}
      footer={Footer}
      bodyPadding="0px"
      onCancel={closeModal}
      modalWidth="450px"
      modalMaxWidth="450px"
      padding="32px"
      zIndex="2000"
    >
      <ModalHeader>
        <ModalHeaderTitle data-cy="testPopUpTitle">
          <span data-testid={testNameChangeModalTitleDataTestId}>
            {showSaveTitle ? 'Save' : 'Enter'} Test Name
          </span>
        </ModalHeaderTitle>
        <EduButton
          IconBtn
          isGhost
          width="24px"
          height="24px"
          onClick={closeModal}
          title="Close"
          noHover
          noBorder
          style={{ 'box-shadow': 'none' }}
        >
          <CloseIcon width={14} height={14} />
        </EduButton>
      </ModalHeader>
      <ModalContent>
        <TextInputStyled
          showArrow
          inputRef={inputRef}
          value={testName}
          onChange={(e) => setData({ ...test, title: e.target.value })}
          data-cy="testname-modal"
          size="large"
          placeholder="Enter name here"
          margin="0px"
          width="100%"
          height="40px"
          fontSize="14px"
          bg="transparent"
          onFocus={(event) => {
            event.target.select()
          }}
          style={{ fontWeight: '400' }}
        />
        <StyledInfoMessage>
          <Icon type="info-circle" /> Rename the test to continue
        </StyledInfoMessage>
      </ModalContent>
    </CustomModalStyled>
  )
}

export default connect(
  (state) => ({
    test: getTestSelector(state),
    deletingTest: getDeleteTestStateSelector(state),
  }),
  {
    setData: setTestDataAction,
  }
)(TestNameChangeModal)
