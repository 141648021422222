import styled from 'styled-components'

const MainContainer = styled.div`
   {
    width: 100%;
    @media (min-width: 1200px) {
      display: flex;
      flex-direction: column;
    }
  }
`

export default MainContainer
