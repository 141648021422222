import React from 'react'
import { Col } from 'antd'
import { withNamespaces } from '@edulastic/localization'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { AlignSwitchRight, StyledRow, Label } from '../SimpleOptions/styled'
import SettingContainer from './SettingsContainer'
import DetailsTooltip from './DetailsTooltip'
import { isPremiumUserSelector } from '../../../src/selectors/user'

const AssignSectionsSettingsContainer = ({
  assignmentSettings,
  testSettings,
  freezeSettings,
  t: i18Translate,
  overRideSettings,
  tootltipWidth,
  premium,
}) => {
  const { lockSections = testSettings.lockSections } = assignmentSettings

  return (
    <>
      <SettingContainer id="lock-sections-setting">
        <DetailsTooltip
          width={tootltipWidth}
          title="Enable Lock Sections"
          content={i18Translate('lockSectionsSettings.info')}
          premium={premium}
        />
        <StyledRow gutter={16} mb="15px">
          <Col span={10}>
            <Label>LOCK SECTIONS</Label>
          </Col>
          <Col span={14}>
            <AlignSwitchRight
              disabled={freezeSettings || !premium}
              size="small"
              checked={lockSections}
              data-cy="lock-sections"
              onChange={(value) => overRideSettings('lockSections', value)}
            />
          </Col>
        </StyledRow>
      </SettingContainer>
    </>
  )
}

const enhance = compose(
  withNamespaces('author'),
  connect((state) => ({
    premium: isPremiumUserSelector(state),
  }))
)

export default enhance(AssignSectionsSettingsContainer)
