import React from 'react'
import { Modal, Button } from 'antd'
import { themeColor } from '@edulastic/colors'

function RegradeModalForStudent({ loading, onRegradedModalOk }) {
  return (
    <Modal
      visible
      centered
      width={500}
      okButtonProps={{
        style: { background: themeColor },
      }}
      closable={false}
      footer={[
        <Button
          style={{ background: themeColor, color: 'white' }}
          loading={loading}
          onClick={onRegradedModalOk}
        >
          Ok
        </Button>,
      ]}
    >
      The assignment has been modified by Instructor. Please restart the
      assignment.
    </Modal>
  )
}

export default RegradeModalForStudent
