import React from 'react'

import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconWhiteUrlAttachment = ({ fillColor = 'white', ...props }) => (
  <SVG
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.3333 0.666748H7.66659V2.00008H10.3333C11.4333 2.00008 12.3333 2.90008 12.3333 4.00008C12.3333 5.10008 11.4333 6.00008 10.3333 6.00008H7.66659V7.33341H10.3333C12.1733 7.33341 13.6666 5.84008 13.6666 4.00008C13.6666 2.16008 12.1733 0.666748 10.3333 0.666748ZM6.33325 6.00008H3.66659C2.56659 6.00008 1.66659 5.10008 1.66659 4.00008C1.66659 2.90008 2.56659 2.00008 3.66659 2.00008H6.33325V0.666748H3.66659C1.82659 0.666748 0.333252 2.16008 0.333252 4.00008C0.333252 5.84008 1.82659 7.33341 3.66659 7.33341H6.33325V6.00008ZM4.33325 3.33341H9.66659V4.66675H4.33325V3.33341Z"
      fill={fillColor}
    />
  </SVG>
)

export default withIconStyles(IconWhiteUrlAttachment)
