import { getMathHtml } from '@edulastic/common'

export const getMathHtmlFromSegmentedString = (segmentedMathString = '') => {
  return segmentedMathString
    .split('`')
    .map((optionSegment, optionSegmentIndex, optionSegments) =>
      optionSegmentIndex % 2 !== 0 &&
      optionSegmentIndex < optionSegments.length - 1
        ? getMathHtml(optionSegment)
        : optionSegment
    )
    .join('')
}
