import React, {
  Fragment,
  useMemo,
  useState,
  useContext,
  useEffect,
} from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { arrayMove } from 'react-sortable-hoc'
import { get, isEmpty, sortBy, keys } from 'lodash'
import styled, { withTheme } from 'styled-components'
import produce from 'immer'
import { withNamespaces } from '@edulastic/localization'
import {
  QuestionNumberLabel,
  ScrollContext,
  FlexContainer,
  QuestionLabelWrapper,
  QuestionSubLabel,
  QuestionContentWrapper,
} from '@edulastic/common'

import CorrectAnswers from '../../components/CorrectAnswers'
import QuillSortableList from '../../components/QuillSortableList'
import { QuestionHeader } from '../../styled/QuestionHeader'

import { setQuestionDataAction } from '../../../author/QuestionEditor/ducks'
import {
  EDIT,
  PREVIEW,
  SHOW,
  CLEAR,
  CHECK,
} from '../../constants/constantsForQuestions'
import OrderListPreview from './components/OrderListPreview'
import Options from './components/Options'
import ShowAnswers from './components/ShowAnswers'
import { getFontSize, getStemNumeration } from '../../utils/helpers'
import { replaceVariables, updateVariables } from '../../utils/variables'
import { ContentArea } from '../../styled/ContentArea'
import Instructions from '../../components/Instructions'
import ComposeQuestion from './ComposeQuestion'
import ListComponent from './ListComponent'
import { StyledPaperWrapper } from '../../styled/Widget'
import Question from '../../components/Question'
import QuestionMetadata from '../../containers/QuestionMetadata'

const EmptyWrapper = styled.div``

const QuestionTitleWrapper = styled.div`
  display: flex;
`

const OptionsContainer = styled.div`
  overflow-x: auto;
  width: 100%;
  .orderlist-set-correct-answer {
    ${({ styleType }) =>
      styleType === 'inline' &&
      `
      display: flex;
      overflow-x: auto;
    `}
    .sortable-item-container {
      ${({ styleType }) =>
        styleType === 'inline' &&
        `
        flex: 1;
        min-width: 200px;
        max-width: 400px;
      `}
    }
  }
`

const convertArrToObj = (arr) =>
  arr.reduce((acc, curr, currIndex) => ({ ...acc, [curr]: currIndex }), {})

const convertObjToArr = (obj) => {
  let arr = keys(obj).map((key) => ({ id: key, index: obj[key] }))
  arr = sortBy(arr, (ite) => ite.index)
  return arr
}

const OrderList = ({
  qIndex,
  view,
  previewTab,
  smallSize,
  item,
  userAnswer: _userAnswer,
  testItem,
  evaluation,
  setQuestionData,
  saveAnswer,
  showQuestionNumber,
  advancedAreOpen,
  fillSections,
  cleanSections,
  disableResponse,
  t,
  viewComponent,
  changePreviewTab,
  advancedLink,
  isReviewTab,
  hideCorrectAnswer,
  isPrintPreview,
  showAnswerScore,
}) => {
  const [correctTab, setCorrectTab] = useState(0)
  const scrollContext = useContext(ScrollContext)
  const scrollContainer = scrollContext.getScrollElement()

  const options = get(item, 'list', {})
  const fontSize = getFontSize(get(item, 'uiStyle.fontsize', 'normal'))
  const uiStyle = get(item, 'uiStyle', {})
  const styleType = get(item, 'uiStyle.type', 'button')
  const axis = styleType === 'inline' ? 'x' : 'y'
  const columns = styleType === 'inline' ? 3 : 1
  const Wrapper = testItem ? EmptyWrapper : StyledPaperWrapper

  const validResponse = get(item, 'validation.validResponse', { value: {} })
  const altResponses = get(item, `validation.altResponses[${correctTab - 1}]`, {
    value: [],
  })
  let answersToEdit =
    correctTab === 0 ? validResponse.value : altResponses.value
  answersToEdit = convertObjToArr(answersToEdit)

  const handleCorrectSortEnd = ({ oldIndex, newIndex }) => {
    let newCorrectAnswer = arrayMove(answersToEdit, oldIndex, newIndex)
    newCorrectAnswer = newCorrectAnswer.reduce(
      (acc, curr, currIndex) => ({ ...acc, [curr.id]: currIndex }),
      {}
    )
    setQuestionData(
      produce(item, (draft) => {
        if (correctTab === 0) {
          draft.validation.validResponse.value = newCorrectAnswer
        } else {
          draft.validation.altResponses[correctTab - 1].value = newCorrectAnswer
        }
      })
    )
  }

  const handleUpdatePoints = (score) => {
    if (score < 0) {
      return
    }
    const points = parseFloat(score, 10)
    setQuestionData(
      produce(item, (draft) => {
        if (correctTab === 0) {
          draft.validation.validResponse.score = points
        } else {
          draft.validation.altResponses[correctTab - 1].score = points
        }
        updateVariables(draft)
      })
    )
  }

  const handleAddAltResponse = () => {
    setQuestionData(
      produce(item, (draft) => {
        if (!draft.validation.altResponses) {
          draft.validation.altResponses = []
        }
        draft.validation.altResponses.push({
          score: draft?.validation?.validResponse?.score,
          value: keys(draft.list).reduce(
            (acc, curr, currIndex) => ({ ...acc, [curr]: currIndex }),
            {}
          ),
        })

        setCorrectTab(correctTab + 1)
      })
    )
  }

  const handleDeleteAltAnswers = (index) => {
    setQuestionData(
      produce(item, (draft) => {
        draft.validation.altResponses.splice(index, 1)

        setCorrectTab(0)
        updateVariables(draft)
      })
    )
  }

  // providing props width with value 230px same as min-width provided in PointsInput
  // fixes the issue with PointsInput taking full width
  const renderOptions = view === EDIT && (
    <OptionsContainer styleType={styleType}>
      <QuillSortableList
        item={item}
        fontSize={fontSize}
        width="230px"
        // placement={{ position: "absolute", top: "70px" }}
        axis={axis}
        data-cy="match-option-list"
        prefix="options2"
        readOnly
        items={answersToEdit.map((ite) => options[ite.id])}
        onSortEnd={handleCorrectSortEnd} // here
        useDragHandle
        columns={columns}
        styleType={styleType}
        lockToContainerEdges
        useWindowAsScrollContainer
        lockOffset={['10%', '10%']}
        lockAxis={uiStyle.type === 'inline' ? 'x' : 'y'}
        canDelete={false}
        className="orderlist-set-correct-answer"
      />
    </OptionsContainer>
  )

  const onTabChange = (index = 0) => {
    setCorrectTab(index)
  }

  if (!item) return null
  // ------------------ Item Preivew Start ------------------ //
  const itemForPreview = useMemo(() => replaceVariables(item), [item])

  /**
   * _userAnswer contains user response and else part will run when there is no response
   * else part is required to show order list if in LCB or Preview tab
   * */
  const userAnswer = !isEmpty(_userAnswer)
    ? _userAnswer
    : convertArrToObj(keys(get(item, 'list', {})))
  const userAnswerToShow = keys(userAnswer)

  const onSortPreviewEnd = ({ oldIndex, newIndex }) => {
    const newUserAnswer = convertArrToObj(
      arrayMove(userAnswerToShow, oldIndex, newIndex)
    )
    changePreviewTab()
    saveAnswer(newUserAnswer)
  }

  /**
   * On load initial answer should be set
   * Orderlist can not be skipped if it has initial answers set
   *
   * Checking for changes in view=preview/edit and previewTab=clear/show/check
   */
  useEffect(() => {
    if (isEmpty(_userAnswer) && view === PREVIEW) {
      onSortPreviewEnd({ oldIndex: 0, newIndex: 0 }) // Setting initial answer set by the author
    }
  }, [view, previewTab])

  const evaluationForCheckAnswer =
    evaluation ||
    (item && item.activity ? item.activity.evaluation : evaluation)

  const useWindowAsScrollContainer = viewComponent === 'editQuestion'
  const autoScrollProps = useWindowAsScrollContainer
    ? { useWindowAsScrollContainer }
    : {
        getContainer: () => scrollContainer,
      }

  const previewProps = {
    smallSize,
    listStyle: { fontSize },
    columns,
    uiStyle,
    styleType,
    disableResponse,
    getStemNumeration,
    onSortEnd: onSortPreviewEnd,
    axis,
    helperClass: 'sortableHelper',
    lockToContainerEdges: true,
    lockOffset: ['10%', '10%'],
    lockAxis: uiStyle.type === 'inline' ? 'x' : 'y',
    options: itemForPreview.list || {},
    isPrintPreview,
    ...autoScrollProps,
  }
  // ------------------ Item Preivew End ------------------ //

  return (
    <>
      {view === EDIT && (
        <ContentArea columns={columns}>
          <ComposeQuestion
            item={item}
            fillSections={fillSections}
            cleanSections={cleanSections}
          />
          <ListComponent
            item={item}
            fillSections={fillSections}
            cleanSections={cleanSections}
          />

          <Question
            section="main"
            label={t('component.orderlist.setcorrectanswers')}
            fillSections={fillSections}
            cleanSections={cleanSections}
          >
            <CorrectAnswers
              onTabChange={onTabChange}
              correctTab={correctTab}
              onAdd={handleAddAltResponse}
              validation={item.validation}
              options={renderOptions}
              onCloseTab={handleDeleteAltAnswers}
              fillSections={fillSections}
              cleanSections={cleanSections}
              questionType={item?.title}
              points={
                correctTab === 0 ? validResponse.score : altResponses.score
              }
              onChangePoints={handleUpdatePoints}
              isCorrectAnsTab={correctTab === 0}
            />
          </Question>

          <QuestionMetadata isEditView />

          {advancedLink}

          <Options
            advancedAreOpen={advancedAreOpen}
            fillSections={fillSections}
            cleanSections={cleanSections}
            item={item}
          />
        </ContentArea>
      )}
      {view === PREVIEW && scrollContainer && (
        <Wrapper>
          <FlexContainer justifyContent="flex-start" alignItems="baseline">
            <QuestionLabelWrapper>
              {showQuestionNumber && (
                <QuestionNumberLabel>{item.qLabel}</QuestionNumberLabel>
              )}
              {item.qSubLabel && (
                <QuestionSubLabel>({item.qSubLabel})</QuestionSubLabel>
              )}
            </QuestionLabelWrapper>

            <QuestionContentWrapper showQuestionNumber={showQuestionNumber}>
              <QuestionTitleWrapper data-cy="questionTitleWrapper">
                <QuestionHeader
                  qIndex={qIndex}
                  smallSize={smallSize}
                  padding="0px"
                  dangerouslySetInnerHTML={{ __html: itemForPreview.stimulus }}
                />
              </QuestionTitleWrapper>
              {previewTab === CLEAR && (
                <OrderListPreview
                  {...previewProps}
                  questions={userAnswerToShow}
                />
              )}

              {(previewTab === CHECK || previewTab === SHOW) && (
                <OrderListPreview
                  {...previewProps}
                  evaluation={evaluationForCheckAnswer}
                  questions={userAnswerToShow}
                />
              )}

              {view !== EDIT && <Instructions item={item} />}
              {(previewTab === SHOW || isReviewTab) && !hideCorrectAnswer ? (
                <ShowAnswers
                  smallSize={smallSize}
                  uiStyle={uiStyle}
                  options={itemForPreview.list}
                  validation={itemForPreview?.validation}
                  isPrintPreview={isPrintPreview}
                  showAnswerScore={showAnswerScore}
                />
              ) : null}
            </QuestionContentWrapper>
          </FlexContainer>
        </Wrapper>
      )}
    </>
  )
}

OrderList.propTypes = {
  view: PropTypes.string.isRequired,
  previewTab: PropTypes.string,
  smallSize: PropTypes.bool,
  item: PropTypes.object,
  setQuestionData: PropTypes.func.isRequired,
  saveAnswer: PropTypes.func.isRequired,
  userAnswer: PropTypes.any.isRequired,
  testItem: PropTypes.bool,
  qIndex: PropTypes.any.isRequired,
  evaluation: PropTypes.any,
  fillSections: PropTypes.func,
  cleanSections: PropTypes.func,
  advancedAreOpen: PropTypes.bool,
  showQuestionNumber: PropTypes.bool,
  theme: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  disableResponse: PropTypes.bool,
  advancedLink: PropTypes.any,
  isReviewTab: PropTypes.bool,
}

OrderList.defaultProps = {
  previewTab: CLEAR,
  smallSize: false,
  item: {},
  testItem: false,
  evaluation: '',
  advancedLink: null,
  advancedAreOpen: false,
  fillSections: () => {},
  cleanSections: () => {},
  showQuestionNumber: false,
  disableResponse: false,
  isReviewTab: false,
}

const enhance = compose(
  withNamespaces('assessment'),
  withTheme,
  connect(null, { setQuestionData: setQuestionDataAction })
)

const OrderListContainer = enhance(OrderList)

export { OrderListContainer as OrderList }
