import React, { useEffect, useState, useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { segmentApi } from '@edulastic/api'
import loadable from '@loadable/component'
import { difference, map } from 'lodash'

// components
import { MainContentWrapper, withWindowSizes } from '@edulastic/common'

import Launch from '../../../LaunchHangout/Launch'

// ducks
import {
  slice,
  trialPeriodTextSelector,
} from '../../../../../Subscription/ducks'
import { getInterestedSubjectsSelector } from '../../../../../src/selectors/user'
import { getDictCurriculumsAction } from '../../../../../src/actions/dictionaries'
import {
  fetchDashboardTiles,
  getNewDashboardActiveTab,
  receiveTeacherDashboardAction,
  setNewDashboardActiveTabAction,
} from '../../../../ducks'
import { getUserDetails } from '../../../../../../student/Login/ducks'
import { FeatureBannerPlaceholder } from '../../../../../FeatureBanner'
import { productsMetaData } from '../../../../../src/components/common/PurchaseModals/ProductsMetaData'
import DashboardSubHeader from '../../../DashboardSubHeader'
import { DashboardContentContainer } from '../../../styled'
import InterestedStandardsInputCard from '../Myclasses/components/InterestedStandardsInputCard'
import PurchaseFlowModals from '../../../../../src/components/common/PurchaseModals'
import CreateTestBanner from '../CreateTestBanner'
import TestCreationBanner from '../../../TestCreationBanner'
import { TAB_KEYS } from '../../../../constants'

const TrialModal = loadable(() => import('../Myclasses/components/TrialModal'))

const NewDashboard = ({
  getTeacherDashboard,
  getDictCurriculums,
  getDashboardTiles,
  user,
  showHeaderTrialModal,
  setShowHeaderTrialModal,
  startTrialAction,
  isPremiumTrialUsed,
  products,
  displayText,
  interestedSubjects,
  itemBankSubscriptions = [],
  usedTrialItemBankIds = [],
  activeTab,
  setActiveTab,
}) => {
  const [trialAddOnProductIds, setTrialAddOnProductIds] = useState([])
  const [showTrialSubsConfirmation, setShowTrialSubsConfirmation] = useState(
    false
  )
  const [showSubscriptionAddonModal, setShowSubscriptionAddonModal] = useState(
    false
  )
  const isPremiumUser = user?.features?.premium
  const isCpm =
    user?.utm_source?.toLowerCase()?.includes('cpm') ||
    user?.referrer?.toLowerCase()?.includes('cpm')

  useEffect(() => {
    getTeacherDashboard()
    getDictCurriculums()
    getDashboardTiles()
  }, [])

  const { productItemBankIds = [] } = useMemo(() => {
    if (products) {
      const itemBankProducts = products.filter(({ type }) => type !== 'PREMIUM')
      return {
        productItemBankIds: itemBankProducts.map(
          ({ linkedProductId }) => linkedProductId
        ),
      }
    }
    return {}
  }, [products])

  const paidItemBankIds = useMemo(() => {
    if (!itemBankSubscriptions) {
      return []
    }

    return itemBankSubscriptions
      .filter(
        (subscription) =>
          // only include the itembanks which are sold as products
          !subscription.isTrial &&
          productItemBankIds.includes(subscription.itemBankId)
      )
      .map((subscription) => subscription.itemBankId)
  }, [itemBankSubscriptions])

  const productsToShowInTrialModal = useMemo(() => {
    // if the product has paid subscription or the trial is used then its not available for trial.
    const allAvailableTrialItemBankIds = difference(productItemBankIds, [
      ...paidItemBankIds,
      ...usedTrialItemBankIds,
    ])

    const subjects = interestedSubjects.map((x) => x.toUpperCase())

    const getProductsKeysByInterestedSubject = Object.entries(
      productsMetaData
    ).reduce((a, [_key, _value]) => {
      if (subjects.includes(_value.filters)) {
        return a.concat(_key)
      }
      return a
    }, [])

    const allAvailableItemProductIds = map(
      products.filter(
        (product) =>
          allAvailableTrialItemBankIds.includes(product.linkedProductId) &&
          getProductsKeysByInterestedSubject.includes(product.name)
      ),
      'id'
    )

    return allAvailableItemProductIds
  }, [itemBankSubscriptions, products, showHeaderTrialModal])

  const handleTabChange = (key) => {
    setActiveTab(key)
    if (key === TAB_KEYS.MY_CLASSES) {
      segmentApi.genericEventTrack('Dashboard:Classes:SwitchTo')
    }
    if (key === TAB_KEYS.ASSIGNMENTS) {
      segmentApi.genericEventTrack('Dashboard:Assignment:SwitchTo')
    }
  }

  return (
    <MainContentWrapper padding="0px">
      <TestCreationBanner />
      <DashboardContentContainer $backgroundColor>
        <InterestedStandardsInputCard>
          <FeatureBannerPlaceholder placeholderId="dashboard" />
        </InterestedStandardsInputCard>
        <DashboardSubHeader
          activeTab={activeTab}
          handleTabChange={handleTabChange}
        />
      </DashboardContentContainer>
      <DashboardContentContainer>
        <CreateTestBanner />
      </DashboardContentContainer>
      <Launch />
      {showHeaderTrialModal && (
        <TrialModal
          addOnProductIds={productsToShowInTrialModal}
          isVisible={showHeaderTrialModal}
          toggleModal={() => {}}
          isPremiumUser={isPremiumUser}
          isPremiumTrialUsed={isPremiumTrialUsed}
          setShowTrialSubsConfirmation={setShowTrialSubsConfirmation}
          startPremiumTrial={startTrialAction}
          products={products}
          setShowHeaderTrialModal={setShowHeaderTrialModal}
          setTrialAddOnProductIds={setTrialAddOnProductIds}
          displayText={displayText}
        />
      )}
      <PurchaseFlowModals
        showSubscriptionAddonModal={showSubscriptionAddonModal}
        setShowSubscriptionAddonModal={setShowSubscriptionAddonModal}
        isConfirmationModalVisible={showTrialSubsConfirmation}
        setShowTrialSubsConfirmation={setShowTrialSubsConfirmation}
        defaultSelectedProductIds={[]}
        setProductData={() => {}}
        trialAddOnProductIds={trialAddOnProductIds}
        clickedBundleId={null}
        setClickedBundleId={() => {}}
        isCpm={isCpm}
        interestedSubjects={interestedSubjects}
      />
    </MainContentWrapper>
  )
}

export default compose(
  withWindowSizes,
  withRouter,
  connect(
    (state) => ({
      user: getUserDetails(state),
      showHeaderTrialModal: state.subscription?.showHeaderTrialModal,
      isPremiumTrialUsed:
        state.subscription?.subscriptionData?.isPremiumTrialUsed,
      products: state.subscription?.products,
      displayText: trialPeriodTextSelector(state),
      interestedSubjects: getInterestedSubjectsSelector(state),
      itemBankSubscriptions:
        state.subscription?.subscriptionData?.itemBankSubscriptions,
      usedTrialItemBankIds:
        state.subscription?.subscriptionData?.usedTrialItemBankIds,
      activeTab: getNewDashboardActiveTab(state),
    }),
    {
      getDictCurriculums: getDictCurriculumsAction,
      getTeacherDashboard: receiveTeacherDashboardAction,
      getDashboardTiles: fetchDashboardTiles,
      setShowHeaderTrialModal: slice.actions.setShowHeaderTrialModal,
      startTrialAction: slice.actions.startTrialAction,
      setActiveTab: setNewDashboardActiveTabAction,
    }
  )
)(NewDashboard)
