import React from 'react'
import withIconStyles from '@edulastic/icons/src/HOC/withIconStyles'
import SVG from '@edulastic/icons/src/common/SVG'

const IconGraphPolynom = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="59.118"
    height="19"
    viewBox="0 0 59.118 19"
    {...props}
  >
    <g transform="translate(-404.8 -410.054)">
      <path d="M409.527,424.624l-.356-.915h-.048a2.715,2.715,0,0,1-.955.81,3.067,3.067,0,0,1-1.28.226,2.071,2.071,0,0,1-1.527-.554,2.129,2.129,0,0,1-.556-1.582,1.777,1.777,0,0,1,.75-1.581,4.341,4.341,0,0,1,2.261-.563l1.168-.036v-.3a.916.916,0,0,0-.8-1.023.935.935,0,0,0-.248,0,4.774,4.774,0,0,0-1.9.492l-.61-1.241a5.486,5.486,0,0,1,2.578-.608,3.211,3.211,0,0,1,2.078.59,2.181,2.181,0,0,1,.723,1.795v4.487Zm-.543-3.115-.711.024a2.285,2.285,0,0,0-1.192.289.907.907,0,0,0-.391.807q0,.777.891.777a1.409,1.409,0,0,0,1.021-.367,1.293,1.293,0,0,0,.382-.976Zm5.1-.319-2.168-3.3h2.084l1.307,2.144,1.319-2.144H418.7l-2.192,3.294,2.294,3.439h-2.09l-1.415-2.307-1.421,2.307H411.8Z" />
      <path d="M422.018,416.007h-.706v1.217H420.1v-1.217h-2.505v-.863l2.573-3.791h1.145v3.69h.707Zm-1.919-.964v-1c0-.166.006-.407.02-.723s.024-.5.032-.55h-.032a4.466,4.466,0,0,1-.357.642l-1.076,1.631Z" />
      <g>
        <text
          transform="translate(419.988 425.054)"
          fontSize="12"
          fontFamily="OpenSans-Bold, Open Sans"
          fontWeight="700"
        >
          <tspan x="0" y="0">
            +
          </tspan>
        </text>
      </g>
      <g>
        <text
          transform="translate(445.641 425.054)"
          fontSize="12"
          fontFamily="OpenSans-Bold, Open Sans"
          fontWeight="700"
        >
          <tspan x="0" y="0">
            +
          </tspan>
        </text>
      </g>
      <path d="M433.3,417.764a2.18,2.18,0,0,1,1.867.93,4.273,4.273,0,0,1,.674,2.555,4.2,4.2,0,0,1-.7,2.584,2.43,2.43,0,0,1-3.755.054h-.122l-.307.741h-1.4v-9.379h1.837v2.18q0,.417-.072,1.331h.072A2.124,2.124,0,0,1,433.3,417.764Zm-.59,1.469a1.161,1.161,0,0,0-.994.416,2.4,2.4,0,0,0-.325,1.382v.2a2.834,2.834,0,0,0,.322,1.554,1.148,1.148,0,0,0,1.021.47,1.023,1.023,0,0,0,.9-.521,2.823,2.823,0,0,0,.33-1.513,2.676,2.676,0,0,0-.337-1.491A1.058,1.058,0,0,0,432.706,419.233Zm6.028,1.952-2.168-3.295h2.084l1.31,2.144,1.316-2.144h2.084l-2.193,3.295,2.293,3.438h-2.088l-1.412-2.306-1.425,2.306h-2.083Z" />
      <path d="M446.134,412.666a1.384,1.384,0,0,1-.333.935,1.729,1.729,0,0,1-.935.53v.024a1.871,1.871,0,0,1,1.076.432,1.209,1.209,0,0,1,.365.923,1.57,1.57,0,0,1-.614,1.321,2.829,2.829,0,0,1-1.755.472,4.255,4.255,0,0,1-1.694-.317V415.93a3.794,3.794,0,0,0,.751.281,3.169,3.169,0,0,0,.811.108,1.578,1.578,0,0,0,.911-.209.765.765,0,0,0,.293-.67.607.607,0,0,0-.337-.586,2.5,2.5,0,0,0-1.076-.173h-.45v-.951h.454a2.109,2.109,0,0,0,1-.179.647.647,0,0,0,.316-.612q0-.666-.835-.666a1.925,1.925,0,0,0-.588.1,2.889,2.889,0,0,0-.664.333l-.574-.855a3.2,3.2,0,0,1,1.911-.582,2.481,2.481,0,0,1,1.439.369A1.187,1.187,0,0,1,446.134,412.666Z" />
      <g>
        <text
          transform="translate(452.918 423.054)"
          fontSize="12"
          fontFamily="OpenSans-Bold, Open Sans"
          fontWeight="700"
        >
          <tspan x="0" y="0">
            ...
          </tspan>
        </text>
      </g>
    </g>
  </SVG>
)

export default withIconStyles(IconGraphPolynom)
