import styled from 'styled-components'
import { white } from '@edulastic/colors'
import { IconInfo } from '@edulastic/icons'

export const Container = styled.div`
  padding: ${(props) => (props.sectionReOrder ? '10px' : '20px')};
  background: ${white};
  min-width: ${(props) => (props.sectionReOrder ? '194px' : '150px')};
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`

export const InfoIcon = styled(IconInfo)`
  width: 12px;
  height: 12px;
  margin-top: 2px;
  margin-right: 5px;
`
