import { segmentApi } from '@edulastic/api'
import { Col } from 'antd'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import {
  clearCreatedItemsAction,
  clearTestDataAction,
} from '../../../TestPage/ducks'
import {
  allowedToCreateVideoQuizSelector,
  enableSectionAdaptiveTestSelector,
  getUserFeatures,
  isGcpsDistrictSelector,
  isQTIDistrictSelector,
} from '../../../src/selectors/user'
import EduAIQuiz from '../CreateAITest'
import TestCard from './Components/TestCard'
import CreateGoogleFormQuiz from '../CreateGoogleFormQuiz'
import { HeadingWrapper, SectionWrapper } from './Components/styled'
import { TestKeys, TestSections } from './constants'
import { isAccessAllowed, validateAndUpdateAccessFeatures } from './utils'

const CreationOptions = ({
  isQTIDistrict,
  isGcpsDistrict,
  userFeatures,
  history,
  clearTestData,
  clearCreatedItems,
  isGcpsDistrictExcludingDemoUsers,
  enableSectionAdaptiveTest,
}) => {
  const isTestCardVisible = ({ key }) => {
    if (
      (key === TestKeys.SURVEY_TEST && isGcpsDistrict) || // don't allow survery test for GCPS
      (key === TestKeys.IMPORT_TEST && isGcpsDistrictExcludingDemoUsers) || // dont allow import test for GCPS excluding demo users
      (key === TestKeys.VIDEO_QUIZ_TEST && isGcpsDistrictExcludingDemoUsers) || // dont allow video quiz for GCPS excluding demo users
      (key === TestKeys.QTI_IMPORT_TEST && !isQTIDistrict) || // allow QTI import for enabled district
      (key === TestKeys.DYNAMIC_TEST && !userFeatures?.enableDynamicTests) || // allow dynamic test for enabled users only
      (key === TestKeys.SECTION_ADAPTIVE_TEST && !enableSectionAdaptiveTest) || // allow section adaptive test for enabled users only
      (key === TestKeys.GOOGLE_FORM_TEST && !isGcpsDistrictExcludingDemoUsers) // allow google form test only for GCPS excluding demo users
    ) {
      return false
    }

    return true
  }

  const onTestCardClick = ({ key, navigation, segmentEvent, access }) => {
    if (isAccessAllowed(access, userFeatures)) {
      if (segmentEvent) {
        segmentApi.genericEventTrack(segmentEvent.name, {
          ...segmentEvent.data,
        })
      }
      if (navigation) {
        if (
          [
            TestKeys.DEFAULT_TEST,
            TestKeys.DYNAMIC_TEST,
            TestKeys.SECTION_ADAPTIVE_TEST,
          ].includes(key)
        ) {
          clearTestData()
          clearCreatedItems()
        }
        return history.push(navigation)
      }
    } else {
      const firstFeature = access?.features?.[0]
      if (firstFeature?.navigation) {
        return history.push(firstFeature.navigation)
      }
    }
  }

  return TestSections.map(({ key, title, tests }, index) => {
    const cardItems = tests
      .map((test) => {
        const testKey = test.key

        const cardVisible = isTestCardVisible(test)
        if (test.access?.features) {
          test.access.features = validateAndUpdateAccessFeatures(
            test.access,
            userFeatures
          )
        }

        if (cardVisible) {
          if (testKey === TestKeys.AI_TEST) {
            return (
              <EduAIQuiz>
                <TestCard testKey={testKey} {...test} />
              </EduAIQuiz>
            )
          }
          if (testKey === TestKeys.GOOGLE_FORM_TEST) {
            return (
              <CreateGoogleFormQuiz>
                <TestCard testKey={testKey} {...test} />
              </CreateGoogleFormQuiz>
            )
          }
          return (
            <TestCard
              testKey={testKey}
              onClick={() => onTestCardClick(test)}
              {...test}
            />
          )
        }
        return false
      })
      .filter((item) => !!item) // removing hidden cards

    if (cardItems.length) {
      return (
        <div key={key || index}>
          <HeadingWrapper strong>{title}</HeadingWrapper>
          <SectionWrapper gutter={32}>
            {cardItems.map((cardItem) => (
              <Col key={cardItem.key} xxs={24} md={12} lg={8} xl={6}>
                {cardItem}
              </Col>
            ))}
          </SectionWrapper>
          <br />
        </div>
      )
    }
    return false
  }).filter((item) => !!item)
}

const enhance = compose(
  withRouter,
  connect(
    (state) => ({
      isQTIDistrict: isQTIDistrictSelector(state),
      isGcpsDistrictExcludingDemoUsers: isGcpsDistrictSelector(state, true),
      isGcpsDistrict: isGcpsDistrictSelector(state),
      userFeatures: getUserFeatures(state),
      allowedToCreateVideoQuiz: allowedToCreateVideoQuizSelector(state),
      enableSectionAdaptiveTest: enableSectionAdaptiveTestSelector(state),
    }),
    {
      clearTestData: clearTestDataAction,
      clearCreatedItems: clearCreatedItemsAction,
    }
  )
)

export default enhance(CreationOptions)
