import { createSlice } from 'redux-starter-kit'

import { FILTER_TABS } from '../../common/utils/constants'
import { DEFAULT_FILTERS } from '../utils/constants'
import { RESET_ALL_REPORTS } from '../../../../common/reportsRedux'

const reduxNamespaceKey = 'dwDomainAnalysisReport'

const initialState = {
  firstLoad: true,
  loadingFiltersData: false,
  prevFiltersData: null,
  filtersData: {},
  filtersTabKey: FILTER_TABS.STUDENT_FILTERS.key,
  filters: {
    ...DEFAULT_FILTERS,
  },
  filterTagsData: {},
  settings: {
    requestFilters: {},
    selectedFilterTagsData: {},
  },
  loadingSkillInfo: false,
  loadingSummaryData: false,
  loadingTableData: false,
  skillInfo: [],
  summaryData: {},
  tableData: {},
  filtersDataRequestError: '',
  skillInfoRequestError: false,
  summaryDataRequestError: false,
  tableDataRequestError: false,
  selectedTests: [],
}

const slice = createSlice({
  slice: reduxNamespaceKey,
  initialState: { ...initialState },
  reducers: {
    fetchFiltersDataRequest: (state) => {
      state.loadingFiltersData = true
    },
    fetchFiltersDataRequestSuccess: (state, { payload }) => {
      state.loadingFiltersData = false
      state.filtersData = payload.filtersData
      state.filtersDataRequestError = false
    },
    fetchFiltersDataRequestError: (state, { payload }) => {
      state.loadingFiltersData = false
      state.filtersDataRequestError = payload.error
    },
    fetchSkillInfoRequest: (state) => {
      state.loadingSkillInfo = true
    },
    fetchSkillInfoRequestSuccess: (state, { payload }) => {
      state.loadingSkillInfo = false
      state.skillInfo = payload.skillInfo
      state.skillInfoRequestError = false
    },
    fetchSkillInfoRequestError: (state, { payload }) => {
      state.loadingSkillInfo = false
      state.skillInfo = []
      state.skillInfoRequestError = payload.error
    },
    fetchSummaryDataRequest: (state) => {
      state.loadingSummaryData = true
    },
    fetchSummaryDataRequestSuccess: (state, { payload }) => {
      state.loadingSummaryData = false
      state.summaryData = payload.summaryData
      state.summaryDataRequestError = false
    },
    fetchSummaryDataRequestError: (state, { payload }) => {
      state.loadingSummaryData = false
      state.summaryData = {}
      state.summaryDataRequestError = payload.error
    },
    fetchTableDataRequest: (state) => {
      state.loadingTableData = true
    },
    fetchTableDataRequestSuccess: (state, { payload }) => {
      state.loadingTableData = false
      state.tableData = payload.tableData
      state.tableDataRequestError = false
    },
    fetchTableDataRequestError: (state, { payload }) => {
      state.loadingTableData = false
      state.tableData = {}
      state.tableDataRequestError = payload.error
    },
    setFirstLoad: (state, { payload }) => {
      state.firstLoad = payload
    },
    setFiltersTabKey: (state, { payload }) => {
      state.filtersTabKey = payload
    },
    setFilters: (state, { payload }) => {
      state.filters = { ...payload }
    },
    setFilterTagsData: (state, { payload }) => {
      state.filterTagsData = payload
    },
    setSelectedFilterTagsData: (state, { payload }) => {
      state.settings.selectedFilterTagsData = payload
    },
    setPrevFiltersData: (state, { payload }) => {
      state.prevFiltersData = payload
    },
    resetFiltersData: (state) => {
      state.filtersData = {}
    },
    setSettings: (state, { payload }) => {
      state.settings = payload
    },
    setRequestFilters: (state, { payload }) => {
      state.settings.requestFilters = payload
    },
  },
  extraReducers: {
    [RESET_ALL_REPORTS]: () => ({ ...initialState }),
  },
})

const { actions, reducer } = slice

export { reduxNamespaceKey, actions, reducer }
