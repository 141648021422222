export const navigationState = {
  SUBSCRIPTION: {
    view: {
      DATA_STUDIO: 'dataStudio',
      ADDON: 'addon',
    },
  },
}

export const navigationItemLabels = {
  DATA_STUDIO: 'Data Studio',
}
