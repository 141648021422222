import {
  MULTIPLE_CHOICE,
  MULTIPLE_SELECTION,
  TRUE_OR_FALSE,
} from '@edulastic/constants/const/questionType'

export const DOK_OPTIONS = [
  { value: '', label: 'Not specify' },
  { value: 'Factual', label: 'Recall' },
  { value: 'Conceptual', label: 'Skill/Concept' },
  { value: 'Critical thinking', label: 'Strategic Thinking' },
  { value: 'Creative questions', label: 'Extended Thinking' },
]

export const DIFFICULTY_OPTIONS = [
  { value: '', label: 'Any Level' },
  { value: 'Easy', label: 'Easy' },
  { value: 'Medium', label: 'Medium' },
  { value: 'Hard', label: 'Hard' },
]

export const PASSAGE_SIZE = [
  'Short Passage (50-100 words)',
  'Medium Passage (150 - 250 words)',
  'Long Passage (300 - 500 words)',
  'Extended Passage (600 - 1000 words)',
]

export const AI_ERROR_MAP = {
  '""attachments" has no content"':
    'No content could be identified for question generation. Please review your input or try using a different one.',
  '""preference" or "attachments" moderation failed"':
    'Moderation failed for provided question context/attachment. Please review the question context/attachment or try using a different one.',
  '""attachments" moderation failed"':
    'Moderation failed for provided attachment. Please review the attachment or try using a different one.',
}

export const QUESTION_REVISE_OPTIONS = [
  {
    value: 'simplified-language',
    label: 'Simplified Language',
    infoText:
      'Rewrite the text using simpler language suitable for younger students or those with lower reading levels.',
  },
  {
    value: 'improved-clarity',
    label: 'Improved Clarity',
    infoText:
      'Rewrite the text to increase its complexity, making it more challenging for advanced students or higher-grade levels.',
  },
  {
    value: 'custom',
    label: 'Custom Prompt',
    infoText:
      'Provide a custom prompt to generate a revised version of the question',
  },
]

export const QUESTION_ADJUST_DIFFICULTY_OPTIONS = [
  { value: 'EASY', label: 'Easy' },
  { value: 'HARD', label: 'Hard' },
]

export const QUESTION_CONVERT_TYPE_OPTIONS = [
  { value: MULTIPLE_CHOICE, label: 'Multiple Choice Questions' },
  { value: MULTIPLE_SELECTION, label: 'Multiple Selection Questions' },
  { value: TRUE_OR_FALSE, label: 'True/False' },
]

export const AI_QUESTION_UPDATE_TYPES = {
  REVISE: 'REVISE',
  ADJUST_DIFFICULTY: 'ADJUST_DIFFICULTY',
  CONVERT: 'CONVERT',
}

export const AI_QUESTION_DIFFICULTY_LEVEL_VALUE_LABEL_MAP = {
  EASY: 'Easy',
  HARD: 'Hard',
}

export const FEEDBACK_VALUES = {
  HELPFUL: 'HELPFUL',
  NOT_HELPFUL: 'NOT HELPFUL',
}
