import { keyBy as _keyBy, shuffle as _shuffle, isEmpty } from 'lodash'

// This method will mutate each language options and apply value from english to other language.
// Then shuffle other language options in the same order as english
const shuffleLanguageFeaturesOptions = (
  { languageFeatures, options },
  shuffledOptions
) => {
  if (!languageFeatures) return

  const languageKeys = Object.keys(languageFeatures)
  if (!languageKeys.length) return

  languageKeys.forEach((language) => {
    const question = languageFeatures[language]
    if (!question.options) return
    question.options.forEach((option, index) => {
      if (options?.[index]) {
        option.value = options[index].value // apply value in other language options so that we can shuffle in same order
      }
    })
    const optionByValue = _keyBy(question.options, 'value')
    question.options = shuffledOptions.map((id) => optionByValue[id])
  })
}

const shuffleOptions = (questions = [], qActivitiesById = {}) => {
  const optionsOrder = {}

  const modifiedQuestions = questions.map((question) => {
    if (question.type === 'multipleChoice') {
      let { shuffledOptions } = qActivitiesById[question.id] || {}

      if (!shuffledOptions) {
        shuffledOptions = _shuffle(question.options.map((item) => item.value))
      }
      optionsOrder[question.id] = shuffledOptions
      const optionByValue = _keyBy(question.options, 'value')
      shuffleLanguageFeaturesOptions(question, shuffledOptions)
      question.options = shuffledOptions.map((id) => optionByValue[id])
    }
    return question
  })

  return [modifiedQuestions, optionsOrder]
}

export const ShuffleChoices = (testItems, questionActivities) => {
  const qActivitiesById = _keyBy(questionActivities, 'qid')
  let shuffles = {}
  testItems.forEach((item) => {
    let optionsOrder
    ;[item.data.questions, optionsOrder] = shuffleOptions(
      item.data.questions,
      qActivitiesById
    )
    shuffles = {
      ...shuffles,
      ...optionsOrder,
    }
  })

  return [testItems, shuffles]
}

/**
 * input
 * questions: [
 * {
 *  ...restProps,
 *  hints: [{label: "", value: ""}]
 * },
 * {
 *  ...restProps,
 *  hints: [{label: "", value: ""}]
 * }
 * ]
 *
 * output: a number >= 0
 *
 * logic:
 * for all questions, check if there are hints
 * for all hints check if the label is not empty
 * empty label is possible when a user entered something in the hint and then cleared it (obj is not removed)
 *
 * a number > 0 would indicate the current item has hints which have non empty label
 */

//  TODO :  need to remove the object if the hint is cleared

export const showHintButton = (questions) =>
  questions.reduce((acc, question) => {
    if (question.hints) {
      // handling cases when hints are undefined
      acc += question.hints.filter((hint) => hint.label.length > 0).length
    }
    return acc
  }, 0)

/**
 * @param {*} { isSectionAdaptiveTest, baseSectionIdAndIsBaseSectionSubmitted, autoSubmit }
 * This function is called on auto-submit when timer for a section ends,
 * used to determine whether its a section adaptive test and if base/common section
 * of adaptive test is submitted. If not submitted should submit base section
 * and navigate to section start page.
 * @returns a boolean that signifies if base section submitted or not
 */
export const shouldSubmitBaseSectionOnAutosubmitForSectionAdaptiveTest = ({
  isSectionAdaptiveTest = false,
  baseSectionIdAndIsBaseSectionSubmitted = {},
  autoSubmit = false,
}) => {
  const {
    isBaseSectionSubmitted,
    baseSectionId,
  } = baseSectionIdAndIsBaseSectionSubmitted
  if (
    autoSubmit &&
    isSectionAdaptiveTest &&
    !isEmpty(baseSectionIdAndIsBaseSectionSubmitted) &&
    !isEmpty(baseSectionId) &&
    typeof isBaseSectionSubmitted === 'boolean'
  ) {
    return !isBaseSectionSubmitted
  }
  return false
}

/**
 * @param {*} currentPageUrl
 * currentPageUrl - is the current url of the student test attempt.
 * currentPageUrl is of type /student/:assessmentType/:testId/class/:groupId/uta/:utaId/itemId/:itemId
 * This function will return section start page url,
 * that is of type /student/:assessmentType/:testId/class/:groupId/uta/:utaId/sections-start
 * @returns string - section start url
 */
const getSectionStartUrlFromTestAttemptUrl = (currentPageUrl = '') => {
  return `${currentPageUrl.slice(
    0,
    currentPageUrl.indexOf('itemId')
  )}sections-start`
}

/**
 * @param {*} { currentRouterState, currentPageUrl }
 * This function returns the current router state along with other data
 * that signifies that on closing timer end modal user needs to be navigated to section start page
 * and update the necessary router state.
 * @returns routerState object
 */
export const getSectionStartPageRouterStateData = ({
  currentRouterState,
  currentPageUrl,
}) => {
  const sectionsStartPageUrl = getSectionStartUrlFromTestAttemptUrl(
    currentPageUrl
  )
  return {
    ...currentRouterState,
    fromSummary: true,
    isBaseSectionAutoSubmitted: true,
    sectionsStartPageUrl,
  }
}
