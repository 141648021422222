import React from 'react'
import withIconStyles from '@edulastic/icons/src/HOC/withIconStyles'
import SVG from '@edulastic/icons/src/common/SVG'

const IconScriptSuper = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="15.192"
    height="13.248"
    viewBox="0 0 15.192 13.248"
    {...props}
  >
    <g transform="translate(-15.227 -27.752)">
      <path
        d="M5.336,0H2.57L-.086-4.32-2.742,0H-5.336l3.789-5.891-3.547-5.531h2.672L.039-7.312l2.414-4.109H5.063L1.477-5.758Z"
        transform="translate(20.563 41)"
      />
      <path
        d="M2.2-4.248a1.6,1.6,0,0,1,.976.268.879.879,0,0,1,.36.748,1.069,1.069,0,0,1-.188.608,2.385,2.385,0,0,1-.444.5q-.256.216-.7.536a5.844,5.844,0,0,0-.808.64H3.12a1.222,1.222,0,0,0,.324-.032.235.235,0,0,0,.148-.1.714.714,0,0,0,.064-.224h.168q0,.944.032,1.352A9.159,9.159,0,0,0,2.664,0H.336V-.152q.1-.112.408-.384.464-.424.772-.756a3.519,3.519,0,0,0,.536-.768,1.935,1.935,0,0,0,.228-.908.871.871,0,0,0-.188-.62.736.736,0,0,0-.556-.2,1.136,1.136,0,0,0-.6.156,1.612,1.612,0,0,0-.476.46l-.136-.08A2.016,2.016,0,0,1,2.2-4.248Z"
        transform="translate(26.563 32)"
      />
    </g>
  </SVG>
)

export default withIconStyles(IconScriptSuper)
