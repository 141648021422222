import { Pagination } from 'antd'
import React from 'react'
import styled from 'styled-components'

const ReplaceItemsNav = ({ onChange, total, current }) => {
  return (
    <PaginationWrapper>
      <p style={{ marginRight: '10px' }}>Showing similar items</p>{' '}
      <Pagination
        simple
        current={current + 1}
        pageSize={1}
        total={total}
        onChange={onChange}
      />
    </PaginationWrapper>
  )
}

export default ReplaceItemsNav

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background: #eeeeee;
  border-radius: 5px;
  padding: 6px 10px;
  .ant-pagination-simple .ant-pagination-simple-pager input {
    padding: 0;
    margin: -2px;
    width: min-content;
    outline: none;
    pointer-events: none;
    border: none;
    background: transparent;
  }
  .ant-pagination-prev {
    margin-right: 4px;
  }
`
