/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconRedirect = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14.328 12.883"
    {...props}
  >
    <g transform="translate(0.25 -37.78)">
      <g transform="translate(0 38.03)">
        <path
          d="M12.1,79.844h-.563a.271.271,0,0,0-.281.281V82.94A1.411,1.411,0,0,1,9.85,84.347H2.533a1.355,1.355,0,0,1-.994-.413,1.355,1.355,0,0,1-.413-.994V75.623a1.355,1.355,0,0,1,.413-.994,1.355,1.355,0,0,1,.994-.413H6.724a.271.271,0,0,0,.281-.281v-.563a.271.271,0,0,0-.281-.281H2.533a2.44,2.44,0,0,0-1.79.743A2.44,2.44,0,0,0,0,75.623V82.94a2.439,2.439,0,0,0,.743,1.79,2.44,2.44,0,0,0,1.79.743H9.85a2.538,2.538,0,0,0,2.533-2.533V80.126a.271.271,0,0,0-.282-.282Z"
          transform="translate(0 -73.09)"
        />
        <path
          d="M202.832,36.674a.411.411,0,0,0-.3-.127h-3.418a.425.425,0,0,0-.3.728l1.175,1.175L195.635,42.8a.21.21,0,0,0,0,.307l.761.761a.21.21,0,0,0,.307,0l4.353-4.353,1.175,1.175a.427.427,0,0,0,.728-.3V36.974A.411.411,0,0,0,202.832,36.674Z"
          transform="translate(-189.131 -36.5)"
        />
      </g>
    </g>
  </SVG>
)

export default withIconStyles(IconRedirect)
