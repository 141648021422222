import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Title, TitleContainer } from './styled'
import ToggleFavorite from '../../../../components/ToggleFavorite'
import { StyledTooltip } from '../../../../../../../Reports/components/dataWarehouseReport/common/components/StyledComponents'
import { isPremiumUserSelector } from '../../../../../../../src/selectors/user'

const ClassTitle = ({ classInfo, isPremiumUser }) => {
  const link = `/author/manageClass/${classInfo._id}`

  return (
    <TitleContainer>
      <StyledTooltip title={classInfo.name} placement="bottomLeft">
        <Link to={link} data-cy={classInfo.name}>
          <Title ellipsis>{classInfo.name}</Title>
        </Link>
      </StyledTooltip>
      {isPremiumUser && <ToggleFavorite classInfo={classInfo} />}
    </TitleContainer>
  )
}

export default connect((state) => ({
  isPremiumUser: isPremiumUserSelector(state),
}))(ClassTitle)
