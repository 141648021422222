import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconMenuBook = (props) => (
  <SVG
    viewBox="0 0 20 20"
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M17.5006 3.33366C16.5756 3.04199 15.559 2.91699 14.584 2.91699C12.959 2.91699 11.209 3.25033 10.0007 4.16699C8.79232 3.25033 7.04232 2.91699 5.41732 2.91699C3.79232 2.91699 2.04232 3.25033 0.833984 4.16699V16.3753C0.833984 16.5837 1.04232 16.792 1.25065 16.792C1.33398 16.792 1.37565 16.7503 1.45898 16.7503C2.58398 16.2087 4.20898 15.8337 5.41732 15.8337C7.04232 15.8337 8.79232 16.167 10.0007 17.0837C11.1257 16.3753 13.1673 15.8337 14.584 15.8337C15.959 15.8337 17.3757 16.0837 18.5423 16.7087C18.6256 16.7503 18.6673 16.7503 18.7506 16.7503C18.959 16.7503 19.1673 16.542 19.1673 16.3337V4.16699C18.6673 3.79199 18.1256 3.54199 17.5006 3.33366ZM17.5006 14.5837C16.584 14.292 15.584 14.167 14.584 14.167C13.1673 14.167 11.1257 14.7087 10.0007 15.417V5.83366C11.1257 5.12533 13.1673 4.58366 14.584 4.58366C15.584 4.58366 16.584 4.70866 17.5006 5.00033V14.5837Z" />
    <path d="M14.584 7.91699C15.3173 7.91699 16.0257 7.99199 16.6673 8.13366V6.86699C16.009 6.74199 15.3006 6.66699 14.584 6.66699C13.1673 6.66699 11.884 6.90866 10.834 7.35866V8.74199C11.7757 8.20866 13.084 7.91699 14.584 7.91699Z" />
    <path d="M10.834 9.57533V10.9587C11.7757 10.4253 13.084 10.1337 14.584 10.1337C15.3173 10.1337 16.0257 10.2087 16.6673 10.3503V9.08366C16.009 8.95866 15.3006 8.88366 14.584 8.88366C13.1673 8.88366 11.884 9.13366 10.834 9.57533Z" />
    <path d="M14.584 11.1087C13.1673 11.1087 11.884 11.3503 10.834 11.8003V13.1837C11.7757 12.6503 13.084 12.3587 14.584 12.3587C15.3173 12.3587 16.0257 12.4337 16.6673 12.5753V11.3087C16.009 11.1753 15.3006 11.1087 14.584 11.1087Z" />
  </SVG>
)

export default withIconStyles(IconMenuBook)
