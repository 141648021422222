import styled from 'styled-components'
import { CLASS_CONTAINER_MIN_HEIGHT } from '../../styled'

export const EmptyClassContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  height: ${CLASS_CONTAINER_MIN_HEIGHT};
`

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`
