import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import TableHeader from './TableHeader'
import TableView from './TableView'
import { Container } from './styled'
import { TAB_KEYS } from '../../../constants'
import {
  getDashboardAssigmentsAction,
  getNewDashboardActiveTab,
} from '../../../ducks'

const Assignment = ({ activeTab, getDashboardAssigments }) => {
  const [filterState, setFilterState] = useState({
    grades: '',
    classIds: '',
    statuses: '',
  })

  useEffect(() => {
    if (activeTab === TAB_KEYS.MY_CLASSES) {
      return
    }
    getDashboardAssigments(filterState)
  }, [activeTab])
  return (
    <Container>
      <TableHeader filterState={filterState} setFilterState={setFilterState} />
      <TableView filterState={filterState} />
    </Container>
  )
}

const enhance = connect(
  (state) => ({
    activeTab: getNewDashboardActiveTab(state),
  }),
  {
    getDashboardAssigments: getDashboardAssigmentsAction,
  }
)

export default enhance(Assignment)
