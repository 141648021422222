// Classes CONSTANTS
export const myClassFilters = {
  ALL_CLASSES: 'All Classes',
  PRIMARY_TEACHER_CLASSES: 'Primary Teacher Classes',
  MY_FAVORITES: 'My Favorites',
}

/** @type {typeof myClassFilters} */
export const FILTER_TYPES = Object.fromEntries(
  Object.keys(myClassFilters).map((key) => [key, key])
)
/** @type {typeof classView} */
export const classView = {
  GRID: 'GRID',
  LIST: 'LIST',
}

// Classes Functions
export const getAllActiveClasses = (allClasses) =>
  allClasses.filter((c) => c.active === 1 && c.type === 'class')

export const getEmptyClassMessage = (classType) => {
  switch (classType) {
    case FILTER_TYPES.MY_FAVORITES:
      return 'No class marked as favorite'
    case FILTER_TYPES.PRIMARY_TEACHER_CLASSES:
      return 'No Class found'
    default:
      return 'No Class created'
  }
}
