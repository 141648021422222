import { testItemsApi } from '@edulastic/api'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { AI_ERROR_MAP } from '../constants/form'

export const namespaceKey = 'aiQuestionGeneration'

// actions constants
export const GENERATE_AI_QUESTION = `[${namespaceKey}] generate question`
export const SET_AI_QUESTION_STATE = `[${namespaceKey}] set state`
export const RESET_AI_QUESTION_STATE = `[${namespaceKey}] reset state`

// actions
export const generateAIQuestionAction = (payload) => ({
  type: GENERATE_AI_QUESTION,
  payload,
})

export const setAIQuestionStateAction = (payload) => ({
  type: SET_AI_QUESTION_STATE,
  payload,
})

export const resetAIQuestionStateAction = () => ({
  type: RESET_AI_QUESTION_STATE,
})

// selectors
export const stateSelector = (state) => state?.[namespaceKey]

export const getAIQuestionState = createSelector(
  stateSelector,
  (state) => state || {}
)

// initial state
const initialState = {
  apiStatus: null,
  result: null,
  errorMessage: null,
}

// reducer
export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_AI_QUESTION_STATE:
      return {
        ...state,
        ...payload,
      }
    case RESET_AI_QUESTION_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

// sagas
function* generateAIQuestionSaga({ payload }) {
  const generalErrorMessage = 'Something went wrong'

  try {
    yield put(
      setAIQuestionStateAction({
        apiStatus: 'INITIATED',
        result: null,
        errorMessage: null,
      })
    )
    const { result } = yield call(testItemsApi.generateQuestionViaAI, payload)
    yield put(
      setAIQuestionStateAction({
        apiStatus: 'SUCCESS',
        result,
        errorMessage: null,
      })
    )
  } catch (err) {
    const aiErrorMessage = AI_ERROR_MAP?.[err?.message]
    yield put(
      setAIQuestionStateAction({
        apiStatus: 'FAILED',
        exact: !!aiErrorMessage,
        result: null,
        errorMessage: aiErrorMessage || err?.message || generalErrorMessage,
      })
    )
  }
}

export function* watcherSaga() {
  yield all([takeEvery(GENERATE_AI_QUESTION, generateAIQuestionSaga)])
}
