import {
  RadioBtn,
  RadioGrp,
  Select,
  TextAreaInputStyled,
} from '@edulastic/common'
import { Col, Form, Row } from 'antd'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { segmentApi } from '@edulastic/api'
import { getAlignmentDataForAiQuestions } from '../../AssessmentCreate/components/CreateAITest/ducks/helpers'
import { standardsFields } from '../../AssessmentPage/VideoQuiz/constants'
import StandardSet from '../../AssessmentPage/components/QuestionEditModal/common/StandardSet/StandardSet'
import { selectsData } from '../../TestPage/components/common'
import AIContentView from '../common/AIContentView'
import AIFooterAction from '../common/AIFooterAction'
import QuestionView from '../common/QuestionView'
import { StyledForm, StyledStandardSet } from '../common/styled'
import { DIFFICULTY_OPTIONS, DOK_OPTIONS } from '../constants/form'
import {
  generateAIPassageQuestionAction,
  getAIPassageQuestionState,
} from '../ducks/passageQuestionGeneration'
import { getPassagePreference } from '../utils/form'
import PromptSuggestions from '../common/PromptSuggestions'
import {
  PASSAGE_AUTHORING_PROMPTS,
  PROMPT_SUGGESTIONS,
} from '../constants/prompt'

const AIPassageQuestionGeneration = ({
  form,
  aiPassageQuestionState,
  generateAIPassageQuestion,
  data: { passages = [] } = {},
  onFinish,
}) => {
  const [alignmentFieldValue, setAlignmentFieldValue] = useState()
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form
  const { apiStatus, result, requestedData } = aiPassageQuestionState

  const alignment = alignmentFieldValue
    ? getAlignmentDataForAiQuestions(alignmentFieldValue)
    : {}

  const invalidGrades = !alignmentFieldValue?.[0]?.grades?.length
  const invalidSubject = !alignmentFieldValue?.[0]?.subject?.length

  const currentPrompt = PASSAGE_AUTHORING_PROMPTS.QUESTION_GENERATION

  const onSubmit = (e) => {
    e.preventDefault()
    form.validateFieldsAndScroll((err, values) => {
      if (!err && !invalidGrades && !invalidSubject) {
        let requestBody = {
          ...values,
        }

        if (requestBody.difficultLevels) {
          requestBody.difficultLevels = [requestBody.difficultLevels]
        } else if (requestBody.difficultLevels === '') {
          requestBody.difficultLevels = DIFFICULTY_OPTIONS.map(
            ({ value }) => value
          ).filter((value) => !!value)
        }

        requestBody.depthsOfKnowledge = DOK_OPTIONS.map(
          ({ value }) => value
        ).filter((value) => !!value)

        const {
          grades,
          subject,
          standardIds: commonCoreStandards,
          standardSet,
          standardDescriptions: commonCoresStandardDescriptions,
        } = alignment

        requestBody = {
          ...requestBody,
          grades: grades.length
            ? grades
            : selectsData.allGrades.map(({ value }) => value),
          subject,
          standardSet,
          commonCoreStandards,
          commonCoresStandardDescriptions,
          preference: getPassagePreference(passages, requestBody.preference),
        }

        if (requestBody.preference === '') {
          delete requestBody.preference
        }

        if (currentPrompt.trackingEvent) {
          segmentApi.genericEventTrack('GenerateAIItem', {
            source: currentPrompt.trackingEvent.source,
          })
        }

        generateAIPassageQuestion(requestBody)
      }
    })
  }

  const onAlignmentChange = (data) => {
    setAlignmentFieldValue(data.alignment)
  }

  let errorMessage = ''
  if (invalidGrades && invalidSubject) {
    errorMessage = 'Please select grade and subject!'
  } else if (invalidGrades) {
    errorMessage = 'Please select grade!'
  } else if (invalidSubject) {
    errorMessage = 'Please select subject!'
  }

  const isLoading = apiStatus === 'INITIATED'

  return (
    <StyledForm onSubmit={onSubmit}>
      <Form.Item
        validateStatus={errorMessage ? 'error' : 'success'}
        help={errorMessage}
        required
        label="STANDARD"
      >
        <StyledStandardSet>
          <StandardSet
            disabled={isLoading}
            alignment={alignmentFieldValue}
            onUpdate={onAlignmentChange}
            showIconBrowserBtn
            hideLabel
            standardsRequiredFields={[
              standardsFields.SUBJECT,
              standardsFields.GRADES,
            ]}
            isDocBased
            required={false}
            trackingEventSource="AI Passage Item Authoring"
            isAIFlow
          />
        </StyledStandardSet>
      </Form.Item>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="QUESTION TYPE">
            {getFieldDecorator('questionType', {
              rules: [
                {
                  required: true,
                  message: 'Please select question type',
                },
              ],
            })(
              <Select
                disabled={isLoading}
                placeholder="Select"
                dropdownStyle={{ zIndex: 1005 }}
                options={selectsData.allQuestionTypes.map(
                  ({ text, value }) => ({
                    label: text,
                    value,
                  })
                )}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="NUMBER OF QUESTIONS">
            {getFieldDecorator('count', {
              rules: [
                {
                  required: true,
                  message: 'Please select number of questions',
                },
              ],
            })(
              <Select
                disabled={isLoading}
                placeholder="Select"
                dropdownStyle={{ zIndex: 1005 }}
                options={[...Array(10).keys()].map((countIndex) => ({
                  label: (countIndex + 1).toString(),
                  value: countIndex + 1,
                }))}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="DIFFICULTY (OPTIONAL)">
        {getFieldDecorator('difficultLevels', {
          initialValue: '',
        })(
          <RadioGrp disabled={isLoading}>
            {DIFFICULTY_OPTIONS.map(({ value, label }, index) => (
              <RadioBtn key={index} value={value} data-cy={label}>
                {label}
              </RadioBtn>
            ))}
          </RadioGrp>
        )}
      </Form.Item>
      <Form.Item label="PREFERENCE (OPTIONAL)">
        {getFieldDecorator('preference', {
          rules: [
            {
              max: 500,
              message: 'This field should not exceed 500 characters!',
            },
          ],
        })(
          <TextAreaInputStyled
            disabled={isLoading}
            autoSize={{ minRows: 4 }}
            placeholder="Enter details here"
            data-cy="aiPassagePreferenceInput"
          />
        )}
      </Form.Item>
      <PromptSuggestions
        disabled={isLoading}
        currentPrompt={getFieldValue('preference')}
        suggestions={PROMPT_SUGGESTIONS.PASSAGE_QUESTION_PREFERENCE}
        onSelect={(preference) => {
          setFieldsValue({
            preference,
          })
        }}
      />
      <br />
      {apiStatus && (
        <div style={{ marginBottom: '90px' }}>
          <AIContentView
            onRegenerate={onSubmit}
            title="Created by AI Assistant for Passage Item Generation"
            loadingTitle="Generating item"
            content={
              <>
                {result?.map((questionData, index) => {
                  return (
                    <QuestionView
                      questionIndex={index + 1}
                      question={questionData}
                      questionType={requestedData?.questionType}
                    />
                  )
                })}
              </>
            }
            status={apiStatus}
          />
        </div>
      )}
      <AIFooterAction
        status={apiStatus}
        onRegenerate={onSubmit}
        action={{
          label: 'ADD QUESTIONS',
          onClick: () => {
            onFinish({
              questions: result,
              questionType: requestedData?.questionType,
              alignment: alignmentFieldValue?.[0],
            })
          },
        }}
        isLoading={isLoading}
      />
    </StyledForm>
  )
}

const enhance = compose(
  Form.create({ name: 'ai-question-generation' }),
  connect(
    (state) => ({
      aiPassageQuestionState: getAIPassageQuestionState(state),
    }),
    {
      generateAIPassageQuestion: generateAIPassageQuestionAction,
    }
  )
)

export default enhance(AIPassageQuestionGeneration)
