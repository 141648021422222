import React from 'react'

import { Col, Row, Tabs, Tooltip } from 'antd'

import { EduSwitchStyled } from '@edulastic/common'
import { SettingContainer } from '../../../../../../AssignTest/components/Container/styled'
import CalculatorSettings from '../../../../../../Shared/Components/CalculatorSettings'
import DollarPremiumSymbol from '../../../../../../AssignTest/components/Container/DollarPremiumSymbol'
import { Title, Block, Body, Description } from '../styled'

const { TabPane } = Tabs

const ShowSectionSettings = ({
  itemGroups,
  premium,
  disabled,
  isSmallSize,
  preventSectionNavigation,
  lockSections,
  updateSectionCalc,
  updateTestData,
  isSectionAdaptiveTest,
  isNormalSectionsTest,
}) => {
  const sectionTabs = itemGroups.map((g) => {
    const id = g._id
    const values = g?.settings?.calcTypes || []
    return {
      key: id,
      label: `${g.groupName || ''}`,
      children: (
        <SettingContainer>
          <Title>
            Show Calculator <DollarPremiumSymbol premium={premium} />
          </Title>
          <Body smallSize={isSmallSize}>
            <Row>
              <Col span={8} data-cy={`calculatorSelector-${g.groupName}`}>
                <CalculatorSettings
                  isCheckBoxGroup
                  disabled={disabled}
                  value={values}
                  onChange={updateSectionCalc('calcTypes', id)}
                />
              </Col>
            </Row>
          </Body>
        </SettingContainer>
      ),
    }
  })

  return (
    <>
      <Block id="disable-section-navigation" smallSize={isSmallSize}>
        <SettingContainer>
          <Title>
            <span>Prevent Navigation to Submitted Sections</span>
            <Tooltip
              title={
                isSectionAdaptiveTest
                  ? "This setting can't be changed for Adaptive Test"
                  : null
              }
            >
              <EduSwitchStyled
                data-cy="disable-section-navigation"
                checked={preventSectionNavigation}
                disabled={disabled || isSectionAdaptiveTest}
                onChange={() =>
                  updateTestData('preventSectionNavigation')(
                    !preventSectionNavigation
                  )
                }
              />
            </Tooltip>
          </Title>
          <Body smallSize={isSmallSize}>
            <Description data-cy="preventSectionNavigationText">
              Test can be attempted in a sequential manner. Within a section,
              you can navigate between questions using the &apos;previous&apos;
              and &apos;next&apos; options. Once a section is submitted,
              returning to it is not allowed.
            </Description>
          </Body>
        </SettingContainer>
      </Block>

      <Block id="lock-section" smallSize={isSmallSize}>
        <SettingContainer>
          <Title>
            <span>
              Lock Sections <DollarPremiumSymbol premium={premium} />
            </span>
            <Tooltip
              title={
                !isNormalSectionsTest
                  ? "This setting can't be changed for Adaptive and Dynamic Test"
                  : null
              }
            >
              <EduSwitchStyled
                data-cy="lock-sections"
                checked={lockSections}
                disabled={disabled || !isNormalSectionsTest || !premium}
                onChange={() => updateTestData('lockSections')(!lockSections)}
              />
            </Tooltip>
          </Title>
          <Body smallSize={isSmallSize}>
            <Description data-cy="lockSectionsText">
              The Lock Section setting locks all sections except the first.
              Teachers must unlock each subsequent section in the Live Class
              Board (LCB) using the &apos;Open Section&apos; button.
            </Description>
          </Body>
        </SettingContainer>
      </Block>

      {sectionTabs && (
        <Block
          id="section-settings"
          smallSize={isSmallSize}
          data-cy="sectionWiseCalculatorSettings"
        >
          <Tabs>
            {sectionTabs.map((item) => (
              <TabPane tab={item.label} key={item.key}>
                {item.children}
              </TabPane>
            ))}
          </Tabs>
        </Block>
      )}
    </>
  )
}

export default ShowSectionSettings
