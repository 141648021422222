export const testStatusConstants = {
  INREVIEW: 'inreview',
  DRAFT: 'draft',
  PUBLISHED: 'published',
  ARCHIVED: 'archived',
  REJECTED: 'rejected',
}

export const accessLabel = {
  EDIT: 'Edit Access',
  VIEW: 'Clone Access',
  ASSIGN: 'View and Assign Access',
  NOACTION: 'View Only Access',
}

export const contentSharing = {
  VIEW: 'VIEW',
  EDIT: 'EDIT',
  ASSIGN: 'ASSIGN',
  NOACTION: 'NOACTION',
}

export const tooltipText = {
  mergeTestInfo:
    'You can combine or merge items from multiple tests and create a new test.',
  cannotAssignTest:
    'Tests in draft mode cannot be assigned. Please edit and publish the test before assigning it.',
  bucketCloneDisabled:
    'Clone test is not enabled for this test collection bucket.',
  bucketDeleteDisabled:
    'You don’t have delete permission for tests in this collection.',
  noActionAndPermissionInfo: (action, accessLevel) =>
    `You cannot ${action} as this test is shared with ${
      accessLevel === contentSharing.VIEW
        ? 'clone and assign permissions'
        : accessLevel === contentSharing.ASSIGN
        ? 'view and assign permissions'
        : 'view permission'
    } only.`,
}
