import { EduButton } from '@edulastic/common'
import { IconSync } from '@edulastic/icons'
import IconMagicWand from '@edulastic/icons/src/IconMagicWand'
import { Typography } from 'antd'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import React from 'react'
import { FooterButton, InlineSVG, StyledFooter } from './styled'
import {
  disclaimerTextBlockDataTestId,
  footerActionButtonDataTestId,
  generateButtonDataTestId,
  reGenerateButtonDataTestId,
} from '../constants/test'

const AIFooterAction = ({
  status,
  onRegenerate,
  isLoading,
  action: { label: actionLabel, icon: actionIcon, onClick: actionOnClick },
}) => {
  return status ? (
    <StyledFooter>
      <Row gutter={16}>
        <Col span={24}>
          <Typography.Paragraph
            style={{ fontSize: '12px' }}
            data-testid={disclaimerTextBlockDataTestId}
          >
            Disclaimer: This is AI-generated text. Please review and modify the
            content as needed
          </Typography.Paragraph>
        </Col>
        <Col span={12}>
          <FooterButton
            disabled={isLoading}
            type="default"
            onClick={onRegenerate}
            data-testid={reGenerateButtonDataTestId}
            data-cy="aiItemRegenerateButton"
          >
            <span>
              <InlineSVG>
                <IconSync height="20px" width="20px" />
              </InlineSVG>
              Re-generate
            </span>
          </FooterButton>
        </Col>
        <Col span={12}>
          <FooterButton
            disabled={isLoading || status === 'FAILED'}
            onClick={actionOnClick}
            data-testid={footerActionButtonDataTestId}
            data-cy="aiItemAddButton"
          >
            <span>
              {actionIcon && <InlineSVG>{actionIcon}</InlineSVG>}
              {actionLabel}
            </span>
          </FooterButton>
        </Col>
      </Row>
    </StyledFooter>
  ) : (
    <EduButton
      ml="0px"
      width="100%"
      type="primary"
      htmlType="submit"
      data-testid={generateButtonDataTestId}
      data-cy="aiItemGenerateButton"
    >
      <span>
        <InlineSVG>
          <IconMagicWand fill="white" />
        </InlineSVG>{' '}
        GENERATE
      </span>
    </EduButton>
  )
}

export default AIFooterAction
