import { Drawer } from 'antd'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { languageStateSelector } from '../../../common/components/LanguageSelectorTab/duck'
import { updateQuestionAction } from '../../sharedDucks/questions'
import { resetAIRewriteContentStateAction } from '../ducks/rewrite'
import { resetAITranslateContentStateAction } from '../ducks/translate'
import { ITEM_AUTHORING_PROMPTS } from '../constants/prompt'
import { processAiGeneratedItems } from '../../AssessmentCreate/components/CreateAITest/ducks/helpers'
import { titleToAIQuestionType } from '../utils/form'
import { resetAIQuestionStateAction } from '../ducks/questionGeneration'
import { updateDictAlignmentAction } from '../../src/actions/dictionaries'

const AIItemAuthoringContainer = ({
  prompt,
  question,
  onClose,
  updateQuestion,
  selectedLanguage,
  resetAIRewriteContentState,
  resetAITranslateContentState,
  resetAIQuestionState,
  updateDictAlignment,
}) => {
  useEffect(() => {
    resetAIRewriteContentState()
    resetAITranslateContentState()
    resetAIQuestionState()
  }, [])

  const processAndAddQuestion = ({ question: aiQuestion, alignment }) => {
    const item = processAiGeneratedItems({
      questions: [aiQuestion],
      questionType: titleToAIQuestionType(question.title),
      alignmentData: [alignment],
    })

    const paQuestion = item?.[0]?.data?.questions?.[0]
    updateQuestion({ ...paQuestion, id: question.id })
    if (paQuestion?.alignment?.length) {
      updateDictAlignment(0, { ...paQuestion.alignment[0] })
    }

    resetAIQuestionState()
    onClose()
  }

  return (
    <Drawer
      style={{ zIndex: 1004 }}
      maskClosable={false}
      width="640px"
      onClose={onClose}
      visible={prompt}
      title={prompt?.title}
      placement="right"
    >
      {prompt?.component && (
        <prompt.component
          data={{
            question,
            selectedLanguage,
          }}
          onFinish={(data) => {
            if (prompt.key === ITEM_AUTHORING_PROMPTS.QUESTION_GENERATION.key) {
              processAndAddQuestion(data)
            }
          }}
        />
      )}
    </Drawer>
  )
}

const enhance = compose(
  connect(
    (state) => ({
      selectedLanguage: languageStateSelector(state),
    }),
    {
      updateQuestion: updateQuestionAction,
      resetAIRewriteContentState: resetAIRewriteContentStateAction,
      resetAITranslateContentState: resetAITranslateContentStateAction,
      resetAIQuestionState: resetAIQuestionStateAction,
      updateDictAlignment: updateDictAlignmentAction,
    }
  )
)

export default enhance(AIItemAuthoringContainer)
