import { EduButton } from '@edulastic/common'
import { IconMagicWandGradient } from '@edulastic/icons'
import { Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { StyledAIContentBorderWrapper } from './styled'
import LoadingDots from './LoadingDots'
import {
  aiAssistantFailedBlockDataTestId,
  aiAssistantLoadingBlockDataTestId,
  aiAssistantSuccessBlockDataTestId,
  createdByAIAssistantBlockDataTestId,
  regenerateButtonDataTestId,
} from '../constants/test'
import AIResponseFeedback from '../components/AIResponseFeedback'

const AIContentView = ({
  onRegenerate,
  title,
  content,
  status,
  loadingTitle,
  showAIResponseFeedback,
  onAIResponseFeedbackSubmit,
}) => {
  const [generationCount, setGenerationCount] = useState(0)

  useEffect(() => {
    if (status !== 'INITIATED') {
      setGenerationCount((prevGenerationCount) => prevGenerationCount + 1)
    }
  }, [status])

  return (
    <>
      <StyledAIContentBorderWrapper>
        <div>
          <div data-testid={createdByAIAssistantBlockDataTestId}>
            <IconMagicWandGradient />{' '}
            <Typography.Text type="secondary">
              {title || 'Created by AI Assistant'}
            </Typography.Text>
          </div>
          <br />
          {status === 'INITIATED' && (
            <div data-testid={aiAssistantLoadingBlockDataTestId}>
              {loadingTitle || 'Generating'} <LoadingDots />
            </div>
          )}
          {status === 'SUCCESS' && (
            <div data-testid={aiAssistantSuccessBlockDataTestId}>{content}</div>
          )}
          {status === 'FAILED' && (
            <div data-testid={aiAssistantFailedBlockDataTestId}>
              <Typography.Text type="danger">
                <span>
                  {generationCount > 1 ? 'Regeneration ' : 'Generation '} failed{' '}
                  <EduButton
                    data-testid={regenerateButtonDataTestId}
                    onClick={onRegenerate}
                    style={{ display: 'inline' }}
                  >
                    Regenerate
                  </EduButton>
                </span>
              </Typography.Text>
            </div>
          )}
        </div>
      </StyledAIContentBorderWrapper>
      {status === 'SUCCESS' && showAIResponseFeedback && (
        <AIResponseFeedback
          onAIResponseFeedbackSubmit={onAIResponseFeedbackSubmit}
        />
      )}
    </>
  )
}

export default AIContentView
