import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import { EduButton } from '@edulastic/common'
import { segmentApi } from '@edulastic/api'
import { ListContainer, MainContainer } from '../styled'
import SubHeader from './SubHeader'
import {
  getClassView,
  getDashboardClasses,
  getIsClassLoading,
  getTotalClassesSelector,
} from '../../../ducks'
import { classView, getAllActiveClasses } from '../utils'
import EmptyClass from './EmptyClass/index'

import ClassListView from './ClassListView/ClassListView'
import ClassGridView from './ClassGridView/ClassGridview'
import {
  getUserId,
  isPremiumUserSelector,
} from '../../../../src/selectors/user'
import Loader from '../components/Loader'
import { SeeAllRow } from '../Assignment/styled'

const DashboardMyClasses = ({
  classData,
  history,
  loading,
  selectedClassView,
  userId,
  isPremiumUser,
  totalClasses,
}) => {
  const activeClasses = useMemo(() => getAllActiveClasses(classData), [
    classData,
  ]).sort((a, b) => a.name.localeCompare(b.name))

  const handleManageClassNavigation = () => {
    history.push('/author/manageClass')
    segmentApi.genericEventTrack('Dashboard:Classes:SeeAll', {
      source: 'Dashboard',
    })
  }

  const classesByViews = {
    [classView.GRID]: (
      <ClassGridView activeClasses={activeClasses} userId={userId} />
    ),
    [classView.LIST]: (
      <ClassListView activeClasses={activeClasses} loading={loading} />
    ),
  }
  return (
    <MainContainer>
      <SubHeader
        isPremiumUser={isPremiumUser}
        classGroups={activeClasses}
        history={history}
      />
      <ListContainer>
        {loading && <Loader />}
        {!loading && !activeClasses.length && <EmptyClass history={history} />}
        {!loading &&
          activeClasses.length > 0 &&
          classesByViews[selectedClassView]}
        {!loading && totalClasses > 9 && (
          <SeeAllRow>
            <EduButton
              onClick={handleManageClassNavigation}
              fontSize="12px"
              isGhost
              data-cy="seeAllClassesAtBottom"
            >
              See All Classes
            </EduButton>
          </SeeAllRow>
        )}
      </ListContainer>
    </MainContainer>
  )
}

export default compose(
  withRouter,
  connect(
    (state) => ({
      classData: getDashboardClasses(state),
      loading: getIsClassLoading(state),
      selectedClassView: getClassView(state),
      userId: getUserId(state),
      isPremiumUser: isPremiumUserSelector(state),
      totalClasses: getTotalClassesSelector(state),
    }),
    {}
  )
)(DashboardMyClasses)
