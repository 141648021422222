import React from 'react'
import { IconEye, IconTrash, IconExpand, IconCollapse } from '@edulastic/icons'
import { Container, ActionButton } from './styled'
import AIQuestionActionButton from '../../../../../../AIFeatures/common/AIQuestionActionButton'

export default ({
  expanded,
  onPreview,
  onDelete,
  onCollapseExpandRow,
  isEditable,
  style,
  showAiActionButton,
  onAiOptionSelected,
}) => (
  <Container style={style}>
    {showAiActionButton && (
      <AIQuestionActionButton onOptionSelected={onAiOptionSelected} />
    )}
    <ActionButton
      isGhost
      title="Expand"
      data-cy={`expand-${expanded}`}
      onClick={onCollapseExpandRow}
      IconBtn
    >
      {expanded ? (
        <IconCollapse width={15} height={15} />
      ) : (
        <IconExpand width={15} height={15} />
      )}
    </ActionButton>
    <ActionButton
      isGhost
      title="Preview"
      data-cy="preview"
      onClick={onPreview}
      IconBtn
    >
      <IconEye width={18} height={18} />
    </ActionButton>
    {isEditable && (
      <ActionButton
        isGhost
        title="Remove"
        data-cy="delete"
        onClick={onDelete}
        onMouseDown={(e) => e && e.preventDefault()}
        IconBtn
      >
        <IconTrash width={15} height={15} />
      </ActionButton>
    )}
  </Container>
)
