import React, { useState, useEffect } from 'react'
import { Row, Col, Select, Checkbox } from 'antd'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { groupBy, isEmpty } from 'lodash'
import { EduButton, EduIf } from '@edulastic/common'
import { IconSaveNew } from '@edulastic/icons'
import { segmentApi } from '@edulastic/api'

import {
  CustomSelect,
  Description,
  Heading,
  InfoContainer,
  StandardInputsContainer,
} from '../styled'
import { selectsData } from '../../../../../../../../TestPage/components/common'
import { getCurriculumsListSelector } from '../../../../../../../../src/selectors/dictionaries'
import { updateDefaultSettingsAction } from '../../../../../../../../../student/Login/ducks'

import {
  getShowAllCurriculumsSelector,
  getUserId,
} from '../../../../../../../../src/selectors/user'

const InterestedStandardInputs = ({
  curriculums,
  updateDefaultSettings,
  showAllStandards,
  userId,
  loading,
}) => {
  const [selectedData, setSelectedData] = useState({
    grades: [],
    subjects: [],
    curriculums: [],
  })

  const [curriculumDropDownData, setCurriculumDropDownData] = useState([])

  const handleChange = (key, value) => {
    setSelectedData({
      ...selectedData,
      [key]: value,
    })
  }

  const handleSave = () => {
    const mappedCurriculums = curriculums.map(
      ({ curriculum, _id, grades, subject }) => ({
        name: curriculum,
        grades,
        _id,
        subject,
      })
    )
    const curriculumsById = groupBy(mappedCurriculums, '_id')
    const selectedCurriculumsData = selectedData.curriculums.map(
      (curriculum) => curriculumsById[curriculum][0]
    )
    if (!isEmpty(selectedData.grades) || !isEmpty(selectedData.subjects)) {
      segmentApi.genericEventTrack('Grade_SubjectModalOpen', {
        Trigger_Source: 'Dashboard banner',
      })
      updateDefaultSettings({
        defaultGrades: selectedData.grades,
        defaultSubjects: selectedData.subjects,
        orgId: userId,
        orgType: 'teacher',
        curriculums: selectedCurriculumsData,
      })
    }
  }

  useEffect(() => {
    // Filter curriculum dropdown data
    if (selectedData.grades.length && selectedData.subjects.length) {
      const filteredCurriculum = curriculums.filter(
        ({ subject, grades }) =>
          selectedData.subjects.includes(subject) &&
          grades.some((grade) => selectedData.grades.includes(grade))
      )
      setCurriculumDropDownData(filteredCurriculum)
    }

    // Clear the selected curriculum if either grade or subject is empty
    if (
      selectedData.curriculums &&
      (isEmpty(selectedData.grades) || isEmpty(selectedData.subjects))
    ) {
      setSelectedData({
        ...selectedData,
        curriculums: [],
      })
    }
  }, [curriculums, selectedData.grades, selectedData.subjects])

  // Show STANDARD SET Select
  const { grades, subjects } = selectedData
  const showStandardSet = grades.length && subjects.length && showAllStandards

  return (
    <StandardInputsContainer>
      <InfoContainer>
        <Heading>Welcome back to school! What are you teaching?</Heading>
        <Description>
          This information will help us prepare your reports and search results.
        </Description>
      </InfoContainer>
      <Row gutter={[12, 0]}>
        <Col lg={7} xxl={6}>
          <CustomSelect
            data-cy="gradeSelect"
            showArrow
            mode="multiple"
            size="large"
            disabled={loading}
            value={selectedData.grades}
            placeholder="Please select Grades"
            onChange={(value) => handleChange('grades', value)}
            getPopupContainer={(trigger) => trigger.parentNode}
            filterOption={(input, option) =>
              option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            maxTagCount={2}
            maxTagTextLength={8}
            optionLabelProp="label"
          >
            {selectsData.allGrades.map(({ value, text }, index) => (
              <Select.Option key={index} value={value} label={text}>
                <Checkbox checked={selectedData.grades.includes(value)} />
                {text}
              </Select.Option>
            ))}
          </CustomSelect>
        </Col>
        <Col lg={7} xxl={6}>
          <CustomSelect
            data-cy="gradeSelect"
            mode="multiple"
            size="large"
            value={selectedData.subjects}
            placeholder="Please select Subjects"
            onChange={(value) => handleChange('subjects', value)}
            getPopupContainer={(trigger) => trigger.parentNode}
            filterOption={(input, option) =>
              option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            maxTagCount={2}
            maxTagTextLength={8}
            optionLabelProp="label"
            showArrow
            disabled={loading}
          >
            {selectsData.allSubjects.map(({ value, text }) => (
              <Select.Option key={value} value={value} label={text}>
                <Checkbox checked={selectedData.subjects.includes(value)} />
                {text}
              </Select.Option>
            ))}
          </CustomSelect>
        </Col>
        <EduIf condition={showStandardSet}>
          <Col lg={7} xxl={6}>
            <CustomSelect
              mode="multiple"
              size="large"
              showArrow
              value={selectedData.curriculums}
              placeholder="Select Standard Set (Optional)"
              onChange={(value) => handleChange('curriculums', value)}
              getPopupContainer={(trigger) => trigger.parentNode}
              filterOption={(input, option) =>
                option.props.label.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
              maxTagCount={2}
              maxTagTextLength={8}
              optionLabelProp="label"
              disabled={loading}
            >
              {curriculumDropDownData.map(({ curriculum, _id }) => (
                <Select.Option key={_id} value={_id} label={curriculum}>
                  <Checkbox checked={selectedData.curriculums.includes(_id)} />
                  {curriculum}
                </Select.Option>
              ))}
            </CustomSelect>
          </Col>
        </EduIf>

        <Col lg={3} xxl={6}>
          <EduButton
            loading={loading}
            isBlue
            style={{ marginLeft: '5px' }}
            onClick={handleSave}
          >
            <EduIf condition={!loading}>
              <IconSaveNew width={16} height={16} />
            </EduIf>
            {loading ? 'Saving...' : 'Save'}
          </EduButton>
        </Col>
      </Row>
    </StandardInputsContainer>
  )
}

const enhance = compose(
  connect(
    (state) => ({
      curriculums: getCurriculumsListSelector(state),
      showAllStandards: getShowAllCurriculumsSelector(state),
      userId: getUserId(state),
    }),
    {
      updateDefaultSettings: updateDefaultSettingsAction,
    }
  )
)

const InterestedStandardInputsWrapper = enhance(InterestedStandardInputs)

export { InterestedStandardInputsWrapper as InterestedStandardInputs }
