export const parseHTMLToPlainText = (html = '') => {
  const jQueryHtml = $(`<span>${html}</span>`)

  // cleanup newline and spaces
  jQueryHtml.html(
    jQueryHtml
      .html()
      .replace(/\n/g, '')
      .replace(/&nbsp;/g, ' ')
      .replace(/\s\s+/g, ' ')
  )

  // add newline after p tag
  jQueryHtml.find('p').after('\n')

  // replace textdropdown with TEXT_DROPDOWN_{index}
  jQueryHtml.find('textdropdown').each((index, element) => {
    element.replaceWith(`TEXT_DROPDOWN_${index + 1}`)
  })

  // replace response box with TEXT_DROPDOWN_{index}
  jQueryHtml.find('response').each((index, element) => {
    element.replaceWith(`RESPONSE_${index + 1}`)
  })

  // replace textinput with TEXT_INPUT_{index}
  jQueryHtml.find('textinput').each((index, element) => {
    element.replaceWith(`TEXT_INPUT_${index + 1}`)
  })

  // replace audio with AUDIO_{index}
  jQueryHtml.find('audio').each((index, element) => {
    element.replaceWith(`AUDIO_${index + 1}`)
  })

  // replace audio with AUDIO_{index}
  jQueryHtml.find('table').each((index, element) => {
    element.replaceWith(`TABLE_${index + 1}`)
  })

  jQueryHtml.find('.fr-video').each((index, element) => {
    element.replaceWith(`VIDEO_${index + 1}`)
  })

  jQueryHtml.find('img').each((index, element) => {
    element.replaceWith(`IMAGE_${index + 1}`)
  })

  jQueryHtml.find('.input__math').each((index, element) => {
    element.replaceWith(`MATH_CONTENT_${index + 1}`)
  })

  // send the text content
  return jQueryHtml.text().trim()
}
