import IconBold from './bold'
import IconItalic from './italic'
import IconUnderline from './underline'
import IconDiv from './div'
import IconOrdered from './ordered'
import IconBullet from './bullet'
import IconAlignLeft from './alignleft'
import IconAlignCenter from './aligncenter'
import IconAlignRight from './alignright'
import IconAlignJustify from './alignjustify'
import IconBlockQuote from './blockquote'
import IconScriptSub from './scriptsub'
import IconScriptSuper from './scriptsuper'
import IconIndent from './indent'
import IconOutdent from './outdent'
import IconDirectionRtl from './directionrtl'
import IconFormula from './formula'
import IconImage from './image'
import IconTable from './insertTable'
import IconRedo from './redo'
import IconUndo from './undo'
import IconStrike from './strike'
import IconHeader1 from './header1'
import IconHeader2 from './header2'
import IconClearFormat from './ClearFormat'
import IconLink from './IconLink'
import IconSpecialCharacter from './SpecialCharacter'

export default {
  bold: IconBold,
  italic: IconItalic,
  underline: IconUnderline,
  strikeThrough: IconStrike,
  h1: IconHeader1,
  h2: IconHeader2,
  div: IconDiv,
  formatOL: IconOrdered,
  formatUL: IconBullet,
  alignLeft: IconAlignLeft,
  alignCenter: IconAlignCenter,
  alignRight: IconAlignRight,
  alignJustify: IconAlignJustify,
  quote: IconBlockQuote,
  subscript: IconScriptSub,
  superscript: IconScriptSuper,
  indent: IconIndent,
  outdent: IconOutdent,
  paragraphFormat: IconDirectionRtl,
  clearFormatting: IconClearFormat,
  math: IconFormula,
  specialCharacters: IconSpecialCharacter,
  insertImage: IconImage,
  insertLink: IconLink,
  insertTable: IconTable,
  undo: IconUndo,
  redo: IconRedo,
}
