/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconLogoWhite = ({ fill, ...rest }) => (
  <SVG
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill={fill || 'none'}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g id="magic-wand 1">
      <g id="Layer_2_00000106836014036346266850000001045297775049221298_">
        <g id="_01.magic_sparkles">
          <path
            id="Vector"
            d="M4.03126 3.22504L5.41876 3.98441C5.59689 4.08129 5.66251 4.30316 5.56564 4.48129C5.53126 4.54379 5.48126 4.59379 5.41876 4.62816L4.03126 5.38441C3.96876 5.41879 3.91876 5.46879 3.88439 5.53129L3.12501 6.91879C3.02814 7.09691 2.80626 7.16254 2.62814 7.06566C2.56564 7.03129 2.51564 6.98129 2.48126 6.91879L1.72501 5.53129C1.69064 5.46879 1.64064 5.41879 1.57814 5.38441L0.190637 4.62816C0.0125124 4.53129 -0.0531126 4.30941 0.0437624 4.13129C0.0781374 4.06879 0.128137 4.01879 0.190637 3.98441L1.57814 3.22504C1.64064 3.19066 1.69064 3.14066 1.72501 3.07816L2.48439 1.69379C2.58126 1.51566 2.80626 1.45004 2.98439 1.55004C3.04376 1.58441 3.09376 1.63441 3.12814 1.69379L3.88751 3.07816C3.91876 3.14066 3.96876 3.19066 4.03126 3.22504Z"
            fill={fill || '#1AB395'}
          />
          <path
            id="Vector_2"
            d="M14.3594 10.6313L15.7469 11.3906C15.925 11.4875 15.9906 11.7094 15.8938 11.8875C15.8594 11.95 15.8094 12 15.7469 12.0344L14.3594 12.7938C14.2969 12.8281 14.2469 12.8781 14.2125 12.9406L13.4531 14.3281C13.3563 14.5063 13.1313 14.5719 12.9531 14.475C12.8906 14.4406 12.8406 14.3906 12.8063 14.3281L12.0531 12.9375C12.0188 12.875 11.9688 12.825 11.9063 12.7906L10.5188 12.0313C10.3406 11.9344 10.275 11.7094 10.3719 11.5313C10.4063 11.4688 10.4563 11.4188 10.5188 11.3844L11.9063 10.625C11.9688 10.5906 12.0188 10.5406 12.0531 10.4781L12.8125 9.09377C12.9094 8.91565 13.1344 8.85002 13.3125 8.9469C13.375 8.98127 13.425 9.03127 13.4594 9.09377L14.2188 10.4813C14.2469 10.5469 14.2969 10.5969 14.3594 10.6313Z"
            fill={fill || '#1AB395'}
          />
          <path
            id="Vector_3"
            d="M8.35314 1.6375L9.26251 2.13438C9.37814 2.2 9.42189 2.34688 9.35939 2.4625C9.33751 2.50313 9.30314 2.53438 9.26251 2.55938L8.35314 3.05625C8.31251 3.07813 8.27814 3.1125 8.25626 3.15313L7.75939 4.0625C7.69376 4.17813 7.54689 4.22188 7.43126 4.15938C7.39064 4.1375 7.35939 4.10313 7.33439 4.0625L6.83751 3.15313C6.81564 3.1125 6.78126 3.07813 6.74064 3.05625L5.83126 2.55938C5.71564 2.49688 5.67189 2.35 5.73439 2.23125C5.75626 2.19063 5.79064 2.15625 5.83126 2.13438L6.74064 1.6375C6.78126 1.61563 6.81564 1.58125 6.83751 1.54063L7.33439 0.631252C7.40001 0.515627 7.54689 0.471877 7.66251 0.534377C7.70314 0.556252 7.73439 0.590627 7.75939 0.631252L8.25626 1.54063C8.27814 1.58125 8.31251 1.61563 8.35314 1.6375Z"
            fill={fill || '#1AB395'}
          />
          <path
            id="Vector_4"
            d="M15.6438 2.05314L14.3594 0.768766C13.8844 0.293766 13.1125 0.290641 12.6344 0.765641C12.6344 0.765641 12.6344 0.765641 12.6313 0.768766L1.18128 12.2219C0.706275 12.6969 0.70315 13.4688 1.18128 13.9469L2.46565 15.2313C2.94065 15.7063 3.71253 15.7094 4.19065 15.2313L15.6438 3.77814C16.1188 3.30314 16.1188 2.53127 15.6438 2.05314ZM11.1782 6.38439L10.0282 5.23439L13.4188 1.84064L14.5688 2.99064L11.1782 6.38439Z"
            fill={fill || '#1AB395'}
          />
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_2739_934">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </SVG>
)

export default withIconStyles(IconLogoWhite)
