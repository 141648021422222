import aiAPI from '@edulastic/api/src/aiAPI'
import { all, fork, put, takeEvery } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { notification } from '@edulastic/common'

export const namespaceKey = 'aiResponseFeedback'

// actions constants
export const SUBMIT_AI_RESPONSE_FEEDBACK = `[${namespaceKey}] submit feedback`
export const SET_AI_RESPONSE_FEEDBACK_STATE = `[${namespaceKey}] set state`
export const RESET_AI_RESPONSE_FEEDBACK_STATE = `[${namespaceKey}] reset state`

// actions
export const submitAIResponseFeedbackAction = (payload) => ({
  type: SUBMIT_AI_RESPONSE_FEEDBACK,
  payload,
})

export const setAIResponseFeedbackStateAction = (payload) => ({
  type: SET_AI_RESPONSE_FEEDBACK_STATE,
  payload,
})

export const resetAIResponseFeedbackStateAction = () => ({
  type: RESET_AI_RESPONSE_FEEDBACK_STATE,
})

// selectors
export const stateSelector = (state) => state?.[namespaceKey]

export const getAIResponseFeedbackState = createSelector(
  stateSelector,
  (state) => state || {}
)

// initial state
const initialState = {
  apiStatus: null,
  requestId: null,
  feedback: null,
  errorMessage: null,
}

// reducer
export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_AI_RESPONSE_FEEDBACK_STATE:
      return {
        ...state,
        ...payload,
      }
    case RESET_AI_RESPONSE_FEEDBACK_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

// sagas
function* submitAIResponseFeedbackSaga({ payload }) {
  try {
    const { requestId, feedback, onFeedbackSuccessCallback } = payload
    yield fork(aiAPI.submitAIResponseFeedback, payload)
    yield put(
      setAIResponseFeedbackStateAction({
        apiStatus: 'SUCCESS',
        requestId,
        feedback,
        errorMessage: null,
      })
    )

    if (typeof onFeedbackSuccessCallback === 'function') {
      onFeedbackSuccessCallback()
    }
  } catch (err) {
    notification({ type: 'error', msg: 'Failed to save the feedback.' })
  }
}

export function* watcherSaga() {
  yield all([
    takeEvery(SUBMIT_AI_RESPONSE_FEEDBACK, submitAIResponseFeedbackSaga),
  ])
}
