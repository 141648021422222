import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconCampaign = (props) => (
  <SVG
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.0003 9.16683C15.0003 9.72516 15.0003 10.2752 15.0003 10.8335C16.0003 10.8335 17.3003 10.8335 18.3337 10.8335C18.3337 10.2752 18.3337 9.72516 18.3337 9.16683C17.3003 9.16683 16.0003 9.16683 15.0003 9.16683Z"
      fill="white"
    />
    <path
      d="M13.3337 14.6752C14.1337 15.2668 15.1753 16.0502 16.0003 16.6668C16.3337 16.2252 16.667 15.7752 17.0003 15.3335C16.1753 14.7168 15.1337 13.9335 14.3337 13.3335C14.0003 13.7835 13.667 14.2335 13.3337 14.6752Z"
      fill="white"
    />
    <path
      d="M17.0003 4.66683C16.667 4.22516 16.3337 3.77516 16.0003 3.3335C15.1753 3.95016 14.1337 4.7335 13.3337 5.3335C13.667 5.77516 14.0003 6.22516 14.3337 6.66683C15.1337 6.06683 16.1753 5.29183 17.0003 4.66683Z"
      fill="white"
    />
    <path
      d="M3.33366 7.50016C2.41699 7.50016 1.66699 8.25016 1.66699 9.16683V10.8335C1.66699 11.7502 2.41699 12.5002 3.33366 12.5002H4.16699V15.8335H5.83366V12.5002H6.66699L10.8337 15.0002V5.00016L6.66699 7.50016H3.33366ZM7.52533 8.92516L9.16699 7.94183V12.0585L7.52533 11.0752L7.12533 10.8335H3.33366V9.16683H7.12533L7.52533 8.92516Z"
      fill="white"
    />
    <path
      d="M12.917 10.0002C12.917 8.89183 12.4337 7.89183 11.667 7.2085V12.7835C12.4337 12.1085 12.917 11.1085 12.917 10.0002Z"
      fill="white"
    />
  </SVG>
)
export default withIconStyles(IconCampaign)
