import React from 'react'
import withIconStyles from '@edulastic/icons/src/HOC/withIconStyles'
import SVG from '@edulastic/icons/src/common/SVG'

const IconGraphCircle = (props) => (
  <SVG width="20.1" height="20.1" viewBox="0 0 20.1 20.1" {...props}>
    <g transform="translate(-257.114 -353.449)">
      <path
        d="M267.164,355.449a8.05,8.05,0,1,1-8.05,8.05h0A8.05,8.05,0,0,1,267.164,355.449Z"
        fill="none"
      />
      <path d="M267.164,355.449a8.05,8.05,0,1,0,8.05,8.05,8.05,8.05,0,0,0-8.05-8.05m0-2a10.05,10.05,0,1,1-10.05,10.05A10.061,10.061,0,0,1,267.164,353.449Z" />
    </g>
  </SVG>
)

export default withIconStyles(IconGraphCircle)
