import {
  IconSibebarLayout as LayoutIcon,
  IconMenuOpenClose as ListIcon,
  IconRedo as NextIcon,
  IconUndo as PreviousIcon,
  IconGraphMove as MoveIcon,
  IconCursor as CursorIcon,
  IconSquareLine as BoxIcon,
  IconEraseText as CleanIcon,
  IconHighlight as HighlightIcon,
  IconStrikeThrough as TextLineIcon,
  IconPencilEdit as EditIcon,
  IconImageTwo as ImageIcon,
  IconMessage as MessageIcon,
  IconTextEdit as TextIcon,
  IconVideo as Videoicon,
} from '@edulastic/icons'

const ToolsIcons = {
  LayoutIcon,
  ListIcon,
  NextIcon,
  PreviousIcon,
  MoveIcon,
  CursorIcon,
  BoxIcon,
  CleanIcon,
  HighlightIcon,
  TextLineIcon,
  EditIcon,
  ImageIcon,
  MessageIcon,
  TextIcon,
  Videoicon,
}

export default ToolsIcons
