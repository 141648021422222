import React, { useState, useEffect, useMemo, useRef } from 'react'
import { compose } from 'redux'
import { Rnd } from 'react-rnd'
import styled from 'styled-components'
import { white } from '@edulastic/colors'
import { IconMore } from '@edulastic/icons'
import { withNamespaces } from '@edulastic/localization'
import { Dropdown, Icon, Menu } from 'antd'
import { connect } from 'react-redux'
import { get } from 'lodash'
import {
  ENGLISH,
  LANGUAGE_EN,
  LANGUAGE_ES,
  SPANISH,
} from '@edulastic/constants/const/languages'
import IconCampaign from '@edulastic/icons/src/IconCampaign'
import { FlexContainer } from '@edulastic/common'
import AudioControls from '../../../AudioControls'
import { themes } from '../../../../theme'
import { getQuestionsByIdSelector } from '../../../selectors/questions'
import { languageDropdown } from '../../../components/Common/StackedTranslator/constants'
import { getCurrentLanguage } from '../../../../common/components/LanguageSelectorTab/duck'
import { changeDataToPreferredLanguage } from '../../../utils/question'

import { isStackedTranslationEnabledSelector } from '../../../../common/components/LanguageSelector/duck'

const {
  playerSkin: { quester },
} = themes

const { footer } = quester

const style = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  border: 'solid 1px #ddd',
  background: '#334049',
  padding: '10px',
  borderRadius: '5px',
  zIndex: 999,
}

const languageMenu = {
  en: ENGLISH,
  es: SPANISH,
}

const QuestionSelect = ({ questions, onSelect, i18Translate }) => {
  if (!questions) {
    return null
  }

  const [selectedKey, setSelectedKey] = useState(0)

  const handleSelect = ({ key: qIndex }) => {
    if (parseInt(selectedKey, 10) !== parseInt(qIndex, 10)) {
      onSelect(questions[qIndex])
      setSelectedKey(qIndex)
    }
  }

  let passageIndex = 0
  let questionIndex = 0
  const menu = (
    <StyledMenu
      onClick={handleSelect}
      defaultSelectedKeys={[`${selectedKey}`]}
      selectedKeys={[`${selectedKey}`]}
    >
      {questions.map((q, i) => {
        if (q.type === 'passage' || q.type === 'video') {
          passageIndex += 1
        } else {
          questionIndex += 1
        }
        return (
          <Menu.Item key={i}>
            {q.type === 'passage' || q.type === 'video'
              ? `${i18Translate(
                  'common.test.ttsButton.playPassage'
                )} ${passageIndex}`
              : `${i18Translate(
                  'common.test.ttsButton.playQuestion'
                )} ${questionIndex}`}
          </Menu.Item>
        )
      })}
    </StyledMenu>
  )

  return (
    <Dropdown overlay={menu} placement="topLeft" trigger="click" zIndex="10000">
      <DropdownTriger>
        <div />
        <div />
        <div />
      </DropdownTriger>
    </Dropdown>
  )
}

const ItemAudioControl = ({
  item,
  windowWidth,
  isPremiumContentWithoutAccess,
  passage,
  questionsById,
  userInteractionsPassageData,
  showTtsForPassages,
  t: i18Translate,
  currentLanguage,
  view,
  isStackedTranslationEnabled,
}) => {
  const [selected, setSelected] = useState()
  const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage)
  const controller = useRef()
  const questions = useMemo(() => {
    return [passage?.structure, ...item.rows]
      .reduce((acc, curr) => {
        if (!curr) {
          return acc
        }
        return [...acc, ...(curr?.widgets || [])]
      }, [])
      .map((widget) => {
        const q =
          questionsById[`${item._id}_${widget.reference}`] ||
          questionsById[widget.reference]
        const questionByLanguage = isStackedTranslationEnabled
          ? changeDataToPreferredLanguage(q, selectedLanguage, view)
          : q

        // EV-36516 | if showTtsForPassages is false hide tts for passage
        if (
          (questionByLanguage.type === 'passage' ||
            questionByLanguage.type === 'video') &&
          showTtsForPassages === false
        ) {
          return null
        }
        if (
          questionByLanguage?.tts &&
          questionByLanguage?.tts?.taskStatus === 'COMPLETED'
        ) {
          return questionByLanguage
        }
        return null
      })
      .filter((x) => x)
  }, [passage?.structure, item.rows, questionsById, view, selectedLanguage])

  useEffect(() => {
    setSelected(questions[0])
  }, [questions, selectedLanguage])

  const handleSelect = (q) => {
    setSelected(q)
    if (controller.current?.play) {
      setTimeout(() => {
        controller.current?.play()
      }, 100)
    }
  }

  if (!selected) {
    return null
  }

  const handleLanguageSelection = (e) => {
    setSelectedLanguage(e.key)
  }

  const languageSelectors = (
    <Menu onClick={handleLanguageSelection}>
      <Menu.Item key={LANGUAGE_ES}>{languageDropdown[LANGUAGE_ES]}</Menu.Item>
      <Menu.Item key={LANGUAGE_EN}>{languageDropdown[LANGUAGE_EN]}</Menu.Item>
    </Menu>
  )

  return (
    <Rnd
      style={style}
      default={{
        x: windowWidth - (isStackedTranslationEnabled ? 420 : 300),
        y: -80,
        height: 60,
      }}
      enableResizing={false}
    >
      {isStackedTranslationEnabled && (
        <Dropdown
          overlay={languageSelectors}
          zIndex="10000"
          placement="topLeft"
          trigger={['click']}
        >
          <a
            style={{ color: 'white', textTransform: 'capitalize' }}
            onClick={(e) => e.preventDefault()}
          >
            <FlexContainer alignItems="center">
              <IconCampaign margin="0 8px 0 0" />{' '}
              {languageMenu[selectedLanguage]}{' '}
              <Icon style={{ margin: '0 5px 0 5px' }} type="down" />
            </FlexContainer>
          </a>
        </Dropdown>
      )}
      {item.multipartItem && (
        <QuestionSelect
          questions={questions}
          onSelect={handleSelect}
          selected={selected}
          i18Translate={i18Translate}
        />
      )}
      <span>{i18Translate('common.test.ttsButton.playAll')}</span>
      <AudioControls
        item={selected}
        qId={selected.id}
        controlRef={controller}
        audioSrc={selected?.tts?.titleAudioURL}
        className="quester-question-audio-controller"
        isPremiumContentWithoutAccess={isPremiumContentWithoutAccess}
        page={userInteractionsPassageData?.[selected.id]?.currentPage || 1}
      />
      <IconMoreVertical
        color={footer.textColor}
        hoverColor={footer.textColor}
      />
    </Rnd>
  )
}

const enhance = compose(
  withNamespaces('student'),
  connect(
    (state) => ({
      questionsById: getQuestionsByIdSelector(state),
      userInteractionsPassageData: get(
        state,
        ['userInteractions', 'passages'],
        {}
      ),
      currentLanguage: getCurrentLanguage(state),
      isStackedTranslationEnabled: isStackedTranslationEnabledSelector(state),
    }),
    null
  )
)

export default enhance(ItemAudioControl)

const IconMoreVertical = styled(IconMore)`
  transform: rotate(90deg);
`

const DropdownTriger = styled.div`
  width: 32px;
  height: 35px;
  margin-right: 4px;
  cursor: pointer;
  position: relative;

  > div {
    width: 100%;
    height: 4px;
    background: ${white};
    margin: 6px 0px;
  }
`

const StyledMenu = styled(Menu)`
  margin-left: -12px;
  margin-bottom: 10px;
  min-width: 180px;
  background: #334049;

  & .ant-dropdown-menu-item {
    color: ${footer.textColor};
    &:hover {
      color: #334049;
      background: ${footer.textColor};
    }
  }
  .ant-dropdown-menu-item-selected {
    color: #334049;
    background: ${footer.textColor};
  }
`
