import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { isNil, isEmpty } from 'lodash'

import { withRouter } from 'react-router'
import { compose } from 'redux'
import { getUser, isGcpsDistrictSelector } from '../src/selectors/user'

import {
  fetchDashboardTiles as fetchTiles,
  getFeatureBannerTiles,
  getTotalAssignmentCount,
} from '../Dashboard/ducks'

import {
  FEATURE_BANNER,
  getTransformedBannerData,
  handleBannerAction,
  saveBannerIdToLocalStorage,
  showBannerCB,
} from './utils'

import LibraryBanner from './LibraryBanner'
import DashboardBanner from './DashboardBanner'
import { getShowDiscountOfferSelector } from '../Subscription/ducks'
import { isValidUrl } from '../../assessment/utils/urlUtils'

function Banner({
  getBannerTiles,
  bannerTiles,
  user,
  placeholderId,
  history,
  isGCPSDistrict,
  showDiscountOffer,
  totalAssignmentCount,
  isOldDashboard,
}) {
  const [isBannerClosed, setIsBannerClosed] = useState(false)
  const { role: userRole, _id: userId } = user

  useEffect(() => {
    const isDashboardPage = history.location.pathname
      .toLowerCase()
      .includes('/author/dashboard')
    // In case of Dashboard page, an API call is already being made to fetch all the configurble tiles
    if (isNil(bannerTiles) && !isDashboardPage) {
      getBannerTiles({ bannerType: FEATURE_BANNER })
    }
  }, [])

  const banner = useMemo(
    () =>
      getTransformedBannerData(
        bannerTiles,
        user.features.premium,
        placeholderId
      ),
    [bannerTiles]
  )

  const showBanner = useMemo(() => showBannerCB(banner, user, isBannerClosed), [
    banner,
    isBannerClosed,
  ])

  const handleBannerClose = () => {
    setIsBannerClosed(true)
    saveBannerIdToLocalStorage(banner.bannerId, userId)
  }

  if (
    (totalAssignmentCount < 2 && !isOldDashboard) ||
    isGCPSDistrict ||
    !showBanner ||
    isEmpty(banner) ||
    (!showDiscountOffer && banner?.tags?.includes('DISCOUNT'))
  ) {
    return null
  }

  const BannerToRender = placeholderId.includes('dashboard')
    ? DashboardBanner
    : LibraryBanner

  const isValidTargetUrl = isValidUrl(banner.targetUrl?.teacher)

  return (
    <BannerToRender
      onClose={handleBannerClose}
      onClick={() =>
        handleBannerAction(banner, userRole, history, isValidTargetUrl)
      }
      banner={banner}
      showDiscountOffer={showDiscountOffer}
      isValidTargetUrl={isValidTargetUrl}
    />
  )
}

const enhance = compose(
  withRouter,
  connect(
    (state) => ({
      bannerTiles: getFeatureBannerTiles(state),
      user: getUser(state),
      isGCPSDistrict: isGcpsDistrictSelector(state),
      showDiscountOffer: getShowDiscountOfferSelector(state),
      totalAssignmentCount: getTotalAssignmentCount(state),
    }),
    {
      getBannerTiles: fetchTiles,
    }
  )
)

export const FeatureBannerPlaceholder = enhance(Banner)
