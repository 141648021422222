import React from 'react'
import SVG from './common/SVG'

const IconUploadFile = (props) => (
  <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      d="M9.33317 1.33331H3.99984C3.2665 1.33331 2.67317 1.93331 2.67317 2.66665L2.6665 13.3333C2.6665 14.0666 3.25984 14.6666 3.99317 14.6666H11.9998C12.7332 14.6666 13.3332 14.0666 13.3332 13.3333V5.33331L9.33317 1.33331ZM11.9998 13.3333H3.99984V2.66665H8.6665V5.99998H11.9998V13.3333ZM5.33317 10.0066L6.27317 10.9466L7.33317 9.89331V12.6666H8.6665V9.89331L9.7265 10.9533L10.6665 10.0066L8.0065 7.33331L5.33317 10.0066Z"
      fill="currentColor"
    />
  </SVG>
)

export default IconUploadFile
