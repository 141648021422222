import {
  Checkbox,
  RadioBtn,
  RadioGrp,
  TextAreaInputStyled,
} from '@edulastic/common'
import Form from 'antd/lib/form'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { segmentApi } from '@edulastic/api'
import { omit } from 'lodash'
import { getAlignmentDataForAiQuestions } from '../../AssessmentCreate/components/CreateAITest/ducks/helpers'
import { standardsFields } from '../../AssessmentPage/VideoQuiz/constants'
import StandardSet from '../../AssessmentPage/components/QuestionEditModal/common/StandardSet/StandardSet'
import { selectsData } from '../../TestPage/components/common'
import AIContentView from '../common/AIContentView'
import AIFooterAction from '../common/AIFooterAction'
import QuestionView from '../common/QuestionView'
import { StyledForm, StyledStandardSet } from '../common/styled'
import { DIFFICULTY_OPTIONS, DOK_OPTIONS } from '../constants/form'
import {
  generateAIQuestionAction,
  getAIQuestionState,
} from '../ducks/questionGeneration'
import { titleToAIQuestionType } from '../utils/form'
import { ITEM_AUTHORING_PROMPTS } from '../constants/prompt'

const AI_FORM_DATA_KEY = 'aiQuestionFromData'

const AIQuestionGeneration = ({
  form,
  data: { question: { title = '' } = {} } = {},
  aiQuestionState,
  generateAIQuestion,
  onFinish,
}) => {
  const [alignmentFieldValue, setAlignmentFieldValue] = useState()
  const [rememberFormData, setRememberFormData] = useState(false)
  const { getFieldDecorator, setFieldsValue } = form
  const { apiStatus, result } = aiQuestionState
  const questionType = titleToAIQuestionType(title)

  const alignment = alignmentFieldValue
    ? getAlignmentDataForAiQuestions(alignmentFieldValue)
    : {}

  useEffect(() => {
    let initialFormData
    try {
      initialFormData = JSON.parse(localStorage.getItem(AI_FORM_DATA_KEY))
    } catch {
      // do nothing
    }

    if (initialFormData) {
      setFieldsValue(initialFormData)
      setRememberFormData(true)

      const { alignmentFieldValue: preAlignmentFieldValue } = initialFormData
      if (preAlignmentFieldValue) {
        setAlignmentFieldValue(preAlignmentFieldValue)
      }
    }
  }, [])

  const onAlignmentChange = (data) => {
    setAlignmentFieldValue(data.alignment)
  }

  const invalidGrades = !alignmentFieldValue?.[0]?.grades?.length
  const invalidSubject = !alignmentFieldValue?.[0]?.subject?.length

  const currentPrompt = ITEM_AUTHORING_PROMPTS.QUESTION_GENERATION

  const onSubmit = (e) => {
    e.preventDefault()
    form.validateFieldsAndScroll((err, values) => {
      if (!err && !invalidGrades && !invalidSubject) {
        if (rememberFormData) {
          localStorage.setItem(
            AI_FORM_DATA_KEY,
            JSON.stringify(
              omit({ ...values, alignmentFieldValue }, ['preference'])
            )
          )
        } else {
          localStorage.removeItem(AI_FORM_DATA_KEY)
        }

        let requestBody = {
          ...values,
          count: 1,
          questionType,
        }

        if (requestBody.difficultLevels) {
          requestBody.difficultLevels = [requestBody.difficultLevels]
        } else if (requestBody.difficultLevels === '') {
          requestBody.difficultLevels = DIFFICULTY_OPTIONS.map(
            ({ value }) => value
          ).filter((value) => !!value)
        }

        if (requestBody.depthsOfKnowledge) {
          requestBody.depthsOfKnowledge = [requestBody.depthsOfKnowledge]
        } else if (requestBody.depthsOfKnowledge === '') {
          requestBody.depthsOfKnowledge = DOK_OPTIONS.map(
            ({ value }) => value
          ).filter((value) => !!value)
        }

        const {
          grades,
          subject,
          standardIds: commonCoreStandards,
          standardSet,
          standardDescriptions: commonCoresStandardDescriptions,
        } = alignment

        requestBody = {
          ...requestBody,
          grades: grades.length
            ? grades
            : selectsData.allGrades.map(({ value }) => value),
          subject,
          standardSet,
          commonCoreStandards,
          commonCoresStandardDescriptions,
        }

        if (currentPrompt.trackingEvent) {
          segmentApi.genericEventTrack('GenerateAIItem', {
            source: currentPrompt.trackingEvent.source,
          })
        }

        generateAIQuestion(requestBody)
      }
    })
  }

  let errorMessage = ''
  if (invalidGrades && invalidSubject) {
    errorMessage = 'Please select grade and subject!'
  } else if (invalidGrades) {
    errorMessage = 'Please select grade!'
  } else if (invalidSubject) {
    errorMessage = 'Please select subject!'
  }

  const isLoading = apiStatus === 'INITIATED'

  return (
    <StyledForm onSubmit={onSubmit}>
      <Form.Item label="QUESTION CONTEXT">
        {getFieldDecorator('preference', {
          rules: [
            {
              required: true,
              message: 'This field is required!',
            },
            {
              max: 1000,
              message: 'This field should not exceed 1000 characters!',
            },
          ],
        })(
          <TextAreaInputStyled
            disabled={isLoading}
            autoSize={{ minRows: 4 }}
            data-cy="aiQuestionGenerationInput"
            placeholder="Tell us what do you want to create?"
          />
        )}
      </Form.Item>
      <Form.Item
        validateStatus={errorMessage ? 'error' : 'success'}
        help={errorMessage}
        required
        label="STANDARD"
      >
        <StyledStandardSet className="standards-set">
          <StandardSet
            disabled={isLoading}
            alignment={alignmentFieldValue}
            onUpdate={onAlignmentChange}
            showIconBrowserBtn
            hideLabel
            standardsRequiredFields={[
              standardsFields.SUBJECT,
              standardsFields.GRADES,
            ]}
            isDocBased
            required={false}
            isAIFlow
            trackingEventSource="AI Item Authoring"
          />
        </StyledStandardSet>
      </Form.Item>
      <Form.Item label="DOK (OPTIONAL)">
        {getFieldDecorator('depthsOfKnowledge', {
          initialValue: '',
        })(
          <RadioGrp disabled={isLoading}>
            {DOK_OPTIONS.map(({ value, label }, index) => (
              <RadioBtn key={index} value={value} data-cy={label}>
                {label}
              </RadioBtn>
            ))}
          </RadioGrp>
        )}
      </Form.Item>
      <Form.Item label="DIFFICULTY (OPTIONAL)">
        {getFieldDecorator('difficultLevels', {
          initialValue: '',
        })(
          <RadioGrp disabled={isLoading}>
            {DIFFICULTY_OPTIONS.map(({ value, label }, index) => (
              <RadioBtn key={index} value={value} data-cy={label}>
                {label}
              </RadioBtn>
            ))}
          </RadioGrp>
        )}
      </Form.Item>
      <div>
        <Checkbox
          disabled={isLoading}
          checked={rememberFormData}
          onChange={() => setRememberFormData((prevValue) => !prevValue)}
        />
        Remember values for next time
      </div>
      <br />
      {apiStatus && (
        <div style={{ marginBottom: '90px' }}>
          <AIContentView
            onRegenerate={onSubmit}
            title="Created by AI Assistant for Full Item Generation"
            loadingTitle="Generating full item"
            content={
              <QuestionView
                question={result?.[0]}
                questionType={questionType}
              />
            }
            status={apiStatus}
          />
        </div>
      )}
      <AIFooterAction
        status={apiStatus}
        onRegenerate={onSubmit}
        action={{
          label: 'ADD ITEM',
          onClick: () => {
            onFinish({
              question: result?.[0],
              alignment: alignmentFieldValue?.[0],
            })
          },
        }}
        isLoading={isLoading}
      />
    </StyledForm>
  )
}

const enhance = compose(
  Form.create({ name: 'ai-question-generation' }),
  connect(
    (state) => ({
      aiQuestionState: getAIQuestionState(state),
    }),
    {
      generateAIQuestion: generateAIQuestionAction,
    }
  )
)

export default enhance(AIQuestionGeneration)
