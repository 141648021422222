import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { compose } from 'redux'
import { TableHeaderStyled } from './styled'
import { getDashboardAssigmentsAction } from '../../../ducks'
import { getUserIdSelector, getUserOrgId } from '../../../../src/selectors/user'
import Filters from './Filters'
import SeeAll from './SeeAll'

const TableHeader = ({
  getDashboardAssigments,
  filterState,
  setFilterState,
  history,
  userId,
  districtId,
}) => {
  const handleFilterChange = (key, value) => {
    setFilterState((prevFilterState) => ({
      ...prevFilterState,
      [key]: value,
    }))
    getDashboardAssigments({
      ...filterState,
      [key]: value,
    })
  }

  return (
    <TableHeaderStyled>
      <Filters
        handleFilterChange={handleFilterChange}
        filterState={filterState}
      />
      <SeeAll
        filterState={filterState}
        history={history}
        userId={userId}
        districtId={districtId}
      />
    </TableHeaderStyled>
  )
}

const enhance = compose(
  withRouter,
  connect(
    (state) => ({
      userId: getUserIdSelector(state),
      districtId: getUserOrgId(state),
    }),
    {
      getDashboardAssigments: getDashboardAssigmentsAction,
    }
  )
)

export default enhance(TableHeader)
