export const fieldKey = {
  schools: 'institutionIds',
  courses: 'courses',
  grades: 'grades',
  subjects: 'subjects',
  groupType: 'groupType',
  classes: 'groupIds',
  tags: 'tagIds',
}

const NULL_OPERATORS = {
  Null: 'null',
  NotNull: 'notNull',
}

export const NULL_OPERATORS_VALUES = [
  NULL_OPERATORS.Null,
  NULL_OPERATORS.NotNull,
]
