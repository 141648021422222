import React, { useMemo, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import { EduElse, EduIf, EduThen } from '@edulastic/common'
import { roleuser } from '@edulastic/constants'
import { CloseBannerIcon, MainContainer } from './styled'
import { InterestedStandardInputs } from './InterestedStandardInputs'
import {
  getUpdateDefaultSettingsSuccess,
  getUpdatingDefaultSettings,
  getUserDetails,
} from '../../../../../../../../student/Login/ducks'
import {
  getInterestedStandardUpdatedAt,
  getInterestedStandardsSelector,
} from '../../../../../../../InterestedStandards/ducks'
import { getUserId } from '../../../../../../../src/selectors/user'

import ConfirmationMessage from './ConfirmationMessage'

const InterestedStandardsInputCard = ({
  interestedStandards,
  loading,
  updateSuccess,
  interestedStandardUpdatedAt,
  user,
  children = null,
}) => {
  const [isCardClosed, setIsCardClosed] = useState(false)

  const handleClose = () => {
    setIsCardClosed(true)
    localStorage.setItem('isInterestedStandardsInputClosed', true)
  }

  const showInterestedStandards = useMemo(() => {
    const currentDate = moment()
    const isInterestedStandardsInputClosed = JSON.parse(
      localStorage.getItem('isInterestedStandardsInputClosed')
    )
    const updatedAtDate = moment(interestedStandardUpdatedAt)
    const differenceMonths = currentDate.diff(updatedAtDate, 'months')
    const monthThreshold = 12
    const isMoreThanMonthThreshold = differenceMonths > monthThreshold // Gap between last updated "interested Standard Settings" and current should be greater than monthThreshold
    const isShown =
      isMoreThanMonthThreshold &&
      !isCardClosed &&
      !isInterestedStandardsInputClosed

    return isShown
  }, [interestedStandardUpdatedAt, isCardClosed])

  return showInterestedStandards && user?.role === roleuser.TEACHER ? (
    <MainContainer>
      <CloseBannerIcon
        title="closeStandardInput"
        height={15}
        width={15}
        color="#FFFFFF"
        onClick={handleClose}
      />

      <EduIf condition={updateSuccess}>
        <EduThen>
          <ConfirmationMessage />
        </EduThen>
        <EduElse>
          <InterestedStandardInputs
            defaultGrades={interestedStandards.defaultGrades}
            defaultSubjects={interestedStandards.defaultSubjects}
            defaultCurriculums={interestedStandards.curriculums}
            interestedStandards={interestedStandards}
            loading={loading}
          />
        </EduElse>
      </EduIf>
    </MainContainer>
  ) : (
    children
  )
}

const enhance = compose(
  connect((state) => ({
    user: getUserDetails(state),
    interestedStandards: getInterestedStandardsSelector(state),
    userId: getUserId(state),
    loading: getUpdatingDefaultSettings(state),
    updateSuccess: getUpdateDefaultSettingsSuccess(state),
    interestedStandardUpdatedAt: getInterestedStandardUpdatedAt(state),
  }))
)

export default enhance(InterestedStandardsInputCard)
