import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconWhitePlay = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="10"
    viewBox="0 0 8 10"
    {...props}
  >
    <path
      id="Polygon_1"
      data-name="Polygon 1"
      d="M4.152,1.357a1,1,0,0,1,1.7,0l3.2,5.113A1,1,0,0,1,8.2,8H1.8A1,1,0,0,1,.956,6.47Z"
      transform="translate(8) rotate(90)"
      fill="#fff"
    />
  </SVG>
)

export default withIconStyles(IconWhitePlay)
