import { EduButton } from '@edulastic/common'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { ConfirmationModal } from '../../../src/components/common/ConfirmationModal'
import {
  getHasDuplicateAssignmentsSelector,
  getHasDuplicateAutoAssignRuleSelector,
  saveAssignmentAction,
  setAutoAssignAction,
  toggleHasDuplicateAssignmentPopupAction,
} from '../../../TestPage/components/Assign/ducks'
import { getPlaylistSelector, getTestSelector } from '../../../TestPage/ducks'
import { Paragraph } from './styled'

const MultipleAssignConfirmation = ({
  hasDuplicateAssignments,
  toggleHasDuplicateAssignmentPopup,
  entity,
  saveAssignment,
  assignment,
  moduleTitle,
  hasDuplicateAutoAssignRule,
  setAutoAssign,
}) => {
  const [saving, setSavingState] = useState(false)

  const onProceed = () => {
    setSavingState(true)
    // allowCommonStudents has to be true for the second time as we have to avoid
    saveAssignment({
      ...assignment,
      allowCommonStudents: true,
      allowDuplicates: true,
    })
  }

  const onRemoveDuplicates = () => {
    setSavingState(true)
    const payload = {
      ...assignment,
    }
    if (hasDuplicateAutoAssignRule) {
      setAutoAssign(false)
      setSavingState(false)
    } else {
      payload.allowCommonStudents = true
      payload.removeDuplicates = true
    }
    saveAssignment(payload)
  }

  const onCancel = () => {
    toggleHasDuplicateAssignmentPopup(false)
  }

  const Footer = [
    <EduButton
      isGhost
      data-cy="duplicate"
      onClick={hasDuplicateAutoAssignRule ? onCancel : onProceed}
      disabled={saving}
    >
      {hasDuplicateAutoAssignRule ? 'Cancel' : 'Proceed with duplicate'}
    </EduButton>,
    <EduButton
      disabled={saving}
      data-cy="noDuplicate"
      onClick={onRemoveDuplicates}
    >
      {hasDuplicateAutoAssignRule
        ? 'Assign without auto assign'
        : 'Remove duplicates'}
    </EduButton>,
  ]

  return (
    <ConfirmationModal
      maskClosable={false}
      textAlign="left"
      title="Warning"
      centered
      visible={hasDuplicateAutoAssignRule || hasDuplicateAssignments}
      footer={Footer}
      onCancel={onCancel}
      afterClose={() => setSavingState(false)}
    >
      {hasDuplicateAutoAssignRule ? (
        <Paragraph>
          A similar auto-assign rule already exists. To create a new one, try
          modifying the filter criteria. Otherwise, you can proceed with the
          assignment without creating a new rule.
        </Paragraph>
      ) : (
        <>
          <Paragraph>
            <b>{moduleTitle || entity.title}</b> has already been assigned to
            one or more of the selected students. Those students will receive a
            duplicate copy of this assessment.
          </Paragraph>
          <Paragraph>
            Please select if the student(s) should receive a duplicate
            assessment.
          </Paragraph>
        </>
      )}
    </ConfirmationModal>
  )
}

export default connect(
  (state, ownProps) => ({
    hasDuplicateAssignments: getHasDuplicateAssignmentsSelector(state),
    hasDuplicateAutoAssignRule: getHasDuplicateAutoAssignRuleSelector(state),
    entity: ownProps.isPlaylist
      ? getPlaylistSelector(state)
      : getTestSelector(state),
  }),
  {
    saveAssignment: saveAssignmentAction,
    toggleHasDuplicateAssignmentPopup: toggleHasDuplicateAssignmentPopupAction,
    setAutoAssign: setAutoAssignAction,
  }
)(MultipleAssignConfirmation)
