const VideoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.408"
    height="19.408"
    viewBox="0 0 19.408 19.408"
  >
    <path
      d="M9.7,0a9.7,9.7,0,1,0,9.7,9.7A9.715,9.715,0,0,0,9.7,0Zm0,17.644a7.94,7.94,0,1,1,7.94-7.94A7.948,7.948,0,0,1,9.7,17.644ZM8.38,12.351,12.35,9.7,8.38,7.057Z"
      transform="translate(0)"
    />
  </svg>
)

export default VideoIcon
