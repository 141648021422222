import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withNamespaces } from 'react-i18next'
import { getFormattedAttrId } from '@edulastic/common/src/helpers'

import { getQuestionDataSelector } from '../../../../author/QuestionEditor/ducks'
import {
  Layout,
  FontSizeOption,
  MaxWidthOption,
} from '../../../containers/WidgetOptions/components'
import { Row } from '../../../styled/WidgetOptions/Row'
import { Col } from '../../../styled/WidgetOptions/Col'
import Question from '../../../components/Question'
import {
  changeUIStyleAction,
  changeItemAction,
} from '../../../../author/src/actions/question'

class LayoutComponent extends Component {
  render() {
    const {
      item,
      changeUIStyle,
      changeItem,
      t,
      advancedAreOpen,
      fillSections,
      cleanSections,
    } = this.props

    return (
      <Question
        section="advanced"
        label={t('component.options.display')}
        advancedAreOpen={advancedAreOpen}
        fillSections={fillSections}
        cleanSections={cleanSections}
      >
        <Layout
          id={getFormattedAttrId(
            `${item?.title}-${t('component.options.display')}`
          )}
        >
          <Row gutter={36}>
            <Col md={12}>
              <MaxWidthOption
                onChange={(val) => changeItem('max_width', +val)}
                value={get(item, 'max_width', 900)}
              />
            </Col>
            <Col md={12}>
              <FontSizeOption
                data-cy="fontSizeSelect"
                onChange={(val) => changeUIStyle('fontsize', val)}
                value={get(item, 'uiStyle.fontsize', 'normal')}
              />
            </Col>
          </Row>
        </Layout>
      </Question>
    )
  }
}

LayoutComponent.propTypes = {
  t: PropTypes.func.isRequired,
  changeItem: PropTypes.func.isRequired,
  changeUIStyle: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  advancedAreOpen: PropTypes.bool,
  fillSections: PropTypes.func,
  cleanSections: PropTypes.func,
}

LayoutComponent.defaultProps = {
  advancedAreOpen: false,
  fillSections: () => {},
  cleanSections: () => {},
}

const enhance = compose(
  withNamespaces('assessment'),
  connect(
    (state) => ({
      item: getQuestionDataSelector(state),
    }),
    {
      changeItem: changeItemAction,
      changeUIStyle: changeUIStyleAction,
    }
  )
)

export default enhance(LayoutComponent)
