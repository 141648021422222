import React from 'react'
import styled from 'styled-components'
import { parcThemeColor } from '@edulastic/colors'

const MaskIcon = ({ hovered }) => {
  const opacity = hovered ? 0.3 : 1
  return (
    <Svg>
      <rect
        x="0%"
        y="0"
        width="100%"
        height="100%"
        fill={parcThemeColor}
        fillOpacity={opacity}
      />
    </Svg>
  )
}

export default MaskIcon

const Svg = styled.svg`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
`
