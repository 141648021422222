import styled from 'styled-components'
import { StyledPaperWrapper } from '../../../styled/Widget'

export const PreviewContainer = styled(StyledPaperWrapper)`
  position: relative;
  border-radius: 0px;
  overflow: hidden;
  overflow-x: auto;
  audio {
    position: relative;
    z-index: 1000 !important;
  }
`
