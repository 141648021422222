/* eslint-disable react/prop-types */
import React from "react";
import withIconStyles from "./HOC/withIconStyles";
import SVG from "./common/SVG";

const IconHighlight = props => (
  <SVG xmlns="http://www.w3.org/2000/svg" width="17.519" height="19.835" viewBox="0 0 17.519 19.835" {...props}>
    <g transform="translate(-473.961 -61.42)">
      <path
        d="M.675-2.08a.076.076,0,0,1,.08.06l.68,1.69c.06.12-.01.15-.1.18-.05.02-.09.04-.09.08s.06.07.15.08c.63.04,1.68.02,2.1.02.13,0,.17-.03.17-.09s-.04-.09-.15-.09a1.829,1.829,0,0,1-.33-.02c-.21-.04-.46-.17-.78-.92C1.845-2.37.365-5.78.2-6.27c-.1-.22-.15-.31-.23-.31-.11,0-.21.19-.39.66L-2.545-.91c-.23.58-.37.7-1,.76-.07,0-.12.04-.12.09,0,.07.05.09.16.09.46,0,.96-.03,1.06-.03.31,0,.67.03.98.03.09,0,.16-.02.16-.09,0-.04-.03-.08-.15-.08-.19,0-.5-.01-.5-.26a1.832,1.832,0,0,1,.13-.52l.44-1.09c.02-.05.04-.07.08-.07Zm-1.78-.5c-.04,0-.05-.02-.04-.05l.79-2.04c.04-.1.07-.08.1,0l.78,2.04c.01.03.01.05-.04.05Z"
        transform="translate(478 68)"
        fill="#66717a"
      />
      <g transform="translate(473.961 60.144)">
        <g transform="translate(0 3.856)">
          <path
            d="M2.878,20.281A3.3,3.3,0,0,1,5.244,19.4a4.86,4.86,0,0,1,1.009.105.877.877,0,0,0,1.17-.061l.412-.415,9.49-10.967a.877.877,0,0,0-.061-1.17L14.535,4.114a.877.877,0,0,0-1.2-.044L2.506,13.625l-.044.038-.026.029-.389.383a.877.877,0,0,0-.061,1.17A3.812,3.812,0,0,1,1.22,18.62a.292.292,0,0,0-.05.328L.085,20.026a.292.292,0,0,0,.076.468l1.17.585a.293.293,0,0,0,.132.032h9.358a.292.292,0,0,0,0-.585H2.3l.24-.193A.292.292,0,0,0,2.878,20.281ZM1.831,18.818a4.492,4.492,0,0,0,.708-3.784.293.293,0,0,0-.07-.129.3.3,0,0,1,0-.418l.2-.2L7.218,18.83l-.208.2a.292.292,0,0,1-.412,0,.33.33,0,0,0-.132-.076,5.319,5.319,0,0,0-1.225-.14,3.907,3.907,0,0,0-2.562.848Z"
            transform="translate(0 -3.856)"
            fill="#66707a"
          />
        </g>
      </g>
    </g>
  </SVG>
);

export default withIconStyles(IconHighlight);
