import { invert } from 'lodash'

import {
  TEST_TYPES_VALUES_MAP,
  ALL_TEST_TYPES_VALUES,
  FEED_TYPES_WITH_LEXILE_SCORE,
  FEED_TYPES_WITH_QUANTILE_SCORE,
} from '@edulastic/constants/const/testTypes'

import { DW_GOALS_AND_INTERVENTIONS_URL } from '../../../../common/constants/dataWarehouseReports'

export const compareByKeys = {
  DISTRICT: 'district',
  SCHOOL: 'school',
  TEACHER: 'teacher',
  CLASS: 'class',
  GROUP: 'group',
  STUDENT: 'student',
  RACE: 'race',
  GENDER: 'gender',
  FRL_STATUS: 'frlStatus',
  ELL_STATUS: 'ellStatus',
  IEP_STATUS: 'iepStatus',
  HISPANIC_ETHNICITY: 'hispanicEthnicity',
}

const demographicFilterFields = {
  [compareByKeys.RACE]: compareByKeys.RACE,
  [compareByKeys.GENDER]: compareByKeys.GENDER,
  [compareByKeys.FRL_STATUS]: compareByKeys.FRL_STATUS,
  [compareByKeys.ELL_STATUS]: compareByKeys.ELL_STATUS,
  [compareByKeys.IEP_STATUS]: compareByKeys.IEP_STATUS,
  [compareByKeys.HISPANIC_ETHNICITY]: compareByKeys.HISPANIC_ETHNICITY,
}

export const compareByOptionsInfo = {
  [compareByKeys.DISTRICT]: { key: 'districtId', name: 'districtName' },
  [compareByKeys.SCHOOL]: { key: 'schoolId', name: 'schoolName' },
  [compareByKeys.TEACHER]: { key: 'teacherId', name: 'teacherName' },
  [compareByKeys.CLASS]: { key: 'groupId', name: 'groupName' },
  [compareByKeys.GROUP]: { key: 'groupId', name: 'groupName' },
  [compareByKeys.STUDENT]: { key: 'studentId', name: 'studentName' },
  [compareByKeys.RACE]: {
    key: compareByKeys.RACE,
    name: compareByKeys.RACE,
  },
  [compareByKeys.GENDER]: {
    key: compareByKeys.GENDER,
    name: compareByKeys.GENDER,
  },
  [compareByKeys.FRL_STATUS]: {
    key: compareByKeys.FRL_STATUS,
    name: compareByKeys.FRL_STATUS,
  },
  [compareByKeys.ELL_STATUS]: {
    key: compareByKeys.ELL_STATUS,
    name: compareByKeys.ELL_STATUS,
  },
  [compareByKeys.IEP_STATUS]: {
    key: compareByKeys.IEP_STATUS,
    name: compareByKeys.IEP_STATUS,
  },
  [compareByKeys.HISPANIC_ETHNICITY]: {
    key: compareByKeys.HISPANIC_ETHNICITY,
    name: compareByKeys.HISPANIC_ETHNICITY,
  },
}

export const compareByFieldKeys = Object.keys(compareByOptionsInfo).reduce(
  (res, ele) => {
    return { ...res, [ele]: compareByOptionsInfo[ele].key }
  },
  {}
)

export const compareByKeysToFilterKeys = {
  [compareByKeys.DISTRICT]: 'districtIds',
  [compareByKeys.SCHOOL]: 'schoolIds',
  [compareByKeys.TEACHER]: 'teacherIds',
  [compareByKeys.CLASS]: 'classIds',
  [compareByKeys.GROUP]: 'groupIds',
  ...demographicFilterFields,
}

export const filterKeysToCompareByKeys = invert(compareByKeysToFilterKeys)

export const commonFilterKeys = [
  compareByKeysToFilterKeys[compareByKeys.DISTRICT],
  compareByKeysToFilterKeys[compareByKeys.SCHOOL],
  compareByKeysToFilterKeys[compareByKeys.TEACHER],
  compareByKeysToFilterKeys[compareByKeys.CLASS],
  compareByKeysToFilterKeys[compareByKeys.GROUP],
  compareByKeys.RACE,
  compareByKeys.GENDER,
  compareByKeys.FRL_STATUS,
  compareByKeys.ELL_STATUS,
  compareByKeys.IEP_STATUS,
  compareByKeys.HISPANIC_ETHNICITY,
]

export const nextCompareByKeys = {
  [compareByKeys.DISTRICT]: compareByKeys.SCHOOL,
  [compareByKeys.SCHOOL]: compareByKeys.TEACHER,
  [compareByKeys.TEACHER]: compareByKeys.CLASS,
  [compareByKeys.CLASS]: compareByKeys.STUDENT,
  [compareByKeys.GROUP]: compareByKeys.STUDENT,
  [compareByKeys.RACE]: compareByKeys.STUDENT,
  [compareByKeys.GENDER]: compareByKeys.STUDENT,
  [compareByKeys.FRL_STATUS]: compareByKeys.STUDENT,
  [compareByKeys.ELL_STATUS]: compareByKeys.STUDENT,
  [compareByKeys.IEP_STATUS]: compareByKeys.STUDENT,
  [compareByKeys.HISPANIC_ETHNICITY]: compareByKeys.STUDENT,
}

export const compareByOptions = [
  {
    key: compareByKeys.DISTRICT,
    title: 'District',
    hiddenFromRole: ['district-admin', 'school-admin', 'teacher'],
  },
  { key: compareByKeys.SCHOOL, title: 'School', hiddenFromRole: ['teacher'] },
  { key: compareByKeys.TEACHER, title: 'Teacher', hiddenFromRole: ['teacher'] },
  { key: compareByKeys.CLASS, title: 'Class' },
  {
    key: compareByKeys.GROUP,
    title: 'Student Group',
    hiddenFromRole: ['district-group-admin'],
  },
  {
    key: compareByKeys.STUDENT,
    title: 'Student',
    hiddenFromRole: ['district-group-admin'],
  },
  {
    key: compareByKeys.RACE,
    title: 'Race',
  },
  {
    key: compareByKeys.GENDER,
    title: 'Gender',
  },
  {
    key: compareByKeys.FRL_STATUS,
    title: 'FRL Status',
  },
  {
    key: compareByKeys.ELL_STATUS,
    title: 'ELL Status',
  },
  {
    key: compareByKeys.IEP_STATUS,
    title: 'IEP Status',
  },
  {
    key: compareByKeys.HISPANIC_ETHNICITY,
    title: 'Hispanic Ethnicity',
  },
]

export const INTERNAL_TEST_TYPES_ORDER = ALL_TEST_TYPES_VALUES.reduce(
  (acc, curr, currIndex) => {
    if (curr === TEST_TYPES_VALUES_MAP.COMMON_ASSESSMENT) acc[curr] = 0
    else if (curr === TEST_TYPES_VALUES_MAP.SCHOOL_COMMON_ASSESSMENT)
      acc[curr] = 1
    else if (curr === TEST_TYPES_VALUES_MAP.ASSESSMENT) acc[curr] = 2
    else acc[curr] = currIndex
    return acc
  },
  {}
)

export const trendPeriodDateFormat = `MMM'YY`
export const trendPeriodPrefix = 'vs '

export const createStudentGroupUrl = `${DW_GOALS_AND_INTERVENTIONS_URL}?subActiveKey=2`

export const PIE_CHART_LABEL_THRESHOLD = 5

export const EXTERNAL_SCORE_TYPES = {
  SCALED_SCORE: 'score',
  LEXILE_SCORE: 'lexileScore',
  QUANTILE_SCORE: 'quantileScore',
}

export const EXTERNAL_SCORE_TYPES_LIST = [
  { key: EXTERNAL_SCORE_TYPES.SCALED_SCORE, title: 'Scaled Score' },
  { key: EXTERNAL_SCORE_TYPES.LEXILE_SCORE, title: 'Lexile Score' },
  { key: EXTERNAL_SCORE_TYPES.QUANTILE_SCORE, title: 'Quantile Score' },
]

export const EXTERNAL_SCORE_TYPES_TO_TEST_TYPES = {
  [EXTERNAL_SCORE_TYPES.LEXILE_SCORE]: FEED_TYPES_WITH_LEXILE_SCORE,
  [EXTERNAL_SCORE_TYPES.QUANTILE_SCORE]: FEED_TYPES_WITH_QUANTILE_SCORE,
}

export const EXTERNAL_SCORE_PREFIX = {
  [EXTERNAL_SCORE_TYPES.LEXILE_SCORE]: 'BR',
  [EXTERNAL_SCORE_TYPES.QUANTILE_SCORE]: 'EM',
}

export const EXTERNAL_SCORE_SUFFIX = {
  [EXTERNAL_SCORE_TYPES.LEXILE_SCORE]: 'L',
  [EXTERNAL_SCORE_TYPES.QUANTILE_SCORE]: 'Q',
}

export const EXTERNAL_SCORE_TOOLTIP_TEXT = {
  [EXTERNAL_SCORE_TYPES.SCALED_SCORE]: '',
  [EXTERNAL_SCORE_TYPES.LEXILE_SCORE]:
    'Lexile score is applicable for ELA assessments only',
  [EXTERNAL_SCORE_TYPES.QUANTILE_SCORE]:
    'Quantile score is applicable for Math assessments only',
}

export const ATTENDANCE_EVENT_CATEGORY_LABELS = {
  inAttendanceDays: 'Present',
  excusedAbsenceDays: 'Excused Absence',
  unexcusedAbsenceDays: 'Absent',
  tardyDays: 'Tardy',
  earlyDepartureDays: 'Early Departure',
  partialDays: 'Partial',
}

export const EXTERNAL_TAG_MAX_CHARS_COUNT = 10

export const RISK_LABEL_SUFFIX = ' / At Promise'

export const FILTER_TABS = {
  STUDENT_FILTERS: {
    key: '0',
    title: 'Select Student Set',
  },
  TEST_FILTERS: {
    key: '1',
    title: 'Select Tests',
  },
  PERFORMANCE_FILTERS: {
    key: 2,
    title: 'Performance',
  },
  DEMOGRAPHIC_FILTERS: {
    key: '3',
    title: 'Demographics',
  },
  PERIOD: {
    key: '3',
    title: 'Duration',
  },
}

export const staticDropDownData = {
  tagTypes: [
    { key: 'termId', tabKey: '0' },
    { key: 'districtIds', tabKey: '0' },
    { key: 'schoolIds', tabKey: '0' },
    { key: 'teacherIds', tabKey: '0' },
    { key: 'grades', subType: 'class', tabKey: '0' },
    { key: 'subjects', subType: 'class', tabKey: '0' },
    { key: 'assignedBy', tabKey: '0' },
    { key: 'courseId', tabKey: '0' },
    { key: 'classIds', tabKey: '0' },
    { key: 'groupIds', tabKey: '0' },
    { key: 'testTermIds', subType: 'test', tabKey: '1' },
    { key: 'testGrades', subType: 'test', tabKey: '1' },
    { key: 'testSubjects', subType: 'test', tabKey: '1' },
    { key: 'testIds', subType: 'test', tabKey: '1' },
    { key: 'tagIds', tabKey: '1' },
    { key: 'assessmentTypes', tabKey: '1' },
    { key: 'testUniqIds', tabKey: '1' },
    { key: 'profileId', tabKey: '2' },
    { key: 'race', tabKey: '3' },
    { key: 'gender', tabKey: '3' },
    { key: 'iepStatus', tabKey: '3' },
    { key: 'frlStatus', tabKey: '3' },
    { key: 'ellStatus', tabKey: '3' },
    { key: 'hispanicEthnicity', tabKey: '3' },
  ],
  subjects: [
    { key: 'Mathematics', title: 'Mathematics' },
    { key: 'ELA', title: 'ELA' },
    { key: 'Science', title: 'Science' },
    { key: 'Social Studies', title: 'Social Studies' },
    { key: 'Computer Science', title: 'Computer Science' },
    { key: 'Other Subjects', title: 'Other Subjects' },
  ],
  grades: [
    { key: 'TK', title: 'PreKindergarten' },
    { key: 'K', title: 'Kindergarten' },
    { key: '1', title: 'Grade 1' },
    { key: '2', title: 'Grade 2' },
    { key: '3', title: 'Grade 3' },
    { key: '4', title: 'Grade 4' },
    { key: '5', title: 'Grade 5' },
    { key: '6', title: 'Grade 6' },
    { key: '7', title: 'Grade 7' },
    { key: '8', title: 'Grade 8' },
    { key: '9', title: 'Grade 9' },
    { key: '10', title: 'Grade 10' },
    { key: '11', title: 'Grade 11' },
    { key: '12', title: 'Grade 12' },
    { key: 'O', title: 'Other' },
  ],
  assignedBy: [
    { key: 'anyone', title: 'Anyone' },
    { key: 'me', title: 'Me' },
  ],
}
