import React, { useEffect, useState } from 'react'
import {
  EduButton,
  FieldLabel,
  FlexContainer,
  SelectInputStyled,
} from '@edulastic/common'
import { compose } from 'redux'
import { withNamespaces } from 'react-i18next'
import { connect } from 'react-redux'
import { Select } from 'antd'
import { isEmpty, isEqual } from 'lodash'
import { dictionaries } from '@edulastic/constants'
import { backgroundGrey } from '@edulastic/colors'

import { StandardsContainer } from '../styled'
import {
  getAllFormattedCurriculumsSelector,
  getCurriculumsListSelector,
  getStandardsListSelector,
  standardsSelector,
} from '../../src/selectors/dictionaries'
import { selectsData } from '../../TestPage/components/common'
import StandardsModal from '../../../assessment/containers/QuestionMetadata/StandardsModal'
import { getDictStandardsForCurriculumAction } from '../../src/actions/dictionaries'
import { StyledDiv } from '../../../assessment/containers/QuestionMetadata/styled/ELOList'
import {
  getAllInterestedCurriculumsSelector,
  getDefaultGradesSelector,
  getDefaultSubjectSelector,
  getInterestedGradesSelector,
} from '../../src/selectors/user'
import { getDefaultInterests } from '../../dataUtils'
import {
  getTestEntityGradesSelector,
  getTestEntitySubjectsSelector,
} from '../../TestPage/ducks'

const StandardsBox = ({
  t,
  handleOnClickExpand,
  handleOnCancel,
  formattedCuriculums,
  alignment,
  curriculums,
  curriculumStandards,
  onUpdateAlignment,
  curriculumStandardsLoading,
  getCurriculumStandardsAction,
  defaultSubject,
  defaultCurriculumId,
  interestedCurriculums,
  testSelectedSubjects,
  testSelectedGrades,
  defaultGrades,
  interestedGrades,
}) => {
  const {
    subject = 'Mathematics',
    curriculumId = 212,
    curriculum = 'Math - Common Core',
    grades = ['7'],
    standards = [],
  } = alignment
  const curriculumStandardsELO = curriculumStandards.elo
  const standardsArr = standards.map((el) => el.identifier)

  const [showModal, setShowModal] = useState(false)
  const [searchProps, setSearchProps] = useState({
    id: '',
    grades: [],
    searchStr: '',
  })

  const searchCurriculumStandards = (searchObject) => {
    if (!isEqual(searchProps, searchObject)) {
      setSearchProps(searchObject)
      getCurriculumStandardsAction(
        searchObject.id,
        searchObject.grades,
        searchObject.searchStr
      )
    }
  }

  const handleSearchStandard = (searchStr) => {
    searchCurriculumStandards({ id: curriculumId, grades, searchStr })
  }

  const handleStandardFocus = () => {
    searchCurriculumStandards({ id: curriculumId, grades, searchStr: '' })
  }

  const handleEditAlignment = (standardSet) => {
    onUpdateAlignment({
      ...alignment,
      ...standardSet,
    })
  }

  useEffect(() => {
    // Below code is a copy of QuestionMetadata/AlignmentRow.js default values prepopulation.
    // Any time logic changes we need to modify here as well.
    const { curriculumId: alCurriculumId } = alignment
    const defaultInterests = getDefaultInterests()
    const getCurriculumDetails = (
      curriculumIdentifier,
      testSubject,
      testGrades
    ) => {
      const _curriculum =
        curriculums.find(
          (item) => item._id === parseInt(curriculumIdentifier, 10)
        ) || {}
      const _curriculumName =
        !isEmpty(testSubject) && testSubject !== _curriculum.subject
          ? ''
          : _curriculum.curriculum || ''

      const _curriculumId = _curriculumName
        ? parseInt(curriculumIdentifier, 10) || ''
        : ''

      const _alignmentSubject = _curriculumName
        ? _curriculum.subject || ''
        : testSubject || ''

      const _alignmentGrades = (_curriculum?.grades || []).filter((grade) =>
        (testGrades || []).includes(grade)
      )

      return {
        _curriculumName,
        _curriculumId,
        _alignmentGrades: _alignmentGrades.length
          ? _alignmentGrades
          : testGrades,
        _alignmentSubject,
      }
    }

    let _subject = defaultInterests?.subject
    _subject = (Array.isArray(_subject) ? _subject[0] : _subject) || ''

    const testSubject = testSelectedSubjects?.length
      ? testSelectedSubjects[0]
      : ''
    const testGrades = testSelectedGrades?.length ? testSelectedGrades : null

    if (!alCurriculumId) {
      if (defaultInterests.curriculumId) {
        const {
          _curriculumName,
          _curriculumId,
          _alignmentSubject,
          _alignmentGrades,
        } = getCurriculumDetails(
          defaultInterests.curriculumId,
          testSubject,
          testGrades
        )

        handleEditAlignment({
          subject: _alignmentSubject || _subject,
          curriculum: _curriculumName,
          curriculumId: _curriculumId,
          grades:
            _alignmentGrades || defaultInterests.grades?.length
              ? defaultInterests.grades
              : [],
        })
      } else if (defaultSubject && defaultCurriculumId) {
        const {
          _curriculumName,
          _curriculumId,
          _alignmentSubject,
          _alignmentGrades,
        } = getCurriculumDetails(defaultCurriculumId, testSubject, testGrades)
        handleEditAlignment({
          subject: _alignmentSubject || defaultSubject,
          curriculum: _curriculumName,
          curriculumId: _curriculumId,
          grades: _alignmentGrades || defaultGrades || interestedGrades || [],
        })
      } else if (interestedCurriculums && interestedCurriculums.length > 0) {
        const {
          _curriculumName,
          _curriculumId,
          _alignmentSubject,
          _alignmentGrades,
        } = getCurriculumDetails(
          interestedCurriculums[0]._id,
          testSubject,
          testGrades
        )

        handleEditAlignment({
          subject: _alignmentSubject || interestedCurriculums[0].subject,
          curriculum: _curriculumName,
          curriculumId: _curriculumId,
          grades: _alignmentGrades || defaultGrades || interestedGrades || [],
        })
      } else {
        const {
          _curriculumName,
          _curriculumId,
          _alignmentSubject,
          _alignmentGrades,
        } = getCurriculumDetails(212, testSubject, testGrades)

        handleEditAlignment({
          subject: _alignmentSubject || 'Mathematics',
          curriculumId: _curriculumId,
          curriculum: _curriculumName,
          grades: _alignmentGrades || ['7'],
          standards: [],
        })
      }
    }
  }, [curriculums])

  const setSubject = (val) => {
    handleEditAlignment({ subject: val, curriculum: '' })
  }

  const setGrades = (val) => {
    handleEditAlignment({ grades: val })
  }

  const handleChangeStandard = (_curriculum, event) => {
    const _curriculumId = parseInt(event.key, 10)
    handleEditAlignment({
      curriculumId: _curriculumId,
      curriculum: _curriculum,
    })
  }

  const handleAddStandard = (newStandard) => {
    let newStandards = standards.some(
      (standard) => standard._id === newStandard._id
    )
    if (newStandards) {
      newStandards = standards.filter(
        (standard) => standard._id !== newStandard._id
      )
    } else {
      newStandards = [...standards, newStandard]
    }
    handleEditAlignment({
      standards: newStandards,
    })
  }

  const handleStandardSelect = (chosenStandardsArr, option) => {
    const {
      _id,
      level,
      grades: _grades,
      identifier,
      tloIdentifier,
      tloId,
      tloDescription,
      eloId,
      subEloId,
      description,
      curriculumId: _curriculumId,
    } = option.props.obj

    handleAddStandard({
      _id,
      level,
      grades: _grades,
      identifier,
      tloIdentifier,
      tloId,
      tloDescription,
      eloId,
      subEloId,
      description,
      curriculumId: _curriculumId,
    })
  }

  const handleStandardDeselect = (removedElement) => {
    const newStandards = standards.filter(
      (el) => el.identifier !== removedElement
    )
    handleEditAlignment({ standards: newStandards })
  }

  const handleApply = (data) => {
    let { subject: _subject } = data
    if (!_subject) {
      const curriculumFromStandard = data.standard.id
        ? formattedCuriculums.find((c) => c.value === data.standard.id) || {}
        : {}
      _subject = curriculumFromStandard?.subject
    }
    handleEditAlignment({
      subject: data.subject,
      curriculum: data.standard.curriculum,
      curriculumId: data.standard.id,
      grades: data.grades,
      standards: data.eloStandards,
    })

    setShowModal(false)
  }

  const showMoreButtonEnabled =
    !curriculumStandardsLoading &&
    curriculumStandardsELO &&
    curriculumStandardsELO.length >= dictionaries.STANDARD_DROPDOWN_LIMIT_1000

  return (
    <StandardsContainer className="" data-cy="ratingContainer" isFullScreen>
      <FlexContainer
        flexDirection="column"
        padding="20px"
        flexProps={{
          border: `4px solid ${backgroundGrey}`,
          borderWidth: '0px 0px 4px 0px',
        }}
      >
        <div style={{ marginBottom: '20px', width: '80%' }}>
          <FieldLabel>{t('component.options.subject')}</FieldLabel>
          <SelectInputStyled
            data-cy="subjectSelect"
            value={subject}
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={setSubject}
          >
            {selectsData.allSubjects.map(({ text, value }) =>
              value ? (
                <Select.Option key={value} value={value}>
                  {text}
                </Select.Option>
              ) : (
                ''
              )
            )}
          </SelectInputStyled>
        </div>
        <div style={{ marginBottom: '20px', width: '80%' }}>
          <FieldLabel>{t('component.options.standardSet')}</FieldLabel>
          <SelectInputStyled
            data-cy="standardSetSelect"
            showSearch
            filterOption
            value={curriculum}
            dropdownClassName="custom-antd-select"
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={handleChangeStandard}
          >
            {formattedCuriculums.map(({ value, text, disabled }) => (
              <Select.Option key={value} value={text} disabled={disabled}>
                {text}
              </Select.Option>
            ))}
          </SelectInputStyled>
        </div>
        <div style={{ marginBottom: '20px', width: '80%' }}>
          <FieldLabel>{t('component.options.grade')}</FieldLabel>
          <SelectInputStyled
            data-cy="gradeSelect"
            mode="multiple"
            showSearch
            getPopupContainer={(trigger) => trigger.parentNode}
            value={grades}
            onChange={setGrades}
          >
            {selectsData.allGrades.map(({ text, value }) => (
              <Select.Option key={text} value={value}>
                {text}
              </Select.Option>
            ))}
          </SelectInputStyled>
        </div>
        <div
          data-cy="searchStandardSelectCriteria"
          style={{ marginBottom: '20px', width: '80%' }}
        >
          <FieldLabel>{t('component.options.searchStandards')}</FieldLabel>
          <SelectInputStyled
            data-cy="searchStandardSelectCriteria"
            mode="multiple"
            style={{ margin: 'auto', display: 'block' }}
            placeholder={t('component.options.searchStandards')}
            filterOption={false}
            value={standardsArr}
            optionLabelProp="title"
            getPopupContainer={(trigger) => trigger.parentNode}
            onFocus={handleStandardFocus}
            onSearch={handleSearchStandard}
            onSelect={handleStandardSelect}
            onDeselect={handleStandardDeselect}
          >
            {!curriculumStandardsLoading &&
              curriculumStandardsELO &&
              curriculumStandardsELO.length > 0 &&
              curriculumStandardsELO.map((el) => (
                <Select.Option
                  title={el.identifier}
                  key={el._id}
                  value={el.identifier}
                  obj={el}
                  style={{ whiteSpace: 'normal' }}
                >
                  <div>
                    <div>
                      <b>{el.identifier}</b>
                    </div>
                    <div
                      className="selected-item-desctiption"
                      dangerouslySetInnerHTML={{
                        __html: el.description,
                      }}
                    />
                  </div>
                </Select.Option>
              ))}
            {showMoreButtonEnabled && (
              <Select.Option
                title="Show More"
                value="show"
                style={{ display: 'block', cursor: 'pointer' }}
                disabled
              >
                <StyledDiv onClick={() => setShowModal(true)}>
                  <span>Show More</span>
                </StyledDiv>
              </Select.Option>
            )}
          </SelectInputStyled>
        </div>
        <div>
          <EduButton
            width="20%"
            height="35px"
            isGhost
            onClick={() => setShowModal(true)}
            ml="0px"
            data-cy="browseStandardButton"
          >
            {t('component.options.browse')}
          </EduButton>
        </div>
      </FlexContainer>
      <FlexContainer padding="14px 14px 14px 14px" justifyContent="flex-end">
        <EduButton
          isGhost
          onClick={() => handleOnCancel()}
          data-cy="standardCancelButton"
        >
          cancel
        </EduButton>
        <EduButton
          onClick={() => handleOnClickExpand('', '')}
          data-cy="standardSavelButton"
        >
          save
        </EduButton>
      </FlexContainer>
      {showModal && (
        <StandardsModal
          t={t}
          defaultSubject={subject}
          defaultGrades={grades}
          defaultStandards={standards}
          defaultStandard={{ curriculum, id: curriculumId }}
          visible={showModal}
          curriculums={curriculums}
          onApply={handleApply}
          setSubject={setSubject}
          onCancel={() => setShowModal(false)}
          getCurriculumStandards={searchCurriculumStandards}
          curriculumStandardsLoading={curriculumStandardsLoading}
          showAllInterestedCurriculums
          isRubricFlow
        />
      )}
    </StandardsContainer>
  )
}

const mapStateToProps = (state, props) => ({
  formattedCuriculums: getAllFormattedCurriculumsSelector(
    state,
    props.alignment
  ),
  curriculumStandardsLoading: standardsSelector(state).loading,
  curriculums: getCurriculumsListSelector(state),
  curriculumStandards: getStandardsListSelector(state),
  testSelectedSubjects: getTestEntitySubjectsSelector(state),
  testSelectedGrades: getTestEntityGradesSelector(state),
  defaultSubject: getDefaultSubjectSelector(state),
  defaultCurriculumId: state?.dictionaries?.defaultCurriculumId,
  interestedCurriculums: getAllInterestedCurriculumsSelector(state),
  defaultGrades: getDefaultGradesSelector(state),
  interestedGrades: getInterestedGradesSelector(state),
})

const mapDispatchToProps = {
  getCurriculumStandardsAction: getDictStandardsForCurriculumAction,
}

const enhance = compose(
  withNamespaces('assessment'),
  connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(StandardsBox)
