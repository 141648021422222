import * as test from '@edulastic/constants/const/test'

const { testContentVisibility } = test

export const settingTitleTestId = 'setting-title-test-id'
export const settingSwitchButtonTestId = 'setting-switch-button-test-id'
export const settingDescriptionTestId = 'setting-description-test-id'

export const messageMapWhenContentNotRestrictedBySuperAdmin = {
  [testContentVisibility.ALWAYS]: { null: 'Items are always visible' },
  [testContentVisibility.GRADING]: {
    [testContentVisibility.SHOW_QTN_RUBRIC_PRE_GRADING_ASSIGNMENT]:
      'Auto-gradable items are not visible to teachers until all students submit. Teachers can view and grade constructed response items',
    [testContentVisibility.SHOW_RUBRIC_PRE_GRADING_ASSIGNMENT]:
      'Auto-gradable items are not visible to teachers until all students submit. Teachers can view rubric and student response and grade constructive response items.',
    null: 'Items are not visible to teachers until all students submit.',
  },
  [testContentVisibility.HIDDEN]: {
    [testContentVisibility.SHOW_QTN_RUBRIC_CONTENT_VIS_HIDDEN]:
      'Auto-gradable items are not visible to teachers. Teachers can view and grade constructive response items.',
    [testContentVisibility.SHOW_RUBRIC_CONTENT_VIS_HIDDEN]:
      'Auto-gradable items are not visible to teachers. Teachers can view rubric and student response and grade constructed response items.',

    null: 'Items are not visible to teachers.',
  },
}

export const messageMapWhenContentRestrictedBySuperAdmin = {
  [testContentVisibility.ALWAYS]: { null: 'Items are always visible' },
  [testContentVisibility.GRADING]: {
    [testContentVisibility.SHOW_QTN_RUBRIC_PRE_GRADING_ASSIGNMENT]:
      'Auto-gradable items are only visible to Super district admins until all students submit. Teachers can view and grade constructed response items.',
    [testContentVisibility.SHOW_RUBRIC_PRE_GRADING_ASSIGNMENT]:
      'Auto-gradable items are only visible to Super district admins until all students submit. Teachers can view rubric and students response and grade constructed response items.',
    null:
      'Items are only visible to district super admins until all students submit.',
  },
  [testContentVisibility.HIDDEN]: {
    [testContentVisibility.SHOW_QTN_RUBRIC_CONTENT_VIS_HIDDEN]:
      'Auto-gradable items are only visible to district super admins. Teachers can view and grade constructed response items.',
    [testContentVisibility.SHOW_RUBRIC_CONTENT_VIS_HIDDEN]:
      'Auto-gradable items are only visible to district super admins. Teachers can view rubric and students response and grade constructed response items.',

    null: 'Items are only visible to district super admins.',
  },
}
