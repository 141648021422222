import { groupBy, isEmpty } from 'lodash'
import memoizeOne from 'memoize-one'

export const getStandardWisePerformance = (
  testActivities,
  std,
  useOnlySubmitted = false
) => {
  const performanceStudentWise = {}
  // filter out absent test activities
  // When useOnlySubmitted is true, only the submitted tests will be used for calculations.
  const interestedTestActivities = testActivities.filter(
    (x) =>
      x.status === 'submitted' ||
      (!useOnlySubmitted && x.status === 'inProgress')
  )
  const _questionActivities = interestedTestActivities.flatMap(
    ({ studentId, questionActivities }) =>
      questionActivities.map((x) => ({ ...x, studentId }))
  )

  if (std && std.qIds) {
    /**
     * if qAct.qActId not present, then its a generated uqa from frontend.
     * we shouldn't consider those, since reports aren't considering it
     */
    let filteredQuestionActivities = _questionActivities.filter(
      (qAct) =>
        !(
          qAct.scoringDisabled ||
          qAct.disabled ||
          !qAct.maxScore ||
          (!qAct.score && qAct.score !== 0) ||
          !qAct.qActId
        )
    )

    // check if any of the filtered question activities match the qIds from standard
    const checkForStdQids = filteredQuestionActivities.some((qAct) =>
      std.qIds.includes(`${qAct.testItemId}_${qAct._id}`)
    )
    // if the above check fails, flatMap qIds from filtered questionActivities
    if (!checkForStdQids) {
      filteredQuestionActivities = filteredQuestionActivities.flatMap((qAct) =>
        qAct.qids
          ? qAct.qids.map((qid) => ({ ...qAct, qids: [qid], _id: qid }))
          : [qAct]
      )
    }

    const questionActivitiesByQid = groupBy(
      filteredQuestionActivities,
      (qAct) => `${qAct.testItemId}_${qAct._id}`
    )

    for (const qid of std.qIds) {
      let questionActs = questionActivitiesByQid[qid] || []
      if (!questionActs.length) {
        questionActs = filteredQuestionActivities.filter(({ qids }) =>
          qids.includes(qid.split('_')[1])
        )
      }
      for (const qAct of questionActs) {
        const { studentId } = qAct
        const rubricScoreObj = Object.values(qAct.rubricFeedback || {})
          .filter((v) => v.standardIds?.includes(std.id))
          .reduce(
            (acc, v) => ({
              score: (acc.score ?? 0) + v.score,
              maxScore: (acc.maxScore ?? 0) + v.maxScore,
            }),
            {}
          )
        const scoreObj = rubricScoreObj.maxScore ? rubricScoreObj : qAct
        if (!performanceStudentWise[studentId]) {
          performanceStudentWise[studentId] = {
            score: scoreObj.score,
            maxScore: scoreObj.maxScore,
            count: 1,
          }
        } else {
          performanceStudentWise[studentId].score += scoreObj.score
          performanceStudentWise[studentId].maxScore += scoreObj.maxScore
          performanceStudentWise[studentId].count++
        }
      }
    }
  }

  for (const key of Object.keys(performanceStudentWise)) {
    const { score, maxScore } = performanceStudentWise[key]
    performanceStudentWise[key] = maxScore ? score / maxScore : 0
  }
  return performanceStudentWise
}

export const getStandardWisePerformanceDetail = (
  testActivities,
  std,
  isPresentationMode = false,
  useOnlySubmitted = false
) => {
  const performanceStudentWise = {}
  // filter out absent test activities
  // When useOnlySubmitted is true, only the submitted tests will be used for calculations.
  const interestedTestActivities = testActivities.filter(
    (x) =>
      x.status === 'submitted' ||
      (!useOnlySubmitted && x.status === 'inProgress')
  )
  const _questionActivities = interestedTestActivities.flatMap(
    ({ studentId, studentName, fakeName, questionActivities }) =>
      questionActivities.map((x) => ({
        ...x,
        studentId,
        studentName,
        fakeName,
      }))
  )

  /**
   * if qAct.qActId not present, then its a generated uqa from frontend
   * we shouldn't consider those, since reports aren't considering it
   */
  let filteredQuestionActivities = _questionActivities.filter(
    (qAct) =>
      !(
        qAct.scoringDisabled ||
        qAct.disabled ||
        qAct.maxScore == 0 ||
        !qAct.qActId
      )
  )

  // check if any of the filtered question activities match the qIds from standard
  const checkForStdQids = filteredQuestionActivities.some((qAct) =>
    std.qIds.includes(`${qAct.testItemId}_${qAct._id}`)
  )
  // if the above check fails, flatMap qIds from filtered questionActivities
  if (!checkForStdQids) {
    filteredQuestionActivities = filteredQuestionActivities.flatMap((qAct) =>
      qAct.qids
        ? qAct.qids.map((qid) => ({ ...qAct, qids: [qid], _id: qid }))
        : qAct
    )
  }

  const questionActivitiesByQid = groupBy(
    filteredQuestionActivities,
    (qAct) => `${qAct.testItemId}_${qAct._id}`
  )

  for (const qid of std.qIds) {
    let questionActs = questionActivitiesByQid[qid] || []
    if (!questionActs.length) {
      questionActs = filteredQuestionActivities.filter(({ qids }) =>
        qids.includes(qid.split('_')[1])
      )
    }
    for (const qAct of questionActs) {
      const { studentId } = qAct

      const rubricScoreObj = Object.values(qAct.rubricFeedback || {})
        .filter((v) => v.standardIds?.includes(std.id))
        .reduce(
          (acc, v) => ({
            score: (acc.score ?? 0) + v.score,
            maxScore: (acc.maxScore ?? 0) + v.maxScore,
          }),
          {}
        )
      const scoreObj = rubricScoreObj.maxScore ? rubricScoreObj : qAct
      if (!performanceStudentWise[studentId]) {
        performanceStudentWise[studentId] = {
          score: scoreObj.score || 0,
          maxScore: scoreObj.maxScore,
          studentName: isPresentationMode ? qAct.fakeName : qAct.studentName,
          count: 1,
        }
      } else {
        performanceStudentWise[studentId].score += scoreObj.score || 0
        performanceStudentWise[studentId].maxScore += scoreObj.maxScore
        performanceStudentWise[studentId].count += 1
      }
    }
  }
  return performanceStudentWise
}

export const getStandardWisePerformanceDetailMemoized = memoizeOne(
  getStandardWisePerformanceDetail
)
export const getStandardWisePerformanceMemoized = memoizeOne(
  getStandardWisePerformance
)

export const getFilteredReportStandards = (
  reportStandards,
  selectedCurriculumId
) =>
  !isEmpty(selectedCurriculumId)
    ? reportStandards.filter(
        ({ curriculumId }) => curriculumId === +selectedCurriculumId
      )
    : reportStandards
