/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconListBar = (props) => (
  <SVG
    width="12"
    height="10"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.666748 6.99992H11.3334V5.66659H0.666748V6.99992ZM0.666748 9.66659H11.3334V8.33325H0.666748V9.66659ZM0.666748 4.33325H11.3334V2.99992H0.666748V4.33325ZM0.666748 0.333252V1.66659H11.3334V0.333252H0.666748Z"
      fill={props.color || '#777777'}
    />
  </SVG>
)

export default withIconStyles(IconListBar)
