/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconBin = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="18"
    viewBox="0 0 11 14"
    {...props}
  >
    <path d="M6.79552 0.856742V0.419189H3.87096V0.856742H0.41748V2.62707H10.2498V0.856742H6.79552Z" />
    <path d="M1.58763 13.581H9.08051L9.61865 3.177H1.04614L1.58763 13.581ZM6.18613 5.60869H7.11404V10.9104H6.18613V5.60869ZM3.5541 5.60869H4.48286V10.9104H3.5541V5.60869Z" />
  </SVG>
)

export default withIconStyles(IconBin)
