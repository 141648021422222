import aiAPI from '@edulastic/api/src/aiAPI'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import {
  AI_QUESTION_DIFFICULTY_LEVEL_VALUE_LABEL_MAP,
  AI_QUESTION_UPDATE_TYPES,
} from '../constants/form'

export const namespaceKey = 'aiEnhanceQuestion'

// actions constants
export const AI_ENHANCE_QUESTION = `[${namespaceKey}] enhance question`
export const SET_AI_ENHANCED_QUESTION_STATE = `[${namespaceKey}] set state`
export const RESET_AI_ENHANCED_QUESTION_STATE = `[${namespaceKey}] reset state`

// actions
export const enhanceAIQuestionAction = (payload) => ({
  type: AI_ENHANCE_QUESTION,
  payload,
})

export const setAIEnhancedQuestionStateAction = (payload) => ({
  type: SET_AI_ENHANCED_QUESTION_STATE,
  payload,
})

export const resetAIEnhancedQuestionStateAction = () => ({
  type: RESET_AI_ENHANCED_QUESTION_STATE,
})

// selectors
export const stateSelector = (state) => state?.[namespaceKey]

export const getAIEnhanceQuestionState = createSelector(
  stateSelector,
  (state) => state || {}
)

// initial state
const initialState = {
  apiStatus: null,
  result: null,
  errorMessage: null,
}

// reducer
export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_AI_ENHANCED_QUESTION_STATE:
      return {
        ...state,
        ...payload,
      }
    case RESET_AI_ENHANCED_QUESTION_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

// sagas
function* AIEnhanceQuestionSaga({ payload }) {
  const generalErrorMessage = 'Something went wrong'

  try {
    yield put(
      setAIEnhancedQuestionStateAction({
        apiStatus: 'INITIATED',
        result: null,
        errorMessage: null,
      })
    )
    const {
      type,
      questionType,
      options: { convertType, difficultyLevel } = {},
    } = payload
    let updatedQuestionQType = questionType
    const updatedQuestionDifficultyLevel =
      AI_QUESTION_DIFFICULTY_LEVEL_VALUE_LABEL_MAP[difficultyLevel]

    if (type === AI_QUESTION_UPDATE_TYPES.CONVERT) {
      updatedQuestionQType = convertType
    }

    const { result, requestId } = yield call(aiAPI.enhanceAIQuestion, payload)
    yield put(
      setAIEnhancedQuestionStateAction({
        apiStatus: 'SUCCESS',
        result: {
          ...result,
          updatedQuestionMetaData: {
            updatedQuestionQType,
            updatedQuestionDifficultyLevel,
          },
          requestId,
        },
        errorMessage: null,
      })
    )
  } catch (err) {
    yield put(
      setAIEnhancedQuestionStateAction({
        apiStatus: 'FAILED',
        result: null,
        errorMessage: err?.message || generalErrorMessage,
      })
    )
  }
}

export function* watcherSaga() {
  yield all([takeEvery(AI_ENHANCE_QUESTION, AIEnhanceQuestionSaga)])
}
