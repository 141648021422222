import React from 'react'
import { Row, Col } from 'antd'
import { connect } from 'react-redux'
import { compose } from 'redux'
import styled from 'styled-components'
import { getCurrentRubricDataSelector } from '../../ducks'

const SelectCriteriaOption = ({ handleOnClickExpand, currentRubricData }) => {
  return (
    <>
      <Row style={{ padding: '16px 16px 0 16px' }}>
        {currentRubricData?.criteria?.map(({ id, name }, index) => {
          return (
            <StyledCriteriaContainer
              key={id}
              span={24}
              onClick={() =>
                handleOnClickExpand(
                  id,
                  currentRubricData?.criteria[index].ratings[0].id
                )
              }
            >
              <Row type="flex" gutter={16} align="middle">
                <Col span={18}>
                  <span style={{ fontWeight: '600' }}>{name}</span>
                </Col>
              </Row>
            </StyledCriteriaContainer>
          )
        })}
      </Row>
    </>
  )
}

const enhance = compose(
  connect(
    (state) => ({
      currentRubricData: getCurrentRubricDataSelector(state),
    }),
    {}
  )
)

export default enhance(SelectCriteriaOption)

const StyledCriteriaContainer = styled(Col)`
  margin-bottom: 16px;
  cursor: pointer;
`
