import { Typography } from 'antd'
import React from 'react'
import {
  passageContentDataTestId,
  passageHeadingDataTestId,
  passageSourceDataTestId,
  passageTitleDataTestId,
} from '../constants/test'

const PassageView = ({ passage = {} }) => {
  const { heading = '', title = '', content = '', authorOrSource } = passage
  return (
    <>
      <Typography.Paragraph
        data-testid={passageHeadingDataTestId}
        data-cy={passageHeadingDataTestId}
      >
        <b>Heading: </b>
        {heading}
      </Typography.Paragraph>
      <Typography.Paragraph
        data-testid={passageTitleDataTestId}
        data-cy={passageTitleDataTestId}
      >
        <b>Title: </b>
        {title}
      </Typography.Paragraph>

      {authorOrSource && (
        <Typography.Paragraph
          data-testid={passageSourceDataTestId}
          data-cy={passageSourceDataTestId}
        >
          <b>Source/Author: </b>
          {authorOrSource}
        </Typography.Paragraph>
      )}
      <Typography.Paragraph
        data-testid={passageContentDataTestId}
        data-cy={passageContentDataTestId}
      >
        {content}
      </Typography.Paragraph>
    </>
  )
}

export default PassageView
