import React from 'react'
import styled from 'styled-components'
import {
  themeColor,
  lightGreen11,
  white,
  themeColorBlue,
} from '@edulastic/colors'
import { Row, Col, Tooltip } from 'antd'

export const RowWrapper = styled(Row)`
  display: flex;
  align-items: center;
`

export const LeftCol = styled(Col)`
  width: ${({ width }) => width || '45px'};
  span {
    display: grid;
  }
  svg {
    fill: white;
    width: 16px;
    height: 16px;
  }
`

export const CenterCol = styled(Col)``

export const CardText = styled.div`
  border: 1px solid ${lightGreen11};
  background-color: ${({ $whiteBg }) => ($whiteBg ? white : themeColorBlue)};
  border-radius: 4px;
  text-transform: uppercase;
  color: ${({ $whiteBg }) => ($whiteBg ? themeColor : white)};
  cursor: pointer;
  padding: 7px 16px;
  display: inline-block;
`

export const CardAction = styled.p`
  font-size: 11px;
  font-weight: 700;
  display: inline;
  white-space: nowrap;
`

const _Tooltip = ({ className, children, overlayClassName, ...props }) => (
  <Tooltip
    overlayClassName={`${overlayClassName || ''} ${className}`}
    {...props}
  >
    {children}
  </Tooltip>
)

/** @type {typeof Tooltip} */
export const TooltipStyled = styled(_Tooltip)`
  .ant-tooltip-inner {
    ${(p) => (p.$bgColor ? `background-color: ${p.$bgColor};` : '')}
    ${(p) => (p.$color ? `color: ${p.$color};` : '')}
    ${(p) => (p.$border ? `border: ${p.$border};` : '')}
    ${(p) => (p.$fontSize ? `font-size: ${p.$fontSize};` : '')}
    ${(p) => (p.$textAlign ? `text-align: ${p.$textAlign};` : '')}
  }
  .ant-tooltip-arrow::before {
    ${(p) => (p.$bgColor ? `background-color: ${p.$bgColor};` : '')}
    ${(p) => (p.$color ? `color: ${p.$color};` : '')}
    ${(p) => (p.$border ? `border: ${p.$border};` : '')}
  }
`

export const ButtonHolder = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  width: 100%;
  text-align: center;
  position: relative;
  margin-top: -18px;
  z-index: 31;
`
