import React from 'react'
import { Layout, Spin } from 'antd'
import { connect } from 'react-redux'
import HeaderSection from './Header/Header'
import MainContent from './Showcase/showcase'
import NewHeaderSection from './Header/NewHeader'
import { isGcpsDistrictSelector } from '../../src/selectors/user'
import { getDashboardSettingsSelector, getUserContextsLoading } from '../ducks'
import NewDashboard from './Showcase/components/NewDashboard'

const Dashboard = ({
  userId,
  history,
  dashboardSettings: { dashboardVersion },
  isGcpsDistrict,
  isUserContextLoading,
}) => {
  // For gcps district it is not important for the user context to load
  // as always old dashboard has to be loaded.
  const waitForUserContext = !isGcpsDistrict && isUserContextLoading
  if (!userId || waitForUserContext) {
    return <Spin />
  }
  return (
    <Layout>
      {dashboardVersion && !isGcpsDistrict ? (
        <>
          <NewHeaderSection history={history} />
          <NewDashboard />
        </>
      ) : (
        <>
          <HeaderSection history={history} />
          <MainContent />
        </>
      )}
    </Layout>
  )
}
export default connect((state) => ({
  userId: state?.user?.user?._id,
  isGcpsDistrict: isGcpsDistrictSelector(state, true),
  dashboardSettings: getDashboardSettingsSelector(state),
  isUserContextLoading: getUserContextsLoading(state),
}))(Dashboard)
