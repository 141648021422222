import { Icon, Tooltip } from 'antd'
import React from 'react'
import styled from 'styled-components'

const SummaryMessageContainer = styled.span`
  padding: 5px;
`

const AdaptiveTestSummaryIcon = () => {
  return (
    <SummaryMessageContainer>
      <Tooltip title="Considering student will attempt either Section 2 Easy or Hard, but not both">
        <Icon type="question-circle" style={{ color: 'grey' }} />
      </Tooltip>
    </SummaryMessageContainer>
  )
}

export default AdaptiveTestSummaryIcon
