import { Col, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { mediumDesktopExactWidth, white } from '@edulastic/colors'
import Card from '../../../Showcase/components/Myclasses/components/Card'

const ClassGridView = ({ activeClasses, userId }) => {
  return (
    <Row gutter={[{ xxl: 16, xl: 8, lg: 10 }, 20]}>
      {activeClasses.map((activeClass) => (
        <Col lg={{ span: 8 }} xl={{ span: 6 }} key={activeClass._id}>
          <CardContainer data-cy="classCardInGridView">
            <Card
              data={activeClass}
              userId={userId}
              activeClasses={activeClasses}
            />
          </CardContainer>
        </Col>
      ))}
    </Row>
  )
}

export default ClassGridView

export const CardContainer = styled.div`
  height: 250px;
  border-radius: 10px;
  border: 1px solid #dadae4;
  background: ${white};
  transform: scale(1);
  transition: 0.2s;

  .card-text {
    @media (max-width: ${mediumDesktopExactWidth}) {
      padding: 7px 10px;
    }
  }
`
