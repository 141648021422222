/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconCalc = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="47.227"
    height="47.227"
    viewBox="0 0 47.227 47.227"
    {...props}
  >
    <path
      d="M80.153,84H81.69v1.537a1.153,1.153,0,0,0,2.306,0V84h1.537a1.153,1.153,0,0,0,0-2.306H84V80.153a1.153,1.153,0,1,0-2.306,0V81.69H80.153a1.153,1.153,0,1,0,0,2.306Z"
      transform="translate(-71.418 -71.418)"
      fill="#b0bac1"
    />
    <path
      d="M313.153,116.306h4.611a1.153,1.153,0,1,0,0-2.306h-4.611a1.153,1.153,0,0,0,0,2.306Z"
      transform="translate(-282.198 -103.376)"
      fill="#b0bac1"
    />
    <path
      d="M313.533,367h-5.38a1.153,1.153,0,0,0,0,2.306h5.38a1.153,1.153,0,0,0,0-2.306Z"
      transform="translate(-277.536 -332.797)"
      fill="#b0bac1"
    />
    <path
      d="M313.533,317h-5.38a1.153,1.153,0,0,0,0,2.306h5.38a1.153,1.153,0,1,0,0-2.306Z"
      transform="translate(-277.536 -287.457)"
      fill="#b0bac1"
    />
    <path
      d="M94.81,317.337a1.153,1.153,0,0,0-1.63,0l-1.106,1.106-1.106-1.106a1.153,1.153,0,0,0-1.63,1.63l1.106,1.106-1.106,1.106a1.153,1.153,0,1,0,1.63,1.63l1.106-1.106,1.106,1.106a1.153,1.153,0,0,0,1.63-1.63L93.7,320.074l1.106-1.106A1.153,1.153,0,0,0,94.81,317.337Z"
      transform="translate(-80.537 -286.856)"
      fill="#b0bac1"
    />
    <path
      d="M44.828,25.012V4.151A4.155,4.155,0,0,0,40.678,0H4.151A4.155,4.155,0,0,0,0,4.151V40.678a4.155,4.155,0,0,0,4.151,4.151H25.012A14.29,14.29,0,0,0,44.828,25.012ZM42.061,4.151V21.937a14.268,14.268,0,0,0-18.263,0V2.767h16.88A1.385,1.385,0,0,1,42.061,4.151ZM4.151,2.767h16.88V21.031H2.767V4.151A1.385,1.385,0,0,1,4.151,2.767ZM2.767,40.678V23.8H21.031v1.215a14.268,14.268,0,0,0,.907,17.049H4.151A1.385,1.385,0,0,1,2.767,40.678Zm30.162,3.782a11.53,11.53,0,1,1,11.53-11.53A11.543,11.543,0,0,1,32.929,44.459Z"
      fill="#b0bac1"
    />
  </SVG>
)

export default withIconStyles(IconCalc)
