/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconPreview = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19.636 19.728"
    {...props}
  >
    <path
      d="M20.221,18.3l-1.727-1.727a4.091,4.091,0,0,0-2.587-6.248V7c0-.007,0-.014,0-.022a.833.833,0,0,0-.19-.53l0,0c-.016-.019-.032-.037-.049-.054l-.007-.007-.022-.022L9.519.245h0C9.5.228,9.483.212,9.465.2L9.448.182,9.406.151,9.386.137,9.341.11,9.322.1Q9.292.083,9.26.069L9.234.059,9.192.043,9.162.034,9.114.022,9.091.016c-.023,0-.047-.008-.07-.011L9,0,8.94,0H1.669A.838.838,0,0,0,.831.838v16.75a.838.838,0,0,0,.838.838h13.4a4.069,4.069,0,0,0,2.24-.67l1.727,1.727A.837.837,0,0,0,20.221,18.3Zm-2.733-3.968a2.42,2.42,0,1,1-2.42-2.42A2.422,2.422,0,0,1,17.488,14.331ZM9.765,2.859l3.282,3.282H9.765ZM2.506,1.675H8.09v5.3a.838.838,0,0,0,.838.838h5.3v2.506a4.091,4.091,0,0,0-2.463,6.428H2.506Z"
      transform="translate(-0.831)"
    />
  </SVG>
)

export default withIconStyles(IconPreview)
