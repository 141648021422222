export const TTS_NAV_NEXT = 'next'
export const TTS_NAV_PREV = 'previous'

export const pageType = {
  itemList: 'itemList',
  addItems: 'addItems',
  review: 'review',
  itemAuthoring: 'itemAuthoring',
  REPORTS: 'reports',
}
