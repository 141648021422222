import React from 'react'
import { MdDelete } from 'react-icons/md'
import { FiDownload } from 'react-icons/fi'

import { notification, FlexContainer } from '@edulastic/common'

import { dwLogStatus } from '../utils/constants'
import { TextButton } from '../../common/components/StyledComponents'

const ViewUploadedRecords = ({ url }) => (
  <a
    data-cy="viewUploadedRecords"
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    download
  >
    <FlexContainer
      alignItems="center"
      justifyContent="left"
      style={{ fontWeight: 600 }}
    >
      <FiDownload />
      &nbsp;&nbsp; View Uploaded Records
    </FlexContainer>
  </a>
)

const DeleteRecords = ({ record, onDelete }) => {
  const { status } = record
  const disableDelete = status === dwLogStatus.IN_PROGRESS
  const onDeleteHandler = () => {
    if (disableDelete) {
      notification({
        type: 'info',
        msg: 'Delete is disabled as upload is currently processing.',
      })
      return
    }
    onDelete(record)
  }
  return (
    <TextButton data-cy="deleteButton" onClick={onDeleteHandler} $width="100%">
      <FlexContainer
        alignItems="center"
        justifyContent="left"
        style={{ opacity: disableDelete ? '0.5' : '1' }}
      >
        <MdDelete />
        &nbsp;&nbsp;Delete
      </FlexContainer>
    </TextButton>
  )
}

export { ViewUploadedRecords, DeleteRecords }
