import { white } from '@edulastic/colors'
import { EduElse, EduIf, EduThen, notification } from '@edulastic/common'
import IconMagicWand from '@edulastic/icons/src/IconMagicWand'
import { roleuser } from '@edulastic/constants'
import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react'
import connect from 'react-redux/es/connect/connect'
import { compose } from 'redux'
import i18 from '@edulastic/localization'
import { Tooltip } from 'antd'
import { withRouter } from 'react-router-dom'
import { segmentApi } from '@edulastic/api'
import SelectGroupModal from '../../../TestPage/components/AddItems/SelectGroupModal'
import { getStandardsListSelector } from '../../../src/selectors/dictionaries'
import AiTestBanner from './CreateAiTestBanner'
import { CreateAITest } from './CreateAITest'
import { aiTestActions } from './ducks'
import { useSaveForm } from './hooks/useSaveForm'
import {
  AiEduButton,
  FullHeightAiEduButton,
  StyledAiTestLoadingModal,
  StyledHoverSpan,
} from './styled'
import { setQuestionDataAction } from '../../../QuestionEditor/ducks'
import { getCurrentQuestionSelector } from '../../../sharedDucks/questions'
import {
  clearCreatedItemsAction,
  getTestEntitySelector,
  isSectionAdaptiveTestSelector,
  setDefaultTestDataAction,
} from '../../../TestPage/ducks'
import {
  getUserRole,
  isGcpsDistrictSelector,
  isPremiumUserSelector,
  isVideoQuizAndAIEnabledSelector,
} from '../../../src/selectors/user'
import AddOnTag from '../common/AddOnTag'
import { CREATE_AI_TEST_DISPLAY_SCREENS } from './constants'
import CreateAiTestBannerSmall from './CreateAiTestBannerSmall'
import VerticalTextSlider from '../../../../common/components/VerticalTextSlider'
import { questionGenerationLoadingTexts } from '../../../AssessmentPage/VideoQuiz/constants'
import { STATUS } from './ducks/constants'
import { navigationState } from '../../../src/constants/navigation'
import {
  aiBundleTrialExpired,
  getIsSubscriptionExpired,
  getProducts,
} from '../../../Subscription/ducks'
import SignupAndTrialModals from '../SignupAndTrialModals'
import appConfig from '../../../../../app-config'

const {
  NEW_TEST_SCREEN,
  ADD_ITEMS_SCREEN,
  CREATE_TEST_SCREEN,
  CREATE_ITEMS_SCREEN,
  SEARCH_NO_DATA_SCREEN,
  ADD_ITEMS_NO_DATA_SCREEN,
} = CREATE_AI_TEST_DISPLAY_SCREENS

const EduAIQuiz = ({
  test,
  addItems,
  aiTestStatus,
  getAiGeneratedTestItems,
  resetTestDetails,
  standardsList,
  setDefaultTest,
  clearCreatedItem,
  history,
  isVideoQuizAndAIEnabled,
  hasAiBundleTrialExpired,
  hasTeacherPremiumSubscriptionExpired,
  products,
  currentGroupIndexValueFromStore,
  showSelectGroupIndexModal,
  isGcpsDistrict,
  displayScreen = NEW_TEST_SCREEN,
  isSectionAdaptiveTest,
  question = {},
  setQuestionData,
  userRole,
  childrenStyle = {},
  isPremiumUser,
  disableGenerate = false,
  myForwardedRef,
  children,
}) => {
  const {
    selectSectionVisible,
    isVisible,
    onCreateItems: createItems,
    onCancel,
    handleFieldDataChange,
    handleAiFormSubmit,
    aiFormContent,
    handleChangeStandard,
    updateAlignment,
    selectedGroupIndex,
    handleSelectGroupResponse,
    attachmentType,
    setAttachmentType,
    handleAttachmentChange,
    attachmentMessageData,
    setAttachmentMessageData,
    isGenerateDisabled,
    setIsGenerateDisabled,
  } = useSaveForm({
    hasSections: test?.hasSections,
    isAiGeneratedTest: test?.aiGenerated,
    getAiGeneratedTestItems,
    addItemsView: false,
    resetTestDetails,
    standardsList,
    addItems,
    setDefaultTest,
    clearCreatedItem,
    currentGroupIndexValueFromStore,
    showSelectGroupIndexModal,
    savePreselected:
      displayScreen === CREATE_ITEMS_SCREEN ||
      displayScreen === CREATE_TEST_SCREEN,
    isSectionAdaptiveTest,
    adaptiveDeliveryData: test?.adaptiveDelivery || {},
    itemGroups: test?.itemGroups || [],
  })

  const [showFreeTrialModalsFlow, setShowFreeTrialModalsFlow] = useState(false)

  useImperativeHandle(myForwardedRef, () => ({
    createItems: () => {
      createItems(true)
    },
  }))

  const onCreateTestClick = (
    showSelectGroup = true,
    source = 'Test Library'
  ) => {
    if (displayScreen === CREATE_ITEMS_SCREEN) {
      source = 'Item Bank'
    } else if (displayScreen === ADD_ITEMS_SCREEN) {
      source = 'Add Items'
    }
    segmentApi.genericEventTrack('AIQuizCreateTestClick', {
      source,
    })

    if (!isVideoQuizAndAIEnabled) {
      if (
        hasTeacherPremiumSubscriptionExpired ||
        hasAiBundleTrialExpired ||
        userRole !== roleuser.TEACHER
      ) {
        history.push({
          pathname: '/author/subscription',
          state: { view: navigationState.SUBSCRIPTION.view.ADDON },
        })
      } else {
        setShowFreeTrialModalsFlow(true)
      }
    } else {
      createItems(showSelectGroup)
    }
  }

  useEffect(() => {
    if (isVisible) {
      // resetting skip standard checkbox
      if (question?.shouldSkipEmptyStandardWarning) {
        setQuestionData({
          ...question,
          shouldSkipEmptyStandardWarning: false,
        })
      }
    }
  }, [isVisible])

  useEffect(() => {
    if (aiTestStatus === STATUS.SUCCESS) {
      onCancel()
    }
  }, [aiTestStatus])

  const EduAiAddItemsButton = (
    <AiEduButton
      aiStyle
      disabled={!isVideoQuizAndAIEnabled || isSectionAdaptiveTest}
      onClick={onCreateTestClick}
      data-cy="createItemUsingAi"
    >
      <IconMagicWand fill={`${white}`} />
      Create Items Using AI
    </AiEduButton>
  )

  const EduAiAddItems = (
    <EduIf condition={isVideoQuizAndAIEnabled || isSectionAdaptiveTest}>
      <EduThen>
        <Tooltip
          title={
            <>
              <p>
                {isSectionAdaptiveTest
                  ? i18.t('author:notSupportedForSectionsAdaptiveTest.info')
                  : i18.t('author:rubric.infoText')}
              </p>
            </>
          }
        >
          <div>{EduAiAddItemsButton}</div>
        </Tooltip>
      </EduThen>
      <EduElse>
        <AddOnTag
          component={EduAiAddItemsButton}
          message={i18.t('author:aiSuite.addOnText')}
        />
      </EduElse>
    </EduIf>
  )

  const EduCreateAddonTag = (
    <EduIf condition={!isVideoQuizAndAIEnabled}>
      <AddOnTag message={i18.t('author:aiSuite.addOnText')} />
    </EduIf>
  )

  const EduCreateItemsWithAiButton = (
    <FullHeightAiEduButton
      aiStyle
      margin
      onClick={onCreateTestClick}
      data-cy="createItemsWithAi"
    >
      <IconMagicWand fill={`${white}`} />
      Create Items with AI
      {EduCreateAddonTag}
    </FullHeightAiEduButton>
  )

  const EduAiCreateTestButton = (
    <FullHeightAiEduButton
      aiStyle
      onClick={onCreateTestClick}
      data-cy="createTestWithAi"
    >
      <IconMagicWand fill={`${white}`} />
      Create Test with AI
      {EduCreateAddonTag}
    </FullHeightAiEduButton>
  )

  const getComponentToDisplay = (screen) => {
    switch (screen) {
      case ADD_ITEMS_SCREEN:
        return EduAiAddItems
      case NEW_TEST_SCREEN:
        return <AiTestBanner onCreateItems={onCreateTestClick} />
      case CREATE_TEST_SCREEN:
        return EduAiCreateTestButton
      case CREATE_ITEMS_SCREEN:
        return EduCreateItemsWithAiButton
      case SEARCH_NO_DATA_SCREEN:
        return <CreateAiTestBannerSmall onCreateItems={onCreateTestClick} />
      case ADD_ITEMS_NO_DATA_SCREEN:
        return (
          <CreateAiTestBannerSmall
            onCreateItems={onCreateTestClick}
            bannerText="Need more content? Create using AI-powered items"
          />
        )
      default:
        return <></>
    }
  }

  const vqProductId = products?.find(
    ({ linkedProductId }) =>
      linkedProductId === appConfig.videoQuizDefaultCollection.collectionId
  )

  const trialCancelHandle = ({ action }) => {
    setShowFreeTrialModalsFlow(false)
    segmentApi.genericEventTrack('CreateAITestFreeTrial:Cancel', {
      source: displayScreen,
      action,
    })
  }
  const trialSuccessHandle = () => {
    notification({
      type: 'success',
      msg: isPremiumUser
        ? 'Video Quiz and AI suite activated.'
        : 'Trial for Teacher Premium with AI Add-on activated.',
    })

    setShowFreeTrialModalsFlow(false)
    createItems()

    segmentApi.genericEventTrack('CreateAITestFreeTrial:Success', {
      source: displayScreen,
    })
  }
  return (
    <EduIf condition={!isGcpsDistrict}>
      {showFreeTrialModalsFlow && (
        <SignupAndTrialModals
          showAIBanner
          trialProducts={[vqProductId]}
          onCancel={trialCancelHandle}
          onTrialSubsConfirmation={trialSuccessHandle}
        />
      )}
      {children ? (
        <StyledHoverSpan
          style={childrenStyle}
          onClick={() => onCreateTestClick(true, displayScreen)}
        >
          {children}
        </StyledHoverSpan>
      ) : (
        getComponentToDisplay(displayScreen)
      )}
      <EduIf condition={addItems}>
        <SelectGroupModal
          visible={selectSectionVisible}
          test={test}
          handleResponse={handleSelectGroupResponse}
          isSectionAdaptiveTest={isSectionAdaptiveTest}
        />
      </EduIf>
      <StyledAiTestLoadingModal
        visible={aiTestStatus === STATUS.INPROGRESS}
        title={null}
        footer={null}
        width="50%"
        centered
        padding="30px 60px"
        bodyPadding="0px"
        borderRadius="10px"
        closeTopAlign="14px"
        closeRightAlign="10px"
        closeIconColor="black"
        zIndex={1006}
        maskClosable={false}
      >
        <VerticalTextSlider
          texts={questionGenerationLoadingTexts}
          textChangeInterval={5}
        />
      </StyledAiTestLoadingModal>
      <CreateAITest
        onCancel={onCancel}
        isVisible={isVisible}
        handleFieldDataChange={handleFieldDataChange}
        handleAiFormSubmit={() => handleAiFormSubmit(selectedGroupIndex)}
        aiTestStatus={aiTestStatus}
        handleChangeStandard={handleChangeStandard}
        aiFormContent={aiFormContent}
        updateAlignment={updateAlignment}
        addItems={addItems}
        onChangeAttachmentType={setAttachmentType}
        attachmentType={attachmentType}
        handleAttachmentChange={handleAttachmentChange}
        attachmentMessageData={attachmentMessageData}
        setAttachmentMessageData={setAttachmentMessageData}
        isGenerateDisabled={isGenerateDisabled || disableGenerate}
        setIsGenerateDisabled={setIsGenerateDisabled}
      />
    </EduIf>
  )
}

const enhance = compose(
  withRouter,
  connect(
    (state) => ({
      isPremiumUser: isPremiumUserSelector(state),
      aiTestStatus: state?.aiTestDetails?.status,
      standardsList: getStandardsListSelector(state),
      isVideoQuizAndAIEnabled: isVideoQuizAndAIEnabledSelector(state),
      hasAiBundleTrialExpired: aiBundleTrialExpired(state),
      hasTeacherPremiumSubscriptionExpired: getIsSubscriptionExpired(state),
      products: getProducts(state),
      isGcpsDistrict: isGcpsDistrictSelector(state, true),
      test: getTestEntitySelector(state),
      isSectionAdaptiveTest: isSectionAdaptiveTestSelector(state),
      question: getCurrentQuestionSelector(state),
      userRole: getUserRole(state),
    }),
    {
      getAiGeneratedTestItems: aiTestActions.getAiGeneratedTestItems,
      resetTestDetails: aiTestActions.resetTestDetails,
      setDefaultTest: setDefaultTestDataAction,
      clearCreatedItem: clearCreatedItemsAction,
      setQuestionData: setQuestionDataAction,
    }
  )
)
const EnhancedComponent = enhance(EduAIQuiz)

export default forwardRef((props, ref) => (
  <EnhancedComponent {...props} myForwardedRef={ref} />
))
