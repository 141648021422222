import React from 'react'
import { connect } from 'react-redux'
import { roleuser } from '@edulastic/constants'
import EmptyMessage from '../../components/EmptyMessage'
import { ActionContainer, EmptyClassContainer } from './styled'
import {
  getManualEnrollmentAllowedSelector,
  getUser,
  getUserOrgId,
} from '../../../../../src/selectors/user'
import CreateClass from '../../components/CreateClass'
import { FILTER_TYPES, getEmptyClassMessage } from '../../utils'
import { getClassFilters } from '../../../../ducks'
import SyncAction from '../../components/SyncAction'
import { setShowCanvasSyncModalAction } from '../../../../../ManageClass/ducks'

const EmptyClass = ({
  classFilters,
  history,
  setShowCanvasSyncModal,
  manualEnrollmentAllowed,
  user,
}) => {
  const { classType } = classFilters
  const { MY_FAVORITES, PRIMARY_TEACHER_CLASSES } = FILTER_TYPES
  const isManualEnrollmentAllowed =
    user?.role === roleuser.TEACHER ? manualEnrollmentAllowed : true
  const showCreateClassBtn =
    classType !== MY_FAVORITES && isManualEnrollmentAllowed
  const showSyncAction = ![PRIMARY_TEACHER_CLASSES, MY_FAVORITES].includes(
    classType
  )

  return (
    <EmptyClassContainer>
      <EmptyMessage message={getEmptyClassMessage(classType)} />
      <ActionContainer>
        {showSyncAction && (
          <SyncAction
            history={history}
            handleCanvasBulkSync={() => setShowCanvasSyncModal(true)}
          />
        )}
        {showCreateClassBtn && <CreateClass />}
      </ActionContainer>
    </EmptyClassContainer>
  )
}

export default connect(
  (state) => ({
    districtId: getUserOrgId(state),
    user: getUser(state),
    classFilters: getClassFilters(state),
    manualEnrollmentAllowed: getManualEnrollmentAllowedSelector(state),
  }),
  {
    setShowCanvasSyncModal: setShowCanvasSyncModalAction,
  }
)(EmptyClass)
