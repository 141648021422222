import React from 'react'
import withIconStyles from '@edulastic/icons/src/HOC/withIconStyles'
import SVG from '@edulastic/icons/src/common/SVG'

const IconBold = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="10.726"
    height="11.701"
    viewBox="0 0 10.726 11.701"
    {...props}
  >
    <g transform="translate(-18.277)">
      <path
        d="M28.3,6.285a3.677,3.677,0,0,0-2.034-1.112,8.29,8.29,0,0,0,1.143-.587,2.536,2.536,0,0,0,.723-.8,1.985,1.985,0,0,0,.244-.975,2.665,2.665,0,0,0-.16-.929,2.332,2.332,0,0,0-.5-.792A2.875,2.875,0,0,0,26.946.51a4.928,4.928,0,0,0-.731-.3A5.762,5.762,0,0,0,24.76.008H24.2q-.152,0-.309,0l-.164,0-.107,0-.107,0-.343.008-2.895.1L18.292.16l.03.632q.64.084.868.1a1.164,1.164,0,0,1,.526.114q.084.061.091.091a2.725,2.725,0,0,1,.084.83q.03,1.128.069,3.078l.015,3.786a11.82,11.82,0,0,1-.069,1.478,1.071,1.071,0,0,1-.16.389,4.23,4.23,0,0,1-.937.236q-.175.023-.518.092l-.015.716q1.821-.061,2.072-.084,1.63-.1,2.27-.084l1.5.03a7.162,7.162,0,0,0,1.508-.084,6.109,6.109,0,0,0,1.546-.457,3.591,3.591,0,0,0,1.066-.823,2.529,2.529,0,0,0,.541-.891A3.8,3.8,0,0,0,29,8.083,2.676,2.676,0,0,0,28.3,6.285ZM22.337.884a6.226,6.226,0,0,1,.99-.1,2.845,2.845,0,0,1,1.943.571,1.823,1.823,0,0,1,.647,1.424,1.975,1.975,0,0,1-.678,1.706,3.4,3.4,0,0,1-2.011.5,5.324,5.324,0,0,1-.83-.053q-.008-.259-.008-.587L22.4,3.6q.008-1.2-.046-2.125Q22.337,1.219,22.337.884Zm3.809,8.8a1.954,1.954,0,0,1-.929.861,3.788,3.788,0,0,1-1.646.312,2.769,2.769,0,0,1-1.067-.244.982.982,0,0,1-.092-.335L22.391,8.22,22.4,6.9V5.8a2.57,2.57,0,0,1,.769-.076,5.4,5.4,0,0,1,1.882.244,2.219,2.219,0,0,1,1.074.96A2.587,2.587,0,0,1,26.436,8.3,2.881,2.881,0,0,1,26.147,9.682Z"
        transform="translate(0 0)"
      />
    </g>
  </SVG>
)

export default withIconStyles(IconBold)
