import React from 'react'
import styled from 'styled-components'

import { EduButton, FlexContainer } from '@edulastic/common'
import { IconClose } from '@edulastic/icons'
import { black, white } from '@edulastic/colors'
import { segmentApi } from '@edulastic/api'

export const StudentPreviewBanner = ({
  text,
  buttonText,
  onButtonClick,
  showButton,
  backgroundColor,
  color,
}) => {
  const handleButtonClick = () => {
    segmentApi.genericEventTrack('ExitPreview', {
      source: 'exitPreview',
    })
    onButtonClick()
  }

  return (
    <BannerContainer
      height="40px"
      width="100%"
      justifyContent="center"
      alignItems="center"
      backgroundColor={backgroundColor}
    >
      <BannerText color={color}>{text}</BannerText>
      {showButton && (
        <ExitButton
          isGhost
          data-cy="exit-view-as-student"
          onClick={handleButtonClick}
          height="25px"
          justifyContent="flex-start"
          noBorder
          ml="8px"
        >
          <IconClose width="12px" height="12px" margin="0 5px" /> {buttonText}
        </ExitButton>
      )}
    </BannerContainer>
  )
}

const ExitButton = styled(EduButton)`
  text-transform: none;
  font-size: 12px;
  padding: 5px;
  border-radius: 2px;
  &.ant-btn.ant-btn-primary {
    color: ${black};
  }
  svg {
    fill: ${black};
    &:focus,
    &:hover {
      fill: ${white};
    }
  }
`

const BannerContainer = styled(FlexContainer)`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: white;
  z-index: 1;

  @media print {
    display: none;
  }
`

const BannerText = styled.div`
  color: ${({ color }) => color};
  font-size: 12px;
`
