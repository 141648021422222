import React, { createContext } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import {
  getCurrentLanguage,
  isTestPreviewModalVisibleSelector,
  stackedTranslationSelector,
} from '../../../../common/components/LanguageSelector/duck'

const StackedTranslatorContext = createContext({})

const StackedTranslatorProvider = ({
  children,
  stackedTranslation,
  isPreview,
  isStudentReport,
  currentLanguage,
}) => {
  return (
    <StackedTranslatorContext.Provider
      value={{
        isPreview,
        stackedTranslation,
        isStudentReport,
        addOriginalQuestion: true,
        currentLanguage,
      }}
    >
      {children}
    </StackedTranslatorContext.Provider>
  )
}

const enhanced = compose(
  connect(
    (state) => ({
      stackedTranslation: stackedTranslationSelector(state),
      isPreview: isTestPreviewModalVisibleSelector(state),
      currentLanguage: getCurrentLanguage(state),
    }),
    null
  )
)

const enhancedStackedTranslatorProvider = enhanced(StackedTranslatorProvider)

export {
  enhancedStackedTranslatorProvider as StackedTranslatorProvider,
  StackedTranslatorContext,
}
