/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconPauseRectangular = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width="20px"
    height="20px"
    {...props}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M4 12.6666H6.66667V3.33325H4V12.6666ZM9.33333 3.33325V12.6666H12V3.33325H9.33333Z"
    />
  </SVG>
)

export default withIconStyles(IconPauseRectangular)
