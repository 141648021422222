import styled from 'styled-components'
import { CustomModalStyled } from '@edulastic/common'

export const StyledAdaptiveTestOverviewContainer = styled.div``

export const StyledAdaptiveTestInfoTextContainer = styled.div``

export const StyledAdptiveTestInfoModal = styled(CustomModalStyled)`
  .ant-modal-content {
    width: 100%;
    height: 100%;
    overflow: scroll;
    .ant-modal-body {
      background-color: white;
      .heading {
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        color: #000000;
      }
      .title {
        margin: 15px 0px;
      }
      .content {
        .heading {
          margin: 15px 0px;
          font-family: Open Sans;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          text-align: left;
          color: #000000;
        }
        .outer-list {
          padding-left: 15px;
        }
        ul {
          list-style: none;
          padding-left: 0px;
        }
        .inner-list {
          list-style: circle;
          padding-left: 12px;
        }
        li::marker,
        p {
          font-family: Open Sans;
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          color: #3d3d3d;
          margin: 0px !important;
        }
        .summary-text {
          font-family: Open Sans;
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          color: #000000;
        }
      }
    }
  }
`
export const StyledAdaptiveTestConfirmationModal = styled(CustomModalStyled)`
  .ant-modal-content {
    width: 100%;
    height: 100%;
    .ant-modal-header > .ant-modal-title {
      font-family: Open Sans;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      color: #000000;
    }
    .ant-modal-body {
      background-color: white;
      p {
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        color: #474747;
      }
    }
    .ant-modal-footer {
      justify-content: flex-end !important;
    }
  }
`
