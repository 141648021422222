/* eslint-disable react/prop-types */
import React from 'react'
import { themeColor } from '@edulastic/colors'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconPlusCircleThemeColor = (props) => (
  <SVG
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="12" cy="12" r="12" fill={themeColor} />
    <path
      d="M12.8867 11.4961H17.5742V13.582H12.8867V18.3164H10.8008V13.582H6.125V11.4961H10.8008V6.71484H12.8867V11.4961Z"
      fill="white"
    />
  </SVG>
)

export default withIconStyles(IconPlusCircleThemeColor)
