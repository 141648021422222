import { lightGreen10, lightGrey } from '@edulastic/colors'
import { Tabs } from 'antd'
import styled from 'styled-components'

export const CLASS_CONTAINER_MIN_HEIGHT = '300px'
// Todo: Remove width when parent has properly set
export const DashboardSubHeaderContainer = styled.div`
  /* max-width: 95%; */
`
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const LeftSection = styled.div`
  display: flex;
  align-items: center;
`
export const RightSection = styled.div`
  display: flex;
  align-items: center;
`

export const ListContainer = styled.div`
  overflow-y: auto;
  min-height: ${CLASS_CONTAINER_MIN_HEIGHT};
  max-height: 575px;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 5px; /* Set the width of the scrollbar */
    background-color: #f1f1f1; /* Color of the scrollbar background */
  }
  ::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the scrollbar thumb (the draggable part) */
    border-radius: 10px; /* Make the scrollbar thumb rounded */
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color of the scrollbar thumb when hovered */
  }
`

export const ListActionContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`
export const ActionItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 36px;
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  &:hover {
    background-color: ${lightGreen10};
    svg {
      fill: ${lightGrey};
    }
  }
`
/** @type {typeof Tabs} */
export const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    padding: 12px 5px;
    margin-right: 15px;
  }
`

export const LoadingContainer = styled.div`
  position: absolute;
  top: 10%;
`

export const ClassesTabJoyrideHighlight = styled.div`
  position: absolute;
  display: block;
  width: 6rem;
  height: 2rem;
  margin-left: -0.75rem;
  z-index: -1;
`

export const AssignmentsTabJoyrideHighlight = styled.div`
  position: absolute;
  display: block;
  width: 7.75rem;
  height: 2rem;
  z-index: -1;
`
