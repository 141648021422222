import React, { useContext, useState } from 'react'
import { EduButton, FlexContainer, Stimulus } from '@edulastic/common'
import styled from 'styled-components'
import produce from 'immer'
import isEmpty from 'lodash/isEmpty'
import { LANGUAGE_EN } from '@edulastic/constants/const/languages'
import { Dropdown, Icon, Menu } from 'antd'
import { white } from '@edulastic/colors'
import { isStackedTranslationEnabled, sortKeys } from './utils'
import { StackedTranslatorContext } from './StackedTranslatorProvider'
import { AudioControlContext } from './AudioControlProvider'
import AudioControls from '../../../AudioControls'
import {
  buttonTexts,
  languageDropdown,
  stackedTranslationMap,
} from './constants'

const StackedTranslatorStimulus = ({ question, fontSize, defaultStimulus }) => {
  const {
    stackedTranslation,
    isPreview,
    isStudentReport,
    currentLanguage,
  } = useContext(StackedTranslatorContext)

  const {
    isShowStudentWork,
    hideVisibility,
    currentIndex,
    passageContentChanged,
    className,
    showAudioControlQuestionLevel,
    hasStackedAudioControls,
    showLanguageDropDown,
    isParccSkin,
  } = useContext(AudioControlContext)

  /**
   * originalQuestion is getting set in changeDataToPreferredLanguage
   * This is to keep original question data as it is getting modified by preferred language
   */
  const originalQuestion = question?.originalQuestion || question

  /** Adding English content to language features */
  const newQuestion = produce(originalQuestion, (draft) => {
    draft.languageFeatures = {
      ...draft.languageFeatures,
      en: {
        options: draft?.options,
        stimulus: draft?.stimulus,
        tts: draft?.tts || {},
      },
    }

    /** ["es", "en"] */
    const languages = Object.keys(draft?.languageFeatures || {})

    /** Language features don't have options value, required for audioControls  */
    for (const language of languages) {
      draft.languageFeatures[language].options = draft?.languageFeatures?.[
        language
      ]?.options?.map((option, optionIndex) => ({
        ...option,
        value: draft?.options?.[optionIndex]?.value,
      }))
    }
  })

  /**
   * Handling _stackedTranslation for preview when Stacked Translation is Student Level
   */
  const _stackedTranslation =
    isPreview &&
    !isStudentReport &&
    isStackedTranslationEnabled(stackedTranslation, isPreview)
      ? stackedTranslationMap.ES_EN
      : stackedTranslation

  const sortKeyOrder = (_stackedTranslation || '').split('-')

  /** ["es", "en"] */
  const languages = sortKeys(
    sortKeyOrder,
    Object.keys(newQuestion?.languageFeatures || {})
  )

  const stimuliByLanguage = (languages || []).map((language) => {
    return {
      language,
      stimulus: newQuestion?.languageFeatures?.[language]?.stimulus,
    }
  })

  /** Question to send to AudioControls */
  const getQuestion = (index) => {
    return {
      ...(newQuestion?.languageFeatures?.[languages?.[index]] || {}),
      type: question.type,
    }
  }

  const availableLanguagesToSelect = (stimuliByLanguage || [])
    .map(({ language, stimulus }) => {
      if (typeof stimulus === 'string' && stimulus.length) {
        return language
      }
      return ''
    })
    .filter((x) => x)

  const defaultLanguage = availableLanguagesToSelect?.includes(currentLanguage)
    ? currentLanguage
    : availableLanguagesToSelect?.[0] || LANGUAGE_EN

  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage)

  const handleLanguageSelection = (e) => {
    setSelectedLanguage(e.key)
  }

  const languageSelectors = (
    <Menu onClick={handleLanguageSelection}>
      {availableLanguagesToSelect.map((languageToSelect) => (
        <Menu.Item key={languageToSelect}>
          {languageDropdown[languageToSelect]}
        </Menu.Item>
      ))}
    </Menu>
  )

  const languageDropdownSelector = showLanguageDropDown ? (
    <Dropdown
      overlay={languageSelectors}
      zIndex="10000"
      placement="topLeft"
      trigger={['click']}
    >
      <StyledMenuItem alignItems="center">
        <StyledMenuItemButton
          height={isStudentReport ? '40px' : ''}
          ml="0px"
          width="45px"
          isStudentReport={isStudentReport}
        >
          {selectedLanguage}
          <Icon
            style={{
              margin: '0px 0px 0px -10px',
              fontSize: '10px',
              fontWeight: 'bold',
              color: '#7A7A8D',
            }}
            type="down"
          />
        </StyledMenuItemButton>
      </StyledMenuItem>
    </Dropdown>
  ) : null
  if (
    stimuliByLanguage?.length === 2 &&
    sortKeyOrder.includes(currentLanguage)
  ) {
    const stimulusComponents = stimuliByLanguage
      ?.map(({ stimulus, language }, index) => {
        const questionData = getQuestion(index)
        if (isEmpty(stimulus)) return null
        return (
          <>
            {showAudioControlQuestionLevel &&
              hasStackedAudioControls &&
              (!isParccSkin ||
                (isParccSkin && selectedLanguage === language)) && (
                <AudioControls
                  hideVisibility={hideVisibility && !isShowStudentWork}
                  key={`${questionData?.id}_${index}_${currentIndex}_${passageContentChanged}`}
                  item={questionData}
                  qId={`${question?.id}_${index}`}
                  audioSrc={questionData?.tts?.titleAudioURL}
                  isPaginated={question?.paginated_content}
                  className={className}
                  isStudentReport={isStudentReport}
                  padding="0px 16px 16px 0px"
                  buttonTexts={buttonTexts[languages[index]]}
                  languageDropdownSelector={languageDropdownSelector}
                />
              )}

            <StyledStimulus
              fontSize={fontSize}
              dangerouslySetInnerHTML={{ __html: stimulus }}
              className="_print-space-reduce-stimulus"
            />
          </>
        )
      })
      .filter((x) => x)

    if (stimulusComponents?.length) {
      return stimulusComponents
    }
  }

  const showAudioControl =
    showAudioControlQuestionLevel &&
    hasStackedAudioControls &&
    (isParccSkin ? selectedLanguage === currentLanguage : true)

  return (
    <>
      {showAudioControl && (
        <AudioControls
          hideVisibility={hideVisibility && !isShowStudentWork}
          key={`${question?.id}_${currentIndex}_${passageContentChanged}`}
          item={question}
          qId={`${question?.id}`}
          audioSrc={question?.tts?.titleAudioURL}
          isPaginated={question?.paginated_content}
          className={className}
          isStudentReport={isStudentReport}
          padding="0px 16px 16px 0px"
        />
      )}
      <StyledStimulus
        fontSize={fontSize}
        dangerouslySetInnerHTML={{ __html: defaultStimulus }}
        className="_print-space-reduce-stimulus"
      />
    </>
  )
}

export default StackedTranslatorStimulus

const StyledStimulus = styled(Stimulus)`
  word-break: break-word;
  overflow: hidden;
  img {
    padding: 0px;
  }
  padding-bottom: 16px;
`
const StyledMenuItem = styled(FlexContainer)`
  height: 40px;
  text-transform: capitalize;
`
const StyledMenuItemButton = styled(EduButton)`
  text-transform: capitalize;
  font-weight: bold;
  &.ant-btn.ant-btn-primary {
    color: ${({ isStudentReport }) => (isStudentReport ? white : '#7A7A8D')};
  }
  padding: 0px;
  font-size: 14px;
`
