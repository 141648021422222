import React from 'react'

const intructionsTableColumns = [
  {
    key: 'input',
    dataIndex: 'input',
    width: 100,
    align: 'center',
  },
  {
    key: 'output',
    dataIndex: 'output',
    width: 150,
    align: 'center',
    render: (text, row) => (
      <i>
        {text}
        {row.sub && <sub>{row.sub}</sub>}
        {row.sup && <sup>{row.sup}</sup>}
      </i>
    ),
  },
]

const intructionsTableData = [
  {
    key: 1,
    input: 'H_2',
    output: 'H',
    sub: '2',
  },
  {
    key: 2,
    input: 'Mg(OH)_2',
    output: 'Mg(OH)',
    sub: '2',
  },
  {
    key: 1,
    input: 'Mg^{2+}',
    output: 'Mg',
    sup: '2+',
  },
  {
    key: 1,
    input: 'e^{\\iota\\theta}',
    output: 'e',
    sup: 'i⍬',
  },
]

const variableTypeArticleLink =
  'https://support.goguardian.com/s/article/Specific-Answer-Formats-for-Dynamic-Parameter-Questions-1629334270256'

const combinationCountArticleLink =
  'https://support.goguardian.com/s/article/Questions-With-Dynamic-Content-1629333196386'

const defaultCombinationCount = 25

export {
  intructionsTableData,
  intructionsTableColumns,
  variableTypeArticleLink,
  combinationCountArticleLink,
  defaultCombinationCount,
}
