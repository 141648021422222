import React, { useState, useEffect } from 'react'
import { EduIf, RadioBtn, CheckboxLabel } from '@edulastic/common'
import {
  testContentVisibilityTypes,
  combinedVisibilityOptions,
  rubricOnlyVisibilityOptions,
  testContentVisibility as contentVisibilityOptions,
} from '@edulastic/constants/const/test'
import { IconInfo } from '@edulastic/icons'
import { lightGrey9 } from '@edulastic/colors'
import { Tooltip } from 'antd'
import {
  Block,
  StyledRadioGroup,
  Description,
  StyledCheckboxContainer,
  CurrentSettingInfo,
} from '../Container/styled'
import {
  messageMapWhenContentNotRestrictedBySuperAdmin,
  messageMapWhenContentRestrictedBySuperAdmin,
} from './constants'

const ContentVisibilityOptions = ({
  isDisabled = false,
  testContentVisibility,
  updateTestContentVisibility,
  isBulkUpdate = false,
  isTestContentRestrictedBySuperAdmin: testContentRestrictedBySuperAdmin,
  updateIsTestContentRestrictedBySuperAdmin,
  showIsTestContentRestrictedBySuperAdminCheckbox,
}) => {
  const [combinedVisibilityKey, setCombinedVisibilityKey] = useState(null)
  const [contentVisibility, setContentVisibility] = useState(
    testContentVisibility || contentVisibilityOptions.ALWAYS
  )
  const [
    isTestContentRestrictedBySuperAdmin,
    setIsTestContentRestrictedBySuperAdmin,
  ] = useState(testContentRestrictedBySuperAdmin || false)

  useEffect(() => {
    if (
      testContentVisibility &&
      Object.keys(combinedVisibilityOptions).some((i) =>
        combinedVisibilityOptions[i].some(
          (a) => a.key === testContentVisibility
        )
      )
    ) {
      setCombinedVisibilityKey(testContentVisibility)
      setContentVisibility(
        Object.keys(combinedVisibilityOptions).find((i) =>
          combinedVisibilityOptions[i].some(
            (a) => a.key === testContentVisibility
          )
        )
      )
    } else {
      setCombinedVisibilityKey(null)
      setContentVisibility(
        isBulkUpdate
          ? testContentVisibility
          : testContentVisibility || contentVisibilityOptions.ALWAYS
      )
    }
  }, [testContentVisibility])

  const handleContentVisibility = (e) => {
    const value = e.target.value
    if (
      value !== null &&
      Object.keys(combinedVisibilityOptions).some((i) =>
        combinedVisibilityOptions[i].some((a) => a.key === value)
      )
    ) {
      setCombinedVisibilityKey(value)
      setContentVisibility(
        Object.keys(combinedVisibilityOptions).find((i) =>
          combinedVisibilityOptions[i].some((a) => a.key === value)
        )
      )
    } else {
      setCombinedVisibilityKey(null)
      setContentVisibility((prev) => (value !== null ? value : prev))
    }
    updateTestContentVisibility(value === null ? contentVisibility : value)
  }

  const handleIsTestContentRestrictedBySuperAdmin = (value) => {
    setIsTestContentRestrictedBySuperAdmin(value)
    updateIsTestContentRestrictedBySuperAdmin(value)
  }

  const currentSettingMessage =
    showIsTestContentRestrictedBySuperAdminCheckbox &&
    isTestContentRestrictedBySuperAdmin
      ? messageMapWhenContentRestrictedBySuperAdmin?.[contentVisibility]?.[
          `${combinedVisibilityKey}`
        ]
      : messageMapWhenContentNotRestrictedBySuperAdmin?.[contentVisibility]?.[
          `${combinedVisibilityKey}`
        ]
  return (
    <div>
      <StyledRadioGroup
        disabled={isDisabled}
        onChange={handleContentVisibility}
        value={contentVisibility}
      >
        {testContentVisibilityTypes.map((item) => (
          <>
            <RadioBtn
              data-cy={`item-visibility-${item.key}`}
              value={item.key}
              key={item.key}
            >
              {item.value}
            </RadioBtn>
            {item.key === contentVisibility &&
              Object.keys(combinedVisibilityOptions).includes(
                contentVisibility
              ) && (
                <Block noBg>
                  <Description marginTop="0px">
                    Choose from the sub-options to let teachers grade
                    manually-gradable/constructive items, while auto-gradable
                    items remain hidden.
                  </Description>
                  <br />
                  <StyledRadioGroup
                    isHorizontal
                    isWrap
                    disabled={isDisabled}
                    onChange={handleContentVisibility}
                    value={combinedVisibilityKey}
                  >
                    {combinedVisibilityOptions[contentVisibility].map((ele) => (
                      <RadioBtn
                        data-cy={`item-visibility-${
                          ele.key || contentVisibility
                        }-suboption`}
                        value={ele.key}
                        key={ele.key}
                      >
                        {ele.value}
                        <EduIf
                          condition={rubricOnlyVisibilityOptions.includes(
                            ele.key
                          )}
                        >
                          <Tooltip title="Item types with input fields within stimulus text is not supported with this setting.">
                            <IconInfo
                              color={lightGrey9}
                              style={{
                                marginLeft: '10px',
                                position: 'relative',
                                top: '3px',
                              }}
                            />
                          </Tooltip>
                        </EduIf>
                      </RadioBtn>
                    ))}
                  </StyledRadioGroup>
                  {showIsTestContentRestrictedBySuperAdminCheckbox && (
                    <StyledCheckboxContainer>
                      <CheckboxLabel
                        disabled={isDisabled}
                        checked={isTestContentRestrictedBySuperAdmin}
                        onChange={(e) =>
                          handleIsTestContentRestrictedBySuperAdmin(
                            e.target.checked
                          )
                        }
                        textTransform="none"
                        labelFontWeight="600"
                      >
                        Restrict visibility to Super District Administrators
                        only
                      </CheckboxLabel>
                    </StyledCheckboxContainer>
                  )}
                </Block>
              )}
          </>
        ))}
      </StyledRadioGroup>
      {currentSettingMessage && (
        <CurrentSettingInfo>
          <span>Current Setting Selection :</span> {currentSettingMessage}
        </CurrentSettingInfo>
      )}
    </div>
  )
}

export default ContentVisibilityOptions
