import {
  faClone,
  faMinus,
  faMagic,
  faPencilAlt,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'
import i18, { withNamespaces } from '@edulastic/localization'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Form, Icon, Pagination } from 'antd'
import produce from 'immer'
import { maxBy, sumBy, uniqBy, debounce, isEmpty, intersection } from 'lodash'
import {
  EduElse,
  EduIf,
  EduThen,
  FlexContainer,
  notification,
} from '@edulastic/common'
import { TAG_NAMES } from '@edulastic/constants/const/tags'
import {
  ESSAY_PLAIN_TEXT,
  ESSAY_RICH_TEXT,
} from '@edulastic/constants/const/questionType'
import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { v4 } from 'uuid'
import { sanitizeForReview } from '@edulastic/common/src/helpers'
import { tagsApi } from '@edulastic/api'
import { withRouter } from 'react-router-dom'

import {
  CustomStyleBtn,
  CustomStyleBtn2,
} from '../../../assessment/styled/ButtonStyles'
import { getUserDetails } from '../../../student/Login/ducks'
import { getCurrentLanguage } from '../../../common/components/LanguageSelectorTab/duck'
import { setItemLevelScoreFromRubricAction } from '../../ItemDetail/ducks'
import {
  getCurrentQuestionSelector,
  getQuestionRubricStandardIds,
  removeRubricIdAction,
  setRubricIdAction,
} from '../../sharedDucks/questions'
import {
  addRubricToRecentlyUsedAction,
  autoGenerateRubricAction,
  deleteRubricAction,
  getCurrentRubricDataSelector,
  getRecentlyUsedRubricsSelector,
  getRubricGenerationInProgress,
  getSearchedRubricsListSelector,
  getSearchingStateSelector,
  getTotalSearchedCountSelector,
  saveRubricAction,
  searchRubricsRequestAction,
  updateRubricAction,
  updateRubricDataAction,
  removeAiTagFromQuestionAction,
  getRubricUUIDsSelector,
  setRubricGenerationStimulusAction,
  getPreviousRubricGeneratedStimulusSelector,
  getAllStandardsFromCurrentRubric,
  getAlignmentStandardMapSelector,
  getAiAssistedRubricSelector,
  setAIAssistedRubricAction,
} from '../ducks'
import {
  ActionBarContainer,
  ExistingRubricContainer,
  PaginationContainer,
  RecentlyUsedContainer,
  RubricsTag,
  SearchBar,
  TagContainer,
} from '../styled'
import ConfirmModal from './common/ConfirmModal'
import DeleteModal from './common/DeleteModal'
import PreviewRubricModal from './common/PreviewRubricModal'
import ShareModal from './common/ShareModal'
import CreateNew from './CreateNew'
import RubricTable from './RubricTable'
import {
  getQuestionDataSelector,
  setQuestionDataAction,
} from '../../QuestionEditor/ducks'
import { getAllTagsSelector, addNewTagAction } from '../../TestPage/ducks'
import {
  getIsAiEvaulationDistrictSelector,
  isGcpsDistrictSelector,
} from '../../src/selectors/user'
import AddOnTag from '../../AssessmentCreate/components/common/AddOnTag'
import { navigationState } from '../../src/constants/navigation'
import {
  getAlignmentStandardMap,
  getUniqueAlignmentData,
} from './common/helper'
import { updateDictAlignmentBulkAction } from '../../src/actions/dictionaries'
import { getDictionariesAlignmentsSelector } from '../../src/selectors/dictionaries'

const { AI_ASSISTED_RUBRICS } = TAG_NAMES

const UseExisting = ({
  updateRubricData,
  currentRubricData,
  closeRubricModal,
  actionType,
  user,
  saveRubric,
  updateRubric,
  searchRubricsRequest,
  searchedRubricList,
  searchingState,
  totalSearchedCount,
  associateRubricWithQuestion,
  dissociateRubricFromQuestion,
  isRegradeFlow,
  currentQuestion,
  deleteRubric,
  recentlyUsedRubrics,
  addRubricToRecentlyUsed,
  setItemLevelScoring,
  autoGenerateRubric,
  isRubricGenerationInProgress,
  t,
  questionData,
  allTagsData,
  setQuestionData,
  removeAiTag,
  addNewTag,
  premium,
  rubricUUIDs,
  setRubricGenerationStimulus,
  previousRubricGeneratedStimulus,
  history,
  isAiEvaulationDistrict,
  isGcpsDistrict,
  currentLang,
  updateDictAlignmentBulk,
  currentAlignments,
  rubricStandardIds,
  alignmentIndexMap,
  currentRubricStandards,
  aIAssisted,
  setAIAssisted,
}) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [showShareModal, setShowShareModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showPreviewRubricModal, setShowPreviewRubricModal] = useState(false)
  const [currentMode, setCurrentMode] = useState('SEARCH')
  const [isEditable, setIsEditable] = useState(
    actionType === 'CREATE NEW' || false
  )
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    if (currentRubricData?.status) setIsEditable(false)
  }, [currentRubricData?.status])

  const addTag = async () => {
    const questionTags = questionData.tags || []
    let aiTag = allTagsData.find((tag) => tag.tagName === AI_ASSISTED_RUBRICS)
    if (!aiTag) {
      const { _id, tagName } = await tagsApi.create({
        tagName: AI_ASSISTED_RUBRICS,
        tagType: 'testitem',
      })
      aiTag = { _id, tagName }
      addNewTag({ tag: aiTag, tagType: 'testitem' })
    }
    const tags = uniqBy([...questionTags, aiTag], '_id')
    setQuestionData({ ...questionData, tags })
  }

  useEffect(() => {
    if (actionType === 'VIEW RUBRIC') setCurrentMode('PREVIEW')
  }, [])

  const maxScore = useMemo(
    () =>
      sumBy(
        currentRubricData?.criteria,
        (c) => maxBy(c?.ratings, 'points')?.points
      ),
    [currentRubricData?.criteria]
  )

  const rubricAlreadyGeneratedOrNotMsg =
    previousRubricGeneratedStimulus === currentQuestion?.stimulus
      ? t('rubric.rubricAlreadyGenerated')
      : t('rubric.infoText')

  const autoGenerateRubricTooltip = isEmpty(currentQuestion?.stimulus)
    ? t('rubric.stimulusNotPresent')
    : rubricAlreadyGeneratedOrNotMsg

  let stimulus = currentQuestion?.stimulus
  if (currentQuestion?.languageFeatures) {
    const languageStimulus =
      currentQuestion?.languageFeatures?.[currentLang]?.stimulus
    if (languageStimulus) {
      stimulus = languageStimulus
    }
  }

  const disableAutoGenerateRubricBtn = [
    isEmpty(stimulus),
    previousRubricGeneratedStimulus === stimulus,
    !isAiEvaulationDistrict,
  ].some((o) => !!o)

  const isEssayTypeQuestion = [ESSAY_PLAIN_TEXT, ESSAY_RICH_TEXT].includes(
    questionData?.type
  )

  const showAutoGenerateRubricBtn = [
    isEssayTypeQuestion,
    premium,
    !currentRubricData?._id,
    !isGcpsDistrict,
  ].every((o) => !!o)

  const handlePaginationChange = (page) => {
    setCurrentPage(page)
    searchRubricsRequest({
      limit: 5,
      page,
      searchString: searchQuery,
    })
    setCurrentMode('RUBRIC_TABLE')
  }

  const generateRubricByOpenAI = () => {
    if (!isAiEvaulationDistrict) {
      history.push({
        pathname: '/author/subscription',
        state: { view: navigationState.SUBSCRIPTION.view.ADDON },
      })
      return
    }
    const stimulusContent = sanitizeForReview(stimulus)
    if (stimulusContent) {
      autoGenerateRubric({ stimulus: stimulusContent })
      setRubricGenerationStimulus(stimulusContent)
      setAIAssisted(true)
    }
  }

  const validateRubric = () => {
    let isValid = true
    if (currentRubricData.name && isValid) {
      const curentRubricCriteria = currentRubricData.criteria || []
      curentRubricCriteria.every((criteria) => {
        if (criteria.name && isValid) {
          const uniqueRatings = []
          criteria.ratings.every((rating) => {
            if (rating.name) {
              if (!uniqueRatings.includes(rating.points))
                uniqueRatings.push(parseFloat(rating.points))

              if (rating.name?.length > 100) {
                isValid = false
                notification({ messageKey: 'ratingsNameLengthValidation' })
              }
              if (rating.desc && rating.desc?.length > 8192) {
                isValid = false
                notification({ messageKey: 'ratingsDescLengthValidation' })
              }
            } else {
              isValid = false
              notification({ messageKey: 'ratingNameCannotEmpty' })
            }
            return isValid
          })
          if (isValid && uniqueRatings.includes(NaN)) {
            isValid = false
            notification({ messageKey: 'ratingPointMustNotBeEmpty' })
          }
          if (isValid && !uniqueRatings.find((p) => p > 0)) {
            isValid = false
            notification({ messageKey: 'ratingPointMustBeMoreThanZero' })
          }
          if (isValid && criteria.name?.length > 256) {
            isValid = false
            notification({ messageKey: 'criteriaNameLengthValidation' })
          }
        } else {
          isValid = false
          notification({ messageKey: 'criteriaNameCannotBeEmpty' })
        }
        return isValid
      })
      if (isValid) {
        const uniqueCriteriaArray = uniqBy(curentRubricCriteria, 'name')
        if (uniqueCriteriaArray.length < curentRubricCriteria.length) {
          isValid = false
          notification({ messageKey: 'criteriaNameShouldBeUnique' })
        }
      }
      // length validation mentioned in BE rubricsValidators
      if (
        currentRubricData?.name?.length > 100 ||
        currentRubricData?.description?.length > 256
      ) {
        isValid = false
      }
    } else {
      isValid = false
      notification({ messageKey: 'rubricNameCannotBeEmpty' })
    }
    return isValid
  }

  const handleSaveRubric = async (type) => {
    const isValid = validateRubric()
    const { __v, updatedAt, modifiedBy, ...data } = currentRubricData

    const clonedRubricData = {
      ...data,
      criteria: currentRubricData.criteria.map((c) => ({
        ...c,
        alignment: getUniqueAlignmentData(c.alignment),
      })),
    }

    if (!isValid) {
      return
    }

    if (isValid) {
      if (currentRubricData._id) {
        updateRubric({
          rubricData: {
            ...clonedRubricData,
            status: type,
            aIAssisted,
          },
          maxScore,
        })
      } else {
        saveRubric({
          rubricData: {
            ...clonedRubricData,
            status: type,
            aIAssisted,
          },
          maxScore,
        })
      }
      // check ai criteria or rating exists in rubric
      let isAnyUUIDExists = false
      if (rubricUUIDs?.length) {
        const criteriaUUIDs = []
        const ratingUUIDs = []
        for (const criteria of currentRubricData.criteria) {
          criteriaUUIDs.push(criteria.id)
          for (const rating of criteria.ratings) {
            ratingUUIDs.push(rating.id)
          }
        }
        const matchingCriterias = intersection(rubricUUIDs, criteriaUUIDs)
        const matchingRatings = intersection(rubricUUIDs, ratingUUIDs)
        if (matchingCriterias.length || matchingRatings.length) {
          isAnyUUIDExists = true
        }
      }
      const isRubricAIAssisted = aIAssisted && isAnyUUIDExists
      if (isRubricAIAssisted) {
        await addTag()
      } else {
        removeAiTag()
      }
      setCurrentMode('PREVIEW')
      setAIAssisted(false) // reset the state in store
    }
  }

  const handleUseRubric = async () => {
    if (currentRubricData.aIAssisted) {
      await addTag()
    } else {
      removeAiTag()
    }

    associateRubricWithQuestion({
      metadata: {
        _id: currentRubricData._id,
        name: currentRubricData.name,
        standardIds: currentRubricStandards.map(({ _id }) => _id),
      },
      maxScore,
    })
    if (Object.keys(alignmentIndexMap).length) {
      updateDictAlignmentBulk(alignmentIndexMap)
    }
    addRubricToRecentlyUsed(currentRubricData)
    setItemLevelScoring(false)
  }

  const handleEditRubric = () => {
    updateRubricData({
      ...currentRubricData,
      status: '',
    })
    setIsEditable(true)
    setCurrentMode('EDIT')
  }

  const handleShareModalResponse = (action = '', sharedType = '') => {
    const {
      // <-- not allowed
      __v,
      updatedAt,
      modifiedBy,
      // not allowed -->
      ...restRubricData
    } = currentRubricData

    const clonedRubricData = {
      ...restRubricData,
      criteria: restRubricData.criteria.map((c) => ({
        ...c,
        alignment: getUniqueAlignmentData(c.alignment),
      })),
    }

    if (action === 'CANCEL') {
      setShowShareModal(false)
    } else {
      updateRubric({
        rubricData: {
          ...clonedRubricData,
          sharedType,
        },
        changes: 'SHARED_TYPE',
      })

      setShowShareModal(false)
    }
  }

  const removeRubricFromQuestion = () => {
    const alignmentIndexMapForRemove = getAlignmentStandardMap(
      rubricStandardIds,
      [],
      currentAlignments
    )
    dissociateRubricFromQuestion()
    if (rubricStandardIds?.size) {
      updateDictAlignmentBulk(alignmentIndexMapForRemove)
      notification({
        msg: 'Rubric along with associated standards removed.',
      })
    }
  }

  const handleDeleteModalResponse = (response) => {
    if (response === 'YES') {
      deleteRubric(currentRubricData._id)
      if (currentQuestion.rubrics) removeRubricFromQuestion()
      closeRubricModal()
    }
    setShowDeleteModal(false)
  }

  const handleSearch = debounce((value) => {
    searchRubricsRequest({
      limit: 5,
      page: currentPage,
      searchString: value,
    })
    setCurrentMode('RUBRIC_TABLE')
  }, 500)

  const handleClone = (rubric) => {
    const clonedData = produce(rubric, (draft) => {
      draft.name = `Clone of ${draft.name}`
      draft.criteria = draft.criteria.map((criteria) => ({
        ...criteria,
        id: v4(),
        ratings: criteria.ratings.map((rating) => ({
          ...rating,
          id: v4(),
        })),
      }))
    })
    setIsEditable(true)
    const { name, description, criteria } = clonedData
    updateRubricData({ name, description, criteria })
    setCurrentMode('CLONE')
  }

  const handleTableAction = (_actionType, _id) => {
    const rubric = searchedRubricList.find((_rubric) => _rubric._id === _id)
    updateRubricData(rubric)
    if (_actionType === 'SHARE') setShowShareModal(true)
    else if (_actionType === 'DELETE') setShowDeleteModal(true)
    else if (_actionType === 'PREVIEW') {
      setCurrentMode('PREVIEW')
      setIsEditable(false)
    } else if (_actionType === 'CLONE') handleClone(rubric)
  }

  const handleConfirmModalResponse = (response) => {
    setShowConfirmModal(false)
    setAIAssisted(false)
    if (response === 'YES') {
      closeRubricModal()
    }
  }

  const btnStyle = {
    width: 'auto',
    margin: '0px 0px 0px 10px',
  }

  const handleRemoveRubric = () => {
    removeRubricFromQuestion()
    removeAiTag()
    if (isRegradeFlow) {
      notification({
        msg:
          'Score and max score will reset on re-score automatically option of re-grade',
      })
    }
  }

  const AutoGenerateRubricBtn = (
    <CustomStyleBtn2
      style={btnStyle}
      onClick={generateRubricByOpenAI}
      disabled={disableAutoGenerateRubricBtn}
      ghost={disableAutoGenerateRubricBtn}
      title={isAiEvaulationDistrict ? autoGenerateRubricTooltip : ''}
      loading={isRubricGenerationInProgress}
      data-cy="autoGenerateRubic"
    >
      <FontAwesomeIcon icon={faMagic} aria-hidden="true" />
      Auto Generate Rubric
    </CustomStyleBtn2>
  )

  const getContent = () => (
    <>
      {(!['RUBRIC_TABLE', 'SEARCH'].includes(currentMode) ||
        actionType === 'CREATE NEW') && (
        <ActionBarContainer>
          <div>
            {actionType === 'USE EXISTING' && currentMode === 'PREVIEW' && (
              <CustomStyleBtn
                style={btnStyle}
                onClick={() => setCurrentMode('RUBRIC_TABLE')}
              >
                <Icon type="left" /> <span data-cy="backButton">Back</span>
              </CustomStyleBtn>
            )}
            {currentMode === 'PREVIEW' && !isEditable && (
              <>
                <CustomStyleBtn
                  style={btnStyle}
                  onClick={() => handleClone(currentRubricData)}
                >
                  <FontAwesomeIcon icon={faClone} aria-hidden="true" />{' '}
                  <span data-cy="cloneButton">Clone</span>
                </CustomStyleBtn>
                {currentRubricData?.createdBy?._id === user?._id && (
                  <>
                    <CustomStyleBtn
                      style={btnStyle}
                      onClick={() => handleEditRubric()}
                    >
                      <FontAwesomeIcon icon={faPencilAlt} aria-hidden="true" />{' '}
                      <span data-cy="editRubric">Edit</span>
                    </CustomStyleBtn>

                    <CustomStyleBtn
                      style={btnStyle}
                      onClick={() => setShowDeleteModal(true)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} aria-hidden="true" />{' '}
                      <span data-cy="deleteButton">Delete</span>
                    </CustomStyleBtn>
                  </>
                )}
              </>
            )}
          </div>
          <div>
            <EduIf condition={showAutoGenerateRubricBtn}>
              <FlexContainer>
                <EduIf condition={isAiEvaulationDistrict}>
                  <EduThen>{AutoGenerateRubricBtn}</EduThen>
                  <EduElse>
                    <AddOnTag
                      component={AutoGenerateRubricBtn}
                      message={i18.t('author:aiSuite.addOnText')}
                    />
                  </EduElse>
                </EduIf>
              </FlexContainer>
            </EduIf>
            <CustomStyleBtn
              style={btnStyle}
              onClick={() => setShowPreviewRubricModal(true)}
            >
              <Icon data-cy="previewButton" type="eye" /> Preview
            </CustomStyleBtn>
            {currentMode === 'PREVIEW' && !isEditable && (
              <>
                <EduIf
                  condition={
                    currentQuestion.rubrics?._id !== currentRubricData?._id
                  }
                >
                  <CustomStyleBtn style={btnStyle} onClick={handleUseRubric}>
                    <Icon type="check" /> <span data-cy="useButton">Use</span>
                  </CustomStyleBtn>
                </EduIf>
                <EduIf
                  condition={
                    currentQuestion.rubrics?._id === currentRubricData?._id
                  }
                >
                  <CustomStyleBtn style={btnStyle} onClick={handleRemoveRubric}>
                    <FontAwesomeIcon icon={faMinus} aria-hidden="true" /> Remove
                  </CustomStyleBtn>
                </EduIf>
                <EduIf
                  condition={currentRubricData?.createdBy?._id === user?._id}
                >
                  <>
                    <CustomStyleBtn
                      style={btnStyle}
                      onClick={() => setShowShareModal(true)}
                    >
                      <Icon type="share-alt" />{' '}
                      <span data-cy="shareButton">Share</span>
                    </CustomStyleBtn>
                  </>
                </EduIf>
              </>
            )}
            {isEditable && (
              <>
                <CustomStyleBtn
                  style={btnStyle}
                  onClick={() => setShowConfirmModal(true)}
                >
                  <Icon type="close" />
                  <span data-cy="cancel">Cancel</span>
                </CustomStyleBtn>
                <CustomStyleBtn
                  style={btnStyle}
                  onClick={() => handleSaveRubric('published')}
                >
                  {/* <FontAwesomeIcon icon={faPaperPlane} aria-hidden="true" /> */}
                  <Icon type="save" theme="filled" />
                  <span data-cy="saveAndUseButton">Save & Use</span>
                </CustomStyleBtn>
              </>
            )}
          </div>
        </ActionBarContainer>
      )}

      <ExistingRubricContainer>
        {['RUBRIC_TABLE', 'PREVIEW', 'SEARCH'].includes(currentMode) &&
          actionType === 'USE EXISTING' && (
            <>
              <SearchBar
                placeholder="Search by rubric name or author name"
                data-cy="rubricSearchBox"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value)
                  handleSearch(e.target.value)
                }}
                onSearch={handleSearch}
                loading={searchingState}
              />
              {recentlyUsedRubrics.length > 0 && (
                <RecentlyUsedContainer>
                  <span>Recently Used: </span>
                  <TagContainer data-cy="recentlyUsedRubrics">
                    {recentlyUsedRubrics.map((rubric) => (
                      <RubricsTag
                        onClick={() => {
                          updateRubricData(rubric)
                          setCurrentMode('PREVIEW')
                          setIsEditable(false)
                        }}
                      >
                        {rubric.name}
                      </RubricsTag>
                    ))}
                  </TagContainer>
                </RecentlyUsedContainer>
              )}
            </>
          )}

        {currentMode === 'RUBRIC_TABLE' && (
          <>
            <RubricTable
              handleTableAction={handleTableAction}
              searchedRubricList={searchedRubricList}
              loading={searchingState}
              user={user}
            />
            <PaginationContainer>
              <Pagination
                current={currentPage}
                total={totalSearchedCount}
                pageSize={5}
                onChange={(page) => handlePaginationChange(page)}
                hideOnSinglePage
              />
            </PaginationContainer>
          </>
        )}
        {['CLONE', 'PREVIEW', 'EDIT'].includes(currentMode) && (
          <CreateNew
            isEditable={isEditable}
            handleSaveRubric={() => handleSaveRubric()}
          />
        )}
        {actionType === 'CREATE NEW' &&
          !['CLONE', 'PREVIEW', 'EDIT'].includes(currentMode) && (
            <CreateNew
              isEditable={isEditable}
              handleSaveRubric={() => handleSaveRubric()}
            />
          )}
      </ExistingRubricContainer>
      {showShareModal && (
        <ShareModal
          visible={showShareModal}
          handleResponse={handleShareModalResponse}
          currentRubricData={currentRubricData}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          visible={showDeleteModal}
          toggleModal={(res) => handleDeleteModalResponse(res)}
        />
      )}
      {showPreviewRubricModal && (
        <PreviewRubricModal
          visible={showPreviewRubricModal}
          toggleModal={() => setShowPreviewRubricModal(false)}
          currentRubricData={currentRubricData}
          shouldValidate={false}
        />
      )}
      <ConfirmModal
        visible={showConfirmModal}
        handleResponse={handleConfirmModalResponse}
      />
    </>
  )

  return (
    <>
      <Col md={24}>{getContent()}</Col>
    </>
  )
}

const enhance = compose(
  Form.create(),
  withRouter,
  withNamespaces('author'),
  connect(
    (state) => ({
      currentRubricData: getCurrentRubricDataSelector(state),
      user: getUserDetails(state),
      searchedRubricList: getSearchedRubricsListSelector(state),
      searchingState: getSearchingStateSelector(state),
      totalSearchedCount: getTotalSearchedCountSelector(state),
      currentQuestion: getCurrentQuestionSelector(state),
      recentlyUsedRubrics: getRecentlyUsedRubricsSelector(state),
      isRubricGenerationInProgress: getRubricGenerationInProgress(state),
      questionData: getQuestionDataSelector(state),
      allTagsData: getAllTagsSelector(state, 'testitem'),
      premium: state?.user?.user?.features?.premium,
      rubricUUIDs: getRubricUUIDsSelector(state),
      previousRubricGeneratedStimulus: getPreviousRubricGeneratedStimulusSelector(
        state
      ),
      isAiEvaulationDistrict: getIsAiEvaulationDistrictSelector(state),
      isGcpsDistrict: isGcpsDistrictSelector(state, true),
      currentLang: getCurrentLanguage(state),
      currentAlignments: getDictionariesAlignmentsSelector(state),
      rubricStandardIds: getQuestionRubricStandardIds(state),
      currentRubricStandards: getAllStandardsFromCurrentRubric(state),
      alignmentIndexMap: getAlignmentStandardMapSelector(state),
      aIAssisted: getAiAssistedRubricSelector(state),
    }),
    {
      updateRubricData: updateRubricDataAction,
      saveRubric: saveRubricAction,
      autoGenerateRubric: autoGenerateRubricAction,
      updateRubric: updateRubricAction,
      searchRubricsRequest: searchRubricsRequestAction,
      associateRubricWithQuestion: setRubricIdAction,
      dissociateRubricFromQuestion: removeRubricIdAction,
      deleteRubric: deleteRubricAction,
      addRubricToRecentlyUsed: addRubricToRecentlyUsedAction,
      setItemLevelScoring: setItemLevelScoreFromRubricAction,
      setQuestionData: setQuestionDataAction,
      addNewTag: addNewTagAction,
      removeAiTag: removeAiTagFromQuestionAction,
      setRubricGenerationStimulus: setRubricGenerationStimulusAction,
      updateDictAlignmentBulk: updateDictAlignmentBulkAction,
      setAIAssisted: setAIAssistedRubricAction,
    }
  )
)

export default enhance(UseExisting)
