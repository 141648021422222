import React, { createContext } from 'react'

const AudioControlContext = createContext({})

const AudioControlProvider = ({ children, ...audioControlProps }) => {
  return (
    <AudioControlContext.Provider value={{ ...audioControlProps }}>
      {children}
    </AudioControlContext.Provider>
  )
}

export { AudioControlProvider, AudioControlContext }
