import React from 'react'
import withIconStyles from '@edulastic/icons/src/HOC/withIconStyles'
import SVG from '@edulastic/icons/src/common/SVG'
import { accessibilityColors } from '@edulastic/colors'

const IconLock = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17.828 16.827"
    {...props}
  >
    <g transform="translate(15.576 -32.657)">
      <g transform="translate(-14.661 33.549)">
        <path
          d="M7.891,63.952a.69.69,0,0,0-.5.213L3.654,67.9H.718a.69.69,0,0,0-.5.213.69.69,0,0,0-.213.5v4.3a.689.689,0,0,0,.213.5.69.69,0,0,0,.5.213H3.654l3.733,3.733a.717.717,0,0,0,1.222-.5V64.67a.727.727,0,0,0-.717-.718Z"
          transform="translate(0 -62.931)"
          fill={accessibilityColors.testLibrary.footerIconText}
        />
        <path
          d="M261.494,173.284a2.857,2.857,0,0,0,.476-1.586,2.886,2.886,0,0,0-.476-1.592,2.576,2.576,0,0,0-1.261-1.042.626.626,0,0,0-.28-.056.7.7,0,0,0-.5.207.685.685,0,0,0-.213.51.6.6,0,0,0,.135.4,1.308,1.308,0,0,0,.325.28q.19.118.381.258a1.13,1.13,0,0,1,.325.4,1.585,1.585,0,0,1,0,1.278,1.131,1.131,0,0,1-.325.4q-.191.14-.381.258a1.309,1.309,0,0,0-.325.28.6.6,0,0,0-.135.4.684.684,0,0,0,.213.51.7.7,0,0,0,.5.208.628.628,0,0,0,.28-.056A2.65,2.65,0,0,0,261.494,173.284Z"
          transform="translate(-249.058 -163.862)"
          fill={accessibilityColors.testLibrary.footerIconText}
        />
        <path
          d="M291.324,110.134a5.738,5.738,0,0,0,0-6.333,5.531,5.531,0,0,0-2.522-2.113.807.807,0,0,0-.291-.056.728.728,0,0,0-.718.717.711.711,0,0,0,.437.661,6.948,6.948,0,0,1,.852.493,4.286,4.286,0,0,1,0,6.927,6.942,6.942,0,0,1-.852.493.711.711,0,0,0-.437.661.689.689,0,0,0,.213.5.7.7,0,0,0,.515.213.785.785,0,0,0,.28-.056A5.53,5.53,0,0,0,291.324,110.134Z"
          transform="translate(-276.494 -99.132)"
          fill={accessibilityColors.testLibrary.footerIconText}
        />
      </g>
      <line
        x2="15"
        y2="14"
        transform="translate(-14.162 34.07)"
        fill="none"
        stroke={accessibilityColors.testLibrary.footerIconText}
        strokeLinecap="round"
        strokeWidth="2"
      />
    </g>
  </SVG>
)

export default withIconStyles(IconLock)
