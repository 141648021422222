import React from 'react'
import styled from 'styled-components'
import { extraDesktopWidthMax, smallDesktopWidth } from '@edulastic/colors'

const StyledSVGContainer = styled.svg`
  height: ${({ height }) => height || '30px'};
  width: ${({ width }) => width || '30px'};

  @media (min-width: ${extraDesktopWidthMax}) {
    height: ${(props) => props.height || '36px'};
    width: ${(props) => props.width || '36px'};
  }
`

const Hovered = (props) => {
  return (
    <StyledSVGContainer
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="29"
        height="29"
        rx="3.5"
        fill="url(#paint0_linear_40_9505)"
        stroke="url(#paint1_linear_40_9505)"
      />
      <path
        d="M20.6259 11.625L21.3309 10.08L22.8759 9.375L21.3309 8.67L20.6259 7.125L19.9209 8.67L18.3759 9.375L19.9209 10.08L20.6259 11.625Z"
        fill="white"
      />
      <path
        d="M12.0009 11.625L12.7059 10.08L14.2509 9.375L12.7059 8.67L12.0009 7.125L11.2959 8.67L9.7509 9.375L11.2959 10.08L12.0009 11.625Z"
        fill="white"
      />
      <path
        d="M20.6259 15.75L19.9209 17.295L18.3759 18L19.9209 18.705L20.6259 20.25L21.3309 18.705L22.8759 18L21.3309 17.295L20.6259 15.75Z"
        fill="white"
      />
      <path
        d="M18.9084 13.215L16.7859 11.0925C16.6359 10.95 16.4484 10.875 16.2534 10.875C16.0584 10.875 15.8709 10.95 15.7209 11.0925L7.3434 19.47C7.0509 19.7625 7.0509 20.235 7.3434 20.5275L9.4659 22.65C9.6159 22.8 9.8034 22.875 9.9984 22.875C10.1934 22.875 10.3809 22.8 10.5309 22.6575L18.9084 14.28C19.2009 13.9875 19.2009 13.5075 18.9084 13.215ZM16.2534 12.69L17.3109 13.7475L16.4334 14.625L15.3759 13.5675L16.2534 12.69ZM9.9984 21.0675L8.9409 20.01L14.3184 14.625L15.3759 15.6825L9.9984 21.0675Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_40_9505"
          x1="30"
          y1="4.08041e-05"
          x2="-0.345099"
          y2="0.353269"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#007D65" />
          <stop offset="1" stopColor="#1766CE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_40_9505"
          x1="30"
          y1="4.08041e-05"
          x2="-0.345099"
          y2="0.353269"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#007D65" />
          <stop offset="1" stopColor="#1766CE" />
        </linearGradient>
      </defs>
    </StyledSVGContainer>
  )
}

const OutLined = (props) => {
  return (
    <StyledSVGContainer
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="29"
        height="29"
        rx="3.5"
        fill="white"
        stroke="url(#paint0_linear_40_9511)"
      />
      <path
        d="M20.6259 11.625L21.3309 10.08L22.8759 9.375L21.3309 8.67L20.6259 7.125L19.9209 8.67L18.3759 9.375L19.9209 10.08L20.6259 11.625Z"
        fill="url(#paint1_linear_40_9511)"
      />
      <path
        d="M12.0009 11.625L12.7059 10.08L14.2509 9.375L12.7059 8.67L12.0009 7.125L11.2959 8.67L9.7509 9.375L11.2959 10.08L12.0009 11.625Z"
        fill="url(#paint2_linear_40_9511)"
      />
      <path
        d="M20.6259 15.75L19.9209 17.295L18.3759 18L19.9209 18.705L20.6259 20.25L21.3309 18.705L22.8759 18L21.3309 17.295L20.6259 15.75Z"
        fill="url(#paint3_linear_40_9511)"
      />
      <path
        d="M18.9084 13.215L16.7859 11.0925C16.6359 10.95 16.4484 10.875 16.2534 10.875C16.0584 10.875 15.8709 10.95 15.7209 11.0925L7.3434 19.47C7.0509 19.7625 7.0509 20.235 7.3434 20.5275L9.4659 22.65C9.6159 22.8 9.8034 22.875 9.9984 22.875C10.1934 22.875 10.3809 22.8 10.5309 22.6575L18.9084 14.28C19.2009 13.9875 19.2009 13.5075 18.9084 13.215ZM16.2534 12.69L17.3109 13.7475L16.4334 14.625L15.3759 13.5675L16.2534 12.69ZM9.9984 21.0675L8.9409 20.01L14.3184 14.625L15.3759 15.6825L9.9984 21.0675Z"
        fill="url(#paint4_linear_40_9511)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_40_9511"
          x1="30"
          y1="4.08041e-05"
          x2="-0.345099"
          y2="0.353269"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#007D65" />
          <stop offset="1" stopColor="#1766CE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_40_9511"
          x1="22.8759"
          y1="7.12502"
          x2="6.94283"
          y2="7.31051"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#007D65" />
          <stop offset="1" stopColor="#1766CE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_40_9511"
          x1="22.8759"
          y1="7.12502"
          x2="6.94283"
          y2="7.31051"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#007D65" />
          <stop offset="1" stopColor="#1766CE" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_40_9511"
          x1="22.8759"
          y1="7.12502"
          x2="6.94283"
          y2="7.31051"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#007D65" />
          <stop offset="1" stopColor="#1766CE" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_40_9511"
          x1="22.8759"
          y1="7.12502"
          x2="6.94283"
          y2="7.31051"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#007D65" />
          <stop offset="1" stopColor="#1766CE" />
        </linearGradient>
      </defs>
    </StyledSVGContainer>
  )
}

export const AIEnhanceQuestionButtonIcon = ({ hovered, ...props }) => {
  return hovered ? <Hovered {...props} /> : <OutLined {...props} />
}
