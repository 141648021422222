/* eslint-disable react/prop-types */
import React from 'react'

import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconWorldWide = (props) => (
  <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
    <path d="M14.1,1.592l.015-.01-.4-.245c-.023-.014-.048-.026-.071-.041q-.2-.118-.4-.226c-.068-.037-.137-.071-.206-.106-.1-.051-.2-.1-.307-.148-.061-.028-.122-.056-.183-.083q-.241-.1-.488-.194L11.966.5q-.216-.076-.438-.14l-.142-.04c-.163-.045-.328-.086-.5-.122L10.844.191c-.185-.039-.371-.071-.56-.1L10.16.078Q9.939.05,9.715.032L9.564.02C9.377.008,9.19,0,9,0c-.2,0-.407.009-.609.023L8.233.035C8.044.051,7.856.071,7.669.1L7.614.107c-.187.029-.372.066-.555.106L6.877.255c-.169.041-.335.086-.5.136l-.155.05Q6,.513,5.784.6c-.066.025-.132.051-.2.078-.166.068-.329.14-.49.217-.053.025-.1.054-.157.081q-.188.1-.371.2c-.061.034-.121.069-.182.1q-.229.137-.448.287c-.02.014-.041.025-.061.039l-.164.113,0,.007A9,9,0,1,0,14.1,1.592ZM9,17.4A8.4,8.4,0,0,1,3.918,2.318c.053.119.107.242.164.371.59,1.345,1.324,3.02,2.552,3.491a4.055,4.055,0,0,0,.916.21,2.855,2.855,0,0,1,.881.226.368.368,0,0,1,.082.063,1.372,1.372,0,0,1,.164.584.91.91,0,0,0,.376.773c.1.051.179.153.136.258a2.914,2.914,0,0,0,1,3.347c.275.228.407.853-.532,2.533a.745.745,0,0,0,.007.747.765.765,0,0,0,.656.38.773.773,0,0,0,.258-.045,5.79,5.79,0,0,0,2.828-2.271,4.081,4.081,0,0,1,.929-1.044.357.357,0,0,0,.128-.173c.032-.095.075-.206.122-.329.308-.809.775-2.031-.052-2.461a2.312,2.312,0,0,1-.749-.923,5.3,5.3,0,0,0-.695-.993.757.757,0,0,0-.459-.218,2.091,2.091,0,0,1-.664-.229,1.508,1.508,0,0,0-.66-.2.879.879,0,0,0-.541.193c-.208.157-.421.067-.5-.2a.8.8,0,0,0-.6-.579.071.071,0,0,1-.043-.039.121.121,0,0,1,0-.1.426.426,0,0,0-.043-.4.7.7,0,0,0-.592-.227.314.314,0,0,0-.031-.077l-.089-.158-.275.006-.07.042a.2.2,0,0,1-.245-.066.331.331,0,0,1-.1-.237,1.077,1.077,0,0,1,.011-.13,3.535,3.535,0,0,1,.419-.06L8.8,4.358l.044-.02c.485-.225.556-.126.6-.083s.13.181.119.6l-.008.266.263.039a1.458,1.458,0,0,0,.212.017.665.665,0,0,0,.568-.246.87.87,0,0,0,.061-.8.191.191,0,0,1,.035-.164A5.3,5.3,0,0,1,13.54,1.939,8.4,8.4,0,0,1,9,17.4Z" />
  </SVG>
)

export default withIconStyles(IconWorldWide)
