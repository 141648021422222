import { black } from '@edulastic/colors'
import { Typography } from 'antd'
import styled from 'styled-components'

const { Text } = Typography

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
  max-width: 300px;
  align-items: center;
`
/** @type {typeof Text} */
export const Title = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  color: ${black};
  cursor: pointer;
  max-width: 200px;
`
