/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconMagicWandGradient = (props) => (
  <SVG
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.0009 5L13.6276 3.62667L15.0009 3L13.6276 2.37333L13.0009 1L12.3743 2.37333L11.0009 3L12.3743 3.62667L13.0009 5Z"
      fill="url(#paint0_linear_205_5820)"
    />
    <path
      d="M5.33427 5L5.96093 3.62667L7.33427 3L5.96093 2.37333L5.33427 1L4.7076 2.37333L3.33427 3L4.7076 3.62667L5.33427 5Z"
      fill="url(#paint1_linear_205_5820)"
    />
    <path
      d="M13.0009 8.66667L12.3743 10.04L11.0009 10.6667L12.3743 11.2933L13.0009 12.6667L13.6276 11.2933L15.0009 10.6667L13.6276 10.04L13.0009 8.66667Z"
      fill="url(#paint2_linear_205_5820)"
    />
    <path
      d="M11.4743 6.41333L9.5876 4.52667C9.45427 4.4 9.2876 4.33333 9.11427 4.33333C8.94093 4.33333 8.77427 4.4 8.64093 4.52667L1.19427 11.9733C0.934268 12.2333 0.934268 12.6533 1.19427 12.9133L3.08093 14.8C3.21427 14.9333 3.38093 15 3.55427 15C3.7276 15 3.89427 14.9333 4.0276 14.8067L11.4743 7.36C11.7343 7.1 11.7343 6.67333 11.4743 6.41333ZM9.11427 5.94667L10.0543 6.88667L9.27427 7.66667L8.33427 6.72667L9.11427 5.94667ZM3.55427 13.3933L2.61427 12.4533L7.39427 7.66667L8.33427 8.60667L3.55427 13.3933Z"
      fill="url(#paint3_linear_205_5820)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_205_5820"
        x1="15.0009"
        y1="1.00002"
        x2="0.838203"
        y2="1.1649"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#007D65" />
        <stop offset="1" stopColor="#1766CE" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_205_5820"
        x1="15.0009"
        y1="1.00002"
        x2="0.838203"
        y2="1.1649"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#007D65" />
        <stop offset="1" stopColor="#1766CE" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_205_5820"
        x1="15.0009"
        y1="1.00002"
        x2="0.838203"
        y2="1.1649"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#007D65" />
        <stop offset="1" stopColor="#1766CE" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_205_5820"
        x1="15.0009"
        y1="1.00002"
        x2="0.838203"
        y2="1.1649"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#007D65" />
        <stop offset="1" stopColor="#1766CE" />
      </linearGradient>
    </defs>
  </SVG>
)

export default withIconStyles(IconMagicWandGradient)
