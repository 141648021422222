import { get } from 'lodash'
import { clozeImage } from '@edulastic/constants'

export const getPreviewDimensions = ({
  responseContainers,
  item,
  canvasWidth,
  imageHeight,
}) => {
  const maxResponseOffsetTop = responseContainers.reduce((max, resp) => {
    max = Math.max(max, resp.top + parseInt(resp.height, 10))
    return max
  }, -1)
  const annotations = item.annotations || []
  const maxAnnotationsOffsetTop = annotations.reduce((max, ann) => {
    max = Math.max(ann.position.y + parseInt(ann.size.height, 10), max)
    return max
  }, 0)
  const imageOffsetY = get(item, 'imageOptions.y', 0)
  // eslint-disable-next-line max-len
  const computedHeight = Math.max(
    maxResponseOffsetTop,
    maxAnnotationsOffsetTop,
    imageHeight + imageOffsetY
  )
  const { maxWidth } = clozeImage
  const previewContainerWidth = canvasWidth > maxWidth ? canvasWidth : maxWidth

  return { computedHeight, previewContainerWidth }
}
