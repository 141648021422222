import { createAction } from 'redux-starter-kit'
import {
  TOGGLE_PRESENTATION_MODE,
  OPEN_SECTION_REQUEST,
  OPEN_SECTION_SUCCESS,
  UPDATE_SECTION_STATUS,
} from '../constants/actions'

// toggle presentation mode!
export const togglePresentationModeAction = createAction(
  TOGGLE_PRESENTATION_MODE
)

export const openSectionAction = (payload) => ({
  type: OPEN_SECTION_REQUEST,
  payload,
})

export const openSectionSuccessAction = (payload) => ({
  type: OPEN_SECTION_SUCCESS,
  payload,
})

export const updateSectionStatusAction = (payload) => ({
  type: UPDATE_SECTION_STATUS,
  payload,
})
