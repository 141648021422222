import React from 'react'
import { IconCheckMark } from '@edulastic/icons'
import { Container, Description, Heading } from '../styled'

const ConfirmationMessage = () => {
  return (
    <Container>
      <IconCheckMark color="white" height="32px" width="32px" />
      <Heading>Thanks for your confirmation!</Heading>
      <Description>
        This information will help us prepare your reports and search results.
      </Description>
    </Container>
  )
}

export default ConfirmationMessage
