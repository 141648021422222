import React, { useEffect } from 'react'
import { withNamespaces } from '@edulastic/localization'
import { Spin, List } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { compose } from 'redux'
import TextWrapper from '../../AssignmentCreate/common/TextWrapper'
import { FlexContainer, StyledButton } from './styled'
import {
  qtiImportProgressAction,
  UPLOAD_STATUS,
  getIsSuccessSelector,
  getErrorDetailsSelector,
  getSuccessMessageSelector,
  getJobIdsSelector,
  getUploadStatusSelector,
  setJobIdsAction,
  uploadTestStatusAction,
  getIsImportingselector,
  JOB_STATUS,
  getJobsDataSelector,
  getQtiFileStatusSelector,
  resetStateAction,
} from '../ducks'
import {
  contentImportProgressAction,
  uploadContentStatusAction,
  setImportContentJobIdsAction,
  importTypeSelector,
} from '../../ContentCollections/ducks'

const ImportInprogress = ({
  t,
  qtiImportProgress,
  jobIds,
  status,
  successMessage,
  isSuccess,
  errorDetails,
  isImporting,
  contentImportProgress,
  location: { pathname: path },
  resetData,
  history,
  jobsData,
  qtiFileStatus = {},
  intervalRef,
}) => {
  const jobId = Array.isArray(jobIds) ? jobIds.join() : jobIds
  const checkProgress = () => {
    if (jobId.includes('qti') && jobIds.length) {
      if (
        jobsData.length === 0 ||
        jobsData.some((job) =>
          [JOB_STATUS.INITIATED, JOB_STATUS.IN_PROGRESS].includes(job.status)
        )
      ) {
        qtiImportProgress({ jobId: jobIds, interval: intervalRef })
      }
    } else if (status !== UPLOAD_STATUS.STANDBY && jobIds.length) {
      contentImportProgress({ jobIds, interval: intervalRef })
    }
  }

  // TODO: need to handle
  const handleRetry = () => {
    resetData()
    sessionStorage.removeItem('jobIds')
    sessionStorage.removeItem('qtiTags')
    sessionStorage.removeItem('testUploadStatus')
    if (path === '/author/import-content') {
      history.push('/author/content/collections')
    }
  }
  useEffect(() => {
    // FIXME: Remove Polling and use firestore db / IOT messages to get the latest status of Import from server
    if (jobIds.length && !intervalRef?.current) {
      intervalRef.current = setInterval(() => {
        checkProgress()
      }, 1000 * 5)
    }
  }, [jobIds])

  const isQtiImport = jobId.includes('qti')
  const totalQtiFiles = jobsData.filter((ele) => ele.type !== 'manifestation')
    .length

  return (
    <FlexContainer flexDirection="column" alignItems="column">
      <TextWrapper
        style={{ color: isSuccess ? 'green' : 'red', fontWeight: 'bold' }}
      >
        {isSuccess
          ? successMessage
          : errorDetails?.message || t('qtiimport.failed.description')}
        {!isSuccess && (
          <p>
            <StyledButton
              data-cy="retryImport"
              position="relative"
              onClick={handleRetry}
            >
              {t('qtiimport.uploadpage.retry')}
            </StyledButton>
          </p>
        )}
      </TextWrapper>
      <Spin size="small" />
      <TextWrapper>
        {isQtiImport
          ? jobIds.length
            ? `Import reference: ${jobIds}`
            : ''
          : path === '/author/tests/import'
          ? isImporting
            ? t('qtiimport.importinprogress.description')
            : 'Please stay on the screen while we are unzipping your files'
          : 'Files are being processed'}
      </TextWrapper>
      {isQtiImport && (
        <List itemLayout="horizontal">
          <List.Item>
            <FlexContainer justifyContent="space-between" width="100%">
              <div>Total no of questions</div>
              <div>{totalQtiFiles || 0}</div>
            </FlexContainer>
          </List.Item>
          <List.Item>
            <FlexContainer justifyContent="space-between" width="100%">
              <div>No of questions is processing</div>
              <div>{qtiFileStatus[JOB_STATUS.INITIATED] || 0}</div>
            </FlexContainer>
          </List.Item>
          <List.Item>
            <FlexContainer justifyContent="space-between" width="100%">
              <div>No of questions completed</div>
              <div>{qtiFileStatus[JOB_STATUS.COMPLETED] || 0}</div>
            </FlexContainer>
          </List.Item>
          <List.Item>
            <FlexContainer justifyContent="space-between" width="100%">
              <div>No of questions failed</div>
              <div>
                {totalQtiFiles -
                  qtiFileStatus[JOB_STATUS.COMPLETED] -
                  qtiFileStatus[JOB_STATUS.INITIATED] || 0}
              </div>
            </FlexContainer>
          </List.Item>
        </List>
      )}
    </FlexContainer>
  )
}

ImportInprogress.propTypes = {
  t: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    jobIds: getJobIdsSelector(state),
    status: getUploadStatusSelector(state),
    successMessage: getSuccessMessageSelector(state),
    isSuccess: getIsSuccessSelector(state),
    errorDetails: getErrorDetailsSelector(state),
    isImporting: getIsImportingselector(state),
    jobsData: getJobsDataSelector(state),
    importType: importTypeSelector(state),
    qtiFileStatus: getQtiFileStatusSelector(state),
  }
}

const enhancedComponent = compose(
  withNamespaces('qtiimport'),
  withRouter,
  connect(mapStateToProps, {
    qtiImportProgress: qtiImportProgressAction,
    setJobIds: setJobIdsAction,
    uploadTestStatus: uploadTestStatusAction,
    uploadContentStatus: uploadTestStatusAction,
    contentImportProgress: contentImportProgressAction,
    setUploadContnentStatus: uploadContentStatusAction,
    setImportContentJobIds: setImportContentJobIdsAction,
    resetData: resetStateAction,
  })
)

export default enhancedComponent(ImportInprogress)
