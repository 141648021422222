import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconFeedback = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16.357 18.251"
    {...props}
  >
    <g transform="translate(-26.57)">
      <g transform="translate(26.57)">
        <g transform="translate(0)">
          <path
            d="M115.228,200.713a.713.713,0,0,0-.713-.713h-7.343a.713.713,0,1,0,0,1.426h7.343A.713.713,0,0,0,115.228,200.713Z"
            transform="translate(-103.611 -192.871)"
          />
          <path
            d="M107.172,280a.713.713,0,0,0,0,1.426h4.46a.713.713,0,0,0,0-1.426Z"
            transform="translate(-103.611 -270.019)"
          />
          <path
            d="M31.806,16.826H29.422A1.428,1.428,0,0,1,28,15.4V2.852a1.428,1.428,0,0,1,1.426-1.426h8.765a1.428,1.428,0,0,1,1.426,1.426V7.236a.713.713,0,1,0,1.426,0V2.852A2.855,2.855,0,0,0,38.187,0H29.422A2.855,2.855,0,0,0,26.57,2.852V15.4a2.855,2.855,0,0,0,2.852,2.852h2.384a.713.713,0,1,0,0-1.426Z"
            transform="translate(-26.57)"
          />
          <path
            d="M251.457,272.65a2.141,2.141,0,0,0-3.024,0l-3.914,3.906a.713.713,0,0,0-.179.3l-.852,2.806a.713.713,0,0,0,.873.894l2.878-.8a.712.712,0,0,0,.313-.182l3.906-3.9A2.141,2.141,0,0,0,251.457,272.65Zm-4.781,5.782-1.448.4.424-1.395,2.641-2.635,1.008,1.008Zm3.773-3.766-.138.138L249.3,273.8l.138-.137a.713.713,0,0,1,1.009,1.008Z"
            transform="translate(-235.726 -262.327)"
          />
          <path
            d="M114.515,120h-7.343a.713.713,0,0,0,0,1.426h7.343a.713.713,0,1,0,0-1.426Z"
            transform="translate(-103.611 -115.722)"
          />
        </g>
      </g>
    </g>
  </SVG>
)

export default withIconStyles(IconFeedback)
