import { questionTitle } from '@edulastic/constants'
import {
  MULTIPLE_CHOICE,
  MULTIPLE_SELECTION,
  TRUE_OR_FALSE,
} from '@edulastic/constants/const/questionType'
import { parseHTMLToPlainText } from './htmlParser'

export const titleToAIQuestionType = (title) => {
  if (title == questionTitle.MCQ_STANDARD) {
    return MULTIPLE_CHOICE
  }
  if (title === questionTitle.MCQ_TRUE_OR_FALSE) {
    return TRUE_OR_FALSE
  }
  if (title === questionTitle.MCQ_MULTIPLE_RESPONSE) {
    return MULTIPLE_SELECTION
  }
  return null
}

export const passagesToAIPrompt = (passages) => {
  return passages
    ?.map((passage, index) => {
      const { title, heading, source, pages, content } = passage

      let formattedPassage = `Passage ${index + 1}:\n`
      formattedPassage += `Title:  ${title}\n`
      formattedPassage += `Heading:  ${parseHTMLToPlainText(heading)}\n`
      if (source) {
        formattedPassage += `Source:  ${parseHTMLToPlainText(source)}\n`
      }
      if (pages) {
        formattedPassage += `Content: \n`
        pages.forEach((page) => {
          formattedPassage += `${parseHTMLToPlainText(page)}\n`
        })
      } else if (content) {
        formattedPassage += `Content: ${parseHTMLToPlainText(content)}\n`
      }

      return formattedPassage
    })
    .join('\n')
}

export const getPassagePreference = (passages, preference) => {
  let formattedPassage = ''
  if (passages?.length || preference) {
    if (passages?.length) {
      formattedPassage +=
        'Generate question based upon on below passage content:\n'
      formattedPassage += passagesToAIPrompt(passages)
    }
    if (preference) {
      formattedPassage += 'Also consider below content type:\n'
      formattedPassage += preference
    }
  }
  return formattedPassage
}
