import React from 'react'
import uuid from 'uuid/v4'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconAITest = (props) => {
  const linearGradientId = uuid()
  const clipPathId = uuid()

  return (
    <SVG
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="21" cy="21" r="21" fill={`url(#${linearGradientId})`} />
      <g clipPath={`url(#${clipPathId})`}>
        <path
          d="M17.0313 15.225L18.4188 15.9844C18.5969 16.0813 18.6625 16.3032 18.5656 16.4813C18.5313 16.5438 18.4813 16.5938 18.4188 16.6282L17.0313 17.3844C16.9688 17.4188 16.9188 17.4688 16.8844 17.5313L16.125 18.9188C16.0281 19.0969 15.8063 19.1625 15.6281 19.0657C15.5656 19.0313 15.5156 18.9813 15.4813 18.9188L14.725 17.5313C14.6906 17.4688 14.6406 17.4188 14.5781 17.3844L13.1906 16.6282C13.0125 16.5313 12.9469 16.3094 13.0438 16.1313C13.0781 16.0688 13.1281 16.0188 13.1906 15.9844L14.5781 15.225C14.6406 15.1907 14.6906 15.1407 14.725 15.0782L15.4844 13.6938C15.5813 13.5157 15.8063 13.45 15.9844 13.55C16.0438 13.5844 16.0938 13.6344 16.1281 13.6938L16.8875 15.0782C16.9188 15.1407 16.9688 15.1907 17.0313 15.225Z"
          fill="white"
        />
        <path
          d="M27.3594 22.6312L28.7469 23.3906C28.925 23.4875 28.9906 23.7093 28.8938 23.8875C28.8594 23.95 28.8094 24 28.7469 24.0343L27.3594 24.7937C27.2969 24.8281 27.2469 24.8781 27.2125 24.9406L26.4531 26.3281C26.3563 26.5062 26.1313 26.5718 25.9531 26.475C25.8906 26.4406 25.8406 26.3906 25.8063 26.3281L25.0531 24.9375C25.0188 24.875 24.9688 24.825 24.9063 24.7906L23.5188 24.0312C23.3406 23.9343 23.275 23.7093 23.3719 23.5312C23.4063 23.4687 23.4563 23.4187 23.5188 23.3843L24.9063 22.625C24.9688 22.5906 25.0188 22.5406 25.0531 22.4781L25.8125 21.0937C25.9094 20.9156 26.1344 20.85 26.3125 20.9468C26.375 20.9812 26.425 21.0312 26.4594 21.0937L27.2188 22.4812C27.2469 22.5468 27.2969 22.5968 27.3594 22.6312Z"
          fill="white"
        />
        <path
          d="M21.3533 13.6375L22.2627 14.1344C22.3783 14.2 22.4221 14.3469 22.3596 14.4625C22.3377 14.5031 22.3033 14.5344 22.2627 14.5594L21.3533 15.0563C21.3127 15.0781 21.2783 15.1125 21.2564 15.1531L20.7596 16.0625C20.6939 16.1781 20.5471 16.2219 20.4314 16.1594C20.3908 16.1375 20.3596 16.1031 20.3346 16.0625L19.8377 15.1531C19.8158 15.1125 19.7814 15.0781 19.7408 15.0563L18.8314 14.5594C18.7158 14.4969 18.6721 14.35 18.7346 14.2313C18.7564 14.1906 18.7908 14.1563 18.8314 14.1344L19.7408 13.6375C19.7814 13.6156 19.8158 13.5813 19.8377 13.5406L20.3346 12.6313C20.4002 12.5156 20.5471 12.4719 20.6627 12.5344C20.7033 12.5563 20.7346 12.5906 20.7596 12.6313L21.2564 13.5406C21.2783 13.5813 21.3127 13.6156 21.3533 13.6375Z"
          fill="white"
        />
        <path
          d="M28.6437 14.0531L27.3593 12.7687C26.8843 12.2937 26.1124 12.2906 25.6343 12.7656C25.6343 12.7656 25.6343 12.7656 25.6312 12.7687L14.1812 24.2218C13.7062 24.6968 13.703 25.4687 14.1812 25.9468L15.4655 27.2312C15.9405 27.7062 16.7124 27.7093 17.1905 27.2312L28.6437 15.7781C29.1187 15.3031 29.1187 14.5312 28.6437 14.0531ZM24.178 18.3843L23.028 17.2343L26.4187 13.8406L27.5687 14.9906L24.178 18.3843Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id={linearGradientId}
          x1="42"
          y1="5.71258e-05"
          x2="-0.483139"
          y2="0.494576"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#007D65" />
          <stop offset="1" stopColor="#1766CE" />
        </linearGradient>
        <clipPath id={clipPathId}>
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(13 12)"
          />
        </clipPath>
      </defs>
    </SVG>
  )
}

export default withIconStyles(IconAITest)
