import styled from 'styled-components'
import { Row, Table } from 'antd'
import { grey, darkGrey } from '@edulastic/colors'

import { SelectInputStyled } from '@edulastic/common'

import { CustomTooltip } from '../../../../common/components/charts/chartUtils/CustomTooltip'
import { CompareByContainer } from '../../common/components/styledComponents'

export const StyledTooltipContainer = styled(CustomTooltip)`
  min-width: 214px;
  max-width: 250px;
  min-height: 75px;
  background-color: #f9f9f9;
  border-radius: 3px;
  box-shadow: 0px 4px 15px 0px #0000001f;
  border: 1px solid #d8d8d8;
  font-size: 12px;
  font-weight: 600;
  z-index: 999;
`
export const StyledRow = styled(Row)`
  padding: 10px;
`
export const StyledText = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: ${(p) => p.$size || '12px'};
  font-weight: ${(p) => p.$weight || 600};
  color: ${(p) => p.$color || '#555555'};
  margin: ${(p) => p.$margin || '5px 0 5px 0'};
`
export const StyledTable = styled(Table)`
  thead {
    th {
      padding: 10px 16px;
      font-size: 11px;
      font-weight: bold;
      color: #aaafb5;
      text-transform: uppercase;
      max-width: 150px;
    }
  }
  ${CompareByContainer} {
    margin: 0px;
  }
  tbody {
    td {
      font-weight: 600;
    }
  }
  .ant-table-body::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }
  .ant-table-body::-webkit-scrollbar-track {
    background: ${grey};
  }

  .ant-table-body::-webkit-scrollbar-thumb {
    background: ${darkGrey};
  }
`
export const StyledSelectInput = styled(SelectInputStyled)`
  &.ant-select {
    height: 32px;
    display: flex;
    max-width: 175px;
    align-items: center;
    .ant-select-selection {
      display: block;
      overflow: hidden;
      &.ant-select-selection--multiple {
        .ant-select-selection__rendered .ant-select-selection__choice {
          height: 20px;
          @media print {
            max-width: fit-content;
          }
        }
      }
    }
    .ant-select-selection__placeholder {
      font-size: 11px;
    }
    .ant-select-dropdown-menu-item {
      font-weight: 400;
      font-size: 12px;
    }
    @media print {
      height: fit-content;
    }
  }
`
