import aiAPI from '@edulastic/api/src/aiAPI'
import { isEmpty, cloneDeep } from 'lodash'
import { all, call, put, takeEvery, select } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { notification } from '@edulastic/common'
import { alignmentStandardsFromUIToMongo } from '../../../../assessment/utils/helpers'
import { getTestEntitySelector } from '../../../TestPage/ducks'
import { DEFAULT_TEST_TITLE } from '../../../TestPage/utils'
import { getAlignmentDataForAiQuestions } from '../CreateAITest/ducks/helpers'
import { processAiGeneratedTestItemsSaga } from '../CreateAITest/ducks/sagas'
import { updateMetaData } from './utils'
import { AI_ERROR_MAP } from '../../../AIFeatures/constants/form'

export const namespaceKey = 'importTestFromDocReducer'

// actions constants
export const IMPORT_TEST_FROM_DOC = `[test from doc] import test from doc`
export const SET_IMPORT_TEST_FROM_DOC = `[test from doc] set state`
export const PROCESS_IMPORTED_QUESTIONS_AND_CREATE_TEST = `[test from doc] process imported questions and create test`

// actions
export const importTestFromDocAction = (payload) => ({
  type: IMPORT_TEST_FROM_DOC,
  payload,
})

export const setImportTestFromDocStateAction = (payload) => ({
  type: SET_IMPORT_TEST_FROM_DOC,
  payload,
})

export const processImportedQuestionsAndCreateTestAction = (payload) => ({
  type: PROCESS_IMPORTED_QUESTIONS_AND_CREATE_TEST,
  payload,
})

// selectors
export const stateSelector = (state) => state?.[namespaceKey]

export const getImportTestFromDocSelector = createSelector(
  stateSelector,
  (state) => state || {}
)

// initial state
const initialState = {
  apiStatus: false,
  result: {},
  errorMessage: '',
}

// reducer
export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_IMPORT_TEST_FROM_DOC:
      return {
        ...state,
        ...payload,
      }
    default:
      return state
  }
}

// sagas
function* importTestFromDocSaga({ payload }) {
  const generalErrorMessage = 'Something went wrong. Please try again'
  try {
    yield put(
      setImportTestFromDocStateAction({
        apiStatus: 'INITIATED',
        result: [],
        errorMessage: '',
      })
    )
    const {
      cdnUrl,
      alignment: { curriculum = '', grades, subject, standards = [] } = {},
    } = payload || {}
    let commonCoreStandards = []
    let commonCoresStandardDescriptions = []

    if (standards?.length) {
      const formattedAlignmentData = getAlignmentDataForAiQuestions([
        {
          grades,
          subject,
          standards,
          curriculum,
        },
      ])
      commonCoreStandards = formattedAlignmentData?.standardIds || []
      commonCoresStandardDescriptions =
        formattedAlignmentData?.standardDescriptions || []
    }

    const importQuestionPayload = {
      url: cdnUrl,
      subject,
      grades,
      standardSet: curriculum,
      commonCoreStandards,
      commonCoresStandardDescriptions,
    }
    let { result } = yield call(aiAPI.importQuestion, importQuestionPayload)
    const updatedMetaData = updateMetaData(result)
    result = { ...result, metaData: updatedMetaData }
    yield put(
      setImportTestFromDocStateAction({
        apiStatus: 'SUCCESS',
        result,
        errorMessage: '',
      })
    )
  } catch (err) {
    const errMsg = err?.response?.data?.message
    const aiErrorMessage = AI_ERROR_MAP?.[errMsg]
    if (errMsg) {
      notification({
        type: 'error',
        exact: !!aiErrorMessage,
        msg: aiErrorMessage || errMsg,
      })
    } else {
      notification({
        type: 'error',
        messageKey: 'aiImportQuestionsFailed',
      })
    }

    yield put(
      setImportTestFromDocStateAction({
        apiStatus: 'FAILED',
        result: [],
        errorMessage: err?.message || generalErrorMessage,
      })
    )
  }
}

function* processImportedQuestionsAndCreateTestSaga({ payload }) {
  //   const generalErrorMessage = 'Something went wrong. Please try again'
  try {
    const {
      fileName = DEFAULT_TEST_TITLE,
      alignment: {
        curriculumId = null,
        curriculum,
        grades,
        subject,
        standards = [],
      } = {},
    } = payload || {}

    const {
      result: { questions: aiGeneratedQuestions = {} } = {},
    } = yield select(getImportTestFromDocSelector) || {}

    if (!isEmpty(aiGeneratedQuestions)) {
      const alignment = [
        {
          curriculum,
          ...(curriculumId ? { curriculumId } : {}),
          subject,
          grades,
          domains: alignmentStandardsFromUIToMongo([
            ...(standards?.length ? standards : []),
          ]),
          standards,
        },
      ]
      const testEntity = yield select(getTestEntitySelector)
      const assessment = cloneDeep(testEntity)
      assessment.aiGenerated = true
      assessment.aiDocExtracted = true

      yield* processAiGeneratedTestItemsSaga({
        aiGeneratedQuestions,
        testName: fileName,
        grades,
        subject,
        alignment,
        assessment,
        groupIndex: 0,
        aiDocExtracted: true,
      })
    }
  } catch (err) {
    console.log('err', err)
    // TODO: handle error
  } finally {
    yield put(
      setImportTestFromDocStateAction({
        apiStatus: false,
        result: {},
        errorMessage: '',
      })
    )
  }
}

export function* watcherSaga() {
  yield all([
    takeEvery(IMPORT_TEST_FROM_DOC, importTestFromDocSaga),
    takeEvery(
      PROCESS_IMPORTED_QUESTIONS_AND_CREATE_TEST,
      processImportedQuestionsAndCreateTestSaga
    ),
  ])
}
