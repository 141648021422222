import styled from 'styled-components'

export const Col = styled.div`
  margin-right: 15px;
  width: 100%;

  :last-child {
    margin-right: 0;
  }
`
