import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { HeaderTabs } from '@edulastic/common'
import { HeaderMidContainer } from '@edulastic/common/src/components/MainHeader'
import { StyledTabs } from '@edulastic/common/src/components/HeaderTabs'

function TestPageNav({
  onChange,
  current,
  buttons,
  showPublishButton = true,
  owner = false,
  isEditDisabledForSectionsAdaptiveTest = false,
  i18translate = () => {},
  hasSections = false,
}) {
  /**
   * In case of sections test (sections test & section adaptive test) an additional manage sections tab
   * is present before the add items tab and thus the index changes.
   */
  const tabIndexOfAddItemsTab = hasSections ? 2 : 1

  return (
    <HeaderMidContainer>
      <StyledTabs>
        {buttons.map(({ value, text, icon }, index) => {
          const isTabDisabled =
            value === 'addItems' && isEditDisabledForSectionsAdaptiveTest
          const tabDisabledTitle = isEditDisabledForSectionsAdaptiveTest
            ? i18translate('regradeNotSupportedForSectionsAdaptiveTest.info')
            : ''
          return (
            <HeaderTabs
              style={
                !showPublishButton && owner && index <= tabIndexOfAddItemsTab
                  ? { cursor: 'not-allowed' }
                  : {}
              }
              dataCy={value}
              isActive={current === value}
              icon={icon}
              linkLabel={text}
              key={value}
              onClickHandler={
                !showPublishButton && owner && index <= tabIndexOfAddItemsTab
                  ? ''
                  : onChange(value)
              }
              disabled={isTabDisabled}
              tabDisabledTitle={tabDisabledTitle}
            />
          )
        })}
      </StyledTabs>
    </HeaderMidContainer>
  )
}

TestPageNav.propTypes = {
  onChange: PropTypes.func.isRequired,
  current: PropTypes.string.isRequired,
  owner: PropTypes.bool.isRequired,
  showPublishButton: PropTypes.bool.isRequired,
  buttons: PropTypes.array.isRequired,
}

export default memo(TestPageNav)
