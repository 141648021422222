import React from 'react'
import Modal from 'antd/lib/modal'
import styled from 'styled-components'
import Typography from 'antd/lib/typography'
import Divider from 'antd/lib/divider'
import ImportInProgress from './ImportInProgress'
import ImportDone from './ImportDone'
import { UPLOAD_STATUS } from '../ducks'

const getTitle = (status) => {
  switch (status) {
    case 'STANDBY':
      return 'Importing File'
    case 'INITIATE':
      return 'Import In Progress'
    case 'INITIATED':
      return 'Loading File'
    case 'SUCCESS':
    case 'DONE':
      return 'Import Summary'
    case 'FAILED':
    default:
      return 'Import Failed'
  }
}

function ImportSummary({ status, visible, intervalRef }) {
  return (
    <Modal
      visible={visible}
      centered
      maskClosable={false}
      closable={false}
      footer={null}
      destroyOnClose
      width="540px"
    >
      <>
        <HeadingWrapper strong>{getTitle(status)}</HeadingWrapper>
        <Divider type="horizontal" />
        {status === UPLOAD_STATUS.INITIATE ? (
          <ImportInProgress intervalRef={intervalRef} />
        ) : (
          <ImportDone />
        )}
      </>
    </Modal>
  )
}

export const HeadingWrapper = styled(Typography.Paragraph)`
  font-size: 16px;
  color: black;
`

export default ImportSummary
