export const ALPHABET = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
]
export const APP_NAME = 'Edulastic'
export const QUESTION_OPTIONS = 'QUESTION_OPTIONS'
export const QUESTION_PROBLEM = 'QUESTION_PROBLEM'
export const QUESTION_ANSWERS = 'QUESTION_ANSWERS'
export const QUESTION_USERSELECTIONS = 'QUESTION_USERSELECTIONS'
export const LARGE_DESKTOP_WIDTH = 1600
export const MEDIUM_DESKTOP_WIDTH = 1300
export const IPAD_LANDSCAPE_WIDTH = 1024
export const SMALL_DESKTOP_WIDTH = 993
export const IPAD_PORTRAIT_WIDTH = 768
export const MAX_MOBILE_WIDTH = 468
export const headerOffsetHashMap = {
  xs: 70,
  sm: 120,
  md: 135,
  lg: 150,
  xl: 200,
}
