import { tabletWidth, white, themeColor } from '@edulastic/colors'
import {
  MainHeader,
  EduButton,
  notification,
  EduIf,
  EduElse,
  EduThen,
} from '@edulastic/common'
import { roleuser, test as testConstants } from '@edulastic/constants'
import {
  IconAddItems,
  IconCopy,
  IconDescription,
  IconDiskette,
  IconPencilEdit,
  IconPrint,
  IconReview,
  IconTick,
  IconSend,
  IconSettings,
  IconShare,
  IconTestBank,
  IconTrashAlt,
  IconItemGroup,
} from '@edulastic/icons'
import PropTypes from 'prop-types'
import React, { memo, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { Modal } from 'antd'
import { AUDIO_RESPONSE } from '@edulastic/constants/const/questionType'

import { ITEM_GROUP_TYPES } from '@edulastic/constants/const/test'
import { segmentApi } from '@edulastic/api'
import {
  getUserFeatures,
  getUserId,
  getUserRole,
  toggleAdminAlertModalAction,
  toggleVerifyEmailModalAction,
  getEmailVerified,
  getVerificationTS,
  isDefaultDASelector,
  isDemoPlaygroundUser,
} from '../../../../student/Login/ducks'
import ConfirmCancelTestEditModal from '../../../src/components/common/ConfirmCancelTestEditModal'
import FilterToggleBtn from '../../../src/components/common/FilterToggleBtn'
import { getStatus } from '../../../src/utils/getStatus'
import {
  publishForRegradeAction,
  getTestsCreatingSelector,
  shouldDisableSelector,
  getTestItemsSelector,
  getShowRegradeConfirmPopupSelector,
  setShowRegradeConfirmPopupAction,
  getRegradeFirebaseDocIdSelector,
  getShowUpgradePopupSelector,
  getQuestionTypesInTestSelector,
  getIsAudioResponseQuestionEnabled,
  isDynamicTestSelector,
  hasSectionsSelector,
  setTestDataAction,
} from '../../ducks'
import { fetchAssignmentsAction, getAssignmentsSelector } from '../Assign/ducks'
import TestPageNav from '../TestPageNav/TestPageNav'
import {
  MobileHeaderFilterIcon,
  RightFlexContainer,
  RightWrapper,
  ShareIcon,
  TestStatus,
} from './styled'
import PrintTestModal from '../../../src/components/common/PrintTestModal'
import {
  getIsCurator,
  isFreeAdminSelector,
  isGcpsDistrictSelector,
  isSAWithoutSchoolsSelector,
  isUserDistrictSuperAdminSelector,
} from '../../../src/selectors/user'
import { validateQuestionsForDocBased } from '../../../../common/utils/helpers'
import AuthorCompleteSignupButton from '../../../../common/components/AuthorCompleteSignupButton'
import RegradeNotificationListener from '../../../Regrade/RegradeNotificationListener'
import ConfirmRegradeModal from '../../../Regrade/ConfirmRegradeModal'
import Upgrade from '../../../Regrade/Upgrade'
import { DeleteItemModal } from '../../../TestList/components/DeleteItemModal/deleteItemModal'
import { LARGE_DESKTOP_WIDTH } from '../../../../assessment/constants/others'
import { deletePlaylistRequestAction } from '../../../CurriculumSequence/ducks'
import { hasUnsavedAiItems } from '../../../../assessment/utils/helpers'
import {
  DEFAULT_PLAYLIST_TITLE,
  DEFAULT_TEST_TITLE,
  hidePrintNonSuperAdminGcpsUsers,
} from '../../utils'
import { validateTestForExpiredItems } from '../../../utils/tests'
import { isContentVisibilityRestrictedForCurrentUser } from '../../../questionUtils'
import { TooltipStyled } from '../../../Dashboard/components/Showcase/components/Myclasses/components/CardAssignmentContent/styled'
import {
  TooltipTitleContainer,
  whiteTooltipProps,
} from '../../../TestList/components/ViewModal/styled'
import {
  testStatusConstants,
  tooltipText,
  contentSharing,
} from '../../../TestList/components/ViewModal/utils'

/**
 *
 * @param {string} id
 * @param {string} title
 * @param {Function} deletePlaylist
 */
function handleConfirmForDeletePlaylist(id, title, deletePlaylist) {
  Modal.confirm({
    title: 'Do you want to delete ?',
    content: `Are you sure you want to Delete the Playlist "${title}"?`,
    onOk: () => {
      deletePlaylist(id)
      Modal.destroyAll()
    },
    okText: 'Continue',
    centered: true,
    width: 500,
    okButtonProps: {
      style: { background: themeColor, outline: 'none' },
    },
  })
}

const {
  statusConstants,
  testContentVisibility: testContentVisibilityOptions,
} = testConstants

export const navButtonsTest = [
  {
    icon: <IconDescription color={white} width={16} height={16} />,
    value: 'description',
    text: 'Description',
  },
  {
    icon: <IconAddItems color={white} width={16} height={16} />,
    value: 'addItems',
    text: 'Add Items',
  },
  {
    icon: <IconTick color={white} width={16} height={16} />,
    value: 'review',
    text: 'Review',
  },
  {
    icon: <IconSettings color={white} width={16} height={16} />,
    value: 'settings',
    text: 'Settings',
  },
]
export const playlistNavButtons = [
  {
    icon: <IconDescription color={white} width={16} height={16} />,
    value: 'summary',
    text: 'Summary',
  },
  {
    icon: <IconReview color={white} width={24} height={24} />,
    value: 'review',
    text: 'Manage Content',
  },
  {
    icon: <IconSettings color={white} width={16} height={16} />,
    value: 'settings',
    text: 'Settings',
  },
]
export const docBasedButtons = [
  {
    icon: <IconDescription color={white} width={16} height={16} />,
    value: 'description',
    text: 'Description',
  },
  {
    icon: <IconAddItems color={white} width={16} height={16} />,
    value: 'worksheet',
    text: 'Worksheet',
  },
  {
    icon: <IconReview color={white} width={16} height={16} />,
    value: 'review',
    text: 'Review',
  },
  {
    icon: <IconSettings color={white} width={16} height={16} />,
    value: 'settings',
    text: 'Settings',
  },
]
export const navButtonsDynamicTest = [
  {
    icon: <IconDescription color={white} width={16} height={16} />,
    value: 'description',
    text: 'Description',
  },
  {
    icon: <IconItemGroup color={white} width={16} height={16} />,
    value: 'manageSections',
    text: 'Manage Sections',
  },
  {
    icon: <IconTick color={white} width={16} height={16} />,
    value: 'review',
    text: 'Review',
  },
  {
    icon: <IconSettings color={white} width={16} height={16} />,
    value: 'settings',
    text: 'Settings',
  },
]
export const navButtonsSectionsTest = [
  {
    icon: <IconDescription color={white} width={16} height={16} />,
    value: 'description',
    text: 'Description',
  },
  {
    icon: <IconItemGroup color={white} width={16} height={16} />,
    value: 'manageSections',
    text: 'Manage Sections',
  },
  {
    icon: <IconAddItems color={white} width={16} height={16} />,
    value: 'addItems',
    text: 'Add Items',
  },
  {
    icon: <IconTick color={white} width={16} height={16} />,
    value: 'review',
    text: 'Review',
  },
  {
    icon: <IconSettings color={white} width={16} height={16} />,
    value: 'settings',
    text: 'Settings',
  },
]

const CLONE_ACCESS_LEVELS = [contentSharing.EDIT, contentSharing.VIEW]

const demoActionUnavailable = 'This feature is not available in demo account.'

// TODO mobile look
const TestPageHeader = ({
  onChangeNav,
  current,
  onSave,
  buttons,
  isDocBased = false,
  title,
  onShare,
  isUsed = false,
  onPublish,
  showEditButton = false,
  editEnable = false,
  windowWidth,
  onEnableEdit,
  showPublishButton,
  hasTestId,
  testStatus,
  isPlaylist,
  owner,
  onAssign,
  history,
  publishForRegrade,
  test,
  updated,
  toggleFilter,
  isShowFilter,
  fetchAssignments,
  testAssignments,
  match,
  showDuplicateButton,
  handleDuplicateTest,
  showCancelButton,
  features,
  userId,
  onCuratorApproveOrReject,
  userRole,
  creating,
  isLoadingData,
  playlistStatus,
  testItems,
  isTestLoading,
  validateTest,
  setDisableAlert,
  playlistHasDraftTests,
  isCurator,
  hasCollectionAccess,
  authorQuestionsById,
  isUpdatingTestForRegrade,
  isFreeAdmin,
  isSAWithoutSchools,
  toggleAdminAlertModal,
  emailVerified,
  verificationTS,
  isDefaultDA,
  toggleVerifyEmailModal,
  showRegradeConfirmPopup,
  setShowRegradeConfirmPopup,
  regradeFirebaseDocId,
  showUpgradePopup,
  isDemoPlayground = false,
  deletePlaylist,
  questionTypesInTest,
  enableAudioResponseQuestion,
  derivedFromPremiumBankId = false,
  isDynamicTest,
  hasSections,
  setData,
  isEditable = false,
  isEditDisabledForSectionsAdaptiveTest = false,
  i18translate = () => {},
  enableEditButton,
  allowDuplicate,
  isGcpsDistrict,
  isDistrictSuperAdmin,
}) => {
  let navButtons =
    buttons ||
    (isPlaylist
      ? [...playlistNavButtons]
      : isDocBased
      ? [...docBasedButtons]
      : // In order to display the Add Sections tab instead of Add Items for dynamic or a test with sections
      isDynamicTest
      ? [...navButtonsDynamicTest]
      : hasSections
      ? [...navButtonsSectionsTest]
      : [...navButtonsTest])
  const [showCancelPopup, setShowCancelPopup] = useState(false)
  const [showPrintOptionPopup, setShowPrintOptionPopup] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const isPublishers = !!(features.isCurator || features.isPublisherAuthor)
  const isEdulasticCurator = userRole === roleuser.EDULASTIC_CURATOR
  const playlistId = match?.params?.id

  useEffect(() => {
    // TODO: As this component used also in playlist page, please call below api conditionally if no purpose of calling assignments list.
    if (!creating && match?.params?.oldId) {
      fetchAssignments({
        testId: match?.params?.oldId,
        regradeAssignments: true,
      })
    } else if (!creating && test?._id) {
      const testId =
        (test.status === 'draft' && test.isUsed) || showUpgradePopup
          ? test.previousTestId
          : test._id
      fetchAssignments({ testId, regradeAssignments: true })
    }
  }, [test?._id, match?.params?.oldId])

  const onRegradeConfirm = () => {
    if (!validateTestForExpiredItems(test)) {
      return
    }
    if (validateTest(test)) {
      if (test.isDocBased) {
        const assessmentQuestions = Object.values(authorQuestionsById || {})
        if (
          !validateQuestionsForDocBased(
            assessmentQuestions,
            false,
            !!test.videoUrl
          )
        ) {
          return
        }
      }
      publishForRegrade(test._id)
    }
  }

  const onCancelRegrade = () => {
    setShowRegradeConfirmPopup(false)
    notification({
      type: 'warn',
      msg:
        'Assignment Regrade has been cancelled and changes have not been published.',
    })
  }

  const handlePublish = () => {
    if (isPlaylist && playlistHasDraftTests()) {
      Modal.confirm({
        title: 'Do you want to Publish ?',
        content:
          'Playlist has some draft Test. Publishing the playlist will not display the draft test to users until they are published.',
        onOk: () => {
          onPublish()
          Modal.destroyAll()
        },
        okText: 'Yes, Publish',
        centered: true,
        width: 500,
        okButtonProps: {
          style: { background: themeColor, outline: 'none' },
        },
      })
      return
    }
    if (
      isUsed &&
      (updated || test.status !== statusConstants.PUBLISHED) &&
      testAssignments?.length > 0
    ) {
      onRegradeConfirm()
      return
    }

    onPublish()
  }

  const isNotRegradable = () => {
    const isTeacher = userRole === roleuser.TEACHER

    return isTeacher && isDynamicTest
  }

  const handleRegrade = () => {
    if (isNotRegradable()) {
      // For time being block teacher regrading a authors test is blocked here
      notification({ type: 'warn', messageKey: 'teacherCantRegrade' })
      return onPublish()
    }
    setDisableAlert(true)
    onRegradeConfirm()
    return true
  }

  const handleAssign = () => {
    if (isFreeAdmin || isSAWithoutSchools) return toggleAdminAlertModal()
    if (!emailVerified && verificationTS && !isDefaultDA) {
      const existingVerificationTS = new Date(verificationTS)
      const expiryDate = new Date(
        existingVerificationTS.setDate(existingVerificationTS.getDate() + 14)
      ).getTime()
      if (expiryDate < Date.now()) {
        history.push(userRole === 'teacher' ? '/' : '/author/items')
        return toggleVerifyEmailModal(true)
      }
    }
    const containsAudioResponseTypeQuestion = questionTypesInTest.includes(
      AUDIO_RESPONSE
    )
    const audioResponseQuestionDisabledByDA = !enableAudioResponseQuestion
    const cannotAssignAudioResponseQuestion = [
      containsAudioResponseTypeQuestion,
      audioResponseQuestionDisabledByDA,
    ].every((o) => !!o)

    if (!validateTestForExpiredItems(test)) {
      return
    }
    if (cannotAssignAudioResponseQuestion) {
      notification({ messageKey: 'testContainsAudioResponseTypeQuestion' })
      return
    }
    onAssign()
  }

  const setCancelState = (val) => {
    setShowCancelPopup(val)
  }

  const handleCancelEdit = () => {
    setCancelState(false)
  }

  const confirmCancel = () => {
    if (history.location.state?.editTestFlow) {
      return history.push('/author/tests')
    }
    history.push('/author/assignments')
  }

  if (!owner) {
    navButtons = navButtons.slice(2)
  }

  let isDirectOwner = owner
  const { authors } = test
  if (features.isCurator && authors && !authors.find((o) => o._id === userId)) {
    isDirectOwner = false
  }

  const onClickCuratorApprove = () => {
    const { collections = [], _id: testId } = test
    onCuratorApproveOrReject({ testId, status: 'published', collections })
  }

  const onClickCuratorReject = () => {
    const { _id: testId } = test
    onCuratorApproveOrReject({ testId, status: 'rejected' })
  }

  const handlePrintTest = () => {
    const isContentVisibilityRestricted = isContentVisibilityRestrictedForCurrentUser(
      {
        isTestContentRestrictedBySuperAdmin:
          test?.isTestContentRestrictedBySuperAdmin,
        userRole,
        isDistrictSuperAdmin,
        testType: test?.testType,
      }
    )
    if (
      isContentVisibilityRestricted &&
      (test?.testContentVisibility === testContentVisibilityOptions.HIDDEN ||
        test?.testContentVisibility === testContentVisibilityOptions.GRADING)
    ) {
      return notification({
        type: 'warn',
        messageKey: 'viewOfItemsRestricted',
      })
    }
    setShowPrintOptionPopup(true)
  }

  const handleOnClickPrintCancel = () => setShowPrintOptionPopup(false)

  const handleOnClickPrintConfirm = (params) => {
    segmentApi.genericEventTrack('TestPrint', {
      origin: 'test_detail_tabs',
      trigger: 'header',
      role: userRole,
    })
    const { type, customValue, showAnswers, preferredLanguage } = params
    handleOnClickPrintCancel()
    window.open(
      `/author/printAssessment/${test?._id}?type=${type}&qs=${customValue}&showAnswers=${showAnswers}&preferredLanguage=${preferredLanguage}`,
      '_blank'
    )
  }

  const onDelete = (id, _title, _deletePlaylist) => {
    if (isPlaylist) {
      handleConfirmForDeletePlaylist(id, _title, _deletePlaylist)
    } else {
      setIsDeleteModalOpen(true)
    }
  }

  const onDeleteModelCancel = () => {
    setIsDeleteModalOpen(false)
  }

  const isFetchingAutoselectItems = test.itemGroups.some(
    ({ type, items }) => type === ITEM_GROUP_TYPES.AUTOSELECT && !items.length
  )

  const headingSubContent = (
    <TestStatus
      data-cy="status"
      className={
        (isPlaylist || editEnable) && !isEdulasticCurator && !isCurator
          ? 'draft'
          : isPlaylist
          ? playlistStatus
          : testStatus
      }
    >
      {(isPlaylist || editEnable) && !isEdulasticCurator && !isCurator
        ? 'DRAFT'
        : getStatus(isPlaylist ? playlistStatus : testStatus)}
    </TestStatus>
  )

  const isRegradeFlow =
    test.isUsed &&
    !!testAssignments.length &&
    !isEdulasticCurator &&
    !isCurator &&
    (testStatus === testStatusConstants.DRAFT || editEnable)
  // if edit assigned there should be assignments to enable the buttons
  const disableButtons =
    isLoadingData ||
    (history.location.state?.editAssigned &&
      !testAssignments.length &&
      !test.isInEditAndRegrade) ||
    isFetchingAutoselectItems

  const isTestContainsDraftItem = testItems.some(
    (i) => i.status === statusConstants.DRAFT
  )

  const showPublishForEC =
    test.status === statusConstants.PUBLISHED &&
    isTestContainsDraftItem &&
    (isEdulasticCurator || isCurator) &&
    !isPlaylist

  const headerTitleWidth =
    windowWidth >= LARGE_DESKTOP_WIDTH
      ? '450px'
      : isPlaylist
      ? '290px'
      : '250px'
  const _hasUnsavedAiItems = hasUnsavedAiItems(test?.itemGroups)
  const hidePrint = hidePrintNonSuperAdminGcpsUsers(
    test?.testContentVisibility,
    isGcpsDistrict,
    isDistrictSuperAdmin
  )
  const hasDuplicatePermission = CLONE_ACCESS_LEVELS.includes(test.accessLevel)
  const DisableTooltip = ({ action }) => (
    <TooltipTitleContainer>
      {tooltipText.noActionAndPermissionInfo(action, test.accessLevel)}
    </TooltipTitleContainer>
  )

  return (
    <>
      <Upgrade />
      <ConfirmRegradeModal
        visible={showRegradeConfirmPopup}
        onCancel={onCancelRegrade}
      />
      <ConfirmCancelTestEditModal
        showCancelPopup={showCancelPopup}
        onCancel={handleCancelEdit}
        onOk={confirmCancel}
        onClose={() => setCancelState(false)}
      />
      {isDeleteModalOpen ? (
        <DeleteItemModal
          isVisible={isDeleteModalOpen}
          onCancel={onDeleteModelCancel}
          testId={test._id}
          test={test}
          view="testReview"
        />
      ) : null}
      {showPrintOptionPopup && (
        <PrintTestModal
          onProceed={handleOnClickPrintConfirm}
          onCancel={handleOnClickPrintCancel}
          currentTestId={test?._id}
          showAnswerCheckbox
        />
      )}
      {windowWidth >= parseInt(tabletWidth, 10) ? (
        <MainHeader
          headingText={
            isEditable
              ? title
              : title ||
                (isPlaylist ? DEFAULT_PLAYLIST_TITLE : DEFAULT_TEST_TITLE)
          }
          onTitleChange={(value) => setData({ ...test, title: value })}
          Icon={IconTestBank}
          headingSubContent={headingSubContent}
          titleMarginTop="10px"
          flexDirection="row"
          alignItems="center"
          titleMaxWidth={headerTitleWidth}
          headerLeftClassName="headerLeftWrapper"
          containerClassName="tabAlignment"
          hasTestId={hasTestId}
          hasSections={hasSections}
          isEditable={isEditable}
        >
          <TestPageNav
            onChange={onChangeNav}
            current={current}
            buttons={navButtons}
            owner={owner}
            showPublishButton={!hasTestId || showPublishButton}
            isEditDisabledForSectionsAdaptiveTest={
              isEditDisabledForSectionsAdaptiveTest
            }
            i18translate={i18translate}
            hasSections={hasSections}
          />

          <RightFlexContainer
            childMarginRight="5"
            justifyContent="flex-end"
            mt="12px"
            width={isPlaylist ? '100%' : 'auto'}
          >
            {hasTestId &&
              !isPlaylist &&
              !isDocBased &&
              !test?.isDocBased &&
              !hidePrint && (
                <TooltipStyled {...whiteTooltipProps} title="Print">
                  <span>
                    <EduButton
                      isBlue
                      isGhost
                      IconBtn
                      data-cy="printTest"
                      aria-label="Print"
                      disabled={isTestLoading}
                      onClick={handlePrintTest}
                    >
                      <IconPrint />
                    </EduButton>
                  </span>
                </TooltipStyled>
              )}
            <EduIf
              condition={
                hasTestId && !isEdulasticCurator && !derivedFromPremiumBankId
              }
            >
              <TooltipStyled
                {...whiteTooltipProps}
                title={
                  isDemoPlayground ? (
                    demoActionUnavailable
                  ) : !(owner || features.isCurator) ? (
                    <DisableTooltip action="Share" />
                  ) : (
                    'Share'
                  )
                }
              >
                <span>
                  <EduButton
                    isBlue
                    isGhost
                    IconBtn
                    data-cy="share"
                    aria-label="Share"
                    onClick={onShare}
                    disabled={
                      disableButtons ||
                      isDemoPlayground ||
                      !(owner || features.isCurator)
                    }
                  >
                    <IconShare />
                  </EduButton>
                </span>
              </TooltipStyled>
            </EduIf>
            {hasTestId &&
              ((isPlaylist && owner) || !isPlaylist) &&
              test.status === testStatusConstants.DRAFT && (
                <TooltipStyled
                  {...whiteTooltipProps}
                  title={
                    isDemoPlayground ? (
                      demoActionUnavailable
                    ) : !owner ? (
                      <DisableTooltip action="Delete" />
                    ) : (
                      'Delete'
                    )
                  }
                >
                  <span>
                    <EduButton
                      isBlue
                      isGhost
                      IconBtn
                      data-cy="delete-test"
                      aria-label="Delete"
                      onClick={() =>
                        onDelete(playlistId, title, deletePlaylist)
                      }
                      disabled={disableButtons || isDemoPlayground || !owner}
                    >
                      <IconTrashAlt />
                    </EduButton>
                  </span>
                </TooltipStyled>
              )}

            <EduIf
              condition={
                _hasUnsavedAiItems ||
                (hasTestId && owner && showPublishButton && !showPublishForEC)
              }
            >
              <EduIf condition={_hasUnsavedAiItems && !hasTestId}>
                <EduThen>
                  <TooltipStyled {...whiteTooltipProps} title="Save as Draft">
                    <span>
                      <EduButton
                        isBlue
                        data-cy="save"
                        aria-label="Save"
                        onClick={() =>
                          onSave(undefined, undefined, undefined, true)
                        }
                        disabled={disableButtons}
                      >
                        Save
                      </EduButton>
                    </span>
                  </TooltipStyled>
                </EduThen>
                <EduElse>
                  <TooltipStyled {...whiteTooltipProps} title="Save as Draft">
                    <span>
                      <EduButton
                        isBlue
                        isGhost
                        IconBtn
                        data-cy="save"
                        aria-label="Save"
                        onClick={onSave}
                        disabled={disableButtons}
                      >
                        <IconDiskette />
                      </EduButton>
                    </span>
                  </TooltipStyled>
                </EduElse>
              </EduIf>
            </EduIf>

            {hasTestId &&
            owner &&
            showPublishButton &&
            !isEdulasticCurator &&
            isDirectOwner ? (
              isPlaylist ? (
                <TooltipStyled {...whiteTooltipProps} title="Publish Playlist">
                  <span>
                    <EduButton
                      isBlue
                      data-cy="publish"
                      aria-label="Publish"
                      onClick={handlePublish}
                      disabled={disableButtons}
                    >
                      PUBLISH
                    </EduButton>
                  </span>
                </TooltipStyled>
              ) : (
                !editEnable &&
                !isRegradeFlow && (
                  <TooltipStyled
                    {...whiteTooltipProps}
                    title="Publish and Assign later"
                  >
                    <span>
                      <EduButton
                        isBlue
                        isGhost
                        IconBtn={!isPublishers}
                        aria-label="Publish"
                        data-cy="publish"
                        onClick={handlePublish}
                        disabled={disableButtons}
                      >
                        <IconSend />
                        {isPublishers && 'Publish'}
                      </EduButton>
                    </span>
                  </TooltipStyled>
                )
              )
            ) : null}
            {features.isCurator &&
              testStatus === testStatusConstants.INREVIEW &&
              hasCollectionAccess && (
                <TooltipStyled
                  {...whiteTooltipProps}
                  title={isPlaylist ? 'Reject Playlist' : 'Reject Test'}
                >
                  <span>
                    <EduButton
                      isBlue
                      data-cy="publish"
                      aria-label="Reject"
                      onClick={onClickCuratorReject}
                      disabled={disableButtons}
                    >
                      REJECT
                    </EduButton>
                  </span>
                </TooltipStyled>
              )}

            {features.isCurator &&
              (testStatus === testStatusConstants.INREVIEW ||
                testStatus === testStatusConstants.REJECTED) &&
              hasCollectionAccess && (
                <TooltipStyled
                  {...whiteTooltipProps}
                  title={isPlaylist ? 'Approve Playlist' : 'Approve Test'}
                >
                  <span>
                    <EduButton
                      isBlue
                      data-cy="approve"
                      aria-label="Approve"
                      onClick={onClickCuratorApprove}
                      disabled={disableButtons}
                    >
                      APPROVE
                    </EduButton>
                  </span>
                </TooltipStyled>
              )}
            {showEditButton && (
              <TooltipStyled
                {...whiteTooltipProps}
                title={
                  !enableEditButton ? (
                    <DisableTooltip action="Edit" />
                  ) : (
                    'Edit Test'
                  )
                }
              >
                <span>
                  <EduButton
                    isBlue
                    isGhost
                    IconBtn
                    disabled={editEnable || disableButtons || !enableEditButton}
                    data-cy="edit-test"
                    aria-label="Edit"
                    onClick={() => {
                      onEnableEdit(false, true)
                    }}
                  >
                    <IconPencilEdit />
                  </EduButton>
                </span>
              </TooltipStyled>
            )}
            {showDuplicateButton && !isEdulasticCurator && (
              <TooltipStyled
                {...whiteTooltipProps}
                title={
                  isDemoPlayground ? (
                    demoActionUnavailable
                  ) : !(allowDuplicate && hasDuplicatePermission) ? (
                    <DisableTooltip action="Clone" />
                  ) : (
                    'Clone Test'
                  )
                }
              >
                <span>
                  <EduButton
                    isBlue
                    isGhost
                    IconBtn
                    disabled={
                      editEnable ||
                      disableButtons ||
                      isDemoPlayground ||
                      !(allowDuplicate && hasDuplicatePermission)
                    }
                    data-cy="duplicate"
                    aria-label="Clone"
                    onClick={() => {
                      segmentApi.genericEventTrack('TestClone', {
                        origin: 'test_detail_tabs',
                        trigger: 'header',
                        role: userRole,
                      })
                      handleDuplicateTest()
                    }}
                  >
                    <IconCopy />
                  </EduButton>
                </span>
              </TooltipStyled>
            )}
            {showCancelButton && (
              <EduButton
                isBlue
                data-cy="assign"
                aria-label="Cancel"
                onClick={() => setCancelState(true)}
              >
                CANCEL
              </EduButton>
            )}
            {hasTestId &&
              owner &&
              ((showPublishButton && !isEdulasticCurator) ||
                showPublishForEC) &&
              !isPlaylist &&
              editEnable &&
              ((isCurator && testStatus !== statusConstants.PUBLISHED) ||
                !isCurator ||
                showPublishForEC) &&
              !isRegradeFlow && (
                <TooltipStyled {...whiteTooltipProps} title="Publish Test">
                  <span>
                    <EduButton
                      isBlue
                      data-cy="publish"
                      aria-label="Publish"
                      onClick={handlePublish}
                      disabled={disableButtons}
                    >
                      PUBLISH
                    </EduButton>
                  </span>
                </TooltipStyled>
              )}
            {!showUpgradePopup &&
              hasTestId &&
              !isPlaylist &&
              !showCancelButton &&
              !isPublishers &&
              !isEdulasticCurator && (
                <AuthorCompleteSignupButton
                  renderButton={(handleClick) => (
                    <TooltipStyled
                      {...whiteTooltipProps}
                      title={
                        test.accessLevel === 'NOACTION'
                          ? tooltipText.noActionAndPermissionInfo(
                              'Assign',
                              test.accessLevel
                            )
                          : !(owner || testStatus === statusConstants.PUBLISHED)
                          ? tooltipText.cannotAssignTest
                          : ''
                      }
                    >
                      <span>
                        <EduButton
                          isBlue
                          data-cy="assign"
                          disabled={
                            disableButtons ||
                            test.accessLevel === 'NOACTION' ||
                            !(owner || testStatus === statusConstants.PUBLISHED)
                          }
                          aria-label="Assign"
                          onClick={handleClick}
                        >
                          ASSIGN
                        </EduButton>
                      </span>
                    </TooltipStyled>
                  )}
                  onClick={handleAssign}
                />
              )}
            {isRegradeFlow &&
              !(showEditButton && enableEditButton) &&
              !(showDuplicateButton && allowDuplicate) &&
              !isPlaylist && (
                <TooltipStyled {...whiteTooltipProps} title="Publish">
                  <span>
                    <EduButton
                      isBlue
                      data-cy="publish"
                      aria-label="Publish"
                      onClick={handleRegrade}
                      disabled={disableButtons || isUpdatingTestForRegrade}
                    >
                      PUBLISH
                    </EduButton>
                  </span>
                </TooltipStyled>
              )}
          </RightFlexContainer>
        </MainHeader>
      ) : (
        <MainHeader
          headingText={title}
          mobileHeaderHeight={120}
          justifyContent="flex-start"
          hasSections={hasSections}
        >
          <RightWrapper>
            {current === 'addItems' && (
              <MobileHeaderFilterIcon>
                <FilterToggleBtn
                  header="true"
                  isShowFilter={isShowFilter}
                  toggleFilter={toggleFilter}
                />
              </MobileHeaderFilterIcon>
            )}
            <EduIf condition={!isEdulasticCurator && !derivedFromPremiumBankId}>
              <TooltipStyled
                {...whiteTooltipProps}
                title={
                  isDemoPlayground ? (
                    demoActionUnavailable
                  ) : !(owner || features.isCurator) ? (
                    <DisableTooltip action="Share" />
                  ) : (
                    'Share'
                  )
                }
              >
                <span>
                  <EduButton
                    isBlue
                    isGhost
                    IconBtn
                    data-cy="share"
                    aria-label="Share"
                    disabled={
                      disableButtons ||
                      isDemoPlayground ||
                      !(owner || features.isCurator)
                    }
                    onClick={onShare}
                  >
                    <ShareIcon />
                  </EduButton>
                </span>
              </TooltipStyled>
            </EduIf>
            {hasTestId &&
              ((isPlaylist && owner) || !isPlaylist) &&
              test.status === testStatusConstants.DRAFT && (
                <TooltipStyled
                  {...whiteTooltipProps}
                  title={
                    isDemoPlayground ? (
                      demoActionUnavailable
                    ) : !owner ? (
                      <DisableTooltip action="Delete" />
                    ) : (
                      'Delete'
                    )
                  }
                >
                  <span>
                    <EduButton
                      isBlue
                      isGhost
                      IconBtn
                      data-cy="delete-test"
                      aria-label="Delete"
                      onClick={onDelete}
                      disabled={disableButtons || isDemoPlayground || !owner}
                    >
                      <IconTrashAlt />
                    </EduButton>
                  </span>
                </TooltipStyled>
              )}

            <EduIf
              condition={_hasUnsavedAiItems || (owner && !showPublishForEC)}
            >
              <TooltipStyled {...whiteTooltipProps} title="Save as Draft">
                <span>
                  <EduButton
                    isBlue
                    isGhost
                    IconBtn
                    data-cy="save"
                    aria-label="Save"
                    onClick={onSave}
                    disabled={disableButtons}
                  >
                    <IconDiskette />
                  </EduButton>
                </span>
              </TooltipStyled>
            </EduIf>
            {hasTestId &&
            owner &&
            showPublishButton &&
            !isEdulasticCurator &&
            isDirectOwner ? (
              isPlaylist ? (
                <TooltipStyled {...whiteTooltipProps} title="Publish Playlist">
                  <span>
                    <EduButton
                      isBlue
                      data-cy="publish"
                      aria-label="Publish"
                      onClick={handlePublish}
                      disabled={disableButtons}
                    >
                      PUBLISH
                    </EduButton>
                  </span>
                </TooltipStyled>
              ) : (
                <TooltipStyled {...whiteTooltipProps} title="Publish Test">
                  <span>
                    <EduButton
                      isBlue
                      isGhost
                      IconBtn
                      data-cy="publish"
                      aria-label="Publish"
                      onClick={handlePublish}
                      disabled={disableButtons}
                    >
                      <IconSend />
                    </EduButton>
                  </span>
                </TooltipStyled>
              )
            ) : null}
            {features.isCurator && testStatus === testStatusConstants.INREVIEW && (
              <TooltipStyled
                {...whiteTooltipProps}
                title={isPlaylist ? 'Reject Playlist' : 'Reject Test'}
              >
                <span>
                  <EduButton
                    isBlue
                    data-cy="publish"
                    aria-label="Reject"
                    onClick={onClickCuratorReject}
                    disabled={disableButtons}
                  >
                    REJECT
                  </EduButton>
                </span>
              </TooltipStyled>
            )}
            {features.isCurator &&
              (testStatus === testStatusConstants.INREVIEW ||
                testStatus === testStatusConstants.REJECTED) && (
                <TooltipStyled
                  {...whiteTooltipProps}
                  title={isPlaylist ? 'Approve Playlist' : 'Approve Test'}
                >
                  <span>
                    <EduButton
                      isBlue
                      data-cy="approve"
                      aria-label="Approve"
                      onClick={onClickCuratorApprove}
                      disabled={disableButtons}
                    >
                      APPROVE
                    </EduButton>
                  </span>
                </TooltipStyled>
              )}
            {!showUpgradePopup &&
              hasTestId &&
              !isPlaylist &&
              !showCancelButton &&
              !isPublishers &&
              !isEdulasticCurator && (
                <AuthorCompleteSignupButton
                  renderButton={(handleClick) => (
                    <TooltipStyled
                      {...whiteTooltipProps}
                      title={
                        test.accessLevel === 'NOACTION'
                          ? tooltipText.noActionAndPermissionInfo(
                              'Assign',
                              test.accessLevel
                            )
                          : !(owner || testStatus === statusConstants.PUBLISHED)
                          ? tooltipText.cannotAssignTest
                          : ''
                      }
                    >
                      <span>
                        <EduButton
                          isBlue
                          disabled={
                            disableButtons ||
                            test.accessLevel === 'NOACTION' ||
                            !(owner || testStatus === statusConstants.PUBLISHED)
                          }
                          aria-label="Assign"
                          data-cy="assign"
                          onClick={handleClick}
                        >
                          ASSIGN
                        </EduButton>
                      </span>
                    </TooltipStyled>
                  )}
                  onClick={handleAssign}
                />
              )}
          </RightWrapper>
          <TestPageNav
            owner={owner}
            onChange={onChangeNav}
            current={current}
            buttons={navButtons}
          />
        </MainHeader>
      )}
      {regradeFirebaseDocId && <RegradeNotificationListener />}
    </>
  )
}

TestPageHeader.propTypes = {
  onChangeNav: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  current: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onShare: PropTypes.func.isRequired,
  onEnableEdit: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
  editEnable: PropTypes.bool.isRequired,
  onAssign: PropTypes.func.isRequired,
  setDisableAlert: PropTypes.func,
}

TestPageHeader.defaultProps = {
  setDisableAlert: () => {},
}

const enhance = compose(
  memo,
  withRouter,
  connect(
    (state) => ({
      test: state.tests.entity,
      testAssignments: getAssignmentsSelector(state),
      features: getUserFeatures(state),
      userId: getUserId(state),
      userRole: getUserRole(state),
      creating: getTestsCreatingSelector(state),
      isLoadingData: shouldDisableSelector(state),
      testItems: getTestItemsSelector(state),
      isCurator: getIsCurator(state),
      authorQuestionsById: state.authorQuestions.byId,
      isUpdatingTestForRegrade: state.tests.updatingTestForRegrade,
      emailVerified: getEmailVerified(state),
      verificationTS: getVerificationTS(state),
      isDefaultDA: isDefaultDASelector(state),
      isFreeAdmin: isFreeAdminSelector(state),
      isSAWithoutSchools: isSAWithoutSchoolsSelector(state),
      showRegradeConfirmPopup: getShowRegradeConfirmPopupSelector(state),
      regradeFirebaseDocId: getRegradeFirebaseDocIdSelector(state),
      showUpgradePopup: getShowUpgradePopupSelector(state),
      isDemoPlayground: isDemoPlaygroundUser(state),
      questionTypesInTest: getQuestionTypesInTestSelector(state),
      enableAudioResponseQuestion: getIsAudioResponseQuestionEnabled(state),
      isDynamicTest: isDynamicTestSelector(state),
      hasSections: hasSectionsSelector(state),
      isGcpsDistrict: isGcpsDistrictSelector(state),
      isDistrictSuperAdmin: isUserDistrictSuperAdminSelector(state),
    }),
    {
      publishForRegrade: publishForRegradeAction,
      setData: setTestDataAction,
      fetchAssignments: fetchAssignmentsAction,
      toggleAdminAlertModal: toggleAdminAlertModalAction,
      toggleVerifyEmailModal: toggleVerifyEmailModalAction,
      setShowRegradeConfirmPopup: setShowRegradeConfirmPopupAction,
      deletePlaylist: deletePlaylistRequestAction,
    }
  )
)
export default enhance(TestPageHeader)
