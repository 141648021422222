export const adaptiveTestTypes = {
  PSAT: 'PSAT',
}

export const adaptiveTestThresholdTypes = {
  PERCENTAGE: {
    label: 'Percentage',
    value: 'PERCENTAGE',
  },
  CORRECT_ITEM_COUNT: {
    label: 'Number Of Correct Items',
    value: 'CORRECT_ITEM_COUNT',
  },
}

export const adaptiveTestSettingsOptions = [
  {
    id: 1,
    label: 'Custom',
    value: 'custom',
  },
  {
    id: 2,
    label: 'PSAT 8/9 Math',
    value: 'psat_8_9_math',
  },
  {
    id: 3,
    label: 'PSAT 10 Math',
    value: 'psat_10_math',
  },
  {
    id: 4,
    label: 'PSAT/NMSQT Math',
    value: 'psat_math',
  },
  {
    id: 5,
    label: 'SAT Math',
    value: 'sat_math',
  },
  {
    id: 6,
    label: 'PSAT 8/9 Reading & Writing',
    value: 'psat_8_9_rw',
  },
  {
    id: 7,
    label: 'PSAT 10 Reading & Writing',
    value: 'psat_10_rw',
  },
  {
    id: 8,
    label: 'PSAT/NMSQT Reading & Writing',
    value: 'psat_rw',
  },
  {
    id: 9,
    label: 'SAT Reading & Writing',
    value: 'sat_rw',
  },
]

export const adaptiveTestSettings = {
  psat_8_9_math: {
    threshold: {
      PERCENTAGE: 65,
      CORRECT_ITEM_COUNT: 15,
      scaledScore: {
        1: {
          min: 60,
          max: 400,
        }, // easy section score
        2: {
          min: 60,
          max: 420,
        }, // hard section score
      },
    },
    itemsRequired: 22,
    timeLimit: 35 * 60 * 1000,
    scaledScore: {
      min: 60,
      max: 300,
    }, // base section score
  },
  psat_10_math: {
    threshold: {
      PERCENTAGE: 65,
      CORRECT_ITEM_COUNT: 15,
      scaledScore: {
        1: {
          min: 80,
          max: 380,
        }, // easy section score
        2: {
          min: 80,
          max: 420,
        }, // hard section score
      },
    },
    itemsRequired: 22,
    timeLimit: 35 * 60 * 1000,
    scaledScore: {
      min: 80,
      max: 340,
    }, // base section score
  },
  psat_math: {
    threshold: {
      PERCENTAGE: 65,
      CORRECT_ITEM_COUNT: 15,
      scaledScore: {
        1: {
          min: 80,
          max: 380,
        }, // easy section score
        2: {
          min: 80,
          max: 420,
        }, // hard section score
      },
    },
    itemsRequired: 22,
    timeLimit: 35 * 60 * 1000,
    scaledScore: {
      min: 80,
      max: 340,
    }, // base section score
  },
  sat_math: {
    threshold: {
      PERCENTAGE: 65,
      CORRECT_ITEM_COUNT: 15,
      scaledScore: {
        1: {
          min: 100,
          max: 360,
        }, // easy section score
        2: {
          min: 100,
          max: 440,
        }, // hard section score
      },
    },
    itemsRequired: 22,
    timeLimit: 35 * 60 * 1000,
    scaledScore: {
      min: 100,
      max: 360,
    }, // base section score
  },
  psat_8_9_rw: {
    threshold: {
      PERCENTAGE: 65,
      CORRECT_ITEM_COUNT: 18,
      scaledScore: {
        1: {
          min: 60,
          max: 400,
        }, // easy section score
        2: {
          min: 60,
          max: 420,
        }, // hard section score
      },
    },
    itemsRequired: 27,
    timeLimit: 32 * 60 * 1000,
    scaledScore: {
      min: 60,
      max: 300,
    }, // base section score
  },
  psat_10_rw: {
    threshold: {
      PERCENTAGE: 65,
      CORRECT_ITEM_COUNT: 18,
      scaledScore: {
        1: {
          min: 80,
          max: 380,
        }, // easy section score
        2: {
          min: 80,
          max: 420,
        }, // hard section score
      },
    },
    itemsRequired: 27,
    timeLimit: 32 * 60 * 1000,
    scaledScore: {
      min: 80,
      max: 340,
    }, // base section score
  },
  psat_rw: {
    threshold: {
      PERCENTAGE: 65,
      CORRECT_ITEM_COUNT: 18,
      scaledScore: {
        1: {
          min: 80,
          max: 380,
        }, // easy section score
        2: {
          min: 80,
          max: 420,
        }, // hard section score
      },
    },
    itemsRequired: 27,
    timeLimit: 32 * 60 * 1000,
    scaledScore: {
      min: 80,
      max: 340,
    }, // base section score
  },
  sat_rw: {
    threshold: {
      PERCENTAGE: 65,
      CORRECT_ITEM_COUNT: 18,
      scaledScore: {
        1: {
          min: 100,
          max: 360,
        }, // easy section score
        2: {
          min: 100,
          max: 440,
        }, // hard section score
      },
    },
    itemsRequired: 27,
    timeLimit: 32 * 60 * 1000,
    scaledScore: {
      min: 100,
      max: 360,
    }, // base section score
  },
}

export const sectionsAdaptiveTestGroupNames = {
  BASE: 'Section 1',
  EASY: 'Section 2 - EASY',
  HARD: 'Section 2 - HARD',
}

export const defaultSectionAdaptiveSettingType = 'psat_10_math'
export const customSectionAdaptiveSettingType = 'custom'
export const adaptiveTestDefaultThresholdValue = 50
export const baseSectionMaxItemsCount = 1
export const thresholdSectionMaxItemsCount = 1
