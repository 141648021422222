import { Collapse, Tooltip } from 'antd'
import {
  IconReloadCircle,
  IconSectionsCalculator,
  IconSmallCircle,
} from '@edulastic/icons'
import { isArray } from 'lodash'
import React, { useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { SortableContainer } from 'react-sortable-hoc'
import { darkGrey5, title, red, darkGrey } from '@edulastic/colors'
import { EduIf, EduThen, EduElse, FlexContainer } from '@edulastic/common'
import {
  ITEM_GROUP_DELIVERY_TYPES,
  ITEM_GROUP_TYPES,
} from '@edulastic/constants/const/test'
import { SortableGroupItem, SortableSingleItem } from './SortableItem'
import {
  InfoDiv,
  Text,
  Count,
  GroupCollapse,
  StyledText,
  DropAreaIndication,
  SectionSpaceElemenet,
} from './styled'
import PassageConfirmationModal from '../../../PassageConfirmationModal/PassageConfirmationModal'
import {
  isDynamicTestSelector,
  isSectionAdaptiveTestSelector,
} from '../../../../ducks'
import { roundOff } from '../../../../utils'
import {
  getAdaptiveTestSectionTotalPoints,
  isAdaptiveSectionMismatch,
} from '../helper'
import { themes } from '../../../../../../theme'
import DropContainer from '../../../../../../assessment/components/DropContainer'

const { Panel } = Collapse

const LeftContent = ({ group, idx, threshold, isSectionAdaptiveTest }) => {
  if (!isSectionAdaptiveTest) {
    return <span dataCy={group.groupName}>{group.groupName}</span>
  }
  const { thresholdType, value: thresholdValue } = threshold
  let thresholdText = ''
  if (idx !== 0 && thresholdValue) {
    // 2nd section is easy & 3rd is hard
    const thresholdPrefix = idx == 1 ? '< ' : '>= '
    const thresholdSuffix = thresholdType === 'PERCENTAGE' ? '%' : ''
    thresholdText = `(${thresholdPrefix}${thresholdValue}${thresholdSuffix})`
  }

  const { scaledScore } = group?.settings || {}
  const showScaledScore = scaledScore && scaledScore.min && scaledScore.max
  const scaledScoreText = showScaledScore
    ? `Scaled Score: ${scaledScore.min} - ${scaledScore.max}`
    : ''

  return (
    <FlexContainer style={{ gap: '8px' }} alignItems="center">
      <div dataCy={group.groupName}>{group.groupName}</div>
      <StyledText data-cy={`thresholdText-${group.groupName}`}>
        {thresholdText}
      </StyledText>
      <EduIf condition={showScaledScore}>
        <IconSmallCircle color={darkGrey} />
        <StyledText data-cy={`scaledScoreText-${group.groupName}`}>
          {scaledScoreText}
        </StyledText>
      </EduIf>
    </FlexContainer>
  )
}

const rightContent = (
  group,
  hasSections = false,
  isDynamicTest,
  isEditable,
  refreshGroupItems,
  itemGroupIndex,
  adaptiveDelivery,
  adaptiveSectionMismatch,
  testItemsUpdatedScores
) => {
  const {
    deliverItemsCount,
    items,
    settings,
    deliveryType,
    itemsDefaultMaxScore,
    type,
  } = group

  const refreshSection = (e) => {
    e.stopPropagation()
    refreshGroupItems(itemGroupIndex)
  }

  const timeLimitInMinutes = `${Math.floor(settings?.timeLimit / 60000)}min`

  return (
    <>
      {/* 
        when any calc is selected for a section, the calc Icon and tooltip 
        will be displayed. Add a condition hasSections for the same. 
      */}
      <EduIf condition={type === ITEM_GROUP_TYPES.AUTOSELECT && isEditable}>
        <Tooltip title="Replaces current items with a new set.">
          <span onClick={refreshSection}>
            <IconReloadCircle data-cy={`reloadIcon-${group.groupName}`} />
          </span>
        </Tooltip>
      </EduIf>
      {hasSections && settings?.calcTypes?.length > 0 && (
        <Tooltip title={settings.calcTypes.join()}>
          <span>
            <IconSectionsCalculator width="30" height="44" color={darkGrey5} />
          </span>
        </Tooltip>
      )}
      <EduIf condition={!!adaptiveDelivery}>
        <EduThen>
          <EduIf condition={!!settings?.timeLimit}>
            <InfoDiv>
              <Text>TIME LIMIT</Text>
              <Count thresholdText data-cy={`timeLimit-${group.groupName}`}>
                {timeLimitInMinutes}
              </Count>
            </InfoDiv>
          </EduIf>
          <InfoDiv>
            <Text
              color={
                adaptiveSectionMismatch[itemGroupIndex]?.points ? red : title
              }
            >
              TOTAL POINTS
            </Text>
            <Count
              color={
                adaptiveSectionMismatch[itemGroupIndex]?.points ? red : title
              }
              data-cy={`totalPoints-${group.groupName}`}
            >
              {getAdaptiveTestSectionTotalPoints({
                itemGroupItems: items,
                testItemsUpdatedScores,
              })}
            </Count>
          </InfoDiv>
          <InfoDiv>
            <Text
              color={
                adaptiveSectionMismatch[itemGroupIndex]?.itemRequired
                  ? red
                  : title
              }
            >
              ITEMS REQUIRED
            </Text>
            <Count
              color={
                adaptiveSectionMismatch[itemGroupIndex]?.itemRequired
                  ? red
                  : title
              }
              data-cy={`itemsRequired-${group.groupName}`}
            >
              {itemGroupIndex === 0
                ? adaptiveDelivery?.baseSectionMaxItemsCount
                : adaptiveDelivery?.threshold?.thresholdSectionMaxItemsCount ||
                  1}
            </Count>
          </InfoDiv>
          <InfoDiv>
            <Text
              color={
                adaptiveSectionMismatch[itemGroupIndex]?.itemsCount
                  ? red
                  : title
              }
            >
              TOTAL ITEMS
            </Text>
            <Count
              color={
                adaptiveSectionMismatch[itemGroupIndex]?.itemsCount
                  ? red
                  : title
              }
              data-cy={`totalItemCount-${group.groupName}`}
            >
              {items.length}
            </Count>
          </InfoDiv>
        </EduThen>
        <EduElse>
          <InfoDiv hasSections={hasSections}>
            <Text>TOTAL ITEMS</Text>
            <Count data-cy={`totalItemCount-${group.groupName}`}>
              {items.length}
            </Count>
          </InfoDiv>
        </EduElse>
      </EduIf>
      {!hasSections && (
        <InfoDiv>
          <Text>Item to Deliver</Text>
          <Count data-cy={`deliverItemsCount-${group.groupName}`}>
            {deliverItemsCount || items.length}
          </Count>
        </InfoDiv>
      )}
      <EduIf
        condition={
          isDynamicTest &&
          deliveryType === ITEM_GROUP_DELIVERY_TYPES.LIMITED_RANDOM
        }
      >
        <InfoDiv>
          <Text>TOTAL POINTS</Text>
          <Count data-cy={`totalPoints-${group.groupName}`}>
            {roundOff(deliverItemsCount * (itemsDefaultMaxScore || 1))}
          </Count>
        </InfoDiv>
      </EduIf>
    </>
  )
}

const ReviewSection = ({
  items,
  isEditable,
  itemGroups,
  isPublishers,
  userRole,
  isPowerPremiumAccount,
  showGroupsPanel,
  onSortGroup,
  removeSingle,
  removeMultiple,
  hasSections,
  setShowAutoSelectScoreChangeModal,
  isDynamicTest,
  refreshGroupItems,
  adaptiveDelivery,
  isSectionAdaptiveTest,
  onDrop,
  dragState,
  ...rest
}) => {
  const [removalObj, setRemovalPassageItems] = useState()

  const { scoring: testItemsUpdatedScores = {} } = rest

  const handleDelete = (item) => (removalId) => {
    if (isArray(item)) {
      setRemovalPassageItems({ items: item.map((x) => x._id), removalId })
    } else {
      removeSingle(removalId)
    }
  }

  const handlePassageItemsConfirm = (value) => {
    if (value) {
      removeMultiple(removalObj.items)
    } else {
      removeSingle(removalObj.removalId)
    }
    setRemovalPassageItems(null)
  }

  const handleClosePassageConfirm = () => {
    setRemovalPassageItems(null)
  }

  const renderItem = (item, index, group) => {
    const { _id: groupId, deliveryType } = group || {}

    const enableScoreEditingForEntireSection =
      isDynamicTest &&
      deliveryType === ITEM_GROUP_DELIVERY_TYPES.LIMITED_RANDOM &&
      isEditable

    const openScoreEditPopup = () => {
      if (!enableScoreEditingForEntireSection) return
      setShowAutoSelectScoreChangeModal(groupId)
    }

    if (isArray(item)) {
      // when use index or item._id for a key, the SortableGroupItem was unmounted
      // so there was a page blinking bug, when drag and drop
      // for now, will use passageId for a key
      return (
        <SortableGroupItem
          {...rest}
          key={item[0].passageId}
          disabled={!isEditable}
          isEditable={isEditable}
          items={item}
          index={index}
          collection={groupId}
          groupId={groupId}
          showGroupsPanel={showGroupsPanel}
          removeItem={handleDelete(item)}
          onSortEnd={onSortGroup(index)}
          lockToContainerEdges
          lockOffset={['10%', '10%']}
          setShowAutoSelectScoreChangeModal={openScoreEditPopup}
        />
      )
    }
    return (
      <SortableSingleItem
        {...rest}
        key={item._id}
        removeItem={handleDelete(item)}
        disabled={!isEditable}
        isEditable={isEditable}
        index={index}
        collection={groupId}
        item={item}
        isPublishers={isPublishers}
        setShowAutoSelectScoreChangeModal={openScoreEditPopup}
      />
    )
  }

  const groupIndexWithoutRestrictedContent = []
  for (const [groupIndex, group] of Object.entries(itemGroups)) {
    if (!group.premiumContentRestriction) {
      groupIndexWithoutRestrictedContent.push(groupIndex)
      break
    }
  }

  const adaptiveSectionMismatch = isAdaptiveSectionMismatch(
    adaptiveDelivery,
    itemGroups,
    isSectionAdaptiveTest,
    testItemsUpdatedScores
  )
  return (
    <>
      {!!removalObj && (
        <PassageConfirmationModal
          removing
          visible={!!removalObj}
          closeModal={handleClosePassageConfirm}
          itemsCount={removalObj.items.length}
          handleResponse={handlePassageItemsConfirm}
        />
      )}
      {showGroupsPanel ? (
        <GroupCollapse
          defaultActiveKey={groupIndexWithoutRestrictedContent}
          expandIconPosition="right"
        >
          {itemGroups.map((group, index) => (
            <Panel
              header={
                <LeftContent
                  group={group}
                  idx={index}
                  threshold={adaptiveDelivery?.threshold || {}}
                  isSectionAdaptiveTest={isSectionAdaptiveTest}
                />
              }
              key={index}
              extra={rightContent(
                group,
                hasSections,
                isDynamicTest,
                isEditable,
                refreshGroupItems,
                index,
                adaptiveDelivery,
                adaptiveSectionMismatch,
                testItemsUpdatedScores
              )}
            >
              <DropContainer
                theme={themes.default}
                width="100%"
                key={`drop-${index}-${group._id}`}
                index={index}
                drop={() => {
                  onDrop(group._id)
                }}
                isPlaylist
                sourceSection={dragState.groupId === group._id}
              >
                {items.map((item, _index) => {
                  // EV-38941: here item can be an object or array of objects
                  if (isArray(item)) {
                    const currentGroupItems = (item || []).filter(
                      (_item) => _item.groupId === group._id
                    )
                    if (currentGroupItems?.length) {
                      return renderItem(currentGroupItems, _index, group)
                    }
                    return null
                  }
                  if (item.groupId == group._id) {
                    return renderItem(item, _index, group)
                  }
                  return null
                })}
                {hasSections && !group.items?.length && (
                  <SectionSpaceElemenet className="space-element">
                    Add items from the &quot;Add Item&quot; tab or drag and drop
                    items between sections.
                  </SectionSpaceElemenet>
                )}
                {
                  <DropAreaIndication
                    className="drop-area-indication"
                    data-cy={`dropContainer-${group.groupName}`}
                  >
                    DROP/MOVE YOUR ITEM HERE
                  </DropAreaIndication>
                }
              </DropContainer>
            </Panel>
          ))}
        </GroupCollapse>
      ) : (
        items.map((item, index) => renderItem(item, index))
      )}
    </>
  )
}

const enhance = compose(
  SortableContainer,
  connect((state) => ({
    isDynamicTest: isDynamicTestSelector(state),
    isSectionAdaptiveTest: isSectionAdaptiveTestSelector(state),
  }))
)

export default enhance(ReviewSection)
