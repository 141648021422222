import { reportsApi, dataWarehouseApi } from '@edulastic/api'
import { notification } from '@edulastic/common'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { actions } from './actionReducers'
import { tableData } from './selectors'

const { domainAnalysisReportApi } = dataWarehouseApi

function* fetchFiltersDataRequestSaga({ payload }) {
  try {
    const filtersData = yield call(reportsApi.fetchMARFilterData, payload)
    yield put(actions.fetchFiltersDataRequestSuccess({ filtersData }))
  } catch (error) {
    const msg =
      'Error getting filter data. Please try again after a few minutes.'
    notification({ msg })
    yield put(actions.fetchFiltersDataRequestError({ error: msg }))
  }
}

function* fetchSkillInfoRequestSaga({ payload }) {
  try {
    const skillInfo = yield call(domainAnalysisReportApi.getSkillInfo, payload)
    if (skillInfo?.dataSizeExceeded) {
      yield put(
        actions.fetchSkillInfoRequestError({
          error: skillInfo,
        })
      )
      return
    }
    yield put(
      actions.fetchSkillInfoRequestSuccess({
        skillInfo: skillInfo.result,
      })
    )
  } catch (error) {
    const msg =
      'Error getting skill info. Please try again after a few minutes.'
    notification({ msg, type: 'error' })
    yield put(actions.fetchSkillInfoRequestError({ error: true }))
  }
}

function* fetchSummaryDataRequestSaga({ payload }) {
  try {
    const summaryData = yield call(
      domainAnalysisReportApi.getDomainAnalysisSummary,
      payload
    )
    if (summaryData?.dataSizeExceeded) {
      yield put(
        actions.fetchSummaryDataRequestError({
          error: summaryData,
        })
      )
      return
    }
    yield put(
      actions.fetchSummaryDataRequestSuccess({
        summaryData: summaryData.result,
      })
    )
  } catch (error) {
    const msg =
      'Error getting chart data. Please try again after a few minutes.'
    notification({ msg, type: 'error' })
    yield put(actions.fetchSummaryDataRequestError({ error: true }))
  }
}

function* fetchTableDataRequestSaga({ payload }) {
  try {
    const tableApiResponse = yield call(
      domainAnalysisReportApi.getDomainAnalysisDetails,
      payload
    )
    if (tableApiResponse.dataSizeExceeded) {
      yield put(
        actions.fetchTableDataRequestError({
          error: true,
        })
      )
      return
    }
    const newtableData = tableApiResponse.result
    const prevTableData = yield select(tableData)
    if (!payload.requiresRowCount) {
      newtableData.rowCount = prevTableData?.rowCount || 0
    }
    yield put(actions.fetchTableDataRequestSuccess({ tableData: newtableData }))
  } catch (error) {
    const msg =
      'Error getting table data. Please try again after a few minutes.'
    notification({ msg, type: 'error' })
    yield put(actions.fetchTableDataRequestError({ error: true }))
  }
}

export default function* watcherSaga() {
  yield all([
    takeLatest(actions.fetchFiltersDataRequest, fetchFiltersDataRequestSaga),
    takeLatest(actions.fetchSkillInfoRequest, fetchSkillInfoRequestSaga),
    takeLatest(actions.fetchSummaryDataRequest, fetchSummaryDataRequestSaga),
    takeLatest(actions.fetchTableDataRequest, fetchTableDataRequestSaga),
  ])
}
