import { createAction, createReducer } from 'redux-starter-kit'
import { createSelector } from 'reselect'
import { all, call, takeLatest, put } from 'redux-saga/effects'
import { contentImportApi } from '@edulastic/api'
import { uploadToS3, notification } from '@edulastic/common'
import { aws } from '@edulastic/constants'
import { groupBy } from 'lodash'

export const UPLOAD_STATUS = {
  STANDBY: 'STANDBY',
  INITIATE: 'INITIATE',
  DONE: 'DONE',
  FAILED: 'FAILED',
}

export const JOB_STATUS = {
  PROGRESS: 'progress',
  FAILED: 'failed',
  SUCCESS: 'success',
  INITIATED: 'initiated',
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
  INVALID: 'invalid',
  UNSUPPORTED: 'unsupported',
  ERROR: 'error',
}

const contentFolders = {
  qti: aws.s3Folders.QTI_IMPORT,
  webct: aws.s3Folders.WEBCT_IMPORT,
}

const UPLOAD_TEST_REQUEST = '[import test] upload test request'
const UPLOAD_TEST_SUSSESS = '[import test] upload test success'
const UPLOAD_TEST_ERROR = '[import test] upload test error'
const SET_UPLOAD_TEST_STATUS = '[import test] set upload test status'
const SET_JOB_IDS = '[import test] test job ids'
const SET_QTI_FILE_STATUS = '[import test] qti file status'
const GET_IMPORT_PROGRESS = '[import test] get import progress action'
const SET_JOBS_DATA = '[import test] set jobs response data'
const SET_SUCCESS_MESSAGE = '[import test] set success message'
const SET_IS_IMPORTING = '[import test] set is importing'
const RESET_STATE = '[import test] reset state'

export const uploadTestRequestAction = createAction(UPLOAD_TEST_REQUEST)
export const uploadTestSuccessAction = createAction(UPLOAD_TEST_SUSSESS)
export const uploadTestErrorAction = createAction(UPLOAD_TEST_ERROR)
export const uploadTestStatusAction = createAction(SET_UPLOAD_TEST_STATUS)
export const setJobIdsAction = createAction(SET_JOB_IDS)
export const setQtiFileStatusesAction = createAction(SET_QTI_FILE_STATUS)
export const qtiImportProgressAction = createAction(GET_IMPORT_PROGRESS)
export const setJobsDataAction = createAction(SET_JOBS_DATA)
export const setSuccessMessageAction = createAction(SET_SUCCESS_MESSAGE)
export const setIsImportingAction = createAction(SET_IS_IMPORTING)
export const resetStateAction = createAction(RESET_STATE)

const initialState = {
  testDetail: {},
  error: {},
  status: sessionStorage.getItem('testUploadStatus') || UPLOAD_STATUS.STANDBY,
  jobIds: JSON.parse(sessionStorage.getItem('jobIds')) || [],
  jobsData: [],
  successMessage: '',
  isSuccess: true,
  importing: false,
  qtiFileStatus: {},
}

const testUploadStatus = (state, { payload }) => {
  sessionStorage.setItem('testUploadStatus', payload)
  state.status = payload
}

const uploadTestSuccess = (state, { payload }) => ({ ...state, ...payload })

const uploadTestError = (state, { payload }) => {
  state.error = payload
  state.isSuccess = false
}

const setJobIds = (state, { payload }) => {
  state.jobIds = payload
}

const setJobsData = (state, { payload }) => {
  state.jobsData = payload
  state.qtiFileStatus = payload.length === 0 ? {} : state.qtiFileStatus
}

const setSuccessMessage = (state, { payload }) => {
  state.successMessage = payload
  state.isSuccess = true
}

const setIsImporting = (state, { payload }) => {
  state.importing = payload
}

const setQtiFileStatuses = (state, { payload }) => {
  state.qtiFileStatus = payload
}

const resetQtiState = (state) => {
  state.jobsData = []
  state.qtiFileStatus = {}
  state.error = {}
  state.status = UPLOAD_STATUS.STANDBY
  state.jobIds = []
  state.successMessage = ''
  state.isSuccess = true
  state.importing = false
}

export const reducers = createReducer(initialState, {
  [SET_UPLOAD_TEST_STATUS]: testUploadStatus,
  [UPLOAD_TEST_SUSSESS]: uploadTestSuccess,
  [UPLOAD_TEST_ERROR]: uploadTestError,
  [SET_JOB_IDS]: setJobIds,
  [SET_JOBS_DATA]: setJobsData,
  [SET_SUCCESS_MESSAGE]: setSuccessMessage,
  [SET_IS_IMPORTING]: setIsImporting,
  [SET_QTI_FILE_STATUS]: setQtiFileStatuses,
  [RESET_STATE]: resetQtiState,
})

export function* uploadTestStaga({ payload }) {
  const { fileList, contentType: type, tags: testItemTags } = payload
  try {
    yield put(uploadTestStatusAction(UPLOAD_STATUS.INITIATE))
    let { responseFiles = [] } = payload
    if (responseFiles.length === 0) {
      try {
        yield put(setSuccessMessageAction('Started creating signed URLS'))
        ;[responseFiles] = yield all([
          fileList.map((file) =>
            call(uploadToS3, file.originFileObj, contentFolders[type])
          ),
        ])
        yield put(setSuccessMessageAction('Done creating signed URLs'))
      } catch (e) {
        yield put(uploadTestErrorAction(e?.data || {}))
        console.log(e)
      }
    }

    try {
      yield put(setSuccessMessageAction('Started creating the items'))
      yield put(setIsImportingAction(true))
      const payloadData = {
        files: responseFiles,
        type,
        testItemTags,
      }
      let endpoint = contentImportApi.contentImport
      if (type === 'qti') {
        endpoint = contentImportApi.qtiImport
        payloadData.file = responseFiles.shift()
        delete payloadData.files
      }
      const response = yield call(endpoint, payloadData)
      if (response?.jobIds?.length || response.jobId) {
        if (type === 'qti') {
          yield put(setJobIdsAction(response.jobId))
        } else {
          yield put(setJobIdsAction(response.jobIds))
        }
        sessionStorage.setItem(
          'jobIds',
          JSON.stringify(type !== 'qti' ? response.jobIds : [response.jobId])
        )
        yield put(setSuccessMessageAction('Completed creating the items'))
      } else {
        yield put(uploadTestStatusAction(UPLOAD_STATUS.FAILED))
        yield put(uploadTestError('Failed uploading'))
      }
    } catch (e) {
      yield put(uploadTestErrorAction(e?.data || {}))
      yield put(uploadTestStatusAction(UPLOAD_STATUS.FAILED))
      console.log(e)
    }
  } catch (e) {
    yield put(uploadTestErrorAction(e?.data || {}))
    yield put(uploadTestStatusAction(UPLOAD_STATUS.FAILED))
    console.log(e, 'eee')
  }
}

function* getImportProgressSaga({ payload }) {
  const { jobId, interval } = payload
  try {
    const response = yield call(contentImportApi.qtiImportStatus, jobId)
    const manifestResponse = response.find(
      (ele) => ele.type === 'manifestation'
    )
    if (manifestResponse.status === JOB_STATUS.COMPLETED) {
      yield put(uploadTestStatusAction(UPLOAD_STATUS.INITIATE))
      yield put(setJobsDataAction(response))
      const qtiFiles = response.filter((ele) => ele.type !== 'manifestation')
      const qtiFilesStatus = qtiFiles.reduce((acc, curr) => {
        if (!acc[curr.status]) {
          acc[curr.status] = 1
        } else {
          acc[curr.status] += 1
        }
        return acc
      }, {})
      yield put(setQtiFileStatusesAction(qtiFilesStatus))
      if (
        qtiFiles.every(
          ({ status }) =>
            ![JOB_STATUS.INITIATED, JOB_STATUS.IN_PROGRESS].includes(status)
        )
      ) {
        yield put(uploadTestStatusAction(UPLOAD_STATUS.DONE))
        if (interval?.current) {
          clearInterval(interval?.current)
          interval.current = null
        }
      }
    } else if (manifestResponse.status === JOB_STATUS.ERROR) {
      yield put(uploadTestStatusAction(UPLOAD_STATUS.DONE))
      yield put(uploadTestErrorAction(`${manifestResponse.error}`))
      if (interval?.current) {
        clearInterval(interval?.current)
        interval.current = null
      }
      notification({
        type: 'error',
        msg: `Failed to process ${manifestResponse.identifier} file since ${manifestResponse.error}`,
      })
    }
  } catch (e) {
    if (interval?.current) {
      clearInterval(interval?.current)
      interval.current = null
    }
    return notification({ messageKey: 'failedToFetchProgress' })
  }
}

export function* importTestWatcher() {
  yield all([
    yield takeLatest(UPLOAD_TEST_REQUEST, uploadTestStaga),
    yield takeLatest(GET_IMPORT_PROGRESS, getImportProgressSaga),
  ])
}

export const stateSelector = (state) => state.admin.importTest

export const getJobsDataSelector = createSelector(
  stateSelector,
  (state) => state.jobsData
)
export const getCompletedJobsByStatus = createSelector(
  getJobsDataSelector,
  (jobsData) => groupBy(jobsData, 'status')
)

export const getUploadStatusSelector = createSelector(
  stateSelector,
  (state) => state.status
)

export const getJobIdsSelector = createSelector(
  stateSelector,
  (state) => state.jobIds
)

export const getSuccessMessageSelector = createSelector(
  stateSelector,
  (state) => state.successMessage
)

export const getIsSuccessSelector = createSelector(
  stateSelector,
  (state) => state.isSuccess
)

export const getErrorDetailsSelector = createSelector(
  stateSelector,
  (state) => state.error
)

export const getIsImportingselector = createSelector(
  stateSelector,
  (state) => state.importing
)

export const getQtiFileStatusSelector = createSelector(
  stateSelector,
  (state) => state.qtiFileStatus
)

export const getQtiUploadPayloadSelector = createSelector(
  stateSelector,
  (state) => state.uploadPayload
)
