import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Tooltip } from 'antd'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { IconManage, IconPlusCircle } from '@edulastic/icons'
import {
  CardText,
  RowWrapper,
  LeftCol,
  CenterCol,
  CardAction,
  ButtonHolder,
  TooltipStyled,
} from './styled'
import { getUserOrgId } from '../../../../../../../src/selectors/user'
import { setFilterInSession } from '../../../../../../../../common/utils/helpers'
import { setShowAssignmentCreationModalAction } from '../../../../../../ducks'

export const CardAssignmentContent = ({
  data,
  history,
  userId,
  districtId,
  setShowAssignmentCreationModal,
}) => {
  const { totalAssignment, _id, termId } = data

  const applyClassFilter = () => {
    const filter = {
      classId: _id,
      testType: '',
      termId,
    }
    setFilterInSession({
      key: 'assignments_filter',
      userId,
      districtId,
      filter,
    })
  }

  const navigateToAssignments = () => {
    applyClassFilter()
    history.push('/author/assignments')
  }

  const openAssignmentPopup = () => {
    setShowAssignmentCreationModal(true)
  }

  return (
    <ButtonHolder>
      <CardText
        className="card-text"
        $whiteBg={!!totalAssignment}
        onClick={
          totalAssignment >= 1 ? navigateToAssignments : openAssignmentPopup
        }
      >
        <RowWrapper>
          <LeftCol span={4} width="auto">
            {!!totalAssignment && (
              <CardAction data-cy="totalAssignment">
                {totalAssignment}
              </CardAction>
            )}
            {(!totalAssignment || totalAssignment === 0) && (
              <Tooltip title="Create New Assignment" placement="topLeft">
                <IconPlusCircle data-cy="addNewAssignmentIcon" />{' '}
              </Tooltip>
            )}
          </LeftCol>
          &nbsp;
          <CenterCol span={20}>
            <CardAction data-cy="assignmentContent">
              {totalAssignment > 1
                ? 'Assignments'
                : totalAssignment === 1
                ? 'Assignment'
                : 'Add Assignments'}
            </CardAction>
          </CenterCol>
        </RowWrapper>
      </CardText>
      <Link to={`/author/manageClass/${_id}`}>
        <TooltipStyled
          title="Manage student rosters, monitor class performance, assign tests, sync with Google Classroom, etc."
          $bgColor="white"
          $color="#777777"
          $border="1px solid #D8D8D8"
          placement="bottom"
        >
          <CardText $whiteBg className="card-text">
            <CardAction>
              <IconManage color="currentColor" css="vertical-align: middle;" />
              &nbsp; CLASS
            </CardAction>
          </CardText>
        </TooltipStyled>
      </Link>
    </ButtonHolder>
  )
}

const enhance = compose(
  withRouter,
  connect(
    (state) => ({
      districtId: getUserOrgId(state),
    }),
    {
      setShowAssignmentCreationModal: setShowAssignmentCreationModalAction,
    }
  )
)
export default enhance(CardAssignmentContent)
