import { roleuser } from '@edulastic/constants'
import React from 'react'
import { connect } from 'react-redux'
import { EduIf } from '@edulastic/common'
import { segmentApi } from '@edulastic/api'
import { Button, Icon } from 'antd'
import { themeColorBlue } from '@edulastic/colors'
import styled from 'styled-components'
import { setShowClassCreationModalAction } from '../../../../../../ducks'
import { setCreateClassTypeDetailsAction } from '../../../../../../../ManageClass/ducks'
import AuthorCompleteSignupButton from '../../../../../../../../common/components/AuthorCompleteSignupButton'
import {
  getManualEnrollmentAllowedSelector,
  getUserSelector,
} from '../../../../../../../src/selectors/user'

const NewClass = ({
  user,
  classData,
  manualEnrollmentAllowed,
  setShowClassCreationModal,
  setCreateClassTypeDetails,
  visible,
}) => {
  const sortableClasses = classData
    .filter((d) => d.asgnStartDate !== null && d.asgnStartDate !== undefined)
    .sort((a, b) => b.asgnStartDate - a.asgnStartDate)
  const unSortableClasses = classData.filter(
    (d) => d.asgnStartDate === null || d.asgnStartDate === undefined
  )

  const allClasses = [...sortableClasses, ...unSortableClasses]

  const allActiveClasses = allClasses.filter(
    (c) => c.active === 1 && c.type === 'class'
  )

  const atleastOneClassPresent = allActiveClasses.length > 0

  const isManualEnrollmentAllowed =
    user?.user?.role === roleuser.TEACHER ? manualEnrollmentAllowed : true
  const showCreateClass = atleastOneClassPresent && isManualEnrollmentAllowed

  const trackClick = (event) => () =>
    segmentApi.trackUserClick({
      user: user.user,
      data: { event },
    })

  const createNewClass = () => {
    setShowClassCreationModal(true)
    setCreateClassTypeDetails({ type: 'class' })
  }

  return (
    <>
      <EduIf condition={showCreateClass && visible}>
        <AuthorCompleteSignupButton
          renderButton={(handleClick) => (
            <NewClassButton
              isBlue
              data-cy="createNewClass"
              onClick={handleClick}
            >
              <Icon type="plus-circle" />
              CREATE CLASS
            </NewClassButton>
          )}
          onClick={createNewClass}
          trackClick={trackClick('dashboard:create-new-class:click')}
        />
      </EduIf>
    </>
  )
}

const enhance = connect(
  (state) => ({
    user: getUserSelector(state),
    classData: state.dashboardTeacher.data,
    manualEnrollmentAllowed: getManualEnrollmentAllowedSelector(state),
  }),
  {
    setShowClassCreationModal: setShowClassCreationModalAction,
    setCreateClassTypeDetails: setCreateClassTypeDetailsAction,
  }
)

export default enhance(NewClass)

const NewClassButton = styled(Button)`
  color: ${themeColorBlue} !important;
  border: none !important;
  font-size: 14px;
  padding: 0 5px;
  font-weight: 600;
  box-shadow: none;
`
