import React, { Component } from 'react'
import PropTypes from 'prop-types'
import produce from 'immer'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import { compose } from 'redux'

import { getFormattedAttrId } from '@edulastic/common/src/helpers'
import { withNamespaces } from '@edulastic/localization'

import QuestionTextArea from '../../components/QuestionTextArea'
import { Subtitle } from '../../styled/Subtitle'

import { updateVariables } from '../../utils/variables'

import { setQuestionDataAction } from '../../../author/QuestionEditor/ducks'

import Question from '../../components/Question'

class ComposeQuestion extends Component {
  render() {
    const { item, setQuestionData, t, fillSections, cleanSections } = this.props

    const { stimulus } = item

    const handleItemChangeChange = (prop, uiStyle) => {
      setQuestionData(
        produce(item, (draft) => {
          draft[prop] = uiStyle
          updateVariables(draft)
        })
      )
    }

    return (
      <Question
        section="main"
        label={t('component.classification.composeQuestion')}
        fillSections={fillSections}
        cleanSections={cleanSections}
      >
        <Subtitle
          id={getFormattedAttrId(
            `${item?.title}-${t('component.classification.composeQuestion')}`
          )}
        >
          {t('component.classification.composeQuestion')}
        </Subtitle>

        <QuestionTextArea
          placeholder={t('component.classification.enterQuestion')}
          onChange={(stim) => handleItemChangeChange('stimulus', stim)}
          value={stimulus}
          border="border"
        />
      </Question>
    )
  }
}

ComposeQuestion.propTypes = {
  item: PropTypes.object.isRequired,
  setQuestionData: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  fillSections: PropTypes.func,
  cleanSections: PropTypes.func,
}

ComposeQuestion.defaultProps = {
  fillSections: () => {},
  cleanSections: () => {},
}

const enhance = compose(
  withNamespaces('assessment'),
  withTheme,
  connect(null, { setQuestionData: setQuestionDataAction })
)

export default enhance(ComposeQuestion)
