import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconRead = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="23.232"
    height="23.232"
    viewBox="0 0 23.232 23.232"
    {...props}
  >
    <g transform="translate(0 -0.006)">
      <g transform="translate(0 0.006)">
        <path
          className="a"
          d="M21.871,12.42V10.261a.681.681,0,0,0-.681-.681,20.533,20.533,0,0,0-3.956.361,6.145,6.145,0,0,0-2.555-1.48,4.765,4.765,0,1,0-6.127,0A6.146,6.146,0,0,0,6,9.941,20.536,20.536,0,0,0,2.042,9.58a.681.681,0,0,0-.681.681V12.42A2.045,2.045,0,0,0,0,14.345v1.361a2.045,2.045,0,0,0,1.361,1.925V19.79a.681.681,0,0,0,.681.681,15.839,15.839,0,0,1,9.2,2.654.683.683,0,0,0,.755,0,15.839,15.839,0,0,1,9.2-2.654.681.681,0,0,0,.681-.681V17.631a2.045,2.045,0,0,0,1.361-1.925V14.345A2.045,2.045,0,0,0,21.871,12.42ZM8.213,4.816a3.4,3.4,0,1,1,3.4,3.4A3.43,3.43,0,0,1,8.213,4.816ZM2.042,16.387a.681.681,0,0,1-.681-.681V14.345a.681.681,0,1,1,1.361,0v1.361A.681.681,0,0,1,2.042,16.387Zm8.894,4.95a17.03,17.03,0,0,0-8.213-2.217V17.631a2.045,2.045,0,0,0,1.361-1.925V14.345A2.045,2.045,0,0,0,2.723,12.42V10.951a15.385,15.385,0,0,1,8.213,2.4Zm.681-9.164a16.176,16.176,0,0,0-3.96-1.82,4.774,4.774,0,0,1,2.6-.772h2.723a4.774,4.774,0,0,1,2.6.772A16.175,16.175,0,0,0,11.616,12.173Zm8.894,6.946A17.031,17.031,0,0,0,12.3,21.337V13.353a15.383,15.383,0,0,1,8.213-2.4V12.42a2.045,2.045,0,0,0-1.361,1.925v1.361a2.045,2.045,0,0,0,1.361,1.925Zm1.361-3.413a.681.681,0,1,1-1.361,0V14.345a.681.681,0,1,1,1.361,0Z"
          transform="translate(0 -0.006)"
        />
      </g>
    </g>
  </SVG>
)

export default withIconStyles(IconRead)
