import { EduButton } from '@edulastic/common'
import styled from 'styled-components'

export const TestRecommendationsWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: -13px;
`

export const ViewMoreButton = styled.a`
  margin-left: auto;
  padding-right: 40px;
  font-size: 12px;
  font-weight: 600;
`

export const TestCardContainer = styled.div`
  width: 240px;
  align-items: flex-end;
  margin: 0px 8px 0px 0px;
`
export const CustomButton = styled(EduButton)`
  margin-left: 5px;
  margin-top: -4px;
  padding: 5px;
`
