import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { get } from 'lodash'

import { toggleChatDisplay } from '@edulastic/common'
import {
  MainWrapper,
  SpinContainer,
  StyledContent,
  StyledLayout,
  StyledSpin,
} from '../../../../admin/Common/StyledComponents'
import AdminHeader from '../../../src/components/common/AdminHeader/AdminHeader'
import Collections from '../Collections/Collections'
import { uploadContnentStatus, contentImportJobIds } from '../../ducks'
import { UPLOAD_STATUS } from '../../../ImportTest/ducks'
import { isGcpsDistrictSelector } from '../../../src/selectors/user'

const title = 'Manage District'
const menuActive = { mainMenu: 'Content', subMenu: 'Collections' }

export const Container = ({
  history,
  routeKey,
  uploadStatus = '',
  jobIds = [],
  loading,
  upserting,
  isGcpsDistrict,
}) => {
  useEffect(() => {
    if (uploadStatus !== UPLOAD_STATUS.DONE && jobIds.length) {
      history.push('/author/import-content')
    }
  }, [])

  useEffect(() => {
    // hiding the chat icon for collection page
    if (isGcpsDistrict) {
      toggleChatDisplay('hide')
    }
    return () => {
      if (isGcpsDistrict) {
        toggleChatDisplay('show')
      }
    }
  }, [isGcpsDistrict])

  const showSpin = loading || upserting

  return (
    <MainWrapper key={routeKey}>
      <AdminHeader title={title} active={menuActive} history={history} />
      <StyledContent>
        <StyledLayout>
          {showSpin && (
            <SpinContainer loading={showSpin}>
              <StyledSpin size="large" />
            </SpinContainer>
          )}
          <Collections history={history} />
        </StyledLayout>
      </StyledContent>
    </MainWrapper>
  )
}

const enhance = compose(
  connect((state) => ({
    routeKey: get(state, ['router', 'location', 'key']),
    uploadStatus: uploadContnentStatus(state),
    jobIds: contentImportJobIds(state),
    isGcpsDistrict: isGcpsDistrictSelector(state),
  }))
)

export default enhance(Container)
