import React from 'react'
import withIconStyles from '@edulastic/icons/src/HOC/withIconStyles'
import SVG from '@edulastic/icons/src/common/SVG'

const IconGraphTangent = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="25.698"
    height="25.699"
    viewBox="0 0 25.698 25.699"
    {...props}
  >
    <g transform="translate(-344.244 -523.239)">
      <path
        d="M368.942,537.088h-23.7a1,1,0,0,1,0-2h23.7a1,1,0,0,1,0,2Z"
        fill="#c1c3c9"
      />
      <path
        d="M357.093,548.938a1,1,0,0,1-1-1v-23.7a1,1,0,0,1,2,0v23.7A1,1,0,0,1,357.093,548.938Z"
        fill="#c1c3c9"
      />
      <path d="M346.86,548.569a.855.855,0,0,1-.112-.008.75.75,0,0,1-.63-.853,15.331,15.331,0,0,1,10.528-12.284c8.482-2.647,9.117-9.859,9.123-9.931a.75.75,0,0,1,1.5.111c-.007.084-.728,8.3-10.172,11.251a13.824,13.824,0,0,0-9.492,11.076A.75.75,0,0,1,346.86,548.569Z" />
    </g>
  </SVG>
)

export default withIconStyles(IconGraphTangent)
