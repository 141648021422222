import React from 'react'
import {
  IconAssignment,
  IconBarChartFilled,
  IconMenuBook,
} from '@edulastic/icons'
import { Link } from 'react-router-dom'
import QueryString from 'qs'
import { segmentApi } from '@edulastic/api'
import { connect } from 'react-redux'
import { ActionItem, ListActionContainer } from '../../../styled'
import { StyledTooltip } from '../../../../../../Reports/components/dataWarehouseReport/common/components/StyledComponents'
import { setFilterInSession } from '../../../../../../../common/utils/helpers'
import { getUserId, getUserOrgId } from '../../../../../../src/selectors/user'
import FeaturesSwitch from '../../../../../../../features/components/FeaturesSwitch'
import { setShowAssignmentCreationModalAction } from '../../../../../ducks'

const ClassListActions = ({
  classInfo,
  userId,
  districtId,
  setShowAssignmentCreationModal,
}) => {
  const getLink = (url, type) => {
    const queryParams = {
      termId: classInfo.termId,
      classIds: classInfo._id,
    }
    if (type === 'standardMastery') {
      queryParams.compareBy = 'student'
    }
    return `${url}?${QueryString.stringify(queryParams)}`
  }

  const applyClassFilter = () => {
    const filter = {
      classId: classInfo._id,
      testType: '',
      termId: classInfo.termId,
    }
    setFilterInSession({
      key: 'assignments_filter',
      userId,
      districtId,
      filter,
    })
  }

  const navigateToAssignments = (e) => {
    segmentApi.genericEventTrack('Dashboard:Assignment:view', {
      source: 'Dashboard',
    })
    if (classInfo.totalAssignment > 0) {
      applyClassFilter()
    } else {
      setShowAssignmentCreationModal(true)
      e.preventDefault()
    }
  }
  return (
    <ListActionContainer>
      <FeaturesSwitch inputFeatures="premium" actionOnInaccessible={null}>
        {({ isAccessible }) => (
          <Link
            data-cy="advancedGradebook"
            to={
              isAccessible
                ? `/author/reports/student-progress?termId=${
                    classInfo.termId
                  }&${classInfo.type === 'class' ? 'classIds' : 'groupIds'}=${
                    classInfo._id
                  }`
                : '/author/reports'
            }
            onClick={() =>
              segmentApi.genericEventTrack('Dashboard:Assignment:Gradebook', {
                source: 'Dashboard',
              })
            }
            target="_blank"
            rel="opener"
          >
            <StyledTooltip title="Advanced Gradebook" placement="bottomLeft">
              <ActionItem>
                <IconMenuBook color="#555555" />
              </ActionItem>
            </StyledTooltip>
          </Link>
        )}
      </FeaturesSwitch>
      <Link
        data-cy="standardMastery"
        to={getLink('/author/reports/standards-gradebook', 'standardMastery')}
        onClick={() =>
          segmentApi.genericEventTrack('Dashboard:StandardsGradebook:SMR', {
            source: 'Dashboard',
          })
        }
        target="_blank"
        rel="opener"
      >
        <StyledTooltip title="Standards Mastery" placement="bottomLeft">
          <ActionItem>
            <IconBarChartFilled color="#555555" />
          </ActionItem>
        </StyledTooltip>
      </Link>

      <Link
        data-cy="authorAssignments"
        to="/author/assignments"
        onClick={navigateToAssignments}
      >
        <StyledTooltip title="Assignments" placement="bottomLeft">
          <ActionItem>
            <IconAssignment />
          </ActionItem>
        </StyledTooltip>
      </Link>
    </ListActionContainer>
  )
}

export default connect(
  (state) => ({
    userId: getUserId(state),
    districtId: getUserOrgId(state),
  }),
  {
    setShowAssignmentCreationModal: setShowAssignmentCreationModalAction,
  }
)(ClassListActions)
