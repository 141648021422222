import React, { Component, memo } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { getFormattedAttrId } from '@edulastic/common/src/helpers'

import { withNamespaces } from '@edulastic/localization'
import { setQuestionDataAction } from '../../../author/QuestionEditor/ducks'

import { Subtitle } from '../../styled/Subtitle'
import Question from '../../components/Question'

import Options from './components/Options'

class VideoPlayer extends Component {
  render() {
    const { item, setQuestionData, t, fillSections, cleanSections } = this.props

    return (
      <Question
        section="main"
        label={t('component.video.videoPlayer')}
        fillSections={fillSections}
        cleanSections={cleanSections}
      >
        <Subtitle
          id={getFormattedAttrId(
            `${item?.title}-${t('component.video.videoPlayer')}`
          )}
        >
          {t('component.video.videoPlayer')}
        </Subtitle>
        <Options setQuestionData={setQuestionData} item={item} t={t} />
      </Question>
    )
  }
}

VideoPlayer.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    videoType: PropTypes.string.isRequired,
    sourceURL: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    transcript: PropTypes.string.isRequired,
    uiStyle: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
      posterImage: PropTypes.string.isRequired,
      captionURL: PropTypes.string.isRequired,
      hideControls: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  setQuestionData: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  fillSections: PropTypes.func,
  cleanSections: PropTypes.func,
}

VideoPlayer.defaultProps = {
  fillSections: () => {},
  cleanSections: () => {},
}

export default compose(
  withNamespaces('assessment'),
  memo,
  connect(null, { setQuestionData: setQuestionDataAction })
)(VideoPlayer)
