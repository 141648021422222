import React from 'react'
import withIconStyles from '@edulastic/icons/src/HOC/withIconStyles'
import SVG from '@edulastic/icons/src/common/SVG'

const IconGraphArea = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="29.084"
    height="20.111"
    viewBox="0 0 29.084 20.111"
    {...props}
  >
    <g transform="translate(-417.53 -524.447)">
      <path
        d="M431.935,526.45c6.926-.118,12.6,3.392,12.678,7.839s-5.478,8.143-12.4,8.261-12.6-3.387-12.678-7.835S425.009,526.568,431.935,526.45Z"
        fill="none"
      />
      <path d="M431.935,526.45c-6.926.118-12.48,3.818-12.4,8.265s5.752,7.957,12.678,7.84,12.48-3.819,12.4-8.266-5.752-7.957-12.678-7.839m-.034-2a18.614,18.614,0,0,1,9.994,2.506,10.948,10.948,0,0,1,3.331,3.087,7.476,7.476,0,0,1,.144,8.469,10.933,10.933,0,0,1-3.224,3.2,19.834,19.834,0,0,1-19.9.338,10.934,10.934,0,0,1-3.331-3.086,7.478,7.478,0,0,1-.144-8.47,10.931,10.931,0,0,1,3.224-3.2A18.6,18.6,0,0,1,431.9,524.45Z" />
      <rect
        width="16.278"
        height="0.999"
        transform="translate(418.239 536.753) rotate(-42.545)"
      />
      <rect
        width="16.279"
        height="0.999"
        transform="translate(433.239 542.754) rotate(-42.545)"
      />
      <rect
        width="21.931"
        height="1"
        transform="matrix(0.73, -0.683, 0.683, 0.73, 421.225, 540.746)"
      />
      <rect
        width="21.932"
        height="1"
        transform="matrix(0.73, -0.683, 0.683, 0.73, 426.225, 542.746)"
      />
    </g>
  </SVG>
)

export default withIconStyles(IconGraphArea)
