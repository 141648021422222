import { Table, Typography } from 'antd'
import styled from 'styled-components'
import {
  black,
  lightGreen10,
  mediumDesktopExactWidth,
  gray600,
} from '@edulastic/colors'
import { EduButton, SelectInputStyled } from '@edulastic/common'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const { Text } = Typography

export const TableHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`

export const TableViewStyled = styled.div`
  min-height: 300px;
  max-height: 500px;
  overflow-y: auto;
  padding-right: 15px;
  ::-webkit-scrollbar {
    width: 5px; /* Set the width of the scrollbar */
    background-color: #f1f1f1; /* Color of the scrollbar background */
  }
  ::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the scrollbar thumb (the draggable part) */
    border-radius: 10px; /* Make the scrollbar thumb rounded */
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: ${gray600}; /* Color of the scrollbar thumb when hovered */
  }
`

export const StyledSelect = styled(SelectInputStyled)`
  margin: unset !important;
  &.ant-select {
    .ant-select-selection {
      font-size: 14px;
      font-weight: 500;
      color: ${black};
      border: none;
      width: fit-content;
      background-color: unset;
      max-width: 150px;
      &:focus,
      &:hover {
        box-shadow: none !important;
        border: none !important;
        background-color: unset !important;
      }

      &__rendered {
        display: flex !important;
        gap: 12px;
        padding: 0px 20px 0px 0px !important;
      }
    }
  }
`

export const TestThumbnail = styled.img`
  border-radius: 4px;
  width: 42px;
  height: 24px;
  margin-right: 5px;

  @media (min-width: ${mediumDesktopExactWidth}) {
    width: 50px;
  }
`

export const TestThumbnailEmpty = styled.div`
  border-radius: 4px;
  width: 42px;
  height: 24px;
  margin-right: 5px;

  @media (min-width: ${mediumDesktopExactWidth}) {
    width: 50px;
  }
`

export const StyledTable = styled(Table)`
  .ant-table-thead {
    display: none;
  }
  .ant-table-tbody {
    tr {
      td {
        padding: 16px 0;
      }
      td:first-child {
        padding: 16px 0px;
      }
    }
  }
  .ant-table-body {
    min-height: 300px;
    .ant-table-body {
      min-height: 0px;
    }
  }
  tr.ant-table-expanded-row td > .ant-table-wrapper {
    margin: -16px 0 -17px 0;
  }
`

export const StyledSeeAll = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${lightGreen10};
  border-radius: 5px;
  border: 1px solid ${lightGreen10};
  background: #fff;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  padding: 2px 12px;
  &:hover {
    background: #1a73e8;
    border-color: #1a73e8;
    color: #fff;
  }
`

/** @type {typeof Text} */
export const StyledText = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => (props.color ? props.color : gray600)};
  width: ${({ width }) => width || 'auto'};
`

export const StyledStatusWrap = styled.div`
  display: inline-flex;
  text-transform: uppercase;
  span {
    margin-right: 0;
    text-align: center;
  }
`

export const StyledPlusIconContainer = styled.div`
  width: 16px;
`

export const ActionSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: end;
  align-items: end;
  .ant-dropdown-trigger {
    cursor: pointer;
    width: fit-content;
  }
  .actions {
    button {
      svg {
        width: 10px;
      }
    }
    button:hover {
      svg {
        fill: #ffffff !important;
      }
    }
    button:focus {
      svg {
        fill: #000000 !important;
      }
    }
    button {
      width: 45px;
    }
  }
`

export const ActionButtonContainer = styled.div`
  text-align: right;
`

export const ClassCountAndStatusContainer = styled.div`
  width: 75px;
`

export const StatsContainer = styled.div`
  width: ${({ width }) => width || '120px'};
`

export const ActionButton = styled(EduButton)`
  svg.actionIcon {
    fill: ${gray600} !important;
    g {
      path {
        fill: ${gray600} !important;
      }
    }
  }
  &:hover {
    svg.actionIcon {
      fill: #ffffff !important;
      g {
        path {
          fill: #ffffff !important;
        }
      }
    }
  }
`

export const SeeAllRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 10px;
  color: ${lightGreen10};
`
