import { segmentApi } from '@edulastic/api'

import {
  withWindowSizes,
  notification,
  EduIf,
  FlexContainer,
} from '@edulastic/common'
import { IconLock, IconMail, IconUser } from '@edulastic/icons'
import { withNamespaces } from '@edulastic/localization'
import { Divider, Form, Input } from 'antd'
import { isEmpty, trim } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { compose } from 'redux'

import { placeholderPrefixColor } from '@edulastic/colors'
import {
  getDistrictLoginUrl,
  getDistrictStudentSignupUrl,
  getFullNameFromString,
  getPartnerDASignupUrl,
  getPartnerKeyFromUrl,
  getPartnerLoginUrl,
  getPartnerStudentSignupUrl,
  isDistrictPolicyAllowed,
  isEmailValid,
  removeMetaTag,
  updateMetaTag,
  validatePartnerUrl,
} from '../../../../common/utils/helpers'
import { Partners } from '../../../../common/utils/static/partnerData'
import cleverIcon from '../../../assets/clever-icon.svg'
import googleIcon from '../../../assets/google-btn.svg'
import icon365 from '../../../assets/icons8-office-365.svg'
import {
  cleverLoginAction,
  getTooManyAtempt,
  googleLoginAction,
  msoLoginAction,
  setInviteDetailsAction,
  setTooManyAttemptAction,
  signupAction,
} from '../../../Login/ducks'
import {
  BoldText,
  FormBody,
  LinkDiv,
  PsiContainer,
  RegisterButton,
  RegistrationHeader,
  RegistrationWrapper,
  RightContainer,
  SignUpContainer,
  SignUpHeader,
  StyledButtonText,
  CreateAccountDivider,
  ThirdPartyLoginBtn,
} from '../../styled'
import PasswordPopup from '../PasswordPopup'
import TermsAndPrivacy from '../TermsAndPrivacy/TermsAndPrivacy'
import { isPearDomain } from '../../../../../utils/pear'
import { LeftContainer } from '../LeftContainer'
import { MAX_TAB_WIDTH } from '../../../../author/src/constants/others'
import { ForgotPasswordPopup } from '../../../Login/components/forgotPasswordPopup'

const FormItem = Form.Item

class Signup extends React.Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    signup: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  state = {
    confirmDirty: false,
    signupError: {},
    showModal: false,
    proceedBtnDisabled: true,
  }

  closeModal = () => {
    this.setState({
      showModal: false,
      password: null,
    })
  }

  componentDidMount() {
    if (window?.analytics?.track) {
      window.analytics.track('Signup_PageView', {
        role: 'teacher',
        referrer: window.document.referrer,
      })
      // Adding meta description tag
      updateMetaTag({ content: 'Pear Assessment for Teachers' })
    }
  }

  componentWillUnmount() {
    removeMetaTag() // removing description meta tag
  }

  regExp = new RegExp('^[A-Za-z0-9 ]+$')

  handleSubmit = (e) => {
    const {
      form,
      signup,
      t,
      invitedUser,
      invitedUserDetails,
      setInviteDetailsAction,
      utm_source,
    } = this.props
    e && e.preventDefault()
    form.validateFieldsAndScroll((err, { password, email, name }) => {
      if (!err) {
        if (!invitedUser) {
          signup({
            passwordForExistingUser: this.state.password,
            password,
            email: trim(email),
            name: trim(name),
            role: 'teacher',
            policyViolation: t('common.policyviolation'),
            utm_source,
            errorCallback: this.errorCallback,
          })
        } else if (invitedUser) {
          const fullName = getFullNameFromString(trim(name))
          setInviteDetailsAction({
            uid: invitedUserDetails._id,
            email: invitedUserDetails.email,
            username: invitedUserDetails.email,
            ...fullName,
            password,
          })
        }
        segmentApi.genericEventTrack('Signup_ButtonClick', { Method: 'Email' })
      }
    })
  }

  handleConfirmBlur = ({ target: { value } }) => {
    let { confirmDirty } = this.state
    confirmDirty = confirmDirty || !!value
    this.setState({ confirmDirty })
  }

  checkPassword = (rule, value, callback) => {
    const { t } = this.props
    if (value.length < 4) {
      callback(t('component.signup.teacher.shortpassword'))
    } else if (value.includes(' ')) {
      callback(t('component.signup.teacher.validpassword'))
    }
    callback()
  }

  checkName = (rule, value, callback) => {
    const { t } = this.props
    if (!this.regExp.test(value.trim())) {
      callback(t('component.signup.teacher.validinputname'))
    } else {
      callback()
    }
  }

  onChangeEmail = () => {
    this.setState((state) => ({
      ...state,
      signupError: {
        ...state.signupError,
        email: '',
      },
    }))
  }

  errorCallback = (error) => {
    if (error === 'Email already exists. Please sign in to your account.') {
      this.setState((state) => ({
        ...state,
        signupError: {
          ...state.signupError,
          email: 'error',
        },
      }))
    } else if ((error || {}).askPassword) {
      if (error.passwordMatch === false) {
        notification({ messageKey: 'passwordIsIncorrect' })
      }
      this.setState({
        showModal: true,
        existingUser: error,
      })
    } else if (error === 'too_many_attempts') {
      this.setState({
        showModal: false,
      })
    } else {
      notification({ msg: error })
    }
  }

  onPasswordChange = (password) => {
    this.setState({
      proceedBtnDisabled: isEmpty(password),
      password,
    })
  }

  onClickProceed = () => {
    this.handleSubmit()
  }

  render() {
    const {
      form: { getFieldDecorator, getFieldError },
      t,
      image,
      isSignupUsingDaURL,
      districtPolicy,
      orgShortName,
      orgType,
      googleLoginAction,
      cleverLoginAction,
      msoLoginAction,
      invitedUser = false,
      invitedUserDetails = {},
      generalSettings,
      windowWidth,
      setTooManyAttempt = () => {},
      tooManyAttempt = false,
    } = this.props
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
      },
    }

    const partnerKey = getPartnerKeyFromUrl(location.pathname)
    const partner = Partners[partnerKey]

    const emailError =
      (this.state.signupError.email && (
        <span>
          Email already exists. Please{' '}
          <Link
            to={
              isSignupUsingDaURL
                ? getDistrictLoginUrl(orgShortName, orgType)
                : getPartnerLoginUrl(partner)
            }
          >
            sign in
          </Link>{' '}
          to your account.
        </span>
      )) ||
      getFieldError('email')
    const isDistrictSignup =
      generalSettings?.pageBackground && isSignupUsingDaURL
    return (
      <div>
        <PasswordPopup
          showModal={this.state.showModal}
          existingUser={this.state.existingUser}
          disabled={this.state.proceedBtnDisabled}
          closeModal={this.closeModal}
          onChange={this.onPasswordChange}
          onClickProceed={this.onClickProceed}
        />
        {tooManyAttempt && (
          <ForgotPasswordPopup
            visible={tooManyAttempt}
            onCancel={() => setTooManyAttempt(false)}
            onOk={() => setTooManyAttempt(false)}
          />
        )}
        {!isSignupUsingDaURL && !validatePartnerUrl(partner) ? (
          <Redirect exact to="/" />
        ) : null}
        <RegistrationWrapper image={image}>
          {windowWidth > MAX_TAB_WIDTH && (
            <LeftContainer
              currentPage="teachersignup"
              isDistrictSignup={isDistrictSignup}
            />
          )}
          <RightContainer windowWidth={windowWidth}>
            <Helmet>
              <link
                rel="canonical"
                href="https://assessment.peardeck.com/signup"
              />
            </Helmet>
            <RegistrationHeader type="flex" align="middle">
              <span>{t('component.signup.alreadyhaveanaccount')}</span>
              <Link
                onClick={() =>
                  segmentApi.genericEventTrack(
                    'SignupPage_SigninButtonClick',
                    {}
                  )
                }
                to={
                  isSignupUsingDaURL
                    ? getDistrictLoginUrl(orgShortName, orgType)
                    : getPartnerLoginUrl(partner)
                }
              >
                {t('component.signup.signinbtn')}
              </Link>
            </RegistrationHeader>
            <SignUpContainer isDistrictSignup={isDistrictSignup}>
              <SignUpHeader>Sign up As Teacher</SignUpHeader>
              <EduIf condition={isPearDomain}>
                <PsiContainer width="100%">
                  <div id="psi_sign_in" />
                </PsiContainer>
              </EduIf>
              {isDistrictPolicyAllowed(
                isSignupUsingDaURL,
                districtPolicy,
                'googleSignOn'
              ) || !isSignupUsingDaURL ? (
                <ThirdPartyLoginBtn
                  span={20}
                  offset={2}
                  onClick={() => {
                    googleLoginAction({ role: 'teacher' })
                  }}
                >
                  <img src={googleIcon} alt="Google" width="16px" />{' '}
                  <StyledButtonText>
                    {t('component.signup.googlesignupbtn')}
                  </StyledButtonText>
                </ThirdPartyLoginBtn>
              ) : null}
              {isDistrictPolicyAllowed(
                isSignupUsingDaURL,
                districtPolicy,
                'office365SignOn'
              ) || !isSignupUsingDaURL ? (
                <ThirdPartyLoginBtn
                  span={20}
                  offset={2}
                  onClick={() => {
                    msoLoginAction({ role: 'teacher' })
                  }}
                >
                  <img src={icon365} alt="Office 365" />{' '}
                  <StyledButtonText>
                    {t('component.signup.office365signupbtn')}
                  </StyledButtonText>
                </ThirdPartyLoginBtn>
              ) : null}
              {isDistrictPolicyAllowed(
                isSignupUsingDaURL,
                districtPolicy,
                'cleverSignOn'
              ) || !isSignupUsingDaURL ? (
                <ThirdPartyLoginBtn
                  span={20}
                  offset={2}
                  onClick={() => {
                    cleverLoginAction('teacher')
                  }}
                  data-cy="singUpWithCleverButton"
                >
                  <img src={cleverIcon} alt="Clever" />{' '}
                  <StyledButtonText>
                    {t('component.signup.cleversignupbtn')}
                  </StyledButtonText>
                </ThirdPartyLoginBtn>
              ) : null}
              <CreateAccountDivider>
                {t('component.signup.formboxheading')}
              </CreateAccountDivider>
              <FormBody>
                <Form onSubmit={this.handleSubmit} autoComplete="new-password">
                  <FormItem {...formItemLayout}>
                    {getFieldDecorator('name', {
                      validateFirst: true,
                      initialValue: '',
                      rules: [
                        {
                          required: true,
                          message: t('component.signup.teacher.validinputname'),
                        },
                        {
                          validator: this.checkName,
                        },
                      ],
                    })(
                      <Input
                        data-cy="name"
                        prefix={<IconUser color={placeholderPrefixColor} />}
                        placeholder="Teacher Name"
                        autoComplete="new-password"
                      />
                    )}
                  </FormItem>
                  <FormItem
                    {...formItemLayout}
                    validateStatus={emailError ? 'error' : 'success'}
                    help={emailError}
                  >
                    {getFieldDecorator('email', {
                      validateFirst: true,
                      initialValue: invitedUser ? invitedUserDetails.email : '',
                      rules: [
                        {
                          transform: (value) => trim(value),
                        },
                        {
                          required: true,
                          message: t('component.signup.teacher.validemail'),
                        },
                        {
                          type: 'string',
                          message: t('component.signup.teacher.validemail'),
                        },
                        {
                          validator: (rule, value, callback) =>
                            isEmailValid(
                              rule,
                              value,
                              callback,
                              'email',
                              t('component.signup.teacher.validemail')
                            ),
                        },
                      ],
                    })(
                      <Input
                        data-cy="email"
                        prefix={<IconMail color={placeholderPrefixColor} />}
                        placeholder="Teacher Email"
                        type="email"
                        autoComplete="new-password"
                        disabled={invitedUser}
                        onChange={this.onChangeEmail}
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout}>
                    {getFieldDecorator('password', {
                      validateFirst: true,
                      initialValue: '',
                      rules: [
                        {
                          required: true,
                          message: t('component.signup.teacher.validpassword'),
                        },
                        {
                          validator: this.checkPassword,
                        },
                      ],
                    })(
                      <Input
                        data-cy="password"
                        prefix={<IconLock color={placeholderPrefixColor} />}
                        type="password"
                        placeholder="Password"
                        autoComplete="new-password"
                      />
                    )}
                  </FormItem>
                  <FormItem>
                    <RegisterButton
                      data-cy="signup"
                      type="primary"
                      htmlType="submit"
                    >
                      {t('component.signup.teacher.signupteacher')}
                    </RegisterButton>
                  </FormItem>
                  <EduIf condition={!isPearDomain}>
                    <TermsAndPrivacy />
                  </EduIf>
                </Form>
              </FormBody>
              <Divider />
              <FlexContainer
                width="100%"
                style={{ gap: '20px' }}
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <BoldText>Not a teacher?</BoldText>
                <div>
                  {!isSignupUsingDaURL ? (
                    <LinkDiv>
                      <Link to={getPartnerDASignupUrl(partner)}>
                        {t('component.signup.signupasadmin')}
                      </Link>
                    </LinkDiv>
                  ) : null}
                  {isDistrictPolicyAllowed(
                    isSignupUsingDaURL,
                    districtPolicy,
                    'studentSignUp'
                  ) || !isSignupUsingDaURL ? (
                    <LinkDiv>
                      <Link
                        to={
                          isSignupUsingDaURL
                            ? getDistrictStudentSignupUrl(orgShortName, orgType)
                            : getPartnerStudentSignupUrl(partner)
                        }
                      >
                        {t('component.signup.signupasstudent')}
                      </Link>
                    </LinkDiv>
                  ) : null}
                </div>
              </FlexContainer>
            </SignUpContainer>
          </RightContainer>
        </RegistrationWrapper>
      </div>
    )
  }
}

const SignupForm = Form.create()(Signup)

const enhance = compose(
  withNamespaces('login'),
  withWindowSizes,
  connect(
    (state) => ({
      tooManyAttempt: getTooManyAtempt(state),
    }),
    {
      signup: signupAction,
      setTooManyAttempt: setTooManyAttemptAction,
      googleLoginAction,
      cleverLoginAction,
      msoLoginAction,
      setInviteDetailsAction,
    }
  )
)

export default enhance(SignupForm)
