import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Icon, Menu, Tooltip } from 'antd'
import { IconUser } from '@edulastic/icons'
import { FlexContainer, withKeyboard } from '@edulastic/common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import {
  StyledButton,
  StyledDropdown,
  StyledMenu,
  StyledSubMenu,
} from './styled'
import { useUtaPauseAllowed } from '../../common/SaveAndExit'
import { getIsMultiLanguageEnabled } from '../../../../common/components/LanguageSelectorTab/duck'
import { lineReaderVisible } from '../../../../common/components/LineReader/duck'
import { getUserNameSelector } from '../../../../author/src/selectors/user'
import { StyledTextForDropdown } from '../../common/styledCompoenents'
import { getIsAnswerMaskingEnabledSelector } from '../../../../author/sharedDucks/testPlayer'
import { magnifierSizes, toolMap } from '../../../constants/assessmentPlayer'
import { itemDataHasMultipleChoiceOptions } from '../../../utils/helpers'
import { getItemsSelector } from '../../../selectors/test'

const MenuItem = withKeyboard(Menu.Item)

const SettingMenu = ({
  userName,
  onSettingsChange,
  showMagnifier,
  enableMagnifier,
  utaId,
  hidePause,
  multiLanguageEnabled,
  isPremiumContentWithoutAccess = false,
  canShowPlaybackOptionTTS,
  isLineReaderVisible,
  i18Translate,
  magnifierSize,
  isAnswerMaskingEnabled,
  tool = [],
  testItem = {},
}) => {
  const _pauseAllowed = useUtaPauseAllowed(utaId)
  const showPause = _pauseAllowed === undefined ? true : _pauseAllowed
  const handleSettingsChange = (e) => e && onSettingsChange(e)

  const menuItems = {
    changeColor: i18Translate('header:headerMenuOptions.changeColor'),
    enableMagnifier: i18Translate('header:headerMenuOptions.enableMagnifier'),
    showLineReaderMask: i18Translate(
      'header:headerMenuOptions.showLineReaderMask'
    ),
    enableAnswerMask: i18Translate('header:headerMenuOptions.enableAnswerMask'),
    testOptions: i18Translate('header:headerMenuOptions.testOptions'),
  }
  const itemHasMultipleChoiceQuestions = itemDataHasMultipleChoiceOptions(
    testItem?.data
  )

  const menu = (
    <StyledMenu onClick={handleSettingsChange} selectedKeys={[magnifierSize]}>
      {Object.keys(menuItems)
        .filter(
          (item) =>
            (item === 'testOptions' && canShowPlaybackOptionTTS) ||
            item !== 'testOptions' ||
            multiLanguageEnabled
        )
        .map((key) =>
          key === 'enableMagnifier' ? (
            <StyledSubMenu
              key="enableMagnifier"
              data-cy={key}
              onTitleClick={() => {
                handleSettingsChange({ key })
              }}
              disabled={
                (key === 'enableMagnifier' && !showMagnifier) ||
                isPremiumContentWithoutAccess
              }
              title={
                <FlexContainer justifyContent="space-between">
                  {menuItems[key]}
                  {key === 'enableMagnifier' && enableMagnifier && (
                    <FontAwesomeIcon icon={faCheck} />
                  )}
                </FlexContainer>
              }
            >
              <Menu.Item key={magnifierSizes.SMALL}>
                {i18Translate('header:toolbar.magnifySmall')}
              </Menu.Item>
              <Menu.Item key={magnifierSizes.MEDIUM}>
                {i18Translate('header:toolbar.magnifyMedium')}
              </Menu.Item>
            </StyledSubMenu>
          ) : (
            <MenuItem
              key={key}
              data-cy={key}
              onClick={() => {
                handleSettingsChange({ key })
              }}
              disabled={
                (tool?.indexOf(toolMap.CROSS_OUT) !== -1 ||
                  !itemHasMultipleChoiceQuestions) &&
                key == 'enableAnswerMask'
              }
            >
              <Tooltip
                title={
                  key === 'enableAnswerMask' && !itemHasMultipleChoiceQuestions
                    ? i18Translate('header:toolbar.crossDisabled')
                    : ''
                }
              >
                <FlexContainer width="100%" justifyContent="space-between">
                  {menuItems[key]}
                  {((key === 'showLineReaderMask' && isLineReaderVisible) ||
                    (key === 'enableAnswerMask' && isAnswerMaskingEnabled)) && (
                    <FontAwesomeIcon icon={faCheck} />
                  )}
                </FlexContainer>
              </Tooltip>
            </MenuItem>
          )
        )}
      {showPause && <Menu.Divider />}
      {showPause && (
        <MenuItem
          disabled={hidePause}
          {...(hidePause
            ? {
                title: i18Translate(
                  'header:saveAndExit.assignmentInOneSitting'
                ),
              }
            : {})}
          key="save"
          data-cy="finishTest"
          onClick={() => {
            handleSettingsChange({ key: 'save' })
          }}
        >
          {i18Translate('header:saveAndExit.saveAndExit')}
        </MenuItem>
      )}
    </StyledMenu>
  )

  return (
    <StyledDropdown
      overlay={menu}
      trigger={['hover', 'click']}
      overlayStyle={{ zIndex: 1003 }}
    >
      <StyledButton style={{ width: 'auto' }} data-cy="exitMenu">
        <IconUser />
        <Tooltip title={userName} placement="left">
          <StyledTextForDropdown>{userName} </StyledTextForDropdown>
        </Tooltip>
        <Icon type="down" />
      </StyledButton>
    </StyledDropdown>
  )
}

const enhance = compose(
  connect(
    (state, { currentItem = 0 }) => ({
      userName: getUserNameSelector(state),
      multiLanguageEnabled: getIsMultiLanguageEnabled(state),
      isLineReaderVisible: lineReaderVisible(state),
      magnifierSize: state.testPlayer?.magnifierSize,
      isAnswerMaskingEnabled: getIsAnswerMaskingEnabledSelector(state),
      testItem: (getItemsSelector(state) || [])[currentItem],
    }),
    {}
  )
)

export default enhance(SettingMenu)
