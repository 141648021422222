import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconThumbsDown = ({ width, height, fillColor, ...props }) => (
  <SVG
    width={width || '12'}
    height={height || '12'}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color={fillColor || '#2F4151'}
    {...props}
  >
    <path d="M7.5 1H3C2.585 1 2.23 1.25 2.08 1.61L0.57 5.135C0.525 5.25 0.5 5.37 0.5 5.5V6.5C0.5 7.05 0.95 7.5 1.5 7.5H4.655L4.18 9.785L4.165 9.945C4.165 10.15 4.25 10.34 4.385 10.475L4.915 11L8.205 7.705C8.39 7.525 8.5 7.275 8.5 7V2C8.5 1.45 8.05 1 7.5 1ZM7.5 7L5.33 9.17L5.885 6.5H1.5V5.5L3 2H7.5V7ZM9.5 1H11.5V7H9.5V1Z" />
  </SVG>
)

export default withIconStyles(IconThumbsDown)
