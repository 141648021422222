import {
  IconAIItem,
  IconFolderWithLines,
  IconMath,
  IconNewList,
  IconPassage,
  IconPlay,
  IconPlusCircle,
  IconSelection,
  IconWrite,
} from '@edulastic/icons'
import React from 'react'
import MCStandard from '../../../src/client/author/src/assets/multiple-choice/standard-blue.svg'
import WSEssayRichText from '../../../src/client/author/src/assets/written-spoken/essay-rich-text-blue.svg'
import SQPassage from '../../../src/client/author/src/assets/video-and-passages/passage01_blue.svg'
import MTCombinationClozeText from '../../../src/client/author/src/assets/math/math-multipart-cloze-blue.svg'
import VPVideoPlayer from '../../../src/client/author/src/assets/video-and-passages/video-player-blue.svg'
import FBClozeText from '../../../src/client/author/src/assets/fill-in-blanks/cloze-text-blue.svg'
import { questionTitle } from '../index'

export const LIBRARY = {
  key: 'library',
  icon: <IconFolderWithLines />,
  label: 'Library',
  hoverImage: null,
  dataCy: 'createFromLibrary',
}

export const AI = {
  key: 'use-ai',
  icon: <IconAIItem />,
  label: 'Use AI',
  hoverImage: null,
  dataCy: 'createItemUsingAi',
}

export const MCQ = {
  key: 'multiple-choice',
  icon: <IconNewList />,
  label: 'MCQ',
  hoverImage: MCStandard,
  dataCy: `new-item-${questionTitle.MCQ_STANDARD}`,
}

export const TEXT_ENTRY = {
  key: 'text-entry',
  icon: <IconSelection width={15} />,
  label: 'Text Entry',
  hoverImage: FBClozeText,
  dataCy: `new-item-${questionTitle.CLOZE_TEXT}`,
}

export const ESSAY_RICH_TEXT = {
  key: 'essay-rich-text',
  icon: <IconWrite width={15} />,
  label: 'Essay Rich Text',
  hoverImage: WSEssayRichText,
  dataCy: `new-item-${questionTitle.ESSAY_RICH_TEXT}`,
}

export const VIDEO = {
  key: 'video',
  icon: <IconPlay />,
  label: 'Video',
  hoverImage: VPVideoPlayer,
  dataCy: `new-item-Video`,
}

export const PASSAGE = {
  key: 'passage',
  icon: <IconPassage />,
  label: 'Passage',
  hoverImage: SQPassage,
  dataCy: `new-item-${questionTitle.PASSAGE_WITH_QUESTIONS}`,
}

export const MATH_TEXT_DROPDOWN = {
  key: 'math-text-dropdown',
  icon: <IconMath />,
  label: 'Math, Text & Dropdown',
  hoverImage: MTCombinationClozeText,
  dataCy: `new-item-${questionTitle.EXPRESSION_MULTIPART}`,
}

export const MORE_ITEM_TYPES = {
  key: 'more-item-types',
  icon: <IconPlusCircle />,
  label: 'More Item Types',
  hoverImage: null,
  dataCy: 'createNewItem',
}

export const ITEM_QUICK_LINKS = [
  MCQ,
  ESSAY_RICH_TEXT,
  VIDEO,
  PASSAGE,
  MATH_TEXT_DROPDOWN,
]

export const AI_ITEM_QUICK_LINKS = [
  AI,
  MCQ,
  ESSAY_RICH_TEXT,
  PASSAGE,
  MATH_TEXT_DROPDOWN,
]

export const NEW_ITEM_DROPDOWN_QUICK_LINKS = [
  MCQ,
  ESSAY_RICH_TEXT,
  VIDEO,
  PASSAGE,
  MATH_TEXT_DROPDOWN,
]
