import {
  MULTIPLE_CHOICE,
  MULTIPLE_SELECTION,
  TRUE_OR_FALSE,
} from '@edulastic/constants/const/questionType'
import { titleToAIQuestionType } from './form'

export const copyContentToClipboard = (content) => {
  const el = document.createElement('textarea')
  el.value = content
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

export const transformPAQuestionToAIQuestion = (question) => {
  const {
    stimulus = '',
    options = [],
    validation: { validResponse: { value: correcAnswerUUIDs = [] } = {} } = {},
    title,
  } = question || {}

  const questionType = titleToAIQuestionType(title)
  let correctAnswerObj = {}

  if (questionType === MULTIPLE_CHOICE) {
    correctAnswerObj = {
      correctAnswerIndex: options.findIndex(({ value }) =>
        correcAnswerUUIDs.includes(value)
      ),
    }
  } else if (questionType === MULTIPLE_SELECTION) {
    correctAnswerObj = {
      correctAnswersIndex: options
        .map(({ value }, index) =>
          correcAnswerUUIDs.includes(value) ? index : -1
        )
        .filter((index) => index > -1),
    }
  } else if (questionType === TRUE_OR_FALSE) {
    const correctAnswerIndex = options.findIndex(({ value }) =>
      correcAnswerUUIDs.includes(value)
    )
    correctAnswerObj = {
      correctAnswer: options[correctAnswerIndex].label === 'True',
    }
  }

  return {
    name: stimulus,
    options: options.map(({ label }) => ({ name: label })),
    ...correctAnswerObj,
  }
}

export const getAIEnhanceExistingQuestionPayloadData = ({
  question,
  questionType,
}) => {
  const transformedAIQuestion = transformPAQuestionToAIQuestion(question)
  if (questionType === TRUE_OR_FALSE) {
    delete transformedAIQuestion.options
    return transformedAIQuestion
  }
  return transformedAIQuestion
}
