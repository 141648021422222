import { MathFormulaDisplay } from '@edulastic/common'
import produce from 'immer'
import React, { useContext } from 'react'
import isEmpty from 'lodash/isEmpty'
import { isStackedTranslationEnabled, sortKeys } from './utils'
import { StackedTranslatorContext } from './StackedTranslatorProvider'
import { stackedTranslationMap } from './constants'

const StackedTranslatorOption = ({
  question,
  optionIndex,
  fontSize,
  defaultLabel = '',
}) => {
  const {
    stackedTranslation,
    isPreview,
    isStudentReport,
    currentLanguage,
  } = useContext(StackedTranslatorContext)
  /**
   * originalQuestion is getting set in changeDataToPreferredLanguage
   * This is to keep original question data as it is getting modified by preferred language
   */
  const originalQuestion = question?.originalQuestion || question

  /** Adding English content to language features */
  const newQuestion = produce(originalQuestion, (draft) => {
    draft.languageFeatures = {
      ...draft.languageFeatures,
      en: { ...(draft.stimulus ? { options: draft?.options } : {}) },
    }
  })

  /**
   * Handles _stackedTranslation for preview when Stacked Translation is Student Level
   */
  const _stackedTranslation =
    isPreview &&
    !isStudentReport &&
    isStackedTranslationEnabled(stackedTranslation, isPreview)
      ? stackedTranslationMap.ES_EN
      : stackedTranslation

  const sortKeyOrder = (_stackedTranslation || '').split('-')

  const languages = sortKeys(
    sortKeyOrder,
    Object.keys(newQuestion?.languageFeatures || {})
  )
  /** [[{es1}, {es2}],[{en1}, {en2}]] */
  const optionsByLanguages = languages
    .map((language) => {
      const options = newQuestion?.languageFeatures?.[language]?.options
      if ((options || [])?.some(({ label }) => isEmpty(label))) return
      return newQuestion?.languageFeatures?.[language]?.options
    })
    .filter((x) => x)

  if (
    optionsByLanguages?.length === 2 && // checks if question have 2 options
    sortKeyOrder.includes(currentLanguage)
  ) {
    return optionsByLanguages.map((options) => {
      return (
        <MathFormulaDisplay
          fontSize={fontSize}
          dangerouslySetInnerHTML={{
            __html: options?.[optionIndex]?.label,
          }}
        />
      )
    })
  }

  return (
    <MathFormulaDisplay
      fontSize={fontSize}
      dangerouslySetInnerHTML={{
        __html: defaultLabel,
      }}
    />
  )
}

export default StackedTranslatorOption
