import { IconPlusCircle, IconSeeAllArrow } from '@edulastic/icons'
import React from 'react'
import { withRouter } from 'react-router'
import { lightGreen10 } from '@edulastic/colors'
import { widgets } from './constants'
import {
  Wrapper,
  IconWrap,
  Heading,
  Header,
  WidgetContainer,
  SeeAll,
} from './styled'
import Widget from './Widget'

const CreateTestBanner = ({ history }) => {
  const handleSeeAll = () => {
    history.push('/author/tests/select')
  }
  return (
    <Wrapper data-joyride-id="newTestBanner">
      <Header>
        <IconWrap>
          <IconPlusCircle fill="#555" width={16} height={16} />
        </IconWrap>
        <div>
          <Heading>Create Test</Heading>
          <p>Quick shortcuts to get started with test creation</p>
        </div>
        <SeeAll onClick={handleSeeAll}>
          See All <IconSeeAllArrow color={lightGreen10} />
        </SeeAll>
      </Header>
      <WidgetContainer data-cy="widgetContainer">
        {widgets.map((widget, index) => (
          <Widget key={index} widgetKey={widget.key} {...widget} />
        ))}
      </WidgetContainer>
    </Wrapper>
  )
}

export default withRouter(CreateTestBanner)
