import { CustomModalStyled, EduButton, FlexContainer } from '@edulastic/common'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getTestSelector } from '../../ducks'
import { hasAIDocExtracted } from '../../../../assessment/utils/helpers'

const ConfirmTabChange = ({
  showConfirmationOnTabChange,
  confirmChangeNav,
  test,
}) => {
  const { itemGroups = [] } = test
  const isAIDocExtracted = hasAIDocExtracted(itemGroups)

  const Footer = [
    <EduButton isGhost onClick={confirmChangeNav(false)} data-cy="cancel">
      Cancel
    </EduButton>,
    <EduButton onClick={confirmChangeNav(true)} data-cy="proceed">
      Proceed
    </EduButton>,
  ]

  const title = isAIDocExtracted
    ? 'You have unsaved changes'
    : 'Unsaved AI Generated Items'

  const description = isAIDocExtracted
    ? 'Please save your work before switching tabs to avoid losing any updates.'
    : 'Click proceed to save all AI generated items to the Test'

  return (
    <CustomModalStyled
      visible={showConfirmationOnTabChange}
      title={<p data-cy="unSavedPopUp">{title}</p>}
      footer={Footer}
      onCancel={confirmChangeNav(false)}
      centered
      padding="30px 60px"
      bodyPadding="0px"
      borderRadius="10px"
      closeTopAlign="14px"
      closeRightAlign="10px"
      closeIconColor="black"
      destroyOnClose
    >
      <FlexContainer
        justifyContent="center"
        alignItems="center"
        padding="2rem"
        data-cy="saveAllGeneratedItemMessage"
      >
        <span>{description}</span>
      </FlexContainer>
    </CustomModalStyled>
  )
}

const enhance = compose(
  connect((state) => ({
    test: getTestSelector(state),
  }))
)

export default enhance(ConfirmTabChange)
