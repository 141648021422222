import styled from 'styled-components'
import { Divider } from 'antd'

export const AddMoreQuestionsPannelTitle = styled.div`
  color: ${({ isTestEmpty }) => (isTestEmpty ? '#707070' : '#2F4151')};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 16px;
  margin-bottom: ${({ isTestEmpty }) => (isTestEmpty ? '6px' : '20px')};
`

export const NoItemsAddedTitle = styled.h1`
  text-align: center;
  font-weight: 600;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    height: 60px;
    margin: 20px;
    margin-top: 20px;
  }
`

export const ButtonTextWrapper = styled.span`
  font-size: 14px;
`

export const StyledDivider = styled(Divider)`
  width: 100%;
  margin: 32px;
`
