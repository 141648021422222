import React from 'react'

import PropTypes from 'prop-types'
import { withNamespaces } from '@edulastic/localization'
import {
  QuestionNumberLabel,
  FlexContainer,
  QuestionSubLabel,
  CorrectAnswersContainer,
} from '@edulastic/common'
import Instructions from '../../../../components/Instructions'
import { EDIT } from '../../../../constants/constantsForQuestions'

import Options, { SortableOptions } from './components/Options'
import StackedTranslatorStimulus from '../../../../components/Common/StackedTranslator/StackedTranslatorStimulus'
import { replaceVariables } from '../../../../utils/variables'
// import { QuestionTitleWrapper } from "./styled/Label";

const Display = ({
  qIndex,
  view,
  smallSize,
  question,
  uiStyle,
  instructorStimulus,
  index,
  styleType,
  multipleResponses,
  showQuestionNumber,
  flowLayout,
  qLabel,
  fontSize,
  item = {},
  qSubLabel,
  fromSetAnswers,
  onSortOptions,
  ...restProps
}) => {
  const { t, hideCorrectAnswer, showAnswer, showAnswerScore } = restProps

  const itemToPreview = replaceVariables(item)

  const OptListComp = fromSetAnswers ? SortableOptions : Options
  return (
    <FlexContainer alignItems="flex-start" justifyContent="flex-start">
      {!flowLayout && (
        <>
          <FlexContainer
            justifyContent="flex-start"
            flexDirection="column"
            alignItems="flex-start"
          >
            {showQuestionNumber && (
              <QuestionNumberLabel
                fontSize={fontSize}
                className="__print-space-reduce-qlabel"
              >
                {qLabel}
              </QuestionNumberLabel>
            )}
            {qSubLabel && <QuestionSubLabel>({qSubLabel})</QuestionSubLabel>}
          </FlexContainer>

          <FlexContainer
            width="100%"
            className="__print_question-content-wrapper"
            flexDirection="column"
            alignItems="flex-start"
            data-cy="question-content-wrapper"
          >
            {!fromSetAnswers && (
              <StackedTranslatorStimulus
                question={itemToPreview}
                fontSize={fontSize}
                defaultStimulus={question}
              />
            )}
            <OptListComp
              view={view}
              smallSize={smallSize}
              question={question}
              uiStyle={uiStyle}
              styleType={styleType}
              multipleResponses={multipleResponses}
              fontSize={fontSize}
              fromSetAnswers={fromSetAnswers}
              item={item}
              distance={10}
              useDragHandle
              onSortEnd={onSortOptions}
              itemToPreview={itemToPreview}
              {...restProps}
            />
            {view !== EDIT && <Instructions item={item} />}

            {showAnswer &&
              showAnswerScore &&
              !hideCorrectAnswer &&
              !!item?.validation?.validResponse && (
                <CorrectAnswersContainer
                  title={t('component.math.correctAnswers')}
                  minHeight="auto"
                  showAnswerScore={showAnswerScore}
                  score={item.validation.validResponse?.score}
                />
              )}

            {showAnswer &&
              showAnswerScore &&
              !hideCorrectAnswer &&
              item?.validation?.altResponses?.map((ans, i) => (
                <CorrectAnswersContainer
                  title={`${t('component.math.alternateAnswers')} ${i + 1}`}
                  minHeight="auto"
                  showAnswerScore={showAnswerScore}
                  score={ans?.score}
                />
              ))}
          </FlexContainer>
        </>
      )}
    </FlexContainer>
  )
}

Display.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  showAnswer: PropTypes.bool,
  validation: PropTypes.object,
  userSelections: PropTypes.array,
  smallSize: PropTypes.bool,
  checkAnswer: PropTypes.bool,
  question: PropTypes.string.isRequired,
  instructorStimulus: PropTypes.string,
  uiStyle: PropTypes.object,
  qLabel: PropTypes.string.isRequired,
  view: PropTypes.string.isRequired,
  qIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  fontSize: PropTypes.any.isRequired,
  item: PropTypes.object.isRequired,
  styleType: PropTypes.string,
  multipleResponses: PropTypes.bool,
  showQuestionNumber: PropTypes.bool,
  flowLayout: PropTypes.bool,
  qSubLabel: PropTypes.string,
}

Display.defaultProps = {
  options: [],
  onChange: () => {},
  showAnswer: false,
  checkAnswer: false,
  validation: {},
  userSelections: [],
  smallSize: false,
  instructorStimulus: '',
  uiStyle: {
    type: 'standard',
    fontsize: 'normal',
    columns: 1,
    orientation: 'horizontal',
    choiceLabel: 'number',
  },
  showQuestionNumber: false,
  flowLayout: false,
  styleType: 'default',
  multipleResponses: false,
  qSubLabel: '',
}

export default withNamespaces('assessment')(Display)
