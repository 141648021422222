/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconPearAssessLogoCompactOnDarkBg = (props) => {
  return (
    <SVG
      viewBox="0 0 210 321"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.0962 0.273193C63.643 0.273193 53.4802 7.618 46.0813 12.9654C45.0318 13.7238 44.0379 14.4421 43.0953 15.0936C39.0005 17.9235 35.9923 19.5388 33.0505 20.0271C30.4535 20.4581 27.0625 20.1346 22.0301 17.0757L11.0176 23.2692C11.0176 34.4457 17.3643 45.9717 26.6176 54.4697C36.0275 63.1114 49.2409 69.4051 64.326 69.4051C75.5257 69.4051 87.792 65.4601 98.137 58.3133C98.3387 61.1126 98.7495 63.9443 99.3829 66.7914C87.6782 67.6482 77.2066 71.3295 68.3245 78.0893C58.1526 85.8308 50.6996 97.1248 45.6426 111.261C40.8754 124.586 34.232 134.842 27.0958 145.858C24.3388 150.114 21.5082 154.484 18.6838 159.188C8.71611 175.79 0.296387 194.69 0.296387 222.734C0.296387 277.47 45.2794 320.274 104.831 320.274C164.383 320.274 209.366 277.47 209.366 222.734C209.366 194.69 200.947 175.79 190.979 159.188C188.156 154.485 185.326 150.117 182.57 145.863L182.568 145.86L182.567 145.858C175.431 134.842 168.787 124.586 164.02 111.261C158.963 97.1247 151.51 85.8307 141.337 78.0892C132.422 71.304 121.904 67.6206 110.146 66.7819C108.008 39.9074 121.188 25.8633 149.282 25.8633L150.223 0.284864C133.434 0.284864 118.856 8.26012 109.4 20.5154C108.017 17.1527 106.146 14.0302 103.756 11.2716C97.6972 4.27775 88.8542 0.273193 78.0962 0.273193ZM109.679 220.387C115.978 220.387 121.085 215.281 121.085 208.982C121.085 202.684 115.978 197.578 109.679 197.578C103.38 197.578 98.2737 202.684 98.2737 208.982C98.2737 215.281 103.38 220.387 109.679 220.387ZM142.509 220.387C148.808 220.387 153.914 215.281 153.914 208.982C153.914 202.684 148.808 197.578 142.509 197.578C136.21 197.578 131.103 202.684 131.103 208.982C131.103 215.281 136.21 220.387 142.509 220.387ZM101.94 234.836C101.94 247.124 111.524 257.491 126.095 257.491C140.667 257.491 150.251 247.123 150.251 234.836H166.981C166.981 256.742 149.524 274.221 126.095 274.221C102.667 274.221 85.21 256.742 85.21 234.836H101.94Z"
        fill="white"
      />{' '}
    </SVG>
  )
}
export default withIconStyles(IconPearAssessLogoCompactOnDarkBg)
