import React from 'react'
import withIconStyles from '@edulastic/icons/src/HOC/withIconStyles'
import SVG from '@edulastic/icons/src/common/SVG'

const IconGraphParabola2 = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="42.043"
    height="25.884"
    viewBox="0 0 42.043 25.884"
    {...props}
  >
    <g transform="translate(0.657 0.462)">
      <path
        d="M0,16.11a1,1,0,0,1-.707-.293,1,1,0,0,1,0-1.414L14.4-.707a1,1,0,0,1,1.414,0,1,1,0,0,1,0,1.414L.707,15.817A1,1,0,0,1,0,16.11Z"
        transform="translate(9.315 11.636) rotate(-45)"
        opacity="0.5"
      />
      <path
        d="M2.258-5.542A1.68,1.68,0,0,1,1.853-4.4,2.1,2.1,0,0,1,.715-3.76v.029a2.274,2.274,0,0,1,1.309.525A1.47,1.47,0,0,1,2.468-2.08a1.907,1.907,0,0,1-.747,1.6A3.444,3.444,0,0,1-.413.1,5.181,5.181,0,0,1-2.473-.288V-1.572a4.657,4.657,0,0,0,.913.342A3.842,3.842,0,0,0-.574-1.1a1.918,1.918,0,0,0,1.1-.254.932.932,0,0,0,.356-.815.736.736,0,0,0-.41-.713,3.034,3.034,0,0,0-1.309-.21h-.542V-4.248h.552A2.566,2.566,0,0,0,.391-4.465.785.785,0,0,0,.774-5.21q0-.811-1.016-.811A2.324,2.324,0,0,0-.957-5.9a3.494,3.494,0,0,0-.808.405l-.7-1.04a3.88,3.88,0,0,1,2.329-.7,3.007,3.007,0,0,1,1.75.449A1.441,1.441,0,0,1,2.258-5.542Z"
        transform="translate(39.332 20.203)"
      />
      <path
        d="M3,0A3,3,0,1,1,0,3,3,3,0,0,1,3,0Z"
        transform="translate(9.076 18.646) rotate(30)"
      />
      <path
        d="M3,0A3,3,0,1,1,0,3,3,3,0,0,1,3,0Z"
        transform="translate(30.076 18.646) rotate(30)"
      />
      <path
        d="M3,0A3,3,0,1,1,0,3,3,3,0,0,1,3,0Z"
        transform="translate(19.076 3.646) rotate(30)"
      />
      <path
        d="M40,1H0A1,1,0,0,1-1,0,1,1,0,0,1,0-1H40a1,1,0,0,1,1,1A1,1,0,0,1,40,1Z"
        transform="translate(0.763 22.5)"
        opacity="0.5"
      />
      <path
        d="M7454.207,1560.655a10.4,10.4,0,0,1-6.584-2.623,24.554,24.554,0,0,1-4.917-5.738,45.717,45.717,0,0,1-4.172-8.284,1,1,0,1,1,1.88-.682h0c.056.154,5.661,15.326,13.792,15.326l.174,0c8.248-.22,13.358-15.15,13.409-15.3a1,1,0,0,1,1.9.636,43.438,43.438,0,0,1-3.868,8.109,24.225,24.225,0,0,1-4.7,5.717,10.707,10.707,0,0,1-6.681,2.838C7454.358,1560.654,7454.282,1560.655,7454.207,1560.655Z"
        transform="translate(-7433.256 -1542.429)"
      />
      <path
        d="M1.277,0H-.232V-4.131l.015-.679.024-.742q-.376.376-.522.493l-.82.659-.728-.908,2.3-1.831h1.24Z"
        transform="translate(15.227 7.139)"
      />
      <path
        d="M2.537,0h-4.99V-1.05L-.662-2.861q.8-.815,1.04-1.13A2.486,2.486,0,0,0,.73-4.575a1.486,1.486,0,0,0,.107-.557A.811.811,0,0,0,.6-5.771a.918.918,0,0,0-.632-.21,1.818,1.818,0,0,0-.806.19,4.158,4.158,0,0,0-.815.542l-.82-.972A5.352,5.352,0,0,1-1.6-6.855a3.208,3.208,0,0,1,.757-.286,3.878,3.878,0,0,1,.918-.1A2.718,2.718,0,0,1,1.257-7a1.885,1.885,0,0,1,.8.684,1.816,1.816,0,0,1,.283,1.006,2.462,2.462,0,0,1-.173.925,3.553,3.553,0,0,1-.537.886A13.131,13.131,0,0,1,.344-2.2l-.918.864v.068h3.11Z"
        transform="translate(2.473 20.1)"
      />
    </g>
  </SVG>
)

export default withIconStyles(IconGraphParabola2)
