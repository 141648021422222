import React, { useMemo } from 'react'
import { Select } from 'antd'
import { CheckBoxGrp, CheckboxLabel } from '@edulastic/common'

import _ from 'lodash'
import { withNamespaces } from 'react-i18next'
import { LabelWithTooltip } from './LabelWithTooltip'
import { withCalcOptions } from '../../HOC/withCalcOptions'
import {
  CalculatorDropdown,
  CalcDescription,
  CalcCountDescription,
} from './styled-components'

const CalculatorSettingsDescription = ({ width }) => {
  return (
    <CalcDescription width={width}>
      <div>
        Select the &ldquo;State Calculator&rdquo; option to set calculators that
        are calibrated to match your state test specifications.
      </div>
      <div>
        To know more about your state test calculator specifications, follow the
        instructions in this{' '}
        <a
          target="_blank"
          href="https://www.desmos.com/testing"
          rel="noopener noreferrer"
        >
          article
        </a>
        {' from desmos.com '}.
      </div>
    </CalcDescription>
  )
}

const CalculatorSettingsCountDescription = ({ count, width }) => {
  return count ? (
    <CalcCountDescription width={width}>
      Student will see {count} calculator{count > 1 ? 's' : ''} on the
      assessment player.
    </CalcCountDescription>
  ) : null
}

export const CalculatorDetailsTooltip = withNamespaces('author')(
  ({ count, t }) => {
    return (
      <>
        {t('calculatorTypesSettings.info')}
        <CalculatorSettingsDescription width="auto" />
        <CalculatorSettingsCountDescription count={count} width="auto" />
      </>
    )
  }
)

const CalculatorSettings = ({
  calcTypes,
  disabled,
  onChange,
  calcOptions,
  isCheckBoxGroup,
}) => {
  const [
    calcTypesWithStateConfig,
    calcTypesWithoutStateConfig,
    calcTypesModified,
  ] = useMemo(() => {
    const _calcTypesWithStateConfig = calcOptions
      .filter(({ id }) => id.includes('STATE'))
      .map(({ id }) => [id, id.replace(/_STATE$/, '')])
      .map(([stateId, standardId]) => ({
        calcOption: calcOptions.find(({ id }) => id === standardId),
        stateCalcOption: calcOptions.find(({ id }) => id === stateId),
      }))
    const calcTypeIdsWithStateConfig = _.flatten(
      _calcTypesWithStateConfig.map(({ calcOption, stateCalcOption }) => [
        calcOption.id,
        stateCalcOption.id,
      ])
    )
    const _calcTypesWithoutStateConfig = calcOptions.filter(
      ({ id }) => !calcTypeIdsWithStateConfig.includes(id)
    )
    const _calcTypesModified = Array.from(
      new Set(
        calcTypes
          .map((ct) =>
            calcTypeIdsWithStateConfig.includes(ct) ? `PARENT_${ct}` : ct
          )
          .map((ct) => ct.replace(/_STATE$/, ''))
      ).values()
    )
    return [
      _calcTypesWithStateConfig,
      _calcTypesWithoutStateConfig,
      _calcTypesModified,
    ]
  }, [calcOptions, calcTypes])

  const onChangeCustom = (values, childCall = false) => {
    const parentIds = values.filter((v) => v.includes('PARENT'))
    parentIds.forEach((pid) => {
      const childOptionId = pid.replace(/PARENT_/, '')
      if (!childCall) {
        calcTypes.forEach((calcType) => {
          if (calcType.startsWith(childOptionId)) values.push(calcType)
        })
      }
      if (!values.find((v) => v.startsWith(childOptionId))) {
        const calcOptionState = calcOptions.find(
          (calcOption) =>
            calcOption.id.startsWith(childOptionId) &&
            calcOption.id.endsWith('_STATE')
        )
        if (!calcOptionState || calcOptionState.disabled) {
          values.push(childOptionId)
        } else {
          values.push(`${childOptionId}_STATE`)
        }
      }
    })

    onChange(_.uniq(values.filter((v) => !v.includes('PARENT'))))
  }

  return isCheckBoxGroup ? (
    <>
      <CalculatorSettingsDescription />
      <CheckBoxGrp
        data-cy="calculatorSelector"
        onChange={(values) => {
          onChangeCustom(values)
        }}
        value={calcTypesModified}
        disabled={disabled}
        mode="vertical"
      >
        {calcTypesWithStateConfig.map(({ calcOption, stateCalcOption }) => {
          const item = calcOption

          return (
            <CheckboxLabel
              data-cy={`PARENT_${item.id}`}
              value={`PARENT_${item.id}`}
              key={`PARENT_${item.id}`}
            >
              <LabelWithTooltip
                showPopover={item.showPopover}
                text={item.text}
              />
              <br />
              {calcTypesModified.includes(`PARENT_${item.id}`) && (
                <CheckBoxGrp
                  onChange={(values) => {
                    const calcTypesFiltered = calcTypes.filter(
                      (calcType) => !calcType.startsWith(calcOption.id)
                    )
                    if (values.length === 0) {
                      onChangeCustom(
                        [
                          ...calcTypesModified.filter(
                            (calcType) => calcType !== `PARENT_${item.id}`
                          ),
                          ...calcTypesFiltered,
                        ],
                        true
                      )
                    } else {
                      onChangeCustom(
                        [...values, ...calcTypesModified, ...calcTypesFiltered],
                        true
                      )
                    }
                  }}
                  value={calcTypes}
                  mode="horizontal"
                  style={{
                    width: 'max-content',
                    paddingLeft: '1rem',
                    paddingTop: '1rem',
                    paddingBottom: '1rem',
                  }}
                >
                  <CheckboxLabel
                    data-cy={calcOption.id}
                    value={calcOption.id}
                    key={calcOption.id}
                    disabled={[disabled, calcOption.disabled].some(
                      (isDisabled) => isDisabled
                    )}
                  >
                    <LabelWithTooltip
                      showPopover={calcOption.showPopover}
                      text="Standard Calculator"
                    />
                  </CheckboxLabel>
                  <CheckboxLabel
                    data-cy={stateCalcOption.id}
                    value={stateCalcOption.id}
                    key={stateCalcOption.id}
                    disabled={[disabled, stateCalcOption.disabled].some(
                      (isDisabled) => isDisabled
                    )}
                  >
                    <LabelWithTooltip
                      showPopover={stateCalcOption.showPopover}
                      text="State Calculator"
                    />
                  </CheckboxLabel>
                </CheckBoxGrp>
              )}
            </CheckboxLabel>
          )
        })}
        {calcTypesWithoutStateConfig.map((item) => (
          <CheckboxLabel
            data-cy={item.id}
            value={item.id}
            key={item.id}
            disabled={[disabled, item.disabled].some(
              (isDisabled) => isDisabled
            )}
          >
            <LabelWithTooltip showPopover={item.showPopover} text={item.text} />
          </CheckboxLabel>
        ))}
      </CheckBoxGrp>
      <CalculatorSettingsCountDescription count={calcTypes.length} />
    </>
  ) : (
    <CalculatorDropdown
      data-cy="calculatorSelector"
      onChange={onChange}
      value={calcTypes}
      disabled={disabled}
      mode="multiple"
      placeholder="NONE"
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
    >
      {calcOptions.map((item) => (
        <Select.Option
          data-cy={item.id}
          value={item.id}
          key={item.id}
          disabled={[disabled, item.disabled].some((isDisabled) => isDisabled)}
        >
          <LabelWithTooltip showPopover={item.showPopover} text={item.text} />
        </Select.Option>
      ))}
    </CalculatorDropdown>
  )
}

export default withCalcOptions(CalculatorSettings)
