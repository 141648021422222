import { Drawer } from 'antd'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { PASSAGE_AUTHORING_PROMPTS } from '../constants/prompt'
import { resetAIPassageStateAction } from '../ducks/passageGeneration'
import { resetAIPassageQuestionStateAction } from '../ducks/passageQuestionGeneration'
import { getPassageSelector } from '../../ItemDetail/ducks'

const AIPassageAuthoringContainer = ({
  prompt,
  onClose,
  processAndAddPassage,
  processAndAddQuestions,
  resetAIPassageState,
  resetAIPassageQuestionState,
  passages,
}) => {
  useEffect(() => {
    resetAIPassageState()
    resetAIPassageQuestionState()
  }, [])

  return (
    <Drawer
      style={{ zIndex: 1004 }}
      maskClosable={false}
      width="640px"
      onClose={onClose}
      visible={prompt}
      title={prompt?.title}
      placement="right"
    >
      {prompt?.component && (
        <prompt.component
          data={{ passages }}
          onFinish={({ passage, questions, questionType, alignment }) => {
            if (
              prompt.key === PASSAGE_AUTHORING_PROMPTS.PASSAGE_GENERATION.key
            ) {
              processAndAddPassage(passage)
              resetAIPassageState()
            } else if (
              prompt.key === PASSAGE_AUTHORING_PROMPTS.QUESTION_GENERATION.key
            ) {
              processAndAddQuestions(questions, questionType, alignment)
              resetAIPassageQuestionState()
            }
            onClose()
          }}
        />
      )}
    </Drawer>
  )
}

const enhance = compose(
  connect(
    (state) => ({
      passages: getPassageSelector(state)?.data || [],
    }),
    {
      resetAIPassageState: resetAIPassageStateAction,
      resetAIPassageQuestionState: resetAIPassageQuestionStateAction,
    }
  )
)

export default enhance(AIPassageAuthoringContainer)
