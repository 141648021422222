import { FlexContainer, EduButton } from '@edulastic/common'
import IconMagicWand from '@edulastic/icons/src/IconMagicWand'
import { IconSync } from '@edulastic/icons'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import React, { useState } from 'react'
import {
  InlineSVG,
  StyledAIEnhanceQuestionFooter,
  StyledAIEnhanceQuestionRegenerateButton,
  StyledUseOldQuestionAlertModal,
} from '../common/styled'

const AIEnhanceQuestionFooterAction = ({
  status,
  onGenerate,
  onFinish,
  onUseOldQuestion,
}) => {
  const isLoading = status === 'INITIATED'
  const [showUseOldQuestionAlert, setShowUseOldQuestionAlert] = useState(false)

  return status ? (
    <StyledAIEnhanceQuestionFooter>
      <StyledUseOldQuestionAlertModal
        visible={showUseOldQuestionAlert}
        height={166}
        width={442}
        zIndex={1006}
        title={null}
        onOk={() => {
          setShowUseOldQuestionAlert(false)
        }}
        onCancel={() => {
          setShowUseOldQuestionAlert(false)
          onUseOldQuestion()
        }}
        maskClosable={false}
        closable={false}
        centered
        footer={[
          <EduButton
            height="40px"
            isGhost
            key="cancelButton"
            onClick={() => {
              setShowUseOldQuestionAlert(false)
            }}
            data-cy="cancel-discard-ai-question"
          >
            CANCEL
          </EduButton>,
          <EduButton
            height="40px"
            key="okButton"
            onClick={() => {
              setShowUseOldQuestionAlert(false)
              onUseOldQuestion()
            }}
            data-cy="discard-ai-question"
          >
            YES, CONTINUE
          </EduButton>,
        ]}
      >
        <p>
          Are you sure you want to use old question and discard the AI generated
          one?
        </p>
      </StyledUseOldQuestionAlertModal>
      <Row gutter={16}>
        <Col span={9}>
          <StyledAIEnhanceQuestionRegenerateButton
            disabled={isLoading}
            onClick={onGenerate}
            data-cy="btn-regenerate"
          >
            <IconSync height="20px" width="20px" />
            REGENERATE
          </StyledAIEnhanceQuestionRegenerateButton>
        </Col>
        <Col span={15}>
          <FlexContainer
            gap="10px"
            alignItems="center"
            justifyContent="flex-end"
          >
            <EduButton
              width="150px"
              height="34px"
              isGhost
              disabled={isLoading || status === 'FAILED'}
              onClick={() => setShowUseOldQuestionAlert(true)}
              data-cy="btn-use-old-question"
            >
              <span>USE OLD QUESTION</span>
            </EduButton>
            <EduButton
              width="150px"
              height="34px"
              disabled={isLoading || status === 'FAILED'}
              onClick={onFinish}
              data-cy="btn-replace-question"
            >
              <span>REPLACE QUESTION</span>
            </EduButton>
          </FlexContainer>
        </Col>
      </Row>
    </StyledAIEnhanceQuestionFooter>
  ) : (
    <EduButton
      ml="0px"
      width="100%"
      onClick={onGenerate}
      data-cy="btn-generate-question"
    >
      <span>
        <InlineSVG>
          <IconMagicWand fill="white" />
        </InlineSVG>{' '}
        GENERATE
      </span>
    </EduButton>
  )
}

export default AIEnhanceQuestionFooterAction
