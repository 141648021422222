import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import produce from 'immer'

import { withNamespaces } from '@edulastic/localization'
import { getFormattedAttrId } from '@edulastic/common/src/helpers'
import { setQuestionDataAction } from '../../../author/QuestionEditor/ducks'
import { checkAnswerAction } from '../../../author/src/actions/testItem'
import { updateVariables } from '../../utils/variables'

import QuestionTextArea from '../../components/QuestionTextArea'
import { Subtitle } from '../../styled/Subtitle'
import Question from '../../components/Question'

class ComposeQuestion extends Component {
  render() {
    const { item, setQuestionData, t, fillSections, cleanSections } = this.props

    const handleItemChange = (prop, data) => {
      setQuestionData(
        produce(item, (draft) => {
          draft[prop] = data
          updateVariables(draft)
        })
      )
    }

    return (
      <Question
        section="main"
        label={t('component.math.composeQuestion')}
        fillSections={fillSections}
        cleanSections={cleanSections}
      >
        <Subtitle
          id={getFormattedAttrId(
            `${item?.title}-${t('component.math.composeQuestion')}`
          )}
        >
          {t('component.math.composeQuestion')}
        </Subtitle>

        <QuestionTextArea
          placeholder={t('component.math.enterQuestion')}
          onChange={(stimulus) => handleItemChange('stimulus', stimulus)}
          value={item.stimulus}
          border="border"
        />
      </Question>
    )
  }
}

ComposeQuestion.propTypes = {
  setQuestionData: PropTypes.func.isRequired,
  item: PropTypes.object,
  t: PropTypes.func.isRequired,
  fillSections: PropTypes.func,
  cleanSections: PropTypes.func,
}

ComposeQuestion.defaultProps = {
  item: {},
  fillSections: () => {},
  cleanSections: () => {},
}

export default compose(
  withNamespaces('assessment'),
  connect(null, {
    setQuestionData: setQuestionDataAction,
    checkAnswer: checkAnswerAction,
  })
)(ComposeQuestion)
