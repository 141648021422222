import React from 'react'
import withIconStyles from '@edulastic/icons/src/HOC/withIconStyles'
import SVG from '@edulastic/icons/src/common/SVG'

const IconBlockQuote = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="14.899"
    height="12.607"
    viewBox="0 0 14.899 12.607"
    {...props}
  >
    <g transform="translate(0 -36.547)">
      <g transform="translate(0 36.547)">
        <path
          d="M5.158,36.547H1.719a1.658,1.658,0,0,0-1.218.5A1.658,1.658,0,0,0,0,38.266V41.7a1.657,1.657,0,0,0,.5,1.218,1.659,1.659,0,0,0,1.218.5H3.725a.856.856,0,0,1,.86.86v.286a2.209,2.209,0,0,1-.672,1.621,2.209,2.209,0,0,1-1.621.671H1.719a.551.551,0,0,0-.4.17.551.551,0,0,0-.17.4v1.146a.551.551,0,0,0,.17.4.551.551,0,0,0,.4.17h.573a4.467,4.467,0,0,0,1.777-.363,4.618,4.618,0,0,0,1.464-.981,4.62,4.62,0,0,0,.98-1.464,4.466,4.466,0,0,0,.363-1.777v-6.3a1.712,1.712,0,0,0-1.719-1.719Z"
          transform="translate(0 -36.547)"
        />
        <path
          d="M262.192,37.048a1.658,1.658,0,0,0-1.218-.5h-3.438a1.712,1.712,0,0,0-1.719,1.719V41.7a1.712,1.712,0,0,0,1.719,1.719h2.006a.856.856,0,0,1,.86.86v.286a2.293,2.293,0,0,1-2.292,2.292h-.573a.581.581,0,0,0-.573.573v1.146a.581.581,0,0,0,.573.573h.573a4.467,4.467,0,0,0,1.777-.363,4.541,4.541,0,0,0,2.444-2.444,4.466,4.466,0,0,0,.363-1.777v-6.3A1.657,1.657,0,0,0,262.192,37.048Z"
          transform="translate(-247.794 -36.547)"
        />
      </g>
    </g>
  </SVG>
)

export default withIconStyles(IconBlockQuote)
