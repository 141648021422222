/* eslint-disable react/prop-types */
import React from 'react'
import { green } from '@edulastic/colors'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconArrowCircleRight = (props) => (
  <SVG
    height="20"
    width="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="10" cy="10" r="10" fill={green} />
    <path
      d="M9.99935 4.6665L9.05935 5.6065L12.7793 9.33317H4.66602V10.6665H12.7793L9.05935 14.3932L9.99935 15.3332L15.3327 9.99984L9.99935 4.6665Z"
      fill="white"
    />
  </SVG>
)

export default withIconStyles(IconArrowCircleRight)
