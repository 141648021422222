// write react component which take PROMPT_SUGGESTIONS as props and render the list of suggestions as antd tags
import { greenPrimary, lightGrey } from '@edulastic/colors'
import { Tag } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'

const PromptSuggestions = ({
  suggestions,
  onSelect,
  currentPrompt,
  disabled,
}) => {
  const [selectedTagKey, setSelectedTagKey] = useState(null)

  const onTagClick = ({ key, prompt }) => {
    if (!disabled) {
      setSelectedTagKey(key)
      onSelect(prompt)
    }
  }

  return (
    <div>
      Suggestions: {''}
      {suggestions.map((suggestion) => {
        const { key, title } = suggestion
        return (
          <StyledTag
            disabled={disabled}
            data-cy={key}
            active={
              selectedTagKey === key && currentPrompt === suggestion.prompt
            }
            onClick={() => onTagClick(suggestion)}
            key={key}
          >
            {title}
          </StyledTag>
        )
      })}
    </div>
  )
}

export default PromptSuggestions

const StyledTag = styled(Tag)`
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  color: black;
  height: 28px;
  line-height: 26px;
  border-radius: 24px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border: 1px solid ${({ active }) => (active ? greenPrimary : 'inherit')};
  background: ${({ active }) => (active ? '#edf8f7' : lightGrey)};
  margin-bottom: 8px;
`
