import React, { useState } from 'react'
import styled from 'styled-components'
import Divider from 'antd/lib/divider'
import { getFormattedAttrId } from '@edulastic/common/src/helpers'
import { withNamespaces } from '@edulastic/localization'
import { extraDesktopWidthMax } from '@edulastic/colors'
import HelpButton from '@edulastic/common/src/components/HelpButton'
import { CustomModalStyled } from '@edulastic/common'
import { Table } from 'antd'
import { Row } from '../../../../../styled/WidgetOptions/Row'
import { Col } from '../../../../../styled/WidgetOptions/Col'
import { Subtitle } from '../../../../../styled/Subtitle'
import { intructionsTableColumns, intructionsTableData } from '../constants'

const DynamicText = ({ t, item }) => {
  const [helpModalOpen, setHelpModalOpen] = useState(false)
  return (
    <>
      <Row type="flex">
        <Subtitle
          id={getFormattedAttrId(
            `${item?.title}-${t('component.options.dynamicParameters')}`
          )}
          margin="0px"
          showIcon={false}
        >
          {t('component.options.dynamicParameters')}
        </Subtitle>
        <Divider type="vertical" style={{ height: 30 }} />
        <HelpButton onClick={() => setHelpModalOpen(true)} />
      </Row>
      <br />
      <Row gutter={24}>
        <Col md={24}>
          <Text>{t('component.options.dynamicParametersDescription')}</Text>
        </Col>
      </Row>
      <DynamicHelpModal visible={helpModalOpen} setVisible={setHelpModalOpen} />
    </>
  )
}

export default withNamespaces('assessment')(DynamicText)

const DynamicHelpModal = ({ visible, setVisible }) => (
  <CustomModalStyled
    visible={visible}
    footer={null}
    title="Dynamic Parameters Overview"
    width="900px"
    height="80%"
    onCancel={() => setVisible(false)}
  >
    <div className="content">
      <Content>
        <b>Number Range:</b>
        <span>
          Here a random value is generated between the ranges specified. Specify
          the range using the min and max option. You can also specify the
          number of decimal places
        </span>
      </Content>
      <Content>
        <b>Number set:</b>
        <span>
          You can specify a set of numbers, and the values generated are numbers
          that are randomly assigned from the set
        </span>
      </Content>
      <Content>
        <span>
          This option is useful with other dynamic parameters of sequence type.
          All variables of sequence type need to have the same number of items.
          The values generated are numbers/text picked out from each position in
          the sequence
        </span>
      </Content>
      <Content>
        <b>Text set:</b>
        <span>
          You can specify a set of words (or any text), and the values generated
          are words that are randomly assigned from the set
        </span>
      </Content>
      <Content>
        <b>Formula:</b>
        <span>
          You can specify a formula using other dynamic variables generated
          earlier. We recommened you use this as the variable in the input box
          because you can use the advanced math settings to control the formula
          output. Example: if you want the student&apos;s answer to be a
          fraction, decimal, etc.
        </span>
      </Content>
      <Content>
        <span>Examples to enter Latex for science and Math</span>
      </Content>
      <Row>
        <Col md={18}>
          <Table
            showHeader={false}
            columns={intructionsTableColumns}
            dataSource={intructionsTableData}
            bordered
            pagination={false}
          />
        </Col>
      </Row>
    </div>
  </CustomModalStyled>
)

const Text = styled.div`
  font-size: ${(props) => props.theme.smallFontSize};

  @media (min-width: ${extraDesktopWidthMax}) {
    font-size: ${(props) => props.theme.widgetOptions.labelFontSize};
  }
`
const Content = styled.div`
  margin-bottom: 10px;
  b {
    font-size: 14px;
    font-weight: 600;
    margin-right: 5px;
  }
`
