import styled from 'styled-components'

export const StyledDiv = styled.div`
  background-color: #858694;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1 0 auto;
`

export const StyledDivF = styled.div`
  width: 100%;
  margin-bottom: 1px;
  flex: 1 0 auto;
  display: flex;
  min-height: 50px;
`
