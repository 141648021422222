import React from 'react'
import { StyledAdptiveTestInfoModal } from '../styled-components'
import SectionsAdaptiveTestInfoContent from './SectionsAdaptiveTestInfoContent'

const SectionsAdaptiveTestInfoModal = ({ visible, onCancel }) => {
  return (
    <StyledAdptiveTestInfoModal
      visible={visible}
      footer={null}
      title="Multi-Stage Adaptive Test Overview"
      width="900px"
      height="80%"
      onCancel={onCancel}
    >
      <SectionsAdaptiveTestInfoContent />
    </StyledAdptiveTestInfoModal>
  )
}

export default SectionsAdaptiveTestInfoModal
