import API from './utils/API'

const prefix = '/ai'
const api = new API()

const generateRewrittenContent = (data) =>
  api
    .callApi({
      useSlowApi: true,
      method: 'post',
      url: `${prefix}/rewrite`,
      data,
    })
    .then((result) => result.data)

const generateTranslatedContent = (data) =>
  api
    .callApi({
      useSlowApi: true,
      method: 'post',
      url: `${prefix}/translate`,
      data,
    })
    .then((result) => result.data)

const generatePassage = (data) =>
  api
    .callApi({
      useSlowApi: true,
      method: 'post',
      url: `${prefix}/passage`,
      data,
    })
    .then((result) => result.data)

const importQuestion = (data) =>
  api
    .callApi({
      useSlowApi: true,
      method: 'post',
      url: `${prefix}/question/import`,
      data,
    })
    .then((result) => result.data)

const enhanceAIQuestion = (data) =>
  api
    .callApi({
      useSlowApi: true,
      method: 'post',
      url: `${prefix}/question/update`,
      data,
    })
    .then((result) => {
      const requestId = result?.headers?.['x-server-ref'] || 'NA'
      return { result: result?.data?.result, requestId }
    })

const submitAIResponseFeedback = (data) =>
  api.callApi({
    method: 'post',
    url: `${prefix}/feedback`,
    data,
  })

export default {
  generateRewrittenContent,
  generateTranslatedContent,
  generatePassage,
  importQuestion,
  enhanceAIQuestion,
  submitAIResponseFeedback,
}
