/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconMute = (props) => (
  <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <path
      d="M3.61641 2.44531L2.44141 3.62031L6.07474 7.25365L5.83307 7.50365H2.49974V12.5036H5.83307L9.99974 16.6703V11.1786L13.4831 14.662C12.9414 15.0703 12.3331 15.3953 11.6664 15.587V17.3036C12.7831 17.0536 13.8081 16.537 14.6747 15.8453L16.3831 17.5536L17.5581 16.3786L3.61641 2.44531ZM8.33307 12.6453L6.52474 10.837H4.16641V9.17031H6.52474L7.25807 8.43698L8.33307 9.51198V12.6453ZM15.8331 10.0036C15.8331 10.687 15.7081 11.3453 15.4914 11.9536L16.7664 13.2286C17.2331 12.2536 17.4997 11.162 17.4997 10.0036C17.4997 6.43698 15.0081 3.45365 11.6664 2.69531V4.41198C14.0747 5.12865 15.8331 7.36198 15.8331 10.0036ZM9.99974 3.33698L8.43307 4.90365L9.99974 6.47031V3.33698ZM13.7497 10.0036C13.7497 8.52865 12.8997 7.26198 11.6664 6.64531V8.13698L13.7331 10.2036C13.7414 10.137 13.7497 10.0703 13.7497 10.0036Z"
      fill="#474747"
    />
  </SVG>
)

export default withIconStyles(IconMute)
