import React, { useEffect } from 'react'
import { Switch, Tooltip } from 'antd'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { FlexContainer } from '@edulastic/common'
import { IconAutoAssign, IconInfoCircle } from '@edulastic/icons'
import { gray600, themeColor } from '@edulastic/colors'
import {
  getAutoAssignSelector,
  getIsNoFiltersAppliedSelector,
  isAutoAssignDiabledSelector,
  setAutoAssignAction,
  setIsAllClassesSelectedAction,
} from '../../../TestPage/components/Assign/ducks'

const AutoAssignToggle = (props) => {
  const {
    autoAssign,
    setAutoAssign,
    isAutoAssignDiabled,
    isAllClassesSelected,
    setIsAllClassesSelected,
    isNoFiltersApplied,
  } = props

  const handleOnChange = (checked) => {
    setAutoAssign(checked)
  }

  useEffect(() => {
    setIsAllClassesSelected(isAllClassesSelected)
  }, [isAllClassesSelected])

  const disabledMessage = isNoFiltersApplied
    ? 'Auto Assign cannot be enabled without filter'
    : 'Auto Assign cannot be enabled with classes filter applied'

  return (
    <FlexContainer
      justifyContent="flex-start"
      alignItems="center"
      gap="10px"
      marginBottom="14px"
    >
      <IconAutoAssign fillColor={isAutoAssignDiabled ? gray600 : '#9501DB'} />
      <StyledText data-cy="autoAssignMessage">
        {isAutoAssignDiabled ? disabledMessage : 'Auto Assign New Classes'}
      </StyledText>
      {!isAutoAssignDiabled && (
        <>
          <Tooltip
            title={
              <TooltipText>
                Auto assign will add future classes that meet the filter
                criteria to this assignment.
              </TooltipText>
            }
            placement="bottom"
            overlayStyle={{ maxWidth: '500px' }}
          >
            <IconInfoCircle
              height="16"
              width="16"
              fillColor="#777777"
              data-cy="autoAssignInfoIcon"
            />
          </Tooltip>
          <StyledSwitch
            checked={autoAssign}
            onChange={handleOnChange}
            data-cy="autoAssignToggleButton"
          />
        </>
      )}
    </FlexContainer>
  )
}

export default connect(
  (state) => ({
    autoAssign: getAutoAssignSelector(state),
    isAutoAssignDiabled: isAutoAssignDiabledSelector(state),
    isNoFiltersApplied: getIsNoFiltersAppliedSelector(state),
  }),
  {
    setAutoAssign: setAutoAssignAction,
    setIsAllClassesSelected: setIsAllClassesSelectedAction,
  }
)(AutoAssignToggle)

const StyledSwitch = styled(Switch)`
  &:hover {
    cursor: pointer;
  }
  &.ant-switch-checked {
    background: ${themeColor};
  }
`

const StyledText = styled.p`
  font-size: 12px;
  font-weight: 600;
`

const TooltipText = styled.p`
  text-align: left;
`
