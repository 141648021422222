import React from 'react'

export default () => {
  return (
    <video controls width="250">
      <track kind="captions" />
      <source src="" type="video/mp4" />
    </video>
  )
}
