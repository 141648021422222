import React from 'react'
import withIconStyles from '@edulastic/icons/src/HOC/withIconStyles'
import SVG from '@edulastic/icons/src/common/SVG'

const IconGraphPiecewiseLine = (props) => (
  <SVG
    width="44px"
    height="26px"
    viewBox="0 0 44 26"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g stroke="none" strokeWidth="1" fillRule="evenodd">
      <g transform="translate(0.000088, 0.669000)">
        <line
          x1="3.49991156"
          y1="18.831"
          x2="42.4999116"
          y2="18.831"
          stroke="#C1C3C9"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="bevel"
        />
        <path
          d="M4.54380987,20.9850294 C4.27861505,20.9850294 4.0243026,20.8795788 3.83680987,20.6920294 C3.44642793,20.3015295 3.44642793,19.6685292 3.83680987,19.2780294 L18.9438099,4.16802939 C19.3343097,3.77764745 19.96731,3.77764745 20.3578099,4.16802939 C20.7481918,4.55852924 20.7481918,5.19152954 20.3578099,5.58202939 L5.25080987,20.6920294 C5.06331713,20.8795788 4.80900468,20.9850294 4.54380987,20.9850294 Z"
          fill="#C1C3C9"
          fillRule="nonzero"
          transform="translate(12.097310, 12.430136) rotate(-45.000000) translate(-12.097310, -12.430136) "
        />
        <path
          d="M14.6839878,13.0980762 C16.340842,13.0980762 17.6839878,14.441222 17.6839878,16.0980762 C17.6839878,17.7549305 16.340842,19.0980762 14.6839878,19.0980762 C13.0271335,19.0980762 11.6839878,17.7549305 11.6839878,16.0980762 C11.6839878,14.441222 13.0271335,13.0980762 14.6839878,13.0980762 Z"
          fillRule="nonzero"
          transform="translate(14.683988, 16.098076) rotate(30.000000) translate(-14.683988, -16.098076) "
        />
        <path
          d="M23.6439878,4.09807621 C25.300842,4.09807621 26.6439878,5.44122196 26.6439878,7.09807621 C26.6439878,8.75493046 25.300842,10.0980762 23.6439878,10.0980762 C21.9871335,10.0980762 20.6439878,8.75493046 20.6439878,7.09807621 C20.6439878,5.44122196 21.9871335,4.09807621 23.6439878,4.09807621 Z"
          fillRule="nonzero"
          transform="translate(23.643988, 7.098076) rotate(30.000000) translate(-23.643988, -7.098076) "
        />
        <path
          d="M15.0459116,17.098 C14.7807167,17.098 14.5264043,16.9925494 14.3389116,16.805 C13.9485296,16.4145001 13.9485296,15.7814999 14.3389116,15.391 L23.3389116,6.391 C23.7294114,6.00061806 24.3624117,6.00061806 24.7529116,6.391 C25.1432935,6.78149985 25.1432935,7.41450015 24.7529116,7.805 L15.7529116,16.805 C15.5654188,16.9925494 15.3111064,17.098 15.0459116,17.098 Z"
          fillRule="nonzero"
        />
        <path
          d="M30.6839878,10.0980762 C32.340842,10.0980762 33.6839878,11.441222 33.6839878,13.0980762 C33.6839878,14.7549305 32.340842,16.0980762 30.6839878,16.0980762 C29.0271335,16.0980762 27.6839878,14.7549305 27.6839878,13.0980762 C27.6839878,11.441222 29.0271335,10.0980762 30.6839878,10.0980762 Z"
          fillRule="nonzero"
          transform="translate(30.683988, 13.098076) rotate(30.000000) translate(-30.683988, -13.098076) "
        />
        <path
          d="M39.6439878,1.09807621 C41.300842,1.09807621 42.6439878,2.44122196 42.6439878,4.09807621 C42.6439878,5.75493046 41.300842,7.09807621 39.6439878,7.09807621 C37.9871335,7.09807621 36.6439878,5.75493046 36.6439878,4.09807621 C36.6439878,2.44122196 37.9871335,1.09807621 39.6439878,1.09807621 Z"
          fillRule="nonzero"
          transform="translate(39.643988, 4.098076) rotate(30.000000) translate(-39.643988, -4.098076) "
        />
        <path
          d="M31.0459116,14.098 C30.7807167,14.098 30.5264043,13.9925494 30.3389116,13.805 C29.9485296,13.4145001 29.9485296,12.7814999 30.3389116,12.391 L39.3389116,3.391 C39.7294114,3.00061806 40.3624117,3.00061806 40.7529116,3.391 C41.1432935,3.78149985 41.1432935,4.41450015 40.7529116,4.805 L31.7529116,13.805 C31.5654188,13.9925494 31.3111064,14.098 31.0459116,14.098 Z"
          fillRule="nonzero"
        />
      </g>
    </g>
  </SVG>
)

export default withIconStyles(IconGraphPiecewiseLine)
