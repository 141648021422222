import { Tooltip } from 'antd'
import React from 'react'
import { withNamespaces } from 'react-i18next'
import { AddMoreStandardsButton } from './styled/AddMoreStandardsButton'

const AddMoreStandards = ({ pushNewAlignment, alignment, t: i18Translate }) => {
  const isDisabled = (alignment || []).length >= 5
  return (
    <Tooltip
      placement="top"
      title={isDisabled ? 'Only 5 standard sets can be added' : ''}
    >
      <AddMoreStandardsButton
        onClick={pushNewAlignment}
        color="primary"
        variant="extendedFab"
        data-cy="addMoreStandards"
        disabled={isDisabled}
      >
        {`+ ${i18Translate('component.options.addMoreStandards')}`}
      </AddMoreStandardsButton>
    </Tooltip>
  )
}

export default withNamespaces('assessment')(AddMoreStandards)
