import { takeLatest, call, all, put } from 'redux-saga/effects'
import { userContextApi } from '@edulastic/api'
import { takeEvery } from 'redux-saga'
import _ from 'lodash'
import * as Sentry from '@sentry/browser'
import {
  GET_VIEWED_TUTORIALS_FAILURE,
  GET_VIEWED_TUTORIALS_REQUEST,
  GET_VIEWED_TUTORIALS_SUCCESS,
  SET_VIEWED_TUTORIALS_FAILURE,
  SET_VIEWED_TUTORIALS_REQUEST,
  SET_VIEWED_TUTORIALS_SUCCESS,
} from './tutorialReducer'

function* getViewedTutorials() {
  try {
    const viewedTutorials = yield call(userContextApi.getViewedTutorials)
    yield put({
      type: GET_VIEWED_TUTORIALS_SUCCESS,
      payload: viewedTutorials?.value ?? [],
    })
  } catch (err) {
    Sentry.captureException(err)
    yield put({
      type: GET_VIEWED_TUTORIALS_FAILURE,
      payload: err,
    })
  }
}

function* setViewedTutorials({ payload }) {
  try {
    yield call(userContextApi.storeViewedTutorials, _.uniq(payload))
    yield put({
      type: SET_VIEWED_TUTORIALS_SUCCESS,
      payload: _.uniq([...payload]),
    })
  } catch (err) {
    Sentry.captureException(err)
    yield put({
      type: SET_VIEWED_TUTORIALS_FAILURE,
      payload: err,
    })
  }
}

export function* watcherSaga() {
  yield all([
    takeLatest(GET_VIEWED_TUTORIALS_REQUEST, getViewedTutorials),
    takeEvery(SET_VIEWED_TUTORIALS_REQUEST, setViewedTutorials),
  ])
}
