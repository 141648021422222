export const initialAlignmentData = {
  curriculum: '',
  curriculumId: null,
  domains: [],
  grades: [],
  subject: '',
  standards: [],
}

export const testFromDocInitialData = {
  cdnUrl: '',
  fileName: '',
  alignment: { ...initialAlignmentData },
}
