import styled from 'styled-components'
import { Menu } from 'antd'
import { themeColorBlue } from '@edulastic/colors'
import { EduButton } from '@edulastic/common'

export const StyledPopoverContent = styled.div`
  border-radius: 4px;
`

export const StyledPopoverInnerContainer = styled.div`
  padding: 0px;
  align-items: center !important;
  display: flex;
  height: inherit;

  svg {
    height: 12px;
  }
`

export const StyledMenuItem = styled(Menu.Item)`
  margin: 0 0 !important;

  &:hover {
    color: white;
    background: ${themeColorBlue};

    svg path,
    circle {
      fill: white;
    }
  }

  div {
    display: inline-flex;
    align-items: center;
    width: 100%;
  }
`

export const StyledButtonInnerContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  height: inherit;
`

export const StyledEduButton = styled(EduButton)`
  padding-right: ${({ showQuickLinks }) =>
    showQuickLinks ? '0px' : undefined};
`
