export const isGoogleDocsUrl = (url) => {
  const googleDocsUrlRegex = /^(https:\/\/)?docs\.google\.com\/(document|spreadsheets|presentation|forms)\/d\/[a-zA-Z0-9_-]+/
  return googleDocsUrlRegex.test(url)
}

export const isValidUrl = (url) => {
  try {
    return !!new URL(url)
  } catch (error) {
    return false
  }
}
