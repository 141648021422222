/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconProtactor = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25.323 25.323"
    {...props}
  >
    <g id="protactor-measuring" transform="translate(0 -7.037)">
      <path
        id="Path_49"
        d="M25.323 11.77a.592.592 0 0 0-.173-.418 14.622 14.622 0 0 0-8.077-4.13l-.05-.01h-.047a14.95 14.95 0 0 0-4.494 0 .609.609 0 0 0-.068.007.6.6 0 0 0-.068.015 14.572 14.572 0 0 0-4.295 1.4l-.02.009-.026.015a14.764 14.764 0 0 0-3.624 2.632.6.6 0 0 0-.078.065.589.589 0 0 0-.066.079 14.762 14.762 0 0 0-2.628 3.626l-.013.022-.008.017a14.573 14.573 0 0 0-1.4 4.3.553.553 0 0 0-.022.135 14.951 14.951 0 0 0 0 4.494c0 .016 0 .032.005.048s.007.037.011.054a14.623 14.623 0 0 0 4.127 8.056.592.592 0 0 0 .837 0l20-20a.592.592 0 0 0 .177-.416zm-10.591 9.162l-1-1a.592.592 0 0 0-.837.837l1 1-9.153 9.153a13.485 13.485 0 0 1-1.8-2.48l.708-.361a.592.592 0 0 0-.537-1.055l-.708.361a13.519 13.519 0 0 1-.942-2.916l.777-.123a.592.592 0 1 0-.185-1.169l-.776.123a13.668 13.668 0 0 1 0-3.062l.775.123a.592.592 0 1 0 .185-1.169l-.776-.123a13.518 13.518 0 0 1 .937-2.914l.7.358a.592.592 0 1 0 .537-1.055l-.7-.359a13.484 13.484 0 0 1 1.8-2.478l.557.557a.592.592 0 1 0 .837-.837l-.558-.558a13.575 13.575 0 0 1 2.475-1.808l.361.708a.592.592 0 0 0 1.055-.537L9.1 9.439a13.4 13.4 0 0 1 2.915-.947l.124.784a.592.592 0 0 0 1.169-.185l-.124-.782a13.706 13.706 0 0 1 3.064 0l-.124.783a.592.592 0 1 0 1.169.185l.125-.786a13.4 13.4 0 0 1 2.918.941l-.363.713a.592.592 0 1 0 1.055.537l.363-.713a13.572 13.572 0 0 1 2.492 1.815z"
        className="cls-1"
        data-name="Path 49"
      />
      <path
        id="Path_50"
        d="M82.628 75.812a10.711 10.711 0 0 0-13.9 13.9.592.592 0 0 0 .969.2L82.83 76.781a.592.592 0 0 0-.2-.969zm-13.1 12.593a9.534 9.534 0 0 1 11.793-11.788z"
        className="cls-1"
        data-name="Path 50"
        transform="translate(-63.956 -64.003)"
      />
    </g>
  </SVG>
)

export default withIconStyles(IconProtactor)
