import { Link } from 'react-router-dom'
import styled from 'styled-components'
import CardBackgroundImg from '../../../../student/assets/Create-test-card.png'

export const Container = styled.div`
  background: #1a4a41;
  padding: 40px 100px 32px;
`
export const StyledImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`
export const MessageText = styled.p`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  margin-bottom: 6px;
`
export const SecondaryText = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #ffffffb3;
`

export const TitleText = styled.p`
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  color: #ffffff;
  margin-bottom: 12px;
`

const StyledCardStyle = `
  cursor: pointer;
  width: 100%;
  height: 180px;
  gap: 0px;
  border-radius: 8px;
  border: 1px 0px 0px 0px;
  box-shadow: 0px 1px 5px 0px #00000014;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`
export const StyledCardLink = styled(Link)`
  ${StyledCardStyle}
`

export const StyledCardDiv = styled.div`
  ${StyledCardStyle}
`

export const UpperContainer = styled.div`
  background: url(${CardBackgroundImg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 87px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  top: 257px;
  border: 1px solid #eeeeee;
  border-radius: 50%;
`

export const LowerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 14px;
  height: 93px;
`

export const CardText = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: #000;
`

export const CardSubtext = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: #555555;
  text-align: center;
`
