import { isEmpty } from 'lodash'
import { notification } from '@edulastic/common'

export const isTestFromDocDataValidAndNotify = (data = {}) => {
  const { alignment: { subject = '', grades = [] } = {} } = data || {}
  if (isEmpty(subject)) {
    notification({
      type: 'warn',
      messageKey: 'subjectFieldEmpty',
    })
    return false
  }
  if (isEmpty(grades)) {
    notification({
      type: 'warn',
      messageKey: 'gradeFieldEmpty',
    })
    return false
  }
  return true
}

export const updateMetaData = (questionsAndMetaData = {}) => {
  const { metaData = {}, questions = {} } = questionsAndMetaData || {}
  let questionWithoutStandardsCount = 0
  let importedQuestionCount = 0

  Object.keys(questions || {}).forEach((itemType) => {
    const itemTypeQuestions = questions?.[itemType] || {}
    const itemTypeImportedQuestionCount = itemTypeQuestions?.length || 0
    importedQuestionCount += itemTypeImportedQuestionCount
    if (importedQuestionCount) {
      itemTypeQuestions.forEach((question) => {
        if (isEmpty(question?.commonCoreStandard)) {
          questionWithoutStandardsCount += 1
        }
      })
    }
  })

  return { ...metaData, importedQuestionCount, questionWithoutStandardsCount }
}
