import { push } from 'react-router-redux'
import { cloneDeep, get, isEmpty, uniq } from 'lodash'
import uuid from 'uuid/v4'
import { testItemsApi } from '@edulastic/api'

import { all, put, select, takeLatest, call } from 'redux-saga/effects'
import { captureSentryException, notification } from '@edulastic/common'
import { aiTestActions } from '.'
import { STATUS } from './constants'
import {
  clearCartTestDataAction,
  getCartTestSelector,
  getTestEntitySelector,
  setTestDataAction,
} from '../../../../TestPage/ducks'
import {
  getAlignmentDataForAiQuestions,
  getExistingQuestionContents,
  processAiGeneratedItems,
} from './helpers'
import { isPremiumUserSelector } from '../../../../src/selectors/user'
import { AI_ERROR_MAP } from '../../../../AIFeatures/constants/form'

function getProcessedItems({
  aiGeneratedQuestions,
  alignment,
  grades,
  subject,
  aiDocExtracted,
  existingQidToRegenerate,
}) {
  // flatting question to maintain order using question number
  const flatMapAiGeneratedQuestionsWithType = Object.entries(
    aiGeneratedQuestions
  )
    .map(([key, value]) =>
      value.map((item) => ({ ...item, questionType: key }))
    )
    .reduce((acc, val) => acc.concat(val), [])
    .sort((a, b) => (a?.questionNumber || 0) - (b?.questionNumber || 0))

  return flatMapAiGeneratedQuestionsWithType.reduce(
    (items, { questionType, ...question }) => {
      const params = {
        questions: [question],
        questionType,
        alignmentData: alignment,
        grades: uniq([...grades]),
        subjects: [subject],
      }
      if (aiDocExtracted) {
        params.aiDocExtracted = aiDocExtracted
      }
      if (existingQidToRegenerate) {
        params.existingQidToRegenerate = existingQidToRegenerate
      }
      const processedItems = processAiGeneratedItems(params)
      items = [...items, ...processedItems]
      return items
    },
    []
  )
}

export function* processAiGeneratedTestItemsSaga({
  aiGeneratedQuestions,
  testName,
  grades,
  subject,
  alignment,
  existingQidToRegenerate = undefined,
  assessment,
  groupIndex,
  savePreselected,
  aiDocExtracted = false,
}) {
  if (!isEmpty(aiGeneratedQuestions)) {
    let existingTestItems = []
    /** Unsaved test */
    if ((assessment._id || '').length !== 24 && !existingQidToRegenerate) {
      if (savePreselected) {
        // this condition is to apply selected items from cart along with AI generated items
        existingTestItems = get(
          assessment,
          `itemGroups.${groupIndex}.items`,
          []
        )
        assessment.savePreselected = true
        yield put(clearCartTestDataAction())
      }

      const aiGeneratedProcessedItems = getProcessedItems({
        aiGeneratedQuestions,
        alignment,
        grades,
        subject,
        aiDocExtracted,
      })

      const isPremiumUser = yield select(isPremiumUserSelector)

      yield put(
        setTestDataAction({
          ...assessment,
          showHintsToStudents: !isPremiumUser,
          title: testName || assessment.title,
          grades: uniq([...assessment.grades, ...grades]),
          subjects: uniq([...assessment.subjects, subject]),
          itemGroups: [
            {
              type: 'STATIC',
              groupName: 'SECTION 1',
              items: [...existingTestItems, ...aiGeneratedProcessedItems],
              deliveryType: 'ALL',
              _id: uuid(),
              index: 0,
              tags: [],
            },
          ],
        })
      )
      yield put(aiTestActions.setStatus(STATUS.SUCCESS))
      yield put(push('/author/tests/create/review'))
    } else if (existingQidToRegenerate) {
      existingTestItems = get(assessment, `itemGroups.${groupIndex}.items`)
      const indexToReplace = existingTestItems
        .map(({ _id }) => _id)
        .indexOf(existingQidToRegenerate)
      const testItems = cloneDeep(existingTestItems)

      const regeneratedProcessedItems = getProcessedItems({
        aiGeneratedQuestions,
        alignment,
        grades,
        subject,
        existingQidToRegenerate,
      })

      const [regeneratedItem] = regeneratedProcessedItems
      if (indexToReplace > -1) {
        testItems.splice(indexToReplace, 1, regeneratedItem)
      }

      assessment.itemGroups[groupIndex].items = testItems

      yield put(
        setTestDataAction({
          ...assessment,
          grades: uniq([...assessment.grades, ...grades]),
          subjects: uniq([...assessment.subjects, subject]),
        })
      )
      yield put(aiTestActions.setStatus(STATUS.SUCCESS))
    } else {
      existingTestItems = get(assessment, `itemGroups.${groupIndex}.items`)
      const aiGeneratedProcessedItems = getProcessedItems({
        aiGeneratedQuestions,
        alignment,
        grades,
        subject,
      })

      assessment.itemGroups[groupIndex].items = [
        ...existingTestItems,
        ...aiGeneratedProcessedItems,
      ]

      yield put(
        setTestDataAction({
          ...assessment,
          grades: uniq([...assessment.grades, ...grades]),
          subjects: uniq([...assessment.subjects, subject]),
        })
      )
      yield put(aiTestActions.setStatus(STATUS.SUCCESS))
      yield put(push(`/author/tests/tab/review/id/${assessment._id}`))
    }
  } else {
    yield put(aiTestActions.setStatus(STATUS.FAILED))
    notification({
      type: 'error',
      messageKey: 'generateAiQuestionsFailed',
    })
  }
}

function* regenerateAiTestItemsSaga({ payload }) {
  try {
    const {
      itemType = 'trueOrFalse',
      numberOfItems = 1,
      dok,
      difficulty,
      alignment = [],
      preference,
      existingQidToRegenerate,
      groupIndex,
    } = payload

    const {
      grades,
      subject,
      standardIds: commonCoreStandards,
      standardSet,
      standardDescriptions: commonCoresStandardDescriptions,
    } = getAlignmentDataForAiQuestions(alignment)

    const testEntity = yield select(getTestEntitySelector)

    const assessment = cloneDeep(testEntity)
    assessment.aiGenerated = true

    const existingQuestions = getExistingQuestionContents(assessment)

    const requestBody = {
      count: numberOfItems,
      questionType: itemType,
      ...(!isEmpty(standardSet) && { standardSet }),
      depthsOfKnowledge: dok,
      difficultLevels: difficulty,
      commonCoreStandards,
      commonCoresStandardDescriptions,
      grades,
      subject,
      ...(!isEmpty(preference) && { preference }),
      existingQuestions,
    }

    const { result } = yield call(
      testItemsApi.generateQuestionViaAI,
      requestBody
    )

    if (!isEmpty(result)) {
      const [aiGeneratedQuestion] = result
      yield* processAiGeneratedTestItemsSaga({
        aiGeneratedQuestions: { [itemType]: [aiGeneratedQuestion] },
        existingQidToRegenerate,
        grades,
        subject,
        alignment,
        assessment,
        groupIndex,
      })
    } else {
      notification({
        type: 'error',
        messageKey: 'generateAiQuestionsFailed',
      })
      yield put(aiTestActions.setStatus(STATUS.FAILED))
    }
  } catch (error) {
    const errMsg = error?.response?.data?.message
    const aiErrorMessage = AI_ERROR_MAP?.[errMsg]
    if (errMsg) {
      notification({
        type: 'error',
        exact: !!aiErrorMessage,
        msg: aiErrorMessage || errMsg,
      })
    } else {
      notification({
        type: 'error',
        messageKey: 'generateAiQuestionsFailed',
      })
    }
    captureSentryException(error)

    yield put(aiTestActions.setStatus(STATUS.FAILED))
  }
}

function* updateAITestItemSaga({ payload }) {
  const {
    itemType = 'trueOrFalse',
    aiGeneratedQuestion,
    existingQidToRegenerate,
    alignment = [{}],
    grades = [],
    subjects = [],
  } = payload

  const testEntity = yield select(getTestEntitySelector)

  const assessment = cloneDeep(testEntity)

  const foundItemGroup = assessment.itemGroups.find((itemGroup) => {
    return itemGroup.items.find((item) => {
      if (item._id === existingQidToRegenerate) {
        return true
      }
      return false
    })
  })

  yield* processAiGeneratedTestItemsSaga({
    aiGeneratedQuestions: { [itemType]: [aiGeneratedQuestion] },
    existingQidToRegenerate,
    grades,
    subject: subjects[0],
    alignment,
    assessment,
    groupIndex: foundItemGroup?.index || 0,
  })
}

function* getAiGeneratedTestItemsSaga({ payload }) {
  try {
    /** call api with given payload to get AiGeneratedTestItems */
    const {
      testName,
      itemType,
      numberOfItems,
      dok,
      difficulty,
      alignment = [],
      preference,
      groupIndex,
      savePreselected,
      attachments = [],
    } = payload

    const {
      grades,
      subject,
      standardIds: commonCoreStandards,
      standardSet,
      standardDescriptions: commonCoresStandardDescriptions,
    } = getAlignmentDataForAiQuestions(alignment)

    const testEntity = savePreselected
      ? yield select(getCartTestSelector)
      : yield select(getTestEntitySelector)

    const assessment = cloneDeep(testEntity)
    assessment.aiGenerated = true

    const existingQuestions = getExistingQuestionContents(assessment)

    const requestBody = {
      count: numberOfItems,
      questionType: itemType,
      ...(!isEmpty(standardSet) && { standardSet }),
      depthsOfKnowledge: dok,
      difficultLevels: difficulty,
      commonCoreStandards,
      grades,
      subject,
      ...(!isEmpty(preference) && { preference }),
      existingQuestions,
      commonCoresStandardDescriptions,
      ...(attachments?.length > 0 && { attachments }),
    }

    const { result } = yield call(
      testItemsApi.generateQuestionViaAI,
      requestBody
    )
    if (!isEmpty(result))
      yield* processAiGeneratedTestItemsSaga({
        aiGeneratedQuestions: { [itemType]: result },
        testName,
        grades,
        subject,
        alignment,
        assessment,
        groupIndex,
        savePreselected,
      })
  } catch (error) {
    const errMsg = error?.response?.data?.message
    const aiErrorMessage = AI_ERROR_MAP?.[errMsg]
    if (errMsg) {
      notification({
        type: 'error',
        exact: !!aiErrorMessage,
        msg: aiErrorMessage || errMsg,
      })
    } else {
      notification({
        type: 'error',
        messageKey: 'generateAiQuestionsFailed',
      })
    }
    captureSentryException(error)

    yield put(aiTestActions.setStatus(STATUS.FAILED))
  }
}

export default function* watcherSaga() {
  yield all([
    yield takeLatest(
      aiTestActions.getAiGeneratedTestItems,
      getAiGeneratedTestItemsSaga
    ),
    yield takeLatest(
      aiTestActions.regenerateAiTestItems,
      regenerateAiTestItemsSaga
    ),
    yield takeLatest(aiTestActions.updateAITestItem, updateAITestItemSaga),
  ])
}
