import styled from 'styled-components'
import { cardBg, cardBorder, themeColorBlue } from '@edulastic/colors'

export const ImageContainer = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 1px solid #d8d8d8;
  background-color: transparent;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 2px 2px 6px;
`

export const MainContainer = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`

export const ImageLabelContainer = styled.div`
  display: flex;
  flex-direction: column; // Align items horizontally
  justify-content: center; // Center items horizontally
  align-items: center; // Align items vertically
  width: 90px;
  font-weight: 600;

  svg {
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  }

  ${({ disabled, popoverVisible }) => {
    const hoverStyle = `
      div {
        background-color: ${themeColorBlue};
        border: none;
      }
      svg path,
      circle {
        fill: white;
      }
      .ant-typography {
        color: ${themeColorBlue};
      }
    `
    return (
      !disabled && (popoverVisible ? hoverStyle : `&:hover {${hoverStyle}}`)
    )
  }}
`

export const StyledRoundDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${cardBg};
  border: 1px solid ${cardBorder};
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.3s ease;
  min-height: 190px;
`

export const StyledPopoverContent = styled.div`
  margin: -20px -20px;
  cursor: pointer;
`
