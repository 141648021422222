/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconImport = (props) => (
  <SVG
    width="22"
    height="28"
    viewBox="0 0 22 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.8806 7.88002L14.4406 1.44002C13.9473 0.946687 13.2673 0.666687 12.5607 0.666687H3.00065C1.53398 0.666687 0.347318 1.86669 0.347318 3.33335L0.333984 24.6667C0.333984 26.1334 1.52065 27.3334 2.98732 27.3334H19.0007C20.4673 27.3334 21.6673 26.1334 21.6673 24.6667V9.77335C21.6673 9.06669 21.3873 8.38669 20.8806 7.88002ZM14.734 18H12.334V22C12.334 22.7334 11.734 23.3334 11.0007 23.3334C10.2673 23.3334 9.66732 22.7334 9.66732 22V18H7.28065C6.68065 18 6.38732 17.28 6.81398 16.8667L10.5473 13.1467C10.814 12.8934 11.2273 12.8934 11.494 13.1467L15.214 16.8667C15.614 17.28 15.3207 18 14.734 18ZM13.6673 10C12.934 10 12.334 9.40002 12.334 8.66669V2.66669L19.6673 10H13.6673Z"
      fill="#1A4A41"
    />
  </SVG>
)

export default withIconStyles(IconImport)
