import { FlexContainer } from '@edulastic/common'
import { Input, Select } from 'antd'
import styled from 'styled-components'
import { ToolTipContainer } from '../../../../../assessment/themes/common'

export const SearchBoxContainer = styled(FlexContainer)`
  width: 363px;
  height: 36px;
`

export const SearchDropdown = styled(Select)`
	width: 120px;
	flex-shrink: 0;
	flex-grow: 0;
	.ant-select-selection {
		border: 1px solid #D8D8D8;
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
		border-right: none;
		background: #F9F9F9;
		outline: none;
	}

	svg {
		width: 8px;
		height: 8px;
	}

	.ant-select-selection-selected-value {
		font-weight: 400;
		color: black;
	}


}
`

export const StyledInput = styled(Input)`
  border: 1px solid #d8d8d8;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  background: #f9f9f9;
  input {
    border: none;
    background: #f9f9f9;
    width: 241px;
    outline: none;
    font-weight: 400;
    color: black;
    padding-left: 2.25rem !important;
  }
`

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
`

export const StyledToolTipContainer = styled(ToolTipContainer)`
  font-weight: 400;
  color: black;
  font-size: 14px;
  height: 28px;
  margin: -12px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  gap: 0.25rem;
  align-text: center;
  vertical-align: bottom;
`
