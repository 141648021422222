import React from 'react'
import withIconStyles from '@edulastic/icons/src/HOC/withIconStyles'
import SVG from '@edulastic/icons/src/common/SVG'

const IconGraphParabola = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="41.044"
    height="23.67"
    viewBox="0 0 41.044 23.67"
    {...props}
  >
    <g transform="translate(-397.607 -352.4)">
      <path
        d="M398.607,376.07a1,1,0,0,1-.008-2l38.486-.3h.008a1,1,0,0,1,.008,2l-38.486.3Z"
        opacity="0.5"
      />
      <path
        d="M417.851,375.769a1,1,0,0,1-1-1V353.4a1,1,0,0,1,2,0v21.369A1,1,0,0,1,417.851,375.769Z"
        opacity="0.5"
      />
      <path d="M417.8,370.386c-9.529,0-15.424-15.96-15.672-16.645a1,1,0,0,1,1.88-.681c.056.154,5.661,15.326,13.792,15.326.059,0,.117,0,.175,0,8.248-.221,13.358-15.15,13.409-15.3a1,1,0,0,1,1.9.636c-.224.67-5.609,16.407-15.251,16.665C417.952,370.385,417.875,370.386,417.8,370.386Z" />
      <path d="M423.391,363.158h-1.509v-4.131l.015-.679.024-.742c-.251.25-.425.415-.522.493l-.82.659-.728-.908,2.3-1.831h1.24Z" />
      <path d="M438.651,363.26h-4.99v-1.05l1.791-1.811a15.335,15.335,0,0,0,1.04-1.13,2.482,2.482,0,0,0,.352-.584,1.5,1.5,0,0,0,.107-.557.808.808,0,0,0-.237-.639.915.915,0,0,0-.632-.21,1.8,1.8,0,0,0-.806.19,4.113,4.113,0,0,0-.815.542l-.82-.972a5.271,5.271,0,0,1,.873-.634,3.208,3.208,0,0,1,.757-.286,3.834,3.834,0,0,1,.918-.1,2.7,2.7,0,0,1,1.182.241,1.877,1.877,0,0,1,.8.684,1.816,1.816,0,0,1,.283,1.006,2.466,2.466,0,0,1-.173.925,3.578,3.578,0,0,1-.537.886,13.249,13.249,0,0,1-1.286,1.3l-.918.864v.068h3.11Z" />
      <path d="M419.524,366.306a3,3,0,1,1-4.1,1.1A3,3,0,0,1,419.524,366.306Z" />
      <path d="M430.524,357.306a3,3,0,1,1-4.1,1.1A3,3,0,0,1,430.524,357.306Z" />
    </g>
  </SVG>
)

export default withIconStyles(IconGraphParabola)
