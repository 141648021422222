/* eslint-disable react/prop-types */
import React from 'react'

import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconSync = (props) => (
  <SVG
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.0007 3.3335V0.833496L6.66732 4.16683L10.0007 7.50016V5.00016C12.759 5.00016 15.0007 7.24183 15.0007 10.0002C15.0007 10.8418 14.7923 11.6418 14.4173 12.3335L15.634 13.5502C16.284 12.5252 16.6673 11.3085 16.6673 10.0002C16.6673 6.31683 13.684 3.3335 10.0007 3.3335ZM10.0007 15.0002C7.24232 15.0002 5.00065 12.7585 5.00065 10.0002C5.00065 9.15849 5.20898 8.3585 5.58398 7.66683L4.36732 6.45016C3.71732 7.47516 3.33398 8.69183 3.33398 10.0002C3.33398 13.6835 6.31732 16.6668 10.0007 16.6668V19.1668L13.334 15.8335L10.0007 12.5002V15.0002Z"
      fill="#1AB395"
    />
  </SVG>
)

export default withIconStyles(IconSync)
