import { questionTitle } from '@edulastic/constants'
import AIQuestionGeneration from '../components/AIQuestionGeneration'
import AITextRewrite from '../components/AIRewrite'
import AITranslate from '../components/AITranslate'
import AIPassageGeneration from '../components/AIPassageGeneration'
import AIPassageQuestionGeneration from '../components/AIPassageQuestionGeneration'
import AIQuestionRevise from '../components/AIQuestionRevise'
import AIQuestionConvertType from '../components/AIQuestionConvertType'
import AIQuestionAdjustDifficulty from '../components/AIQuestionAdjustDifficulty'

export const ITEM_AUTHORING_PROMPTS = {
  QUESTION_GENERATION: {
    key: 'question_generation',
    title: 'Generate Full Item',
    component: AIQuestionGeneration,
    trackingEvent: {
      source: 'Item Generation',
    },
  },
  REWRITE: {
    key: 'rewrite',
    title: 'Rewrite Text',
    component: AITextRewrite,
    trackingEvent: {
      source: 'Rewrite',
    },
  },
  TRANSLATION: {
    key: 'translation',
    title: 'Translate Text',
    component: AITranslate,
    trackingEvent: {
      source: 'Translate',
    },
  },
}

export const PASSAGE_AUTHORING_PROMPTS = {
  PASSAGE_GENERATION: {
    key: 'passage_generation',
    title: 'Generate Passage',
    component: AIPassageGeneration,
    trackingEvent: {
      source: 'Passage Generation',
    },
  },
  QUESTION_GENERATION: {
    key: 'question_generation',
    title: 'Generate Items',
    component: AIPassageQuestionGeneration,
    trackingEvent: {
      source: 'Items for Passage',
    },
  },
}

export const ALL_PROMPTS = [
  ITEM_AUTHORING_PROMPTS.QUESTION_GENERATION,
  ITEM_AUTHORING_PROMPTS.REWRITE,
  ITEM_AUTHORING_PROMPTS.TRANSLATION,
]

export const ALL_PROMPTS_WITHOUT_QUESTION_GENERATION = [
  ITEM_AUTHORING_PROMPTS.REWRITE,
  ITEM_AUTHORING_PROMPTS.TRANSLATION,
]

const v1MigratedItemsQuestionTitle = {
  MCQ_STANDARD: 'Multiple choice - standard',
  CLOZE_TEXT: 'Cloze with Text',
  CLOZE_DROP_DOWN: 'Cloze with Drop Down',
  ESSAY_RICH_TEXT: 'Essay with rich text',
  ESSAY_PLAIN_TEXT: 'Essay with plain text',
}

export const ITEM_TYPE_AND_PROMPT_MAP = {
  [questionTitle.MCQ_STANDARD]: ALL_PROMPTS,
  [v1MigratedItemsQuestionTitle.MCQ_STANDARD]: ALL_PROMPTS,
  [questionTitle.MCQ_TRUE_OR_FALSE]: ALL_PROMPTS,
  [questionTitle.MCQ_MULTIPLE_RESPONSE]: ALL_PROMPTS,
  [questionTitle.MCQ_BLOCK_LAYOUT]: ALL_PROMPTS_WITHOUT_QUESTION_GENERATION,
  [questionTitle.CHOICE_MATRIX_STANDARD]: ALL_PROMPTS_WITHOUT_QUESTION_GENERATION,
  [questionTitle.CHOICE_MATRIX_INLINE]: ALL_PROMPTS_WITHOUT_QUESTION_GENERATION,
  [questionTitle.CHOICE_MATRIX_LABELS]: ALL_PROMPTS_WITHOUT_QUESTION_GENERATION,
  [questionTitle.CLOZE_DRAG_DROP]: [ITEM_AUTHORING_PROMPTS.REWRITE],
  [questionTitle.CLOZE_TEXT]: [ITEM_AUTHORING_PROMPTS.REWRITE],
  [v1MigratedItemsQuestionTitle.CLOZE_TEXT]: [ITEM_AUTHORING_PROMPTS.REWRITE],
  [questionTitle.EDITING_TASK]: [ITEM_AUTHORING_PROMPTS.REWRITE],
  [questionTitle.CLOZE_DROP_DOWN]: [ITEM_AUTHORING_PROMPTS.REWRITE],
  [v1MigratedItemsQuestionTitle.CLOZE_DROP_DOWN]: [
    ITEM_AUTHORING_PROMPTS.REWRITE,
  ],
  [questionTitle.CLOZE_TEXT]: [ITEM_AUTHORING_PROMPTS.REWRITE],
  [questionTitle.ESSAY_RICH_TEXT]: [ITEM_AUTHORING_PROMPTS.TRANSLATION],
  [v1MigratedItemsQuestionTitle.ESSAY_RICH_TEXT]: [
    ITEM_AUTHORING_PROMPTS.TRANSLATION,
  ],
  [questionTitle.ESSAY_PLAIN_TEXT]: [ITEM_AUTHORING_PROMPTS.TRANSLATION],
  [v1MigratedItemsQuestionTitle.ESSAY_PLAIN_TEXT]: [
    ITEM_AUTHORING_PROMPTS.TRANSLATION,
  ],
}

export const PROMPT_SUGGESTIONS = {
  REWRITE: [
    {
      title: 'Simplify Language',
      key: 'simplify_language',
      prompt:
        'Rewrite the following text using simpler language suitable for younger students or those with lower reading levels.',
    },
    {
      title: 'Increase Complexity',
      key: 'increase_complexity',
      prompt:
        'Rewrite the following text to increase its complexity, making it more challenging for advanced students or higher-grade levels.',
    },
    {
      title: 'Improve Clarity',
      key: 'improve_clarity',
      prompt:
        'Rewrite the following text to improve its clarity, making it more straightforward and easy to understand.',
    },
    {
      title: 'Expand Details',
      key: 'expand_details',
      prompt:
        'Rewrite the following text to include more details and explanations to enrich content understanding.',
    },
  ],
  PASSAGE_CONTENT_REQUIREMENT: [
    {
      title: 'Short Story',
      key: 'short_story',
      prompt: 'I want content to be a short story.',
    },
    {
      title: 'Narrative Text',
      key: 'narrative_text',
      prompt: 'I want content to be narrative text.',
    },
    {
      title: 'Expository Text',
      key: 'expository_text',
      prompt: 'I want content to be expository text.',
    },
    {
      title: 'Descriptive Text',
      key: 'descriptive_text',
      prompt: 'I want content to be descriptive text.',
    },
    {
      title: 'Biography',
      key: 'biography',
      prompt: 'I want content to be a biography.',
    },
    {
      title: 'Persuasive Text',
      key: 'persuasive_text',
      prompt: 'I want content to be persuasive.',
    },
    {
      title: 'News Article',
      key: 'news_article',
      prompt: 'I want content to be a news article.',
    },
  ],
  PASSAGE_QUESTION_PREFERENCE: [
    {
      title: 'Comprehensive',
      key: 'comprehensive',
      prompt: 'I want questions to be comprehensive.',
    },
    {
      title: 'Short',
      key: 'short',
      prompt: 'I want questions to be short.',
    },
    {
      title: 'Theme',
      key: 'theme',
      prompt: 'I want question to match the theme of passage.',
    },
  ],
}

export const QUESTION_ENHANCE_PROMPTS = [
  {
    value: 'revise',
    key: 'revise',
    label: 'Revise Question',
    component: AIQuestionRevise,
  },
  {
    value: 'adjust-difficulty',
    key: 'adjust-difficulty',
    label: 'Adjust Difficulty',
    component: AIQuestionAdjustDifficulty,
  },
  {
    value: 'convert-question-type',
    key: 'convert-question-type',
    label: 'Convert Question Type',
    component: AIQuestionConvertType,
  },
]
