import styled from 'styled-components'
import { white } from '@edulastic/colors'

const BodyWrapper = styled.div`
  padding: 20px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${white};
  height: ${({ height }) => height || ''};
`

export default BodyWrapper
