import React from 'react'
import { HeaderContainer, LeftSection, RightSection } from '../../styled'
import Filters from './Filters'
import ActionItems from './ActionItems'

const SubHeader = ({ classGroups, history, isPremiumUser }) => {
  return (
    <HeaderContainer>
      <LeftSection>{isPremiumUser && <Filters />}</LeftSection>
      <RightSection>
        <ActionItems groups={classGroups} history={history} />
      </RightSection>
    </HeaderContainer>
  )
}

export default SubHeader
