import EduButton from '@edulastic/common/src/components/EduButton'
import { IconClose } from '@edulastic/icons'
import { largeDesktopWidth, smallDesktopWidth } from '@edulastic/colors'

import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  padding: 7px 12px;
  background: ${(props) => `url(${props.$imageUrl})` || '#fcf5eb'};
  border-radius: 4px;
  background-size: 100% 100%;
  min-height: 32px;
  height: 35px;
  margin-left: 16px;
  @media (max-width: ${smallDesktopWidth}) {
    height: 30px;
  }
`

export const LeftContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-left: 38px;
  @media (max-width: ${largeDesktopWidth}) {
    margin-left: 24px;
  }
`

export const RightContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-left: 24px;
  @media (max-width: ${largeDesktopWidth}) {
    margin-left: 14px;
  }
`

export const BannerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  & > *:first-child {
    font-size: 12px;
    font-weight: 600;
    color: #41464d;
    white-space: nowrap;
    @media (max-width: ${largeDesktopWidth}) {
      width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`

export const CloseBannerIcon = styled(IconClose)`
  &:hover {
    cursor: pointer;
  }
`
const buttonTextColor = css`
  color: ${(props) => props.$buttonTextColor || '#41464d'};
`
export const BannerActionButton = styled(EduButton)`
  &.ant-btn.ant-btn-primary {
    ${buttonTextColor}
    font-weight: 700;
    font-size: 10px;
    border: 0.5px solid #d8d8d8;
    span {
      margin: auto;
    }
  }
  &:hover {
    &.ant-btn.ant-btn-primary {
      background-color: #ffffff;
      border: 0.5px solid #d8d8d8;
      ${buttonTextColor}
    }
  }
  &:focus {
    &.ant-btn.ant-btn-primary {
      ${buttonTextColor}
    }
  }
`
