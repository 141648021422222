import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FlexContainer, getRangeAtFirst } from '@edulastic/common'
import { highlightColors } from '@edulastic/colors'
import { ColorButton, BackSlashIcon } from './styled-components'
import { isiOS } from '../../../utils/helpers'

const ColorPicker = ({ selectColor, bg }) => {
  const { blue: highlightBlue, green, orange, yellow, pink } = highlightColors
  const [selection, setSelection] = useState()

  const chooseColor = (value) => () => {
    selectColor(value, selection)
  }

  const selectStartHandle = () => {
    setSelection(getRangeAtFirst?.()?.cloneRange?.())
  }

  useEffect(() => {
    if (isiOS()) {
      window.addEventListener('selectstart', selectStartHandle)
    }

    return () => {
      if (isiOS()) {
        window.removeEventListener('selectstart', selectStartHandle)
      }
    }
  }, [])

  return (
    <FlexContainer>
      <ColorButton onClick={chooseColor('remove')}>
        <BackSlashIcon type="minus" />
      </ColorButton>
      <ColorButton
        onClick={chooseColor(highlightBlue)}
        color={highlightBlue}
        active={bg === highlightBlue}
      />
      <ColorButton
        onClick={chooseColor(green)}
        color={green}
        active={bg === green}
      />
      <ColorButton
        onClick={chooseColor(orange)}
        color={orange}
        active={bg === orange}
      />
      <ColorButton
        onClick={chooseColor(yellow)}
        color={yellow}
        active={bg === yellow}
      />
      <ColorButton
        onClick={chooseColor(pink)}
        color={pink}
        active={bg === pink}
      />
    </FlexContainer>
  )
}

export default ColorPicker

ColorPicker.propTypes = {
  selectColor: PropTypes.func.isRequired,
  bg: PropTypes.string,
}

ColorPicker.defaultProps = {
  bg: null,
}
