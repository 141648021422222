/* eslint-disable react/prop-types */
import React from "react";
import withIconStyles from "./HOC/withIconStyles";
import SVG from "./common/SVG";

const IconStrikeThrough = props => (
  <SVG xmlns="http://www.w3.org/2000/svg" width="11" height="14.896" viewBox="0 0 11 14.896" {...props}>
    <g transform="translate(-452.5 -66.393)">
      <path
        d="M-.779.323A5.491,5.491,0,0,0,2.242-.456,3.829,3.829,0,0,0,4.028-3.762c0-1.805-.817-3.116-3.116-4.845l-.532-.4c-1.577-1.2-2.014-1.862-2.014-2.736A2,2,0,0,1,.627-13.7a2.406,2.406,0,0,1,2.071.931,2.3,2.3,0,0,1,.494,1.254c0,.228.057.342.19.342.152,0,.209-.152.209-.57,0-1.482.076-2.033.076-2.28,0-.133-.1-.19-.266-.228-.209-.057-.456-.114-.779-.171a10.284,10.284,0,0,0-1.748-.152c-2.641,0-4.389,1.5-4.389,3.591,0,1.539.722,2.869,2.812,4.5L.171-5.8C1.748-4.56,2.033-3.686,2.033-2.755A2.309,2.309,0,0,1-.513-.589,2.618,2.618,0,0,1-3.23-2.508,4.013,4.013,0,0,1-3.4-3.477c0-.152-.019-.323-.19-.323-.152,0-.209.209-.228.494-.019.3-.1,1.444-.1,2.489,0,.418.057.513.361.646A6.583,6.583,0,0,0-.779.323Z"
        transform="translate(458.1 80.966)"
        fill="#66717a"
      />
      <path
        d="M0,0H11"
        transform="translate(452.5 74.5)"
        fill="none"
        stroke="#66717a"
        strokeLinecap="square"
        strokeWidth="1.5"
      />
    </g>
  </SVG>
);

export default withIconStyles(IconStrikeThrough);
