/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconWriting = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="17.622"
    height="19.663"
    viewBox="0 0 17.622 19.663"
    {...props}
  >
    <g transform="translate(0)">
      <g transform="translate(0)">
        <path
          d="M115.907,200.768a.768.768,0,0,0-.768-.768h-7.911a.768.768,0,1,0,0,1.536h7.911A.768.768,0,0,0,115.907,200.768Z"
          transform="translate(-103.391 -192.319)"
        />
        <path
          d="M107.227,280a.768.768,0,1,0,0,1.536h4.8a.768.768,0,1,0,0-1.536Z"
          transform="translate(-103.391 -269.247)"
        />
        <path
          d="M32.211,18.127H29.642a1.538,1.538,0,0,1-1.536-1.536V3.072a1.538,1.538,0,0,1,1.536-1.536h9.443a1.538,1.538,0,0,1,1.536,1.536V7.8a.768.768,0,0,0,1.536,0V3.072A3.076,3.076,0,0,0,39.086,0H29.642A3.076,3.076,0,0,0,26.57,3.072V16.591a3.076,3.076,0,0,0,3.072,3.072h2.569a.768.768,0,1,0,0-1.536Z"
          transform="translate(-26.57)"
        />
        <path
          d="M252.076,272.7a2.307,2.307,0,0,0-3.258,0l-4.217,4.208a.768.768,0,0,0-.192.32l-.918,3.023a.768.768,0,0,0,.94.964l3.1-.859a.769.769,0,0,0,.338-.2l4.208-4.2A2.307,2.307,0,0,0,252.076,272.7Zm-5.15,6.229-1.56.432.456-1.5,2.845-2.839,1.086,1.086Zm4.065-4.057-.149.148-1.086-1.086.148-.148a.768.768,0,0,1,1.087,1.086Z"
          transform="translate(-235.127 -261.577)"
        />
        <path
          d="M115.139,120h-7.911a.768.768,0,1,0,0,1.536h7.911a.768.768,0,1,0,0-1.536Z"
          transform="translate(-103.391 -115.391)"
        />
      </g>
    </g>
  </SVG>
)

export default withIconStyles(IconWriting)
