export const TEST_IMPORT_KEYS = {
  GOOGLE_FORMS: 'google_forms',
  DOCUMENT: 'document',
  QTI: 'qti',
  WEBCT: 'webct',
}

export const TEST_IMPORT_OPTIONS = [
  {
    key: TEST_IMPORT_KEYS.GOOGLE_FORMS,
    title: 'Google Forms',
    isPremiumFeature: true,
  },
  {
    key: TEST_IMPORT_KEYS.DOCUMENT,
    title: 'Document',
    isBeta: true,
  },
  {
    key: TEST_IMPORT_KEYS.QTI,
    title: 'QTI',
  },
  {
    key: TEST_IMPORT_KEYS.WEBCT,
    title: 'WebCT',
  },
]
