import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

// components
import { Radio, Tooltip } from 'antd'
import { IconClose } from '@edulastic/icons'
import { EduButton } from '@edulastic/common'
import {
  HelperText,
  StyledFontAwesomeIcon,
  StyledModal,
  StyledTable,
} from './styled'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

// ducks
import { actions, selectors } from '../ducks'

const MergeUsersModal = ({
  type,
  visible,
  loading,
  onSubmit,
  onCancel,
  userIds,
  userDetails,
  fetchUserDetails,
  mergeUsers,
}) => {
  // state
  const [tooltipRow, setTooltipRow] = useState(-1)
  const [primaryUserId, setPrimaryUserId] = useState([])

  useEffect(() => {
    if (userIds.length > 1 && visible) {
      fetchUserDetails({ type, userIds })
    }
  }, [visible])

  useEffect(() => {
    setPrimaryUserId(userDetails[0]?._id)
  }, [userDetails])

  const handleMerge = () => {
    mergeUsers({
      primaryUserId,
      userIds: userIds.filter((id) => id !== primaryUserId),
      onMergeAction: onSubmit,
    })
  }

  const columns = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (data, { _id }, index) => (
        <Tooltip
          placement="topLeft"
          title="The selected user will remain active"
          visible={_id === primaryUserId && index === tooltipRow}
        >
          <Radio checked={_id === primaryUserId}>{data}</Radio>
        </Tooltip>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Username',
      key: 'username',
      dataIndex: 'username',
      sorter: (a, b) => a.username.localeCompare(b.username),
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: 'LMS',
      key: 'lms',
      dataIndex: 'lms',
      sorter: (a, b) => a.lms.localeCompare(b.lms),
    },
    {
      title: 'Id',
      dataIndex: 'openId',
      sorter: (a, b) => a.openId.localeCompare(b.openId),
    },
    {
      title: 'Assignments',
      key: 'assignments',
      align: 'center',
      dataIndex: 'assignments',
      sorter: (a, b) => a.assignments < b.assignments,
    },
    {
      title: 'Created Date',
      key: 'createdAt',
      dataIndex: 'createdAt',
      sorter: (a, b) => a < b,
    },
  ]

  const curatedDetails = userDetails.map((u) => ({
    _id: u._id,
    name:
      [u.firstName || '', u.middleName || '', u.lastName || ''].join(' ') ||
      '-',
    username: u.username || '-',
    email: u.email || '-',
    lms: u.lms || '-',
    openId: u.openId || '-',
    assignments: parseInt(u.assignments || 0),
    createdAt: moment(u.createdAt).format('MMM D, YYYY'),
  }))

  return (
    <StyledModal
      title={
        <>
          <div>
            <span>Merge Accounts</span>
            <IconClose
              height={20}
              width={20}
              onClick={onCancel}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <p>
            Merging {type} accounts is irreversible. All information will be
            combined into a single account. The selected user will remain active
          </p>
          <p>
            <HelperText>
              <StyledFontAwesomeIcon icon={faInfoCircle} aria-hidden="true" />{' '}
              <b>Note:</b> Assignments and other details will NOT be merged.
            </HelperText>
          </p>
        </>
      }
      visible={visible}
      onCancel={onCancel}
      footer={[
        <EduButton isGhost onClick={onCancel}>
          Cancel
        </EduButton>,
        <EduButton onClick={handleMerge} loading={loading} data-cy="mergeStudents">
          Merge
        </EduButton>,
      ]}
      centered
    >
      <StyledTable
        data-cy="studentsToMerge"
        loading={loading}
        columns={columns}
        dataSource={curatedDetails}
        pagination={false}
        onRow={({ _id }, index) => ({
          onClick: (e) => {
            e.stopPropagation()
            setPrimaryUserId(_id)
          },
          onMouseEnter: () => setTooltipRow(index),
          onMouseLeave: () => setTooltipRow(-1),
        })}
      />
    </StyledModal>
  )
}

export default connect(
  (state) => ({
    loading: selectors.loading(state),
    userDetails: selectors.userDetails(state),
  }),
  {
    fetchUserDetails: actions.fetchUserDetailsRequest,
    mergeUsers: actions.mergeUsersRequest,
  }
)(MergeUsersModal)
