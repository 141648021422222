import React from 'react'
import withIconStyles from '@edulastic/icons/src/HOC/withIconStyles'
import SVG from '@edulastic/icons/src/common/SVG'

const IconGraphVector = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="25.138"
    height="10.125"
    viewBox="0 0 25.138 10.125"
    {...props}
  >
    <g transform="translate(-332.514 -309.563)">
      <path d="M333.513,319.688a1,1,0,0,1-.258-1.966l20.474-5.487a1,1,0,0,1,.517,1.932l-20.474,5.486A.962.962,0,0,1,333.513,319.688Z" />
      <path d="M357.651,312.467l-2.9,5.031-1.185-4.282-.941-3.653Z" />
    </g>
  </SVG>
)

export default withIconStyles(IconGraphVector)
