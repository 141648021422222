import { createSelector } from 'reselect'

import { reduxNamespaceKey } from './actionReducers'

const stateSelector = (state) => state.reportReducer[reduxNamespaceKey]

const firstLoad = createSelector(stateSelector, (state) => state.firstLoad)
const loadingFilterData = createSelector(
  stateSelector,
  (state) => state.loadingFilterData
)
const loadingStudentList = createSelector(
  stateSelector,
  (state) => state.loadingStudentList
)
const filterTabKey = createSelector(
  stateSelector,
  (state) => state.filterTabKey
)
const filters = createSelector(stateSelector, (state) => state.filters)
const filtersData = createSelector(stateSelector, (state) => state.filtersData)
const prevFiltersData = createSelector(
  stateSelector,
  (state) => state.prevFiltersData
)
const filterTagsData = createSelector(
  stateSelector,
  (state) => state.filterTagsData
)
const settings = createSelector(stateSelector, (state) => state.settings)
const selectedFilterTagsData = createSelector(
  stateSelector,
  (state) => state.settings.selectedFilterTagsData
)
const student = createSelector(stateSelector, (state) => state.student)
const studentList = createSelector(stateSelector, (state) => state.studentList)
const activeStudentQuery = createSelector(
  stateSelector,
  (state) => state.studentListQuery
)

export {
  firstLoad,
  loadingFilterData,
  loadingStudentList,
  filterTabKey,
  filters,
  filtersData,
  prevFiltersData,
  filterTagsData,
  settings,
  selectedFilterTagsData,
  student,
  studentList,
  activeStudentQuery,
}
