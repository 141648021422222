import { TextAreaInputStyled } from '@edulastic/common/src/components/InputStyles'
import { IconCopyContent } from '@edulastic/icons'
import { Form } from 'antd'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { segmentApi } from '@edulastic/api'
import AIFooterAction from '../common/AIFooterAction'
import PromptSuggestions from '../common/PromptSuggestions'
import { StyledForm } from '../common/styled'
import { ITEM_AUTHORING_PROMPTS, PROMPT_SUGGESTIONS } from '../constants/prompt'
import {
  generateAIRewrittenContentAction,
  getAIRewriteContentState,
} from '../ducks/rewrite'
import { copyContentToClipboard } from '../utils/common'
import { parseHTMLToPlainText } from '../utils/htmlParser'
import AIContentView from '../common/AIContentView'

const AIRewrite = ({
  form,
  data: { question: { stimulus = '' } = {} } = {},
  generateAIRewrittenContent,
  aiRewriteContentState,
}) => {
  const { getFieldDecorator, setFieldsValue, getFieldValue } = form
  const { apiStatus, result } = aiRewriteContentState

  useEffect(() => {
    form.setFieldsValue({
      content: parseHTMLToPlainText(stimulus),
    })
  }, [stimulus])

  const currentPrompt = ITEM_AUTHORING_PROMPTS.REWRITE

  const onSubmit = (e) => {
    e.preventDefault()
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (currentPrompt.trackingEvent) {
          segmentApi.genericEventTrack('GenerateAIItem', {
            source: currentPrompt.trackingEvent.source,
          })
        }

        generateAIRewrittenContent(values)
      }
    })
  }

  const isLoading = apiStatus === 'INITIATED'

  return (
    <StyledForm onSubmit={onSubmit}>
      <Form.Item required label="ORIGINAL QUESTION" name="content">
        {getFieldDecorator('content', {
          rules: [
            {
              required: true,
              message: 'This field is required!',
            },
            {
              max: 500,
              message: 'This field should not exceed 500 characters!',
            },
          ],
        })(
          <TextAreaInputStyled
            disabled={isLoading}
            autoSize={{ minRows: 4 }}
            placeholder="Your original question..."
            data-cy="originalQuestionTextOnRewrite"
          />
        )}
      </Form.Item>
      <Form.Item label="REWRITE SO THAT" name="prompt">
        {getFieldDecorator('prompt', {
          rules: [
            {
              required: true,
              message: 'This field is required!',
            },
            {
              max: 500,
              message: 'This field should not exceed 500 characters!',
            },
          ],
        })(
          <TextAreaInputStyled
            disabled={isLoading}
            autoSize={{ minRows: 4 }}
            placeholder="Rewrite so that..."
            data-cy="rewriteQuestionText"
          />
        )}
      </Form.Item>
      <PromptSuggestions
        disabled={isLoading}
        currentPrompt={getFieldValue('prompt')}
        suggestions={PROMPT_SUGGESTIONS.REWRITE}
        onSelect={(prompt) => {
          setFieldsValue({
            prompt,
          })
        }}
      />
      <br />
      {apiStatus && (
        <div style={{ marginBottom: '90px' }} data-cy="rewriteContent">
          <AIContentView
            onRegenerate={onSubmit}
            title="Created by AI Assistant for Rewriting Text"
            loadingTitle="Generating rewritten content"
            content={result}
            status={apiStatus}
          />
        </div>
      )}
      <AIFooterAction
        status={apiStatus}
        onRegenerate={onSubmit}
        action={{
          label: 'Copy',
          icon: <IconCopyContent height="20px" width="20px" />,
          onClick: () => {
            copyContentToClipboard(result)
          },
        }}
        isLoading={isLoading}
      />
    </StyledForm>
  )
}

const enhance = compose(
  Form.create({ name: 'ai-text-rewrite' }),
  connect(
    (state) => ({
      aiRewriteContentState: getAIRewriteContentState(state),
    }),
    {
      generateAIRewrittenContent: generateAIRewrittenContentAction,
    }
  )
)

export default enhance(AIRewrite)
