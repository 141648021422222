import React, { useMemo } from 'react'
import { themeColorBlue } from '@edulastic/colors'

import { Button, Icon } from 'antd'

import styled from 'styled-components'

const ReSyncClasses = ({
  googleAllowedInstitutions,
  isClassLink,
  canvasAllowedInstitution,
  user,
  enableCleverSync,
  isCleverDistrict,
  history,
  providerNames,
  isCleverClassExists,
  textColor,
  text = 'SYNC CLASSES',
}) => {
  const { atlasId, cleverId, isPlayground } = user

  const atlasProviderName = [
    'schoology',
    'classlink',
    'clever',
    'canvas',
  ].find((providerName) =>
    providerNames.some((name) => name.toLowerCase() === providerName)
  )

  // `googleStopSync` is true if all `googleAllowedInstitutions` have `stopSync` enabled
  const googleStopSync = useMemo(
    () => googleAllowedInstitutions.every((s) => !!s.institution.stopSync),
    [googleAllowedInstitutions]
  )

  const canvasStopSync = useMemo(
    () => canvasAllowedInstitution.every((s) => !!s.institution.stopSync),
    [canvasAllowedInstitution]
  )

  const showCleverSync = [!isPlayground, enableCleverSync].every((val) => !!val)
  const showGoogleClassSync = [
    !isPlayground,
    googleAllowedInstitutions?.length > 0,
    // if stopSync is enabled for all institutions, ignore cleverId/atlasId,
    // else, show bulk Sync only if both(atlasId & cleverId) are unavailable
    (!cleverId && !isClassLink && !atlasId) || googleStopSync,
    !enableCleverSync,
  ].every((val) => !!val)

  const showCanvasSync = [
    !isPlayground,
    canvasAllowedInstitution?.length > 0,
    // if stopSync is enabled for all institutions, ignore cleverId/atlasId,
    // else, show bulk Sync only if both(atlasId & cleverId) are unavailable
    (!cleverId && !isClassLink && !atlasId) || canvasStopSync,
    !enableCleverSync,
  ].every((val) => !!val)

  const showEdlinkSync = [
    !isPlayground,
    atlasId,
    atlasProviderName?.length,
  ].every((val) => !!val)

  const showCleverDistrictSync = [
    !isPlayground,
    isCleverDistrict,
    isCleverClassExists,
  ].every((val) => !!val)

  const handleClick = () => {
    history.push('/author/manageClass', { fromDashboard: true })
  }

  const isResyncEnabled =
    showCleverSync ||
    showCleverDistrictSync ||
    showGoogleClassSync ||
    showCanvasSync ||
    showEdlinkSync

  if (!isResyncEnabled) {
    return null
  }

  return (
    <MainContainer>
      <ReSyncButton
        data-cy="syncClasses"
        $color={textColor}
        type="link"
        onClick={handleClick}
      >
        <Icon type="sync" />
        <span>{text}</span>
      </ReSyncButton>
    </MainContainer>
  )
}

export default ReSyncClasses

const MainContainer = styled.div`
  display: inline;
`

/** @type {typeof Button} */
const ReSyncButton = styled(Button)`
  color: ${({ $color }) => $color || themeColorBlue};
  border: none !important;
  font-size: 14px;
  font-weight: 600;
  padding: 0px !important;
`
