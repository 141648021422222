/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconUserRegular = (props) => {
  const { fill } = props
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M8.00033 3.93341C8.77366 3.93341 9.40033 4.56008 9.40033 5.33341C9.40033 6.10675 8.77366 6.73341 8.00033 6.73341C7.22699 6.73341 6.60033 6.10675 6.60033 5.33341C6.60033 4.56008 7.22699 3.93341 8.00033 3.93341ZM8.00033 9.93341C9.98033 9.93341 12.067 10.9067 12.067 11.3334V12.0667H3.93366V11.3334C3.93366 10.9067 6.02033 9.93341 8.00033 9.93341ZM8.00033 2.66675C6.52699 2.66675 5.33366 3.86008 5.33366 5.33341C5.33366 6.80675 6.52699 8.00008 8.00033 8.00008C9.47366 8.00008 10.667 6.80675 10.667 5.33341C10.667 3.86008 9.47366 2.66675 8.00033 2.66675ZM8.00033 8.66675C6.22033 8.66675 2.66699 9.56008 2.66699 11.3334V13.3334H13.3337V11.3334C13.3337 9.56008 9.78032 8.66675 8.00033 8.66675Z"
        fill={fill || '#9501DB'}
      />
    </SVG>
  )
}

export default withIconStyles(IconUserRegular)
