/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconAccessibility = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="20.058"
    height="20.058"
    viewBox="0 0 20.058 20.058"
    {...props}
  >
    <g transform="translate(-13.1 -13.1)">
      <path d="M33.158,23.129A10.029,10.029,0,1,0,23.129,33.158,10.039,10.039,0,0,0,33.158,23.129ZM23.129,16.144a1.522,1.522,0,1,1-1.522,1.522A1.537,1.537,0,0,1,23.129,16.144Zm-1.522,13.29a.763.763,0,0,1-.707.462.724.724,0,0,1-.326-.082.784.784,0,0,1-.381-1.06A42.783,42.783,0,0,0,21.96,24.08a17.05,17.05,0,0,0,.19-2.147.418.418,0,0,0-.272-.408l-3.37-.978a.808.808,0,0,1-.544-1.006.824.824,0,0,1,1.006-.489,23.637,23.637,0,0,0,4.158.978,23.991,23.991,0,0,0,4.213-1.006.791.791,0,0,1,.435,1.522l-3.343,1.006a.386.386,0,0,0-.272.408,17.05,17.05,0,0,0,.19,2.147,42.784,42.784,0,0,0,1.767,4.675.807.807,0,0,1-.381,1.06.724.724,0,0,1-.326.082.737.737,0,0,1-.707-.462l-1.576-3.289Z" />
    </g>
  </SVG>
)

export default withIconStyles(IconAccessibility)
