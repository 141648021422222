import { FlexContainer } from '@edulastic/common'
import { IconAI, IconAIGradientTick } from '@edulastic/icons'
import React from 'react'
import AddOnTag from '../../AssessmentCreate/components/common/AddOnTag'
import {
  featuresListBlockDataTestId,
  getAIFeaturesAccessTextDataTestId,
} from '../constants/test'
import {
  StyledAIFeaturesSubscriptionWrapper,
  StyledAIFeaturesText,
  StyledGradientText,
} from './styled'

const AIFeaturesSubscription = ({
  i18Translate,
  showFeatureList = false,
  isPremiumUser,
  isPassage = false,
}) => {
  return (
    <StyledAIFeaturesSubscriptionWrapper>
      <FlexContainer
        alignItems="center"
        justifyContent="flex-start"
        marginBottom={showFeatureList ? '10px' : null}
      >
        <IconAI />
        <StyledGradientText
          data-testid={getAIFeaturesAccessTextDataTestId}
          fontWeight={600}
          marginRight="10px"
        >
          Get {isPremiumUser ? '' : 'Teacher Premium + '}AI Add-on{' '}
          {isPassage
            ? 'to generate passage and items using AI'
            : 'to access AI Tools'}
        </StyledGradientText>
        <AddOnTag message={i18Translate('author:aiSuite.addOnText')} />
      </FlexContainer>
      {!!showFeatureList && (
        <FlexContainer
          alignItems="center"
          justifyContent="flex-start"
          gap="6px"
          marginLeft="3px"
          data-testid={featuresListBlockDataTestId}
        >
          <IconAIGradientTick />
          <StyledAIFeaturesText>
            Translate to Multiple Languages
          </StyledAIFeaturesText>
          <IconAIGradientTick />
          <StyledAIFeaturesText>
            Generate Questions, Passages, Items using AI
          </StyledAIFeaturesText>
        </FlexContainer>
      )}
    </StyledAIFeaturesSubscriptionWrapper>
  )
}

export default AIFeaturesSubscription
