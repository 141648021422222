import { createAction, createReducer } from 'redux-starter-kit'
import { createSelector } from 'reselect'

// constants

export const UPDATE_TEST_PLAYER = '[test player] update test player'
export const CHANGED_PLAYER_CONTENT = '[test player] content has been changed'
export const SET_CURRENT_CALCULATOR_TYPE =
  '[test player] set current calculator type'
export const CHANGED_PASSAGE_CONTENT =
  '[test player] passage content has been changed'

// actions

export const updateTestPlayerAction = createAction(UPDATE_TEST_PLAYER)
export const changedPlayerContentAction = createAction(CHANGED_PLAYER_CONTENT)
export const changedPassageContentAction = createAction(CHANGED_PASSAGE_CONTENT)

// reducer

const initialState = {
  enableMagnifier: false,
  contentChanged: '',
  ttsPlaybackSpeed: localStorage.getItem('ttsPlaybackSpeed') || '1',
  currentCalculatorType: '',
}

const updateTestPlayerReducer = (state, { payload }) => {
  return {
    ...state,
    ...payload,
    ...(!payload?.enableMagnifier ? { magnifierSize: '' } : {}),
  }
}

const changedPlayerContent = (state) => ({
  ...state,
  contentChanged: Date.now(),
})

/**
 * Triggers when any passage navigation changes happen.
 * Passage page change, Tab change and collapse passage view
 */
const changedPassageContent = (state) => ({
  ...state,
  passageContentChanged: Date.now(),
})

export default createReducer(initialState, {
  [UPDATE_TEST_PLAYER]: updateTestPlayerReducer,
  [CHANGED_PLAYER_CONTENT]: changedPlayerContent,
  [CHANGED_PASSAGE_CONTENT]: changedPassageContent,
})

export const testPlayerSelector = (state) => state.testPlayer || {}

export const getTextToSpeechPlaybackSpeed = createSelector(
  testPlayerSelector,
  (testPlayer) => testPlayer?.ttsPlaybackSpeed || '1'
)

export const getCurrentCalculatorTypeSelector = createSelector(
  testPlayerSelector,
  (testPlayer) => testPlayer?.currentCalculatorType
)

export const getIsAnswerMaskingEnabledSelector = createSelector(
  testPlayerSelector,
  (testPlayer) => testPlayer.isAnswerMaskingEnabled || false
)

export const getMaskedAnswersSelector = createSelector(
  testPlayerSelector,
  (testPlayer) => testPlayer.maskedAnswers || {}
)
