import React from 'react'
import { EduButton } from '@edulastic/common'
import { StyledAdaptiveTestConfirmationModal } from '../styled-components'

const AdaptiveToggleConfirmationModal = ({ visible, onCancel, onOk }) => {
  return (
    <StyledAdaptiveTestConfirmationModal
      centered
      visible={visible}
      closable={false}
      maskClosable={false}
      title="Adaptive Toggle Confirmation"
      width="424px"
      height="204px"
      onCancel={onCancel}
      footer={[
        <EduButton height="32px" isGhost onClick={onCancel}>
          CANCEL
        </EduButton>,
        <EduButton height="32px" onClick={onOk}>
          YES
        </EduButton>,
      ]}
    >
      <p>Please note you will lose any items already added to the test.</p>
    </StyledAdaptiveTestConfirmationModal>
  )
}

export default AdaptiveToggleConfirmationModal
