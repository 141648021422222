import React from 'react'
import HeaderWrapper from '../../src/mainContent/headerWrapper'
import Title from '../../AssessmentCreate/components/common/Title'

const ImportTestHeader = () => {
  return (
    <HeaderWrapper>
      <Title>Import Content</Title>
    </HeaderWrapper>
  )
}

export default ImportTestHeader
