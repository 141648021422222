import { IconAI, IconMagicWandGradient } from '@edulastic/icons'
import { Tag, Tooltip } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { segmentApi } from '@edulastic/api'
import { StyledGradientText } from './styled'

const AIButtons = ({ prompts, onClick }) => {
  const onClickHandle = (prompt) => {
    if (prompt.trackingEvent) {
      segmentApi.genericEventTrack('AI Prompt', {
        source: prompt.trackingEvent.source,
      })
    }
    onClick(prompt)
  }
  return (
    <>
      <IconAI />
      <StyledGradientText>Get AI Assistance</StyledGradientText>
      <StyledTagButtonWrapper>
        {prompts.map((prompt) => {
          const { key, title, disableMessage = null } = prompt
          return (
            <Tooltip title={disableMessage} position="bottom">
              <StyledTag
                key={key}
                onClick={() => !disableMessage && onClickHandle(prompt)}
              >
                <IconMagicWandGradient />
                <StyledTagTitle data-cy={key}>{title}</StyledTagTitle>
              </StyledTag>
            </Tooltip>
          )
        })}
      </StyledTagButtonWrapper>
    </>
  )
}

const StyledTagButtonWrapper = styled.span`
  margin-left: 12px;
`
const StyledTag = styled(Tag)`
  svg {
    top: 2px;
    position: relative;
  }
  color: black;
  height: 28px;
  line-height: 26px;
  border-radius: 24px;
  cursor: pointer;
`

const StyledTagTitle = styled.span`
  margin-left: 6px;
`

export default AIButtons
