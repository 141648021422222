import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { ThemeContext } from 'styled-components'
import isEmpty from 'lodash/isEmpty'

import { helpers } from '@edulastic/common'
import { response } from '@edulastic/constants'

import { StyledPreviewImage } from '../../styled/StyledPreviewImage'
import { StyledPreviewTemplateBox } from '../../styled/StyledPreviewTemplateBox'
import { StyledPreviewContainer } from '../../styled/StyledPreviewContainer'

import Response from './components/Response'
import { getPreviewDimensions } from '../../../../utils/clozeImage'

const CheckboxTemplateBoxLayout = ({
  showAnswer,
  checkAnswer,
  responseContainers,
  imageAlterText,
  userAnswers,
  stemNumeration,
  evaluation,
  fontSize,
  imageUrl,
  imageOptions,
  imageWidth,
  imageHeight,
  canvasHeight,
  canvasWidth,
  backgroundColor,
  onClickHandler,
  isExpressGrader,
  isPrintPreview,
  answerScore,
  item,
}) => {
  const {
    answerBox: { borderWidth, borderStyle, borderColor, borderRadius },
  } = useContext(ThemeContext)
  const allCorrect =
    responseContainers?.length === Object.keys(evaluation)?.length &&
    Object.keys(evaluation)?.every((id) => evaluation[id])

  const isEvaluationEmpty = useMemo(() => {
    return isEmpty(evaluation)
  }, [evaluation])

  const singleResponseBox =
    responseContainers && responseContainers.length === 1

  const { computedHeight, previewContainerWidth } = getPreviewDimensions({
    responseContainers,
    item,
    canvasWidth,
    imageHeight,
  })

  return (
    <StyledPreviewTemplateBox fontSize={fontSize} height={computedHeight}>
      <StyledPreviewContainer
        data-cy="image-text-preview-board"
        width={previewContainerWidth}
        height={computedHeight}
      >
        <StyledPreviewImage
          imageSrc={imageUrl || ''}
          width={imageWidth}
          height={imageHeight}
          heighcanvasDimensionst={imageHeight}
          alt={imageAlterText}
          style={{
            position: 'absolute',
            top: imageOptions.y || 0,
            left: imageOptions.x || 0,
          }}
        />
        {responseContainers.map((responseContainer, index) => {
          const responseBoxId = responseContainer.id
          const btnStyle = {
            width: responseContainer.width,
            top: responseContainer.top,
            left: responseContainer.left,
            height: responseContainer.height,
            position: 'absolute',
            backgroundColor,
            border: `${borderWidth} ${borderStyle} ${borderColor}`,
            borderRadius,
            display: 'flex',
          }

          const indexStr = helpers.getNumeration(index, stemNumeration)
          const status =
            evaluation[responseBoxId] !== undefined
              ? evaluation[responseBoxId]
                ? 'right'
                : 'wrong'
              : ''
          const lessMinWidth =
            parseInt(responseContainer.width, 10) < response.minWidthShowAnswer

          const userAnswer = userAnswers[responseContainer.id]
          const hasAnswered = !!userAnswer

          return (
            <Response
              showAnswer={showAnswer}
              responseContainer={responseContainer}
              btnStyle={btnStyle}
              userAnswer={userAnswer}
              hasAnswered={hasAnswered}
              userAnswers={userAnswers}
              onClickHandler={onClickHandler}
              status={status}
              indexStr={indexStr}
              lessMinWidth={lessMinWidth}
              checkAnswer={checkAnswer}
              isExpressGrader={isExpressGrader}
              isPrintPreview={isPrintPreview}
              answerScore={answerScore}
              allCorrect={allCorrect}
              isEvaluationEmpty={isEvaluationEmpty}
              singleResponseBox={singleResponseBox}
            />
          )
        })}
      </StyledPreviewContainer>
    </StyledPreviewTemplateBox>
  )
}
CheckboxTemplateBoxLayout.propTypes = {
  fontSize: PropTypes.string.isRequired,
  responseContainers: PropTypes.array.isRequired,
  imageOptions: PropTypes.object.isRequired,
  userAnswers: PropTypes.object.isRequired,
  stemNumeration: PropTypes.string.isRequired,
  evaluation: PropTypes.array.isRequired,
  showAnswer: PropTypes.bool.isRequired,
  imageUrl: PropTypes.string.isRequired,
  imageAlterText: PropTypes.string.isRequired,
  imageHeight: PropTypes.number.isRequired,
  imageWidth: PropTypes.number.isRequired,
  canvasHeight: PropTypes.number.isRequired,
  canvasWidth: PropTypes.number.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func.isRequired,
  isExpressGrader: PropTypes.bool.isRequired,
}

export default React.memo(CheckboxTemplateBoxLayout)
