import React from 'react'
import withIconStyles from '@edulastic/icons/src/HOC/withIconStyles'
import SVG from '@edulastic/icons/src/common/SVG'

const IconGraphCos = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="25.698"
    height="25.932"
    viewBox="0 0 25.698 25.932"
    {...props}
  >
    <g transform="translate(-269.244 -523.239)">
      <path
        d="M293.942,538.088h-23.7a1,1,0,0,1,0-2h23.7a1,1,0,0,1,0,2Z"
        fill="#c1c3c9"
      />
      <path
        d="M274.093,548.938a1,1,0,0,1-1-1v-23.7a1,1,0,0,1,2,0v23.7A1,1,0,0,1,274.093,548.938Z"
        fill="#c1c3c9"
      />
      <path d="M292.577,549.171c-1.619,0-5.261-.288-7.88-2.659a9.328,9.328,0,0,1-2.867-7.15,10.086,10.086,0,0,0-2.882-7.522c-3.347-3.189-8.537-3.028-8.586-3.027a.765.765,0,0,1-.779-.72.752.752,0,0,1,.719-.78c.24-.009,5.858-.193,9.672,3.431a11.529,11.529,0,0,1,3.357,8.6A7.887,7.887,0,0,0,285.7,545.4c2.906,2.632,7.517,2.252,7.564,2.248a.75.75,0,1,1,.133,1.5C293.354,549.146,293.052,549.171,292.577,549.171Z" />
    </g>
  </SVG>
)

export default withIconStyles(IconGraphCos)
