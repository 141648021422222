import { Spin } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { CLASS_CONTAINER_MIN_HEIGHT } from '../styled'

const Loader = () => {
  return (
    <LoaderContainer>
      <Spin tip="Please wait while loading..." />
    </LoaderContainer>
  )
}

export default Loader

export const LoaderContainer = styled.div`
  position: relative;
  height: calc(${CLASS_CONTAINER_MIN_HEIGHT} - 40px);
  .ant-spin-text {
    margin-top: 10px;
  }
`
