import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconAnswerEliminator = withIconStyles((props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18.729 18.724"
    width="18.729"
    height="18.724"
    {...props}
  >
    <g transform="translate(0 -0.07)">
      <g transform="translate(0 0.07)">
        <g transform="translate(0 0)">
          <path
            d="M15.99,2.808a9.362,9.362,0,1,0,0,13.246A9.379,9.379,0,0,0,15.99,2.808Zm-1.026,12.22a7.916,7.916,0,1,1,0-11.194A7.925,7.925,0,0,1,14.964,15.029Z"
            transform="translate(0 -0.07)"
            fill="#d4d4d4"
          />
        </g>
      </g>
      <g transform="translate(6.118 6.096)">
        <path
          d="M173.612,170.154l-2.054-2.051,2.054-2.051a.726.726,0,1,0-1.026-1.027l-2.055,2.053-2.055-2.053a.726.726,0,1,0-1.026,1.027L169.5,168.1l-2.054,2.051a.726.726,0,0,0,1.026,1.027l2.055-2.053,2.055,2.053a.726.726,0,1,0,1.026-1.027Z"
          transform="translate(-167.236 -164.813)"
          fill="#d4d4d4"
        />
      </g>
    </g>
  </SVG>
))

const IconBookMark = withIconStyles((props) => (
  <SVG
    viewBox="0 0 15.052 17.791"
    xmlns="http://www.w3.org/2000/svg"
    width="15.052"
    height="17.791"
    {...props}
  >
    <g transform="translate(-33.261)">
      <path d="M48.083.588a1.326,1.326,0,0,0-.617-.482A1.3,1.3,0,0,0,46.949,0H34.625a1.3,1.3,0,0,0-.517.106,1.325,1.325,0,0,0-.617.482,1.248,1.248,0,0,0-.229.729V16.474a1.249,1.249,0,0,0,.229.729,1.325,1.325,0,0,0,.617.482,1.3,1.3,0,0,0,.517.106A1.4,1.4,0,0,0,35.6,17.4l5.186-4.986L45.973,17.4a1.4,1.4,0,0,0,.976.376,1.368,1.368,0,0,0,1.135-.576,1.247,1.247,0,0,0,.229-.729V1.317A1.248,1.248,0,0,0,48.083.588ZM46.807,16.11l-4.974-4.774-1.047-1-1.047,1L34.766,16.11V1.505H46.807v14.6Z" />
    </g>
  </SVG>
))

const IconCalculator = withIconStyles((props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="14.956"
    height="20.809"
    viewBox="0 0 14.956 20.809"
    {...props}
  >
    <g transform="translate(-72)">
      <path
        d="M85.087,20.809H73.87A1.872,1.872,0,0,1,72,18.939V1.87A1.872,1.872,0,0,1,73.87,0H85.087a1.872,1.872,0,0,1,1.87,1.87v17.07A1.872,1.872,0,0,1,85.087,20.809ZM73.87,1.3a.57.57,0,0,0-.569.569v17.07a.57.57,0,0,0,.569.569H85.087a.57.57,0,0,0,.569-.569V1.87a.57.57,0,0,0-.569-.569Z"
        transform="translate(0 0)"
        fill="#d4d4d4"
      />
      <path
        d="M151.641,92.552h-6.178A1.465,1.465,0,0,1,144,91.089V89.463A1.465,1.465,0,0,1,145.463,88h6.178a1.465,1.465,0,0,1,1.463,1.463v1.626A1.465,1.465,0,0,1,151.641,92.552ZM145.463,89.3a.163.163,0,0,0-.163.163v1.626a.163.163,0,0,0,.163.163h6.178a.163.163,0,0,0,.163-.163V89.463a.163.163,0,0,0-.163-.163Z"
        transform="translate(-69.074 -84.423)"
        fill="#d4d4d4"
      />
      <path
        d="M160.665,257.3a.65.65,0,0,1,0-1.3h0a.65.65,0,1,1,0,1.3Z"
        transform="translate(-84.438 -245.596)"
        fill="#d4d4d4"
      />
      <path
        d="M240.665,257.3a.65.65,0,0,1,0-1.3h0a.65.65,0,0,1,0,1.3Z"
        transform="translate(-161.186 -245.596)"
        fill="#d4d4d4"
      />
      <path
        d="M320.665,257.3a.65.65,0,0,1,0-1.3h0a.65.65,0,0,1,0,1.3Z"
        transform="translate(-237.935 -245.596)"
        fill="#d4d4d4"
      />
      <path
        d="M160.665,329.3a.65.65,0,0,1,0-1.3h0a.65.65,0,1,1,0,1.3Z"
        transform="translate(-84.438 -314.669)"
        fill="#d4d4d4"
      />
      <path
        d="M240.665,329.3a.65.65,0,1,1,0-1.3h0a.65.65,0,1,1,0,1.3Z"
        transform="translate(-161.186 -314.669)"
        fill="#d4d4d4"
      />
      <path
        d="M320.665,329.3a.65.65,0,0,1,0-1.3h0a.65.65,0,1,1,0,1.3Z"
        transform="translate(-237.935 -314.669)"
        fill="#d4d4d4"
      />
      <path
        d="M160.665,401.3a.65.65,0,0,1,0-1.3h0a.65.65,0,0,1,0,1.3Z"
        transform="translate(-84.438 -383.743)"
        fill="#d4d4d4"
      />
      <path
        d="M240.665,401.3a.65.65,0,0,1,0-1.3h0a.65.65,0,0,1,0,1.3Z"
        transform="translate(-161.186 -383.743)"
        fill="#d4d4d4"
      />
      <path
        d="M320.665,401.3a.65.65,0,0,1,0-1.3h0a.65.65,0,0,1,0,1.3Z"
        transform="translate(-237.935 -383.743)"
        fill="#d4d4d4"
      />
    </g>
  </SVG>
))

const IconMinus = withIconStyles((props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="33.956"
    height="20.103"
    viewBox="0 0 33.956 20.103"
    {...props}
  >
    <g transform="translate(-14660.521 -826.12)">
      <path
        d="M104.087,20.1H73.87A1.841,1.841,0,0,1,72,18.3V1.806A1.841,1.841,0,0,1,73.87,0h30.217a1.841,1.841,0,0,1,1.87,1.806V18.3A1.841,1.841,0,0,1,104.087,20.1ZM73.87,1.256a.56.56,0,0,0-.569.55V18.3a.56.56,0,0,0,.569.55h30.217a.56.56,0,0,0,.569-.55V1.806a.56.56,0,0,0-.569-.55Z"
        transform="translate(14588.521 826.12)"
        fill="#d4d4d4"
      />
      <path
        d="M-3.018-5.52V-7.605H8.432V-5.52Z"
        transform="translate(14674.518 842.725)"
        fill="#d4d4d4"
      />
    </g>
  </SVG>
))

const IconPlus = withIconStyles((props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="33.956"
    height="20.103"
    viewBox="0 0 33.956 20.103"
    {...props}
  >
    <g transform="translate(-14695.521 -826.12)">
      <path
        d="M139.087,20.1H108.87A1.841,1.841,0,0,1,107,18.3V1.806A1.841,1.841,0,0,1,108.87,0h30.217a1.841,1.841,0,0,1,1.87,1.806V18.3A1.841,1.841,0,0,1,139.087,20.1ZM108.87,1.256a.56.56,0,0,0-.569.55V18.3a.56.56,0,0,0,.569.55h30.217a.56.56,0,0,0,.569-.55V1.806a.56.56,0,0,0-.569-.55Z"
        transform="translate(14588.521 826.12)"
        fill="#d4d4d4"
      />
      <path
        d="M-1.061-7.418H-5.725V-9.5h4.664v-4.781H1.049V-9.5H5.725v2.086H1.049V-2.66H-1.061Z"
        transform="translate(14712.246 844.405)"
        fill="#d4d4d4"
      />
    </g>
  </SVG>
))

const IconNext = withIconStyles((props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="8.689"
    height="15.244"
    viewBox="0 0 8.689 15.244"
    {...props}
  >
    <path d="M8.377,6.867,1.822.313A1.067,1.067,0,0,0,.313,1.822l5.8,5.8-5.8,5.8a1.067,1.067,0,0,0,1.51,1.51L8.377,8.377a1.067,1.067,0,0,0,0-1.509Z" />
  </SVG>
))

const IconPrevious = withIconStyles((props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="8.689"
    height="15.243"
    viewBox="0 0 8.689 15.243"
    {...props}
  >
    <path
      d="M105.515,8.376,98.961,14.93a1.067,1.067,0,0,1-1.51-1.509l5.8-5.8-5.8-5.8a1.067,1.067,0,0,1,1.51-1.51l6.554,6.554a1.067,1.067,0,0,1,0,1.509Z"
      transform="translate(105.828 15.243) rotate(180)"
    />
  </SVG>
))

export default {
  IconAnswerEliminator,
  IconBookMark,
  IconCalculator,
  IconMinus,
  IconPlus,
  IconNext,
  IconPrevious,
}
