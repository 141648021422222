import { Tabs } from 'antd'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { isEmpty } from 'lodash'
import { notification } from '@edulastic/common'
import { segmentApi } from '@edulastic/api'
import { aiTestActions } from '../../AssessmentCreate/components/CreateAITest/ducks'
import {
  StyledAIEnhanceQuestionTabs,
  StyledAiTestDrawer,
  StyledDrawerTitle,
} from '../common/styled'
import { QUESTION_ENHANCE_PROMPTS } from '../constants/prompt'
import {
  getAIEnhanceQuestionState,
  resetAIEnhancedQuestionStateAction,
} from '../ducks/enhanceQuestion'
import { defaultAlignmentData } from '../../../assessment/containers/QuestionMetadata/constants'
import { submitAIResponseFeedbackAction } from '../ducks/feedback'

const AIEnhanceQuestion = ({
  isVisible,
  onCancel,
  defaultSelectedTab,
  questionData,
  updateAITestItem,
  resetAIEnhancedQuestionState,
  aiEnhanceQuestionState,
  submitAIResponseFeedback,
}) => {
  const [selectedTab, setSelectedTab] = useState(defaultSelectedTab)

  useEffect(() => {
    resetAIEnhancedQuestionState()
  }, [selectedTab])

  const { apiStatus, result = {} } = aiEnhanceQuestionState || {}
  const { requestId = 'NA' } = result || {}

  const trackEvent = ({ option, action, source }) => {
    segmentApi.genericEventTrack('AIQuestionEnhance', {
      source,
      option,
      action,
      requestId,
    })
  }

  const onFinish = ({ question, questionType, selectedOption }, key) => {
    const {
      depthOfKnowledge,
      authorDifficulty,
      alignment,
      subjects = [],
      grades = [],
    } = questionData.data.questions?.[0]

    updateAITestItem({
      itemType: questionType,
      aiGeneratedQuestion: {
        difficultLevel: authorDifficulty,
        ...question,
        depthOfKnowledge,
        commonCoreStandard: alignment?.[0]?.standards?.[0]?.identifier,
      },
      existingQidToRegenerate: questionData._id,
      alignment: isEmpty(alignment)
        ? [{ ...defaultAlignmentData, grades, subject: subjects[0] }]
        : alignment,
      subjects,
      grades,
    })

    notification({
      type: 'success',
      messageKey: 'aiQuestionReviseReplaced',
    })

    trackEvent({
      source: key,
      option: selectedOption,
      action: 'Replace Question',
    })

    onCancel()
  }

  const onAIFeedbackSubmitSuccessCallback = () => {
    notification({ type: 'success', msg: 'Feedback noted successfully' })
  }

  const onAIResponseFeedbackSubmit = ({ feedback }) => {
    submitAIResponseFeedback({
      requestId,
      feedback,
      onFeedbackSuccessCallback: onAIFeedbackSubmitSuccessCallback,
    })
  }

  return (
    <StyledAiTestDrawer
      style={{ zIndex: 1004 }}
      title={<StyledDrawerTitle>Enhance with AI</StyledDrawerTitle>}
      maskClosable={false}
      onClose={onCancel}
      visible={isVisible}
      width="640px"
      destroyOnClose
    >
      <StyledAIEnhanceQuestionTabs
        defaultActiveKey="revise"
        onChange={setSelectedTab}
        activeKey={selectedTab}
      >
        {QUESTION_ENHANCE_PROMPTS.map(
          ({ label, key, component: PromptComponent }) => {
            return (
              <Tabs.TabPane
                tab={<span data-cy={`tab-${key}`}>{label}</span>}
                key={key}
                disabled={key !== selectedTab && apiStatus === 'INITIATED'}
              >
                <PromptComponent
                  onFinish={(data) => {
                    onFinish(data, key)
                  }}
                  onUseOldQuestion={({ selectedOption }) => {
                    trackEvent({
                      source: key,
                      option: selectedOption,
                      action: 'Use Old Question',
                    })
                    onCancel()
                  }}
                  data={{ question: questionData?.data.questions[0] }}
                  onAIResponseFeedbackSubmit={onAIResponseFeedbackSubmit}
                />
              </Tabs.TabPane>
            )
          }
        )}
      </StyledAIEnhanceQuestionTabs>
    </StyledAiTestDrawer>
  )
}

const enhance = compose(
  connect(
    (state) => ({
      aiEnhanceQuestionState: getAIEnhanceQuestionState(state),
    }),
    {
      updateAITestItem: aiTestActions.updateAITestItem,
      resetAIEnhancedQuestionState: resetAIEnhancedQuestionStateAction,
      submitAIResponseFeedback: submitAIResponseFeedbackAction,
    }
  )
)

export default enhance(AIEnhanceQuestion)
