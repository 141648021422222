import { Dropdown } from 'antd'
import React, { useState } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { compose } from 'redux'
import { roleuser } from '@edulastic/constants'
import { EduButton } from '@edulastic/common'
import { IconMoreVertical } from '@edulastic/icons'
import { segmentApi } from '@edulastic/api'
import {
  getCurrentTerm,
  getUserIdSelector,
  getUserRole,
  isGcpsDistrictSelector,
  isPremiumUserSelector,
  isUserDistrictSuperAdminSelector,
} from '../../../../src/selectors/user'
import {
  getIsProxiedByEAAccountSelector,
  isDemoPlaygroundUser as isDemoPlaygroundUserSelector,
} from '../../../../../student/Login/ducks'
import ActionMenu from '../../../../Assignments/components/ActionMenu/ActionMenu'
import PremiumPopover from '../../../../../features/components/PremiumPopover'
import { bulkDownloadGradesAndResponsesAction } from '../../../../AssignmentAdvanced/ducks'
import { ActionButtonContainer } from './styled'

const AssignmentActions = ({
  rowData,
  userId,
  userRole,
  isDemoPlaygroundUser,
  isProxiedByEAAccount,
  history,
  onOpenReleaseScoreSettings,
  showPreviewModal,
  toggleEditModal,
  togglePrintModal,
  showEmbedLinkModal,
  isPremiumUser,
  currentTermId,
  t,
  bulkDownloadGradesAndResponses,
  showViewSummary,
  canEdit,
  isGcpsDistrict,
  isDistrictSuperAdmin,
}) => {
  const [premiumPopup, setPremiumPopup] = useState(null)

  const currentAssignment = {
    testId: rowData._id,
    _id: rowData.classes[0].assignmentId,
    classId: rowData.classes[0]._id,
    title: rowData.testTitle,
    testType: rowData.testType,
    termId: currentTermId,
    testContentVisibility: rowData.classes[0].testContentVisibility,
    assignedBy: rowData.classes[0].assignedBy,
  }

  const handleDownloadResponses = (testId) => {
    bulkDownloadGradesAndResponses({
      data: {
        assignmentGroups: {},
        isResponseRequired: true,
      },
      testId,
      testType: null,
    })
  }

  const hasAdminAssignments = rowData.classes.some(
    (item) =>
      item.assignedBy.role === roleuser.SCHOOL_ADMIN ||
      item.assignedBy.role === roleuser.DISTRICT_ADMIN
  )

  return (
    <ActionButtonContainer data-cy="actions" className="actions">
      <div onClick={(e) => e.stopPropagation()}>
        <Dropdown
          overlay={ActionMenu({
            userId,
            userRole,
            isDemoPlaygroundUser,
            isProxiedByEAAccount,
            isDashboardView: true,
            onOpenReleaseScoreSettings,
            row: rowData,
            history,
            showPreviewModal,
            toggleEditModal,
            togglePrintModal,
            showEmbedLinkModal,
            isPremiumUser,
            currentAssignment,
            showPremiumPopup: setPremiumPopup,
            i18Translate: t,
            handleDownloadResponses,
            showViewSummary,
            canEdit:
              canEdit &&
              !(hasAdminAssignments && userRole === roleuser.TEACHER),
            isGcpsDistrict,
            isDistrictSuperAdmin,
          })}
          placement="bottomRight"
          trigger={['click']}
          getPopupContainer={(trigger) => trigger.parentNode}
        >
          <EduButton
            isGhost
            btnType="secondary"
            IconBtn
            noHover
            onClick={() => {
              segmentApi.genericEventTrack('Dashboard:Assignment:Menu')
            }}
          >
            <IconMoreVertical style={{ transform: 'rotate(90deg)' }} />
          </EduButton>
        </Dropdown>
        <PremiumPopover
          target={premiumPopup}
          onClose={() => setPremiumPopup(null)}
          descriptionType="report"
          imageType="IMG_DATA_ANALYST"
        />
      </div>
    </ActionButtonContainer>
  )
}

const enhance = compose(
  withRouter,
  withNamespaces('assignmentCard'),
  connect(
    (state) => ({
      userId: getUserIdSelector(state),
      userRole: getUserRole(state),
      isDemoPlaygroundUser: isDemoPlaygroundUserSelector(state),
      isProxiedByEAAccount: getIsProxiedByEAAccountSelector(state),
      isPremiumUser: isPremiumUserSelector(state),
      currentTermId: getCurrentTerm(state),
      isGcpsDistrict: isGcpsDistrictSelector(state),
      isDistrictSuperAdmin: isUserDistrictSuperAdminSelector(state),
    }),
    {
      bulkDownloadGradesAndResponses: bulkDownloadGradesAndResponsesAction,
    }
  )
)

export default enhance(AssignmentActions)
