import React from 'react'
import { EduButton, FlexContainer } from '@edulastic/common'
import { IconPlusCircle } from '@edulastic/icons'
import connect from 'react-redux/es/connect/connect'
import AuthorCompleteSignupButton from '../../../../common/components/AuthorCompleteSignupButton'
import { setShowAssignmentCreationModalAction } from '../../../Dashboard/ducks'

const NewAssignmentButton = ({
  setShowAssignmentCreationModal,
  buttonDataCy,
  buttonTitle = 'NEW ASSIGNMENT',
  isBlue = true,
}) => {
  return (
    <FlexContainer
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <AuthorCompleteSignupButton
        renderButton={(handleClick) => (
          <EduButton
            data-cy={buttonDataCy}
            isBlue={isBlue}
            onClick={handleClick}
          >
            <IconPlusCircle />
            {buttonTitle}
          </EduButton>
        )}
        onClick={() => setShowAssignmentCreationModal(true)}
        triggerSource="Create Assignment"
      />
    </FlexContainer>
  )
}

const enhance = connect(() => {}, {
  setShowAssignmentCreationModal: setShowAssignmentCreationModalAction,
})

export default enhance(NewAssignmentButton)
