import React from 'react'
import { connect } from 'react-redux'
import { segmentApi } from '@edulastic/api'
import SwitchButton from '../../../TestPage/components/Setting/components/Common/SwitchButton'
import { getIsEasyAssignEnabledSelector } from '../../../src/selectors/assignments'
import { setEasyAssign } from '../../../src/actions/assignments'
import { EasyAssignToggleBannerContainer } from './styled'

const EasyAssignToggleBanner = ({
  isEasyAssignEnabled,
  setEasyAssignEnabled,
}) => {
  const toggleHandler = (value) => {
    setEasyAssignEnabled(!value)
    segmentApi.genericEventTrack('AssignPage:AssignModeToggle:Switch', {
      to: value ? 'proAssign' : 'easyAssign',
      from: value ? 'easyAssign' : 'proAssign',
    })
  }
  return (
    <EasyAssignToggleBannerContainer>
      {isEasyAssignEnabled
        ? 'Pear Assessment shows basic settings by default. Your settings will be preserved.'
        : 'You can turn off Pro Mode to see basic settings. Your settings will be preserved.'}
      <SwitchButton
        checked={!isEasyAssignEnabled}
        onChangeHandler={toggleHandler}
        dataCy="easyAssignToggleButton"
        ariaLabel="Easy Assign Toggle Button"
      />
    </EasyAssignToggleBannerContainer>
  )
}

const enhance = connect(
  (state) => ({
    isEasyAssignEnabled: getIsEasyAssignEnabledSelector(state),
  }),
  {
    setEasyAssignEnabled: setEasyAssign,
  }
)

export default enhance(EasyAssignToggleBanner)
