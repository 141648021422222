/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconWhiteLightBulb = (props) => (
  <SVG
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.442 11.3333H9.55733M8 2V2.66667M12.2427 3.75733L11.7713 4.22867M14 8H13.3333M2.66667 8H2M4.22867 4.22867L3.75733 3.75733M5.64267 10.3573C5.17658 9.89112 4.8592 9.29718 4.73065 8.6506C4.60211 8.00402 4.66817 7.33384 4.92049 6.72481C5.1728 6.11577 5.60005 5.59523 6.14819 5.229C6.69634 4.86276 7.34077 4.66729 8 4.66729C8.65923 4.66729 9.30366 4.86276 9.85181 5.229C10.4 5.59523 10.8272 6.11577 11.0795 6.72481C11.3318 7.33384 11.3979 8.00402 11.2693 8.6506C11.1408 9.29718 10.8234 9.89112 10.3573 10.3573L9.992 10.722C9.78314 10.9309 9.61747 11.1789 9.50446 11.4518C9.39144 11.7247 9.33329 12.0173 9.33333 12.3127V12.6667C9.33333 13.0203 9.19286 13.3594 8.94281 13.6095C8.69276 13.8595 8.35362 14 8 14C7.64638 14 7.30724 13.8595 7.05719 13.6095C6.80714 13.3594 6.66667 13.0203 6.66667 12.6667V12.3127C6.66667 11.716 6.42933 11.1433 6.008 10.722L5.64267 10.3573Z"
      stroke="#FEB63A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVG>
)

export default withIconStyles(IconWhiteLightBulb)
