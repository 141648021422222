/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconAssignPearPractice = (props) => (
  <SVG
    viewBox="0 0 80 94"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    {...props}
  >
    <path
      d="M68.2881 25.4881L44.7761 1.97609C42.1361 -0.663906 37.8641 -0.663906 35.2321 1.97609L11.7121 25.4881C-3.91186 41.1121 -3.91186 66.4321 11.7121 82.0561C27.3361 97.6801 52.6561 97.6801 68.2801 82.0561C83.9041 66.4321 83.9041 41.1121 68.2801 25.4881H68.2881Z"
      fill="#FFC428"
    />
    <path
      d="M44.5123 19.1357C42.0163 16.6397 37.9763 16.6397 35.4883 19.1357L20.0163 34.6077C8.97633 45.6477 8.97633 63.5437 20.0163 74.5757C31.0563 85.6157 48.9523 85.6157 59.9843 74.5757C71.0243 63.5357 71.0243 45.6397 59.9843 34.6077L44.5123 19.1357Z"
      fill="#DBA100"
    />
    <path
      d="M42.6154 34.0801C41.1674 32.6321 38.8234 32.6321 37.3834 34.0801L28.4074 43.0561C22.0074 49.4561 22.0074 59.8321 28.4074 66.2321C34.8074 72.6321 45.1834 72.6321 51.5834 66.2321C57.9834 59.8321 57.9834 49.4561 51.5834 43.0561L42.6074 34.0801H42.6154Z"
      fill="#A47A00"
    />
  </SVG>
)

export default withIconStyles(IconAssignPearPractice)
