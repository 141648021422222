import styled from 'styled-components'
import { Input } from 'antd'

const { TextArea } = Input

export const StyledInput = styled(Input)`
  width: 100%;
`

export const StyledInputArea = styled(TextArea)`
  width: 100%;
`
