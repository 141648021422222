import { isEmpty } from 'lodash'
import { test as testConstants } from '@edulastic/constants'
import React from 'react'
import {
  GroupName,
  GroupSummaryCard,
  GroupSummaryCardValue,
  SummaryCardContainer,
  SummaryCardLabel,
} from './styled'
import Tags from '../../../src/components/common/Tags'

const TestGroupSummary = ({
  groupSummary,
  itemGroups,
  testCategory,
  testStandards,
}) => {
  if (isEmpty(groupSummary)) {
    return null
  }

  return groupSummary?.map((group, i) => {
    return (
      <>
        <GroupName>{itemGroups?.[i]?.groupName}</GroupName>
        <SummaryCardContainer>
          <GroupSummaryCard>
            <GroupSummaryCardValue>{group.totalItems}</GroupSummaryCardValue>
            <SummaryCardLabel>Items</SummaryCardLabel>
          </GroupSummaryCard>
          <GroupSummaryCard>
            <Tags
              tags={
                testCategory ===
                testConstants.testCategoryTypes.ADAPTIVE_SECTION_TEST
                  ? group?.standards?.map((s) => s.identifier) || []
                  : testStandards
              }
              key="standards"
              show={2}
              isStandards
            />
          </GroupSummaryCard>
        </SummaryCardContainer>
      </>
    )
  })
}

export default TestGroupSummary
