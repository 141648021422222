import React from 'react'
import Dropdown from 'antd/lib/dropdown'
import Menu from 'antd/lib/menu'
import { speedControlOptions } from '../../constants'
import { StyledCircleButton } from '../../styled-components/VideoPreview'

export default function SpeedControl({
  speedControl,
  changeSpeedControl,
  disabled,
}) {
  const menu = (
    <Menu
      style={{ width: 100 }}
      onClick={(e) => changeSpeedControl(Number(e.key))}
    >
      {speedControlOptions.map((value) => (
        <Menu.Item key={value}>{value}x</Menu.Item>
      ))}
    </Menu>
  )
  return (
    <Dropdown
      overlayClassName="speed-control-dropdown"
      overlayStyle={{ zIndex: 1003 }}
      overlay={menu}
      disabled={disabled}
      trigger={['click']}
    >
      <StyledCircleButton data-testid="speed-control">
        <span>{speedControl}x</span>
      </StyledCircleButton>
    </Dropdown>
  )
}
