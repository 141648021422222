import { question } from '@edulastic/constants'
import get from 'lodash/get'
import { LANGUAGES_OPTIONS } from '@edulastic/constants/const/languages'

/**
 * This function is used to get the stimulus of an item,
 * To show in the review page and item bank
 * */
const getItemStimulus = (item) => {
  let stimulus = get(item, ['data', 'questions', 0, 'stimulus'])
  const languageFeatures = get(item, [
    'data',
    'questions',
    0,
    'languageFeatures',
  ])
  // If the stimulus is empty, then get the stimulus from the languageFeatures object
  if (languageFeatures && !stimulus) {
    // Get the language codes from the languageFeatures object and sort them
    const languageCodes = LANGUAGES_OPTIONS.map((language) => language.value)
    // Loop through the language codes and get the stimulus of the first language that is not empty
    for (const languageCode of languageCodes) {
      const languageStimulus = languageFeatures?.[languageCode]?.stimulus
      // If the stimulus is not empty, then set the stimulus and break the loop
      if (languageStimulus) {
        stimulus = languageStimulus
        break
      }
    }
  }
  return stimulus || question.DEFAULT_STIMULUS
}

export default getItemStimulus
