import { black } from '@edulastic/colors'
import { SelectInputStyled } from '@edulastic/common'
import styled from 'styled-components'

export const ClassTypeDropDown = styled(SelectInputStyled)`
  margin: unset !important;
  &.ant-select {
    .ant-select-selection {
      font-size: 14px;
      font-weight: 600;
      color: ${black};
      border: none;
      width: fit-content;
      background-color: unset;
      max-width: 150px;
      &:focus,
      &:hover {
        box-shadow: none !important;
        border: none !important;
        background-color: unset !important;
      }

      &__rendered {
        display: flex !important;
        gap: 12px;
        padding: 0px 20px 0px 0px !important;
      }
    }
  }
`
export const FilterContainer = styled.div`
  display: flex;
`
