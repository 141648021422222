/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconBarChartFilled = (props) => (
  <SVG
    width="14"
    height="14"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M3.66732 4.49967H0.333984V13.6663H3.66732V4.49967Z" />
    <path d="M13.6673 7.83301H10.334V13.6663H13.6673V7.83301Z" />
    <path d="M8.66732 0.333008H5.33398V13.6663H8.66732V0.333008Z" />
  </SVG>
)

export default withIconStyles(IconBarChartFilled)
