import { Tabs } from 'antd'
import React from 'react'
import { IconAssignment, IconManage } from '@edulastic/icons'
import Assignment from './Assignment'
import TabTitle from './components/TabTitle'
import DashboardMyClasses from './Classes'
import {
  DashboardSubHeaderContainer,
  StyledTabs,
  ClassesTabJoyrideHighlight,
  AssignmentsTabJoyrideHighlight,
} from './styled'
import { TAB_KEYS } from '../../constants'

const { TabPane } = Tabs

const tabs = [
  {
    key: TAB_KEYS.MY_CLASSES,
    title: (
      <TabTitle
        title="Classes"
        icon={
          <>
            <IconManage color="currentColor" />
            <ClassesTabJoyrideHighlight data-joyride-id="classesTab" />
          </>
        }
      />
    ),
    component: <DashboardMyClasses />,
  },
  {
    key: TAB_KEYS.ASSIGNMENTS,
    title: (
      <TabTitle
        title="Assignments"
        icon={
          <>
            <IconAssignment color="currentColor" />
            <AssignmentsTabJoyrideHighlight data-joyride-id="assignmentTab" />
          </>
        }
      />
    ),
    component: <Assignment />,
  },
]

const DashboardSubHeader = ({ activeTab, handleTabChange }) => {
  return (
    <DashboardSubHeaderContainer>
      <StyledTabs activeKey={activeTab} onChange={handleTabChange}>
        {tabs.map((tab) => (
          <TabPane key={tab.key} tab={tab.title}>
            {tab.component}
          </TabPane>
        ))}
      </StyledTabs>
    </DashboardSubHeaderContainer>
  )
}

export default DashboardSubHeader
