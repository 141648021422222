import { EduButton } from '@edulastic/common'
import { PropTypes } from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Tooltip } from 'antd'
import { segmentApi } from '@edulastic/api'
import { IconShoppingBag } from '@edulastic/icons'
import {
  getSelectedItemSelector,
  clearSelectedItemsAction,
} from '../../../TestPage/components/AddItems/ducks'
import {
  clearTestDataAction,
  getCartTestSelector,
} from '../../../TestPage/ducks'
import { Container, ItemsAmount } from './styled'
import { getUserRole } from '../../../src/selectors/user'

const CartButton = ({
  selectedItems,
  onClick,
  buttonText,
  numberChecker,
  tests,
  clearSelectedItems,
  clearTestData,
  displayDeselect = false,
  origin,
  userRole,
  showBagIcon,
}) => {
  let numberOfSelectedItems = selectedItems && selectedItems.length
  if (numberOfSelectedItems && numberChecker) {
    numberOfSelectedItems = numberChecker(
      tests.itemGroups.flatMap((itemGroup) => itemGroup.items || [])
    )
  }
  useEffect(() => {
    if (tests?._id) {
      clearSelectedItems()
      clearTestData()
    }
  }, [tests?._id])

  return (
    <Container rightMargin={displayDeselect}>
      {numberOfSelectedItems > 0 && displayDeselect && (
        <EduButton
          data-cy="deselectItems"
          isBlue
          isGhost
          onClick={clearSelectedItems}
        >
          Deselect all items
        </EduButton>
      )}
      <Tooltip
        title={
          displayDeselect
            ? 'Combine items from test and item library and create new test.'
            : ''
        }
      >
        <span>
          <EduButton
            isBlue
            isGhost
            onClick={() => {
              segmentApi.genericEventTrack('CreateTestWithxItems', {
                origin,
                trigger: 'header',
                count_of_items: numberOfSelectedItems,
                role: userRole,
              })
              onClick()
            }}
            data-cy="newTest"
            disabled={!numberOfSelectedItems}
          >
            {showBagIcon && <IconShoppingBag />}
            <span>{buttonText}</span>
            <ItemsAmount threeDigit={numberOfSelectedItems > 99}>
              {numberOfSelectedItems}
            </ItemsAmount>
            items
          </EduButton>
        </span>
      </Tooltip>
    </Container>
  )
}

CartButton.propTypes = {
  selectedItems: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    selectedItems: getSelectedItemSelector(state),
    tests: getCartTestSelector(state),
    userRole: getUserRole(state),
  }),
  {
    clearSelectedItems: clearSelectedItemsAction,
    clearTestData: clearTestDataAction,
  }
)(CartButton)
