import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

// hoc
import { withNamespaces } from 'react-i18next'

// components
import { Row, Layout, Spin, Modal } from 'antd'
import { MainContentWrapper, MainHeader, EduButton } from '@edulastic/common'
import { IconClockDashboard, IconHangouts } from '@edulastic/icons'
import { white, themeColor } from '@edulastic/colors'
import ClassSelect, {
  StudentSlectCommon,
} from '../../sharedComponents/ClassSelector'
import AssignmentContainer from './Container'
import SubHeader from './SubHeader'
import HangoutsModal from './HangoutsModal'

// ducks
import {
  getEnrollClassAction,
  setFilterClassAction,
} from '../../ManageClass/ducks'

// constants
import {
  proxyRole,
  toggleIosRestrictNavigationModalAction,
} from '../../Login/ducks'

const Wrapper = styled(Layout)`
  width: 100%;
  background-color: ${(props) => props.theme.sectionBackgroundColor};
`

const Assignments = ({
  userRole,
  activeClasses,
  loadAllClasses,
  loading,
  currentChild,
  proxyUserRole,
  isCliUser,
  districtPolicies,
  t,
  schoolPolicies,
  iosRestrictNavigationModalVisible,
  toggleIosModal,
}) => {
  const isParentRoleProxy = proxyUserRole === 'parent'

  const activeEnrolledClasses = (activeClasses || []).filter(
    (c) => c.status == '1'
  )
  const hangoutEnabledDistrictMap = (districtPolicies || []).reduce(
    (acc, o) => {
      acc[o.districtId] = o.enableGoogleMeet
      return acc
    },
    {}
  )

  const hangoutEnabledSchoolMap = (schoolPolicies || []).reduce((acc, o) => {
    acc[o.schoolId] = o.enableGoogleMeet
    return acc
  }, {})

  const classListWithHangouts = activeEnrolledClasses.filter(
    (c) =>
      c.hangoutLink &&
      (hangoutEnabledDistrictMap[c.districtId] === true
        ? true
        : hangoutEnabledSchoolMap[c.institutionId] === true)
  )

  const [showHangoutsModal, setShowHangoutsModal] = useState(false)
  const [selectedGroupId, selectGroupId] = useState('')

  const selectedGroup = classListWithHangouts.find(
    (c) => c._id === selectedGroupId
  )
  const hangoutLink = selectedGroup?.hangoutLink

  useEffect(() => {
    loadAllClasses()
  }, [currentChild])

  if (loading) return <Spin />
  const isHangoutEnabled = !!classListWithHangouts?.length
  return (
    <Wrapper>
      <IosModal
        visible={iosRestrictNavigationModalVisible}
        centered
        footer={
          <EduButton onClick={() => toggleIosModal(false)} type="primary">
            Ok
          </EduButton>
        }
        onCancel={() => toggleIosModal(false)}
      >
        Your teacher has added security settings to this test that are not
        compatible with an iPad
      </IosModal>
      <HangoutsModal
        isStudent
        visible={showHangoutsModal}
        onCancel={() => setShowHangoutsModal(false)}
        title="Join Google Meet"
        onSelect={selectGroupId}
        selected={selectedGroup}
        classList={classListWithHangouts}
        description="Select the class that you want to join for the Google Meet session."
        hangoutLink={hangoutLink}
      />
      <MainHeader
        Icon={IconClockDashboard}
        headingText={t('common.dashboardTitle')}
        hideSideMenu={isCliUser}
      >
        <Row type="flex" align="middle">
          {!!classListWithHangouts.length &&
            !(userRole === 'parent' || isParentRoleProxy) &&
            isHangoutEnabled && (
              <StyledEduButton
                height="40px"
                style={{ 'margin-right': '20px' }}
                isGhost
                onClick={() => setShowHangoutsModal(true)}
              >
                <IconHangouts height={23} width={20} />
                Join Google Meet
              </StyledEduButton>
            )}
          <StudentSlectCommon />
          <ClassSelect
            t={t}
            classList={activeEnrolledClasses}
            showAllClassesOption
          />
        </Row>
      </MainHeader>
      <MainContentWrapper>
        <SubHeader />
        <AssignmentContainer />
      </MainContentWrapper>
    </Wrapper>
  )
}

export default withNamespaces('header')(
  connect(
    (state) => ({
      userRole: state?.user?.user?.role,
      iosRestrictNavigationModalVisible:
        state?.user?.iosRestrictNavigationModalVisible,
      districtPolicies: state?.user?.user?.orgData?.districtPolicies,
      schoolPolicies: state?.user?.user?.orgData?.schoolPolicies,
      allClasses: state.studentEnrollClassList.allClasses,
      activeClasses: state.studentEnrollClassList.filteredClasses,
      loading: state.studentEnrollClassList.loading,
      currentChild: state?.user?.currentChild,
      proxyUserRole: proxyRole(state),
      isCliUser: state?.user?.isCliUser,
    }),
    {
      loadAllClasses: getEnrollClassAction,
      setFilterClass: setFilterClassAction,
      toggleIosModal: toggleIosRestrictNavigationModalAction,
    }
  )(Assignments)
)

const StyledEduButton = styled(EduButton)`
  margin-left: 10px;
  span {
    margin: 0 15px;
  }
  svg {
    .b {
      fill: ${white};
    }
  }
  &:hover,
  &:focus {
    .b {
      fill: ${themeColor};
    }
  }
`

const IosModal = styled(Modal)`
  .ant-modal-footer {
    border-top: 0;
    text-align: center;
    button {
      display: inline-block;
    }
  }
`
