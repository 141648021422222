import React from 'react'
import SVG from './common/SVG'
import withIconStyles from './HOC/withIconStyles'

const IconRectangle = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20" // Square viewBox with equal dimensions
    {...props}
  >
    <rect x="0" y="0" width="20" height="20" rx="0" ry="0" />
  </SVG>
)

export default withIconStyles(IconRectangle)
