import { greenDark } from '@edulastic/colors'
import styled from 'styled-components'

const Title = styled.div`
  color: ${greenDark};
  font-size: 13px;
  font-weight: 600;
`

export default Title
