/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconTextEntry = ({ title, ...props }) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23.072 14.179"
    {...props}
  >
    <g transform="translate(0 0)">
      <path
        d="M295.456,65.928v-.753h-3.25v.753h1.247V78.6h-1.247v.753h3.25V78.6h-1.247V65.928Z"
        transform="translate(-272.383 -65.175)"
      />
      <g transform="translate(0 1.608)">
        <path
          d="M.14,98.463l3.8-8.559a1.484,1.484,0,0,1,1.4-.948h.14a1.463,1.463,0,0,1,1.387.948l3.8,8.559a1.163,1.163,0,0,1,.125.482,1.136,1.136,0,0,1-1.138,1.149,1.222,1.222,0,0,1-1.154-.823l-.733-1.708h-4.8l-.764,1.786a1.179,1.179,0,0,1-1.107.746A1.1,1.1,0,0,1,0,98.976,1.265,1.265,0,0,1,.14,98.463ZM6.89,95.45,5.378,91.862,3.866,95.45Z"
          transform="translate(0 -88.957)"
        />
        <path
          d="M176.011,133.819v-.031c0-1.817,1.387-2.656,3.367-2.656a5.955,5.955,0,0,1,2.042.342v-.14c0-.979-.608-1.522-1.793-1.522a5.451,5.451,0,0,0-1.637.233,1.006,1.006,0,0,1-.343.062.963.963,0,0,1-.982-.963.979.979,0,0,1,.639-.917,7.3,7.3,0,0,1,2.666-.435,3.866,3.866,0,0,1,2.822.932,3.621,3.621,0,0,1,.9,2.656v3.79a1.136,1.136,0,0,1-1.154,1.134,1.059,1.059,0,0,1-1.138-.979v-.016a3.218,3.218,0,0,1-2.525,1.056A2.581,2.581,0,0,1,176.011,133.819Zm5.44-.544v-.419a3.685,3.685,0,0,0-1.512-.311c-1.013,0-1.637.4-1.637,1.149v.031c0,.637.53,1.01,1.294,1.01C180.7,134.735,181.451,134.13,181.451,133.275Z"
          transform="translate(-164.071 -125.167)"
        />
      </g>
    </g>
    <title>{title || ''}</title>
  </SVG>
)

export default withIconStyles(IconTextEntry)
