import { EduButton } from '@edulastic/common'
import styled from 'styled-components'
import {
  dangerColor,
  fadedBlack2,
  greenPrimary,
  greyThemeDark1,
  lightGreen10,
  lightGrey,
  themeColorBlue,
} from '@edulastic/colors'
import { Drawer, Form, Menu, Tabs, Typography } from 'antd'
import { ConfirmationModal } from '../../src/components/common/ConfirmationModal'
import { ANSWER_OPTION_DELIMITER } from '../constants/test'

const { Text } = Typography

export const StyledFooter = styled.div`
  background: #fff;
  bottom: 24px;
  width: 100%;
  position: absolute;
  box-shadow: 0px -2px 5px 0px #0000001f;
  padding: 12px 24px;
  height: 90px;
  margin: -24px;
`

export const StyledAIContentBorderWrapper = styled.div`
  border: double 1px transparent;
  border-radius: 4px;
  background-image: linear-gradient(white, white),
    linear-gradient(
      250.79deg,
      #257eed 3.18%,
      #429dc4 24.86%,
      #4dc3d8 67.26%,
      #017d69 96.39%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;

  > div:first-child {
    padding: 16px;
  }
`
export const InlineSVG = styled.span`
  svg {
    float: left;
  }
`
export const FooterButton = styled(EduButton)`
  width: 100%;
  font-size: 14px;
  color: ${({ type }) =>
    type === 'default' ? greenPrimary : 'white'} !important;

  svg,
  &:hover svg {
    fill: ${({ type }) =>
      type === 'default' ? greenPrimary : 'white'} !important;
  }
`

export const StyledForm = styled(Form)`
  font-family: 'Open Sans';

  .ant-form-item-label {
    .ant-form-item-required {
      font-weight: 700;
      text-transform: uppercase;

      &::before {
        content: '';
        margin-right: 0;
      }

      &::after {
        display: inline-block;
        color: ${dangerColor};
        content: '*';
      }
    }

    label {
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;

      &::after {
        content: '';
      }
    }
  }

  .ant-input {
    background-color: ${lightGrey};
  }

  .ant-radio-group {
    .ant-radio-wrapper {
      .ant-radio-checked {
        .ant-radio-inner {
          border-color: ${lightGreen10};

          &::after {
            background-color: ${lightGreen10};
          }
        }
      }
    }
  }

  .has-error {
    .custom-tree-select,
    textarea:not(.standards-set textarea),
    textarea:not(.standards-set textarea):hover {
      border-color: ${dangerColor} !important;
    }

    .ant-select-selection:not(.standards-set .ant-select-selection),
    .ant-select-selection:not(.standards-set .ant-select-selection):hover {
      border-color: ${dangerColor} !important;
    }
  }
`
export const StyledStandardSet = styled.div`
  span {
    height: 34px;
    line-height: 34px;
  }

  div[data-cy='standardBrowseButton'] {
    line-height: 0px;
  }

  .anticon-close {
    line-height: 36px;
  }
`

export const StyledGradientText = styled.span`
  margin-left: 6px;
  margin-right: ${(props) => props?.marginRight || 'unset'};
  line-height: 24px;
  background: linear-gradient(269.33deg, #007d65 0%, #1766ce 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: ${(props) => props?.fontWeight || 500};
  line-height: 16.34px;
`

export const StyledAIFeaturesSubscriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledAIFeaturesText = styled.span`
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 16.34px;
  text-align: left;
  color: ${fadedBlack2};
`
export const StyledAiTestDrawer = styled(Drawer)`
  .ant-drawer-content {
    overflow: hidden;

    .ant-drawer-body {
      padding: 0px !important;
      height: calc(100vh - 60px);
      padding-bottom: 0;
    }

    .ant-drawer-header {
      border-bottom: revert;
    }
  }
`
export const StyledDrawerTitle = styled.span`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 21.79px;
  text-align: left;
  color: ${greyThemeDark1};
`
export const StyledAIEnhanceQuestionFooter = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 12px 24px;
  margin: -24px;
  background: #fff;
  box-shadow: 0px -2px 5px 0px #0000001f;
  height: 80px;
`
export const StyledAIEnhanceQuestionRegenerateButton = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: ${(props) => (props?.disabled ? '#b3e8de' : '#1ab395')};
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  cursor: ${(props) => (props?.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${(props) => (props?.disabled ? 'none' : 'auto')};

  svg {
    path {
      fill: ${(props) => (props?.disabled ? '#b3e8de' : '#1ab395')} !important;
    }
  }
`
export const StyledAIEnhanceQuestionTabs = styled(Tabs)`
  &.ant-tabs {
    height: 100%;

    .ant-tabs-bar {
      padding: 0px 24px;
    }

    .ant-tabs-content {
      height: calc(100% - 70px) !important;

      .ant-tabs-tabpane {
        padding: 0px 24px;
      }
    }
  }
`
export const StyledAIEnhanceQuestionContainer = styled.div`
  height: calc(100% - 70px);
  overflow: auto;
  padding: 2px;
`

export const StyledRadioGroupContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`

export const StyledRadioButtonContainer = styled.div`
  margin-bottom: 16px;
`

export const StyledUseOldQuestionAlertModal = styled(ConfirmationModal)`
  min-width: 442px !important;

  .ant-modal-content {
    padding: 0px 0px 25px 0px !important;

    .ant-modal-body {
      display: block !important;
      min-height: 0px !important;
      margin: 0px !important;
      padding: 25px !important;

      p {
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-align: center;
        color: #000000;
      }
    }
  }
`

export const StyledMenuItem = styled(Menu.Item)`
  &:hover {
    background-color: ${themeColorBlue} !important;
    color: white !important;
  }
`
export const StyledOriginalQuestionText = styled(Text)`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 7px;
`

export const StyledQuestionListItem = styled.li`
  ::before {
    content: counter(list-item, upper-alpha) '${ANSWER_OPTION_DELIMITER}\\00A0\\00A0\\00A0';
  }

  ${({ isBold }) => isBold && `font-weight: bold;`}

  list-style-type: none !important;
  margin-left: 0 !important;
  padding-left: 0 !important;
`
