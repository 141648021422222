import { fileTypes } from '@edulastic/constants'

export const CREATE_AI_TEST_DISPLAY_SCREENS = {
  NEW_TEST_SCREEN: 'newTestScreen',
  ADD_ITEMS_SCREEN: 'addItemsScreen',
  CREATE_ITEMS_SCREEN: 'createItemsScreen',
  CREATE_TEST_SCREEN: 'createTestScreen',
  SEARCH_NO_DATA_SCREEN: 'searchNoDataScreen',
  ADD_ITEMS_NO_DATA_SCREEN: 'addItemsNoDataScreen',
  DASHBOARD_TEST_BANNER: 'dashboardTestBanner',
  QUICK_LINK_TEST_REVIEW_SCREEN: 'quickLinkTestReview',
}

export const aiAttachmentTypes = {
  URL: 'URL',
  DOCUMENT: 'DOCUMENT',
}

export const aiAttachmentMaxFileSize = 5

export const allowedAIAttachmentFiles = [
  fileTypes.PDF,
  fileTypes.PNG,
  fileTypes.JPEG,
  fileTypes.JPG,
  fileTypes.DOCX,
]

export const aiAttachmentMessageTypes = {
  INFO: 'INFO',
  ERROR: 'ERROR',
}

export const trackingEventSource = {
  URL: 'Add Link',
  DOCUMENT: 'Upload Document',
}
