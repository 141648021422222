import React from 'react'
import PropTypes from 'prop-types'

import { MathSpan, CorrectAnswersContainer } from '@edulastic/common'
import { withNamespaces } from '@edulastic/localization'
import { convertToMathTemplate } from '@edulastic/common/src/utils/mathUtils'

import { getStemNumeration } from '../../../../utils/helpers'
import { CorrectAnswerBox } from './styled/CorrectAnswerBox'
import { AnswerBox } from './styled/AnswerBox'
import { IndexBox } from './styled/IndexBox'
import { AnswerContent } from './styled/AnswerContent'

const CorrectAnswerBoxLayout = ({
  userAnswers,
  altAnsIndex,
  stemNumeration,
  singleResponseBox,
  t,
  showAnswerScore,
  score,
}) => (
  <CorrectAnswersContainer
    title={
      altAnsIndex
        ? `${t('component.cloze.altAnswers')} ${altAnsIndex}`
        : t('component.cloze.correctAnswer')
    }
    minHeight="auto"
    showAnswerScore={showAnswerScore}
    score={score}
  >
    <CorrectAnswerBox>
      {userAnswers.map((userAnswer) => (
        <AnswerBox key={`correct-answer-${userAnswer.id}`}>
          {!singleResponseBox && (
            <IndexBox>
              {getStemNumeration(stemNumeration, userAnswer.index)}
            </IndexBox>
          )}
          <AnswerContent>
            <MathSpan
              dangerouslySetInnerHTML={{
                __html: convertToMathTemplate(userAnswer.value) || '',
              }}
            />
          </AnswerContent>
        </AnswerBox>
      ))}
    </CorrectAnswerBox>
  </CorrectAnswersContainer>
)

CorrectAnswerBoxLayout.propTypes = {
  userAnswers: PropTypes.object,
  t: PropTypes.func.isRequired,
  altAnsIndex: PropTypes.number,
  stemNumeration: PropTypes.string,
  singleResponseBox: PropTypes.bool,
}

CorrectAnswerBoxLayout.defaultProps = {
  userAnswers: [],
  altAnsIndex: 0,
  stemNumeration: 'numerical',
  singleResponseBox: false,
}

export default React.memo(withNamespaces('assessment')(CorrectAnswerBoxLayout))
