import React from 'react'
import withIconStyles from '@edulastic/icons/src/HOC/withIconStyles'
import SVG from '@edulastic/icons/src/common/SVG'

const IconGraphDashed = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="30.942"
    height="20.101"
    viewBox="0 0 30.942 20.101"
    {...props}
  >
    <g transform="translate(-454.133 -283.203)">
      <ellipse
        cx="13.5"
        cy="8.05"
        rx="13.5"
        ry="8.05"
        transform="translate(456.133 285.203)"
        fill="none"
      />
      <path d="M469.633,303.3l-.514,0,.035-2,.479,0a22.3,22.3,0,0,0,2.392-.128l.215,1.988A24.234,24.234,0,0,1,469.633,303.3Zm-2.6-.139a22.458,22.458,0,0,1-3.09-.55l.491-1.939a20.589,20.589,0,0,0,2.814.5Zm7.273-.321-.4-1.961a18.5,18.5,0,0,0,2.738-.77l.692,1.877A20.555,20.555,0,0,1,474.307,302.844Zm-12.371-.85a16.871,16.871,0,0,1-2.878-1.366l1.031-1.713a14.963,14.963,0,0,0,2.536,1.2Zm17.344-.85-.91-1.782a11.986,11.986,0,0,0,2.309-1.518l1.3,1.524A14.021,14.021,0,0,1,479.28,301.144ZM457.3,299.375a9.8,9.8,0,0,1-2.161-2.524l1.728-1.006a7.8,7.8,0,0,0,1.726,2Zm26.2-1.6-1.584-1.22a5.762,5.762,0,0,0,1.1-2.282l1.954.427A7.757,7.757,0,0,1,483.5,297.778Zm-29.209-3.065a6.769,6.769,0,0,1,.144-3.469l1.913.588a4.819,4.819,0,0,0-.217,1.421,4.871,4.871,0,0,0,.113,1.029Zm28.8-2.078a5.432,5.432,0,0,0-.922-2.335l1.658-1.119a7.4,7.4,0,0,1,1.246,3.194Zm-26-2.33-1.658-1.117a10.447,10.447,0,0,1,2.279-2.391l1.226,1.58A8.476,8.476,0,0,0,457.091,290.3Zm23.923-1.344a11.26,11.26,0,0,0-2.236-1.6l.971-1.749a13.244,13.244,0,0,1,2.63,1.887Zm-20.534-1.6-.97-1.748a17.624,17.624,0,0,1,2.909-1.275l.641,1.894A15.781,15.781,0,0,0,460.48,287.365Zm16.6-.8a18.057,18.057,0,0,0-2.712-.839l.443-1.951a20.082,20.082,0,0,1,3.013.934Zm-12.2-.838-.444-1.95a22.739,22.739,0,0,1,3.1-.481l.17,1.994A20.685,20.685,0,0,0,464.887,285.723Zm7.608-.335a22,22,0,0,0-2.862-.185l-.008-2h.008a24.242,24.242,0,0,1,3.122.2Z" />
    </g>
  </SVG>
)

export default withIconStyles(IconGraphDashed)
