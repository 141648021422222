import React from 'react'
import withIconStyles from '@edulastic/icons/src/HOC/withIconStyles'
import SVG from '@edulastic/icons/src/common/SVG'

const IconRedo = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="15.36"
    height="13.717"
    viewBox="0 0 15.36 13.717"
    {...props}
  >
    <g transform="translate(0 -27.417)">
      <path d="M.454,33.616q1.389-3.455,7.5-3.455h1.92V27.966a.545.545,0,0,1,.934-.386L15.2,31.97a.539.539,0,0,1,0,.772l-4.389,4.39a.549.549,0,0,1-.934-.386V34.55H7.954q-.84,0-1.5.051a10.842,10.842,0,0,0-1.32.184,4.9,4.9,0,0,0-1.14.364,4.035,4.035,0,0,0-.9.6,2.987,2.987,0,0,0-.686.866A4.667,4.667,0,0,0,1.984,37.8a7.3,7.3,0,0,0-.15,1.552q0,.471.043,1.055a1.757,1.757,0,0,0,.021.2,1.765,1.765,0,0,1,.021.227.32.32,0,0,1-.073.214.251.251,0,0,1-.2.086.293.293,0,0,1-.24-.146.98.98,0,0,1-.111-.189q-.051-.111-.116-.257c-.043-.1-.073-.166-.09-.206A10.3,10.3,0,0,1,0,36.471,7.808,7.808,0,0,1,.454,33.616Z" />
    </g>
  </SVG>
)

export default withIconStyles(IconRedo)
