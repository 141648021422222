import styled, { createGlobalStyle } from 'styled-components'
import {
  desktopWidth,
  mediumDesktopExactWidth,
  extraDesktopWidthMax,
  smallDesktopWidth,
  black,
  themeColor,
} from '@edulastic/colors'
import { Divider, Modal } from 'antd'

export const Content = styled.div`
  width: 100%;
  left: 0;
  right: 0;
  height: ${(props) => `calc(100vh - ${props.theme.HeaderHeight.xs}px)`};
  overflow: ${({ hideOverflow }) => (hideOverflow ? 'hidden' : 'auto')};

  @media (min-width: ${mediumDesktopExactWidth}) {
    height: ${(props) => `calc(100vh - ${props.theme.HeaderHeight.md}px)`};
  }
  @media (min-width: ${extraDesktopWidthMax}) {
    height: ${(props) => `calc(100vh - ${props.theme.HeaderHeight.xl}px)`};
  }
  @media (max-width: ${smallDesktopWidth}) {
    height: ${(props) => `calc(100vh - ${props.theme.HeaderHeight.sd}px)`};
  }
  @media (max-width: ${desktopWidth}) {
    height: calc(100vh - 120px);
  }
`

export const ContentBackDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: calc(100vh - 64px);
  z-index: 2;
`

export const StyledConfirmModalTitle = styled.div`
  font-size: 20px;
  padding: 24px;
`

export const StyledConfirmModalContent = styled.div`
  color: ${black};
  padding: 24px;
`
export const StyledConfirmModalDivider = styled(Divider)`
  margin: 0px;
`

export const ConfirmModal = styled(Modal)`
  background: red;
`

export const ConfirmModalStyle = createGlobalStyle`
  .content-confirmation-modal {
    .ant-modal-body{
      padding: 0px;  
    }
    .ant-modal-confirm-content{
      margin: 0px;
    }
    .ant-btn {
      font-weight: 600;
      &:first-child {
        background: white;
        color: ${themeColor} !important;
        border: 1px solid ${themeColor};
      }
      &:last-child {
        background: ${themeColor} !important;
        outline: none;
      }
    }
    .ant-modal-confirm-btns{
      padding: 16px 24px 30px 24px;
      margin-top: 0px;
    }
  }
`
